import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { OverlayContext } from '../../../../layout-and-styling/context-hooks/OverlayContext';
import { useContext } from 'react';

import SolvedProblemIcon from '../../../../assets/solved_problem_icon.svg';
import UnsolvedProblemIcon from '../../../../assets/unsolved_problem_icon.svg';


const ProblemContainer = ({ problem, name, isSelected, select }) => {

    const context = useContext(OverlayContext);
    const { t, i18n } = useTranslation();
    const { key, color, rootCause, isResolved } = problem

    if (problem && name) {
        return (
            <ProblemContainerData onClick={() => select(name)} isSelected={isSelected}>
                <ProblemKeyWrapper style={{ backgroundColor: color }}>
                    {key}
                </ProblemKeyWrapper>
                <ProblemDataWrapper>
                    <ProblemNameWrapper style={{ color: color }}>
                        {/* translate the text based on language choice */}
                        {context.setBusinessProcess(name, i18n.language)}
                    </ProblemNameWrapper>
                    <ProblemRootWrapper>
                        {t('Transformation.RootCause')} : {rootCause.toString().replace(',', ', ')}
                    </ProblemRootWrapper>
                </ProblemDataWrapper>
                <ProblemResolvedWrapper>
                    <img src={isResolved ? SolvedProblemIcon : UnsolvedProblemIcon} />
                </ProblemResolvedWrapper>
            </ProblemContainerData>
        )
    } else {
        return (<></>)
    }
}

export default ProblemContainer

const ProblemContainerData = styled.div`
    cursor: pointer;
    width: 80%;
    max-width: 80%;
    height: 32%;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 10px;
    padding-inline: 20px;
    padding-block: 5px;
    border-radius: 5px;
    background: #FCFCFC;
    border: ${props => props.isSelected ? "1px solid #e2336B" : "none"};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    position: relative; 
`;

const ProblemKeyWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 600;
    padding: 2px;
    height: 19px;
    width: 19px;
`;

const ProblemDataWrapper = styled.div`
    margin-left: 5%;
    display: block;
`;

const ProblemNameWrapper = styled.div`
    font-family: Overpass;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; 
`;

const ProblemRootWrapper = styled.div`
    color: #929699;
    font-family: Overpass;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

const ProblemResolvedWrapper = styled.div`
    margin-left: auto;
    width: 20px;
    height: 16px;
`;

