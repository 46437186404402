import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import styled from 'styled-components';
import CustomTooltipBarChart from './CustomTooltipBarChart';
import { useState } from 'react'
import CustomLegendBarChart from './CustomLegendBarChart';

const MetricsChart = ({ data }) => {

    const [hoverData, sethoverData] = useState({});

    return (
        <div>
            <LinesContainer>
                {Object.values(data).map(metric => {
                    // The height of the chart is divided by 100 to get the height in px per 1%
                    // This value is then multiplied by the 'Optimal' percentage to get the height of the line
                    return <Line key={metric.name} style={{ marginBottom: `${((199 / 100) * metric['benchmark'])}px` }} />
                })}
            </LinesContainer>

            <ResponsiveContainer width="100%" height={290}>
                <BarChart data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: -10,
                        bottom: 5,
                    }}
                    style={{ position: 'relative', zIndex: 2 }}
                >
                    <CartesianGrid strokeDasharray="5 5" />
                    <XAxis dataKey="name" />
                    <YAxis
                        domain={[0, 100]}
                        tickFormatter={(value) => `${value}%`}
                    />
                    <Tooltip
                        content={<CustomTooltipBarChart />}
                        cursor={{ fill: '#E3E3E3' }}
                        position={{ x: hoverData.x, y: -52 }}
                        z={5}
                    />
                    <Legend
                        verticalAlign="top"
                        z={10}
                        height={36}
                        content={<CustomLegendBarChart />}
                    />

                    <Bar onMouseOver={(data) => {
                        sethoverData(data);
                    }} dataKey='values[0]' fill="#D6BAEB" barSize={40} radius={[5, 5, 0, 0]} />
                    <Bar onMouseOver={(data) => {
                        sethoverData(data);
                    }} dataKey='values[1]' fill="#A773E4" radius={[5, 5, 0, 0]} barSize={40} />

                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

export default MetricsChart

const LinesContainer = styled.div`
    position: absolute;
    margin-top: 55px;
    margin-left: 50px;
    // Based on the width of the responsive container - the difference of width between the chart and the container
    width: calc(100% - 110px);
    height: 199px;
    background-color: transparent;
    display: grid;
    align-items: end;
    grid-template-columns: repeat(3, auto);
    z-index: 3;
    pointer-events: none;
`;

const Line = styled.div`
    border-top: 1.5px solid;
    border-color: #E2336B;
    width: 80px;
    margin-top: 4px;
    justify-self: center;
`;