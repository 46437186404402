import styled from 'styled-components';
import {useEffect, useState} from 'react';
import axios from 'axios';

import {SendRepresentativeIntake} from "../layout-and-styling/standardized-components-library/Component-API";
import {useWindowSize} from '../layout-and-styling/standardized-components-library/Component-Responsive';
import {useTranslation, Trans} from 'react-i18next';
import ImageNotFound from '../assets/intake/ImageNotFound.png';
import {ReactComponent as IconCheckCircleFill} from '../assets/intake/IconCheckCircleFill.svg';
import ComponentHeaderBlock from '../layout-and-styling/standardized-components-library/Component-header-block';
import {StyledDivBold, StyledDivRegular} from '../layout-and-styling/standardized-components-library/Styling-TextInput';
import ComponentTeamPersonFilter from '../survey/survey-close-colleagues/page-specific-components/Component-team-person-filter';


const PageIntakeRepresentative = () => {
    const {t} = useTranslation();

    const [identifier, setIdentifier] = useState('');

    useEffect(() => {
        const axiosInstance = axios.create({withCredentials: true})

        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
            .catch(() => {
                console.log('error, you are not logged in yet!')
                window.location.replace(process.env.REACT_APP_APPURL + '/login');
            })
        
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/myintakeprocesses/compare_intakes')
            .then((res) => {
                if (JSON.parse(localStorage.getItem('intakeData'))[0].is_main && !res.data) {
                    axiosInstance.get(process.env.REACT_APP_APIURL + '/api/myintakeprocesses/check_own_intake')
                        .then((res) => {
                            const businessProcess = JSON.parse(localStorage.getItem(`businessProcessIntake`));

                            if (businessProcess !== 0 && businessProcess.length !== 0 && res.data) {
                                setIdentifier('IntakeBuilderLeader');
                            }
                            
                            if (!res.data) {
                                setIdentifier('IntakeBuilderLeaderPublished');
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                }
            })
            .catch((error) => {
                console.log(error);
            })

        if (!identifier) {
            setIdentifier('IntakeNotFound');
        }
    }, []);

    useWindowSize();

    const [render, setRender] = useState(false);

    const reRender = () => {
        setRender(!render);
    };

    useEffect(() => {
        if(!localStorage.getItem("i18nextLng")) {
            localStorage.setItem("i18nextLng", "en-US");
        }
    }, [])

    const [localStorageLanguage, setSelectedLanguage] = useState(JSON.parse(localStorage.getItem('Language')) ? JSON.parse(localStorage.getItem('Language')) : 0);

    const backToHome = () => {
        window.location.replace(process.env.REACT_APP_APPURL + '/home');
    }

    const [inputCheckPeople, setInputCheckPeople] = useState(false);
    const [publishButtonClicked, setPublishButtonClicked] = useState(false);

    const publishRepresentative = () => {
        setPublishButtonClicked(true);

        if (inputCheckPeople) {
            SendRepresentativeIntake();
        }
    }

    return(
        <Container>
            {(identifier === 'IntakeNotFound') ? (
                <OverlayWrapper>
                    <img src={ImageNotFound} style={{width: 'calc(50% - 20px)', height: 'auto'}} alt=''/>  

                    <StyledDivBold Position='static' Width='auto' Height='auto' MarginTop='40px' MarginBottom='40px' FontSize='42px' LineHeight='52px'>
                        {t('PageIntakeBuilder.NotFoundTextHeader')}
                    </StyledDivBold>

                    <ButtonWrapper onClick={backToHome} Width='auto'>
                        <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#FCFCFC' FontWeight='700' LetterSpacing='0.3px'>
                            {t('PageIntakeBuilder.NotFoundOrPublishedTextButton')}
                        </StyledDivRegular>
                    </ButtonWrapper>
                </OverlayWrapper>
            ) : (identifier === 'IntakeBuilderLeaderPublished') ? (
                <OverlayWrapper>
                    <IconCheckCircleFill/>

                    <StyledDivBold Position='static' Width='auto' Height='auto' MarginTop='40px' MarginBottom='16px' FontSize='42px' LineHeight='52px'>
                        {t('PageIntakeBuilder.PublishedTextHeader')}
                    </StyledDivBold>

                    <StyledDivRegular Position='static' Width='auto' Height='auto' MarginBottom='40px' FontSize='18px' LineHeight='28px' TextAlign='center'>
                        {t('PageIntakeBuilder.PublishedBuilderLeaderTextBody')}
                    </StyledDivRegular>

                    <ButtonWrapper onClick={backToHome} Width='auto'>
                        <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#FCFCFC' FontWeight='700' LetterSpacing='0.3px'>
                            {t('PageIntakeBuilder.NotFoundOrPublishedTextButton')}
                        </StyledDivRegular>
                    </ButtonWrapper>
                </OverlayWrapper>
            ) : (
                <>
                    <HeaderWrapper>
                        <ComponentHeaderBlock page='IntakeRepresentative' isDisabled={!inputCheckPeople} handleClick={publishRepresentative} set_selected_language={setSelectedLanguage} reRender={reRender}/>
                    </HeaderWrapper>

                    <Wrapper>
                        <StyledDivBold Position='static' Width='auto' Height='auto' MarginBottom='8px' FontSize='42px' LineHeight='52px'>
                            {t('PageIntakeRepresentative.TextHeader')}
                        </StyledDivBold>

                        <StyledDivBold Position='static' Width='auto' Height='auto' MarginBottom='40px' FontSize='20px' LineHeight='20px' FontWeight='700'>
                            <Trans i18nKey="PageIntakeRepresentative.TextSubHeader">
                                FIND <span style={{color: "#E2336B"}}>THE MOST IMPORTANT TEAMS</span> AND SELECT <span style={{color: "#E2336B"}}>ONE REPRESENTATIVE</span> FOR EACH OF THEM
                            </Trans>
                        </StyledDivBold>

                        <PersonContainer>
                            <ComponentTeamPersonFilter whichFilter="IntakeRepresentatives" MinWidth='840px' MaxWidth='100%' Height='100%' MaxHeight='100%' Margin='0' BorderRadius='8px'
                                                       BoxShadow='0px 0px 60px 0px rgba(0, 0, 0, 0.05)' ZIndex='0' MaxHeightMedia='100%' inputMarginTop="16px" inputMarginLeft="30px" 
                                                       inputCheckPeople={inputCheckPeople} nextButtonClicked={publishButtonClicked} set_inputCheckPeople={setInputCheckPeople} 
                                                       incompleteText={t('PageIntakeRepresentative.TextError')} set_selected_language={setSelectedLanguage} reRender={reRender}/>
                        </PersonContainer>
                    </Wrapper>

                    {/* <ComponentFooter page='PageIntakeRepresentative' prevdirectory='/intake-builder' inputCheckPeople={inputCheckPeople} nextButtonClicked={publishButtonClicked} 
                                     setNextButtonClicked={setPublishButtonClicked} Position='static' MarginTop='0' BackMarginLeft='0' BackWidth="56px" BackHeight="56px" MarginLeft='0'
                                     Width='160px' Height='56px'/> */}
                </>
            )}
        </Container>
    )
}

export default PageIntakeRepresentative;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f6f7fa;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    pointer-events: none;
    -drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }

  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: calc(1 / 1.75);
  }
`;

const OverlayWrapper = styled.div`
  display: flex;
  width: calc(100% - 80px);
  max-width: 1290px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: ${props => props.Width || 'calc(100% - 20px)'};
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${props => props.Color || '#E2336B'};
  transition: filter 0.15s;
  cursor: pointer;
  user-select: none;

  &:hover {
    filter: brightness(90%);
  }
`;

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1290px;
  flex-direction: column;
  align-items: flex-start;
  padding: 108px 40px 145px 40px;
`;

const PersonContainer = styled.div`
  display: flex;
  height: calc(100vh - 373px);
  max-height: 754px;
`;