import styled from 'styled-components';
import OutcomeContainer from './OutcomeContainer';
import SolvedProblemIcon from '../../../../assets/solved_problem_icon.svg';
import UnsolvedProblemIcon from '../../../../assets/unsolved_problem_icon.svg';

import { useTranslation } from "react-i18next";
import { useContext } from 'react';
import { OverlayContext } from '../../../../layout-and-styling/context-hooks/OverlayContext';

const ActionNeededContainer = ({ name, action, color }) => {

    const context = useContext(OverlayContext);
    const { t, i18n } = useTranslation();

    const getTeamPosition = (teams) => {
        let teamsNames = teams.map(team => context.setTeams(team, i18n.language))
        let position = ""

        if (teamsNames.length > 1) {
            position = "Between Teams: "
            teamsNames = teamsNames.join(', ')
        }
        else {
            position = "Within Team: "
        }
        return <span style={{color: "black"}}><span style={{color: color}}>{position}</span> {teamsNames}</span>
    }

    if (action) {
        return (
            <ActionContainer style={{ color: color }}>

                <ActionHeader>{name}</ActionHeader>
                <OutcomeContainer outcome={action.outcome} color={color} />

                <TeamsContainer>
                    {action.actions.map((action, idx) => {
                        return (
                            <TeamWrapper key={idx}>
                                <TeamPosition>
                                    {
                                        getTeamPosition(action.teams)
                                    }
                                </TeamPosition>
                                <TeamResolvedStatus style={{ color: action.resolved === "Resolved" ? "#048B67" : "#FFA62B" }}>
                                    <img src={action.resolved === "Resolved" ? SolvedProblemIcon : UnsolvedProblemIcon} /> {action.resolved}
                                </TeamResolvedStatus>
                            </TeamWrapper>)
                    })}

                    <TeamsList style={{ color: "black" }}></TeamsList>
                </TeamsContainer>

            </ActionContainer>
        )
    } else { return <></> }
}

export default ActionNeededContainer;

const ActionContainer = styled.div`
    margin-bottom: 15px;
    display:flex;
    flex-direction: column;
    width: 100%;
`;

const ActionHeader = styled.div`
    font-family: "Overpass";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin-block: 8px;
    display: flex;
    flex-direction: row;
`;

const TeamsContainer = styled.div`
    margin-top: 8px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
`;

const TeamsList = styled.div`
    font-weight: 300;
    margin-left: 5px;
`;

const TeamWrapper = styled.div`
    display: grid;
    grid-template-columns: 300px 200px;
`;

const TeamPosition = styled.div`
    font-weight: 300;
    margin-left: 5px;
    margin-block: 2px;
`;

const TeamResolvedStatus = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
`;