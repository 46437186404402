import React, {useEffect, useState} from 'react';
import axios from 'axios';
import styled from 'styled-components';

import {StyledDivBold, StyledDivRegular} from '../../layout-and-styling/standardized-components-library/Styling-TextInput';
import Logo from '../../assets/LogoElephantsLanding.svg';
import ComponentFooter from '../../layout-and-styling/standardized-components-library/Component-Footer';
import ComponentLanguageSwitcher from "../../layout-and-styling/standardized-components-library/Component-language-switcher";
import {Trans, useTranslation} from "react-i18next";
import {ReactComponent as IconCheckCircleFill} from '../../assets/SurveyIntroduction/IconCheckCircleFill.svg';
import {ReactComponent as IconArrowRight} from '../../assets/SurveyIntroduction/IconArrowRight.svg';
import {ReactComponent as IconThreeCircleFill} from '../../assets/SurveyIntroduction/IconThreeCircleFill.svg';
import {ReactComponent as BottomWave} from '../../assets/SurveyIntroduction/BottomWave.svg';
import {ReactComponent as ImageIntroductionPage} from '../../assets/SurveyIntroduction/ImageIntroductionPage.svg';
import {ReactComponent as ImageSurveyPage} from '../../assets/SurveyIntroduction/ImageSurveyPage.svg';


const PageInstructions = () => {
    const {t} = useTranslation();
    const [hasBeenRequested, setHasBeenRequested] = useState(false);

    /** Check if already authenticated, and bring to dashboard if possible.*/
    useEffect(() => {
        const axiosInstance = axios.create({withCredentials: true})

        // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
            .catch((error) => {
                console.log('error, you are not logged in yet!')
                window.location.replace(process.env.REACT_APP_APPURL + '/login');
            })
    }, []);

    const [render, setRender] = useState(false);
    const reRender = () => {
        setRender(!render)
    };

    return (
            <Container>
            <Wrapper>

                <LeftContainer>

                    <LogoWrapper>
                      <img src={Logo} alt="" width={"75%"}/>
                    </LogoWrapper>

                    <LeftTextWrapper>

                      <PointWrapper>
                        <IconCheckCircleFill/>

                        <StyledDivBold Position='static' Width='auto' Height='auto' Color='#c6cbcb' FontSize='18px' LineHeight='28px' FontWeight='500' LetterSpacing='1.8px'>
                          {t('PageInstructions.TextSidebarPoint1')}
                        </StyledDivBold>
                      </PointWrapper>

                      <PointWrapper>
                        <IconCheckCircleFill/>
                        
                        <StyledDivBold Position='static' Width='auto' Height='auto' Color='#c6cbcb' FontSize='18px' LineHeight='28px' FontWeight='500' LetterSpacing='1.8px'>
                          {t('PageInstructions.TextSidebarPoint2')}
                        </StyledDivBold>
                      </PointWrapper>

                      <PointWrapper>
                        <IconThreeCircleFill/>

                        <StyledDivBold Position='static' MarginRight='auto' Width='auto' Height='auto' Color='#e52d6b' FontSize='18px' LineHeight='28px' FontWeight='500' LetterSpacing='1.8px'>
                          {t('PageInstructions.TextSidebarPoint3')}
                        </StyledDivBold>

                        <IconArrowRight/>
                      </PointWrapper>

                    </LeftTextWrapper>

                    <BottomWaveWrapper>
                      <BottomWave style={{width: '100%', height: 'auto'}}/>
                    </BottomWaveWrapper>

                </LeftContainer>

                <RightContainer>
                  
                    <LanguageSwitcherWrapper>
                      <ComponentLanguageSwitcher reRender={reRender} Position='static' MarginTop='0' MarginLeft='0' MarginRight='0'/>
                    </LanguageSwitcherWrapper>
                    
                    <RightTextWrapper>

                      <StyledDivBold Position='static' Width='auto' Height='auto' FontSize='42px' LineHeight='52px'>
                        {t('PageInstructions.TextHeader')}
                      </StyledDivBold>

                      <StyledDivRegular Position='static' MarginTop='40px' Width='auto' Height='auto' FontSize='18px' LineHeight='28px'>
                        <Trans i18nKey="PageInstructions.TextBody1">
                          To make things easier for you, every <b>survey page</b> begins with a quick <b>introduction</b> to guide you through the process, as shown below.
                        </Trans>
                      </StyledDivRegular>

                      <ExampleWrapper>

                        <ExamplePointWrapper>

                          <ExamplePointTextWrapper>
                            <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='18px' LineHeight='28px' FontWeight='700'>
                              {t('PageInstructions.TextPoint1')}
                            </StyledDivRegular>
                          </ExamplePointTextWrapper>

                          <ExamplePointImageWrapper>
                            <ImageIntroductionPage style={{width: '100%', height: '100%'}}/>
                          </ExamplePointImageWrapper>

                        </ExamplePointWrapper>

                        <ExamplePointWrapper>

                          <ExamplePointTextWrapper>
                            <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='18px' LineHeight='28px' FontWeight='700'>
                              {t('PageInstructions.TextPoint2')}
                            </StyledDivRegular>
                          </ExamplePointTextWrapper>

                          <ExamplePointImageWrapper>
                            <ImageSurveyPage style={{width: '100%', height: '100%'}}/>
                          </ExamplePointImageWrapper>

                        </ExamplePointWrapper>

                      </ExampleWrapper>

                      <StyledDivRegular Position='static' MarginTop='40px' Width='auto' Height='auto' FontSize='18px' LineHeight='28px' FontWeight='700'>
                        {t('PageInstructions.TextBody2')}
                      </StyledDivRegular>

                      <StyledDivRegular Position='static' MarginTop='20px' Width='auto' Height='auto' Color='#929699' FontSize='18px' LineHeight='28px' FontWeight='700'>
                        {t('PageInstructions.TextBody3')}
                      </StyledDivRegular>

                    </RightTextWrapper>

                    <ComponentFooter identifier='Instructions' page='PageInstructions' prevdirectory='/user-benefits' hasBeenRequested={hasBeenRequested} setHasBeenRequested={setHasBeenRequested} ButtonText='Next' Position='static' MarginTop='0' BackMarginLeft='0' BackWidth="56px" BackHeight="56px" MarginLeft='20px' Width='160px' Height='56px'/>

                </RightContainer>
                
            </Wrapper>
        </Container>
    );
};

export default PageInstructions

const Container = styled.div`
  background: #f6f7fa;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: calc(1 / 1.75);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const LeftContainer = styled.div`
  position: absolute;
  width: 26%;
  min-width: 400px;
  max-width: 500px;
  height: 100%;
  display: flex;
  background: #fcfcfc;
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.05);

  @media (max-width: 1420px) {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 40px;
  left: 60px;
`;

const LeftTextWrapper = styled.div`
  position: absolute;
  top: 18%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

const PointWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 60px 0 64px;
  gap: 30px;
`;

const BottomWaveWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -3px;
`;

const RightContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;

  @media (min-width: 1921px) {
    justify-content: center;
  }
`;

const LanguageSwitcherWrapper = styled.div`
  position: absolute;
  top: 39px;
  display: flex;
  justify-content: flex-end;
  width: 1300px;
  padding: 0 60px;

  @media (max-width: 2420px) {
    margin-left: max(400px, 26%);
  }

  @media (max-width: 1920px) {
    right: 0;
  }
`;

const RightTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1030px;
  padding: 0 220px 0 170px;

  img {
    -drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  @media (max-width: 2420px) {
    margin-left: max(400px, 26%);
  }

  @media (max-width: 1420px) {
    padding: 0 60px;
    margin: auto;
  }
`;

const ExampleWrapper = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 40px;
  row-gap: 20px;
  margin-top: 20px;
`;

const ExamplePointWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 495px;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
`;

const ExamplePointTextWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ExamplePointImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: 80%;
  border-radius: 8px;
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.10);
  overflow: hidden;
`;

//Archive
// import IconCheckBox from '../../assets/Icon_IntroductionUserInput.svg';
// <img src={IconCheckBox}/>
// const util = require('util') // pre-condition for console.log to see all content of array without cutting off

/** GDPR check */
// checked_flag={checked} MarginTop='694px'
// <StyledDivRegular MarginTop='602px' MarginLeft='160px' Width='900px' Display='flex' Gap='0px 20px' JustifyContent='flex-start' AlignItems='flex-start'>
//     <Checkbox MarginTop='' MarginLeft='' sx={{'& .MuiSvgIcon-root': { fontSize: 28}, }}  style={{ color: "#e2336B", }}
//         required={true} value={checked} onChange={handleChange}
//     />
// </StyledDivRegular>

// <StyledDivRegular MarginTop='610px' MarginLeft='214px' Width='1043px' FontSize='18px' LineHeight='28px'>
//     By checking this box you confirm that you have read and agree to our Terms of Service, and that you have read our Privacy Policy.
//     See our <a href="https://elephantsintheroom.co/terms-of-service/" target="_blank" rel="noreferrer" style={{textDecoration: "none"}}> <span> Terms of Service </span></a>
//     and <a href="https://elephantsintheroom.co/privacy-policy/" target="_blank" rel="noreferrer" style={{textDecoration: "none"}}> <span> Privacy Policy </span></a>
// </StyledDivRegular>
// import { Checkbox } from '@mui/material';