import React, {useContext, useEffect, useRef, useState} from "react";
import styled from 'styled-components';
import { OverlayContext } from "../../../layout-and-styling/context-hooks/OverlayContext";
import {StyledDivRegular} from "../../../layout-and-styling/standardized-components-library/Styling-TextInput";
import { ComponentTooltipSpontaneousCollaborationPage } from "../../../layout-and-styling/standardized-components-library/Component-Tooltips";
import {Checkbox} from '@mui/material';
import ComponentDay from "./Component-day";
import ComponentAddDay from "./Component-add-day";
import ComponentAverage from "./Component-average";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const ComponentWeek = (props) => {

    const {t} = useTranslation();
    const [hover, setHover] = useState(false);

    const [average, setAverage] = useState(true);

    const handleCheckboxChange = () => {
      setAverage(!average);
    };

    function addDay() {
      if(!average){
        props.MeetingsDays.push({ id: props.MeetingsDays.length, name: "Extra", name_nl: "Extra", name_ch: "额外的", selected: true, meetings: [], meetingsDuration: 0 })
        for (let i = 0; i < props.meetingsTimes.length; i++) props.meetingsTimes[i]["days"].push(0)
        props.setMeetingsDays(props.MeetingsDays)
        props.setMeetingsTimes(props.meetingsTimes)
        localStorage.setItem("MeetingsDays", JSON.stringify(props.MeetingsDays))
        localStorage.setItem("meetingsTimes", JSON.stringify(props.meetingsTimes))

        props.SpontaneousDays.push({ id: props.SpontaneousDays.length, name: "Extra", name_nl: "Extra", name_ch: "额外的"})
        for (let i = 0; i < props.spontaneousTimes.length; i++) props.spontaneousTimes[i]["days"].push(0)
        props.setSpontaneousDays(props.SpontaneousDays)
        props.setSpontaneousTimes(props.spontaneousTimes)
        localStorage.setItem("SpontaneousDays", JSON.stringify(props.SpontaneousDays))
        localStorage.setItem("spontaneousTimes", JSON.stringify(props.spontaneousTimes))

        props.reRender()
      }
    }
  
    for (let i = 0; i < props.spontaneousTimes.length; i++) {
      if (props.spontaneousTimes[i]['days'].filter((day) => day > 0).length > 0 &&
          !props.ArrayInputCheck.includes(props.spontaneousTimes[i]['employee'])) {
          props.ArrayInputCheck.push(props.spontaneousTimes[i]['employee']);
      }
    }
    props.updateArrayInputCheck(props.ArrayInputCheck)
    localStorage.setItem('CheckedEmployeesSpontaneousCollaboration', JSON.stringify(props.ArrayInputCheck))
    props.setInputChecker(props.spontaneousTimes.length === props.ArrayInputCheck.length)
    

    /** For and if statements to select the right day in ArrayHrs, so this specific selections only alters the right day and colleague interaction */
    return (
      <Container>
        <TitleWrapper>
          <Title>
              {t('PageSpontaneousCollaboration.SlidersTitle')}
          </Title>
          <LegendWrapper>
            <Legend>
                <Circle Color={"#271B36"}/>
                {t('PageSpontaneousCollaboration.MeetingsTime')}
            </Legend>
            <Legend>
                <Circle Color={"#B3194A"}/>
                {t('PageSpontaneousCollaboration.SpontaneousTime')}
            </Legend>
            <InfoHover onMouseOver={() => setHover(true)}
                        onMouseOut={() => setHover(false)}>?

                {hover && (
                  <ComponentTooltipSpontaneousCollaborationPage whichTooltip={"Sliders"}/>
                )}
            </InfoHover>
          </LegendWrapper>
        </TitleWrapper>

        {/** Average per day */}
        <ComponentAverage Opacity={average ? 1 : 0.3} SpontaneousDays={props.SpontaneousDays} setSpontaneousDays={props.setSpontaneousDays} spontaneousTimes={props.spontaneousTimes} setSpontaneousTimes={props.setSpontaneousTimes} selectedColleague={props.selectedColleague} setInputChecker={props.setInputChecker}
                          MeetingsDays={props.MeetingsDays} setMeetingsDays={props.setMeetingsDays} meetingsTimes={props.meetingsTimes} setMeetingsTimes={props.setMeetingsTimes} reRender={props.reRender} ArrayInputCheck={props.ArrayInputCheck} updateArrayInputCheck={props.updateArrayInputCheck} average={average} element={"average"}/>

        <CheckboxWrapper MarginTop='6px' MarginBottom='6px' MarginLeft='24px'>
          <Checkbox size="large" sx={{'& .MuiSvgIcon-root': {fontSize: 28},}}
                    style={{color: "#5F6366", margin: "-12px",}}
                    value={average} onChange={handleCheckboxChange}/>
          <StyledDivRegular Width="fit-content" Position="relative" MarginTop="8px" MarginLeft="8px">
            {t('PageSpontaneousCollaboration.InteractionsPerDay')}
          </StyledDivRegular>
        </CheckboxWrapper>

        {/** Days of the week */}
        <DaysWrapper Opacity={average ? 0.3 : 1}>
            {props.SpontaneousDays.map((day, day_id) => (
                <ComponentDay day={day} id={day_id} SpontaneousDays={props.SpontaneousDays} setSpontaneousDays={props.setSpontaneousDays} spontaneousTimes={props.spontaneousTimes} setSpontaneousTimes={props.setSpontaneousTimes} selectedColleague={props.selectedColleague} setInputChecker={props.setInputChecker}
                              MeetingsDays={props.MeetingsDays} setMeetingsDays={props.setMeetingsDays} meetingsTimes={props.meetingsTimes} setMeetingsTimes={props.setMeetingsTimes} reRender={props.reRender} ArrayInputCheck={props.ArrayInputCheck} updateArrayInputCheck={props.updateArrayInputCheck} average={average} element={"days"}/>
            ))}

            <ComponentAddDay addDay={addDay} average={average}/>
        </DaysWrapper>
      </Container>        
    )

}
export default ComponentWeek

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1000px;
  height: calc(100% - 24px);
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  background: #FCFCFC;
`;

const DaysWrapper = styled.div`
  width: 100%;
  height: calc(100% - 100px);
  padding-top: 30px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  background: #FCFCFC;
  gap: 24px;
  border-radius: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  opacity: ${props => props.Opacity || ""};

  ::-webkit-scrollbar {
    height: 12px;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #929699;
    border-radius: 5px;
  }
`;

const TitleWrapper = styled.div`
  position: relative;
  width: calc(100% - 48px);
  height: 40px;
  padding-top: 24px;
  padding-bottom: 8px;
  padding: 24px 24px 8px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #FCFCFC;
  border-radius: 10px;
`;

const Title = styled.div`
  width: fit-content;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: #271B36;
`;

const LegendWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 24px;
  margin-right: 12px;
`;

const Legend = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #271B36;
  gap: 8px;
`;

const Circle = styled.div`
  position: relative;
  width: 14px;
  height: 14px;
  margin-top: 2px;
  border-radius: 10px;
  background-color: ${props => props.Color || ""};
`;

const CheckboxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: ${props => props.MarginTop || "0%"};
  margin-bottom: ${props => props.MarginBottom || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
`;

const InfoHover = styled.div`
  float: right;
  font-size: 18px;
  font-family: 'Overpass', sans-serif;
  font-weight: 500;
  margin-right: -12px;
  position: relative;
  color: #FCFCFC;
  background-color: #E2336B;
  border: 2px solid #E2336B;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
`;
