import React, {useContext, useRef, useState} from 'react';
import styled from 'styled-components';
import {ReactComponent as ArrowSlider} from "../../../assets/SurveyCommunication/ArrowSlider.svg";
import {ReactComponent as ResetIcon} from "../../../assets/SurveyCommunication/ResetButton.svg";
import {OverlayContext} from "../../../layout-and-styling/context-hooks/OverlayContext";
import i18next from "i18next";
import { ComponentTooltipKnowledge } from '../../../layout-and-styling/standardized-components-library/Component-Tooltips';
import {useTranslation} from "react-i18next";

const _colors = ["#26547C", "#62A2DA"];


const ComponentSliderProcessesInvolved = (props) => {
    /** Component to select how much knowledge an employee has of the business processes */
    const context = useContext(OverlayContext);
    const [hover, setHover] = useState(false);
    const {t, i18n} = useTranslation();

    const TagSliderRef = useRef(null);

    const getPercentage = (containerWidth, distanceMoved) => {
        return (distanceMoved / containerWidth) * 100;
    };

    const limitNumberWithinRange = (value, min, max) => {
        return Math.min(Math.max(value, min), max);
    };

    const nearestN = (N, number) => Math.ceil(number / N) * N;

    const firstProcessSelected = (process, List) => {
        let i = 0
        while (i < List.length && List[i] == false) {
            ++i
        }
        return i == process
    };

    const lastProcessSelected = (process, List) => {
        let i = List.length - 1
        while (i > 0 && List[i] == false) {
            --i
        }
        return i == process
    };

    const orderIDselected = (process, List) => {
        let position = 0
        for (let i = 0; i < List.length; i++) {
            if(i == process && List[i])
                return position 
            else if(List[i])
                ++position
        }
    };

    const nextProcessSelected = (process, List) => {
        let i = process + 1
        while (i < List.length && List[i] == false) {
            ++i
        }
        return i
    };

    const numProcessesSelected = (List) => {
        let selected = 0
        for (let i = 0; i < List.length; i++) {
            if(List[i])
                ++selected
        }
        return selected
    };

    function multiSliderChange(e, current_process_object, current_process_id, processesSelected) {
        props.setSliderCheck(true);
        localStorage.setItem("slider_check", JSON.stringify(true))

        e.preventDefault();
        document.body.style.cursor = "ew-resize";

        const startDragX = e.pageX;
        const sliderWidth = TagSliderRef.current.offsetWidth;
        const resize = (e) => {
            e.preventDefault();
            const endDragX = e.touches ? e.touches[0].pageX : e.pageX;
            const distanceMoved = endDragX - startDragX;
            const maxPercent = props.widths[current_process_id] + props.widths[nextProcessSelected(current_process_id, processesSelected)];

            const percentageMoved = nearestN(1, getPercentage(sliderWidth, distanceMoved))

            const _widths = props.widths.slice();

            const prevPercentage = _widths[current_process_id];

            const newPercentage = prevPercentage + percentageMoved;

            const currentSectionWidth = limitNumberWithinRange(
                newPercentage,
                0,
                maxPercent
            );

            _widths[current_process_id] = currentSectionWidth;

            const nextSectionIndex = nextProcessSelected(current_process_id, processesSelected);

            const nextSectionNewPercentage = _widths[nextSectionIndex] - percentageMoved;

            const nextSectionWidth = limitNumberWithinRange(
                nextSectionNewPercentage,
                0,
                maxPercent
            );

            _widths[nextSectionIndex] = nextSectionWidth;

            if (current_process_object.length > 2) {
                if (_widths[current_process_id] === 0) {
                    _widths[nextSectionIndex] = maxPercent;
                    _widths.splice(current_process_id, 1);
                    removeEventListener();
                }
                if (_widths[nextSectionIndex] === 0) {
                    _widths[current_process_id] = maxPercent;
                    _widths.splice(nextSectionIndex, 1);
                    removeEventListener();
                }
            }

            props.setWidths(_widths);
            localStorage.setItem("ProcessesTimePortion", JSON.stringify(_widths));
            
            for (var i = 0; i < props.SelfRatedKnowledge.length; i++) {
                if(processesSelected[props.SelfRatedKnowledge[i].id])
                    props.SelfRatedKnowledge[i].time_portion = (_widths[i] / 100).toFixed(4);
            }

            localStorage.setItem("props.SelfRatedKnowledge", JSON.stringify(props.SelfRatedKnowledge))
        };

        window.addEventListener("pointermove", resize);
        window.addEventListener("touchmove", resize);

        const removeEventListener = () => {
            window.removeEventListener("pointermove", resize);
            window.removeEventListener("touchmove", resize);
        };

        const handleEventUp = (e) => {
            e.preventDefault();
            document.body.style.cursor = "initial";
            removeEventListener();
        };

        window.addEventListener("touchend", handleEventUp);
        window.addEventListener("pointerup", handleEventUp);                
    }

    /** TODO create a more elegant await authentication, now a simple if statement to prevent .map(null) errors */
    if (props.SelfRatedKnowledge) {
        return (
            <Container outline={!props.sliderCheck && props.nextButtonClicked ? "2px solid #F72B2B" : ""}>
                <IncompleteContentText display={!props.sliderCheck && props.nextButtonClicked ? "flex" : "none"}>
                    {t('PageKnowledge.SliderError')}
                </IncompleteContentText>
                <TopHeader>
                    <SliderHeaderText>
                        {t('PageKnowledge.SliderHeaderText')}
                    </SliderHeaderText>

                    <InfoHover onMouseOver={() => setHover(true)}
                               onMouseOut={() => setHover(false)}>?
                        {hover && (<ComponentTooltipKnowledge whichTooltip={"SliderKnowledge"}/>
                        )}
                    </InfoHover>
                </TopHeader>

                <SliderComponentWrapper ref={TagSliderRef}>
                    {props.SelfRatedKnowledge.map((current_process_object, current_process_id, all_process_objects) => {
                        if (props.ProcessesSelected[current_process_id]) { 
                            return (
                                <ProcessWrapper
                                    Width={props.widths[current_process_id] + "%"}
                                    key={current_process_id}
                                    noSliderButton={current_process_id === current_process_object.length - 1}
                                    BackgroundColor={props.sliderCheck ? _colors[orderIDselected(current_process_id, props.ProcessesSelected) % 2] : "#C6CBCB"}
                                    BorderRadiusTopLeft={firstProcessSelected(current_process_id, props.ProcessesSelected) ? "30px" : "0"}
                                    BorderRadiusTopRight={lastProcessSelected(current_process_id, props.ProcessesSelected) ? "30px" : "0"}
                                    BorderRadiusBottomLeft={firstProcessSelected(current_process_id, props.ProcessesSelected) ? "30px" : "0"}
                                    BorderRadiusBottomRight={lastProcessSelected(current_process_id, props.ProcessesSelected) ? "30px" : "0"}
                                >
                                    <SpanInformation>
                                        <SpanText>{context.setBusinessProcess(current_process_object.process_name, i18next.language)}</SpanText>
                                        <SpanPercentage>{props.widths[current_process_id].toFixed(0) + "%"}</SpanPercentage>
                                    </SpanInformation>
                                    <SliderButton
                                        id="sliderbtn" Display={lastProcessSelected(current_process_id, props.ProcessesSelected) ? "none" : "flex"}
                                        onPointerDown={(e) => multiSliderChange(e, current_process_object, current_process_id, props.ProcessesSelected)}
                                    >
                                        <ArrowSlider/>
                                    </SliderButton>
                                </ProcessWrapper>
                            )
                        }
                    })}
                </SliderComponentWrapper>

                <ResetButton>
                    <button onClick={() => {
                        if(numProcessesSelected(props.ProcessesSelected) > 1)
                            props.setSliderCheck(false);
                        var equal_widths = new Array(props.ProcessesSelected.length).fill(0)
                        for (let i = 0; i < props.ProcessesSelected.length; i++) {
                            if(props.ProcessesSelected[i])
                                equal_widths[i] = 100 / numProcessesSelected(props.ProcessesSelected)
                        }
                        props.setWidths(equal_widths)
                        localStorage.setItem("ProcessesTimePortion", JSON.stringify(equal_widths));
                        for (let i = 0; i < props.SelfRatedKnowledge.length; i++) {
                            props.SelfRatedKnowledge[i].time_portion = (equal_widths[i] / 100).toFixed(4);
                        }
                        localStorage.setItem("props.SelfRatedKnowledge", JSON.stringify(props.SelfRatedKnowledge))

                    }}>{t('PageKnowledge.ResetButton')}
                        <ResetIconWrapper>
                            <ResetIcon/>
                        </ResetIconWrapper>
                    </button>
                </ResetButton>
            </Container>
        )
    }

}
export default ComponentSliderProcessesInvolved


/** Styling */
const Container = styled.div`
  background: #FCFCFC;
  position: relative;
  margin-right: 20px;
  min-width: 1044px;
  height: 177px;
  border-radius: ${props => props.BorderRadius || '5px'};
  outline: ${props => props.outline || ''};
  outline-offset: -2px;
`;

const IncompleteContentText = styled.div`
  position: absolute;
  display: ${(props) => props.display || ""};
  margin-left: 416px;
  margin-top: 4px;
  border-radius: ${(props) => props.BorderRadius || "5px"};
  color: #F72B2B;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;

const SliderHeaderText = styled.div`
  margin-left: 20px;
  margin-top: 24px;
  width: 100%;
  height: 20px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  line-height: 40px;
`;

const SliderComponentWrapper = styled.div`
  width: calc(100% - 50px);
  min-width: fit-content;
  display: flex;
  margin: 24px 24px 0 24px;
  height: 50px;
`;

const ProcessWrapper = styled.div`
  padding: 11px;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  white-space: nowrap;
  max-height: 100px;

  //margin-top: -10px;
  width: ${props => props.Width || "20%"};
  background-color: ${props => props.BackgroundColor || "#048B67"};
  border-top-left-radius: ${props => props.BorderRadiusTopLeft || "0"};
  border-bottom-left-radius: ${props => props.BorderRadiusBottomLeft || "0"};
  border-top-right-radius: ${props => props.BorderRadiusTopRight || "0"};
  border-bottom-right-radius: ${props => props.BorderRadiusBottomRight || "0"};
  border-left-width: .1em;
  border-left-style: solid;
  border-left-color: #fcfcfc;
  border-right-width: .1em;
  border-right-style: solid;
  border-right-color: #fcfcfc;
`;
const SpanInformation = styled.div`
  color: #fcfcfc;
  font-weight: 400;
  user-select: none;
  display: block;
  overflow: hidden !important;
  font-family: 'Overpass';
  margin-top: -5px;
  //font-size: 14px;
`;

const SpanText = styled.div`
  color: #fcfcfc;
  font-weight: 400;
  user-select: none;
  display: block;
  overflow: hidden !important;
  font-family: 'Overpass';
`;

const SpanPercentage = styled.div`
  margin-top: -2px !important;
  color: #fcfcfc;
  min-width: fit-content;
  font-family: 'Overpass', sans-serif;
`;

const SliderButton = styled.div`
  width: 2em;
  height: 2em;
  background-color: #fcfcfc;
  position: absolute;
  border-radius: 2em;
  right: calc(-1.1em);
  top: 0;
  display: ${props => props.Display || "flex"};
  justify-content: center;
  align-items: center;
  bottom: 0;
  margin: auto;
  z-index: 2;
  cursor: ew-resize;
`;

const ResetButton = styled.div`
  font-family: 'Overpass';
  margin-left: 20px;
  margin-top: 16px;

  button {
    background-color: #FCFCFC;
    border: none;
    width: fit-content;
    font-size: 16px;
    font-weight: 500;
    height: 30px;
    border-radius: 5px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    cursor: pointer;
  }
`;

const ResetIconWrapper = styled.div`
  display: flex;
  float: right;
  margin-right: 6px;
  margin-left: 6px;
  transform: scaleX(-1) rotate(90deg);
`;

const InfoHover = styled.div`
  float: right;
  font-size: 18px;
  font-family: 'Overpass', sans-serif;
  font-weight: 300;
  position: relative;
  color: #FCFCFC;
  background-color: #E2336B;
  margin-right: 16px;
  margin-top: 16px;
  border: 2px solid #E2336B;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
`;

const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;