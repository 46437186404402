import axios from "axios";
import getCookieValue from "../../utils";
import {useContext, useEffect} from "react";
import {DashboardContext} from "../../dashboard/DashboardContext";
import SocialNetwork from "../../dashboard/dashboard-social-web/components/SocialNetwork";
import { OverlayContext } from "../context-hooks/OverlayContext";

// const util = require('util') // pre-condition for console.log to see all content of array without cutting off

// const util = require('util') // pre-condition for console.log to see all content of array without cutting off

/** API URL, variable dependent on what branch you ar eon, dev staging, or main branches*/
// const baseURL = process.env.REACT_APP_APIURL
const axiosInstance = axios.create({withCredentials: true});
const CurrentUserData = JSON.parse(localStorage.getItem("current_user_data"));

// Usefull Functions

function sendPageTime(page, windowReplace)
{
    const axiosInstance = axios.create({withCredentials: true});

    axiosInstance
            .post(
                process.env.REACT_APP_APIURL +
                "/api/pagetimestamp/",
                {"page": page},
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": getCookieValue("csrftoken"),
                    },
                }
            )
            .then (() => {
                window.location.replace(process.env.REACT_APP_APPURL + windowReplace);
            })
            .catch((error) => {
                console.log(error);
            });
}

function toHHMMSS(totalMinutes) {
    const minutes = totalMinutes % 60;

    const hours = Math.floor(totalMinutes / 60);

    // if no hours exist
    if (!hours) {
        // return hours as '00'
        return `00:${padTo2Digits(Math.floor(minutes))}:00`;
    } else {
        return `${padTo2Digits(hours)}:${padTo2Digits(Math.floor(minutes))}:00`;
    }
}

function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
}

const reverseFormatMainProcesses = (formattedMainProcesses) => {
    const reverseFormattedMainProcesses = [];

    const mainProcessesData = formattedMainProcesses.map((mainProcess, mainProcessIndex) => ({ 
        name: mainProcess.name, 
        order_id: mainProcessIndex
    }));

    reverseFormattedMainProcesses.push(mainProcessesData);

    let subProcessesData = [];

    formattedMainProcesses.forEach((mainProcess) => {
        if (mainProcess.subProcesses) {
            mainProcess.subProcesses.forEach((subProcess, subIndex) => {
                subProcessesData.push({
                    name: subProcess.name,
                    order_id: subIndex,
                    parent: mainProcess.name
                });
            });
        }
    });

    reverseFormattedMainProcesses.push(subProcessesData);
    return reverseFormattedMainProcesses;
};

// ----------------------------------------------------------------------------------------
// Intake api calls

/** View from builder into Representative choice page */
export async function FollowUpRepresentativePage() {
    const axiosInstance = axios.create({withCredentials: true});
    try {
        /** Get ALL employees except from the logged in user's team via API /api/teamexcludedemployees/ */
        const promise1 = await new Promise((resolve, reject) => {
            axiosInstance
                .get(
                    process.env.REACT_APP_APIURL +
                    "/api/teamexcludedemployees/")
                    .then((res)=> {
                        localStorage.setItem("intake_employee_data", JSON.stringify(res.data));
                        resolve('promise1');
                    })
                    .catch((error) => {
                        console.log( "ERROR: Could not fetch /api/teamexcludedemployees/", error );
                    })
                })
            if (promise1){
                window.location.replace(process.env.REACT_APP_APPURL + '/intake-representative-choice')
            }
    } catch (error) {
        console.log( error );
    };
}

export async function FollowUpProcessTimePage(){
    window.location.replace(process.env.REACT_APP_APPURL + '/intake-process-time')
}

/** View from Intake Representative to Home */
export async function SendRepresentativeIntake(){
    const IntakeChosenRepresentatives = JSON.parse(localStorage.getItem("RepresentativeData"))
    const axiosInstance = axios.create({withCredentials: true});
    try{
        const promise1 = await new Promise((resolve, reject) => {
            const businessProcess = JSON.parse(localStorage.getItem(`businessProcessIntake`));

            if (businessProcess !== null && businessProcess.length !== 0) {
                const reverseFormattedData = JSON.stringify(reverseFormatMainProcesses(businessProcess));
                const axiosInstance = axios.create({withCredentials: true});

                axiosInstance
                    .post(
                        process.env.REACT_APP_APIURL +
                        '/api/myintakeprocesses/update_batch/',
                        reverseFormattedData,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "X-CSRFToken": getCookieValue("csrftoken"),
                            },
                        }
                    )
                    .then (() => {
                        resolve("resolved promise1");
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        })

        if (promise1){
            const promise2 = await new Promise((resolve, reject) => {
                axiosInstance
                    .post(
                        process.env.REACT_APP_APIURL +
                        "/api/myintakeprocesses/create_intakes/",
                        IntakeChosenRepresentatives,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "X-CSRFToken": getCookieValue("csrftoken"),
                            },
                        }
                    )
                    .then((response) => {
                        resolve("resolved promise1");
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                })
                if (promise2){
                    sendPageTime("intake-representative-choice", "/intake-builder")
                }
        }
    }
    catch (error) {
        console.log(error);
    }
}

// ----------------------------------------------------------------------------------------
// Dashboard api calls

/**  View for the Dashboard Follow Up page with developer mode  */
export async function DeveloperDashboardFollowUpPage(selected_company, selected_survey) {
    const axiosInstance = axios.create({withCredentials: true});
    try {
        /** Get MyProcesses via API /api/processes/ */
        const promise1 = await new Promise((resolve, reject) => {
            axiosInstance
                .get(
                    process.env.REACT_APP_APIURL +
                    "/api/developer_dashboard_processes/" +
                    selected_company + "/" +
                    selected_survey
                )
                .then((response) => {
                    localStorage.setItem("business_process_data", JSON.stringify(response.data));
                    resolve("promise1");
                })
                .catch((error) => {
                    console.log( "ERROR: Could not fetch /api/developer_dashboard_processes/", error );
                });
        });

        /** Get MyEmployees via API /api/myemployees/,
         * use form input and tokens to login via loginURL */
        if (promise1) {
            /** Get votesData via API /api/developer_votes/ */
            const promise2 = await new Promise((resolve, reject) => {
                /** define axiosInstance for API requests */
                axiosInstance
                    .get(
                        process.env.REACT_APP_APIURL +
                        "/api/developer_dashboard_votes/" +
                        selected_company + "/" +
                        selected_survey
                    )
                    .then((res) => {
                        localStorage.setItem("votes_data", JSON.stringify(res.data));
                        resolve("promise1");
                    })
                    .catch((error) => {
                        console.log( "ERROR: Could not fetch /api/developer_dashboard_votes/", error );
                    });
            });
            if (promise2) {
                const promise3 = await new Promise((resolve, reject) => {
                    axiosInstance.get(
                        process.env.REACT_APP_APIURL + 
                        "/api/developer_dashboard_votes_teams/" +
                        selected_company + "/" +
                        selected_survey
                    )
                    .then((res) => {
                        localStorage.setItem("dashboard_votes_per_team", JSON.stringify(res.data));
                        resolve("promise4");
                    })
                    .catch((error) => {
                        console.log( "ERROR: Could not fetch /api/developer_dashboard_votes_teams/", error );
                    });
                });
                if (promise3) {
                    const promise4 = await new Promise((resolve, reject) => {
                        axiosInstance.get(
                            process.env.REACT_APP_APIURL + "/api/developer_dashboard_tags_teams/" +
                            selected_company + "/" +
                            selected_survey
                            )
                            .then((res) => {
                                localStorage.setItem("dashboard_tags_per_team", JSON.stringify(res.data));
                                resolve("promise4");
                            })
                            .catch((error) => {
                                console.log("ERROR: Could not fetch /api/developer_dashboard_tags_teams/", error )
                            });
                    });
                    if (promise4) {
                        window.dispatchEvent(new Event('elephants_data_event'))
                    }
                }
            }
        }
    } catch (err) {
        console.log(err);
    }
}



/**  View for the Dashboard Follow Up page */
export async function DashboardFollowUpPage() {
    /** Start nested Promise async sequence */
    const axiosInstance = axios.create({withCredentials: true});
    try {
        /** Get MyProcesses via API /api/processes/ */        
        const promise1 = await new Promise((resolve, reject) => {
            /** define axiosInstance for API requests */
            axiosInstance
                .get(process.env.REACT_APP_APIURL + "/api/dashboard_processes/")
                .then((res) => {
                    localStorage.setItem("selected_survey", JSON.stringify(res.data.survey));
                    localStorage.setItem("business_process_data", JSON.stringify(res.data.processes));
                    resolve("promise1");
                })
                .catch((error) => {
                    console.log("ERROR: Could not fetch /api/dashboard_processes/");
                });
        });
        /** Get MyEmployees via API /api/myemployees/,
         * use form input and tokens to login via loginURL */
        if (promise1) {
            /** Get votesData via API /api/votes/ */
            const promise2 = await new Promise((resolve, reject) => {
                /** define axiosInstance for API requests */
                axiosInstance
                    .get(process.env.REACT_APP_APIURL + "/api/dashboard_votes/")
                    .then((res) => {
                        localStorage.setItem("votes_data", JSON.stringify(res.data));
                        resolve("promise2");
                    })
                    .catch((error) => {
                        console.log("ERROR: Could not fetch /api/dashboard_votes/");
                    });
            });
            if (promise2) {
                const promise3 = await new Promise((resolve, reject) => {
                    axiosInstance.get(
                        process.env.REACT_APP_APIURL + "/api/dashboard_votes_teams/")
                        .then((res) => {
                            localStorage.setItem("dashboard_votes_per_team", JSON.stringify(res.data));
                            resolve("promise3");
                        })
                        .catch((error) => {
                            console.log("ERROR: Could not fetch /api/dashboard_votes_teams/", error )
                        });
                });
                if (promise3) {
                    const promise4 = await new Promise((resolve, reject) => {
                        axiosInstance.get(
                            process.env.REACT_APP_APIURL + "/api/dashboard_tags_teams/")
                            .then((res) => {
                                localStorage.setItem("dashboard_tags_per_team", JSON.stringify(res.data));
                                resolve("promise4");
                            })
                            .catch((error) => {
                                console.log("ERROR: Could not fetch /api/dashboard_tags_teams/", error )
                            });
                    });
                    if (promise4) {
                        window.dispatchEvent(new Event('elephants_data_event'))
                    }
                }
            }            
        }
    } catch (err) {
        console.log(err);
    }
}

/** View for checking whether they are allowed to go to the dashboard or not. */
export async function DashBoardAvailibility() {
    /** define axiosInstance for API requests */
    const axiosInstance = axios.create({withCredentials: true});

    axiosInstance
        .get(
            process.env.REACT_APP_APIURL +
            "/api/dashboard_available/"
        )
        .then((res) => {
            if(res.data == ""){
                localStorage.setItem("allow_dashboard", false);
            }
            else{localStorage.setItem("allow_dashboard", JSON.stringify(res.data));}
        })
        .catch((error) => {
            console.log("ERROR: Could not fetch /api/dashboard_availability/");
        });

}

/**  View for the Dashboard Metrics page with developer mode */
export async function DeveloperDashboardMetricsPage(selected_company, selected_survey) {
    /** define axiosInstance for API requests */
    const axiosInstance = axios.create({withCredentials: true});

    axiosInstance
        .get(
            process.env.REACT_APP_APIURL +
            "/api/developer_dashboard_metrics/" +
            selected_company + "/" +
            selected_survey
        )
        .then((res) => {
            localStorage.setItem("metrics_data", JSON.stringify(res.data));
            window.dispatchEvent(new Event('metrics_data_event'))
        })
        .catch((error) => {
            console.log("ERROR: Could not fetch /api/developer_dashboard_metrics/");
        });

}

/**  View for the Dashboard Metrics page */
export async function DashboardMetricsPage() {
    /** define axiosInstance for API requests */
    const axiosInstance = axios.create({withCredentials: true});

    axiosInstance
        .get(
            process.env.REACT_APP_APIURL +
            "/api/dashboard_metrics/"
        )
        .then((res) => {
            localStorage.setItem("metrics_data", JSON.stringify(res.data));
            window.dispatchEvent(new Event('metrics_data_event'))
        })
        .catch((error) => {
            console.log("ERROR: Could not fetch /api/dashboard_metrics/");
        });

}

/**  View for the dashboard Social Network page with developer mode */
export async function DeveloperDashboardSocialNetworkPage(selected_company, selected_survey) {
    /** define axiosInstance for API requests */
    const axiosInstance = axios.create({withCredentials: true});

    axiosInstance
        .get(
            process.env.REACT_APP_APIURL +
            "/api/developer_socialnetwork/" +
            selected_company + "/" +
            selected_survey
        )
        .then((res) => {
            localStorage.setItem("graph_data", JSON.stringify(res.data));
            window.dispatchEvent(new Event('graph_data_event'))
        })
        .catch((error) => {
            console.log("ERROR: Could not fetch /api/developer_socialnetwork/");
        });

}

/**  View for the dashboard Social Network page */
export async function DashboardSocialNetworkPage() {
    /** define axiosInstance for API requests */
    const axiosInstance = axios.create({withCredentials: true});

    axiosInstance
        .get(
            process.env.REACT_APP_APIURL + "/api/socialnetwork/"
        )
        .then((res) => {
            localStorage.setItem("graph_data", JSON.stringify(res.data));
            window.dispatchEvent(new Event('graph_data_event'))
        })
        .catch((error) => {
            console.log("ERROR: Could not fetch /api/socialnetwork/");
        });
    
}

/**  View for the Dashboard Actions page with developer mode */
export async function DeveloperDashboardActionsPage(selected_company, selected_survey) {
    /** define axiosInstance for API requests */
    const axiosInstance = axios.create({withCredentials: true});

    axiosInstance
        .get(
            process.env.REACT_APP_APIURL +
            "/api/developer_dashboard_actions/" +
            selected_company + "/" +
            selected_survey
        )
        .then((res) => {
            localStorage.setItem("actions_data", JSON.stringify(res.data));
            window.dispatchEvent(new Event('actions_data_event'))
        })
        .catch((error) => {
            console.log("ERROR: Could not fetch /api/developer_dashboard_actions/");
        });

}

/**  View for the Dashboard Actions page */
export async function DashboardActionsPage() {
    /** define axiosInstance for API requests */
    const axiosInstance = axios.create({withCredentials: true});

    axiosInstance
        .get(
            process.env.REACT_APP_APIURL +
            "/api/dashboard_actions/"
        )
        .then((res) => {
            localStorage.setItem("actions_data", JSON.stringify(res.data));
            window.dispatchEvent(new Event('actions_data_event'))
        })
        .catch((error) => {
            console.log("ERROR: Could not fetch /api/dashboard_actions/");
        });

}

/**  View for the dashboard Transformation page with developer mode */
export async function DeveloperDashboardTransformationPage(selected_company, selected_survey) {
    /** define axiosInstance for API requests */
    const axiosInstance = axios.create({withCredentials: true});

    axiosInstance
        .get(
            process.env.REACT_APP_APIURL +
            "/api/developer_dashboard_transformation/" +
            selected_company + "/" +
            selected_survey
        )
        .then((res) => {
            localStorage.setItem("transformation_data", JSON.stringify(res.data));
            window.dispatchEvent(new Event('transformation_data_event'))
        })
        .catch((error) => {
            console.log("ERROR: Could not fetch /api/developer_dashboard_transformation/");
        });

}

/**  View for the Dashboard Transformation page */
export async function DashboardTransformationPage() {
    /** define axiosInstance for API requests */
    const axiosInstance = axios.create({withCredentials: true});

    axiosInstance
        .get(
            process.env.REACT_APP_APIURL +
            "/api/dashboard_transformation/"
        )
        .then((res) => {
            localStorage.setItem("transformation_data", JSON.stringify(res.data));
            window.dispatchEvent(new Event('transformation_data_event'))
        })
        .catch((error) => {
            console.log("ERROR: Could not fetch /api/dashboard_transformation/");
        });

}

/**  View for the dashboard Highlights page with developer mode */
export async function DeveloperDashboardHighlightsPage(selected_company, selected_survey) {
    /** define axiosInstance for API requests */
    const axiosInstance = axios.create({withCredentials: true});

    axiosInstance
        .get(
            process.env.REACT_APP_APIURL +
            "/api/developer_dashboard_highlights/" +
            selected_company + "/" +
            selected_survey
        )
        .then((res) => {
            localStorage.setItem("highlights_data", JSON.stringify(res.data));
            window.dispatchEvent(new Event('highlights_data_event'))
        })
        .catch((error) => {
            console.log("ERROR: Could not fetch /api/developer_dashboard_highlights/");
        });

}

export async function DashboardOverviewPromises(setDataOverview) {
    const axiosInstance = axios.create({withCredentials: true});
    try {
        /** Get data overview via API /api/data_overview/ */
        const promise1 = await new Promise((resolve, reject) => {
            axiosInstance
                .get(process.env.REACT_APP_APIURL + "/api/data_overview/")
                .then((res) => {
                    localStorage.setItem("dashboard_analysis", JSON.stringify(res.data));
                    setDataOverview(res.data);
                })
                .catch((error) => {
                    // localStorage.setItem('dashboard_analysis', JSON.stringify("No such data for this company"))
                    console.log(error);
                });
        });
        if (promise1) {
        }
    } catch (err) {
        console.log(err);
    }
}


// ----------------------------------------------------------------------------------------
// Survey api calls

/**  UserIntro/UserBenefits To ColleagueFilter Page, API calls  */
export async function UserIntroToColleagueFilterPromises(hasBeenRequested, setHasBeenRequested, setButtonClicked) {

    /** Start nested Promise async sequence */
    const axiosInstance = axios.create({withCredentials: true});
    if (!hasBeenRequested) {
        try {
            /** Get MyProcesses via API /api/myprocesses/ */
            const promise1 = await new Promise((resolve, reject) => {
                axiosInstance
                    .get(process.env.REACT_APP_APIURL + "/api/myprocesses/")
                    .then((response) => {
                        localStorage.setItem("business_process_data", JSON.stringify(response.data));
                        resolve("promise1");
                    })
                    .catch((error) => {
                        setButtonClicked(false);
                        console.log(error);
                    });
            });

            if (promise1) {
                /** Get MySubprocesses via API /api/mysubprocesses/ */
                const promise2 = await new Promise((resolve, reject) => {
                    axiosInstance
                        .get(process.env.REACT_APP_APIURL + "/api/mysubprocesses/")
                        .then((response) => {
                            localStorage.setItem( "business_subprocess_data", JSON.stringify(response.data) );
                            resolve("promise2");
                        })
                        .catch((error) => {
                            setButtonClicked(false);
                            console.log(error);
                        });
                });

                /** Get MyEmployees via API /api/myemployees/,
                 * use form input and tokens to login via loginURL */
                if (promise2) {
                    const promise3 = await new Promise((resolve, reject) => {
                        axiosInstance
                            .get(process.env.REACT_APP_APIURL + "/api/myemployees/")
                            .then((response) => {

                                /** Setback when avatar urls are fixed via FTP*/
                                var employees = response.data

                                for (let i = 0; i < employees.length; i++) {
                                    if (employees[i].first_name === "customer" || employees[i].last_name === "supplier") {
                                        employees.splice(i, 1);
                                        i--;
                                    }
                                }

                                localStorage.setItem("employee_data", JSON.stringify(employees));
                                resolve("promise3");
                            })
                            .catch((error) => {
                                setButtonClicked(false);
                                console.log(error);
                            });
                    });
                    /** If API requests are resolved, save page time and re-direct to next page */
                    if (promise3) {
                        const EmployeeData = JSON.parse(localStorage.getItem('employee_data'));
                        var CurrentUserData = JSON.parse(localStorage.getItem('current_user_data'))
                        var EmployeeSurveyId = 0;
                        for (let i = 0; i < EmployeeData.length; i++) {
                            if (EmployeeData[i]['username'] == CurrentUserData[0]['username']) EmployeeSurveyId = EmployeeData[i]['id']
                        }

                        CurrentUserData[0].id = EmployeeSurveyId;
                        localStorage.setItem('current_user_data', JSON.stringify(CurrentUserData));

                        setHasBeenRequested(true)
                        sendPageTime("page-intro-gifs", "/survey-close-colleagues")
                    }
                }
            }
        } catch (err) {
            setButtonClicked(false);
            console.log(err);
        }
    } else {
        window.location.replace(
            process.env.REACT_APP_APPURL + "/survey-close-colleagues"
        );
    }
}

// Page Close Colleague API call
export async function PostSignificantInteractions(setButtonClicked) {
    /** Load in necessary data */
    const CurrentUserData = JSON.parse(localStorage.getItem("current_user_data"));
    const InteractionData = JSON.parse(localStorage.getItem("UpdatedInteractions"));
    const CustomerSupplierData = JSON.parse(localStorage.getItem("CustomerAndSupplierData"));
    const Data = [InteractionData, CustomerSupplierData]
    const spontaneous_checked_employees = JSON.parse(localStorage.getItem('CheckedEmployeesSpontaneousCollaboration')) ? JSON.parse(localStorage.getItem('CheckedEmployeesSpontaneousCollaboration')) : [];
      
    // initial days
    let meeting_days = [
        { id: 0, name: "Monday", name_nl: "Maandag", name_ch: "周一", selected: true, meetings: [], meetingsDuration: 0},
        { id: 1, name: "Tuesday", name_nl: "Dinsdag", name_ch: "周二", selected: false, meetings: [], meetingsDuration: 0},
        { id: 2, name: "Wednesday", name_nl: "Woensdag", name_ch: "周三", selected: false, meetings: [], meetingsDuration: 0 },
        { id: 3, name: "Thursday", name_nl: "Donderdag", name_ch: "周四", selected: false, meetings: [], meetingsDuration: 0 },
        { id: 4, name: "Friday", name_nl: "Vrijdag", name_ch: "星期五", selected: false, meetings: [], meetingsDuration: 0 },
    ];

    // initial days
    let spontaneous_days = [
        { id: 0, name: "Monday", name_nl: "Maandag", name_ch: "周一"},
        { id: 1, name: "Tuesday", name_nl: "Dinsdag", name_ch: "周二"},
        { id: 2, name: "Wednesday", name_nl: "Woensdag", name_ch: "周三"},
        { id: 3, name: "Thursday", name_nl: "Donderdag", name_ch: "周四"},
        { id: 4, name: "Friday", name_nl: "Vrijdag", name_ch: "星期五"},
    ];

    // list of the colleagues selected
    let meeting_updated_colleagues = []
    let spontaneous_updated_colleagues = []
    for (let i = 0; i < InteractionData.length; i++) {
        if(InteractionData[i].significant_interaction) {
            meeting_updated_colleagues.push(InteractionData[i].target)
            spontaneous_updated_colleagues.push(InteractionData[i].target)
        }
    }

    // getting meetingsTimes if exists
    const meetingsTimes = (JSON.parse(localStorage.getItem("meetingsTimes"))
        ? JSON.parse(localStorage.getItem("meetingsTimes"))
        : []
      );

    // getting MeetingsDays if exists
    const meetingsDays = (JSON.parse(localStorage.getItem("MeetingsDays"))
      ? JSON.parse(localStorage.getItem("MeetingsDays"))
      : meeting_days
    );

    // getting spontaneousTimes if exists
    const spontaneousTimes = (JSON.parse(localStorage.getItem("spontaneousTimes"))
        ? JSON.parse(localStorage.getItem("spontaneousTimes"))
        : []
      );

    // getting SpontaneousDays if exists
    const spontaneousDays = (JSON.parse(localStorage.getItem("SpontaneousDays"))
      ? JSON.parse(localStorage.getItem("SpontaneousDays"))
      : spontaneous_days
    );

    // updating meetingsTimes even if it exists or not
    let i = 0;
    while(i < meetingsTimes.length) {
        if(meeting_updated_colleagues.includes(meetingsTimes[i].employee)){
            meeting_updated_colleagues.splice(meeting_updated_colleagues.findIndex(e => e === meetingsTimes[i].employee), 1)
            ++i
        }
        else{
            meetingsTimes.splice(i, 1)
        }
    }
    for (let i = 0; i < meeting_updated_colleagues.length; i++) {
        meetingsTimes.push({
            employee: meeting_updated_colleagues[i], 
            average: 0,
            days: [0, 0, 0, 0, 0]
            });
    }

    // updating spontaneousTimes even if it exists or not
    i = 0;
    while(i < spontaneousTimes.length) {
        if(spontaneous_updated_colleagues.includes(spontaneousTimes[i].employee)){
            spontaneous_updated_colleagues.splice(spontaneous_updated_colleagues.findIndex(e => e === spontaneousTimes[i].employee), 1)
            ++i
        }
        else{
            if(spontaneous_checked_employees.includes(spontaneousTimes[i].employee))
                spontaneous_checked_employees.splice(spontaneous_checked_employees.findIndex(e => e === spontaneousTimes[i].employee), 1)
            spontaneousTimes.splice(i, 1)
        }
    }
    for (let i = 0; i < spontaneous_updated_colleagues.length; i++) {
        spontaneousTimes.push({
            employee: spontaneous_updated_colleagues[i], 
            average: 0,
            days: [0, 0, 0, 0, 0]
            });
    }


    /** Start nested Promise async sequence */
    const axiosInstance = axios.create({withCredentials: true});
    try {
        /** Update all existing interactions via API URL /api/myinteractions/ */
        const promise = await new Promise((resolve, reject) => {
            axiosInstance
                .post(
                    process.env.REACT_APP_APIURL + "/api/myinteractions/update_batch/",
                    Data,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-CSRFToken": getCookieValue("csrftoken"),
                        },
                    }
                )
                .then((res) => {
                    resolve("resolved promise3");
                })
                .catch((error) => {
                    setButtonClicked(false);
                    console.log(error);
                    reject("rejected promise3");
                });
        });

        /** Get and store in localStorage all the significant interactions */
        if (promise) {
            const promise2 = await new Promise((resolve, reject) => {
                axiosInstance
                    .get(
                        process.env.REACT_APP_APIURL +
                        "/api/myinteractions?significant_interaction=1" +
                        "&source=" +
                        CurrentUserData[0]["id"]
                    )
                    .then((response) => {
                        localStorage.setItem(
                            "SignificantInteractions",
                            JSON.stringify(response.data)
                        );
                        localStorage.setItem(
                            "meetingsTimes",
                            JSON.stringify(meetingsTimes)
                        );
                        localStorage.setItem(
                            "MeetingsDays",
                            JSON.stringify(meetingsDays)
                        );
                        localStorage.setItem(
                            "spontaneousTimes",
                            JSON.stringify(spontaneousTimes)
                        );
                        localStorage.setItem(
                            "SpontaneousDays",
                            JSON.stringify(spontaneousDays)
                        );
                        localStorage.setItem(
                            "CheckedEmployeesSpontaneousCollaboration",
                            JSON.stringify(spontaneous_checked_employees)
                        );
                        resolve("resolved promise4");
                    })
                    .catch((error) => {
                        setButtonClicked(false);
                        console.log(error);
                        reject("rejected promise4");
                    });
            });
            /** If API requests are resolved, save page time and re-direct to next page */
            if (promise2) {
                sendPageTime("page-close-colleagues", "/survey-planned-meetings-question")
            }
        }
    } catch (err) {
        setButtonClicked(false);
        console.log(err);
    }
}

// Page Planned Meetings API call
export async function UpdateMeetingHours(setButtonClicked) {
    localStorage.setItem("hours_check", "true")
    /** Function to prepare and send the data from the meetings page to the spontaneous collaboration page  */
    const MeetingsDays = JSON.parse(localStorage.getItem("MeetingsDays"));
    const SignificantInteractions = JSON.parse( localStorage.getItem("SignificantInteractions"));
    const meetingsTimes = [];
    const HrsDataForAPI = [];

    // Initializing the desired data structures
    for (let i = 0; i < SignificantInteractions.length; i++) {
        HrsDataForAPI.push({
            weekly_duration: 0,
            source: SignificantInteractions[i]["source_survey"],
            target: SignificantInteractions[i]["target_survey"],
        });
        meetingsTimes.push({
            employee: SignificantInteractions[i]["target_survey"],
            average: 0,
            days: []
        });
        for (let day = 0; day < MeetingsDays.length; day++) {
            meetingsTimes[i]["days"].push(0)
        }
    }
    
    // Filling the data structures
    for (let day = 0; day < MeetingsDays.length; day++) {
        if (MeetingsDays[day]["meetings"].length > 0) {
            for (let meet = 0; meet < MeetingsDays[day]["meetings"].length; meet++) {
                const significant_meeting_members = MeetingsDays[day]["meetings"][meet]["Teams"]
                    .flatMap((arr) => arr.team_members)
                    .filter((member) => member.checked);
                const checked_significant_team_members =
                    significant_meeting_members.length;

                const meeting_average_minutes =
                    MeetingsDays[day]["meetings"][meet]["duration"] /
                    checked_significant_team_members;

                for (let i = 0; i < meetingsTimes.length; i++) {
                    for (let m = 0; m < significant_meeting_members.length; m++) {
                        if (meetingsTimes[i]["employee"] === significant_meeting_members[m]["id"]) {
                            meetingsTimes[i]["days"][day] += meeting_average_minutes;
                        }
                    }
                }
            }
        }
    }

    for (let i = 0; i < meetingsTimes.length; i++) {
        let average = 0
        for (let j = 0; j < meetingsTimes[i]["days"].length; j++) {
            average += meetingsTimes[i]["days"][j]
        }
        meetingsTimes[i]["average"] = average/meetingsTimes[i]["days"].length
        HrsDataForAPI[i]["weekly_duration"] = average;
    }

    localStorage.setItem("meetingsTimes", JSON.stringify(meetingsTimes))

    /** In case the spontaneous times already exists (so the used went back) we update them */
    let spontaneousTimes = JSON.parse(localStorage.getItem("spontaneousTimes")) 
      ? JSON.parse(localStorage.getItem("spontaneousTimes")) 
      : []
    let SpontaneousDays = JSON.parse(localStorage.getItem("SpontaneousDays")) 
      ? JSON.parse(localStorage.getItem("SpontaneousDays")) 
      : []

    if (spontaneousTimes.length > 0 && SpontaneousDays.length > 0) {
        /** In case in the number of days in the meetings and in the spontaneous are different we adjust the sizes of the arrays keeping only the days that have interaction */
        let lenMeetingsDays = meetingsTimes[0]["days"].length
        let lenSpontaneousDays = spontaneousTimes[0]["days"].length

        if (lenMeetingsDays > lenSpontaneousDays) {
            for (let i = 0; i < lenMeetingsDays - lenSpontaneousDays; i++) {
                let is_meeting = false
                for (let j = 0; j < meetingsTimes.length; j++) if (meetingsTimes[j]["days"][5+i] > 0) is_meeting = true
                if (is_meeting) {
                SpontaneousDays.push({ id: SpontaneousDays.length + i, name: "Extra", name_nl: "Extra", name_ch: "额外的"})
                for (let j = 0; j < spontaneousTimes.length; j++) spontaneousTimes[j]["days"].push(0)
                }
            }
        } else if (lenMeetingsDays < lenSpontaneousDays) {
            for (let i = 0; i < lenSpontaneousDays - lenMeetingsDays; i++) {
                let is_interaction = false
                for (let j = 0; j < spontaneousTimes.length; j++) if (spontaneousTimes[j]["days"][5+i] > 0) is_interaction = true
                if (is_interaction) {
                SpontaneousDays.push({ id: SpontaneousDays.length + i, name: "Extra", name_nl: "Extra", name_ch: "额外的"})
                } else {
                for (let j = 0; j < spontaneousTimes.length; j++) spontaneousTimes[j]["days"].splice(5+i, 1)
                }
            }
        }   
        localStorage.setItem("spontaneousTimes", JSON.stringify(spontaneousTimes))
        localStorage.setItem("SpontaneousDays", JSON.stringify(SpontaneousDays))
    }
    
    const axiosInstance = axios.create({withCredentials: true});
    try {
        /** Get interactions via API /api/myinteractions/update_batch/ */
        const promise1 = await new Promise((resolve, reject) => {
            axiosInstance
                .post(
                    process.env.REACT_APP_APIURL + "/api/myinteractions/update_batch/",
                    HrsDataForAPI,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-CSRFToken": getCookieValue("csrftoken"),
                        },
                    }
                )
                .then((res) => {
                    resolve("resolved promise1");
                })
                .catch((error) => {
                    setButtonClicked(false)
                    console.log(error);
                });
        });
        /** If API requests are resolved, save page time and re-direct to next page */
        if (promise1) {
            sendPageTime("page-planned-meetings", "/survey-spontaneous-collaboration")
        }
    } catch (err) {
        console.log(err);
    }

}

// Page Spontaneous Interactions API call
export async function UpdateSpontaneousHours(setButtonClicked) {
    /** Function to save the interaction times filled in in the meetings and the spontaneous interactions pages  */
    const meetingsTimes = JSON.parse(localStorage.getItem("meetingsTimes"));
    const spontaneousTimes = JSON.parse(localStorage.getItem("spontaneousTimes"));
    const SignificantInteractions = JSON.parse( localStorage.getItem("SignificantInteractions"));
    const HrsDataForAPI = [];

    // Initializing the desired data structures
    for (let i = 0; i < SignificantInteractions.length; i++) {
        HrsDataForAPI.push({
            weekly_duration: 0,
            source: SignificantInteractions[i]["source_survey"],
            target: SignificantInteractions[i]["target_survey"],
        });
    }
    
    // Filling the data structures with the meetings times
    for (let i = 0; i < meetingsTimes.length; i++) {
        HrsDataForAPI.filter((int) => int["target"] === meetingsTimes[i]["employee"])[0]["weekly_duration"] += meetingsTimes[i]["average"] * meetingsTimes[i]["days"].length;
    }

    // Filling the data structures with the spontaneous times
    for (let i = 0; i < spontaneousTimes.length; i++) {
        HrsDataForAPI.filter((int) => int["target"] === spontaneousTimes[i]["employee"])[0]["weekly_duration"] += spontaneousTimes[i]["average"] * spontaneousTimes[i]["days"].length;
    }
    
    const axiosInstance = axios.create({withCredentials: true});
    try {
        /** Get interactions via API /api/myinteractions/update_batch/ */
        const promise1 = await new Promise((resolve, reject) => {
            axiosInstance
                .post(
                    process.env.REACT_APP_APIURL + "/api/myinteractions/update_batch/",
                    HrsDataForAPI,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-CSRFToken": getCookieValue("csrftoken"),
                        },
                    }
                )
                .then((res) => {
                    resolve("resolved promise1");
                })
                .catch((error) => {
                    setButtonClicked(false)
                    console.log(error);
                });
        });
        /** If API requests are resolved, save page time and re-direct to next page */
        if (promise1) {
            sendPageTime("page-spontaneous-collaboration", "/survey-collaboration-measurement")
        }
    } catch (err) {
        console.log(err);
    }
}

// Page Collaboration Quality API call
export async function UpdateCollaborationQuality(setButtonClicked) {
    /** Function to check for new Collab Input for api/interactions,  */
    const QualityData = JSON.parse(localStorage.getItem("ArrayQuality"));
    const QualityDataAPI = [];

    for (let i = 0; i < QualityData.length; i++) {
        QualityDataAPI.push({
            impact: parseInt(QualityData[i].impact),
            pleasantness: parseInt(QualityData[i].pleasantness),
            source: QualityData[i].source,
            target: QualityData[i].target,
        });
    }

    /** Start nested Promise async sequence */
    const axiosInstance = axios.create({withCredentials: true});
    try {
        const promise1 = await new Promise((resolve, reject) => {
            axiosInstance
                .post(
                    process.env.REACT_APP_APIURL + "/api/myinteractions/update_batch/",
                    QualityDataAPI,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-CSRFToken": getCookieValue("csrftoken"),
                        },
                    }
                )
                .then((res) => {
                    resolve("resolved promise1");
                })

                .catch((error) => {
                    setButtonClicked(false);
                    console.log(error);
                });
        });
        /** If API requests are resolved, save page time and re-direct to next page */
        if (promise1) {
            sendPageTime("page-collaboration-measurement", "/survey-driving-force")
        }
    } catch (err) {
        setButtonClicked(false);
        console.log(err);
    }
}

// Page Proactiveness API call
export async function UpdateProactiveness(setButtonClicked) {
    let QualityData = JSON.parse(localStorage.getItem("ArrayQuality"));

    const QualityDataAPI = [];

    /** Start nested Promise async sequence */
    const axiosInstance = axios.create({withCredentials: true});

    for (var i = 0; i < QualityData.length; i++) {
        var initiative = QualityData[i].initiative;
        var dm = QualityData[i].decision_making;
        var results = QualityData[i].finish_line;
        var calculatedScore = (initiative + dm + results) / 3

        QualityDataAPI[i] = {
            "target": QualityData[i].target,
            // scoreSum doesn't seem to be saved in any way, what is it's purpose?
            "scoreSum": calculatedScore,
            "initiative": initiative,
            "decision_making": dm,
            "results": results,
            "source": CurrentUserData[0].id
        }
    }

    try {
        /** Get Initiative via API /api/myinitiative/update_batch/ */
        const promise1 = await new Promise((resolve, _reject) => {
            axiosInstance
                .post(process.env.REACT_APP_APIURL + "/api/myinitiative/update_batch/", QualityDataAPI, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": getCookieValue("csrftoken"),
                    },
                })
                .then((res) => {
                    resolve("resolved promise1");
                })

                .catch((error) => {
                    setButtonClicked(false);
                    console.log(error);
                });
        });
        /** If API requests are resolved, save page time and re-direct to next page */
        if (promise1) {
            sendPageTime("page-proactiveness", "/survey-knowledge")
        }
    } catch (err) {
        setButtonClicked(false);
        console.log(err);
    }
}

// Page Process Involvment and Knowledge API call
export async function UpdateKnowledge(setButtonClicked) {
    const IndividualImpact = JSON.parse(
        localStorage.getItem("individual_impact_score")
    );
    const KnowledgeAndTimeRatios = JSON.parse(
        localStorage.getItem("SelfRatedKnowledge")
    );

    /**Initialize and Populate every entry knowledge per business process and time_portion for the current user*/
    const KnowledgeAndTimeRatiosArray = [];

    for (let i = 0; i < KnowledgeAndTimeRatios.length; i++) {
        KnowledgeAndTimeRatiosArray.push({
            employee: CurrentUserData[0].id,
            process: KnowledgeAndTimeRatios[i].process_id,
            time_portion: KnowledgeAndTimeRatios[i].time_portion,
            self_rated_knowledge: parseInt(KnowledgeAndTimeRatios[i].self_rated_knowledge),
        });
    }


    /** Populate overall impact self survey for the current user */
    const IndividualImpactObject = {
        individual_impact: parseInt(IndividualImpact),
        employee_survey_id: CurrentUserData[0].id,
    };
    /** Do POST request for KnowledgeAndTimeRatiosArray and PUT request for IndividualImpactObject */
    try {
        /** POST request for KnowledgeAndTimeRatiosArray  */
        const promise1 = await new Promise((resolve, reject) => {
            axiosInstance
                .post(
                    process.env.REACT_APP_APIURL +
                    "/api/processefficacysurvey/batch_update/",
                    KnowledgeAndTimeRatiosArray,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-CSRFToken": getCookieValue("csrftoken"),
                        },
                    }
                )
                .then((res) => {
                    resolve("resolved promise1");
                })
                .catch((error) => {
                    setButtonClicked(false);
                    console.log(error);
                });
        });
        if (promise1) {
            /** PUT request for IndividualImpactObject  */
            const promise2 = await new Promise((resolve, reject) => {
                axiosInstance
                    .put(
                        process.env.REACT_APP_APIURL +
                        "/api/myimpact/" +
                        CurrentUserData[0].id +
                        "/",
                        IndividualImpactObject,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "X-CSRFToken": getCookieValue("csrftoken"),
                            },
                        }
                    )
                    .then((res) => {
                        resolve("resolved promise1");
                    })
                    .catch((error) => {
                        setButtonClicked(false);
                        console.log(error);
                    });
            });
            /** If API requests are resolved, save page time and re-direct to next page */
            if (promise2) {
                sendPageTime("page-individual-impact", "/survey-culture")
            }
        }
    } catch (err) {
        setButtonClicked(false);
        console.log(err);
    }
}

// Page Culture API call
export async function UpdateCulture(setButtonClicked) {
    /** Function to check for new Collab Input for api/interactions,  */
    const CultureData = JSON.parse(localStorage.getItem("ArrayCultureApi"));
    const CultureDataAPI = [];

    for (let i = 0; i < CultureData.length; i++) {
        CultureDataAPI.push({
            communication: parseInt(CultureData[i]["communication"]),
            deciding: parseInt(CultureData[i]["deciding"]),
            disagreeing: parseInt(CultureData[i]["disagreeing"]),
            evaluating: parseInt(CultureData[i]["evaluating"]),
            leading: parseInt(CultureData[i]["leading"]),
            scheduling: parseInt(CultureData[i]["scheduling"]),
            trust: parseInt(CultureData[i]["trust"]),
            source: CultureData[i].source,
            target: CultureData[i].target,
        });
    }
    /** Start nested Promise async sequence */
    const axiosInstance = axios.create({withCredentials: true});
    try {
        const promise1 = await new Promise((resolve, reject) => {
            axiosInstance
                .post(
                    process.env.REACT_APP_APIURL + "/api/myinteractions/update_batch/",
                    CultureDataAPI,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-CSRFToken": getCookieValue("csrftoken"),
                        },
                    }
                )
                .then((res) => {
                    resolve("resolved promise1");
                })

                .catch((error) => {
                    setButtonClicked(false);
                    console.log(error);
                    // reject('reject promise1')
                });
        });
        /** If API requests are resolved, save page time and re-direct to next page */
        if (promise1) {
            if(JSON.parse(localStorage.getItem("ArrayCultureApi"))) {
                sendPageTime("page-culture", "/survey-business-process-selection-feedback-optimal") 
            }
           /* if(JSON.parse(localStorage.getItem("cultureSelectedPeople") == null)){
                sendPageTime("page-culture", "/survey-business-process-selection-feedback-optimal")
        }  else {
            sendPageTime("page-culture", "/survey-culture-peer")
        }*/
        }
    } catch (err) {
            setButtonClicked(false);
            console.log(err);}
}

// Page Peer Culture API call
export async function UpdateCulturePeer(setButtonClicked) {
    /** Function to check for new Collab Input for api/interactions,  */
    const CultureData = JSON.parse(localStorage.getItem("ArrayCultureApi"));
    const CultureDataAPI = [];

    for (let i = 0; i < CultureData.length; i++) {
        CultureDataAPI.push({
            communication: parseInt(CultureData[i]["communication"]),
            deciding: parseInt(CultureData[i]["deciding"]),
            disagreeing: parseInt(CultureData[i]["disagreeing"]),
            evaluating: parseInt(CultureData[i]["evaluating"]),
            leading: parseInt(CultureData[i]["leading"]),
            scheduling: parseInt(CultureData[i]["scheduling"]),
            trust: parseInt(CultureData[i]["trust"]),
            source: CultureData[i].source,
            target: CultureData[i].target,
        });
    }

    /** Start nested Promise async sequence */
    const axiosInstance = axios.create({withCredentials: true});
    try {
        const promise1 = await new Promise((resolve, reject) => {
            axiosInstance
                .post(
                    process.env.REACT_APP_APIURL + "/api/myinteractions/update_batch/",
                    CultureDataAPI,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-CSRFToken": getCookieValue("csrftoken"),
                        },
                    }
                )
                .then((res) => {
                    resolve("resolved promise1");
                })

                .catch((error) => {
                    setButtonClicked(false);
                    console.log(error);
                    // reject('reject promise1')
                });
        });
        /** If API requests are resolved, save page time and re-direct to next page */
        if (promise1) {
            sendPageTime("page-culture-peer", "/survey-business-process-selection-feedback-optimal")
        }
    } catch (err) {
            setButtonClicked(false);
            console.log(err);}
}

// Page Individual Satisfaction API call
export async function UpdateIndividualSatisfaction(event) {
    /** Get from localstorage SatisfactionfactorsAndPortions and IndividualSatisfaction */
    const SatisfactionfactorsAndPortions = JSON.parse(
        localStorage.getItem("IndividualSatisfaction")
    );
    const OverallSatisfaction = JSON.parse(
        localStorage.getItem("IndividualSatisfactionScore")
    );

    /** Initialize and Populate Satisfaction factors and it's portions for the current user */
    const SatisfactionfactorsAndPortionsArray = [];
    for (let i = 0; i < SatisfactionfactorsAndPortions.length; i++) {
        SatisfactionfactorsAndPortionsArray.push({
            score: parseInt(SatisfactionfactorsAndPortions[i]["satisfaction"]),
            weight: SatisfactionfactorsAndPortions[i].magnitude,
            employee: CurrentUserData[0]["user"],
            satisfaction: SatisfactionfactorsAndPortions[i]["id"],
        });
    }

    /** Populate overall satisfaction self survey for the current user */
    const OverallSatisfactionObject = {
        overall_satisfaction: parseInt(OverallSatisfaction),
    };
    /** TODO: In front end component seems values go from 1 to 5, not the agreed -2 to 2. Quick fix pl0x */
    /** Do POST request for SatisfactionfactorsAndPortions and PUT request for IndividualSatisfactionObject */
    const axiosInstance = axios.create({withCredentials: true});

    try {
        /** Do POST request for SatisfactionfactorsAndPortions  */
        const promise1 = await new Promise((resolve, reject) => {
            axiosInstance
                .post(
                    process.env.REACT_APP_APIURL +
                    "/api/employeesatisfactionsurvey/batch_update/",
                    SatisfactionfactorsAndPortionsArray,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "X-CSRFToken": getCookieValue("csrftoken"),
                        },
                    }
                )
                .then((response) => {
                    resolve("promise2");
                })
                .catch((error) => {
                    console.log(error);
                });
        });
        if (promise1) {
            /** PUT request for IndividualSatisfactionObject */
            const promise2 = await new Promise((resolve, reject) => {
                axiosInstance
                    .put(
                        process.env.REACT_APP_APIURL +
                        "/api/mysatisfaction/" +
                        CurrentUserData[0]["user"] +
                        "/",
                        OverallSatisfactionObject,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "X-CSRFToken": getCookieValue("csrftoken"),
                            },
                        }
                    )
                    .then((response) => {
                        resolve("promise2");
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            });
            /** If API requests are resolved, save page time and re-direct to next page */
            if (promise2) {
                window.location.replace(
                    process.env.REACT_APP_APPURL + "/survey-collaboration-measurement"
                );
            }
        }
    } catch (err) {
        console.log(err);
    }
}

// Page Good Feedback API call
export async function UpdateFeedbackOptimal(setButtonClicked) {
    const axiosInstance = axios.create({withCredentials: true});

    const votes = JSON.parse(localStorage.getItem("optimalFeedbackAllAnswers"));
    const data = [CurrentUserData[0].id, 'HL', votes]

    try {
        const promise1 = await new Promise((resolve, _reject) => {
            axiosInstance
                .post(process.env.REACT_APP_APIURL + "/api/myfeedbacks/update_batch/", data, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": getCookieValue("csrftoken"),
                    },
                })
                .then((res) => {
                    resolve("resolved promise1");
                })

                .catch((error) => {
                    setButtonClicked(false);
                    console.log(error);
                });
        });
        /** If API requests are resolved, save page time and re-direct to next page */
        if (promise1) {
            sendPageTime("page-good-feedback", "/survey-business-process-selection-feedback-improvement")
        }
    } catch (err) {
        setButtonClicked(false);
        console.log(err);
    }
}

// Page Bad Feedback API call
export async function UpdateFeedbackImprovement(setButtonClicked) {
    const axiosInstance = axios.create({withCredentials: true});

    const votes = JSON.parse(localStorage.getItem("answersImprovementFeedback"));
    const data = [CurrentUserData[0].id, 'LL', votes]

    try {
        const promise1 = await new Promise((resolve, _reject) => {
            axiosInstance
                .post(process.env.REACT_APP_APIURL + "/api/myfeedbacks/update_batch/", data, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": getCookieValue("csrftoken"),
                    },
                })
                .then((res) => {
                    resolve("resolved promise1");
                })

                .catch((error) => {
                    setButtonClicked(false);
                    console.log(error);
                });
        });
        /** If API requests are resolved, save page time and re-direct to next page */
        if (promise1) {
            sendPageTime("page-bad-feedback", "/survey-completed")
        }
    } catch (err) {
        setButtonClicked(false);
        console.log(err);
    }
}

// Page Rootcause Chatbot API call
export async function UpdateRootcauseChatbot(setButtonClicked){
    const axiosInstance = axios.create({withCredentials: true});
    const data = JSON.parse(localStorage.getItem('chatbot_translated_messages'));
    let subprocesses = [];
    //Get subprocesses from the messages
    for(let i=0; i<data.length; i++){
        if(!subprocesses.includes(data[i][3]) && data[i][3] !== "None"){
            subprocesses.push(data[i][3]);
        }
    }

    let processedData = [];
    for(let j=0; j<subprocesses.length; j++){
        const currentsbp = subprocesses[j];
        let set = [];
        for(let i=0; i<data.length; i++){
            //Skip this message if it is not part of the current subprocess
            if(currentsbp !== data[i][3]){
                continue;
            }
            let stage = "Introduction";
            //First two chatbot messages are hardcoded and have no stage yet, so they get their own stage here
            if(i>1){
                stage = data[i][4];
            }
            const messagedata = {
                "message": data[i][0],
                "ownerisbot": data[i][1],
                "ordernumber": data[i][2],
                "stage": stage,
            }
            set = [...set, messagedata];
        }
        processedData = [...processedData, [currentsbp.trim().replace(/^"|"$/g, ''), set]];
    }

    try{
        const promise1 = await new Promise((resolve, _reject) => {
            axiosInstance.post(process.env.REACT_APP_APIURL + "/api/mychatbotsurvey/update_batch/", processedData, {
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": getCookieValue("csrftoken"),
                },
            })
            .then((res) => {
                resolve("resolved promise1");
            })
            .catch((error) => {
                setButtonClicked(false);
                console.log(error);
            });
        });
        /** If API requests are resolved, save page time and re-direct to next page */
        if(promise1){ 
            sendPageTime("page-rootcause-chatbot", "/survey-completed");
        }
    }
    catch(error){
        console.log(error);
        setButtonClicked(false);
    }
}

// Page Logout API call
export const handleLogout = (e) => {
    const axiosInstance = axios.create({withCredentials: true});
    // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
    axiosInstance
        .get(process.env.REACT_APP_APIURL + "/api/auth/logout/")
        .then((res) => {
            localStorage.clear();
            sessionStorage.clear();
            window.location.replace(process.env.REACT_APP_APPURL + "/login");
        });
};
