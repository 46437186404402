import React, {useContext} from 'react';
import styled from '@emotion/styled';
import { OverlayContext } from "../../../layout-and-styling/context-hooks/OverlayContext";
import botImage from '../page-specific-images/botImage.png';


export default function ComponentMessage(props){
    let alignment = "right";
    let color = "#D0E1F1";
    if(props.owner){
        alignment = "left";
        color = "#F1F3F4";
    }

    const currentUser = JSON.parse(localStorage.getItem("current_user_data"))[0];
    const context = useContext(OverlayContext);

    return(
        <Container>
                <AvatarContainer>
                {(props.owner && props.genAvatar) && <Avatar>
                    <img src={botImage} style={{width: '48px', height: '48px'}} ></img>
                </Avatar>
                }
                </AvatarContainer>
            <MessageContainer>
                <Message style={{backgroundColor: color}}>
                    {props.message}
                </Message>
            </MessageContainer>
            {(!props.owner && props.genAvatar) &&
                <AvatarContainer>
                    <Avatar>
                        {context.setFirstName(currentUser.first_name, 0).charAt(0)
                        + context.setFirstName(currentUser.last_name, 0).split(" ").filter((str) => str !== '').pop().charAt(0)}
                    </Avatar>
                </AvatarContainer>
            }
        </Container>
    )
}

const MessageContainer = styled.div`
margin-left: 16px;
margin-top: 8px;
margin-bottom: 8px;
margin-right: 16px;
width: fit-content;
max-width: 500px;
`;

const AvatarContainer = styled.div`
width: 92px;
justify-content: center;
display: flex;
align-items: center; 
`;

const Container = styled.div`
display: flex;
`;

const Message = styled.div`
border-radius: 8px;
padding: 12px;
font-family: overpass;
font-size: 14px;
line-height: 22px;
`;

const Avatar = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: #D0E1F1;
  border-radius: 100px;
  justify-content: center;
  color: #000000;
  font-family: overpass;
  font-size: 20px;
  margin: 0px;
`;