import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { OverlayContext } from '../../../../layout-and-styling/context-hooks/OverlayContext';


const ElephantContainer = ({ problem }) => {

    const context = useContext(OverlayContext);
    const { t, i18n } = useTranslation();


    const { key, name, businessProcess, color } = problem
    if (key && name && businessProcess && color) {
        return (
            <ElephantWrapper>
                <ElephantKey color={color}>{key}</ElephantKey>
                <ElephantDetails>
                    {/* translate the text based on language choice */}
                    <ElephantName color={color}>{context.setBusinessProcess(name, i18n.language)}</ElephantName>
                    <ElephantProcess>{t('Transformation.PartOf')}: {context.setBusinessProcess(businessProcess, i18n.language)}</ElephantProcess>
                </ElephantDetails>
            </ElephantWrapper>
        )
    } else {
        return (<></>)
    }
}

export default ElephantContainer

const ElephantWrapper = styled.div`
    display: flex;
    gap: 12px;
    margin-block: 7px;
`;

const ElephantKey = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Overpass";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    border-radius: 50%;
    background-color: ${props => props.color};
    color: white;
    width: 11px;
    height: 11px;
    padding: 5px;
`;

const ElephantDetails = styled.div`
`;

const ElephantName = styled.div`
    color: ${props => props.color};
    font-family: "Overpass";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
`;

const ElephantProcess = styled.div`
    color: #929699;
    font-family: "Overpass";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;