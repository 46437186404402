import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import { DashboardContext } from '../../dashboard/DashboardContext';
import {StyledDivRegular} from './Styling-TextInput';
import logo from '../../assets/dashboard/load.gif'

import {ReactComponent as IconAnalysisRedirect} from "../../assets/dashboard/IconAnalysisRedirect.svg"
import {OverlayContext} from "../context-hooks/OverlayContext";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { DashboardOverviewPromises} from "./Component-API";
import { red } from '@mui/material/colors';
import {Trans, useTranslation} from 'react-i18next';

const CurrentUserData = JSON.parse(localStorage.getItem('current_user_data'));

const ComponentTopBotProcesses = (props) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [statusAnalysis, setStatusAnalysis] = useState(JSON.parse(localStorage.getItem("dashboard_analysis")))
    const dashboard_context_value = useContext(DashboardContext);
    const overlay_context = useContext(OverlayContext);
    const {t, i18n} = useTranslation();

    const selectedCompany = JSON.parse(localStorage.getItem("selected_company"));

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        fontFamily: "Overpass",
        fontSize: 24,
        textAlign: "center",
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        // window.location.replace(process.env.REACT_APP_APPURL + '/dashboard-analysis')
        // if (statusAnalysis === "No such data for this company")
        //     handleOpen();
        if (dashboard_context_value.openAnalysis === true) {
            dashboard_context_value.setCurrentTabSelection('TabParametersOverview');
        }

    }, [dashboard_context_value.dataOverview]);

    function onClickAnalysis(subprocess) {
        if(dashboard_context_value.openGraph){
            props.show(subprocess);
            dashboard_context_value.setSubProcessSelected(subprocess);
        }
        else{
            DashboardOverviewPromises(dashboard_context_value.setDataOverview);
            dashboard_context_value.setOpenAnalysis(true);
            dashboard_context_value.setSubProcessSelected(subprocess);
        }
    }



    // Venice hardcoded data
    if(CurrentUserData[0].company === 4 || selectedCompany.id === 4) {
        if (props.OverviewType === 'Optimal') {
            return (
                <>
                    <AnalyticsWrapper MarginLeft={props.MarginLeftParent}>

                        <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width="410px" Height="108px">

                            <StyledDivRegular MarginTop="10px" MarginLeft="14px" Background='#048B67' Width='24px'
                                            Height='22px' FontWeight='700' Color='#FCFCFC' BorderRadius='5px'
                                            Display='flex' Padding='2px 0px 0px 0px'>
                                1
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="14px" MarginLeft="46px" Color='#271B36' Width='fit-content'
                                            Height='20px'>
                                {props.topProcessesArray[0] ? overlay_context.setProcess(props.topProcessesArray[0].subprocess, i18n.language) : ""}
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="10px" MarginLeft="316px" Width='82px' Height='24px' Display='flex'
                                            Gap='0px 4px'
                                            Background='#26547C' Color='#FCFCFC' Cursor='pointer' FontSize='10px'
                                            FontWeight='600' JustifyContent='flex-start'
                                            onClick={() => onClickAnalysis(props.topProcessesArray[0] ? props.topProcessesArray[0] : "")}>

                                <StyledDivRegular MarginTop="-4px" MarginLeft="4px" Width='40px' Height='14px'
                                                Color='#FCFCFC' FontSize='14px' FontWeight='500'>
                                    {dashboard_context_value.subProcessSelected.subprocess === props.topProcessesArray[0].subprocess && dashboard_context_value.openAnalysis ?
                                        <LoadLogo> <img src={logo} alt="loading..."/></LoadLogo>
                                        : t('Elephant.Analysis')}
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="62px" Width='16px' Height='16px'>
                                    <IconAnalysisRedirect/>
                                </StyledDivRegular>

                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="39px" MarginLeft="46px" Width='352px' Height='24px' Display='flex'
                                            JustifyContent='flex-start' FlexWrap='wrap' Gap='10px 10px' TextAlign='left'>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                Width='fit-content' Height='fit-content'
                                                FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                Display='flex' Padding='3px 10px'>
                                    Competence level
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                Width='fit-content' Height='fit-content'
                                                FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                Display='flex' Padding='3px 10px'>
                                    Prioritization & Planning
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                Width='fit-content' Height='fit-content'
                                                FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                Display='flex' Padding='3px 10px'>
                                    Product Quality
                                </StyledDivRegular>

                            </StyledDivRegular>

                        </StyledDivRegular>

                    </AnalyticsWrapper>

                    <AnalyticsWrapper MarginLeft={props.MarginLeftParent} MarginTop='196px'>

                        <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width="410px" Height="108px">

                            <StyledDivRegular MarginTop="10px" MarginLeft="14px" Background='#06D6A0' Width='24px'
                                            Height='22px' FontWeight='700' Color='#FCFCFC' BorderRadius='5px'
                                            Display='flex' Padding='2px 0px 0px 0px'>
                                2
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="14px" MarginLeft="46px" Color='#271B36' Width='fit-content'
                                            Height='20px'>
                                {props.topProcessesArray[1] ? overlay_context.setProcess(props.topProcessesArray[1].subprocess, i18n.language) : ""}
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="10px" MarginLeft="316px" Width='82px' Height='24px' Display='flex'
                                            Gap='0px 4px'
                                            Background='#26547C' Color='#FCFCFC' Cursor='pointer' FontSize='10px'
                                            FontWeight='600' JustifyContent='flex-start'
                                            onClick={() => onClickAnalysis(props.topProcessesArray[0] ? props.topProcessesArray[1] : "")}>

                                <StyledDivRegular MarginTop="-4px" MarginLeft="4px" Width='40px' Height='14px'
                                                Color='#FCFCFC' FontSize='14px' FontWeight='500'>
                                    {dashboard_context_value.subProcessSelected.subprocess === props.topProcessesArray[1].subprocess && dashboard_context_value.openAnalysis ?
                                        <LoadLogo> <img src={logo} alt="loading..."/></LoadLogo>
                                        : t('Elephant.Analysis')}
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="62px" Width='16px' Height='16px'>
                                    <IconAnalysisRedirect/>
                                </StyledDivRegular>

                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="39px" MarginLeft="46px" Width='352px' Height='24px' Display='flex'
                                            JustifyContent='flex-start' FlexWrap='wrap' Gap='10px 10px' TextAlign='left'>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                Width='fit-content' Height='fit-content'
                                                FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                Display='flex' Padding='3px 10px'>
                                    Supply Chain Management
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                Width='fit-content' Height='fit-content'
                                                FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                Display='flex' Padding='3px 10px'>
                                    Responsibility & Role Clarity
                                </StyledDivRegular>

                            </StyledDivRegular>

                        </StyledDivRegular>

                    </AnalyticsWrapper>


                    <AnalyticsWrapper MarginLeft={props.MarginLeftParent} MarginTop='324px'>

                        <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width="410px" Height="108px">

                            <StyledDivRegular MarginTop="10px" MarginLeft="14px" Background='#A9D7CB' Width='24px'
                                            Height='22px' FontWeight='700' Color='#FCFCFC' BorderRadius='5px'
                                            Display='flex' Padding='2px 0px 0px 0px'>
                                3
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="14px" MarginLeft="46px" Color='#271B36' Width='fit-content' toilet
                                            Height='20px'>
                                {props.topProcessesArray[2] ? overlay_context.setProcess(props.topProcessesArray[2].subprocess, i18n.language) : ""}
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="10px" MarginLeft="316px" Width='82px' Height='24px' Display='flex'
                                            Gap='0px 4px'
                                            Background='#26547C' Color='#FCFCFC' Cursor='pointer' FontSize='10px'
                                            FontWeight='600' JustifyContent='flex-start'
                                            onClick={() => onClickAnalysis(props.topProcessesArray[2])}>

                                <StyledDivRegular MarginTop="-4px" MarginLeft="4px" Width='40px' Height='14px'
                                                Color='#FCFCFC' FontSize='14px' FontWeight='500'>
                                    {dashboard_context_value.subProcessSelected.subprocess === props.topProcessesArray[2].subprocess && dashboard_context_value.openAnalysis ?
                                        <LoadLogo> <img src={logo} alt="loading..."/></LoadLogo>
                                        : t('Elephant.Analysis')}
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="62px" Width='16px' Height='16px'>
                                    <IconAnalysisRedirect/>
                                </StyledDivRegular>

                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="39px" MarginLeft="46px" Width='352px' Height='24px' Display='flex'
                                            JustifyContent='flex-start' FlexWrap='wrap' Gap='10px 10px' TextAlign='left'>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                Width='fit-content' Height='fit-content'
                                                FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                Display='flex' Padding='3px 10px'>
                                    Marketing
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                Width='fit-content' Height='fit-content'
                                                FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                Display='flex' Padding='3px 10px'>
                                    Prioritization & Planning
                                </StyledDivRegular>

                            </StyledDivRegular>

                        </StyledDivRegular>

                    </AnalyticsWrapper>


                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            There is no such data for this company!
                        </Box>
                    </Modal>
                </>
            )

        }
        if (props.OverviewType === 'Improvement') {
            return (
                <>
                    <AnalyticsWrapper MarginLeft={props.MarginLeftParent}>

                        <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width="410px" Height="108px">

                            <StyledDivRegular MarginTop="10px" MarginLeft="14px" Background='#B3194A' Width='24px'
                                            Height='22px' FontWeight='700' Color='#FCFCFC' BorderRadius='5px'
                                            Display='flex' Padding='2px 0px 0px 0px'>
                                1
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="14px" MarginLeft="46px" Color='#271B36' Width='fit-content'
                                            Height='20px'>
                                {props.botProcessesArray[0] ? overlay_context.setProcess(props.botProcessesArray[0].subprocess, i18n.language) : ""}
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="10px" MarginLeft="316px" Width='82px' Height='24px' Display='flex'
                                            Gap='0px 4px'
                                            Background='#26547C' Color='#FCFCFC' Cursor='pointer' FontSize='10px'
                                            FontWeight='600' JustifyContent='flex-start'
                                            onClick={() => onClickAnalysis(props.botProcessesArray[0])}>

                                <StyledDivRegular MarginTop="-4px" MarginLeft="4px" Width='40px' Height='14px'
                                                Color='#FCFCFC' FontSize='14px' FontWeight='500'>
                                    {dashboard_context_value.subProcessSelected.subprocess === props.botProcessesArray[0].subprocess && dashboard_context_value.openAnalysis ?
                                        <LoadLogo> <img src={logo} alt="loading..."/></LoadLogo>
                                        : t('Elephant.Analysis')}
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="62px" Width='16px' Height='16px'>
                                    <IconAnalysisRedirect/>
                                </StyledDivRegular>

                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="39px" MarginLeft="46px" Width='352px' Height='24px' Display='flex'
                                            JustifyContent='flex-start' FlexWrap='wrap' Gap='10px 10px' TextAlign='left'>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                Width='fit-content' Height='fit-content'
                                                FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                Display='flex' Padding='3px 10px'>
                                    Responsibility & Role Clarity
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                Width='fit-content' Height='fit-content'
                                                FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                Display='flex' Padding='3px 10px'>
                                    Prioritization & Planning
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                Width='fit-content' Height='fit-content'
                                                FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                Display='flex' Padding='3px 10px'>
                                    Sales
                                </StyledDivRegular>

                            </StyledDivRegular>

                        </StyledDivRegular>

                    </AnalyticsWrapper>

                    <AnalyticsWrapper MarginLeft={props.MarginLeftParent} MarginTop='196px'>

                        <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width="410px" Height="108px">

                            <StyledDivRegular MarginTop="10px" MarginLeft="14px" Background='#E2236B' Width='24px'
                                            Height='22px' FontWeight='700' Color='#FCFCFC' BorderRadius='5px'
                                            Display='flex' Padding='2px 0px 0px 0px'>
                                2
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="14px" MarginLeft="46px" Color='#271B36' Width='fit-content'
                                            Height='20px'>
                                {props.botProcessesArray[1] ? overlay_context.setProcess(props.botProcessesArray[1].subprocess, i18n.language) : ""}
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="10px" MarginLeft="316px" Width='82px' Height='24px' Display='flex'
                                            Gap='0px 4px'
                                            Background='#26547C' Color='#FCFCFC' Cursor='pointer' FontSize='10px'
                                            FontWeight='600' JustifyContent='flex-start'
                                            onClick={() => onClickAnalysis(props.botProcessesArray[1])}>

                                <StyledDivRegular MarginTop="-4px" MarginLeft="4px" Width='40px' Height='14px'
                                                Color='#FCFCFC' FontSize='14px' FontWeight='500'>
                                    {dashboard_context_value.subProcessSelected.subprocess === props.botProcessesArray[1].subprocess && dashboard_context_value.openAnalysis ?
                                        <LoadLogo> <img src={logo} alt="loading..."/></LoadLogo>
                                        : t('Elephant.Analysis')}
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="62px" Width='16px' Height='16px'>
                                    <IconAnalysisRedirect/>
                                </StyledDivRegular>

                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="39px" MarginLeft="46px" Width='352px' Height='24px' Display='flex'
                                            JustifyContent='flex-start' FlexWrap='wrap' Gap='10px 10px' TextAlign='left'>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                Width='fit-content' Height='fit-content'
                                                FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                Display='flex' Padding='3px 10px'>
                                    Specification & Design
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                Width='fit-content' Height='fit-content'
                                                FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                Display='flex' Padding='3px 10px'>
                                    Product Quality
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                Width='fit-content' Height='fit-content'
                                                FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                Display='flex' Padding='3px 10px'>
                                    Prioritization & Planning
                                </StyledDivRegular>

                            </StyledDivRegular>

                        </StyledDivRegular>

                    </AnalyticsWrapper>


                    <AnalyticsWrapper MarginLeft={props.MarginLeftParent} MarginTop='324px'>

                        <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width="410px" Height="108px">

                            <StyledDivRegular MarginTop="10px" MarginLeft="14px" Background='#F7C9D9' Width='24px'
                                            Height='22px' FontWeight='700' Color='#FCFCFC' BorderRadius='5px'
                                            Display='flex' Padding='2px 0px 0px 0px'>
                                3
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="14px" MarginLeft="46px" Color='#271B36' Width='fit-content'
                                            Height='20px'>
                                {props.botProcessesArray[2] ? overlay_context.setProcess(props.botProcessesArray[2].subprocess, i18n.language) : ""}
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="10px" MarginLeft="316px" Width='82px' Height='24px' Display='flex'
                                            Gap='0px 4px'
                                            Background='#26547C' Color='#FCFCFC' Cursor='pointer' FontSize='10px'
                                            FontWeight='600' JustifyContent='flex-start'
                                            onClick={() => onClickAnalysis(props.botProcessesArray ? props.botProcessesArray[2] : 0)}>

                                <StyledDivRegular MarginTop="-4px" MarginLeft="4px" Width='40px' Height='14px'
                                                Color='#FCFCFC' FontSize='14px' FontWeight='500'>
                                    {dashboard_context_value.subProcessSelected.subprocess === props.botProcessesArray[2].subprocess && dashboard_context_value.openAnalysis ?
                                        <LoadLogo> <img src={logo} alt="loading..."/></LoadLogo>
                                        : t('Elephant.Analysis')}
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="62px" Width='16px' Height='16px'>
                                    <IconAnalysisRedirect/>
                                </StyledDivRegular>

                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="39px" MarginLeft="46px" Width='352px' Height='24px' Display='flex'
                                            JustifyContent='flex-start' FlexWrap='wrap' Gap='10px 10px' TextAlign='left'>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                Width='fit-content' Height='fit-content'
                                                FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                Display='flex' Padding='3px 10px'>
                                    Prioritization & Planning
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                Width='fit-content' Height='fit-content'
                                                FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                Display='flex' Padding='3px 10px'>
                                    Workload & Capacity
                                </StyledDivRegular>

                            </StyledDivRegular>

                        </StyledDivRegular>

                    </AnalyticsWrapper>


                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            There is no such data for this company!
                        </Box>
                    </Modal>
                </>

            )
        }
    }

    // Avocado hardcoded data
    if(CurrentUserData[0].company === 6 || selectedCompany.id === 6){
        if (props.OverviewType === 'Optimal') {
            return (
                <>
                    <AnalyticsWrapper MarginLeft={props.MarginLeftParent}>

                        <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width="410px" Height="108px">

                            <StyledDivRegular MarginTop="10px" MarginLeft="14px" Background='#048B67' Width='24px'
                                              Height='22px' FontWeight='700' Color='#FCFCFC' BorderRadius='5px'
                                              Display='flex' Padding='2px 0px 0px 0px'>
                                1
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="14px" MarginLeft="46px" Color='#271B36' Width='fit-content'
                                              Height='20px'>
                                {props.topProcessesArray[0] ? overlay_context.setProcess(props.topProcessesArray[0].subprocess, i18n.language) : ""}
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="10px" MarginLeft="316px" Width='82px' Height='24px' Display='flex'
                                              Gap='0px 4px'
                                              Background='#26547C' Color='#FCFCFC' Cursor='pointer' FontSize='10px'
                                              FontWeight='600' JustifyContent='flex-start'
                                              onClick={() => onClickAnalysis(props.topProcessesArray[0] ? props.topProcessesArray[0] : "")}>

                                <StyledDivRegular MarginTop="-4px" MarginLeft="4px" Width='40px' Height='14px'
                                                Color='#FCFCFC' FontSize='14px' FontWeight='500'>
                                    {dashboard_context_value.subProcessSelected.subprocess === props.topProcessesArray[0].subprocess && dashboard_context_value.openAnalysis ?
                                        <LoadLogo> <img src={logo} alt="loading..."/></LoadLogo>
                                        : t('Elephant.Analysis')}
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="62px" Width='16px' Height='16px'>
                                    <IconAnalysisRedirect/>
                                </StyledDivRegular>

                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="39px" MarginLeft="46px" Width='352px' Height='24px' Display='flex'
                                              JustifyContent='flex-start' FlexWrap='wrap' Gap='10px 10px' TextAlign='left'>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                  Width='fit-content' Height='fit-content'
                                                  FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                  Display='flex' Padding='3px 10px'>
                                    Specification & Design
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                  Width='fit-content' Height='fit-content'
                                                  FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                  Display='flex' Padding='3px 10px'>
                                    Customer Feedback
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                  Width='fit-content' Height='fit-content'
                                                  FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                  Display='flex' Padding='3px 10px'>
                                    Standardization & Automation
                                </StyledDivRegular>

                            </StyledDivRegular>

                        </StyledDivRegular>

                    </AnalyticsWrapper>

                    <AnalyticsWrapper MarginLeft={props.MarginLeftParent} MarginTop='196px'>

                        <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width="410px" Height="108px">

                            <StyledDivRegular MarginTop="10px" MarginLeft="14px" Background='#06D6A0' Width='24px'
                                              Height='22px' FontWeight='700' Color='#FCFCFC' BorderRadius='5px'
                                              Display='flex' Padding='2px 0px 0px 0px'>
                                2
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="14px" MarginLeft="46px" Color='#271B36' Width='fit-content'
                                              Height='20px'>
                                {props.topProcessesArray[1] ? overlay_context.setProcess(props.topProcessesArray[1].subprocess, i18n.language) : ""}
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="10px" MarginLeft="316px" Width='82px' Height='24px' Display='flex'
                                              Gap='0px 4px'
                                              Background='#26547C' Color='#FCFCFC' Cursor='pointer' FontSize='10px'
                                              FontWeight='600' JustifyContent='flex-start'
                                              onClick={() => onClickAnalysis(props.topProcessesArray[0] ? props.topProcessesArray[1] : "")}>

                                <StyledDivRegular MarginTop="-4px" MarginLeft="4px" Width='40px' Height='14px'
                                                  Color='#FCFCFC' FontSize='14px' FontWeight='500'>
                                    {dashboard_context_value.subProcessSelected.subprocess === props.topProcessesArray[1].subprocess && dashboard_context_value.openAnalysis ?
                                        <LoadLogo> <img src={logo} alt="loading..."/></LoadLogo>
                                        : t('Elephant.Analysis')}
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="62px" Width='16px' Height='16px'>
                                    <IconAnalysisRedirect/>
                                </StyledDivRegular>

                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="39px" MarginLeft="46px" Width='352px' Height='24px' Display='flex'
                                              JustifyContent='flex-start' FlexWrap='wrap' Gap='10px 10px' TextAlign='left'>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                  Width='fit-content' Height='fit-content'
                                                  FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                  Display='flex' Padding='3px 10px'>
                                    Standardization & Automation
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                  Width='fit-content' Height='fit-content'
                                                  FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                  Display='flex' Padding='3px 10px'>
                                    Supply chain management
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                  Width='fit-content' Height='fit-content'
                                                  FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                  Display='flex' Padding='3px 10px'>
                                    Competence level
                                </StyledDivRegular>

                            </StyledDivRegular>

                        </StyledDivRegular>

                    </AnalyticsWrapper>


                    <AnalyticsWrapper MarginLeft={props.MarginLeftParent} MarginTop='324px'>

                        <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width="410px" Height="108px">

                            <StyledDivRegular MarginTop="10px" MarginLeft="14px" Background='#A9D7CB' Width='24px'
                                              Height='22px' FontWeight='700' Color='#FCFCFC' BorderRadius='5px'
                                              Display='flex' Padding='2px 0px 0px 0px'>
                                3
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="14px" MarginLeft="46px" Color='#271B36' Width='fit-content' toilet
                                              Height='20px'>
                                {props.topProcessesArray[2] ? overlay_context.setProcess(props.topProcessesArray[2].subprocess, i18n.language) : ""}
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="10px" MarginLeft="316px" Width='82px' Height='24px' Display='flex'
                                              Gap='0px 4px'
                                              Background='#26547C' Color='#FCFCFC' Cursor='pointer' FontSize='10px'
                                              FontWeight='600' JustifyContent='flex-start'
                                              onClick={() => onClickAnalysis(props.topProcessesArray[2])}>

                                <StyledDivRegular MarginTop="-4px" MarginLeft="4px" Width='40px' Height='14px'
                                                  Color='#FCFCFC' FontSize='14px' FontWeight='500'>
                                    {dashboard_context_value.subProcessSelected.subprocess === props.topProcessesArray[2].subprocess && dashboard_context_value.openAnalysis ?
                                        <LoadLogo> <img src={logo} alt="loading..."/></LoadLogo>
                                        : t('Elephant.Analysis')}
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="62px" Width='16px' Height='16px'>
                                    <IconAnalysisRedirect/>
                                </StyledDivRegular>

                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="39px" MarginLeft="46px" Width='352px' Height='24px' Display='flex'
                                              JustifyContent='flex-start' FlexWrap='wrap' Gap='10px 10px' TextAlign='left'>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                  Width='fit-content' Height='fit-content'
                                                  FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                  Display='flex' Padding='3px 10px'>
                                    Specification & Design
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                  Width='fit-content' Height='fit-content'
                                                  FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                  Display='flex' Padding='3px 10px'>
                                    Competence level
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                  Width='fit-content' Height='fit-content'
                                                  FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                  Display='flex' Padding='3px 10px'>
                                    Standardization & Automation
                                </StyledDivRegular>

                            </StyledDivRegular>

                        </StyledDivRegular>

                    </AnalyticsWrapper>


                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            There is no such data for this company!
                        </Box>
                    </Modal>
                </>
            )

        }
        if (props.OverviewType === 'Improvement') {
            return (
                <>
                    <AnalyticsWrapper MarginLeft={props.MarginLeftParent}>

                        <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width="410px" Height="108px">

                            <StyledDivRegular MarginTop="10px" MarginLeft="14px" Background='#B3194A' Width='24px'
                                              Height='22px' FontWeight='700' Color='#FCFCFC' BorderRadius='5px'
                                              Display='flex' Padding='2px 0px 0px 0px'>
                                1
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="14px" MarginLeft="46px" Color='#271B36' Width='fit-content'
                                              Height='20px'>
                                {props.botProcessesArray[0] ? overlay_context.setProcess(props.botProcessesArray[0].subprocess, i18n.language) : ""}
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="10px" MarginLeft="316px" Width='82px' Height='24px' Display='flex'
                                        Gap='0px 4px'
                                        Background='#26547C' Color='#FCFCFC' Cursor='pointer' FontSize='10px'
                                        FontWeight='600' JustifyContent='flex-start'
                                        onClick={() => onClickAnalysis(props.botProcessesArray[0])}>

                                <StyledDivRegular MarginTop="-4px" MarginLeft="4px" Width='40px' Height='14px'
                                                Color='#FCFCFC' FontSize='14px' FontWeight='500'>
                                    {dashboard_context_value.subProcessSelected.subprocess === props.botProcessesArray[0].subprocess && dashboard_context_value.openAnalysis ?
                                        <LoadLogo> <img src={logo} alt="loading..."/></LoadLogo>
                                        : t('Elephant.Analysis')}
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="62px" Width='16px' Height='16px'>
                                    <IconAnalysisRedirect/>
                                </StyledDivRegular>

                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="39px" MarginLeft="46px" Width='352px' Height='24px' Display='flex'
                                              JustifyContent='flex-start' FlexWrap='wrap' Gap='10px 10px' TextAlign='left'>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                  Width='fit-content' Height='fit-content'
                                                  FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                  Display='flex' Padding='3px 10px'>
                                    Standardization & Automation
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                  Width='fit-content' Height='fit-content'
                                                  FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                  Display='flex' Padding='3px 10px'>
                                    Customer feedback
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                  Width='fit-content' Height='fit-content'
                                                  FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                  Display='flex' Padding='3px 10px'>
                                    Alignment & Meetings
                                </StyledDivRegular>

                            </StyledDivRegular>

                        </StyledDivRegular>

                    </AnalyticsWrapper>

                    <AnalyticsWrapper MarginLeft={props.MarginLeftParent} MarginTop='196px'>

                        <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width="410px" Height="108px">

                            <StyledDivRegular MarginTop="10px" MarginLeft="14px" Background='#E2236B' Width='24px'
                                              Height='22px' FontWeight='700' Color='#FCFCFC' BorderRadius='5px'
                                              Display='flex' Padding='2px 0px 0px 0px'>
                                2
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="14px" MarginLeft="46px" Color='#271B36' Width='fit-content'
                                              Height='20px'>
                                {props.botProcessesArray[1] ? overlay_context.setProcess(props.botProcessesArray[1].subprocess, i18n.language) : ""}
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="10px" MarginLeft="316px" Width='82px' Height='24px' Display='flex'
                                              Gap='0px 4px'
                                              Background='#26547C' Color='#FCFCFC' Cursor='pointer' FontSize='10px'
                                              FontWeight='600' JustifyContent='flex-start'
                                              onClick={() => onClickAnalysis(props.botProcessesArray[1])}>

                                <StyledDivRegular MarginTop="-4px" MarginLeft="4px" Width='40px' Height='14px'
                                                  Color='#FCFCFC' FontSize='14px' FontWeight='500'>
                                    {dashboard_context_value.subProcessSelected.subprocess === props.botProcessesArray[1].subprocess && dashboard_context_value.openAnalysis ?
                                        <LoadLogo> <img src={logo} alt="loading..."/></LoadLogo>
                                        : t('Elephant.Analysis')}
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="62px" Width='16px' Height='16px'>
                                    <IconAnalysisRedirect/>
                                </StyledDivRegular>

                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="39px" MarginLeft="46px" Width='352px' Height='24px' Display='flex'
                                              JustifyContent='flex-start' FlexWrap='wrap' Gap='10px 10px' TextAlign='left'>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                  Width='fit-content' Height='fit-content'
                                                  FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                  Display='flex' Padding='3px 10px'>
                                    Specification & Design
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                  Width='fit-content' Height='fit-content'
                                                  FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                  Display='flex' Padding='3px 10px'>
                                    Standardization & Automation
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                  Width='fit-content' Height='fit-content'
                                                  FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                  Display='flex' Padding='3px 10px'>
                                    Customer feedback
                                </StyledDivRegular>

                            </StyledDivRegular>

                        </StyledDivRegular>

                    </AnalyticsWrapper>


                    <AnalyticsWrapper MarginLeft={props.MarginLeftParent} MarginTop='324px'>

                        <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width="410px" Height="108px">

                            <StyledDivRegular MarginTop="10px" MarginLeft="14px" Background='#F7C9D9' Width='24px'
                                              Height='22px' FontWeight='700' Color='#FCFCFC' BorderRadius='5px'
                                              Display='flex' Padding='2px 0px 0px 0px'>
                                3
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="14px" MarginLeft="46px" Color='#271B36' Width='fit-content'
                                              Height='20px'>
                                {props.botProcessesArray[2] ? overlay_context.setProcess(props.botProcessesArray[2].subprocess, i18n.language) : ""}
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="10px" MarginLeft="316px" Width='82px' Height='24px' Display='flex'
                                              Gap='0px 4px'
                                              Background='#26547C' Color='#FCFCFC' Cursor='pointer' FontSize='10px'
                                              FontWeight='600' JustifyContent='flex-start'
                                              onClick={() => onClickAnalysis(props.botProcessesArray ? props.botProcessesArray[2] : 0)}>

                                <StyledDivRegular MarginTop="-4px" MarginLeft="4px" Width='40px' Height='14px'
                                                  Color='#FCFCFC' FontSize='14px' FontWeight='500'>
                                    {dashboard_context_value.subProcessSelected.subprocess === props.botProcessesArray[2].subprocess && dashboard_context_value.openAnalysis ?
                                        <LoadLogo> <img src={logo} alt="loading..."/></LoadLogo>
                                        : t('Elephant.Analysis')}
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="62px" Width='16px' Height='16px'>
                                    <IconAnalysisRedirect/>
                                </StyledDivRegular>

                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="39px" MarginLeft="46px" Width='352px' Height='24px' Display='flex'
                                              JustifyContent='flex-start' FlexWrap='wrap' Gap='10px 10px' TextAlign='left'>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                  Width='fit-content' Height='fit-content'
                                                  FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                  Display='flex' Padding='3px 10px'>
                                    Standardization & Automation
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                  Width='fit-content' Height='fit-content'
                                                  FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                  Display='flex' Padding='3px 10px'>
                                    Customer feedback
                                </StyledDivRegular>

                                <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1'
                                                  Width='fit-content' Height='fit-content'
                                                  FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px'
                                                  Display='flex' Padding='3px 10px'>
                                    Alignment & Meetings
                                </StyledDivRegular>

                            </StyledDivRegular>

                        </StyledDivRegular>

                    </AnalyticsWrapper>


                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            There is no such data for this company!
                        </Box>
                    </Modal>
                </>

            )
        }
    }
}
export default ComponentTopBotProcesses

const AnalyticsWrapper = styled.div`
  position: fixed;
  margin-top: ${props => props.MarginTop || "68px"};
  margin-left: ${props => props.MarginLeft || "339px"};
  width: ${props => props.Width || "410px"};
  height: ${props => props.Height || "108px"};
  background-color: #F1F3F4; 
  z-index: 5;

  button {
    position: fixed;
    margin-top: 208px;
    margin-left: 20px;
    width: ${props => props.Width || "210px"};
    height: ${props => props.Height || "48px"};
    border: ${props => props.Border || "none"};
    border-radius: ${props => props.BorderRadius || "5px"};
    background-color: ${props => props.BackgroundColor || "#FCFCFC"};
    color: ${props => props.Color || "#e2336B"};
    font-size: ${props => props.FontSize || "16px"};
    font-weight: ${props => props.FontWeight || "400"};
    font-family: ${props => props.FontFamily || "Overpass"};
    cursor: ${props => props.Cursor || "pointer"};
    transition: all 0.2s ease-in;
    align-items: flex-start;
  }
`;

const LoadLogo = styled.div`
  img {
    width: 18px;

  }

  margin-left: 20px
`;