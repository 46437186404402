import {createContext, useState} from "react";

export const OverlayContext = createContext();

export function OverlayContextProvider(props) {
    const [header, setHeader] = useState(false);
    const [footer, setFooter] = useState(false);
    const [daysComponent, setDaysComponent] = useState(2);
    const [colleagueComponent, setColleagueComponent] = useState(2);
    const [businessProcessInvolvedComponent, setBusinessProcessInvolvedComponent] = useState(2);
    const [teamPersonFilterComponent, setTeamPersonFilterComponent] = useState(2);
    const [qualityComponent, setQualityComponent] = useState(2);
    const [subProcessComponent, setSubProcessComponent] = useState(2);
    const [feedbackComponent, setFeedbackComponent] = useState(2);
    const [subProcessBadComponent, setSubProcessBadComponent] = useState(2);
    const [feedbackBadComponent, setFeedbackBadComponent] = useState(2);
    const [likertKnowledgeComponent, setLikertKnowledgeComponent] = useState(2);
    const [likertSatisfactionComponent, setLikertSatisfactionComponent] = useState(2);
    const [sliderProcessesComponent, setSliderProcesses] = useState(2);
    const [categoriesComponent, setCategoriesComponent] = useState(2);
    const [meetingsComponent, setMeetingsComponent] = useState(2);
    const [sliderComponent, setSliderComponent] = useState(2);
    const [interactionsComponent, setInteractionsComponent] = useState(2);
    const [individualImpactComponent, setIndividualImpactComponent] = useState(2);
    const [individualSatisfactionComponent, setIndividualSatisfactionComponent] = useState(2);
    const [collaborationColleaguesComponent, setCollaborationColleaguesComponent] = useState(2);
    const [collaborationNextColleagueComponent, setCollaborationNextColleagueComponent] = useState(2);
    const [sharingReceivingComponent, setSharingReceivingComponent] = useState(2);
    const [collaborationSlidersComponent, setCollaborationSlidersComponent] = useState(2);
    const [decisionMakingProcessesComponent, setDecisionMakingProcessesComponent] = useState(2);
    const [decisionMakingColleaguesComponent, setDecisionMakingColleaguesComponent] = useState(2);
    const [decisionMakingSliderComponent, setDecisionMakingSliderComponent] = useState(2);

    const setBusinessProcess = (value, language) => {
        if (value.includes("/")) {
            if (language === "ch-CH") return value.split('/')[1]
            else return value.substring(0, value.indexOf('/'))
        } else return value;
    }

    const setNames = (first_name, last_name, language) => {
        let first = "";
        let last = "";

        if (first_name.includes("/")) {
            if (language === "ch-CH") first = first_name.split('/')[1]
            else first = first_name.substring(0, first_name.indexOf('/') - 1)
        } else return first_name + " " + last_name;

        if (last_name.includes("/")) {
            if (language === "ch-CH") last = last_name.split('/')[1]
            else last = last_name.substring(0, last_name.indexOf('/') - 1)
        } else return first_name + " " + last_name;

        if (language === "ch-CH") return last + first;
        else return first + " " + last;

    }

    const setFirstName = (value, language) => {
        if (value.includes("/")) {
            if (language === "ch-CH") return value.split('/')[1]
            else return value.substring(0, value.indexOf('/') - 1)
        } else return value;
    }

    const setTeams = (value, language) => {
        if (value.includes("/")) {
            if (language === "ch-CH") return value.split('/')[1]
            else return value.substring(0, value.indexOf('/'))
        } else return value;
    }

    const setProcess = (value, language) => {
        if (value.includes("/")) {
            if (language === "ch-CH") return value.split('/')[1]
            else return value.substring(0, value.indexOf('/'))
        } else return value;
    }

    const toggleBusinessProcessInvolvedComponent = (e) => {
        setBusinessProcessInvolvedComponent(e);
    }

    const toggleOverlay = (e) => {
        setHeader(!header);
        setFooter(!footer);
    }

    const toggleTeamPersonFilterComponent = (e) => {
        setTeamPersonFilterComponent(e);
    }

    const toggleDaysComponent = (e) => {
        setDaysComponent(e);
    }

    const toggleColleagueComponent = (e) => {
        setColleagueComponent(e);
    }

    const toggleQualityComponent = (e) => {
        setQualityComponent(e);
    }

    const toggleCategories = (e) => {
        setCategoriesComponent(e);
    }

    const toggleMeetings = (e) => {
        setMeetingsComponent(e);
    }

    const toggleSlider = (e) => {
        setSliderComponent(e);
    }

    const toggleInteractions = (e) => {
        setInteractionsComponent(e);
    }

    const toggleSubProcessComponent = (e) => {
        setSubProcessComponent(e);
    }

    const toggleFeedbackComponent = (e) => {
        setFeedbackComponent(e);
    }

    const toggleSubProcessBadComponent = (e) => {
        setSubProcessBadComponent(e);
    }

    const toggleFeedbackBadComponent = (e) => {
        setFeedbackBadComponent(e);
    }

    const toggleLikertKnowledgeComponent = (e) => {
        setLikertKnowledgeComponent(e);
    }

    const toggleSliderProcessesComponent = (e) => {
        setSliderProcesses(e);
    }

    const toggleIndividualImpact = (e) => {
        setIndividualImpactComponent(e);
    }

    const toggleIndividualSatisfaction = (e) => {
        setIndividualSatisfactionComponent(e);
    }

    const toggleLikertSatisfactionComponent = (e) => {
        setLikertSatisfactionComponent(e);
    }

    const toggleCollaborationColleaguesComponent = (e) => {
        setCollaborationColleaguesComponent(e);
    }

    const toggleSharingReceivingComponent = (e) => {
        setSharingReceivingComponent(e);
    }

    const toggleCollaborationSlidersComponent = (e) => {
        setCollaborationSlidersComponent(e);
    }

    const toggleDecisionMakingProcessesComponent = (e) => {
        setDecisionMakingProcessesComponent(e);
    }

    const toggleDecisionMakingColleaguesComponent = (e) => {
        setDecisionMakingColleaguesComponent(e);
    }

    const toggleDecisionMakingSliderComponent = (e) => {
        setDecisionMakingSliderComponent(e);
    }

    const toggleCollaborationNextColleagueComponent = (e) => {
        setCollaborationNextColleagueComponent(e);
    }

    return (
        <OverlayContext.Provider
            value={{
                header,
                footer,
                daysComponent,
                colleagueComponent,
                qualityComponent,
                subProcessComponent,
                feedbackComponent,
                businessProcessInvolvedComponent,
                teamPersonFilterComponent,
                subProcessBadComponent,
                feedbackBadComponent,
                likertKnowledgeComponent,
                sliderProcessesComponent,
                likertSatisfactionComponent,
                categoriesComponent,
                meetingsComponent,
                sliderComponent,
                interactionsComponent,
                individualImpactComponent,
                individualSatisfactionComponent,
                collaborationColleaguesComponent,
                sharingReceivingComponent,
                collaborationSlidersComponent,
                collaborationNextColleagueComponent,
                decisionMakingProcessesComponent,
                decisionMakingColleaguesComponent,
                decisionMakingSliderComponent,
                toggleOverlay,
                toggleColleagueComponent,
                toggleDaysComponent,
                toggleQualityComponent,
                toggleSubProcessComponent,
                toggleFeedbackComponent,
                toggleTeamPersonFilterComponent,
                toggleBusinessProcessInvolvedComponent,
                toggleSubProcessBadComponent,
                toggleFeedbackBadComponent,
                toggleLikertKnowledgeComponent,
                toggleSliderProcessesComponent,
                toggleLikertSatisfactionComponent,
                toggleCategories,
                toggleMeetings,
                toggleSlider,
                toggleInteractions,
                toggleIndividualImpact,
                toggleIndividualSatisfaction,
                toggleCollaborationColleaguesComponent,
                toggleSharingReceivingComponent,
                toggleCollaborationSlidersComponent,
                toggleDecisionMakingProcessesComponent,
                toggleDecisionMakingColleaguesComponent,
                toggleDecisionMakingSliderComponent,
                toggleCollaborationNextColleagueComponent,
                setBusinessProcess,
                setNames,
                setTeams,
                setProcess,
                setFirstName,
            }}>
            {props.children}
        </OverlayContext.Provider>
    );
};