import React, { useEffect, useState, useContext} from 'react';
import axios from 'axios';
import styled from "styled-components";

import { StyledDivBold, StyledDivRegular } from '../layout-and-styling/standardized-components-library/Styling-TextInput';
import IntroductionTimeComponent from './intake-builder/page-specific-components/IntroductionTimeComponent';
import { Trans, useTranslation } from "react-i18next";
import i18n from "../i18n";
import { useWindowSize } from '../layout-and-styling/standardized-components-library/Component-Responsive';
import { ComponentTooltipProcessTime } from '../layout-and-styling/standardized-components-library/Component-Tooltips';
import MappedSubProcesses from './MappedSubProcesses';
import {ReactComponent as IconCheckCircleFill} from '../assets/intake/IconCheckCircleFill.svg';
import ComponentHeaderBlock from '../layout-and-styling/standardized-components-library/Component-header-block';
import { ReactComponent as Pink1 } from "../assets/intake/Pink1.svg";
import { ReactComponent as Grey2 } from "../assets/intake/Grey2.svg";
import { ReactComponent as Pink2 } from "../assets/intake/Pink2.svg";
import { ReactComponent as Grey1 } from "../assets/intake/Grey1.svg";
import { ReactComponent as NextPinkButtonEN } from "../assets/intake/NextPinkButtonEN.svg";
import { ReactComponent as NextPinkButtonNL } from "../assets/intake/NextPinkButtonNL.svg";
import { ReactComponent as NextPinkButtonCH } from "../assets/intake/NextPinkButtonCH.svg";
import { ReactComponent as BackPinkButtonEN } from "../assets/intake/BackPinkButtonEN.svg";
import { ReactComponent as BackPinkButtonNL } from "../assets/intake/BackPinkButtonNL.svg";
import { ReactComponent as BackPinkButtonCH } from "../assets/intake/BackPinkButtonCH.svg";
import { ReactComponent as BackGreyButtonEN } from "../assets/intake/BackGreyButtonEN.svg";
import { ReactComponent as BackGreyButtonNL } from "../assets/intake/BackGreyButtonNL.svg";
import { ReactComponent as BackGreyButtonCH } from "../assets/intake/BackGreyButtonCH.svg";
import { ReactComponent as Invalid} from "../assets/intake/Invalid.svg";
import { ReactComponent as GreenCheckCircle } from "../assets/intake/GreenCheckCircle.svg";
import ImageNotFound from '../assets/intake/ImageNotFound.png';
import getCookieValue from "../utils";


// const CurrentUserData = JSON.parse(localStorage.getItem('current_user_data'));
const ProcessData = JSON.parse(localStorage.getItem('businessProcessIntake'));

const PageProcessTime = () => {
/** Page to measure the time it takes a company to run through their business process */

    const { t } = useTranslation();

    // const context = useContext(OverlayContext);

    useEffect(() => {
        const axiosInstance = axios.create({withCredentials: true})

        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
            .catch(() => {
                console.log('error, you are not logged in yet!')
                window.location.replace(process.env.REACT_APP_APPURL + '/login');
            })
        
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/myintakeprocesses/compare_intakes')
            .then((res) => {
                if (JSON.parse(localStorage.getItem('intakeData'))[0].is_main) {
                    const businessProcess = JSON.parse(localStorage.getItem(`businessProcessIntake`));

                    if (businessProcess !== 0 && businessProcess.length !== 0 && res.data) {
                        setIdentifier('IntakeComparison');
                    } else {
                        setIdentifier('IntakeNotFound');
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })

        if (!identifier) {
            setIdentifier('IntakeNotFound');
        }
    }, []);


    const [hover, setHover] = useState(false);
    const containerRef = React.useRef(null);

    // Type of projects !page = medium, page = large 
    const[pageType, setPageType] = useState(0);
    const [localStorageLanguage, setSelectedLanguage] = useState(JSON.parse(localStorage.getItem('Language')) ? JSON.parse(localStorage.getItem('Language')) : 0);
    
    // Number of processes filled in for each project type
    const[countFilledProcessesMedium, setCountFilledProcessesMedium] = useState(JSON.parse(localStorage.getItem("countFilledProcessesMedium")) ? JSON.parse(localStorage.getItem('countFilledProcessesMedium')) : []);
    const[countFilledProcessesLarge, setCountFilledProcessesLarge] = useState(JSON.parse(localStorage.getItem("countFilledProcessesLarge")) ? JSON.parse(localStorage.getItem('countFilledProcessesLarge')) : []);
    localStorage.setItem("countFilledProcessesMedium", JSON.stringify(countFilledProcessesMedium));
    localStorage.setItem("countFilledProcessesLarge", JSON.stringify(countFilledProcessesLarge));

    // Checks if some input is invalid for each project type
    const[invalidPresenceMedium, setInvalidPresenceMedium] = useState(false);
    const[invalidPresenceLarge, setInvalidPresenceLarge] = useState(false);

    let filled = countFilledProcessesLarge.length>=ProcessData.length && !invalidPresenceLarge && countFilledProcessesMedium.length>=ProcessData.length && !invalidPresenceMedium;

    const [publishButtonClicked, setPublishButtonClicked] = useState(false);
    const [isShowingIntroduction, setIsShowingIntroduction] = useState(false);

    const backToHome = () => {
        window.location.replace(process.env.REACT_APP_APPURL + '/home');
    }

    const reverseFormatMainProcesses = (formattedMainProcesses) => {
        const reverseFormattedMainProcesses = [];
    
        const mainProcessesData = formattedMainProcesses.map((mainProcess, mainProcessIndex) => ({ 
            name: mainProcess.name, 
            order_id: mainProcessIndex
        }));
    
        reverseFormattedMainProcesses.push(mainProcessesData);
    
        let subProcessesData = [];
    
        formattedMainProcesses.forEach((mainProcess) => {
            if (mainProcess.subProcesses) {
                mainProcess.subProcesses.forEach((subProcess, subIndex) => {
                    subProcessesData.push({
                        name: subProcess.name,
                        order_id: subIndex,
                        parent: mainProcess.name
                    });
                });
            }
        });
    
        reverseFormattedMainProcesses.push(subProcessesData);
        return reverseFormattedMainProcesses;
    };

    let InitialTimeEstimationMedium = []

    for (let i = 0; i < ProcessData.length; i++) {
        InitialTimeEstimationMedium.push({
        id: i,
        process_id: ProcessData[i].id,
        process_name: ProcessData[i].name,
        idontknow: false,
        months: "",
        weeks: ""
        })
    }

    let InitialTimeEstimationLarge = []

    for (let i = 0; i < ProcessData.length; i++) {
        InitialTimeEstimationLarge.push({
        id: i,
        process_id: ProcessData[i].id,
        process_name: ProcessData[i].name,
        idontknow: false,
        months: "",
        weeks: ""
        })
    }

    // Check whether input was given for all processes
    const [TimeEstimation, setTimeEstimation] = useState(JSON.parse(localStorage.getItem("TimeEstimation")) ? JSON.parse(localStorage.getItem('TimeEstimation')) : [InitialTimeEstimationMedium, InitialTimeEstimationLarge]);

    // const [TimeEstimationLarge, setTimeEstimationLarge] = useState(JSON.parse(localStorage.getItem("TimeEstimationLarge")) ? JSON.parse(localStorage.getItem('TimeEstimationLarge')) : InitialTimeEstimationLarge);

    localStorage.setItem("TimeEstimation", JSON.stringify(TimeEstimation));

    // localStorage.setItem("TimeEstimationLarge", JSON.stringify(TimeEstimationLarge));

    const [render, setRender] = useState(false);

    const reRender = () => {
        setRender(!render);
    };

    useEffect(() => {
        if(!localStorage.getItem("i18nextLng")) {
            localStorage.setItem("i18nextLng", "en-US");
        }
        console.log("isShowingIntroduction", isShowingIntroduction)
        showIntroduction();
    }, [])

    /** Responsive window sizing and useLayoutEffect trigger */
    useWindowSize();

    const [identifier, setIdentifier] = useState('');
   
    const decimalRegexMonths = /^(0(\.\d)?|([1-9]|1\d|2[0-4])(\.\d)?)$/;
    const decimalRegexWeeks = /^(0(\.\d)?|([1-3](\.\d)?)|4(\.0)?)$/;

    const handleInputChange = (process, field, value) => {
        TimeEstimation[pageType][process][field] = value;
        setTimeEstimation(TimeEstimation);
        localStorage.setItem("TimeEstimation", JSON.stringify(TimeEstimation));

        if (pageType){ 
            if (!countFilledProcessesLarge.includes(process)) {
                countFilledProcessesLarge.push(process);
                setCountFilledProcessesLarge(countFilledProcessesLarge);
            } else {
                if (TimeEstimation[pageType][process].months == "" && TimeEstimation[pageType][process].weeks == "" && TimeEstimation[pageType][process].idontknow == false) {
                    countFilledProcessesLarge.splice(countFilledProcessesLarge.indexOf(process), 1);
                    setCountFilledProcessesLarge(countFilledProcessesLarge);
                }
            }
        } else {
            if (!countFilledProcessesMedium.includes(process)) {
                countFilledProcessesMedium.push(process);
                setCountFilledProcessesMedium(countFilledProcessesMedium);
            } else {
                if (TimeEstimation[pageType][process].months == "" && TimeEstimation[pageType][process].weeks == "" && TimeEstimation[pageType][process].idontknow == false) {
                    countFilledProcessesMedium.splice(countFilledProcessesMedium.indexOf(process), 1);
                    setCountFilledProcessesMedium(countFilledProcessesMedium);
                }
            }
        } 

        localStorage.setItem("countFilledProcessesMedium", JSON.stringify(countFilledProcessesMedium));
        localStorage.setItem("countFilledProcessesLarge", JSON.stringify(countFilledProcessesLarge));
        checkInvalidInput();
        reRender();
    }

    const checkInvalidInput = () => {
        let checkerMedium = false;
        let checkerLarge = false;

        TimeEstimation[0].map((element, index) => {
            if (!decimalRegexMonths.test(element.months) && element.months != '' || !decimalRegexWeeks.test(element.weeks) && element.weeks != '') {
                checkerMedium = true;
            }
        })

        setInvalidPresenceMedium(checkerMedium);

        TimeEstimation[1].map((element, index) => {
            if (!decimalRegexMonths.test(element.months) && element.months != ''|| !decimalRegexWeeks.test(element.weeks) && element.weeks != '') {
                checkerLarge = true;
            }
        })

        setInvalidPresenceLarge(checkerLarge);
    }

    const reverseFormatTimeEstimation = (formattedTimeEstimation) => {
        const reverseFormattedTimeEstimation = [];

        formattedTimeEstimation.forEach((projectSize, projectSizeIndex) => {
            let processData = [];

            projectSize.forEach((process, processIndex) => {
                let checked_month = (process.months=='' ? parseFloat("0.0") : parseFloat(process.months));
                let checked_week = (process.weeks=='' ? parseFloat("0.0") : parseFloat(process.weeks));

                processData.push({
                    id: process.id,
                    months: ( checked_month + (checked_week/4.3) ).toFixed(2) // a month has 4.3 weeks
                });
            });
            
            console.log("processData", projectSizeIndex, processData)
            reverseFormattedTimeEstimation.push(processData);
        });

        return reverseFormattedTimeEstimation;
    };

    async function publishTime()  {
        console.log("IN PUBLISH TIME");
        const timeEstimation = JSON.parse(localStorage.getItem('TimeEstimation'));

        if (filled) {
            const businessProcess = JSON.parse(localStorage.getItem(`businessProcessIntake`));
            const reverseFormattedData = JSON.stringify(reverseFormatTimeEstimation(timeEstimation));
            const reverseFormatedDataComparison = JSON.stringify(reverseFormatMainProcesses(businessProcess));
            const axiosInstance = axios.create({withCredentials: true});
            
            const promise1 = await new Promise((resolve, reject) => {
                axiosInstance
                    .post(
                        process.env.REACT_APP_APIURL + '/api/myintakeprocesses/update_batch_comparison/',
                        reverseFormatedDataComparison,
                        {
                            headers: {
                                "Content-Type": "application/json",
                                "X-CSRFToken": getCookieValue("csrftoken"),
                            },
                        }
                    )
                    .then (() => {
                        localStorage.removeItem('intakeData');
                        resolve('promise2')      
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                })
                if(promise1){
                    const promise2 = await new Promise((resolve, reject) => {
                    axiosInstance
                        .post(
                            process.env.REACT_APP_APIURL + '/api/timeestimation/update_batch/',
                            reverseFormattedData,
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                    "X-CSRFToken": getCookieValue("csrftoken"),
                                },
                            }
                        )
                        .then (() => {
                            localStorage.removeItem('intakeData');
                            resolve('promise2')      
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                })

                if (promise2) {
                    setIdentifier('IntakeComparisonPublished');
                }
            }
        } else {
            setPublishButtonClicked(true);
            console.log("not everything filled yet");
        }
    }

    const showIntroduction = () => {
        const isShowingIntroductionBefore = localStorage.getItem(`isShowingIntroductionTime`);

        if (!isShowingIntroductionBefore) {
            setTimeout(() => {
                setIsShowingIntroduction(true);
            }, 1000);
        } else {
            setIsShowingIntroduction(false);
        }
    }

    const hideIntroduction = () => {
        localStorage.setItem(`isShowingIntroductionTime`, 'true');
        setIsShowingIntroduction(false);
    }

    return (
        <Container>
            {(identifier === 'IntakeNotFound') ? (
                <OverlayWrapper>
                    <img src={ImageNotFound} style={{width: 'calc(50% - 20px)', height: 'auto'}} alt=''/>  

                    <StyledDivBold Position='static' Width='auto' Height='auto' MarginTop='40px' MarginBottom='40px' FontSize='42px' LineHeight='52px'>
                        {t('PageIntakeBuilder.NotFoundTextHeader')}
                    </StyledDivBold>

                    <ButtonWrapper onClick={backToHome} Width='auto'>
                        <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#FCFCFC' FontWeight='700' LetterSpacing='0.3px'>
                            {t('PageIntakeBuilder.NotFoundOrPublishedTextButton')}
                        </StyledDivRegular>
                    </ButtonWrapper>
                </OverlayWrapper>
            ) : (identifier === 'IntakeComparisonPublished') ? (
                <OverlayWrapper>
                    <IconCheckCircleFill/>

                    <StyledDivBold Position='static' Width='auto' Height='auto' MarginTop='40px' MarginBottom='16px' FontSize='42px' LineHeight='52px'>
                        {t('PageIntakeBuilder.PublishedTextHeader')}
                    </StyledDivBold>

                    <StyledDivRegular Position='static' Width='auto' Height='auto' MarginBottom='40px' FontSize='18px' LineHeight='28px' TextAlign='center'>
                        {t('PageIntakeBuilder.PublishedComparisonTextBody')}
                    </StyledDivRegular>

                    <ButtonWrapper onClick={backToHome} Width='auto'>
                        <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#FCFCFC' FontWeight='700' LetterSpacing='0.3px'>
                            {t('PageIntakeBuilder.NotFoundOrPublishedTextButton')}
                        </StyledDivRegular>
                    </ButtonWrapper>
                </OverlayWrapper>
            ) : (
                <>
                    <IntroductionTimeComponent reRender={reRender} isShowingIntroduction={isShowingIntroduction} hideIntroduction={hideIntroduction}/>

                    <HeaderWrapper>
                        <ComponentHeaderBlock page='ProcessTime' isDisabled={!filled} handleClick={publishTime} set_selected_language={setSelectedLanguage} reRender={reRender}/>
                    </HeaderWrapper>

                    <WrapperTop>
                        <Trans i18nKey="IntakeProcessTime.Heading">
                            <span>How long</span> does it take for the company to <span>complete this process</span>?
                        </Trans>
                    </WrapperTop>

                    <Mainfield>
                        <PublishErrorWrapper>
                            {publishButtonClicked && !filled && (<div style={{ color: 'red' }}>{t('IntakeProcessTime.ErrorPublish')}</div>)}
                        </PublishErrorWrapper>

                        <TopHeader>
                            <Header>
                                {t("IntakeProcessTime.Instruction")}
                            </Header>

                            <InfoHover onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}>?
                                {hover && (<ComponentTooltipProcessTime whichTooltip={"Tooltip1"}/>)}
                            </InfoHover>
                        </TopHeader>

                        <ProjectNumberWrapper>
                            <IconWrapper Top={"80.23px"} Left={i18n.language == 'nl-NL' ? "355px" : "329px"}>
                                {!pageType && (countFilledProcessesMedium.length<ProcessData.length && !invalidPresenceMedium) ? <Pink1/> : (pageType && (countFilledProcessesMedium.length<ProcessData.length && !invalidPresenceMedium) ? <Grey1/> : ( (countFilledProcessesMedium.length>=ProcessData.length && !invalidPresenceMedium) ? <GreenCheckCircle/> : <Invalid/>))}      
                            </IconWrapper> 
                            
                            <IconWrapper Top={"80.23px"} Left={i18n.language == 'nl-NL' ? "512px" : "486px"}>
                                {!pageType && (countFilledProcessesLarge.length<ProcessData.length) ? <Grey2 /> : (pageType && (countFilledProcessesLarge.length<ProcessData.length) ? <Pink2/> : ( (countFilledProcessesLarge.length>=ProcessData.length && !invalidPresenceLarge) ? <GreenCheckCircle/> : <Invalid/>))}  
                            </IconWrapper>
                        </ProjectNumberWrapper>

                        <ProjectSizeWrapper>
                            <SubheadingText Width={i18n.language == 'nl-NL' ? "170px" : "144px"}>
                                {t("IntakeProcessTime.Subheading")}
                            </SubheadingText>

                            <Button1 Left={"0px"} Background={!pageType && (countFilledProcessesMedium.length<ProcessData.length || invalidPresenceMedium) ? "#E2336B" : (!pageType && countFilledProcessesMedium.length>=ProcessData.length && !invalidPresenceMedium) ? "#06D6A0" : "#E3E5E4"}>
                                <Button1Text Color={!pageType ? "#fcfcfc" : "#5F6366"}>{t("IntakeProcessTime.LabelSize1")}</Button1Text>
                            </Button1>

                            <Button1 Left={"36px"} Background={!pageType ? "#E3E5E4" : (pageType && (countFilledProcessesLarge.length<ProcessData.length || invalidPresenceLarge) ? "#E2336B" : (pageType && countFilledProcessesLarge.length>=ProcessData.length && !invalidPresenceLarge) ? "#06D6A0" : "blue" )}>
                                <Button1Text Color={!pageType ? "#5F6366" : "#fcfcfc"}>{t("IntakeProcessTime.LabelSize2")}</Button1Text>
                            </Button1>
                        </ProjectSizeWrapper>

                        {/* have this as filler now until other chart br works again */}
                        {/* <ProcessBlockWrapper>
                            
                            <Startblock/>

                            <Middleblock/>
                            <Middleblock/>
                            <Middleblock/>
                            <Middleblock/>
                            <Endblock/>

                        </ProcessBlockWrapper>

                        <ProcessBlockTextWrapper>

                            <ProcessBlockText>
                                <MaxTextWrapper>
                                    Sales & Survey
                                </MaxTextWrapper>
                            </ProcessBlockText>
                                

                            <ProcessBlockText Width={"170px"}>
                                <MaxTextWrapper>
                                    Research & Development
                                </MaxTextWrapper>
                            </ProcessBlockText>

                            <ProcessBlockText Width={"170px"}>
                                <MaxTextWrapper>
                                    Creative Design
                                </MaxTextWrapper>
                            </ProcessBlockText>

                            <ProcessBlockText Width={"170px"}>
                                <MaxTextWrapper>
                                    Software Development
                                </MaxTextWrapper>
                            </ProcessBlockText>

                            <ProcessBlockText Width={"170px"}>
                                <MaxTextWrapper>
                                Integration 
                                </MaxTextWrapper>
                            </ProcessBlockText>

                            <ProcessBlockText>
                                <MaxTextWrapper>
                                Customer Support
                                </MaxTextWrapper>
                            </ProcessBlockText>

                        </ProcessBlockTextWrapper> */}

                        {/* reinstate the process component block again later */}

                        <ProcessComponentWrapper>
                            {ProcessData.map((current_process_object, current_process_id, all_process_objects) => {
                                return (
                                    <ProcessComponentWrapperNew>
                                        <ProcessComponent>
                                            <MappedSubProcesses
                                                widths={"fit-content"}
                                                setWidths={"fit-content"}
                                                current_process_object={current_process_object}
                                                current_process_id={current_process_id} reRender={reRender}
                                                containerRef={containerRef}/>
                                        </ProcessComponent>
                                            
                                        <InputBlockWrapper reRender={reRender}>
                                            <InputContainer>
                                                <ErrorWrapper>
                                                    {/* display error message when input isn't according to constraints */}

                                                    {!decimalRegexMonths.test(TimeEstimation[pageType][current_process_id].months) && TimeEstimation[pageType][current_process_id].months.length > 0 && (<div style={{ color: 'red' }}>{t('IntakeProcessTime.ErrorMonths')}</div>)}
                                                    {!decimalRegexWeeks.test(TimeEstimation[pageType][current_process_id].weeks) && TimeEstimation[pageType][current_process_id].weeks.length > 0 && (<div style={{ color: 'red' }}>{t('IntakeProcessTime.ErrorWeeks')}</div>)}                                        
                                                </ErrorWrapper>

                                                <InputFieldsWrapper Opacity={TimeEstimation[pageType][current_process_id].idontknow ? "0.3" : "1"}>
                                                    <InputWrapper>
                                                        {/* disabled={true} value={input_field} changeTimeEstimationMedium(current_process_id, "months", e.currentTarget.value) */}

                                                        <input type="text" key={current_process_id} disabled={TimeEstimation[pageType][current_process_id].idontknow} placeholder='Fill in...'  value={TimeEstimation[pageType][current_process_id].months} maxLength="4" onChange={(e) => handleInputChange(current_process_id, "months", e.currentTarget.value)} />
                                                    </InputWrapper>

                                                    <InputWrapper>
                                                        <input type="text" key={current_process_id} disabled={TimeEstimation[pageType][current_process_id].idontknow} placeholder='Fill in...' value={TimeEstimation[pageType][current_process_id].weeks} maxLength="4" onChange={(e) => handleInputChange(current_process_id, "weeks", e.currentTarget.value)} />
                                                    </InputWrapper>
                                                </InputFieldsWrapper>

                                                <TextTimeWrapper Opacity={TimeEstimation[pageType][current_process_id].idontknow ? "0.3" : "1"}>
                                                    <TextTimeText>
                                                        {t("IntakeProcessTime.Months")}
                                                    </TextTimeText>

                                                    <TextTimeText Width={"24px"}>
                                                        &
                                                    </TextTimeText>

                                                    <TextTimeText>
                                                        {t("IntakeProcessTime.Weeks")}
                                                    </TextTimeText>
                                                </TextTimeWrapper>
                                            </InputContainer>
                                        </InputBlockWrapper>
                                    </ProcessComponentWrapperNew>
                                )
                            })}
                        </ProcessComponentWrapper>

                        {/* button translation through translated text, not seperate svgs or each language (problem: text part unclickale) */}
                        {/* <NextButtonWrapper>
                            {pageType == 0 ? 
                                <button><NextPinkButton onClick={() => setPageType(1)}/></button> 
                            : ( !filled ?
                                <button><BackPinkButton onClick={() => setPageType(0)}/></button>
                            :   <button><BackGreyButton onClick={() => setPageType(0)}/></button>)}

                        </NextButtonWrapper>
                            <Button2Text MarginTop={"-35px"} >{!pageType ? t("IntakeProcessTime.Next") : t("IntakeProcessTime.Back")}</Button2Text> */}

                        <NextButtonWrapper>
                            {i18n.language == 'en-US' && pageType == 0 &&
                                <button><NextPinkButtonEN onClick={() => setPageType(1)}/></button>}
                            {i18n.language == 'nl-NL' && pageType == 0 &&
                                <button><NextPinkButtonNL onClick={() => setPageType(1)}/></button>}
                            {i18n.language == 'ch-CH' && pageType == 0 &&
                                <button><NextPinkButtonCH onClick={() => setPageType(1)}/></button>}

                            {i18n.language == 'en-US' && pageType == 1 && !filled &&
                                <button><BackPinkButtonEN onClick={() => setPageType(0)}/></button>}
                            {i18n.language == 'nl-NL' && pageType == 1 && !filled &&
                                <button><BackPinkButtonNL onClick={() => setPageType(0)}/></button>}
                            {i18n.language == 'ch-CH' && pageType == 1 && !filled &&
                                <button><BackPinkButtonCH onClick={() => setPageType(0)}/></button>}

                            {i18n.language == 'en-US' && pageType == 1 && filled &&
                                <button><BackGreyButtonEN onClick={() => setPageType(0)}/></button>}
                            {i18n.language == 'nl-NL' && pageType == 1 && filled &&
                                <button><BackGreyButtonNL onClick={() => setPageType(0)}/></button>}
                            {i18n.language == 'ch-CH' && pageType == 1 && filled &&
                                <button><BackGreyButtonCH onClick={() => setPageType(0)}/></button>}    
                        </NextButtonWrapper>
                    </Mainfield>

                    {/* <ComponentFooter 
                    page='PageIntakeRepresentative' 
                    prevdirectory='/intake-builder' 
                    input_check_process={true} 
                    //  inputCheckPeople={InputCheckPeople}  
                                            nextButtonClicked={nextButtonClicked} setNextButtonClicked={setNextButtonClicked} Position='static' MarginTop='0' BackMarginLeft='0' BackWidth="56px" 
                                            BackHeight="56px" MarginLeft='0' Width='160px' Height='56px'/> */}
                </>
            )}
        </Container>
    );
};

export default PageProcessTime;

const OverlayWrapper = styled.div`
    display: flex;
    width: calc(100% - 80px);
    max-width: 1290px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    width: ${props => props.Width || 'calc(100% - 20px)'};
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: ${props => props.Color || '#E2336B'};
    transition: filter 0.15s;
    cursor: pointer;
    user-select: none;

    &:hover {
        filter: brightness(90%);
    }
`;

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f6f7fa;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
    pointer-events: none;
    -drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    }

    @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
    }

    @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
    }

    @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: calc(1 / 1.75);
    }
`;

const HeaderWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: ${props => props.isShowingIntroduction ? 'none' : 'auto'};
    z-index: 4;
`;

const ProjectSizeWrapper = styled.div`
    /* background: blueviolet; */
    display:flex;
    flex-direction: row;
    margin-top: 0px;
    /* gap: 40px; */
    margin-left: 97px;
    /* width:1000px; */
`;

const ProjectNumberWrapper = styled.div`
    /* background: blue;  */
    display:flex;
    width: 100%;
    height: ${(props) => props.Width || "fit-content"};
    flex-direction: row;
    margin-top: 75.18px;
    gap: 40px;
    margin-left: 0px;
    width: 100%;
`;

const NumberWrapper = styled.div`
    background: blueviolet;
    display:flex;
    position: relative;
    flex-direction: row;
    margin-left: 15px;
    width: 100px;
    height: 100px;
`;

const WrapperTop = styled.div`
  position: relative;

  /* align-self: center; */
  left: 0px;
  right: 0px;
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 100px;
  letter-spacing: 0;
  text-align: center;
  margin-top: 64px;
  height: fit-content;

  span {
    color: #e2336b;
  }
`;

const Bodywrapper = styled.div`
    background: blueviolet;
    /* position: absolute; */
    display: flex;
    top: 196px;
    width: 100%;
    height: 640px;
    flex-direction: row;
`;

const Mainfield = styled.div`
    background: #FCFCFC;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: left;
    border-radius: 5px;
    width: 1290px;
    height: 640px;
    /* @media only screen and (min-width: 2000px) {
        width: 75%; 
    }*/
`;

const PublishErrorWrapper = styled.div`
    /* background: blue; */
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: left;
    border-radius: 5px;
    width: 1290px;
    height: 20px;
    margin-top: 10px;
    margin-left: 10px;
    color: #F72B2B;
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 500;
    font-size: 16px; 
    
`;

const SubheadingText = styled.div`
  width: ${(props) => props.Width || "144px"};
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 300;
  line-height: 36px;
  font-size: 24px;
  margin-right: 44px;
  color: #271B36;
  top: 145.3px;
  left: 97px;
`;

const IconWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${(props) => props.Width || "fit-content"};
    height: ${(props) => props.Height || "fit-content"};
    margin-left: ${(props) => props.Left || "1234px"};
    color: #271B36;
    position: absolute;
    top: ${(props) => props.Top || "40px"};
`;

const Button1 = styled.div`
    background: ${(props) => props.Background || "#000000"};
    width: 121px;
    height: 30.84px;
    margin-left: ${(props) => props.Left || "0px"};
    color: #271B36;
    top: 146px;
    border-radius: 5px;
`;

const Button1Text = styled.div`
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    font-size: 16px;
    color: ${(props) => props.Color || "#000000"};
    text-align: center;
    vertical-align: 15px;
    margin-top: ${(props) => props.MarginTop || "6px"};
`;

const Button2Text = styled.div`
    width: fit-content;
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    font-size: 16px;
    color: ${(props) => props.Color || "#fcfcfc"};
    /* text-align: center; */
    vertical-align: 15px;
    margin-top: ${(props) => props.MarginTop || "6px"};
    margin-left: 608px;
   
`;

const ButtonLabelText = styled.div`
  width: ${(props) => props.Width || "fit-content"};
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  font-size: 24px;
  margin-left: 0px;
  color: #fcfcfc;
  top: 145.3px;
  left: 97px;
  z-index: 5;
`;

const ProcessBlockWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: ${(props) => props.Width || "fit-content"};
    height: ${(props) => props.Height || "fit-content"};
    margin-left: 86px;
    margin-top: 87px;
    color: #271B36;
    position: relative;
`;

const ProcessBlockTextWrapper = styled.div`
    /* background: yellow; */
    position: relative;
    display: flex;
    flex-direction: row;
    width: ${(props) => props.Width || "fit-content"};
    height: ${(props) => props.Height || "fit-content"};
    margin-left: 86px;
    margin-top: -51px;
    color: #271B36;
    position: relative;
    align-items: center;
`;

const ProcessBlockText = styled.div`
  /* background: blue; */
  width: ${(props) => props.Width || "182px"};
  height: fit-content;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  font-size: 16px;
  margin-right: 10px;
  color: #fcfcfc;
  /* top: -145.3px;
  left: 97px; */
  text-align: center;
  vertical-align: 15px;
  margin-top: 0px;
`;

const MaxTextWrapper = styled.div`
  /* background: green; */
  max-width: 150px;
  height: fit-content;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  font-size: 16px;
  margin-right: 10px;
  color: #fcfcfc;
  /* top: -145.3px;
  left: 97px; */
  text-align: center;
  vertical-align: 15px;
  margin-top: 0px;
  margin-left: 15px;
`;

const ProcessComponentWrapper = styled.div`
  /* background: blue; */
  margin-top: 44px;
  margin-bottom: 24px;
  margin-left: 110px;
  display: flex;
  /* justify-content: center; */
  flex-direction: row;
  background: #FCFCFC;
  width: calc(100% - 20px);
  width: 1100px;
  /* align-items: center; */
  justify-content: center;
`;

const ProcessBlockContainer = styled.div`
  /* background: grey; */
  margin: 44px 0 24px 86px;
  display: flex;
  flex-direction: row;
  /* background: #FCFCFC; */
  /* width: calc(100% - 20px); */
  width: 1100px;
  height: 250px;
  /* align-items: center; */
  justify-content: top;
`;

const ProcessComponentWrapperNew = styled.div`
  /* background: yellow; */
  /* margin: 44px 0 24px 86px; */
  display: flex;
  flex-direction: column;
  /* width: calc(100% - 20px); */
  width: 190px; 
  height: 250px;
  align-items: center;
  /* justify-content: center; */
`;

const ProcessComponent = styled.div`
  /* background: blue; */
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

const InfoHover = styled.div`
  float: right;
  font-size: 24px;
  font-family: 'Overpass', sans-serif;
  font-weight: 300;
  position: relative;
  color: #FCFCFC;
  background-color: #E2336B;
  margin-right: 16px;
  margin-top: 0px;
  border: 2px solid #E2336B;
  border-radius: 500px;
  width: 32px;
  text-align: center;
  height: 30.843px;
  cursor: pointer;
  line-height: 35px;
  z-index: 16;
`;

const Header = styled.div`
  margin-top: 8.55px;
  margin-left: 97px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
`;

const InstructionText = styled.div`
  width: ${(props) => props.Width || "fit-content"};
  display: flex;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  font-size: 18px;
  margin-left: 97px;
  color: #271B36;
  position: absolute;
  top: 38.55px;
 
`;

const HoverTooltip = styled.div`
  //display: flex;
  //align-items: center;
  position: absolute;
  padding: 0 0 10px 20px;
  top: 300px;
  z-index: 3;
  background: #FCFCFC;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const SubProcessColumnWrapper = styled.div`
`;

const SubProcessWrapper = styled.div`
`;
const SubProcessText = styled.p`
  margin: 0;
  padding: 3px;
  width: 115px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: 'Overpass';
  font-weight: ${props => props.FontWeight || "400"};
  font-size: ${props => props.FontSize || "14px"};
  line-height: 18px;
overflow: hidden;
`;
const BreakColumn = styled.div`
  flex-basis: ${props => props.FlexBasis || "100%"};
`;

const BottomWrapper = styled.div`
  width: 100%;
  height: 30px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  input{
    height: 0px;
    width: 60px;
    background: #FCFCFC;
    border: solid 1px #271B36;
    border-radius: 8px;
  }
`;

const TimeBox = styled.div`
    border: solid 1px #271B36;
    width: 60px;
    text-align: center;
    border-radius: 5px;
    height: 18px;
`

const InputFieldsWrapper = styled.div`
    /* background: yellow; */
    display: flex;
    justify-content: row;
    width: 190px;
    height: 46px;
    opacity: ${props => props.Opacity || '1'};
`

const InputWrapper = styled.div`
  width: 85px;
  height: 46px;
  display: flex;
  justify-content: center;
  /* background: blanchedalmond; */
  input{
    width: 61px;
    height: 45px;
    border: solid 1px #271B36;
    background: #FCFCFC;
    font-family: 'Overpass';
    font-size: 14px;
    text-align: center;
    padding-top: 0px;
    border-radius: 5px;
    :focus{
      outline-style: solid;
      outline-width: 1px;
      outline-color: #E2336B;
    }
  }
`;

const TextTimeWrapper = styled.div`
    /* background: orange; */
    display: flex;
    justify-content: row;
    align-items: center;
    width:  178px;
    height: 20px;
    margin-top: 16px;
    margin-left: 12px;
    color: #5F6366;
    opacity: ${props => props.Opacity || '1'};
   
`

const TextTimeText = styled.div`
    /* background: blue; */
    display: flex;
    align-content: center;
    justify-content: center;
    width: ${props => props.Width || "62px"};
    color: #5F6366;
    text-align: center;
    font-family: 'Overpass';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
`
const ErrorWrapper = styled.div`
    /* background: blueviolet; */
    display: flex;
    flex-direction: column;
    width: 190px;
    height: 48px;
    color: #F72B2B;
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
`;
const InputContainer = styled.div`
    /* background: blueviolet; */
    display: flex;
    flex-direction: column;
    width: 190px;
    height: fit-content;

`;
const InputBlockWrapper = styled.div`
    /* background: red; */
    width: 190px;
`;

const NoClueWrapper = styled.div`
    display: flex;
    justify-content: row;
    background: #f1f3f4;
    margin-top: 24px;
    margin-left: 12px;
    width: 144px;
    height: 42px;
    border-radius: 5px;
    opacity: ${props => props.Opacity || '1'};
`;

const NoClueText = styled.div`
    display: flex;
    justify-content: row;
    margin-top: 12px;
    margin-left: 16px;
    width: 76px;
    height: 18px;
    color: var(--primary-color-dark-purple, #271B36);
    text-align: center;
    font-family: Overpass;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
`;

const RadioWrapper = styled.div`
    display: flex;
    justify-content: row;
    /* background: black; */
    margin-top: 11px;
    margin-left: 16px;
    width: 20px;
    height: 20px;
`;


const NextButtonWrapper = styled.div`
    /* background: blueviolet; */
    display:flex;
    flex-direction: row;
    margin-top: 57px;
    margin-left: 571px;

    button {
    background: none;
    border: none;
    cursor: pointer;
  }
`;

