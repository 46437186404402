import axios from 'axios';
import getCookieValue from '../../../utils';
import * as d3 from 'd3';
import './D3Components.css';
import { useEffect } from 'react';


/**  Teams based graph component */
export class SocialNetwork {

    // Constructor variable for the class
    containerEl; // the container that will hold the visualization
    props;  // the json data, width and height
    svg;  // variable to hold the svg elements(canvas)

    /** Constructor function to render the graph, click and hover functions
     * hover shows name and team affiliation
     */

    constructor(containerEl, props) {
        // Initiliazing the variables
        // The this variables are visible from SocialNetwork-render-component
        this.containerEl = containerEl;
        this.props = props;
        const {width, height, context, demo_mode, RandomNames, language, selected_survey} = props;
        const nodeData = props.data.nodes; // store the node data
        const edgeData = props.data.edges; // store edge data
        const scoreData = props.data.score; // store scores and for every process 
        const formalLeadersData = props.data.formal_leaders; // store actual leaders of the company
        const actualLeadersData = props.data.actual_leaders; // store actual leaders of the company
        const potentialLeadersData = props.data.potential_leaders; // store actual leaders of the company

        const displayLanguage = props.language;
        // Div Container size
        const windowWidth = width;
        const windowHeight = height;
        
        // Graph Width
        const xpositions = nodeData.map(node => node.xpos)
        const minX = Math.min(...xpositions)
        const maxX = Math.max(...xpositions)
        var graphWidth = (maxX - minX)
        
        // Graph center X
        const sortedXpositions = xpositions.sort((a,b) => a - b)
        const medianXIndex = Math.floor(sortedXpositions.length / 2)
        const medianX = sortedXpositions.length % 2 !== 0 ? sortedXpositions[medianXIndex] : (sortedXpositions[medianXIndex - 1] + sortedXpositions[medianXIndex]) / 2;
        
        // Graph Height
        const ypositions = nodeData.map(node => node.ypos)
        const minY = Math.min(...ypositions)
        const maxY = Math.max(...ypositions)
        var graphHeight = (maxY - minY)

        // Graph center Y
        const sortedYpositions = ypositions.sort((a,b) => a - b)
        const medianYIndex = Math.floor(sortedYpositions.length / 2)
        const medianY = sortedYpositions.length % 2 !== 0 ? sortedYpositions[medianYIndex] : (sortedYpositions[medianYIndex - 1] + sortedYpositions[medianYIndex]) / 2;

        // Scaling factor (modifies nodes and edges size depending on the size of the graph)
        const initialZoom = 1  // >1 = zoom out  |  <1 = zoom in 
        const widthScalingFactor = windowWidth / (graphWidth * initialZoom)
        const heightScalingFactor = windowHeight / (graphHeight * initialZoom)
        var scalingFactor;
        if (widthScalingFactor > heightScalingFactor)
            scalingFactor = widthScalingFactor
        else
            scalingFactor = heightScalingFactor

        // Box containing all the elements in the graph
        var containingBoxX = ( graphWidth + graphHeight ) * scalingFactor
        var containingBoxY = ( graphHeight + graphWidth ) * scalingFactor

        // Passing variables to the SocialNetwork-render-component
        this.containingBoxX = containingBoxX
        this.containingBoxY = containingBoxY
        // Calculate offsets so the graph can be aligned at a certain distance form the edge of the canvas top and left alignment
        const offsetX = ( minX - graphHeight / 2 ) * scalingFactor
        const offsetY = ( minY - graphWidth / 2 ) * scalingFactor
        this.offsetX = offsetX
        this.offsetY = offsetY
        // Passing variables to the SocialNetwork-render-component
        this.medianX = medianX * scalingFactor
        this.medianY = medianY * scalingFactor

        // Animation duration for the efects 
        const transitionDuration = 200;

        // Edge drawing parameters
        const edgeWeights = edgeData.map(edge => edge.weight);
        const edgeWeightMax = Math.max(...edgeWeights);
        const maxEdgeOpacity = 1;
        const minEdgeHighlightValue = 15; 
        const edgeThicknessMultiplier = 0.50;
        const normalEdgeColor = "#271B36";
        const highlightEdgeColor = "#271B36";

        // Node drawing parameters
        const normalOpacity = 1
        const hiddenOpacity = 0.05

        // Node size
        var nodeRadius = containingBoxX /  (nodeData.length * 2);
        var nodesFontSize = containingBoxX /  (nodeData.length * 3);

        // Node highlight circle size
        const highlightCircleLine = 12;
        const highlightCircleWidth = 8;
        var highlightCircleRadius = containingBoxX /  (nodeData.length * 1.75);

        // Team teamFilterLegend
        const legendFontSize = "14px";
        const legendFontWeight = "400";
        const legendFontFamily = "Overpass"
        const teamFilterLegendSquareColorSize = "16px"
        
        // Color is the no team node color (Customer and Supplier)
        var noTeamColor = ['#271B36']
        // List of colors depending on the team name
        var teamColors = {'#B3194A' : ["Management", "Secretariat"], '#52B5B5' : ["Chintech"], '#C07E21' : ["Sales"], '#048B67' : ["Logistic", "FLS"], '#D90808' : ["Quality", "QC", "D&I"], 
                            '#89F066' : ["VAG", "Production"], '#26547C' : ["PCB"], '#4183BD' : ["R&D"], '#06D6A0' : ["HR", "Financial"], '#FFBC1F' : ["MIS", "ICT"],
                            '#A79090' : ["ODOO", "Support"]}

        function get_team_color(team_name){ // color depending on the team
            if (team_name === null) return noTeamColor
            else{
                for (const [color, teams] of Object.entries(teamColors)) {
                    for (let team = 0; team < teams.length; team++) {
                        if(team_name.includes(teamColors[color][team])) return color
                    }
                }
            }
            return noTeamColor
        }

        // Teams selected at the moment
        var formalTeamsList = []; 

        // Selected nodes at the moment
        var all_employees = []; 
        for(var i = 0; i<nodeData.length; ++i){
            all_employees.push(nodeData[i].id);
        }

        var selected_employees = all_employees

        // Iterate through the nodes and add a team, if the team was already added, don't add it
        for (let i = 0; i < nodeData.length; i++) {
            if (!formalTeamsList.includes(nodeData[i].team__name) && nodeData[i].team__name != null){ 
                formalTeamsList.push(nodeData[i].team__name)
            }
        }

        formalTeamsList.sort()

        //center point's coordinates of all selected areas in the social network
        let centerXCoordinateOfAllSelection = null
        let centerYCoordinateOfAllSelection = null
    
        //size of the selection areas
        var heightOfSelectionAreas = null
        let widthOfSelectionAreas = null
        //

        // Create the tooltip
        var tooltip = d3.select("body")
            .append("div") 
            .attr("class", "tooltip") // setting class of the div
            // css styling
            .style("position", "absolute")
            .style("z-index", "9999")
            .style("visibility", "hidden")
            .style("background", "#D0E1F1");

        // SVG canvas creation (where the nodes and edges are drawn)
        this.svg = d3.select(containerEl)
            .append('svg')
            .attr('width', function () {
                if(containingBoxX > windowWidth) return containingBoxX
                else return windowWidth
            })
            .attr('height', function () {
                if(containingBoxY > windowHeight) return containingBoxY
                else return windowHeight
            })

        // Edges in the graph
        var edges = this.svg.selectAll(".line")
            .attr("className", "edge")
            .data(edgeData) 
            .enter() 
            .append("g") // place each edge in a group
            .append("line") 
            // Edge points positions
            .attr("x1", function (edge) {
                return (nodeData.find(({id}) => id === edge.source).xpos * scalingFactor - offsetX)
            })
            .attr("y1", function (edge) {
                return (nodeData.find(({id}) => id === edge.source).ypos * scalingFactor - offsetY)
            })
            .attr("x2", function (edge) {
                return (nodeData.find(({id}) => id === edge.target).xpos * scalingFactor - offsetX)
            })
            .attr("y2", function (edge) {
                return (nodeData.find(({id}) => id === edge.target).ypos * scalingFactor - offsetY)
            })
            // Edge color. The if is doesn't do anything at the moment because the highlightEdgeColor is the same as the normalEdgeColor
            .attr('stroke', function (edge) {
                if (edge.weight > 0 && edge.weight <= minEdgeHighlightValue) return normalEdgeColor;
                else return highlightEdgeColor;
            })
            .attr('stroke-width', function (edge) {
                return Math.sqrt(edge.weight * edgeThicknessMultiplier);
            })
            .attr("stroke-opacity", function (edge) {
                return maxEdgeOpacity * edge.weight / 0.75 * edgeWeightMax;
            });

        // Nodes in the graph
        var nodes = this.svg.selectAll(".node")
            .attr("className", "nodeBorder")
            .data(nodeData)
            .enter()
            .append("g");

        // Node circle
        nodes.append("circle")
            .attr("class", "team")
            .attr("r", function (node) { // radius of the node, bigger for the customer and supplier
                if(node.team__name === null) return nodeRadius*1.5
                else return nodeRadius
            })
            .attr('stroke', '#271B36')  // outside edge of the node
            .attr('fill', function (node) {
                return get_team_color(node.team__name)
            })
            // Position of the node
            .attr('cx', function (node) {
                return node.xpos * scalingFactor - offsetX;
            })
            .attr('cy', function (node) {
                return node.ypos * scalingFactor - offsetY;
            })
            .attr("opacity", 1)
            .style("z-index", 2)
            .on('mouseover', function (event, node) {
                mouseOverNode(event, node);
                return tooltip.style("top", (event.pageY - 10) + "px").style("left", (event.pageX + 10) + "px");
            })
            .on("mouseout", function () {
                mouseOutNode();
            })

            
        // Node image (not used)
        nodes.append("image")
            // uncomment this to show images
            // .attr("xlink:href", function(node) { return "/profilepics/" + node.user__avatar;})
            // .attr("xlink:href", function(node) { return "/" + node.user__avatar})
            .attr("x", function (node) {
                return node.xpos * scalingFactor - offsetX;
            })    
            .attr("y", function (node) {
                return node.ypos * scalingFactor - offsetY
            })  
            .attr("width", nodeRadius)
            .attr("height", nodeRadius)
            .attr("opacity", 1)
            .on('mouseover', function (event, node) {
                mouseOverNode(event, node);
                return tooltip.style("top", (event.pageY - 10) + "px").style("left", (event.pageX + 10) + "px");
            })
            .on("mouseout", function (event, node) {
                mouseOutNode(event, node);
            })

        // Node text (initials of the employee)
        nodes.append('text')
            .attr('class', 'text')
            .attr("fill", '#fcfcfc')
            .style("font-weight", "500")
            .style("font-family", "Overpass")
            .style("font-size", function (node) {
                if(node.employee__user__first_name.includes("customer") || node.employee__user__first_name.includes("supplier")) return nodesFontSize*1.5
                else return nodesFontSize
            })
            .style("cursor", "pointer")
            // position
            .attr("x", function (node) {
                if(node.employee__user__first_name.includes("customer") || node.employee__user__first_name.includes("supplier")) return node.xpos * scalingFactor - offsetX - nodeRadius / 1.15
                else return node.xpos * scalingFactor - offsetX - nodeRadius / 2.5
            })   
            .attr("y", function (node) {
                if(node.employee__user__first_name.includes("customer") || node.employee__user__first_name.includes("supplier")) return node.ypos * scalingFactor - offsetY + nodeRadius / 3.5
                else return node.ypos * scalingFactor - offsetY + nodeRadius / 4.5
            })  
            .text(function (node, index) {
                if (demo_mode) { // Random initials for the demo mode
                    if(node.employee__user__first_name.includes("customer")) return "Cus"
                    else if(node.employee__user__first_name.includes("supplier")) return "Sup"
                    else return RandomNames[index].first_name.charAt(0) + RandomNames[index].last_name.charAt(0)

                } else{
                    if(node.employee__user__first_name.includes("customer")) return "Cus"
                    else if(node.employee__user__first_name.includes("supplier")) return "Sup"
                    else {
                        return context.setFirstName(node.employee__user__first_name, 0).charAt(0)
                        + context.setFirstName(node.employee__user__last_name, 0).split(" ").pop().charAt(0)
                    }
                }
            })
            .on('mouseover', function (event, node) {
                mouseOverNode(event, node);
                return tooltip.style("top", (event.pageY - 10) + "px").style("left", (event.pageX + 10) + "px");
            })
            .on("mouseout", function () {
                mouseOutNode();
            })


        // Node highlightings
        var nodeHighlights = this.svg.selectAll(".node")
            .attr("className", "nodeHighlight")
            .data(nodeData)
            .enter()
            .append("g");

        nodeHighlights.append("circle") 
            .attr("class", "highlight")
            .attr("cx", function (node) {
                return node.xpos * scalingFactor - offsetX
            })  
            .attr("cy", function (node) {
                return node.ypos * scalingFactor - offsetY
            })  
            .attr("r", 0)       
            .style("stroke-dasharray", highlightCircleLine) 
            .style("stroke", "#e2236b")   
            .style("fill", "none") 
            .attr("stroke-width", highlightCircleWidth) 
            .attr("opacity", 0) // no highlights are shown at the start


        // Group of nodes selection zones
        var circles = this.svg.selectAll(".node")
            .attr("className", "nodeCircle")
            .data(nodeData)
            .enter()
            .append("g");

        // Team filter
        const teamFilterLegend = d3.select('.TeamFilter')
            .append('div')
            .attr("class", "teamFilterLegend")
            .style('display', 'flex')
            .style('flex-direction', 'row')
            .style('gap', '12px')
            .style('overflow', 'auto')
            .style("padding-bottom", "8px")
            .style('font-family', legendFontFamily)
            .style('font-size', legendFontSize)
            .style('font-weight', legendFontWeight);

        const collaboration = d3.select('.Collaboration')
            .text(scoreData[0] + "%")
            .style("border-color", scoreData[0] >= 2*scoreData[1]/3 ? "#00C592" : scoreData[0] >= scoreData[1]/3 ? "#FFD166" : "#E2336B");

        const benchmark = d3.select('.Benchmark')
            .text(scoreData[1] + "%")
            .style("border-color", "#00C592");

        // Every team entry creates a div
        const teamEntry = teamFilterLegend.selectAll('div')
            .data(formalTeamsList)
            .join('div')
            .style('width', 'fit-content')
            .style('display', 'flex')
            .style('align-items', 'center');

        // Add the colored circle for each teamEntry
        teamEntry.append('div')
            .style('background', d => get_team_color(d))
            .style('min-width', teamFilterLegendSquareColorSize)
            .style('min-height', teamFilterLegendSquareColorSize)
            .style('margin-right', '0.5em')
            .style('border-radius', '50%');
        
        // Add the text to each teamEntry
        teamEntry.append('div')
            .style('width', 'max-content')
            // .text(d =>  setTeams(d, "not chinese")); 
            .text(d =>  context.setTeams(d, displayLanguage)); 

        // After a selection is done this function is called
        async function filterTeamsProcess(listOfEmployeeIDs) { 
            // sets the opacity of the selected nodes to normal (1) and the other ones to hidden (0.05)
            nodes.transition().duration(transitionDuration).attr("opacity", function (node) {
                if (listOfEmployeeIDs.includes(node.id)){
                    return normalOpacity
                } 
                else return hiddenOpacity
            })

            // sets the opacity of the selected nodes highlights to normal (1) and the other ones to hidden (0.05)
            nodeHighlights.selectAll(".highlight").transition().duration(transitionDuration).attr("opacity", function (node) {
                if (listOfEmployeeIDs.includes(node.id)) return normalOpacity
                else return hiddenOpacity
            })

            // sets the opacity of the edges between the selected nodes to normal (1) and the other ones to hidden (0.05)
            edges.transition().duration(transitionDuration).attr("stroke-opacity", function (edge) {
                var sourceNode = nodeData.find(({id}) => id === edge.source)
                var targetNode = nodeData.find(({id}) => id === edge.target)
                if (listOfEmployeeIDs.includes(sourceNode.id) && listOfEmployeeIDs.includes(targetNode.id)) return normalOpacity
                else return hiddenOpacity
            });
        }

        // Shows the box with the name and team of the employee when its node is hovered
        function mouseOverNode(event, selectedNode) {           
            if(selected_employees.includes(selectedNode.id)) {
                tooltip.style("visibility", "visible");
                if (demo_mode) { // developer
                    if(selectedNode.employee__user__first_name.includes("customer")) tooltip.html(`Company customers`)
                    else if(selectedNode.employee__user__first_name.includes("supplier")) tooltip.html(`Company suppliers`)
                    else {
                        let index = nodeData.indexOf(selectedNode)
                        tooltip.html(`${RandomNames[index].first_name} ${RandomNames[index].last_name}<br/>${context.setTeams(selectedNode.team__name, 0)}`)
                    }
                } else { // no developer
                    if(selectedNode.employee__user__first_name.includes("customer")) tooltip.html(`Company customers`)
                    else if(selectedNode.employee__user__first_name.includes("supplier")) tooltip.html(`Company suppliers`)
                    else {
                        tooltip.html(`${context.setFirstName(selectedNode.employee__user__first_name, 0)} ${context.setFirstName(selectedNode.employee__user__last_name, 0)}<br/>${context.setTeams(selectedNode.team__name, 0)}`)
                    }
                }
            
                var connectedEdges = edgeData.filter(function (edge) {

                    var sourceNode = nodeData.find(({id}) => id === edge.source)
                    var targetNode = nodeData.find(({id}) => id === edge.target)

                    return (sourceNode === selectedNode || targetNode === selectedNode) && selected_employees.includes(sourceNode.id) && selected_employees.includes(targetNode.id);
                    });

                const connectedSources = connectedEdges.map(edge => edge.source);
                const connectedTargets = connectedEdges.map(edge => edge.target);
                
                // sets the opacity of the selected nodes to normal (1) and the other ones to hidden (0.05)
                nodes.transition().duration(transitionDuration).attr("opacity", function (node) {
                    if (selected_employees.includes(node.id) && connectedSources.includes(node.id) || connectedTargets.includes(node.id)) return normalOpacity
                    else return hiddenOpacity
                });

                // sets the opacity of the selected nodes highlights to normal (1) and the other ones to hidden (0.05)
                nodeHighlights.selectAll(".highlight").transition().duration(transitionDuration).attr("opacity", function (node) {
                    if (selected_employees.includes(node.id) && connectedSources.includes(node.id) || connectedTargets.includes(node.id)) return normalOpacity
                    else return hiddenOpacity
                });

                // sets the opacity of the edges between the selected nodes to normal (1) and the other ones to hidden (0.05)
                edges.transition().duration(transitionDuration).attr("stroke-opacity", function (edge) {
                    if (connectedEdges.includes(edge)) return maxEdgeOpacity * edge.weight / 0.75 * edgeWeightMax
                    else return hiddenOpacity
                });
                
            }
        }

        // Node hover functionality stops
        function mouseOutNode() {
            tooltip.style("visibility", "hidden");
            filterTeamsProcess(selected_employees)
        }

        // Draw highlighting cirles around the leaders
        this.highlightNodes = async function (selection) {

            let leaders
            if (selection === "FormalLeaders") leaders = formalLeadersData
            else if (selection === "ActualLeaders") leaders = actualLeadersData
            else if (selection === "PotentialLeaders") leaders = potentialLeadersData
            else leaders = []

            var ids = [];
            for(var i = 0; i<leaders.length; ++i){
                ids.push(leaders[i].id);
            }

            // Create highlight dotted circles
            nodeHighlights.selectAll(".highlight").transition().duration(transitionDuration)
                .attr("r", function (node) {
                    if (ids.includes(node.id)) return highlightCircleRadius * 1.2
                    else return 0
                })
                .attr("opacity", function (node) {
                    if (ids.includes(node.id)) return normalOpacity
                    else return 0
                }); 
        }
        

        this.RemoveSelectionAreas = function(ids){
            if(ids != null){
                for(i=0;i<ids.length;i++){
                    if(document.getElementById("selectionAreaNr"+`${i}`)!=null){
                        document.getElementById("selectionAreaNr"+`${i}`).remove()
                    }
                }
            }
        }
        
        this.HasSelectionAreas = function(){
            if(centerXCoordinateOfAllSelection == null || centerYCoordinateOfAllSelection == null){
                return false
            }
            return true;
        }
        this.GetCenterPointForSelectionArea = function(){
            var center_x_c = centerXCoordinateOfAllSelection
            var center_y_c = centerYCoordinateOfAllSelection
            if(center_x_c!=null && center_y_c!=null){
                return {center_x_c, center_y_c};
            }
            else{
                return null;
            }
        }
        
        //get width and height of the selection areas
        this.GetSizeOfSelectionAreas = function(){
            return {widthOfSelectionAreas, heightOfSelectionAreas}
        }

        this.GetPolygonByID = function(id){
            if(document.getElementById(id)!=null)
                return document.getElementById(id)
        }
        
        this.GeneratePolygonsForSelection = function(ids){
            if(ids != null && ids.length>0){
                let listOfAllCoordinates = []
                let sortedXForAllCoordinate = [] //collect all X-coordinates from all the selection areas
                let sortedYForAllCoordinate = [] //collect all Y-coordinates from all the selection areas
                let areaID = 0;
                //drawing polygons for multiple arrays
                ids.forEach(nodeIdList => {
                    if(nodeIdList!=null && nodeIdList.length>0){
                        let selectedNodesPositions = [];
                        let selectedNodes = [];
                        for (var i = 0; i < nodeIdList.length; i++) {
                            let node = nodeData.find(({id}) => id === nodeIdList[i])
                            let pos = [node.xpos, node.ypos]
                            selectedNodesPositions.push(pos)
                            selectedNodes.push(node)
                            listOfAllCoordinates.push(pos)
                            
                        }
                        
                        let sortedNodesXPositions = [...selectedNodesPositions].sort(function(a,b){return a[0] - b[0]})
                        let sortedNodesYPositions = [...selectedNodesPositions].sort(function(a,b){return a[1] - b[1]})
                        var polygon_points = []
                        //irrelevant points inside the polygons that need to be eliminated
                        var internal_points = []
                        //find and collect internal points that are not relevant in the polygon
                        for(var i=0; i<nodeData.length; i++){
                            if(sortedNodesXPositions[sortedNodesXPositions.length-1][0]>=nodeData[i].xpos&&nodeData[i].xpos>=sortedNodesXPositions[0][0]
                                &&sortedNodesYPositions[sortedNodesYPositions.length-1][1]>=nodeData[i].ypos&&nodeData[i].ypos>=sortedNodesYPositions[0][1]
                                &&selectedNodes.find(({id}) => id === nodeData[i].id) == null){
                                    internal_points.push(nodeData[i])
                            }
                        }
        
                        //create coordinates for points in the polygon, based on the coordinates of the relevant nodes
                        for (let i = 0; i < sortedNodesXPositions.length; i++) {
                            let x = sortedNodesXPositions[i][0] * scalingFactor - offsetX
                            let y = sortedNodesXPositions[i][1] * scalingFactor - offsetY
                                                
                            if (i==0) polygon_points.push(
                                {x: x-nodeRadius*1.5, y: y-nodeRadius*0.75},
                                {x: x-nodeRadius*1.5, y: y+nodeRadius*0.75},
                                {x: x+nodeRadius*2, y: y-nodeRadius*1.25},
                                {x: x+nodeRadius*2, y: y+nodeRadius*1.25})
                            else if (i==sortedNodesXPositions.length-1) polygon_points.push(
                                {x: x-nodeRadius*1.5, y: y-nodeRadius*1.5},
                                {x: x-nodeRadius*1.5, y: y+nodeRadius*1.5},
                                {x: x+nodeRadius*1.5, y: y-nodeRadius*1.75},
                                {x: x+nodeRadius*1.5, y: y+nodeRadius*1.75},) 
                            else polygon_points.push(
                                {x: x-nodeRadius*2, y: y-nodeRadius*1.2},
                                {x: x-nodeRadius*2, y: y+nodeRadius*1.2},
                                {x: x+nodeRadius*1.5, y: y-nodeRadius*1.25},
                                {x: x+nodeRadius*1.5, y: y+nodeRadius*1.25})     
                        }
        
                        //draw the polygon on dash board
                        var points_data = polygon_points.map((d)=>[d.x,d.y])
                        var hull = d3.polygonHull(points_data)
                        var line = d3.line().curve(d3.curveLinearClosed);
        
                        //try to smooth the polygon
                        // const cornerRadius = 10;
                        // const smoothedPolygonPath = d3.path();
                        // smoothedPolygonPath.moveTo(hull[0][0], hull[0][1]);
                        // for (let i = 1; i < hull.length; i++) {
                        //     const x0 = hull[i - 1][0];
                        //     const y0 = hull[i - 1][1];
                        //     const x1 = hull[i][0];
                        //     const y1 = hull[i][1];
                          
                        //     // Calculate the angle between the two line segments
                        //     const angle = Math.atan2(y1 - y0, x1 - x0);
                          
                        //     // Calculate the control points for the rounded corner
                        //     const cx0 = x0 + cornerRadius * Math.cos(angle - Math.PI / 2);
                        //     const cy0 = y0 + cornerRadius * Math.sin(angle - Math.PI / 2);
                          
                        //     // Draw the rounded corner using a quadratic Bézier curve
                        //     smoothedPolygonPath.quadraticCurveTo(cx0, cy0, x1, y1);
                        //   }
                          
                        //   // Close the path to create a closed shape
                        //   smoothedPolygonPath.closePath();
                          
                        //   // Append the smoothed polygon to the SVG
                        // this.svg.append("path")
                        //     .attr("d", smoothedPolygonPath.toString())
                        //     .attr("fill", "blue"); // Set the fill color
                        //smooth polygon
        
                        this.svg
                            .append("g")
                            .append("path")
                            .attr("d", line(hull))
                            .attr('fill', '#B3194A')
                            .attr("opacity", 0.25)
                            .attr('stroke-dasharray', '5,5')
                            .attr('stroke-width', 4)
                            .attr('stroke', 'black') // Border color
                            .style("pointer-events", "none")
                            .attr("id", "selectionAreaNr"+`${areaID}`);
                        areaID++;
                    }
                    
                });
    
                //calculate the coordinates of a center point given a set of point coordinates
                
                sortedXForAllCoordinate = [...listOfAllCoordinates].sort(function(a,b){return a[0] - b[0]})
                sortedYForAllCoordinate = [...listOfAllCoordinates].sort(function(a,b){return a[1] - b[1]}) 
                
                if(sortedXForAllCoordinate.length>0 && sortedYForAllCoordinate.length>0){                      
                    centerXCoordinateOfAllSelection = ((sortedXForAllCoordinate[0][0]+sortedXForAllCoordinate[sortedXForAllCoordinate.length-1][0])/2)*scalingFactor-offsetX
                    centerYCoordinateOfAllSelection = ((sortedYForAllCoordinate[0][1]+sortedYForAllCoordinate[sortedYForAllCoordinate.length-1][1])/2)*scalingFactor-offsetY
                    widthOfSelectionAreas = (sortedXForAllCoordinate[0][0]+sortedXForAllCoordinate[sortedXForAllCoordinate.length-1][0])*scalingFactor-offsetX
                    heightOfSelectionAreas = (sortedYForAllCoordinate[0][1]+sortedYForAllCoordinate[sortedYForAllCoordinate.length-1][1])*scalingFactor-offsetY        
                }
                
            }
            
        }

        // Creates areas to select a group of nodes
        this.createCircles = function (ids) {
            let selectedNodes = [];
            for (var i = 0; i < ids.length; ++i) {
                let node = nodeData.find(({id}) => id === ids[i])
                let pos = [node.xpos, node.ypos]
                selectedNodes.push(pos)
                
            }

            let sortedNodesX = [...selectedNodes].sort(function(a,b){return a[0] - b[0]})
            let sortedNodesY = [...selectedNodes].sort(function(a,b){return a[1] - b[1]})
            var points = []
            var polygon_points = []
            
            for (let i = 0; i < sortedNodesX.length; i++) {
                let x = sortedNodesX[i][0] * scalingFactor - offsetX
                let y = sortedNodesX[i][1] * scalingFactor - offsetY
                                       
                if (i==0) points.push(
                    {x: x-nodeRadius*1.5, low: y-nodeRadius*0.75, high: y+nodeRadius*0.75},
                    {x: x+nodeRadius*2, low: y-nodeRadius*1.25, high: y+nodeRadius*1.25})
                else if (i==sortedNodesX.length-1) points.push({x: x-nodeRadius*1.5, low: y-nodeRadius*1.5, high: y+nodeRadius*1.5},
                    {x: x+nodeRadius*1.5, low: y-nodeRadius*1.75, high: y+nodeRadius*1.75},) 
                else points.push({x: x-nodeRadius*2, low: y-nodeRadius*1.2, high: y+nodeRadius*1.2},
                    {x: x+nodeRadius*1.5, low: y-nodeRadius*1.25, high: y+nodeRadius*1.25})                   
            }

            //test polygon
            for (let i = 0; i < sortedNodesX.length; i++) {
                let x = sortedNodesX[i][0] * scalingFactor - offsetX
                let y = sortedNodesX[i][1] * scalingFactor - offsetY
                                       
                if (i==0) polygon_points.push(
                    {x: x-nodeRadius*1.5, y: y-nodeRadius*0.75},
                    {x: x-nodeRadius*1.5, y: y+nodeRadius*0.75},
                    {x: x+nodeRadius*2, y: y-nodeRadius*1.25},
                    {x: x+nodeRadius*2, y: y+nodeRadius*1.25})
                else if (i==sortedNodesX.length-1) polygon_points.push(
                    {x: x-nodeRadius*1.5, y: y-nodeRadius*1.5},
                    {x: x-nodeRadius*1.5, y: y+nodeRadius*1.5},
                    {x: x+nodeRadius*1.5, y: y-nodeRadius*1.75},
                    {x: x+nodeRadius*1.5, y: y+nodeRadius*1.75},) 
                else polygon_points.push(
                    {x: x-nodeRadius*2, y: y-nodeRadius*1.2, high: y+nodeRadius*1.2},
                    {x: x-nodeRadius*2, y: y+nodeRadius*1.2},
                    {x: x+nodeRadius*1.5, y: y-nodeRadius*1.25},
                    {x: x+nodeRadius*1.5, y: y+nodeRadius*1.25})                   
            }


            //to uncomment
            var areaGenerator = d3.area()
                .curve(d3.curveBasis)
                .x(function(d) {        // X coordinates points 
                    return d.x;
                })
                .y0(function(d) {       // X coordinates points
                    return d.low;
                })
                .y1(function(d) {       // X coordinates points
                    return d.high;
                });

            // Calls the areaGenerator
            var area = areaGenerator(points);
            //to uncomment 2

            // // Adds path area to the svg
            // nodes.append("circle")
            //     .attr("class", "team")
            //     .attr("r", 10)
            //     .attr('stroke', '#271B36')  // outside edge of the node
            //     .attr('fill', "black")
            //     // Position of the node
            //     .attr('x', function(d) {        // X coordinates points 
            //         return d.x;
            //     })
            //     .attr('y', function(d) {        // X coordinates points 
            //         return (d.low+d.high)/2;
            //     })
            //     .attr("opacity", 1)

            //to uncomment
            this.svg.append('path')
                .attr('d', area)
                .attr('fill', '#B3194A')
                .attr("opacity", 0.3)
                .style("stroke", "#B3194A")
                .style("stroke-dasharray", highlightCircleLine)
                .attr("stroke-width", highlightCircleWidth)
                .style("z-index", 1);
            
            var points_data = polygon_points.map((d)=>[d.x,d.y])
            var hull = d3.polygonHull(points_data)
            var line = d3.line().curve(d3.curveLinearClosed);
            this.svg.append("path").attr("d", line(hull)).attr('fill', '#B3194A').attr("opacity", 0.25);
        }
        

        // Draws impact and pleasantness for each node
        this.involved_people = async function (ids) {
            if(ids.length > 0) selected_employees = ids
            else selected_employees = all_employees
            filterTeamsProcess(selected_employees) 
        }
        
        this.highlightSelectedTeams = async function(ids){
            selected_employees = ids
            filterTeamsProcess(selected_employees) 
        }

        // Draws impact and pleasantness for each node
        this.zones = async function (value) {

            nodes.selectAll('.zone').remove();
            nodes.selectAll('.text').attr("fill", "#FCFCFC");
            
            if(value != ""){              
                prepareColorBlurring(nodes, 4);  
                var arc = d3.arc()
                    .innerRadius(nodeRadius*1.2)
                    .outerRadius(nodeRadius*2)
                    .startAngle(0) //convert from degs to radians
                    .endAngle(2*Math.PI) //just radians
                nodes.append("path")
                    .attr("class", 'zone')
                    .attr("d", arc)
                    .attr('fill', function (node) {
                            if(value == "Pleasantness"){
                                if(node.given_pleasantness > 1.0) return "#559B3E";
                                else if(node.given_pleasantness > 0.8) return "#89F066";
                                else if(node.given_pleasantness > 0.6) return "#FFBC1F";
                                else if(node.given_pleasantness > 0.4) return "#F72B2B";
                                else return "#B3194A";
                            }else if(value == "Impact"){
                                if(node.given_impact > 1.0) return "#559B3E";
                                else if(node.given_impact > 0.8) return "#89F066";
                                else if(node.given_impact > 0.6) return "#FFBC1F";
                                else if(node.given_impact > 0.4) return "#F72B2B";
                                else return "#B3194A";
                            }else if(value == "DrivingForce"){
                                if(node.given_driving_force > 1.0) return "#559B3E";
                                else if(node.given_driving_force > 0.8) return "#89F066";
                                else if(node.given_driving_force > 0.6) return "#FFBC1F";
                                else if(node.given_driving_force > 0.4) return "#F72B2B";
                                else return "#B3194A";
                            }
                        })
                    .attr('transform', function (node) {
                        return `translate(${node.xpos * scalingFactor - offsetX},${node.ypos * scalingFactor - offsetY})` //set the positions of the rings around the nodes
                    })
                    .style("filter", "url(#blur)")
                    .attr("opacity", function (node) {
                                    if(node.employee__user__first_name.includes("customer") || node.employee__user__first_name.includes("supplier")) return 0;
                                    else return 0.6;
                        })  
            }
        }

        //used for blurring a svg element
        function prepareColorBlurring(nodes, deviation) {
            var defs = nodes.append("defs");
            var filter = defs.append("filter")
                .attr("id", "blur")
                .attr("x", "-100%").attr("y", "-100%")
                .attr("width", "300%").attr("height", "300%");
        
            filter.append("feGaussianBlur")
                .attr("stdDeviation", deviation)
                .attr("result", "coloredBlur");
        }

        // Remove the node highlights
        function removeHighlights() {
            nodeHighlights.selectAll(".highlight").transition().duration(transitionDuration)
                .attr("r", 0)
                .attr("opacity", 0);
        }

        // Updates nodes and edges size to the zoom
        this.update_node_size = async function(zoom, currentZone, currentLeaders) {
            if(zoom > 1){
                nodeRadius = (nodeData.length / 2.5) / zoom;
                nodesFontSize = (nodeData.length / 3.5) / zoom;
                nodeRadius = nodeRadius;
                highlightCircleRadius = nodeRadius * 1.25;
                nodes.selectAll(".team").attr("r", function (node) {
                    if(node.team__name === null) return nodeRadius*1.5
                    else return nodeRadius
                    });
                nodes.selectAll(".text")
                    .style("font-size", function (node) {
                        if(node.team__name === null) return nodesFontSize*1.5
                        else return nodesFontSize
                    })
                    .attr("x", function (node) {
                        if(node.team__name === null) return node.xpos * scalingFactor - offsetX - nodeRadius / 1.15
                        else return node.xpos * scalingFactor - offsetX - nodeRadius / 2.5
                    })    
                    .attr("y", function (node) {
                        if(node.team__name === null) return node.ypos * scalingFactor - offsetY + nodeRadius / 3.5
                        else return node.ypos * scalingFactor - offsetY + nodeRadius / 4.5
                    });
                nodes.selectAll(".zone").attr("r", nodeRadius * 2); 

                if(currentZone !== ""){
                    this.zones(currentZone);
                }
                if(currentLeaders !== ""){
                    this.highlightNodes(currentLeaders);
                }
            }
        }
    }

}

export default SocialNetwork;
