import React, {useContext, useState, useEffect} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Fade, Tab, Tabs, Tooltip} from '@mui/material';
import {OverlayContext} from "../../layout-and-styling/context-hooks/OverlayContext";
import BarCharts from '../specific-components/Component-bar-charts';
import {DashboardContext} from "../DashboardContext";
import load from "../../assets/dashboard/load.gif";
import { Rectangle } from 'recharts';


const Metrics = () => {
    const context = useContext(OverlayContext);
    const {t, i18n} = useTranslation();

    const [selectedElephant, setSelectedElephant] = useState(0);
    const [metricsData, setMetricsData] = useState(JSON.parse(localStorage.getItem("metrics_data")));
    const [hoverMetric, setHoverMetric] = useState(false);
    const [hoveredMetricScore, setHoveredMetricScore] = useState(false);

    /** Adding a listener that runs when graph data is sotred in the local storage */
    useEffect(() => {      
        window.addEventListener('metrics_data_event', () => {
          setMetricsData(JSON.parse(localStorage.getItem("metrics_data")));
        })
    }, [])

    // Mouse position to move the top teams tags perspective about the elephant
    const [mouseCoordinates, setMouseCoordinates] = useState({x:0, y:0});

    const mouseMoveHandler = (event) => {
    	setMouseCoordinates({
      		x:event.clientX,
      		y:event.clientY
    	});
  	}   
    
    useEffect(()=>{
    	window.addEventListener('mousemove', mouseMoveHandler);
    	return(()=>{
      		window.removeEventListener('mousemove', mouseMoveHandler);
    	})
  	}, []) 

    function hoverMetricCell(hover, score) {
      setHoverMetric(hover)
      setHoveredMetricScore(score)
    }

    function hoverMetricCellPosition(mousePosition) {
      return {x: mousePosition.x - 112, y: mousePosition.y - 12}
    }

    return (
      <Container>
        <ComponentsWrapper Background="#FCFCFC">

          <ProcessTitle>
              {t('DashboardFollowUp.ElephantsTitle')}
          </ProcessTitle> 

          {!(metricsData && metricsData.elephants_data.length > 0) ? (
              <LoadLogo Top="0%">
                {" "}
                <img src={load} alt="loading..."/>
              </LoadLogo>
            ) : (
              <CriticalProblemWrapper FlexDirection="row">
                  {metricsData.elephants_data.map((item, index) => {
                      return (
                          <CriticalProblemContainer onClick={() => setSelectedElephant(index)} Border={selectedElephant === index ? "1px solid #E2336B" : ""} >
                              <CriticalProblemNumber>
                                  {index + 1}
                              </CriticalProblemNumber>

                              <CriticalProblemName>
                                  {context.setProcess(item.elephant, i18n.language)}
                              </CriticalProblemName>

                              <CriticalProblemButton Background={selectedElephant === index ? "#E2336B" : "#F1F3F4"} Color={selectedElephant === index ? "#FCFCFC" : "#271B36"}>
                                {t('DashboardFollowUp.Analysis')}
                              </CriticalProblemButton>
                          </CriticalProblemContainer>
                      )

                  })}

              </CriticalProblemWrapper>
          )}


          <MetricsWrapper>
              <LeftContainer>
                  <ProcessTitle>
                      {t('DashboardFollowUp.MetricsLeftTitle')}
                  </ProcessTitle>

                  <ProcessSubtitle>
                      {t('DashboardFollowUp.MetricsLeftSubtitle')}
                  </ProcessSubtitle>

                  <Categories>
                      {t('DashboardFollowUp.Categories')}
                  </Categories>

                  {!(metricsData && metricsData.elephants_data.length > 0) ? (
                      <LoadLogo Top="35%">
                          {" "}
                          <img src={load} alt="loading..."/>
                      </LoadLogo>
                  ) : (
                      <BarCharts ComponentShown={"followUp"} data={metricsData.elephants_data[selectedElephant].teams_metrics} maxValue={1}/>
                  )}

              </LeftContainer>

              <RightContainer>
                  <ProcessTitle>
                      {t('DashboardFollowUp.MetricsRightTitle')}
                  </ProcessTitle>

                  <ProcessSubtitle>
                      {t('DashboardFollowUp.MetricsRightSubtitle')}
                  </ProcessSubtitle>

                  {!(metricsData && metricsData.elephants_data.length > 0) ? (
                      <LoadLogo Top="47%">
                          {" "}
                          <img src={load} alt="loading..."/>
                      </LoadLogo>
                  ) : (
                      
                    <MatrixWrapper>
                      <RowNameWrapper>
                          {metricsData.elephants_data[selectedElephant].teams_communication.map((current_item, index) => {
                              return(
                                  <RowName>
                                    {context.setTeams(current_item["team"].toString(), i18n.language)}
                                  </RowName>
                              )
                          })}
                          
                      </RowNameWrapper>
                      {metricsData.elephants_data[selectedElephant].teams_communication.map((current_item, index) => {
                          return (
                              <CellsWrapper>
                                  <CellsLeftText>
                                    {context.setTeams(current_item["team"].toString(), i18n.language)} {t('DashboardFollowUp.CommunicationWith')}
                                  </CellsLeftText>
                                  {current_item["scores"].map((score, index_scores) => {
                                      return (
                                          <ColorRectangle Background={score >= 1.0 ? "#06D6A0" : score >= 0.5 ? "#FFD166" : "#F72B2B"} onMouseOver={() => hoverMetricCell(true, score)} onMouseOut={() => hoverMetricCell(false, 0)}>
                                            {score >= 1.0 ? t('DashboardFollowUp.AboveAverage') : score >= 0.5 ? t('DashboardFollowUp.Average') : t('DashboardFollowUp.BelowAverage')}
                                          </ColorRectangle>
                                        
                                      )
                                      
                                  })}
                                  <CellsLeftText>
                                    {t('DashboardFollowUp.AccordingTo')} {context.setTeams(current_item["team"].toString(), i18n.language)}
                                  </CellsLeftText>
                              </CellsWrapper>
                          )
                      })}
                      <CellLegend Display={hoverMetric ? "flex" : "None"} Top={hoverMetricCellPosition(mouseCoordinates).y + "px"} Left={hoverMetricCellPosition(mouseCoordinates).x + "px"}>
                        {Math.round(hoveredMetricScore * 100)}% collaboration
                      </CellLegend>
                    </MatrixWrapper>
                  )}
              </RightContainer>
          </MetricsWrapper>
            
        </ComponentsWrapper>
      </Container>

    )
}

export default Metrics

const Container = styled.div`
  font-family: 'Overpass', sans-serif;
  width: calc(100%);
  display: flex;
  flex-direction: row;
  z-index: 6;
  margin-top: 24px;
  margin-left: 42px;
  margin-right: 42px;
`;

const ComponentsWrapper = styled.div`
  display: flex;
  background: ${ props => props.Background || "" };
  padding-left: 32px;
  padding-right: 32px;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
`;

const ProcessTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #271B36;
  margin-top: 20px;
  margin-left: 24px;
`;

const ProcessSubtitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #5F6366;
  margin-top: 12px;
  margin-left: 24px;
`;

const Categories = styled.div`
  color: #271B36;
  text-align: center;
  font-size: 14px;
  font-family: Overpass;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  margin-top: 40px;
`;


const CriticalProblemWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.FlexDirection || ""};
  justify-content: center;
  gap: 8px;
  margin-left: 20px;
  margin-top: 16px;
  margin-bottom: 24px;
  gap: 24px;
`;

const CriticalProblemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${ props => props.Width || "" };
  border-radius: 5px;
  padding: 18px;
  gap: 40px;
  background: #FCFCFC;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
  border: ${ props => props.Border || "" };
`;

const CriticalProblemNumber = styled.div`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #E2336B;
  color: #FCFCFC;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  justify-content: center;
  display: flex;
`;

const CriticalProblemName = styled.div`
  display: flex;
  color: #271B36;
  font-size: 14px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

const CriticalProblemButton = styled.div`
  display: flex;
  padding: 4px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${ props => props.Color || "" };
  border-radius: 5px;
  background: ${ props => props.Background || "" };
`;

const MetricsWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  gap: 24px;
  margin-bottom: 40px;
  flex-direction: row;
`;

const LeftContainer = styled.div`
  width: 832px;
  height: 584px;
  border-radius: 5px;
  background: #FCFCFC;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
`;

const RightContainer = styled.div`
  width: 832px;
  height: 584px;
  border-radius: 5px;
  background: #FCFCFC;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
`;

const MatrixWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 34px;
  gap: 16px;
`;

const RowNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const RowName = styled.div`
  display: flex;
  border-radius: 5px;
  background: #F1F3F4;
  width: 124px;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 80px;
`;

const CellsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  align-items: center;
`;

const ColorRectangle = styled.div`
  display: flex;
  width: 124px;
  height: 124px;
  flex-direction: column;
  justify-content: center;
  color: #fcfcfc;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  background: ${props => props.Background || "#fcfcfc"};
`;

const CellLegend = styled.div`
  position: absolute;
  display: ${props => props.Display || "None"};
  width: fit-content;
  height: fit-content;
  top: ${props => props.Top || ''};
  left: ${props => props.Left || ''};
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  color: #271B36;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  background: #FCFCFC;
`;

const CellsLeftText = styled.div`
  color: #292929;
  text-align: center;
  width: 127px;
  font-size: 14px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;


const LoadLogo = styled.div`
  img {
    width: 50px;
  }
  display: flex;
  position: relative;
  top: ${ props => props.Top || "50%" };
  left: 50%;
`;