import {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { StyledDivButton } from '../layout-and-styling/standardized-components-library/Styling-TextInput';
import Logo from '../assets/LogoElephantsLanding.svg';
import SurveyLogo from '../assets/flat-color-icons_survey.svg'
import SurveyOverlay from '../assets/LandingSurveyOverlay.svg'
import IntakeLogo from '../assets/colors_icon_intake_logo.svg'
import IntakeOverlay from '../assets/LandingIntakeOverlay.svg'
import DashboardLogo from '../assets/carbon_dashboard.svg'
import DashboardOverlay from "../assets/LandingDashboardOverlay.svg"
import {DashBoardAvailibility, DashboardFollowUpPage, DeveloperDashboardFollowUpPage, FollowUpRepresentativePage } from "../layout-and-styling/standardized-components-library/Component-API";
import i18next from "i18next";
import { OverlayContext } from '../layout-and-styling/context-hooks/OverlayContext';
import {useTranslation} from 'react-i18next';
import {Accordion, AccordionDetails, AccordionSummary, Switch} from '@mui/material';
import {Select, MenuItem, FormControl, InputLabel} from "@mui/material";
import ComponentLanguageSwitcher from "../layout-and-styling/standardized-components-library/Component-language-switcher";
import { waitFor } from '@testing-library/react';

const CurrentUserData = JSON.parse(localStorage.getItem('current_user_data'));
const IntakeData = JSON.parse(localStorage.getItem('intakeData'));

const LandingIntroduction = () => {
    const {t} = useTranslation();
    const demoMode = JSON.parse(localStorage.getItem('demo_mode'));
    const context = useContext(OverlayContext);
    const [companies_data, setCompaniesData] = useState(JSON.parse(localStorage.getItem("companies_data")) ? JSON.parse(localStorage.getItem("companies_data")) : []);
    const [expanded, setExpanded] = useState(false);
    const [render, setRender] = useState(false);
    const [allowDash, setAllowDash] = useState(false);
    const [allowSurvey, setAllowSurvey] = useState(false);

    const reRender = () => {
        setRender(!render)
    };

    const [selectedCompany, setSelectedCompany] = useState(CurrentUserData[0].is_developer ? companies_data.filter((comp) => comp.id === CurrentUserData[0].company)[0] : CurrentUserData[0].company);
    const [selectedSurvey, setSelectedSurvey] = useState(CurrentUserData[0].is_developer ? selectedCompany.current_survey : 0);

    /** Check if already authenticated, and bring to dashboard if possible.*/
    useEffect(() => {
        const axiosInstance = axios.create({withCredentials: true})
        // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
            .catch((error) => {
                console.log('error, you are not logged in yet!')
                window.location.replace(process.env.REACT_APP_APPURL + '/login');
            })

        if (CurrentUserData[0].is_developer) {
            setCompaniesData(JSON.parse(localStorage.getItem("companies_data")))
            
            setAllowDash(JSON.parse(localStorage.getItem('allow_dashboard')));
        }
        else{
          setAllowDash(JSON.parse(localStorage.getItem('allow_dashboard')));
        }
    }, []);

    useEffect(() => {
      const axiosInstance = axios.create({withCredentials: true})
      // Perform a GET request to see if processes are present and if so allow the survey
      axiosInstance.get(process.env.REACT_APP_APIURL + '/api/myprocesses/')
        .then(response => {
          if(JSON.stringify(response.data) == '[]'){
              setAllowSurvey(false)
          }
          else{setAllowSurvey(true)}
        })
        .catch((error) => {
          console.log(error);
      });
    }, [])

    const goToSurvey = () => {
        window.location.replace(process.env.REACT_APP_APPURL + '/user-welcome')
    }

    const goToIntake = () => {
      window.location.replace(process.env.REACT_APP_APPURL + '/intake-builder')
    }

    async function goToDashboard(developer) {
      if (developer) {
        if(! (JSON.parse(localStorage.getItem("business_process_data")) && JSON.parse(localStorage.getItem("votes_data")) && JSON.parse(localStorage.getItem("dashboard_votes_per_team")) && JSON.parse(localStorage.getItem("dashboard_tags_per_team")))) {
          localStorage.setItem('selected_company', JSON.stringify(selectedCompany))
          localStorage.setItem('selected_survey', JSON.stringify(selectedSurvey))
          if(selectedSurvey != 9) await DeveloperDashboardFollowUpPage(selectedCompany.id, selectedSurvey);
          window.location.replace(process.env.REACT_APP_APPURL + "/dashboard");
        } 
        else {
          let old_company = JSON.parse(localStorage.getItem("business_process_data"))[0].company_id
          let old_survey = JSON.parse(localStorage.getItem("business_process_data"))[0].survey_id
          if (selectedCompany.id != old_company || selectedSurvey != old_survey){
            localStorage.removeItem("business_process_data");
            localStorage.removeItem("votes_data");
            localStorage.removeItem("dashboard_votes_per_team");
            localStorage.removeItem("dashboard_tags_per_team");
            localStorage.setItem('selected_company', JSON.stringify(selectedCompany))
            localStorage.setItem('selected_survey', JSON.stringify(selectedSurvey))
            if(selectedSurvey != 9) await DeveloperDashboardFollowUpPage(selectedCompany.id, selectedSurvey);
          } 
          window.location.replace(process.env.REACT_APP_APPURL + "/dashboard"); 
        }
      } else{
        localStorage.setItem('selected_company', JSON.stringify(selectedCompany))
        localStorage.setItem('selected_survey', JSON.stringify(selectedSurvey));
        if(selectedSurvey != 9) await DashboardFollowUpPage();
        window.location.replace(process.env.REACT_APP_APPURL + "/dashboard");
      }
    }


    const toggleDemoMode = (value) => {
        localStorage.setItem('demo_mode', JSON.stringify(value));
        reRender();
    }

    const handleAccordion = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    }

    const handleSelectedCompany = (e) => {
      if(e.target.value == "None"){
        setSelectedCompany(companies_data.filter((comp) => comp.id === CurrentUserData[0].company)[0])
        setSelectedSurvey(companies_data.filter((comp) => comp.id === CurrentUserData[0].company)[0].current_survey)
      } else {
        setSelectedCompany(e.target.value)
        setSelectedSurvey(e.target.value.current_survey)
      }
    }

    const handleSelectedSurvey = (e) => {
      setSelectedSurvey(e.target.value)
    }

    return(
        <Container>
          <ComponentLanguageSwitcher Top="10px"/>
            {CurrentUserData[0].is_developer &&
                <Wrapper>
                    <LogoWrapper>
                        <img src={Logo} alt=""/>
                    </LogoWrapper>
                    
                    <NavigationWrapper>
                        <TitleWrapper>
                            <Title> {t('LandingPage.Title')}, {context.setFirstName(CurrentUserData[0]['first_name'], i18next.language)}! </Title>

                            <Subtitle>{t('LandingPage.Subtitle')}</Subtitle>

                        </TitleWrapper>
                        {IntakeData != null &&
                        <Intake Background={IntakeOverlay} onClick={() => goToIntake()}>
                          <IntakeContent>
                            <img src={IntakeLogo} alt=""></img>
                            <IntakeText>
                              <IntakeTitle>{t('LandingPage.Intake')}</IntakeTitle>
                              <IntakeDescription>
                                {t('LandingPage.IntakeDescription')}
                              </IntakeDescription>
                            </IntakeText>
                          </IntakeContent>
                        </Intake>}
                        <Survey Background={SurveyOverlay} onClick={() => goToSurvey()} changeOpacity={allowSurvey} changeEvent={allowSurvey}>
                            <SurveyContent>
                                <img src={SurveyLogo} alt=""/>

                                <SurveyText>
                                    <SurveyTitle>{t('LandingPage.Survey')} </SurveyTitle>
                                    <SurveyDescription>
                                        {t('LandingPage.SurveyDescription')}
                                    </SurveyDescription>
                                </SurveyText>
                            </SurveyContent>
                        </Survey>

                        <Accordion expanded={expanded === 'dashboard'} onChange={handleAccordion('dashboard')}
                              sx={{ width: "fit-content", justifyContent: 'center'}}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header"
                                sx={{ color: '#292929', fontSize: 16, fontFamily: 'Overpass', fontStyle: 'normal', fontWeight: 700,
                                    '& .MuiAccordionSummary-content': {
                                        justifyContent: 'center',
                                    }
                                }}>
                                
                                <Dashboard Background={DashboardOverlay} changeOpacity={allowDash} changeEvent={allowDash}>
                                  <DashboardContent>
                                      <img src={DashboardLogo} alt=""/>

                                      <DashboardText>
                                          <DashboardTitle> {t('LandingPage.Dashboard')}
                                          </DashboardTitle>

                                          <DashboardDescription>
                                              {t('LandingPage.DashboardDescription')}
                                          </DashboardDescription>
                                      </DashboardText>
                                  </DashboardContent>
                                </Dashboard>
                            </AccordionSummary>

                            <AccordionDetails sx={{display: 'flex', flexDirection: 'row', height: 'fit-content'}} >
                                <DeveloperViewDashboard>
                                  <ToggleDemoMode>
                                      <TextToggle>Demo mode:</TextToggle>
                                      <Switch checked={demoMode} onChange={(event, value) => toggleDemoMode(value)}/>
                                  </ToggleDemoMode>

                                  <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-filled-label">Company</InputLabel>
                                      <Select value={selectedCompany} label="Company" onChange={handleSelectedCompany} >
                                          {companies_data && companies_data.map((company, company_index) => {
                                              if(company.id === 4) {
                                                return (
                                                  <MenuItem value={company}>{!demoMode ? company.name : "Venice"}</MenuItem>
                                                )
                                              } else if(company.id === 6) {
                                                return (
                                                  <MenuItem value={company}>{!demoMode ? company.name : "Avocado"}</MenuItem>
                                                )
                                              } else if(company.id === 3) {
                                                return (
                                                  <MenuItem value={company}>{company.name}</MenuItem>
                                                )
                                              }
                                          })}
                                      </Select>
                                  </FormControl>

                                  <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-filled-label">Survey</InputLabel>
                                      <Select value={selectedSurvey} label="Survey" onChange={handleSelectedSurvey}  >
                                        {selectedCompany.survey_set && selectedCompany.survey_set.map((survey, company_index) => {
                                              if(survey === 1) { // EITR survey 1
                                                return (
                                                  <MenuItem value={survey}>1</MenuItem>
                                                )
                                              } else if(survey === 9) { // Venice survey 0
                                                return (
                                                  <MenuItem value={survey}>0</MenuItem>
                                                )
                                              } else if(survey === 2) { // Venice survey 1
                                                return (
                                                  <MenuItem value={survey}>1</MenuItem>
                                                )
                                              } else if(survey === 3) { // Avocado survey 1
                                                return (
                                                  <MenuItem value={survey}>1</MenuItem>
                                                )
                                              } else if(survey === 5) { // Avocado survey 2
                                                return (
                                                  <MenuItem value={survey}>2</MenuItem>
                                                )
                                              } else if(survey === 6) { // EITR survey 2
                                                return (
                                                  <MenuItem value={survey}>2</MenuItem>
                                                )
                                              } else if(survey === 8) { // VPC survey 3
                                                return (
                                                  <MenuItem value={survey}>3</MenuItem>
                                                )
                                              }
                                        })}
                                      </Select>
                                  </FormControl>

                                  <StyledDivButton MarginTop='0px' MarginLeft='32px' Width='64px' Height='fit-content' BoxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)' 
                                                        Padding='8px 4px 8px 4px' FontSize='16px' Display='flex' JustifyContent='center' Position="relative" BackgroundColor={'#FCFCFC'}
                                                        Border={'0px solid #271B36'} Color={'#271B36'}>
                                        <button onClick={() => goToDashboard(true)} > Go </button>
                                  </StyledDivButton>
                                </DeveloperViewDashboard>
                            </AccordionDetails>
                            
                        </Accordion>

                    </NavigationWrapper>

                </Wrapper>
            }

            {!CurrentUserData[0].is_developer &&
                <Wrapper>
                    <LogoWrapper>
                    
                        <img src={Logo} alt=""/>
                    </LogoWrapper>
                    <NavigationWrapper>
                        <TitleWrapper>
                            <Title> {t('LandingPage.Title')}, {context.setFirstName(CurrentUserData[0]['first_name'], i18next.language)}! </Title>

                            <Subtitle>{t('LandingPage.Subtitle')}</Subtitle>

                        </TitleWrapper>
                        {IntakeData != null &&
                        <Intake Background={IntakeOverlay} onClick={() => goToIntake()}>
                          <IntakeContent>
                            <img src={IntakeLogo} alt=""></img>
                            <IntakeText>
                              <IntakeTitle>{t('LandingPage.Intake')}</IntakeTitle>
                              <IntakeDescription>
                                {t('LandingPage.IntakeDescription')}
                              </IntakeDescription>
                            </IntakeText>
                          </IntakeContent>
                        </Intake>}
                        <Survey Background={SurveyOverlay} onClick={() => goToSurvey()} changeOpacity={allowSurvey} changeEvent={allowSurvey}>
                            <SurveyContent>
                                <img src={SurveyLogo} alt=""/>

                                <SurveyText>
                                    <SurveyTitle>{t('LandingPage.Survey')} </SurveyTitle>
                                    <SurveyDescription>
                                        {t('LandingPage.SurveyDescription')}
                                    </SurveyDescription>
                                </SurveyText>
                            </SurveyContent>


                        </Survey>

                        <Dashboard Background={DashboardOverlay} onClick={() => goToDashboard(false)} changeOpacity={allowDash} changeEvent={allowDash}>
                            <DashboardContent>
                                <img src={DashboardLogo} alt=""/>

                                <DashboardText>
                                    <DashboardTitle> {t('LandingPage.Dashboard')}
                                    </DashboardTitle>

                                    <DashboardDescription>
                                        {t('LandingPage.DashboardDescription')}
                                    </DashboardDescription>

                                </DashboardText>
                            </DashboardContent>

                        </Dashboard>
                    </NavigationWrapper>

                </Wrapper>
            }
        </Container>
    )
};
export default LandingIntroduction

const Container = styled.div`
  background: #FCFCFC;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // -ms-overflow-style: none;  /* IE and Edge */
  // scrollbar-width: none;  
  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: calc(1 / 1.75);
  }
`;

const Wrapper = styled.div`
  width: 1920px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 36px;
  margin-left: 45px;
`;


const TitleWrapper = styled.div`
  justify-content: left;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  justify-content: left;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 52px;
  margin-bottom: 40px;
`;

const Subtitle = styled.div`
  justify-content: center;
  display: flex;
  font-family: 'Overpass', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #271B36;
  margin-bottom: 40px;

`;

const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Intake = styled.div`
  width: 720px;
  height: 106px;
  left: 679px;
  top: 408px;
  background: #FCFCFC;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 32px;
  background: url(${props => props.Background || ""}) no-repeat right bottom;
  cursor: pointer;
`;

const IntakeContent = styled.div`
  margin: 25px;
  display: flex;
  gap: 24px;
`;

const IntakeText = styled.div`
  display: flex;
  flex-direction: column;
`;

const IntakeTitle = styled.div`
  font-family: 'Overpass', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
`;

const IntakeDescription = styled.div`
  font-family: 'Overpass', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
`;

const Survey = styled.div`
  width: 720px;
  height: 106px;
  left: 679px;
  top: 408px;
  background: #FCFCFC;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 32px;
  background: url(${props => props.Background || ""}) no-repeat right bottom;
  cursor: pointer;
  opacity: ${props => props.changeOpacity ? "1" : "0.2"};
  pointer-events: ${props => props.changeEvent ? "auto" : "none"};
`;

const SurveyContent = styled.div`
  margin: 20px;
  display: flex;
  gap: 24px;
`;

const SurveyText = styled.div`
  display: flex;
  flex-direction: column;
`;

const SurveyTitle = styled.div`
  font-family: 'Overpass', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
`;

const SurveyDescription = styled.div`
  font-family: 'Overpass', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
`;

const Dashboard = styled.div`
  width: 720px;
  height: 106px;
  left: 679px;
  top: 408px;
  background: #FCFCFC;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background: url(${props => props.Background || ""}) no-repeat right bottom;
  cursor: pointer;
  opacity: ${props => props.changeOpacity ? "1" : "0.2"};
  pointer-events: ${props => props.changeEvent ? "auto" : "none"};
`;

const DashboardContent = styled.div`
  margin: 20px;
  display: flex;
  gap: 24px;
  padding-left: 7.5px;
`;

const DashboardText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 7.5px;
`;

const DashboardTitle = styled.div`
  font-family: 'Overpass', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
`;


const DashboardDescription = styled.div`
  font-family: 'Overpass', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
`;

const DeveloperViewDashboard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-content;
`;

const ToggleDemoMode = styled.div`
  margin: 0 40px;
  align-content: center;
  font-family: 'Overpass', sans-serif;
  justify-content: center;
`;

const TextToggle = styled.div`
  font-size: 16px;
`;

const SurveyCompanies = styled.div`
  width: 560px;
  height: 200px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  z-index: -1;
  position: relative;
  flex-direction: row;
  padding-top: 40px;
  display: flex;
`;

const CompanyWrapper = styled.div`
  border: 1px solid #B3194A;
  border-radius: 5px;
  height: 80px;
  width: 200px;
  display: flex;
  margin: 0 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const DashboardCompanies = styled.div`
  width: 720px;
  height: fit-content;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  position: relative;
  flex-direction: row;
  padding: 32px 0 32px 0;
  display: flex;
  justify-content: center;
`;
