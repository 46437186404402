import { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as DashboardLogo } from "../assets/dashboard/EITR_Logo.svg";
import { ReactComponent as DashboardLogoExpanded } from "../assets/dashboard/EITR_Logo_Expanded.svg";
import { ReactComponent as ElephantIdentificationIcon } from "../assets/dashboard/Sidebar/ElephantIdentificationIcon.svg";
import { ReactComponent as ElephantIdentificationIconClicked } from "../assets/dashboard/Sidebar/ElephantIdentificationIconClicked.svg";
import { ReactComponent as DiagnosticsIcon } from "../assets/dashboard/Sidebar/DiagnosticsIcon.svg";
import { ReactComponent as DiagnosticsIconClicked } from "../assets/dashboard/Sidebar/DiagnosticsIconClicked.svg";
import { ReactComponent as ActionsIcon } from "../assets/dashboard/Sidebar/ActionsIcon.svg";
import { ReactComponent as ActionsIconClicked } from "../assets/dashboard/Sidebar/ActionsIconClicked.svg";
import { ReactComponent as TransformationIcon } from "../assets/dashboard/Sidebar/TransformationIcon.svg";
import { ReactComponent as TransformationIconClicked } from "../assets/dashboard/Sidebar/TransformationIconClicked.svg";
import { ReactComponent as HighlightsIcon } from "../assets/dashboard/Sidebar/HighlightsIcon.svg";
import { ReactComponent as HighlightsIconClicked } from "../assets/dashboard/Sidebar/HighlightsIconClicked.svg";
import { ReactComponent as SeparationLineShort } from "../assets/dashboard/SeparationLineShort.svg";
import { ReactComponent as SeparationLineShortExpanded } from "../assets/dashboard/SeparationLineShortExpanded.svg";
import { ReactComponent as HelpIcon } from "../assets/dashboard/HelpIcon.svg";
import { ReactComponent as FlagCh } from "../assets/translation/flag-ch.svg";
import { ReactComponent as FlagNl } from "../assets/translation/flag-nl.svg";
import { ReactComponent as FlagEn } from "../assets/translation/flag-en.svg";
import { ReactComponent as SeparationLineLong } from "../assets/dashboard/SeparationLineLong.svg";
import { ReactComponent as SeparationLineLongExpanded } from "../assets/dashboard/SeparationLineLongExpanded.svg";
import { ReactComponent as Logout } from "../assets/dashboard/logout.svg";

import ComponentLanguageSwitcher from "../layout-and-styling/standardized-components-library/Component-language-switcher";

import { DashboardContext } from "./DashboardContext";
import { OverlayContext } from "../layout-and-styling/context-hooks/OverlayContext";
import { useTranslation } from "react-i18next";
import { DashboardSocialNetworkPage, DeveloperDashboardSocialNetworkPage , 
  DeveloperDashboardMetricsPage, DashboardMetricsPage,
  DeveloperDashboardActionsPage, DashboardActionsPage,
  DeveloperDashboardTransformationPage, DashboardTransformationPage,
  DeveloperDashboardHighlightsPage} from "../layout-and-styling/standardized-components-library/Component-API";

import { StyledDivRegular } from "../layout-and-styling/standardized-components-library/Styling-TextInput";
import {ReactComponent as IconArrowOpen} from "../assets/SurveyCommunication/IconTeamArrowOpen.svg";
import {ReactComponent as IconArrowClosed} from "../assets/SurveyCommunication/IconTeamArrowClosed.svg";
import {ReactComponent as IconArrowPinkUp} from "../assets/dashboard/ArrowPinkUp.svg";
import {ReactComponent as DropdownLine} from "../assets/dashboard/DropdownLine.svg";

const ComponentDashboardSidebar = ({ sidebarOpen, setSideBarOpen }) => {
  const dashboard_context_value = useContext(DashboardContext);
  const selectedCompany = JSON.parse(localStorage.getItem("selected_company"));
  const selectedSurvey = JSON.parse(localStorage.getItem("selected_survey"));
  const currentUser = JSON.parse(localStorage.getItem("current_user_data"))[0];
  const context = useContext(OverlayContext);

  const [render, setRender] = useState(true);
  const reRender = () => {
    setRender(!render);
  };

  const { t, i18n } = useTranslation();

  // Function that make the api call to get the metrics data if necessary
  async function goToMetrics() {
    if (currentUser.is_developer) {
      if(! JSON.parse(localStorage.getItem("metrics_data"))) await DeveloperDashboardMetricsPage(selectedCompany.id, selectedSurvey);
      else {
        let old_company = JSON.parse(localStorage.getItem("metrics_data")).company
        let old_survey = JSON.parse(localStorage.getItem("metrics_data")).survey
        if (selectedCompany.id != old_company || selectedSurvey != old_survey){
          localStorage.removeItem("metrics_data");
          await DeveloperDashboardMetricsPage(selectedCompany.id, selectedSurvey);
        } 
      }
    } else {
        if(! JSON.parse(localStorage.getItem("metrics_data"))) DashboardMetricsPage();
    }
  }

  // Function that make the api call to get the social netowrk data if necessary
  async function goToSocialNetwork() {
    if (currentUser.is_developer) {
      if(! JSON.parse(localStorage.getItem("graph_data"))) await DeveloperDashboardSocialNetworkPage(selectedCompany.id, selectedSurvey);
      else {
        let old_company = JSON.parse(localStorage.getItem("graph_data")).company
        let old_survey = JSON.parse(localStorage.getItem("graph_data")).survey
        if (selectedCompany.id != old_company || selectedSurvey != old_survey){
          localStorage.removeItem("graph_data");
          await DeveloperDashboardSocialNetworkPage(selectedCompany.id, selectedSurvey);
        } 
      }
    } else {
        if(! JSON.parse(localStorage.getItem("graph_data"))) DashboardSocialNetworkPage();
    }
  }

  // Function that make the api call to get the actions data if necessary
  async function goToActions() {
    if (currentUser.is_developer) {
      if(! JSON.parse(localStorage.getItem("actions_data"))) await DeveloperDashboardActionsPage(selectedCompany.id, selectedSurvey);
      else {
        let old_company = JSON.parse(localStorage.getItem("actions_data")).company
        let old_survey = JSON.parse(localStorage.getItem("actions_data")).survey
        if (selectedCompany.id != old_company || selectedSurvey != old_survey){
          localStorage.removeItem("actions_data");
          await DeveloperDashboardActionsPage(selectedCompany.id, selectedSurvey);
        } 
      }
    } else {
        if(! JSON.parse(localStorage.getItem("actions_data"))) DashboardActionsPage();
    }
  }

  // Function that make the api call to get the transformation data if necessary
  async function goToTransformation() {
    if (currentUser.is_developer) {
      if(! JSON.parse(localStorage.getItem("transformation_data"))) await DeveloperDashboardTransformationPage(selectedCompany.id, selectedSurvey);
      else {
        let old_company = JSON.parse(localStorage.getItem("transformation_data")).company
        let old_survey = JSON.parse(localStorage.getItem("transformation_data")).survey
        if (selectedCompany.id != old_company || selectedSurvey != old_survey){
          localStorage.removeItem("transformation_data");
          await DeveloperDashboardTransformationPage(selectedCompany.id, selectedSurvey);
        } 
      }
    } else {
        if(! JSON.parse(localStorage.getItem("transformation_data"))) DashboardTransformationPage();
    }
  }

  // Function that make the api call to get the highlights data if necessary
  async function goToHighLights() {
    if (currentUser.is_developer) {
      if(! JSON.parse(localStorage.getItem("highlights_data"))) await DeveloperDashboardHighlightsPage(selectedCompany.id, selectedSurvey);
      else {
        let old_company = JSON.parse(localStorage.getItem("highlights_data")).company
        let old_survey = JSON.parse(localStorage.getItem("highlights_data")).survey
        if (selectedCompany.id != old_company || selectedSurvey != old_survey){
          localStorage.removeItem("highlights_data");
          await DeveloperDashboardHighlightsPage(selectedCompany.id, selectedSurvey);
        } 
      }
    }
  }

  useEffect(() => {
    if (
      !i18n.language ||
      (i18n.language != "en-US" &&
        i18n.language != "ch-CH" &&
        i18n.language != "nl-NL")
    ) {
      i18n.changeLanguage("en-US");
    }
  }, []);

  function getFlag(flagCode) {
    if (flagCode === "ch") return <FlagCh />;
    else if (flagCode === "nl") return <FlagNl />;
    else return <FlagEn />;
  }

  return (
    <SidebarContainer Width={sidebarOpen ? "232px" : "88px"}>
      <HeaderLogoWrapper
        onClick={() =>
          window.location.replace(process.env.REACT_APP_APPURL + "/home")
        }
      >
        {sidebarOpen ? <DashboardLogoExpanded /> : <DashboardLogo />}
      </HeaderLogoWrapper>
      
      {/* Elephants Identification Tab */}
      <SidebarButton Width={sidebarOpen ? "2000px" : "44px"} Background={ dashboard_context_value.tabSelection === "TabElephantIdentification" ? "#F7C9D9" : "transparent"}
        Color={ dashboard_context_value.tabSelection === "TabElephantIdentification" ? "#B3194A" : "#5F6366" } MarginTop="0px">
        <button
          onClick={() => {
            dashboard_context_value.setTabSelection("TabElephantIdentification");
            setSideBarOpen(true);
          }}
        >
          {dashboard_context_value.tabSelection === "TabElephantIdentification" ? (
            <>
              <ElephantIdentificationIconClicked />
              <SidebarButtonText Display={sidebarOpen === false ? "none" : ""} Color={dashboard_context_value.tabSelection === "TabElephantIdentification" ? "#B3194A" : "#5F6366"} FlexDirection={"row"} Width="fit-content" Height="fit-content">
                {t('SideBar.ElephantsIdentification')}
              </SidebarButtonText>
            </>
          ) : (
            <>
              <ElephantIdentificationIcon MarginBottom="10px"/>
              <SidebarButtonText Display={sidebarOpen === false ? "none" : ""} Color={dashboard_context_value.tabSelection === "TabElephantIdentification" ? "#B3194A" : "#5F6366"} Width="fit-content" Height="fit-content" >
                {t('SideBar.ElephantsIdentification')} 
              </SidebarButtonText>
            </>
          )}
        </button>
      </SidebarButton>

      {/* Root Cause Diagnostics Tab */}
      <SidebarButton Width={sidebarOpen ? "2000px" : "44px"} Background={ dashboard_context_value.tabSelection === "TabRootCauseDiagnostics" ? "#F7C9D9" : "transparent"}
        Color={ dashboard_context_value.tabSelection === "TabRootCauseDiagnostics" ? "#B3194A" : "#5F6366" } MarginTop="12px">
        <button
          onClick={() => {
            dashboard_context_value.setTabSelection("TabRootCauseDiagnostics");
            setSideBarOpen(true);
            goToMetrics();
          }}
        >
          {dashboard_context_value.tabSelection === "TabRootCauseDiagnostics" ? (
            <>
              <DiagnosticsIconClicked />
              <SidebarButtonText Display={sidebarOpen === false ? "none" : ""} Color={dashboard_context_value.tabSelection === "TabRootCauseDiagnostics" ? "#B3194A" : "#5F6366"} FlexDirection={"row"} Width="fit-content" Height="fit-content">
                {t('SideBar.RootCauseDiagnostics')}
              </SidebarButtonText>
              <ArrowWrapper Display={sidebarOpen === false ? "none" : ""} >
                <IconArrowPinkUp/>
              </ArrowWrapper>
            </>
          ) : (
            <>
              <DiagnosticsIcon MarginBottom="10px"/>
              <SidebarButtonText Display={sidebarOpen === false ? "none" : ""} Color={dashboard_context_value.tabSelection === "TabRootCauseDiagnostics" ? "#B3194A" : "#5F6366"} Width="fit-content" Height="fit-content" >
                {t('SideBar.RootCauseDiagnostics')} 
              </SidebarButtonText>
              <ArrowWrapper Display={sidebarOpen === false ? "none" : ""} >
                <IconArrowClosed/>
              </ArrowWrapper>
              </>
          )}
        </button>
      </SidebarButton>

      {/* Root Cause Diagnostics SubTabs */}
      {sidebarOpen && dashboard_context_value.tabSelection === "TabRootCauseDiagnostics"  ? (
          <>
          {/* <DropdownLineWrapper Display={sidebarOpen === false ? "none" : ""} >
            <DropdownLine/>
          </DropdownLineWrapper> */}
          <SidebarButtonText Display={sidebarOpen === false ? "none" : ""} Color={dashboard_context_value.subTabSelection === "SubTabMetrics" ? "#B3194A" : "#5F6366"} FlexDirection={"row"} MarginLeft={"60px"} MarginTop={"15px"} Cursor={"pointer"}
            onClick={() => {
              dashboard_context_value.setSubTabSelection("SubTabMetrics");
              setSideBarOpen(true);
              goToMetrics();
            }}
            
          >
            {t('SideBar.Metrics')}
          </SidebarButtonText>
          <SidebarButtonText Display={sidebarOpen === false ? "none" : ""} Color={dashboard_context_value.subTabSelection === "SubTabSocialNetwork" ? "#B3194A" : "#5F6366"} FlexDirection={"row"} MarginLeft={"60px"} MarginTop={"15px"} Cursor={"pointer"}
            onClick={() => {
              dashboard_context_value.setSubTabSelection("SubTabSocialNetwork");
              setSideBarOpen(true);
              goToSocialNetwork();
            }}
          >
            {t('SideBar.SocialNetwork')}  
          </SidebarButtonText>
          </>
        ) : (
          ""
        )
      }

      {/* Actions Tab */}
      <SidebarButton Width={sidebarOpen ? "2000px" : "44px"} Background={ dashboard_context_value.tabSelection === "TabActions" ? "#F7C9D9" : "transparent"}
        Color={ dashboard_context_value.tabSelection === "TabActions" ? "#B3194A" : "#5F6366" } MarginTop="12px">
        <button
          onClick={() => {
            dashboard_context_value.setTabSelection("TabActions");
            setSideBarOpen(true);
            goToActions();
          }}
        >
          {dashboard_context_value.tabSelection === "TabActions" ? (
            <>
              <ActionsIconClicked />
              <SidebarButtonText Display={sidebarOpen === false ? "none" : ""} Color={dashboard_context_value.tabSelection === "TabActions" ? "#B3194A" : "#5F6366"} FlexDirection={"row"} Width="fit-content" Height="fit-content">
                {t('SideBar.Actions')}
              </SidebarButtonText>
            </>
          ) : (
            <>
              <ActionsIcon MarginBottom="10px"/>
              <SidebarButtonText Display={sidebarOpen === false ? "none" : ""} Color={dashboard_context_value.tabSelection === "TabActions" ? "#B3194A" : "#5F6366"} Width="fit-content" Height="fit-content" >
                {t('SideBar.Actions')} 
              </SidebarButtonText>
            </>
          )}
        </button>
      </SidebarButton>

      {/* Transformation Tab */}
      <SidebarButton Width={sidebarOpen ? "2000px" : "44px"} Background={ dashboard_context_value.tabSelection === "TabTransformation" ? "#F7C9D9" : "transparent"}
        Color={ dashboard_context_value.tabSelection === "TabTransformation" ? "#B3194A" : "#5F6366" } MarginTop="12px">
        <button
          onClick={() => {
            dashboard_context_value.setTabSelection("TabTransformation");
            setSideBarOpen(true);
            goToTransformation();
          }}
        >
          {dashboard_context_value.tabSelection === "TabTransformation" ? (
            <>
              <TransformationIconClicked />
              <SidebarButtonText Display={sidebarOpen === false ? "none" : ""} Color={dashboard_context_value.tabSelection === "TabTransformation" ? "#B3194A" : "#5F6366"} FlexDirection={"row"} Width="fit-content" Height="fit-content">
                {t('SideBar.Transformation')}
              </SidebarButtonText>
            </>
          ) : (
            <>
              <TransformationIcon MarginBottom="10px"/>
              <SidebarButtonText Display={sidebarOpen === false ? "none" : ""} Color={dashboard_context_value.tabSelection === "TabTransformation" ? "#B3194A" : "#5F6366"} Width="fit-content" Height="fit-content" >
                {t('SideBar.Transformation')} 
              </SidebarButtonText>
            </>
          )}
        </button>
      </SidebarButton>

      {/* Highlights Tab */}
      <SidebarButton Width={sidebarOpen ? "2000px" : "44px"} Background={ dashboard_context_value.tabSelection === "TabHighlights" ? "#F7C9D9" : "transparent"} 
        Color={ dashboard_context_value.tabSelection === "TabHighlights" ? "#B3194A" : "#5F6366" } MarginTop="12px">
        <button
          onClick={() => {
            dashboard_context_value.setTabSelection("TabHighlights");
            setSideBarOpen(true);
            goToHighLights();
          }}
        >
          {dashboard_context_value.tabSelection === "TabHighlights" ? (
            <>
              <HighlightsIconClicked />
              <SidebarButtonText Display={sidebarOpen === false ? "none" : ""} Color={dashboard_context_value.tabSelection === "TabHighlights" ? "#B3194A" : "#5F6366"} FlexDirection={"row"} Width="fit-content" Height="fit-content">
                {t('Highlights')}
              </SidebarButtonText>
            </>
          ) : (
            <>
              <HighlightsIcon MarginBottom="10px"/>
              <SidebarButtonText Display={sidebarOpen === false ? "none" : ""} Color={dashboard_context_value.tabSelection === "TabHighlights" ? "#B3194A" : "#5F6366"} Width="fit-content" Height="fit-content" >
                {t('Highlights')} 
              </SidebarButtonText>
            </>
          )}
        </button>
      </SidebarButton>

      <BottomWrapper MarginTop={(sidebarOpen && dashboard_context_value.tabSelection === "TabRootCauseDiagnostics") ? "272px" : "350px"}>
        {sidebarOpen ? (
          <SeparationLineShortExpanded />
        ) : (
          <SeparationLineShort />
        )}


        <SidebarButton Width={sidebarOpen ? "220px" : "44px"} MarginTop={"4px"} Bottom={"0"} Background={"transparent"} Color={"#5F6366"} >
          <button>
            <HelpIcon />

            <SidebarButtonText Color={"#5F6366"} Display={sidebarOpen === false ? "none" : ""} >
              {t('Component-header-block-need-help')}
            </SidebarButtonText>
          </button>
        </SidebarButton>

        <SidebarButton Width={"fit-content"} MarginTop={"12px"} MarginLeft={"12px"} MarginBottom={"28px"} Bottom={"0"} Background={"transparent"} Color={"#5F6366"} >
          <FlagWrapper Display={sidebarOpen ? "none" : "flex"}>
            {" "}
            {getFlag(i18n.language.split("-")[0])}
          </FlagWrapper>

          <SidebarButtonText Display={sidebarOpen ? "flex" : "none"} MarginTop={"4px"} MarginLeft={"-74px"}>
            <ComponentLanguageSwitcher Display={sidebarOpen ? "flex" : "none"} reRender={reRender} Position={"relative"}/>
          </SidebarButtonText>
        </SidebarButton>
      </BottomWrapper>

      {sidebarOpen ? <SeparationLineLongExpanded /> : <SeparationLineLong />}

      <SidebarButton Width={sidebarOpen ? "220px" : "44px"} Position={"relative"} MarginTop={sidebarOpen ? "-19px" : "4px"} MarginLeft={sidebarOpen ? "23px" : "20px"} MarginBottom={"12px"} Background={"transparent"} Color={"#5F6366"} >
        <CheckboxWrapper MarginBottom="0px" MarginLeft="0px" MarginRight="0px" MarginTop="0px" Height="50px" Width="50px" >
          <PersonPic>
            <StyledDivRegular Position="absolute" BorderRadius="50%" Width="36px" Height="36px" Background="#D0E1F1" Color="#271B36" Display="flex" AlignItems="center" JustifyContent="center" TextIndent="0" >
              {context.setFirstName(currentUser.first_name, 0).charAt(0) +
                context
                  .setFirstName(currentUser.last_name, 0)
                  .split(" ")
                  .filter((str) => str !== "")
                  .pop()
                  .charAt(0)}
            </StyledDivRegular>
          </PersonPic>
        </CheckboxWrapper>

        <SidebarButtonText Color={"#5F6366"} Display={sidebarOpen ? "flex" : "none"} MarginTop={"12px"} MarginLeft={"12px"} >
          {context.setFirstName(currentUser.first_name, 0) +
            " " +
            context
              .setFirstName(currentUser.last_name, 0)
              .split(" ")
              .filter((str) => str !== "")
              .pop()}
        </SidebarButtonText>

        <SidebarLogOutButton Color={"#5F6366"} Display={sidebarOpen ? "flex" : "none"} MarginTop={"12px"} >
          <button
            onClick={() =>
              window.location.replace(process.env.REACT_APP_APPURL + "/logout")
            }
          >
            <Logout />
          </button>
        </SidebarLogOutButton>
      </SidebarButton>
    </SidebarContainer>
  );
};
export default ComponentDashboardSidebar;

export const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  margin-top: ${(props) => props.MarginTop || "0px"};
  margin-left: ${(props) => props.MarginLeft || "0%"};
  width: ${(props) => props.Width || ""};
  height: ${(props) => props.Height || "100%"};
  border: ${(props) => props.Border || "none"}; //1px solid #E3E5E4
  border-radius: ${(props) => props.BorderRadius || ""};
  box-shadow: ${(props) => props.BoxShadow || "none"};
  background: ${(props) => props.inputBackground || "#FCFCFC"};
  color: ${(props) => props.inputColor || "#929699"};
  font-family: ${(props) => props.FontFamily || "Overpass"};
  font-size: ${(props) => props.FontSize || "16px"};
  line-height: ${(props) => props.LineHeight || "20px"};
  font-weight: ${(props) => props.FontWeight || "400"};
  z-index: 1;
  display: ${(props) => props.Display || "flex"};
  flex-direction: column;
  align-content: flex-start;
  transition: all 0.05s linear;
`;

const HeaderLogoWrapper = styled.div`
  position: relative;
  z-index: 1;
  cursor: pointer;
  margin: 16px 0 32px 0;
`;

const SidebarButton = styled.div`
  position: ${(props) => props.Position || "relative"};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: transparent;
  bottom: ${(props) => props.Bottom || ""};
  margin-top: ${(props) => props.MarginTop || "0px"};
  margin-left: ${(props) => props.MarginLeft || "0px"};
  margin-bottom: ${(props) => props.MarginBottom || "0px"};
  z-index: 1;
  width: ${(props) => props.Width || "189px"};
  button {
    padding: 0;
    margin: 0px 20px 0px 20px; // padding inside input box
    height: ${(props) => props.Background || "fit-content"};
    border: none;
    background: ${(props) => props.Background || ""};
    color: ${(props) => props.Color || "#5F6366"};
    border-radius: 10px;
    cursor: pointer;
    font-family: ${(props) => props.FontFamily || "Overpass"};
    font-size: ${(props) => props.FontSize || "16px"};
    line-height: ${(props) => props.LineHeight || "0px"};
    font-weight: ${(props) => props.FontWeight || "400"};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const SidebarButtonText = styled.div`
  display: ${(props) => props.Display || ""};
  flex-direction: ${(props) => props.FlexDirection || ""};
  margin-left: ${(props) => props.MarginLeft || ""};
  margin-top: ${(props) => props.MarginTop || ""};
  margin-bottom: ${(props) => props.MarginBottom || ""};
  right: ${(props) => props.Right || ""};
  font-size: 16px;
  text-align: left;
  line-height: 24px;
  padding-right: 12px;
  color: ${(props) => props.Color || "#5F6366"};
  width: ${(props) => props.Width || "126px"};
  height: ${(props) => props.Height || "fit-content"};
  max-width: 126px;
  cursor: ${(props) => props.Cursor || ""};
`;

const BottomWrapper = styled.div`
  position: relative;
  display: "flex";
  flex-direction: column;
  text-align: center;
  margin-top: ${(props) => props.MarginTop || "438px"};
`;

const CheckboxWrapper = styled.div`
  position: relative;
  margin-top: ${(props) => props.MarginTop || "0%"};
  margin-bottom: ${(props) => props.MarginBottom || "0%"};
  margin-left: ${(props) => props.MarginLeft || "0%"};
  margin-right: ${(props) => props.MarginRight || "0%"};
  width: ${(props) => props.Width || "24px"};
  height: ${(props) => props.Height || "24px"};
  color: ${(props) => props.inputColor || "transparent"};
  background-color: #fcfcfc;
  border-color: ${(props) =>
    props.BorderColor || "transparent"}; //1px solid #E3E5E4
  /* box-shadow: ${(props) => props.BoxShadow || "0px 4px 20px #F1F3F4"}; */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
`;

const PersonPic = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`;

const SidebarLogOutButton = styled.div`
  display: ${(props) => props.Display || ""};
  flex-direction: ${(props) => props.FlexDirection || ""};
  margin-top: ${(props) => props.MarginTop || ""};
  font-size: 16px;
  text-align: left;
  color: ${(props) => props.Color || "#5F6366"};
`;

const FlagWrapper = styled.div`
  display: ${(props) => props.Display || "flex"};
  margin-left: 20px;
`;

const ArrowWrapper = styled.div`
  display: ${(props) => props.Display || ""};
  position: absolute;
  flex-direction: row;
  margin-left: 120px;
  margin-top: 0px;
  justify-content: right;
`;

const DropdownWrapper = styled.div`
  display: flex;
`;

const DropdownLineWrapper = styled.div`
  display: ${(props) => props.Display || ""};
  position: absolute;
  flex-direction: row;
  margin-left: 42px;
  margin-top: 150px;
  justify-content: right;
`;

