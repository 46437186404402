import { useTranslation } from 'react-i18next';
import { useState } from "react";
import styled from 'styled-components';
import load from "../../../assets/dashboard/load.gif";
import { ComponentPageTransformation } from '../../../layout-and-styling/standardized-components-library/Component-Tooltips';


import LightBubbleIcon from "../../../assets/number_new.svg"

const TransformationsEmerged = ({ newProblems, surveyDates }) => {

    const { t, i18n } = useTranslation();
    const [hover1, setHover1] = useState(false);


    return (
        <EmergedWrapper>
            {surveyDates.length && newProblems ?
                <EmergedData>
                    <ProblemsOverviewSVGContainer>
                        <img src={LightBubbleIcon} alt='' />
                    </ProblemsOverviewSVGContainer>

                    <EmergedTitle>
                        {/* the $ is to insert variables into a string */}
                        {`${newProblems} ${t('Transformation.New')}`}
                    </EmergedTitle>

                    <EmergedSubtitle>
                        {t('Transformation.ElephantsEmerged')}
                    </EmergedSubtitle>

                    <EmergedDates>
                        <p> {t('Transformation.From')} {surveyDates[0]} {t('Transformation.To')} {surveyDates[1]} </p>
                    </EmergedDates>
                </EmergedData> : <LoadLogo>
                    <img src={load} alt="loading..." />
                </LoadLogo>}

            {/* The hover information */}
            <InfoHover onMouseOver={() => setHover1(true)}
                onMouseOut={() => setHover1(false)}>?
                {hover1 && (<ComponentPageTransformation whichTooltip={"ElephantsEmerged"} />
                )}
            </InfoHover>

        </EmergedWrapper>
    )
}

export default TransformationsEmerged

const EmergedWrapper = styled.div`
    position: relative;
    display: flex;
    margin-left: 0px;
    margin-right: 20px;
    background: #FCFCFC;
    height: 215px;
    min-width: fit-content;
    width: 375px;
    flex-direction: column;
    border-radius: 5px;
`;

const EmergedData = styled.div`
    margin-left: 30px;
    margin-top: 23px; 
`;

const EmergedTitle = styled.div`
    color: #E2336B;
    font-family: "Montserrat";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-block: 10px;
`;

const EmergedSubtitle = styled.div`
    color: #271B36;
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; 
`;

const EmergedDates = styled.div`
    color: #929699;
    font-family: "Overpass";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
`;

const ProblemsOverviewSVGContainer = styled.div`
    width: 50.13px;
    height: 42.8px;
`;

const InfoHover = styled.div`
    font-size: 18px;
    position: absolute;
    font-family: "Overpass";
    font-weight: 300;
    color: #FCFCFC;
    background-color: #e2336b;
    border: 2px solid #e2336b;
    top: 16px;
    border-radius: 500px;
    width: 20px;
    text-align: center;
    height: 20px;
    cursor: pointer;
    z-index: 20;
    right: 16px;
    float: right;
`;

const LoadLogo = styled.div`
  img {
    width: 50px;
  }
  display: flex;
  position: absolute;
  top: 40%;
  left: 43%;
`;