import React, {useContext, useState, useEffect} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import { OverlayContext } from '../../layout-and-styling/context-hooks/OverlayContext';
import {DonutChart} from "../specific-components/Component-Donut-Chart";
import { SubProcessBlock } from '../../layout-and-styling/standardized-components-library/Styling-ProcessBlocks';
import {DashboardContext} from "../DashboardContext";
import DashboardCommentsOverlay from "../specific-components/Dashboard-comments-overlay";

import DashboardCriticalButton from "../../assets/dashboard/DashboardCriticalButton.svg";
import {ReactComponent as CommentsIcon} from "../../assets/dashboard/CommentsIcon.svg";
import {ReactComponent as CommentsIconClicked} from "../../assets/dashboard/CommentsIconClicked.svg";
import {ReactComponent as EllyProcessBackground} from "../../assets/dashboard/EllyProcessBackground.svg";
import {ReactComponent as EllyNumber1} from "../../assets/dashboard/EllyNumber1.svg";
import load from "../../assets/dashboard/load.gif";
import {ReactComponent as Avatar} from "../../assets/dashboard/TagAvatar.svg";
import {ReactComponent as Line} from "../../assets/dashboard/PopUpLine.svg";


const Elephant = () => {
    const [ProcessData, setProcessData] = useState(JSON.parse(localStorage.getItem("business_process_data")));
    const [votesData, setVotesData] = useState(JSON.parse(localStorage.getItem('votes_data')));
    const [dashboardVotesPerTeamData, setDashboardVotesPerTeamData] = useState(JSON.parse(localStorage.getItem('dashboard_votes_per_team')));
    const [dashboardTagsPerTeamData, setDashboardTagsPerTeamData] = useState(JSON.parse(localStorage.getItem('dashboard_tags_per_team')));

    const overlay_context = useContext(OverlayContext);
    const context = useContext(OverlayContext);

    const [teamsPerspectives, setTeamsPerspectives] = useState(false);
    const [hoverElephant, setHoverElephant] = useState(false);

    const [opacity, setOpacity] = useState(1);
    const [selectedOptimal, setSelectedOptimal] = useState("");
    const [selectedImprovements, setSelectedImprovements] = useState("");
    const dashboard_context_value = useContext(DashboardContext);
    const {t, i18n} = useTranslation();

    useEffect(() => {      
      window.addEventListener('elephants_data_event', () => {
        setProcessData(JSON.parse(localStorage.getItem("business_process_data")));
        setVotesData(JSON.parse(localStorage.getItem("votes_data")));
        setDashboardVotesPerTeamData(JSON.parse(localStorage.getItem("dashboard_votes_per_team")));
        setDashboardTagsPerTeamData(JSON.parse(localStorage.getItem("dashboard_tags_per_team")));
      })
    }, [])

    // Mouse position to move the top teams tags perspective about the elephant
    const [mouseCoordinates, setMouseCoordinates] = useState({x:0, y:0});
	
  	const mouseMoveHandler = (event) => {
    	setMouseCoordinates({
      		x:event.clientX,
      		y:event.clientY
    	});
  	}   
    
    useEffect(()=>{
    	window.addEventListener('mousemove', mouseMoveHandler);
    	return(()=>{
      		window.removeEventListener('mousemove', mouseMoveHandler);
    	})
  	}, []) 
    
    function openComments(process, tag_name, tag_data_good, tag_data_bad, index, type) {
        dashboard_context_value.setDisplayModal(true);
        let data = [];

        data.push({
            "subprocess": process,
            "tag": tag_name,
            "index": index,
            "type": type,
            "feedbackGood": tag_data_good,
            "feedbackBad": tag_data_bad
        })
        dashboard_context_value.setTagSelected(data);
    }

    function lowerOpacity(subprocess, type) {
        if (type === "optimal")
            setSelectedOptimal(subprocess)
        else setSelectedImprovements(subprocess)
        setOpacity(0.1)
    }

    function raiseOpacity() {
        setSelectedOptimal("")
        setSelectedImprovements("")
        setOpacity(1)
    }

    function hoverElephantPosition(mousePosition) {
      let x = mousePosition.x
      let y = mousePosition.y
      var resultX
      var resultY
      if(x > 544 ){
        resultX = 248
      }
      else {
        resultX = 547
      }

      if(y > 390 ){
        resultY = 165
      }
      else {
        resultY = 314
      }

      return {x: resultX, y: resultY}
    }

    return (
        <Container>

            <OverlayBackground Display={dashboard_context_value.displayModal ? "block" : "none"}
                                onClick={() => dashboard_context_value.setDisplayModal(false)}/>
            <DashboardCommentsOverlay Display={dashboard_context_value.displayModal ? "block" : "none"}
                                        setDisplayModal={dashboard_context_value.setDisplayModal}/>

            
            <ProcessWrapper>
              {!ProcessData ? (
                <LoadLogo Top="50%" Left="50%">
                  {" "}
                  <img src={load} alt="loading..."/>
                </LoadLogo>
              ) : (
                <>
                  <ProcessTitle>
                      {t('Elephant.ProcessTitle')}
                  </ProcessTitle>

                  <ProcessSubtitle>
                      {t('Elephant.ProcessSubtitle')}
                  </ProcessSubtitle>


                  <ProcessComponentWrapper>
                    {ProcessData.map((current_process_object, process_index) => {
                        return (
                            <ProcessComponent>
                              {current_process_object.order_id === 0 &&
                                  <ArrowProcess
                                      RightBorderTop={"40px solid transparent"}
                                      RightBorderBottom={"40px solid transparent"}
                                      RightBorderLeft={"40px solid #271B36"}
                                      RightBorderRight={"none"}>
                                      <ArrowTitle>
                                          {context.setProcess(current_process_object.name, i18n.language)}
                                      </ArrowTitle>
                                  </ArrowProcess>
                              }
                              {current_process_object.order_id === ProcessData.length - 1 &&
                                  <ArrowProcess3
                                      LeftBorderTop={"40px solid transparent"}
                                      LeftBorderBottom={"40px solid transparent"}
                                      LeftBorderLeft={"40px solid #F1F3F4"}
                                      LeftBorderRight={"none"}>
                                      <ArrowTitle>
                                          {context.setProcess(current_process_object.name, i18n.language)}
                                      </ArrowTitle>
                                  </ArrowProcess3>
                              }

                              {current_process_object.order_id !== 0 && current_process_object.order_id !== ProcessData.length - 1 &&
                                  <ArrowProcess2
                                      LeftBorderTop={"40px solid transparent"}
                                      LeftBorderBottom={"40px solid transparent"}
                                      LeftBorderLeft={"40px solid #F1F3F4"}
                                      LeftBorderRight={"none"}
                                      RightBorderTop={"40px solid transparent"}
                                      RightBorderBottom={"40px solid transparent"}
                                      RightBorderLeft={"40px solid #271B36"}
                                      RightBorderRight={"none"}
                                  >
                                      <ArrowTitle>
                                          {context.setProcess(current_process_object.name, i18n.language)}
                                      </ArrowTitle>
                                  </ArrowProcess2>
                              }


                              {current_process_object.children.map((subprocess, subprocess_index) => {
                                  let top = votesData.slice(0,3).findIndex(e => e.id === subprocess.id);
                                  let bot = votesData.slice(3,6).findIndex(e => e.id === subprocess.id);
                                  let positiveTeams = []
                                  let negativeTeams = []
                                  let intersection = []
                                  if(dashboardVotesPerTeamData[subprocess.id]) positiveTeams = dashboardVotesPerTeamData[subprocess.id]["HL"]
                                  if(dashboardVotesPerTeamData[subprocess.id]) negativeTeams = dashboardVotesPerTeamData[subprocess.id]["LL"]
                                  if(positiveTeams && negativeTeams){
                                    intersection = positiveTeams.filter((t) => negativeTeams.includes(t))
                                    positiveTeams = positiveTeams.filter((t) => !intersection.includes(t))
                                    negativeTeams = negativeTeams.filter((t) => !intersection.includes(t))
                                  }
                                    return (
                                        <SubProcessColumnWrapper>

                                          {/* make the influential teams tags appear on the corresponding process ProcessBlocks
                                          but only when the influential teams perspective button is activated */}
                                          {teamsPerspectives ? 
                                          <>
                                            {/* blurr by putting opacity on a block if no team appears as tag on it, meaning none of the tag team arrays has any data in it */}
                                            <SubProcessBlock  Opacity={positiveTeams.length > 0 || negativeTeams.length > 0 || intersection.length > 0 ? "1" : "0.5"} FontSize="16px" Width="166px" Height='80px' MarginRight='0'  MarginBottom='24px' Background={bot === 0 ? 'transparent' : '#E3E5E4'}
                                              BackgroundHover={bot === 0 ? 'transparent' : ''} FontWeight={bot === 0 ? '700' : ''}  Color={bot === 0 ? '#271B36' : ''}>
                                              {context.setProcess(subprocess.name.toString(), i18n.language)}
                                            </SubProcessBlock>
                                            {/* make the elephant symbol appear in the ProcessBlock if it is the Elly process */}
                                            {bot === 0 ? 
                                            <EllyWrapper Width="166px" Height='80px' MarginRight='0px' MarginTop='-104px'  MarginBottom='24px' Background='#EF97B3' FontWeight= '700' BackgroundHover= "#fcfcfc">
                                              <EllyProcessBackground/>
                                            </EllyWrapper>
                                            : ''
                                            }
                                            <InfTeamsTagsWrapper>
                                              {positiveTeams.map((team, index) => {
                                                return (
                                                  <InfTeamsTag BackgroundColor={'#06D6A0'}> 
                                                    {context.setTeams(team, i18n.language).includes("Management") ? 
                                                      "Mngt"
                                                    :
                                                      context.setTeams(team, i18n.language)
                                                    }
                                                  </InfTeamsTag>
                                                )
                                              })}

                                              {intersection.map((team, index) => {
                                                return (
                                                  <DoubleTeamTag>
                                                    <InfTeamsTag BackgroundColor={'#06D6A0'}  MinWidth="18px" MarginLeft="0px" MarginTop="0px" JustifyContent="flex-end" BorderBottomRightRadius="0px" BorderTopRightRadius="0px">
                                                      {context.setTeams(team, i18n.language).includes("Management") ? 
                                                        "Mn"
                                                      :
                                                        context.setTeams(team, i18n.language).substring(0, context.setTeams(team, i18n.language).length/2)
                                                      }
                                                    </InfTeamsTag>
                                                    <InfTeamsTag BackgroundColor={'#E2336B'} MinWidth="18px" MarginLeft="-2px" MarginTop="0px" JustifyContent="flex-start" BorderBottomLeftRadius="0px" BorderTopLeftRadius="0px"> 
                                                      {context.setTeams(team, i18n.language).includes("Management") ? 
                                                        "gt"
                                                      :
                                                        context.setTeams(team, i18n.language).substring(context.setTeams(team, i18n.language).length/2, context.setTeams(team, i18n.language).length)
                                                      }
                                                    </InfTeamsTag>
                                                  </DoubleTeamTag>
                                                )
                                              })}

                                              {negativeTeams.map((team, index) => {
                                                return (
                                                  <InfTeamsTag BackgroundColor={'#E2336B'}> 
                                                    {context.setTeams(team, i18n.language).includes("Management") ? 
                                                      "Mngt"
                                                    :
                                                      context.setTeams(team, i18n.language)
                                                    }
                                                  </InfTeamsTag>
                                                )
                                              })}
                                          
                                            </InfTeamsTagsWrapper>
                                          </>  
                                          : 
                                          <>
                                            <NumbersWrapper JustifyContent="space-around">
                                                {/* green numbers */}
                                                { top > -1 ?
                                                  <NumberProcessRight Display={top > -1 ? "flex" : "none"}
                                                      onMouseOver={() => lowerOpacity(subprocess.name.toString(), "optimal")} 
                                                      onMouseOut={() => raiseOpacity()}
                                                      Background={top === 0 ? '#048B67' : top === 1 ? '#06D6A0' : '#A9D7CB'}>
                                                      {top + 1}
                                                  </NumberProcessRight>
                                                : 
                                                ""
                                                }
                                                {/* red numbers */}
                                                { bot > -1 ?
                                                  <NumberProcessRight Display={bot > -1 ? "flex" : "none"}
                                                      onMouseOver={() => lowerOpacity(subprocess.name.toString(), "improvement")} 
                                                      onMouseOut={() => raiseOpacity()}
                                                      Background={bot === 0 ? '#B3194A' : bot === 1 ? '#E2236B' : '#F7C9D9'}>
                                                      {bot + 1}
                                                  </NumberProcessRight>
                                                : 
                                                ""
                                                }            
                                            </NumbersWrapper>
                                            <SubProcessBlock MarginBottom='8px' FontSize="16px" Width="166px" Height='80px' MarginRight='0' Background={bot === 0 ? 'transparent' : '#E3E5E4'}
                                                            BackgroundHover={bot === 0 ? 'transparent' : ''} FontWeight={bot === 0 ? '700' : ''}  Color={bot === 0 ? '#271B36' : ''}
                                                            onMouseOver={() => bot === 0 ? setHoverElephant(true) : ""} onMouseOut={() => bot === 0 ? setHoverElephant(false) : ""}>
                                                {context.setProcess(subprocess.name.toString(), i18n.language)}
                                            </SubProcessBlock>
                                            {/* make the elephant symbol appear in the ProcessBlock if it is the Elly process */}
                                            {bot === 0 ? 
                                            <EllyWrapper Width="166px" Height='80px' MarginRight='0px' MarginTop= '-88px'  MarginBottom='8px' Background='#EF97B3' FontWeight= '700' BackgroundHover= "#fcfcfc">
                                              <EllyProcessBackground/>
                                            </EllyWrapper>
                                            : ''
                                            }

                                          </>
                                          }

                                        </SubProcessColumnWrapper>
                                    )
                              })}

                            </ProcessComponent>
                        )
                      })}
                  </ProcessComponentWrapper>
                </>
              )}
            </ProcessWrapper>
            

            <RightContainerCritical>
                <TopBars Opacity={teamsPerspectives === false ? "1" : "0.3"}>
                
                  <OptimalProcessesWrapper>
                    {!(votesData && votesData.length > 0) ? (
                      <LoadLogo Top="50%">
                        {" "}
                        <img src={load} alt="loading..."/>
                      </LoadLogo>
                    ) : (
                      <>
                        <Title>
                            {t('Elephant.BestPractices')}
                        </Title>
                        <DonutChart MarginTop='40px' MarginLeft='32px' Width='168px' Height='168px'
                                    HighDonutColor="#048B67" MedDonutColor="#06D6A0" LowDonutColor="#A9D7CB"
                                    OtherDonutColor="#C6CBCB"
                                    HighValue={votesData[0].percent}
                                    MedValue={votesData[1].percent}
                                    LowValue={votesData[2].percent}
                                    OtherValue={1 - votesData[0].percent - votesData[1].percent - votesData[2].percent}/>

                        <CriticalSubprocessWrapper>
                            {votesData.slice(0,3).map((subprocess, index_process) => {
                                return (
                                    <SubprocessWrapper Opacity={subprocess.name === selectedOptimal ? "1" : opacity}>
                                        <Header>
                                            <NumberProcess Background={index_process === 0 ? '#048B67' : index_process === 1 ? '#06D6A0' : '#A9D7CB'}>
                                                {index_process + 1}
                                            </NumberProcess>

                                            <NameProcess>
                                                {overlay_context.setProcess(subprocess.name, i18n.language)}
                                            </NameProcess>
                                        </Header>
                                        <TagsWrapper>
                                            {subprocess.tags.sort((a,b) => b.timesGood - a.timesGood).map((tag, index_tag) => {
                                                return (
                                                    <Tag onClick={() => openComments(subprocess.name, tag.name, tag.feedbackGood, tag.feedbackBad, index_process + 1, "optimal")}>
                                                    
                                                        <TagName>
                                                            {tag.name}
                                                        </TagName>

                                                        <Comments>
                                                            {tag.timesGood}
                                                            <IconWrapper>
                                                                <CommentsIcon/>
                                                            </IconWrapper>

                                                            <IconWrapperClicked>
                                                                <CommentsIconClicked/>
                                                            </IconWrapperClicked>
                                                        </Comments>

                                                    </Tag>
                                                )
                                            })}
                                        </TagsWrapper>
                                    </SubprocessWrapper>
                                )
                            })}
                        </CriticalSubprocessWrapper>
                      </>
                    )}  
                  </OptimalProcessesWrapper>

                  <ImprovementsProcessesWrapper>
                    {!(votesData && votesData.length > 0) ? (
                      <LoadLogo Top="50%">
                        {" "}
                        <img src={load} alt="loading..."/>
                      </LoadLogo>
                    ) : (
                      <>
                        <Title>
                            {t('Elephant.ImprovementNeeded')}
                        </Title>
                        <DonutChart MarginTop='20px' Display="flex" MarginLeft='32px' Width='100px' Height='100px'
                                    HighDonutColor="#B3194A" MedDonutColor="#E2336B" LowDonutColor="#F7C9D9"
                                    OtherDonutColor="#C6CBCB"
                                    HighValue={votesData[3].percent}
                                    MedValue={votesData[4].percent}
                                    LowValue={votesData[5].percent}
                                    OtherValue={1 - votesData[3].percent - votesData[4].percent - votesData[5].percent}/>

                        <CriticalSubprocessWrapper>
                            {votesData.slice(3,6).map((subprocess, index_process) => {
                                return (
                                    <SubprocessWrapper Opacity={subprocess.name === selectedImprovements ? "1" : opacity} Background={index_process === 0 ? '#F7D4DF': ''}>
                                        <Header>
                                            <NumberProcess Background={index_process === 0 ? 'transparent' : index_process === 1 ? '#E2236B' : '#F7C9D9'}>
                                                {index_process + 1}
                                            </NumberProcess>
                                            {index_process === 0 ? 
                                            <NumberProcess MarginLeft='-24px' Background= '#B3194A' ZIndex='0' JustifyContent='flex-end' AlignItems='flex-end'>
                                              <EllyNumber1/>
                                            </NumberProcess> 
                                            : ''}
                                            <NameProcess>
                                                {overlay_context.setProcess(subprocess.name, i18n.language)}
                                            </NameProcess>
                                        </Header>
                                        <TagsWrapper Background={index_process === 0 ? '#F7D4DF': ''}>
                                            {subprocess.tags.sort((a,b) => b.timesBad - a.timesBad).map((tag, index_tag) => {
                                                return (
                                                      <Tag onClick={() => openComments(subprocess.name, tag.name, tag.feedbackGood, tag.feedbackBad, index_process + 1, "improvement")}>
                                                        <TagName>
                                                            {tag.name}
                                                        </TagName>

                                                        <Comments>
                                                            {tag.timesBad}
                                                            <IconWrapper>
                                                                <CommentsIcon/>
                                                            </IconWrapper>

                                                            <IconWrapperClicked>
                                                                <CommentsIconClicked/>
                                                            </IconWrapperClicked>
                                                        </Comments>

                                                    </Tag>
                                                )
                                            })}
                                        </TagsWrapper>
                                    </SubprocessWrapper>
                                )
                            })}
                        </CriticalSubprocessWrapper>
                      </>
                    )}                      
                  </ImprovementsProcessesWrapper>
                </TopBars>

                {hoverElephant === true ?      
                  <InfluentialPopUp Position='absolute' Top={hoverElephantPosition(mouseCoordinates).y + "px"} Left={hoverElephantPosition(mouseCoordinates).x + "px"}>
                    <InfluentialTextWrapper MarginTop='25px'>
                      {t('Elephant.PopUpHeading')}
                    </InfluentialTextWrapper>
                    <InfluentialTextWrapper MarginTop='15px' FontSize='14px' FontWeight='normal'>
                      {t('Elephant.PopUpSubHeading')}
                    </InfluentialTextWrapper>

                    <ColumnsWrapper>
                    
                      <SpecificColumnWrapper>
                      
                        <InfluentialTextWrapper MarginLeft='0px' Color='#5F6366' FontSize='14px'>{t('Elephant.BestPractices')}</InfluentialTextWrapper>

                        {Object.entries(dashboardTagsPerTeamData).map(([infTeam, value]) => {
                          return(
                            <>
                            <TagFieldWrapper>
                              <Line/>
                              <TagWrapper  Height='24px' BackgroundColor='#06D6A0' MarginTop='0px'>
                                <Avatar/>
                                <InfluentialTextWrapper FontSize='16px'FontWeight='bold' Color='#fcfcfc' MarginLeft='12px'>
                                  {context.setTeams(infTeam, i18n.language).includes("Management") ? 
                                    "Mngt"
                                  :
                                    context.setTeams(infTeam, i18n.language)
                                  }
                                </InfluentialTextWrapper>
                              </TagWrapper>
                              {Object.entries(value["positive"]).map(([negORpos, value2]) => {
                                
                                return(
                                  <>
                                    <TagWrapper BorderStyle='solid' BorderWidth="1px" BorderColor="#C6CBCB" BackgroundColor='transparent'>
                                      <InfluentialTextWrapper FontSize='14px'FontWeight='normal' Color='#271B36' MarginLeft='5px' >
                                        {value2["tag"]} 
                                          {/* display the number of times this tag has been mentioned */}
                                        {/* {value2["mentioned"]} */}
                                      </InfluentialTextWrapper>
                                    </TagWrapper>
                                  </> 
                                )
                              })}
                            </TagFieldWrapper>
                          </>
                          )
                        })}

                      </SpecificColumnWrapper>
                      <SpecificColumnWrapper>
                        <InfluentialTextWrapper MarginLeft='0px' Color='#5F6366' FontSize='14px'>
                          {t('Elephant.ImprovementNeeded')}
                        </InfluentialTextWrapper> 
                        {Object.entries(dashboardTagsPerTeamData).map(([infTeam, value]) => {
                            return(
                              <TagFieldWrapper>
                              <Line/>
                                    <TagWrapper  Height='24px' BackgroundColor='#E2336B' MarginTop='0px'>
                                      <Avatar/>
                                      <InfluentialTextWrapper FontSize='16px'FontWeight='bold' Color='#fcfcfc' MarginLeft='12px'>
                                        {context.setTeams(infTeam, i18n.language).includes("Management") ? 
                                          "Mngt"
                                        :
                                          context.setTeams(infTeam, i18n.language)
                                        }
                                      </InfluentialTextWrapper>
                                    </TagWrapper>
                              {Object.entries(value["negative"]).map(([negORpos, value2]) => {
                                
                                return(
                                  <>
                                    <TagWrapper BorderStyle='solid' BorderWidth="1px" BorderColor="#C6CBCB" BackgroundColor='transparent'>
                                      <InfluentialTextWrapper FontSize='14px'FontWeight='normal' Color='#271B36' MarginLeft='5px' >
                                        {value2["tag"].includes("Customer feedback & support") ? 
                                          "Cust.feedback&support"
                                        :
                                          value2["tag"]
                                        }
                                        {/* display the number of times this tag has been mentioned */}
                                        {/* {value2["mentioned"]}*/}
                                      </InfluentialTextWrapper>
                                    </TagWrapper>
                                  </> 
                                  )
                              })}
                              </TagFieldWrapper>
                          ) 
                        })}

                      </SpecificColumnWrapper>
                    </ColumnsWrapper> 
                      
                  </InfluentialPopUp>
                : ''
                }
                
                <InfluentialTeamsWrapper>
                  {!votesData ? (
                      <LoadLogo Top="0%">
                        {" "}
                        <img src={load} alt="loading..."/>
                      </LoadLogo>
                    ) : (
                      <>
                        <InfluentialTextWrapper>
                            <InfluentialTextWrapper MarginLeft='10px' MarginTop='10px'>
                              {t('Elephant.InfluentialHeading')}
                            </InfluentialTextWrapper>
                            <InfluentialTextWrapper MarginLeft='10px' MarginRight='30px' FontSize={"14px"} FontWeight={"normal"}>
                              {t('Elephant.InfluentialSubHeading')}
                            </InfluentialTextWrapper>
                        </InfluentialTextWrapper>
                      
                        <InfluentialButton>
                        <button
                            onClick={() => {
                                setTeamsPerspectives(!teamsPerspectives)
                            }}
                        >
                            {t('Elephant.InfluentialButtonText')}
                        </button>
                        </InfluentialButton>
                      </>
                    )}                
                </InfluentialTeamsWrapper>
            </RightContainerCritical>

        </Container>
    )

};
export default Elephant




const LoadLogo = styled.div`
  img {
    width: 50px;
  }
  display: flex;
  position: relative;
  top: ${ props => props.Top || "50%" };
  left: ${ props => props.Left || "0%" };
`;

const Container = styled.div`
  font-family: 'Overpass', sans-serif;
  width: calc(100%);
  display: flex;
  flex-direction: row;
  z-index: 1;
  margin-top: 24px;
  margin-left: 42px;
  margin-right: 42px;
`;

const OverlayBackground = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  position: fixed;
  display: ${props => props.Display || ""};
  background: rgba(107, 107, 107, 0.46);
  cursor: pointer;
`;

const OptimalProcessesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 288px;
  height: 716px;
  background: #FCFCFC;
  border-radius: 5px;
  margin-right: 20px;
  padding-bottom: 12px;
`;

const ImprovementsProcessesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 288px;
  height: 716px;
  background: #FCFCFC;
  border-radius: 5px;
  padding-bottom: 12px;
`;

const RightContainerCritical = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  margin-top: 20px;
  color: #271B36;
`;

const CriticalSubprocessWrapper = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  margin: 12px;
  align-items: center;
`;

const NumberProcess = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-left: ${props => props.MarginLeft || "0px"};
  padding: 0px;
  line-height: 20px;
  color: #FCFCFC;
  display: flex;
  justify-content: ${props => props.JustifyContent || 'center'};
  align-items: ${props => props.AlignItems || 'center'};
  width: 24px;
  height: 24px;
  border-radius: 5px;
  background: ${props => props.Background || 'none'};
  z-index: ${props => props.ZIndex || '1'};
`;


const NameProcess = styled.div`
  margin-left: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #271B36;
  width: 210px;
  margin-left: 8px;
`;

const TagsWrapper = styled.div`
  background: ${props => props.Background || "#F1F3F4"};
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  border-radius: 5px;
`;

const EllyWrapper = styled.div`
  position: relative;
  flex-direction: column;
  margin-top: ${props => props.MarginTop || "8px"};
  margin-left: ${props => props.MarginLeft || "0px"};
  margin-bottom: ${props => props.MarginBottom || "0px"};
  margin-right: ${props => props.MarginRight || "12px"};
  width: ${props => props.Width || "125px"};
  height: ${props => props.Height || "56px"};
  background: ${props => props.Background || "#E3E5E4"};
  opacity: ${props => props.Opacity || "1"};
  border-radius: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: center;
  cursor: ${props => props.Cursor || "default"};
  z-index: 0;

  :hover {
    background: ${props => props.BackgroundHover || "#B3194A"};
  }
`;

const InfTeamsTagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 166px;
  justify-content: ${props => props.JustifyContent || 'space-around'};
  font-weight: 700;
  color: #FCFCFC;
  font-size: 16px;
  line-height: 20px;
  margin-top: -16px;
`;


const InfTeamsTag = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-left: ${props => props.MarginLeft || "0px"};
  margin-top: ${props => props.MarginTop || "-26px"};
  padding: 2px;
  line-height: 16px;
  color: #FCFCFC;
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.JustifyContent || 'center'};
  align-items: ${props => props.AlignItems || 'center'};
  min-width: ${props => props.MinWidth || '36px'};
  width: ${props => props.Width || 'fit-content'};
  height: 14px;
  border-bottom-left-radius: ${props => props.BorderBottomLeftRadius || '10px'};
  border-top-left-radius: ${props => props.BorderTopLeftRadius || '10px'};
  border-bottom-right-radius: ${props => props.BorderBottomRightRadius || '10px'};
  border-top-right-radius: ${props => props.BorderTopRightRadius || '10px'};
  background: ${props => props.BackgroundColor || '#FCFCFC'};
  z-index: ${props => props.ZIndex || '2'};
`;

const DoubleTeamTag = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-top: -28px;
`;

const Comments = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  color: #271B36;
  margin-right: 6px;
  flex-direction: row;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  display: flex;
  margin-left: 2px;
`;

const IconWrapperClicked = styled.div`
  display: none;
  margin-left: 2px;
`;

const Tag = styled.div`
  background: #FCFCFC;
  display: flex;
  width: 248px;
  margin-bottom: 1px;
  margin-left: 3px;
  height: 30px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 5px;

  &:hover {
    background: #F1F3F4;
  }

  &:hover ${Comments} {
    color: #E2336B;
  }

  &:hover ${IconWrapper} {
    display: none;
  }

  &:hover ${IconWrapperClicked} {
    display: flex;
  }
`;

const TagName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #0A070E;
  margin-left: 20px;
  margin-top: 8px;
`;

const ProcessWrapper = styled.div`
position: relative;
  display: flex;
  margin-left: 0px;
  margin-right: 20px;
  background: #FCFCFC;
  height: 844px;
  min-width: fit-content;
  width: 1136px;
  flex-direction: column;
  border-radius: 5px;
`;

const ProcessComponentWrapper = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: row;
`;

const ProcessComponent = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const ArrowProcess = styled.div`
  width: 165px;
  height: 80px;
  background: ${
          props => props.Background || "#271B36"
  };
  border-radius: 2px;
  color: #FCFCFC;
  font-family: 'Overpass';
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  &:after {
    content: " ";
    z-index: 2;
    position: absolute;
    margin-left: 205px;
    border-right: ${props => props.RightBorderRight || "28px solid #271B36"};
    border-top: ${props => props.RightBorderTop || "28px solid transparent"};
    border-bottom: ${props => props.RightBorderBottom || "28px solid transparent;"};
    border-left: ${props => props.RightBorderLeft || "28px solid #271B36"};
  }
`;

const ArrowProcess2 = styled.div`
  width: 165px;
  height: 80px;

  background: ${
          props => props.Background || "#271B36"
  };
  border-radius: 2px;
  color: #FCFCFC;
  font-family: 'Overpass';
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  &:before {
    content: " ";
    position: absolute;
    margin-right: 125px;
    border-top: ${props => props.LeftBorderTop || "28px solid #271B36"};
    border-right: ${props => props.LeftBorderRight || "28px solid #271B36"};
    border-bottom: ${props => props.LeftBorderBottom || "28px solid #271B36"};
    border-left: ${props => props.LeftBorderLeft || "28px solid transparent"};
  }

  &:after {
    content: " ";
    z-index: 2;
    position: absolute;
    margin-left: 205px;
    border-right: ${props => props.RightBorderRight || "28px solid #271B36"};
    border-top: ${props => props.RightBorderTop || "28px solid transparent"};
    border-bottom: ${props => props.RightBorderBottom || "28px solid transparent;"};
    border-left: ${props => props.RightBorderLeft || "28px solid #271B36"};
  }
`;

const ArrowProcess3 = styled.div`
  width: 165px;
  height: 80px;
  background: ${
          props => props.Background || "#271B36"
  };
  border-radius: 2px;
  color: #FCFCFC;
  font-family: 'Overpass';
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  &:before {
    content: " ";
    position: absolute;
    margin-right: 125px;
    border-top: ${props => props.LeftBorderTop || "28px solid #271B36"};
    border-right: ${props => props.LeftBorderRight || "28px solid #271B36"};
    border-bottom: ${props => props.LeftBorderBottom || "28px solid #271B36"};
    border-left: ${props => props.LeftBorderLeft || "28px solid transparent"};
  }
`;

const ArrowTitle = styled.div`
  margin-left: 40px;
  text-align: center;
  width: 88px;
`;

const SubProcessColumnWrapper = styled.div`

`;


const ProcessTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #271B36;
  margin-top: 20px;
  margin-left: 24px;
`;

const ProcessSubtitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #5F6366;
  margin-top: 12px;
  margin-left: 24px;
`;

const NumberProcessRight = styled.div`
  display: ${props => props.Display || 'none'};
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  margin-bottom: -25px;
  z-index: 2;
  background: ${props => props.Background || 'none'};
`;

const NumbersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 166px;
  justify-content: ${props => props.JustifyContent || 'center'};
  font-weight: 700;
  color: #FCFCFC;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
`;

const TopBars = styled.div`
  display: flex;
  flex-direction: row;
  position: ${props => props.Position || ''};
  opacity: ${props => props.Opacity || '1'};
  margin-top: ${props => props.MarginTop || ''};
  margin-left: ${props => props.MarginLeft || ''};
  justify-content: ${props => props.JustifyContent || ''};
`;

const LogoCompany = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  margin-top: 20px;
  margin-right: 40px;
`;

const SubprocessWrapper = styled.div`
  opacity: ${props => props.Opacity || '1'};
  background: ${props => props.Background || ''};
  border-radius: 5px;
`;

const InfluentialTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: ${props => props.FontWeight || 'bold'};
  font-size: ${props => props.FontSize || '16px'};
  line-height: ${props => props.LineHeight || '20px'};
  margin-left: ${props => props.MarginLeft || '30px'};
  margin-right: ${props => props.MarginRight || '0px'};
  margin-top: ${props => props.MarginTop || '0px'};
  color:  ${props => props.Color || '#271B36'};
  font-family: ${props => props.FontFamily || 'Overpass'};
`;

const InfluentialTeamsWrapper = styled.div`
  border-radius: 5px;
  width: 600px;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: #FCFCFC;
  margin-top: 16px;
`;
const InfluentialButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: transparent;
  width: "142px";
  height: "36px";
  button {
    padding: 0;
    margin: 0px 20px 0px 20px; // padding inside input box
    height: 36px;
    width: 142px;
    border: none;
    background: #26547C;
    color: #FCFCFC;
    border-radius: 5px;
    font-family: "Overpass";
    font-size: 14px;
    text-align: center;
    color: "#fcfcfc";
    background-color: "#fcfcfc";
    cursor: pointer;
    &:hover {
    background: #6283A0;
    }
  }
`;

const InfluentialPopUp = styled.div`
  display: flex;
  flex-direction: column;
  position: ${props => props.Position || ''};
  opacity: ${props => props.Opacity || '1'};
  top: ${props => props.Top || ''};
  left: ${props => props.Left || ''};
  justify-content: ${props => props.JustifyContent || ''};
  background: #FCFCFC;
  border-radius: 5px;
  width: 476px;
  height: 494px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  z-index: 3;
`;

const ColumnsWrapper = styled.div`
  width: 476px;
  display: flex;
  margin-top: 20px;
`;

const SpecificColumnWrapper = styled.div`
  width: 208px;
  margin-left: 30px;
`;

const TagFieldWrapper = styled.div`
  height: 118px;
`;

const TagWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  text-align: center;
  width: fit-content;
  height:  ${props => props.Height || '23px'};
  background-color: ${props => props.BackgroundColor || 'transparent'};
  border-style: ${props => props.BorderStyle || ''};
  border-width: ${props => props.BorderWidth || ''};
  border-color: ${props => props.BorderColor || ''};
  color: #fcfcfc;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 400;
  padding: 2px 12px 2px 12px;
  margin-left: 5px;
  margin-top: ${props => props.MarginTop || '5px'};
  white-space: nowrap;
`;