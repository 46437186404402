import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import ComponentHeaderBlock from '../../layout-and-styling/standardized-components-library/Component-header-block';
import ComponentFooter from '../../layout-and-styling/standardized-components-library/Component-Footer';
import {useWindowSize} from '../../layout-and-styling/standardized-components-library/Component-Responsive';
import {Trans, useTranslation} from "react-i18next";
import ComponentSignificantColleagues from '../survey-close-colleagues/page-specific-components/Component-significant-colleagues';
import ComponentInitiativeSlider from './page-specific-components/Component-initiative-slider';

/** 
 * Survey used to gather initiative, decision making, and finish line from each close colleague selected by a user.
 * This data is gathered via three sliders in the component ComponentInitiativeSlider. and a list of close colleagues 
 * from ComponentSignificantColleagues. The data is later processed and sent with the method UpdateProactiveness() from Component-API.
 */
const PageProactiveness = () => {
    /**
     * Component that allows the user to fill in how much driving force others have, based on the colleagues they selected
     * 1) Calculate data required to run the page with getArrayQualityAPI() and getArrayQuality(), based on localStorage data
     * 2) Component Header, with corresponding Survey styling
     * 3) Checklist of close colleagues selected in first page
     * 4) Sliders to grade close colleague selected in previous component
     * 5) Component Footer, specifically for this page that will send a POST request with the Driving Force data. Also if you return from the next page
     */

    const {t} = useTranslation();
    const [InputCheckPeopleInitiative, setInputCheckPeopleInitiative] = useState(false); //Boolean to check if the page is complete
    const employeeRef = useRef([]);
    const [render, setRender] = useState(false);
    const InteractionData = JSON.parse(localStorage.getItem('SignificantInteractions'));
    const EmployeeData = JSON.parse(localStorage.getItem('employee_data'));
    const CurrentUserData = JSON.parse(localStorage.getItem('current_user_data'));
    const significantColleagues = JSON.parse(localStorage.getItem("SignificantColleagues"))

    /** Check if already authenticated, and bring to dashboard if possible.*/
    useEffect(() => {
        const axiosInstance = axios.create({withCredentials: true})
        // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
            .catch((error) => {
                console.log('error, you are not logged in yet!')
                window.location.replace(process.env.REACT_APP_APPURL + '/login');
            })


    }, []);

    const reRender = () => {
        setRender(!render)
    };

    /** 
     * Calculates the ArrayQuality which contains all the close colleague interactions selected by the user and the information
     * required to create the colleague checklist. 
     */
    function getArrayQuality()
    {
        const InitArrayQuality = localStorage.getItem('ArrayQuality') ? JSON.parse(localStorage.getItem('ArrayQuality')) : []


        for (var i = 0; i < Object(InteractionData).length; i++) {
            for (var j = 0; j < Object(EmployeeData).length; j++) {
                /**  Populate API array, without input window info and only the necessary /api/interactions JSON data **/
                
                /**  initialize the colleague input windows */
                var exists = InitArrayQuality.filter(obj => {
                    return obj.full_name === EmployeeData[j]['first_name'].concat(' ', EmployeeData[j]['last_name'])
                })
                if (EmployeeData[j]['id'] === InteractionData[i]['target_survey'] && exists.length === 0) {
                    InitArrayQuality.push({
                        full_name: EmployeeData[j]['first_name'].concat(' ', EmployeeData[j]['last_name']),
                        selected: false,
                        source: CurrentUserData[0].id,
                        target: EmployeeData[j]['id'],
                        impact: 1,
                        pleasantness: 1,
                        impact_activated: false,
                        pleasantness_activated: false,
                        initiative: 1,
                        decision_making: 1,
                        finish_line: 1,
                        initiative_activated: false,
                        decision_making_activated: false,
                        finish_line_activated: false,
                        first_name: EmployeeData[j]['first_name'],
                        last_name: EmployeeData[j]['last_name']
                    })
                }
            }
        }
        return InitArrayQuality
    }

    const [ArrayQuality, updateArrayQuality] = useState(getArrayQuality());
    const [SignificantColleagues, setSignificantColleaguesPerTeam] = useState();
    const [ArrayInputCheck, updateArrayInputCheck] = useState(JSON.parse(localStorage.getItem('CheckedEmployeesDrivingForce')) ? JSON.parse(localStorage.getItem('CheckedEmployeesDrivingForce')) : []);
    const [nextButtonClicked, setNextButtonClicked] = useState(false);
    const [selectedColleague, setSelectedColleague] = useState(significantColleagues[0].id)

    return (
        <Container>
            <HeaderAndOverlayWrapper>
                <ComponentHeaderBlock page='Survey' overlay='PageProactiveness' directory='/survey-decision-making'/>
            </HeaderAndOverlayWrapper>
            <WrapperTop>
                <Trans i18nKey="PageProactiveness.Title">
                How <span style={{color:"#B3194A"}}> proactive </span> are your colleagues?
                </Trans>
            </WrapperTop>

            <WrapperBottom>
                {/* Checklist component, creates a list with all close colleagues selected */}
                <ComponentSignificantColleagues ArrayQuality={ArrayQuality} updateArrayQuality={updateArrayQuality} ArrayInputCheck={ArrayInputCheck} nextButtonClicked={nextButtonClicked} page={"PageProactiveness"}
                    selectedColleague={selectedColleague} setSelectedColleague={setSelectedColleague} reRender={reRender} employeeRef={employeeRef} inputCheckPeople={InputCheckPeopleInitiative}/>

                {/* Slider component, grades the driving force parameters from the colleague selected in the checklist */}
                <ComponentInitiativeSlider ArrayQuality={ArrayQuality} ArrayInputCheck={ArrayInputCheck} updateArrayInputCheck={updateArrayInputCheck}
                    updateArrayQuality={updateArrayQuality} selectedColleague={selectedColleague} setSelectedColleague={setSelectedColleague} SignificantColleagues={SignificantColleagues}
                    reRender={reRender} set_input_checker={setInputCheckPeopleInitiative} employeeRef={employeeRef} />
            </WrapperBottom>

            <ComponentFooter page='PageProactiveness' backDirectory='/survey-collaboration-measurement' FooterMarginTop={(useWindowSize()[1] - 104).toString() + "px"} FooterMarginLeft='0px' FooterHeight='104px'
                            FooterWidth='100%' MarginTop='25px' BackButtonMarginLeft='65%' NextButtonMarginLeft='calc(65% + 66px)' inputCheckPeople={InputCheckPeopleInitiative} nextButtonClicked={nextButtonClicked} setNextButtonClicked={setNextButtonClicked} />
        </Container>
    );
}

export default PageProactiveness

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 105px);
  background: #F1F3F4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //@media (-webkit-device-pixel-ratio: 1.25) {
  //  zoom: calc(1 / 1.25);
  //}
  //@media (-webkit-device-pixel-ratio: 1.5) {
  //  zoom: calc(1 / 1.5);
  //}
`;

const HeaderAndOverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
`;

const WrapperTop = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 100px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 10px;
  height: 10px;
`;

const WrapperBottom = styled.div`
  margin-top: 20px;
  position: relative;
  width: 80%;
  height: calc(100% - 168px);
  top: 64px;
  background: #F1F3F4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
`;
