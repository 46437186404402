import React, {useEffect, useState} from 'react';
import axios from 'axios';
import ComponentHeaderBlock from '../../layout-and-styling/standardized-components-library/Component-header-block';
import styled from 'styled-components';
import ComponentFooter from '../../layout-and-styling/standardized-components-library/Component-Footer';
import ComponentChatbox from './page-specific-components/Component-Chatbox';
import nlp from 'compromise';
import ComponentSendmessage from './page-specific-components/Component-Sendmessage';
import getCookieValue from "../../utils";
import { useWindowSize } from '../../layout-and-styling/standardized-components-library/Component-Responsive';
import {Trans, useTranslation} from 'react-i18next';
import ComponentOptionSelector from './page-specific-components/Component-OptionSelector';

const PageRootcauseChatbot = () => {

  const {t} = useTranslation();

  const [botThinking, setBotThinking] = useState(false);
  const [currentStage, setCurrentStage] = useState("ASKING_WHY");
  const [formOptions, setFormOptions] = useState([]);
  const [questionAmount, setQuestionAmount] = useState(0);
  const [shouldCheckGibberish, setShouldCheckGibberish] = useState(true);
  const [gibberishCounter, setGibberishCounter] = useState(0);
  const [knowledge, setKnowledge] = useState(3);
  const [metric, setMetric] = useState("None");
  const [firstRender, setFirstRender] = useState(true);
  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  const [currentSubprocess, setCurrentSubprocess] = useState(0);
  const [selectedSubprocesses, setSelectedSubprocesses] = useState(calculateProcessesSelected(false, true, false));
  const [selectedProcesses, setSelectedProcesses] = useState(calculateProcessesSelected(false, false, false));
  const [fullNameSelectedSubprocesses, setFullNameSelectedSubprocesses] = useState(calculateProcessesSelected(true, true, false));
  const [teams, setTeams] = useState(getTeams());
  const [comboQueue, setComboQueue] = useState(calculateProcessesSelected(true, true, true));


  const [messages, setMessages] = useState(localStorage.getItem('chatbot_messages') ? JSON.parse(localStorage.getItem('chatbot_messages')) :
    [[t("PageRootcauseChatbot.EllyIntroMessage1"), true, true],
    [t("PageRootcauseChatbot.EllyIntroMessage2.1") + ` ${selectedSubprocesses[currentSubprocess]}` + t("PageRootcauseChatbot.EllyIntroMessage2.2") + `${selectedProcesses[currentSubprocess]}` + t("PageRootcauseChatbot.EllyIntroMessage2.3"), true, false]]);
  const [translatedMessages, setTranslatedMessages] = useState(localStorage.getItem('chatbot_translated_messages') ? JSON.parse(localStorage.getItem('chatbot_translated_messages')) : 
                                                      [["Hi! My name is Elly. I am the Elephants in the Room Chatbot. Nice to meet you! I would like to have a quick conversation with you to find out why there are problems occurring within your company.", true, 1, "None", fullNameSelectedSubprocesses[currentSubprocess]],
                                                      ["You said the subprocess" + `${selectedSubprocesses[currentSubprocess]}` + "from the process" + `${selectedProcesses[currentSubprocess]}` + " needed improvement. Can you briefly describe why you think so?", true, 2, "None", fullNameSelectedSubprocesses[currentSubprocess]]]);

  const [conversationData, setConversationData] = useState( 
  {
    'stage': 'ASKING_WHY',
    'options': [],
    'questionamount': 0,
    'checkgibberish': true,
    'metric': 'None',
    'thinking': false,
    'subprocess': 0,
    'gibberishcounter': 0,
    'subprocessqueue': [],
    'processqueue': [],
    'comboqueue': []
  });

  useWindowSize();
  var incrementQuestionAmount = true;

  function calculateProcessesSelected(fullName, subprocesses, needsComboQueue) {
    // Method used to get data from the initial process. Used only on load.
    const processData = JSON.parse(localStorage.getItem("business_process_data"));
    const selectedProcessData = JSON.parse(localStorage.getItem("improvementFeedbackSelectedProcesses"));
    const talkData = localStorage.getItem('chatbot_conversationdata') ? JSON.parse(localStorage.getItem('chatbot_conversationdata')) : {
      'subprocessqueue': [],
      'processqueue': [],
      'comboqueue': []
    };

    //Make array that contains each subprocess and its process number
    let subprocesscounter = 0;
    let processcounter = 0;
    let combinations = talkData.comboqueue;
    let chosencombinations = [];
    for(let i=0; i<processData.length; i++){
      for(let j=0; j<processData[i].children.length; j++){
        if(selectedProcessData.includes(subprocesscounter)){
          combinations = [...combinations, [subprocesscounter, processcounter]];
          chosencombinations = [...chosencombinations, [subprocesscounter, processcounter]];
        }
        subprocesscounter++;
      }
      processcounter++;
    }
    //Remove all duplicates
    const uniqueCombos = new Set();
    const combinationCopy = combinations.filter((arr) =>{
      const key = arr.join(',');

      if(!uniqueCombos.has(key) ){
        uniqueCombos.add(key);
        return true;
      }
      return false;
    });

    //Remove all entries that are beyond current subprocess and are not in chosen subprocesses
    let finalCombination = combinationCopy;
    for(let i=combinationCopy.length - 1; i>currentSubprocess; i--){
      let match = false;
      for(let j=0; j<chosencombinations.length; j++){
        if(combinationCopy[i][0] === chosencombinations[j][0] && combinationCopy[i][1] === chosencombinations[j][1]){
          match = true;
        }
      }
      if(!match){
        finalCombination.splice(i, 1);
      }
    }
    if(needsComboQueue){
      return(finalCombination);
    }

    //Transform each number of subprocess and process to name
    let counter = 0;
    let subprocessList = [];
    let processList = [];
    for(let j=0; j<processData.length; j++){
      for(let k=0; k<processData[j].children.length; k++){
        for(let i=0; i<finalCombination.length; i++){
          if(finalCombination[i][0] === counter){
            subprocessList = [...subprocessList, processData[j].children[k]];
            processList = [...processList, processData[j].name];
          }
        }
        counter++;
      }
    }
    if(fullName && subprocesses){return subprocessList;}
    if(fullName && !subprocesses){return processList;}
    let which = subprocesses ? subprocessList : processList;

    let output = [];
    for(let i=0; i<subprocessList.length; i++){
      localStorage.getItem("i18nextLng") === "ch-CH" && subprocessList[i].includes('/') ? output = [...output, '"' + which[i].split('/')[1] + '" '] : 
      output = [...output, '"' + which[i].split('/')[0] + '" '];
    }
    return output; 
  }

  function getTeams(){
    //Fetch teams from localstorage and sort them into an array.
    const employeeData = JSON.parse(localStorage.getItem("AllEmployeeData"));
    let teams = [];
    for(let i=0; i<employeeData.length; i++){
      localStorage.getItem("i18nextLng") === "ch-CH" && employeeData[i].team.includes('/')? teams = [...teams, employeeData[i].team.split('/')[1]] : 
      teams = [...teams, employeeData[i].team.split('/')[0]];
    }
    return teams;
  }

  function addMessage(messageText, ownerIsBot){
    //Adds a message, unless the conversation is finished.
    if(currentStage === "FINISHED"){
      return;
    }
    if(botThinking && !ownerIsBot){
      return;
    }
    let newMessages = [...messages, [messageText, ownerIsBot, true]];
    localStorage.setItem('chatbot_messages', JSON.stringify(newMessages));
    setMessages(newMessages);
    if(botThinking && ownerIsBot){
      setBotThinking(false);
    }
    if(!botThinking && !ownerIsBot){
      setBotThinking(true);
    }
    if(incrementQuestionAmount && !ownerIsBot){
      setQuestionAmount(questionAmount + 1);
    }
    incrementQuestionAmount = true;
  }

  function giveBotSelectedOption(option){
    //Comes from the option selector component
    incrementQuestionAmount = false;
    addMessage(option, false);
  }

  function makeMetricButtons(message){
    //Adds buttons with the various metrics, adds the message for it and advances the stage.
    const options = [t("PageRootcauseChatbot.Metric1"), t("PageRootcauseChatbot.Metric2"), t("PageRootcauseChatbot.Metric3"), t("PageRootcauseChatbot.Metric4"), t("PageRootcauseChatbot.Metric5")];
    setFormOptions(options);
    addMessage(t("PageRootcauseChatbot.EllyMetricMessage"), true);
    updateTranslatedMessages(message, "Please select one of the below options that most closely relates to the problem.");
    setCurrentStage("ASKING_RESPONSIBLE_TEAM");
    setShouldCheckGibberish(false);
  }

  //General controller method that handles all the input processing from start to finish.
  async function calculateResponse(input){
    try{
      //Try to translate the message
      let message = input;
      if(t("PageRootcauseChatbot.LanguageCode") !== "EN-GB"){
        message = await translateWithDeepL(input, "EN-GB");
      }
      if(message === t("PageRootcauseChatbot.TranslationFail")){
        addMessage(message, true)
        return;
      }
      if(detectNotKnowing(message) && messages[messages.length - 1] === t("PageRootcauseChatbot.EllyMetricMessage")){
        addMessage(t("PageRootcauseChatbot.EllyAskSolutionMessage"), true, true);
        updateTranslatedMessages(message, "Okay, enough about the problem. Let's start thinking in solutions. What can we do to solve this problem?");
        setCurrentStage("ASKING_IMPLEMENTING_TEAM");
        return;
      }
      //If the user doesn't know, perform custom stage changes depending on current conversation state
      if(detectNotKnowing(message) && currentStage === ("ASKING_WHY")){
        incrementQuestionAmount = false;
        makeMetricButtons(message);
        return;
      }
      if(detectNotKnowing(message) && currentStage === ("ASKING_RESPONSIBLE_TEAM")){
        incrementQuestionAmount = false;
        makeMetricButtons(message);
        setShouldCheckGibberish(false);
        return;
      }
      if(detectNotKnowing(message) && currentSubprocess < selectedSubprocesses.length - 1){
        let increment = 0;
        //If the subprocess has not yet been incremented, increment it and adjust message from bot
        if(currentStage !== "NEW_SUBPROCESS"){
          setCurrentSubprocess(currentSubprocess + 1);
          increment = 1;
        }
        addMessage(t("PageRootcauseChatbot.EllyNewSubprocessMessage1") + `${selectedSubprocesses[currentSubprocess + increment]}` + t("PageRootcauseChatbot.EllyNewSubprocessMessage2") + `${selectedProcesses[currentSubprocess + increment]}` + t("PageRootcauseChatbot.EllyNewSubprocessMessage3"), true, true);
        updateTranslatedMessages(message, "Let's move on to the next subprocess. You also said the subprocess " + `${fullNameSelectedSubprocesses[currentSubprocess + increment]}` + "from the process " + `${selectedProcesses[currentSubprocess + increment]}` + " needed improvement. Can you briefly describe why you think that?");
        
        setQuestionAmount(0);
        setCurrentStage("ASKING_WHY");
        setMetric("None");
        return;
      }
      if(detectNotKnowing(message) && currentSubprocess === selectedSubprocesses.length - 1 && currentStage !== "NEW_SUBPROCESS"){
        addMessage(t("PageRootcauseChatbot.EllyFinishMessage"), true, true);
        updateTranslatedMessages(message, "Thank you so much for taking the time to talk to me! Your feedback is much appreciated. Please continue onwards with the survey.");
        setCurrentStage("FINISHED");
        return;
      }
      //Check for button inputs with predetermined outcomes
      const buttonCheck = detectButtonInput(message);
      if(buttonCheck === "DONT_UNDERSTAND"){
        makeMetricButtons(message);
        return;
      }
      else if(buttonCheck === "UNRELATED"){
        addMessage(t("PageRootcauseChatbot.EllyUnrelatedMessage"), true);
        updateTranslatedMessages(message, "Apologies for the confusion. Please select the team that is most responsible for the root cause of the problem.");
        setCurrentStage("ASKING_SOLUTION");
        setFormOptions(teams);
        setShouldCheckGibberish(false);
        return;
      }
      let metricFound = checkMetrics(message);
      if(metricFound && currentStage === "ASKING_WHY"){
        setCurrentStage("ASKING_RESPONSIBLE_TEAM");
        setShouldCheckGibberish(false);
      }
      //If the message makes no sense, ask user to rephrase
      if(checkGibberish(message) && !metricFound){
        incrementQuestionAmount = false;
        addMessage(t("PageRootcauseChatbot.EllyDontUnderstandMessage"), true);
        updateTranslatedMessages(message, "I'm sorry, I don't understand you. Could you rephrase that in more detail?");
        return;
      }
      if(currentStage === "NEW_SUBPROCESS"){
        addMessage(t("PageRootcauseChatbot.EllyNewSubprocessMessage1") + `${selectedSubprocesses[currentSubprocess]}` + t("PageRootcauseChatbot.EllyNewSubprocessMessage2") + `${selectedProcesses[currentSubprocess]}` + t("PageRootcauseChatbot.EllyNewSubprocessMessage3"), true, true);
        updateTranslatedMessages(message, "Let's move on to the next subprocess. You also said the subprocess " + `${fullNameSelectedSubprocesses[currentSubprocess]}` + "from the process " + `${selectedProcesses[currentSubprocess]}` + " needed improvement. Can you briefly describe why you think that?");
        setCurrentStage("ASKING_WHY");
        setQuestionAmount(0);
      }
      else if(currentStage === "ASKING_WHY"){
        
        if(questionAmount >= (knowledge)){
          setCurrentStage("ASKING_RESPONSIBLE_TEAM");
        }
        if(messageLong(message)){
          addMessage(t("PageRootcauseChatbot.EllyDefaultResponse"), true);
          updateTranslatedMessages(message, "What is the cause of this?");
          return;
        }
  
        //Perform algorithm to create custom response
        let baggedNouns = makeBagOfWords(message);
        const targetSentence = getTargetSentence(message, baggedNouns);
        const slicedSentence = splitConjunctionBased(targetSentence);
        const response =  flipSentence(slicedSentence);
        setFormOptions([t("PageRootcauseChatbot.SteeringPrompt1"), t("PageRootcauseChatbot.SteeringPrompt2"), t("PageRootcauseChatbot.SteeringPrompt3")]);
        updateTranslatedMessages(message, response);
        let translatedResponse = "";
        if(t("PageRootcauseChatbot.LanguageCode") !== "EN-GB"){
          translatedResponse = await translateWithDeepL(response, t("PageRootcauseChatbot.LanguageCode"));
          addMessage(translatedResponse, true);
          updateTranslatedMessages(message, response);
          return;
        }
        updateTranslatedMessages(message, response);
        addMessage(response, true);
      }
  
      //Add hardcoded responses based on current stage
      else if(currentStage === "ASKING_RESPONSIBLE_TEAM"){
        addMessage(t("PageRootcauseChatbot.EllyAskResponsibleTeamMessage"), true);
        updateTranslatedMessages(message, "Please select the team that is most responsible for the root cause of this problem.");
        setFormOptions(teams);
        setCurrentStage("ASKING_SOLUTION");
        setShouldCheckGibberish(false);
      }
      else if(currentStage === "ASKING_SOLUTION" || formOptions[0] === "Communication") {
        addMessage(t("PageRootcauseChatbot.EllyAskSolutionMessage"), true, true);
        updateTranslatedMessages(message, "Okay, enough about the problem. Let's start thinking in solutions. What can we do to solve this problem?");
        setCurrentStage("ASKING_IMPLEMENTING_TEAM");
      }
      else if(currentStage === "ASKING_IMPLEMENTING_TEAM"){
        addMessage(t("PageRootcauseChatbot.EllyAskImplementingTeamMessage"), true);
        updateTranslatedMessages(message, "What team could best implement this solution?");
        setFormOptions(teams);
        setCurrentStage("ASKING_SOLUTION_DONE_BEFORE");
        setShouldCheckGibberish(false);
      }
      else if(currentStage === "ASKING_SOLUTION_DONE_BEFORE"){
        addMessage(t("PageRootcauseChatbot.EllyAskSolutionDoneBeforeMessage"), true);
        updateTranslatedMessages(message, "Has this solution been tried before?");
        setFormOptions([t("PageRootcauseChatbot.YesPrompt"), t("PageRootcauseChatbot.NoPrompt")])
        setCurrentStage("ASKING_SOLUTION_DIFFICULTIES");
        setShouldCheckGibberish(false);
      }
      else if(currentStage === "ASKING_SOLUTION_DIFFICULTIES"){
        if(checkNo(message)){
          addMessage(t("PageRootcauseChatbot.EllyAskSolutionDifficultiesNoMessage"), true);
          updateTranslatedMessages(message, "What is stopping you from implementing this solution?");
        }
        else{
          addMessage(t("PageRootcauseChatbot.EllyAskSolutionDifficultiesYesMessage"), true);
          updateTranslatedMessages(message, "What stopped this solution from working?");
        }
        //If we haven't talked about all subprocesses yet, advance by 1. Otherwise, finish up
        if(currentSubprocess < selectedSubprocesses.length - 1){
          setCurrentSubprocess(currentSubprocess + 1);
          setCurrentStage("NEW_SUBPROCESS");
          setMetric("None");
          return;
        }
        setCurrentStage("THANKING_USER");
      }
      else if(currentStage === "THANKING_USER"){
        addMessage(t("PageRootcauseChatbot.EllyFinishMessage"), true, true);
        updateTranslatedMessages(message, "Thank you so much for taking the time to talk to me! Your feedback is much appreciated. Please continue onwards with the survey.");
        setCurrentStage("FINISHED");
      }
    }
    catch(error){console.log(error)}
  }

  function updateTranslatedMessages(userMessage, botMessage){
    //Stage updates as the bot sends its response, but user's next reply still belongs to previous stage, so we need to fetch that here
    let newCurrentStage = currentStage;
    let sbp = currentSubprocess;
    if(translatedMessages.length > 2){
      newCurrentStage = translatedMessages[translatedMessages.length - 1][4];
    }
    //If the bot didn't understand the input, take the stage of the previous messages.
    if(messages[messages.length - 2][0] === t("PageRootcauseChatbot.EllyDontUnderstandMessage")){
      newCurrentStage = translatedMessages[translatedMessages.length - 2][4];
    }
    if(newCurrentStage === "NEW_SUBPROCESS"){
      newCurrentStage = "ASKING_WHY";
    }
    if(currentStage === "NEW_SUBPROCESS"){
      sbp = currentSubprocess - 1;
    }
    //This function exists because the calculator needs to save the translated messages to avoid unnecessary deepL calls
    const newMessages = [...translatedMessages, [userMessage, false, translatedMessages.length + 1, fullNameSelectedSubprocesses[sbp], newCurrentStage],
                                                [botMessage, true, translatedMessages.length + 2, fullNameSelectedSubprocesses[currentSubprocess], currentStage]];
    setTranslatedMessages(newMessages);
    localStorage.setItem('chatbot_translated_messages', JSON.stringify(newMessages));
  }

  function checkGibberish(message){
    if(!shouldCheckGibberish){
      setShouldCheckGibberish(true);
      return false;
    }
    if(gibberishCounter > 1){
      //If the user repeatedly spams gibberish, the bot will stop checking for it altogether. This is to avoid database clutter.
      return false;
    }
    let doc = nlp(message).toLowerCase();
    if(doc.text().includes("nothing")){return false;}
    if(doc.not('(#Noun|#Value|#Singular|#Plural|#Cardinal|#NumericValue)').text() === ""){
      setGibberishCounter(gibberishCounter + 1);
      return true;
    }
    return false;
  }
  
  function splitConjunctionBased(message){
    //Function that finds conjunctions like 'because' and splits a sentence in half.
    let doc = nlp(message);
    const keys = '(because|since|therefore|thus|hence|consequently|due to|cause|but|even though)';
    if(!doc.has('#Conjunction')){
      return message;
    }
    let sentenceParts = doc.clauses().if('#Conjunction').out('array');
    let correctPart = 0;
    //Find first part of sentence that has conjunction
    for(let i=0; i<sentenceParts.length; i++){
      let data = nlp(sentenceParts[i]);
        if(data.if(keys)){
        correctPart = i;
        break;
      }
    }
    
    if(correctPart !== 0){
      return sentenceParts[correctPart - 1];
    }

    //Remove all words behind conjunction
    let correctData = nlp(sentenceParts[correctPart]);
    correctData.matchOne(keys).lookBehind('.').remove();
    correctData.matchOne(keys).remove();
    return correctData.text();
  }

  function getTargetSentence(message, baggedNouns){
    //This function attempts to pick the best part of the user's input to respond to, using the bag of words algorithm.
    //Fetch all sentences and the top nouns in the sentences
    let sentences = nlp(message).clauses().out('array');
    let selectedSentenceIndex = 0;
    let highestCount = 0;
    //Check how often the nouns show up in each sentence. If a sentence has more matches, overwrite the previous.
    
    for(let i=0; i<sentences.length; i++){
      let count = 0;
      for(let it=0; it<baggedNouns.length; it++){
        if(sentences[i].includes(baggedNouns[it])){
          count++;
        }
      }
      if(count > highestCount){
        selectedSentenceIndex = i;
        highestCount = count;
      }
    }
    return sentences[selectedSentenceIndex];
  }

  function flipSentence(sentence){
    //This function takes a sentence and turns it into a why question.
    let data = nlp(sentence);
    //Make everything lowercase except acronyms, and remove punctuation
    data.not('#Acronym').toLowerCase();
    data.post('');
    //Return default response if sentence does not match certain constraints
    if(!data.has('#Pronoun')){
      return ("What is the cause of this?");
    }
    //Remove all text before the first pronoun
    data.not('(it|him|her|us|them|they|their|we)').matchOne('#Pronoun').lookBehind('.').remove();
    //Replace all pronouns relating to user with 'you'
    data.match('(my|our|)').replaceWith('your');
    data.match('am').replaceWith('are');
    data.match('#Pronoun').not('(your|it|them|their|they|he|she|#Possessive)').replaceWith('you');
    //if answer does not contain a verb, the 'magic formula' does not work, so return default response
    if(!data.has('#Verb') && !data.has('takes')){
      return ("What is the cause of this?");
    }
    return("Why is it that " + data.text() + "?");
  }

  function makeBagOfWords(text){
    //step 1: Make all the text lowercase and format it into an array
    let data = nlp(text).nouns().toSingular().remove('#Punctuation').compute('root').out('array');
    
    //step 2: Create additional array where stopwords are filtered out of strings
    const stopwords = ["problem", "issue", "a", "about","above","after","again","against","all","am","an","and","any","are","aren't","as","at","be","because","been","before","being","below","between","both","but","by","can't","cannot","could","couldn't","did","didn't","do","does","doesn't","doing","don't","down","during","each","few","for","from","further","had","hadn't","has","hasn't","have","haven't","having","he","he'd","he'll","he's","her","here","here's","hers","herself","him","himself","his","how","how's","i","i'd","i'll","i'm","i've","if","in","into","is","isn't","it","it's","its","itself","let's","me","more","most","mustn't","my","myself","no","nor","not", "nobody", "no one", "of","off","on","once","only","or","other","ought","our","ours","ourselves","out","over","own","problem","same","shan't","she","she'd","she'll","she's","should","shouldn't","so","some", "something", "such","than","that","that's","the","their","theirs","them","themselves","then","there","there's","these","they","they'd","they'll","they're","they've","this","those","through","to","too","under","until","up","very","was","wasn't","we","we'd","we'll","we're","we've","were","weren't","what","what's","when","when's","where","where's","which","while","who","who's","whom","why","why's","with","won't","would","wouldn't","you","you'd","you'll","you're","you've","your","yours","yourself","yourselves"];
    
    let filteredData = [];
    for (let i=0; i<data.length; i++){
      let words = data[i].split(' ');

      let filteredNoun = words.filter(function(word){
        return stopwords.indexOf(word) === -1;
      }).join(' ');

      filteredData.push(filteredNoun);
    }
    //step 3: Create array with amount of times word appears in text, and store highest number
    //Algorithm also gives priority to similarly used words that are more descriptive. 
    //eg 'honest communication' will count 'communication' as an extra appearance, but not vice versa
    let counts = [];
    let highest = 1;
    for(let i=0; i<filteredData.length; i++){
      let currentWord = filteredData[i];
      let currentCount = 0;
      for(let it=0; it<filteredData.length; it++){
        if(currentWord.includes(filteredData[it]) && currentWord !== '' && filteredData[it] !== ''){
          currentCount++;
        }
        if(currentCount > highest){
          highest = currentCount;
        }
      }
      counts = [...counts, currentCount];
    }

    //step 4: Create array with highest appearance rate nouns, taking from the original data to include stopwords in bot response
    let bestNouns = [];
    for (let i=0; i<filteredData.length; i++){
      if(counts[i] === highest && !bestNouns.includes(filteredData[i])){
        bestNouns = [...bestNouns, data[i]];
      }
    }
    return(bestNouns);
  }

  function checkMetrics(message){
    //This function checks all the text for metrics. Also checks for the metric button inputs
    const metrics = ["communication/collaboration", "exchanges/cooperation", "exchange/cooperation", "communicatie/collaboratie", "lack of motive power", "lack of skillset/knowledge", "lack of proactivity", "language/culture", "none apply", "skillset", "motivation", "budget", "communication", "dialogue", "intercommunication", "silence", "verbalization", "disclosure", "talk", "chat", "chatter", "discussion", "discuss", "verbalize", "speak", "transparency", "express", "expression", "convey", "knowledge", "inept", "ineptitude", "incompetent", "incompetence", "driving force", "unmotivated", "lazy", "unenthusiastic", "slump", "efficiency", "slow", "culture"];
    const data = nlp(message).compute('root').toLowerCase().text();
    for (let i=0; i<metrics.length; i++){
      if(data.includes(metrics[i])){
        setMetric(metrics[i]);
        return true;
      }
    }
    return false;
  }

  function detectButtonInput(message){
    //This function checks for the steering buttons. 
    if(message === "This question is unrelated to the original problem" || message === "This question is not related to the original problem" || message === "This question is not relevant to the original question"){
      return "UNRELATED";
    }
    else if(message.includes("I don't understand the question")){
      return "DONT_UNDERSTAND";
    }
    return "NONE";
  }

  function messageLong(message){
    //Function that checks if a message is very long
    let doc = nlp(message);
    if(doc.clauses().out('array').length > 2){
      return true;
    }
    return false;
  }

  function checkNo(message){
    //Function that checks if the user responds yes or no
    let doc = nlp(message);
    doc.toLowerCase().contractions().expand();
    if(doc.text().includes("no")){
      return true;
    }
    if(doc.text().includes("nee") && t("PageRootcauseChatbot.LanguageCode") === "NL"){
      return true;
    }
    return false;
  }

  function detectNotKnowing(message){
    //Function that checks if the user says they don't know
    const keywords = ["know", "clue", "idea", "answer", "dunno"];
    let doc = nlp(message);
    doc.toLowerCase().contractions().expand();
    if(!doc.has("#Negative") && !doc.has("no ")){
      return false;
    }

    let foundKeyword = false;
    for(let i=0; i<keywords.length; i++){
      if(message.includes(keywords[i])){
        foundKeyword = true;
      }
    }

    if(!foundKeyword){
      return false;
    }
    if(doc.toLowerCase().not("(i |you)").has("#Pronoun")){
      return false;
    }
    return true;
  }

  useEffect(() => {
    if(messages[messages.length - 1][0] !== t("PageRootcauseChatbot.ChangedSubprocesses") && messages.length > 2){
      addMessage(t("PageRootcauseChatbot.ChangedSubprocesses"), true);
    }
  }, []);

  useEffect(() => {
    if(botThinking){
      setFormOptions([]);
      calculateResponse(messages[(messages.length - 1)][0]);
    }
    var elem = document.getElementById('chatboxContainer');
    elem.scrollTop = elem.scrollHeight;
  }, [messages]); 

  useEffect(() => {
    //If any values are updated, store them, but only after the initial render to ensure no overwriting saved data
    if(firstRender){
      setFirstRender(false);
      return;
    }
    let tempCopy = conversationData;
    tempCopy.stage = currentStage;
    tempCopy.options = formOptions;
    tempCopy.questionamount = questionAmount;
    tempCopy.checkgibberish = shouldCheckGibberish;
    tempCopy.metric = metric;
    tempCopy.thinking = botThinking;
    tempCopy.subprocess = currentSubprocess;
    tempCopy.gibberishcounter = gibberishCounter;
    tempCopy.subprocessqueue = selectedSubprocesses;
    tempCopy.processqueue = selectedProcesses;
    tempCopy.comboqueue = comboQueue;

    localStorage.setItem('chatbot_conversationdata', JSON.stringify(tempCopy));
    setConversationData(tempCopy);
  }, [currentStage, formOptions, questionAmount, shouldCheckGibberish, metric, botThinking, currentSubprocess, gibberishCounter, selectedSubprocesses, selectedProcesses, comboQueue]);

  useEffect(() => {
    //If page is refreshed and there is stored data, load it in
    if(localStorage.getItem('chatbot_conversationdata')){
      let data = JSON.parse(localStorage.getItem('chatbot_conversationdata'));
      setCurrentStage(data.stage);
      setFormOptions(data.options);
      setQuestionAmount(data.questionamount);
      setShouldCheckGibberish(data.checkgibberish);
      setMetric(data.metric);
      setBotThinking(data.thinking);
      setCurrentSubprocess(data.subprocess);
      setGibberishCounter(data.gibberishcounter);
      return;
    }
  }, []);


  function translateWithDeepL(text, targetLanguage){
    const axiosInstance = axios.create({withCredentials: true});
    const baseURL = process.env.REACT_APP_APIURL;
    const data = `{ 
      "text": "${text}", 
      "targetLanguage": "${targetLanguage}"
    }`
    return axiosInstance.post(baseURL+"/api/translate/", data, {
      headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookieValue("csrftoken")
      },
    })
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log("ERROR: Could not translate text with DeepL", error);
      return t("PageRootcauseChatbot.TranslationFail");
    })
  }

  return(
    <Container>
      <HeaderAndOverlayWrapper>
        <ComponentHeaderBlock page='Survey'/>
      </HeaderAndOverlayWrapper>
      <WrapperBottom>
        <WrapperTitle>
          <Trans i18nKey="PageRootcauseChatbot.HeaderText">
          Talk <span style={{color: "#B3194A"}}> Talk </span>
          to Elly to find the root cause of your problems
          </Trans>
        </WrapperTitle>
        <ComponentChatbox messages={messages} botThinking={botThinking} formOptions={formOptions} 
        giveBotSelectedOption={giveBotSelectedOption}>
        </ComponentChatbox>
        <ComponentSendmessage addMessage={addMessage} botThinking={botThinking} currentStage={currentStage} />
      </WrapperBottom>

      <ComponentFooter page='PageRootcauseChatbot' backDirectory='/survey-business-process-selection-feedback-improvement' conversationState={currentStage === "FINISHED" ? true:false} FooterMarginTop={(useWindowSize()[1] - 104).toString() + "px"} 
                        FooterMarginLeft='0px'FooterHeight='104px' FooterWidth='100%' MarginTop='25px'MarginLeft='77%' BackButtonMarginLeft='76%' NextButtonMarginLeft='calc(76% + 66px)' nextButtonClicked={nextButtonClicked} setNextButtonClicked={setNextButtonClicked}/>
    </Container>
  );
}
// /survey-feedback-improvement
export default PageRootcauseChatbot

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 105px);
  background: #F1F3F4;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  align-items: center;

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
`;

const WrapperTitle = styled.div`
height: fit-content;
font-family: montserrat;
font-weight: bold;
font-size: 32px;
background: #FCFCFC;
padding: 0px 0px 32px 0px;
text-align: center;
width: 100%;
`;

const HeaderAndOverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
`;

const WrapperBottom = styled.div`
  margin-top: 64px;
  position: relative;
  width: 90%;
  max-width: 1920px;
  height: calc(100% - 216px);
  padding: 32px 0px 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #FCFCFC;
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.25);
`;