import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Component} from 'react';
import reportWebVitals from './reportWebVitals';
import {createRoot} from 'react-dom/client';
import './i18n';

import {OverlayContextProvider} from "./layout-and-styling/context-hooks/OverlayContext";

/** Login and Auth pages */
import Login from './authentication-and-home/Login'; 
import Logout from './authentication-and-home/Logout'; 
import Home from "./authentication-and-home/Home";
/** Intake */
import PageIntakeBuilder from './intake/intake-builder/Intake_Builder';
import PageProcessTime from './intake/PageProcessTime';
import IntakeRepresentativeChoice from './intake/PageIntakeRepresentative';

/** User Introduction */
import PageWelcome from './survey/survey-introduction/Survey_Welcome'; 
import PageBenefits from './survey/survey-introduction/Survey_Benefits';
import PageInstructions from "./survey/survey-introduction/Survey_Instructions";

/** Survey pages */
import PageCloseColleagueFilter from './survey/survey-close-colleagues/PageCloseColleagueFilter';
import PagePlannedMeetingsQuestion from './survey/survey-planned-meetings/PagePlannedMeetingsQuestion';
import PagePlannedMeetings from './survey/survey-planned-meetings/PagePlannedMeetings';
import PageSpontaneousCollaboration from './survey/survey-spontaneous-collaboration/PageSpontaneousCollaboration';
import PageKnowledge from './survey/survey-individual-impact/Page-Knowledge';
import PageCollaborationMeasurement from "./survey/survey-collaboration-quality/PageCollaborationMeasurement";
import PageProactiveness from "./survey/survey-leadership/PageProactiveness";
import PrePageProcessGoodFeedback from './survey/survey-feedback-best-practice/Pre-Page-Process-Good-Feedback';
import PageProcessGoodFeedback from './survey/survey-feedback-best-practice/Page-Process-Good-Feedback';
import PrePageProcessBadFeedback from './survey/survey-feedback-improvement/Pre-Page-Process-Bad-Feedback';
import PageProcessBadFeedback from './survey/survey-feedback-improvement/Page-Process-Bad-Feedback';
import PageRootcauseChatbot from './survey/survey-rootcause-chatbot/PageRootcauseChatbot';
import PageCulture from './survey/survey-culture/PageCulture';
import PageCulturePeer from './survey/survey-culture/PageCulturePeer';

import PageSurveyCompleted from './survey/survey-completed/Page-Survey-Completed';

/** Dashboard pages */
import {DashboardContextProvider} from "./dashboard/DashboardContext";
import PageDashboard from "./dashboard/Dashboard";
// import DashboardSubprocesses from "./archive/Dashboard-subprocesses";

class App extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/" component={Login}/>
                    <Route exact path="/login" component={Login}/>
                    <Route exact path='/logout' component={Logout}/>

 
                    <OverlayContextProvider>
                        <Route exact path="/home" component={Home}/>
                        <Route exact path="/intake-builder" component={PageIntakeBuilder}/>
                        <Route exact path="/intake-representative-choice" component={IntakeRepresentativeChoice}/>
                        <Route exact path="/intake-process-time" component={PageProcessTime}/>
                        <Route exact path="/user-welcome" component={PageWelcome}/>
                        <Route exact path="/user-benefits" component={PageBenefits}/>
                        <Route exact path="/user-instructions" component={PageInstructions}/>
                        <Route exact path="/survey-close-colleagues" component={PageCloseColleagueFilter}/>

                        <Route exact path="/survey-planned-meetings-question" component={PagePlannedMeetingsQuestion}/>
                        <Route exact path="/survey-planned-meetings" component={PagePlannedMeetings}/>
                        
                        <Route exact path="/survey-spontaneous-collaboration" component={PageSpontaneousCollaboration}/>

                        <Route exact path="/survey-collaboration-measurement" component={PageCollaborationMeasurement}/>

                        <Route exact path="/survey-driving-force" component={PageProactiveness}/>

                        <Route exact path="/survey-knowledge" component={PageKnowledge}/>
                        {/* <Route exact path="/survey-individual-satisfaction" component={PageIndividualSatisfaction}/> */}
                        <Route exact path="/survey-culture" component={PageCulture}/>
                        {/* <Route exact path="/survey-culture-peer" component={PageCulturePeer}/> */}

                        <Route exact path="/survey-business-process-selection-feedback-optimal" component={PrePageProcessGoodFeedback}/>
                        <Route exact path="/survey-feedback-optimal" component={PageProcessGoodFeedback}/>

                        <Route exact path="/survey-business-process-selection-feedback-improvement" component={PrePageProcessBadFeedback}/>
                        <Route exact path="/survey-feedback-improvement" component={PageProcessBadFeedback}/>

                        <Route exact path="/survey-rootcause-chatbot" component={PageRootcauseChatbot}/>

                        <Route exact path="/survey-completed" component={PageSurveyCompleted}/>

                        <DashboardContextProvider>
                            <Route exact path="/dashboard" component={PageDashboard}/>
                            {/* <Route exact path="/dashboard-analysis" component={DashboardSubprocesses}/> */}
                        </DashboardContextProvider>

                    </OverlayContextProvider>

                </Switch>
            </Router>
        );
    }
};

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App/>);

reportWebVitals();

/** Archive */
// <Route exact path="/leader-intake-introduction" component={LeaderIntakeIntroduction} />
// <Route exact path="/leader-intake-goals" component={LeaderIntakeGoals}/>
// <Route exact path="/leader-intake-kpi" component={LeaderIntakeKPI}/>
// <Route exact path="/leader-intake-problem-identification" component={LeaderIntakeProblemIdentification}/>
// <Route exact path="/leader-intake-milestones" component={LeaderIntakeMilestones}/>

// <Route exact path="/leader-introduction" component={PageLeaderIntroduction}/>
// <Route exact path="/leader-benefits" component={PageLeaderBenefits}/>
// <Route exact path="/leader-input-explanation" component={PageLeaderInput}/>
// <Route exact path="/leader-team-composition" component={PageLeaderTeamComp}/>
// <Route exact path="/leader-business-processes" component={PageLeaderBusinessProcesses}/> 
// <Route exact path="/user-gifs" component={PageIntroGifs}/>
// <Route exact path="/user-benefits-quotes" component={PageBenefitsTestimonials}/>