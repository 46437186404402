import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {useHistory} from 'react-router-dom';

import ComponentHeaderBlock from "../../layout-and-styling/standardized-components-library/Component-header-block";
import ComponentFooter from "../../layout-and-styling/standardized-components-library/Component-Footer";
import { useWindowSize } from "../../layout-and-styling/standardized-components-library/Component-Responsive";
import { Trans, useTranslation } from "react-i18next";
import { StyledDivButton } from "../../layout-and-styling/standardized-components-library/Styling-TextInput";


const PagePlannedMeetingsQuestion = () => {

  let history = useHistory();

  const [render, setRender] = useState(false);
  const reRender = () => {
      setRender(!render)
  };

  return (
    <>
      <Container>

        <HeaderAndOverlayWrapper>
            <ComponentHeaderBlock page="Survey" overlay="PagePlannedMeetingsQuestion" directory="/survey-planned-meetings-question" reRender={reRender} />
        </HeaderAndOverlayWrapper>

        <QuestionWrapper>
          <Trans i18nKey="PagePlannedMeetingsQuestion.Question">
                {/* Do you have planned meetings in a typical week? */}
          </Trans>
        </QuestionWrapper>

        <ButtonsWrapper>
          <StyledDivButton Color="#271B36" Position="relative" MarginTop="0px" MarginLeft="0px" Width="210px" Height="64px" FontSize="24px" FontWeight="500">
            <button
                onClick={() => {
                    localStorage.setItem("plannedMeetings", JSON.stringify(true))
                    history.push("/survey-planned-meetings");
                }} > 
            <Trans i18nKey="PagePlannedMeetingsQuestion.Yes"/>
            </button>
          </StyledDivButton>

          <StyledDivButton Color="#271B36" Position="relative" MarginTop="0px" MarginLeft="0px" Width="210px" Height="64px" FontSize="24px" FontWeight="500">
            <button
                  onClick={() => {
                    localStorage.setItem("plannedMeetings", JSON.stringify(false))
                      history.push("/survey-spontaneous-collaboration");
                  }} > 
              <Trans i18nKey="PagePlannedMeetingsQuestion.No"/>
            </button>
          </StyledDivButton>
        </ButtonsWrapper>
        

        <ComponentFooter page="PagePlannedMeetingsQuestion" backDirectory="/survey-close-colleagues" FooterMarginTop={(useWindowSize()[1] - 104).toString() + "px"}
                          FooterMarginLeft="0px" FooterHeight="104px" FooterWidth="100%" MarginTop="25px" BackButtonMarginLeft="82px" />
      </Container>
    </>
  );
};
export default PagePlannedMeetingsQuestion;

const Container = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #f1f3f4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: calc(1 / 1.75);
  }
`;

const HeaderAndOverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
`;

const QuestionWrapper = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-weight: 700;
  color: #271b36;
  display: flex;
  justify-content: center;

  span {
    font-family: "Montserrat", sans-serif;
    font-size: 32px;
    font-weight: 700;
    color: #e3316b;
  }
  span2 {
    font-family: "Montserrat", sans-serif;
    font-size: 32px;
    font-weight: 700;
    color: #271b36;
  }
`;

const ButtonsWrapper = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: #F1F3F4;
  z-index: 1;
  gap: 46px;
  button {
    border-radius: 8px;
    cursor: "pointer";
    transition: all 0.2s ease-in;
    pointer-events: "all";
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    background-color: #FCFCFC;

    :hover {
        border: solid 2px #E2236B;
        transition-duration: 0s;
    }
  }
`;