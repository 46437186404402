import { useTranslation } from 'react-i18next';
import { useState } from "react";
import styled from 'styled-components';
import load from "../../../assets/dashboard/load.gif";
import { ComponentPageTransformation } from '../../../layout-and-styling/standardized-components-library/Component-Tooltips';


import TargetIcon from "../../../assets/number_solved.svg";
import ElephantIcon from "../../../assets/elephant_solved.svg";

const TransformationsSolved = ({ totalProblems, solvedProblems, surveyDates }) => {

    const { t, i18n } = useTranslation();
    const [hover1, setHover1] = useState(false);


    return (
        <LessVotesWrapper>
            {totalProblems && solvedProblems && surveyDates.length ?
                <LessVotesData>

                    <ProblemsOverviewSVGContainer>
                        <img src={TargetIcon} alt='' />
                    </ProblemsOverviewSVGContainer>

                    <LessVotesTitle>
                        {`${solvedProblems} ${t('Transformation.OutOf')} ${totalProblems}`}
                    </LessVotesTitle>

                    <LessVotesSubtitle>
                        {t('Transformation.ElephantsSolved')}
                    </LessVotesSubtitle>

                    <LessVotesDates>
                        <p> {t('Transformation.From')} {surveyDates[0]} {t('Transformation.To')} {surveyDates[1]} </p>
                    </LessVotesDates>

                </LessVotesData> : <LoadLogo>
                    <img src={load} alt="loading..." />
                </LoadLogo>}

            {/* The hover information */}
            <InfoHover onMouseOver={() => setHover1(true)}
                onMouseOut={() => setHover1(false)}>?
                {hover1 && (<ComponentPageTransformation whichTooltip={"ElephantsLessVotedOn"} />
                )}
            </InfoHover>

            <ElephantLogoContainer>
                <img src={ElephantIcon} alt='' />
            </ElephantLogoContainer>
        </LessVotesWrapper>
    )
}

export default TransformationsSolved

const LessVotesWrapper = styled.div`
    position: relative;
    display: flex;
    margin-left: 0px;
    margin-right: 20px;
    background: #FCFCFC;
    height: 215px;
    min-width: fit-content;
    width: 375px;
    flex-direction: column;
    border-radius: 5px;
`;

const LessVotesData = styled.div`
    margin-left: 30px;
    margin-top: 23px; 
`;

const LessVotesTitle = styled.div`
    color: #048B67;
    font-family: 'Montserrat';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    margin-block: 10px;
`;

const LessVotesSubtitle = styled.div`
    color: #271B36;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; 
`;

const LessVotesDates = styled.div`
    color: #929699;
    font-family: 'Overpass';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
`;

const ProblemsOverviewSVGContainer = styled.div`
    width: 50.13px;
    height: 42.8px;
`;

const ElephantLogoContainer = styled.div`
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 91px;
    height: 66px; 
`;

const InfoHover = styled.div`
    font-size: 18px;
    position: absolute;
    font-family: "Overpass";
    font-weight: 300;
    color: #FCFCFC;
    background-color: #e2336b;
    border: 2px solid #e2336b;
    top: 16px;
    border-radius: 500px;
    width: 20px;
    text-align: center;
    height: 20px;
    cursor: pointer;
    z-index: 20;
    right: 16px;
    float: right;
`;

const LoadLogo = styled.div`
  img {
    width: 50px;
  }
  display: flex;
  position: absolute;
  top: 40%;
  left: 43%;
`;