import styled from 'styled-components';
import ActionNeededContainer from './ActionNeededContainer';
import { ReactComponent as ActionNeededIcon } from '../../../../assets/action_needed_icon.svg';

const ActionsNeededList = ({ actionsNeeded, color }) => {
    if (actionsNeeded) {
        return (

            <ActionsContainer>
                {Object.keys(actionsNeeded).map((key) => {
                    return (
                        <ActionsNeededWrapper key={key}>
                            <ActionNeededIconWrapper >
                                <ActionNeededIcon style={{ fill : color }} />
                                <Line color={color}/>
                            </ActionNeededIconWrapper>
                            <ActionNeededContainer key={key} name={key} action={actionsNeeded[key]} color={color} />
                        </ActionsNeededWrapper>
                    )
                })}

            </ActionsContainer>
        )
    }
    else {
        return (<>Loading..</>)
    }
}

export default ActionsNeededList;

const ActionsContainer = styled.div`
    margin-top: 10px;
    overflow-y: auto;
    height: 300px;
    width: 530px;
    overflow-x: hidden;

    ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #929699;
  }
`;

const ActionNeededIconWrapper = styled.div`
    margin-right: 8px;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Line = styled.div`
  border-left: 1px solid;
  border-color: ${props => props.color};
  height: 100%;
  margin-right: 6px;
  margin-top: 4px;
`;

const ActionsNeededWrapper = styled.div`
    display: flex;
`;