import React, { useEffect } from 'react';
import styled from 'styled-components';
import {MenuItem} from "@mui/material";
import {ReactComponent as FlagCh} from "../../assets/translation/flag-ch.svg";
import {ReactComponent as FlagNl} from "../../assets/translation/flag-nl.svg";
import {ReactComponent as FlagEn} from "../../assets/translation/flag-en.svg";
import {StyledFormControl, StyledLanguageSwitcher} from "./Styling-TextInput";
import {useTranslation} from 'react-i18next';

const ComponentLanguageSwitcher = (componentprops) => {
    const {t, i18n} = useTranslation();
    
    const languages = [
        {id: 0, code: 'en-US', flagCode: "en", name: 'English'},
        {id: 1, code: 'nl-NL', flagCode: "nl", name: 'Nederlands'},
        {id: 2, code: 'ch-CH', flagCode: "ch", name: '中文'}
    ]

    function getFlag(flagCode) {
        if (flagCode === "ch")
            return <FlagCh/>
        else if (flagCode === "nl")
            return <FlagNl/>
        else 
            return <FlagEn/>
    }

    const handleChangeLanguage = (e) => {
        i18n.changeLanguage(e.target.value);
        if (componentprops.reRender)
            componentprops.reRender();
    };

    useEffect(() => {
        if(!i18n.language || (i18n.language != 'en-US' && i18n.language != "ch-CH" && i18n.language != "nl-NL"))
        {
            i18n.changeLanguage('en-US')
        }
    },[])

    return (
        <LanguageSwitcher Position={componentprops.Position} Top={componentprops.Top} Height={componentprops.Height} Padding={componentprops.Padding} MarginTop={componentprops.MarginTop} MarginLeft={componentprops.MarginLeft} MarginRight={componentprops.MarginRight} BorderRadius={componentprops.BorderRadius} Background={componentprops.Background}>
            <StyledFormControl variant="standard" MinWidth='auto'>
                <StyledLanguageSwitcher
                    value={i18n.language}
                    onChange={handleChangeLanguage}
                    sx={{
                        "&.MuiInput-root::before": {
                            border: "none !important;"
                        },
                        "&.MuiInput-root:after": {
                            border: "none !important;"
                        }
                    }}
                    Color={componentprops.Color}
                >
                    
                {languages.map((language) => (
                    <MenuItem
                        key={language.id}
                        value={language.code}
                    >
                        <FlagWrapper>{getFlag(language.flagCode)}</FlagWrapper>
                        <NameWrapper>{language.name}</NameWrapper>
                    </MenuItem>
                ))}

                </StyledLanguageSwitcher>
            </StyledFormControl>
        </LanguageSwitcher>
    );
};

export default ComponentLanguageSwitcher;

export const LanguageSwitcher = styled.div`
  position: ${props => props.Position || 'absolute'};
  right: 0;
  display: flex;
  margin-top: ${props => props.MarginTop || "8px"};
  margin-left: ${props => props.MarginLeft || "75%"};
  margin-right: ${props => props.MarginRight || "60px"};
  position: ${props => props.Position || 'absolute'};;
  top: ${props => props.Top || '-20px'};
  height: ${props => props.Height || '50px'};
  min-width: 200px;
  right: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: ${props => props.Padding || ''};
  margin-top: ${props => props.MarginTop || '8px'};
  margin-left: ${props => props.MarginLeft || '75%'};
  margin-right: ${props => props.MarginRight || '60px'};
  border-radius: ${props => props.BorderRadius || ''};
  background: ${props => props.Background || ''};
  
  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
`;

const FlagWrapper = styled.div`
  display: flex;
  float: left;
  width: 25px;
  margin-top: 1px;

`;

const NameWrapper = styled.div`
  width: fit-content;
  float: left;
  margin-top: 4px;
  margin-left: 6px;
  display: flex;
`;