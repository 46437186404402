import {useContext, useRef, useState} from "react";
import { DashboardContext } from "../../DashboardContext"; // import the d3 component
import axios from 'axios';
import styled from "@emotion/styled";

/** variable to hold the component, initialized at null and assigned later when the component is created */
let vis = null;

export default function MatrixRenderComponent() {

    //React js hook usestate for tracking data changes
    const [data, setData] = useState(null);
    const [width, setWidth] = useState(1042);
    const [height, setHeight] = useState(472);
    // const [active, setActive] = useState(null);
    const dashboard_context_value = useContext(DashboardContext);
    const selectedCompany = JSON.parse(localStorage.getItem("selected_company"));
    const matrixData = JSON.parse(localStorage.getItem("matrix_data"));

    // console.log(data)
    /** The useRef Hook creates a variable that "holds on" to a value across rendering passes.
     * In this case it will hold our component's SVG DOM element. It's initialized null and React will assign it later (see the return statement) */
    const refElement = useRef(null);

    //It adds content to the DOM outside of React’s virtual DOM mechanism
    // useEffect(initVis, [data]);
    // useEffect(fetchData(), []);

    /** Function that sets the data of the hook */
    function fetchData() {
        const selectedCompany = JSON.parse(localStorage.getItem("selected_company"));
        const axiosInstance = axios.create({withCredentials: true})
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/mymatrix/elephants_score/' + selectedCompany.id)
            .then((res) => {
                setData(res.data)
            })
            .catch((error) => {
                console.log('ERROR: Could not fetch /api/mymatrix/')
            })

        // Promise.resolve().then(() => setData(matrixdata));
    }

    /** Function that initializes the d3 data that needs to be passed to the d3 component and
     * initiliazing the vis variable as the d3 component */
        // function initVis() {
        //     if(data) {
        //         const d3Props = {
        //             data,
        //             width,
        //             height,
        //         };
        //         vis = new D3MatrixValidatorClass(refElement.current, d3Props);
        //     }
        // }

    const renderTable = () => {
        console.log(matrixData)
        let table = '';
        for (let a = 0; a < data[0].length; a++) {
            table += <tr key={a}>
                {/* {data[2][a].map((col, j) => (
                    <td>{col.value}</td>
                ))} */}
            </tr>
        }
        return table
    };
    return (
        <Background>
            <StyledTable>
                {/* {renderTable()} */}
                <Teams>
                    {/* {matrixData.teams.map((value, id) => ( */}
                    {matrixData.processes.map((value, id) => (    
                        <td>{value} <Space1 /> </td>
                    ))}
                </Teams>
                <RowIndex>
                    {/* {matrixData.nodes.map((value, id) => ( */}
                    {matrixData.subprocesses.map((value, id) => (    
                        <td>{value} <Space2 /> </td>
                    ))}
                </RowIndex>
                <ColIndex>
                    {/* {matrixData.matrix.map((row, row_index) => ( */}
                    {matrixData.nodes.map((row, row_index) => (
                        <>
                        {
                            row_index - 1 > 0 && matrixData.nodes[row_index].team__name != matrixData.nodes[row_index-1].team__name ?
                            <>
                            <LineH />
                            <tr>
                                {console.log("employee: ", row.id)}
                                {row_index}
                                {matrixData.subprocesses.map((col, col_index) => (
                                    <>
                                    {
                                        col_index - 1 > 0 && matrixData.subprocesses[col_index] < matrixData.subprocesses[col_index-1] ?
                                        <>
                                        <LineV />
                                        <td>
                                            {console.log("subprocess: ", col_index)}
                                            {console.log("feedback good: ", matrixData.feedback[row.id]['good'], "feedback bad: ", matrixData.feedback[row.id]['bad'])}
                                            {/* <Cell BackgroundColor={col > matrixData.average ? "#00C592" : col > 0 ? "#FFD166" : "#FCFCFC"}></Cell> */}
                                            <Cell BackgroundColor={matrixData.feedback[row.id]['good'].includes(col_index) ? "#00C592" : matrixData.feedback[row.id]['bad'].includes(col_index) ? "red" : "#FCFCFC"}></Cell>
                                        </td>
                                        </>
                                        :
                                        <td>    
                                            {console.log("subprocess: ", col_index)}
                                            {console.log("feedback good: ", matrixData.feedback[row.id]['good'], "feedback bad: ", matrixData.feedback[row.id]['bad'])}         
                                            {/* <Cell BackgroundColor={col > matrixData.average ? "#00C592" : col > 0 ? "#FFD166" : "#FCFCFC"}></Cell> */}
                                            <Cell BackgroundColor={matrixData.feedback[row.id]['good'].includes(col_index) ? "#00C592" : matrixData.feedback[row.id]['bad'].includes(col_index) ? "red" : "#FCFCFC"}></Cell>
                                        </td>
                                    }
                                    </>
                                ))}
                            </tr>
                        </>
                        :
                        <tr>
                            {console.log("employee: ", row.id)}
                            {row_index}
                            {matrixData.subprocesses.map((col, col_index) => (
                                <>
                                {
                                    // col_index - 1 > 0 && matrixData.nodes[col_index].team__name != matrixData.nodes[col_index-1].team__name ?
                                    col_index - 1 > 0 && matrixData.subprocesses[col_index] < matrixData.subprocesses[col_index-1] ?
                                    <>
                                    <LineV />
                                    <td>
                                        {console.log("subprocess: ", col_index)}
                                        {console.log("feedback good: ", matrixData.feedback[row.id]['good'], "feedback bad: ", matrixData.feedback[row.id]['bad'])}
                                        {/* <Cell BackgroundColor={col > matrixData.average ? "#00C592" : col > 0 ? "#FFD166" : "#FCFCFC"}></Cell> */}
                                        <Cell BackgroundColor={matrixData.feedback[row.id]['good'].includes(col_index) ? "#00C592" : matrixData.feedback[row.id]['bad'].includes(col_index) ? "red" : "#FCFCFC"}></Cell>
                                    </td>
                                    </>
                                    :
                                    <td>
                                        {console.log("subprocess: ", col_index)}
                                        {console.log("feedback good: ", matrixData.feedback[row.id]['good'], "feedback bad: ", matrixData.feedback[row.id]['bad'])}
                                        {/* <Cell BackgroundColor={col > matrixData.average ? "#00C592" : col > 0 ? "#FFD166" : "#FCFCFC"}></Cell> */}
                                        <Cell BackgroundColor={matrixData.feedback[row.id]['good'].includes(col_index) ? "#00C592" : matrixData.feedback[row.id]['bad'].includes(col_index) ? "red" : "#FCFCFC"}></Cell>
                                    </td>
                                }
                                </>
                            ))}
                        </tr>
                        }
                        </>
                    ))}
                </ColIndex>
            </StyledTable>
        </Background> 
    )
}

const Background = styled.div`
  /* min-height: fit-content; */
    height: calc(100% - 50px);
    width: 100%;
    margin-left: 32px;
    margin-top: 12px;
    background-color: #fcfcfc;
`;

const StyledTable = styled.table`
    min-height: 300px;
`;

const Teams = styled.table`
    margin-left: 64px;
    margin-bottom: 24px;
`;

const RowIndex = styled.table`
    margin-left: 58px;
`;

const ColIndex = styled.table`
    width: 1000px;
    margin-left: 32px;
`;

const Cell = styled.div`
    height: 16px;
    width: 16px;
    margin-top: 0px;
    margin-left: 0px;
    background-color: ${props => props.BackgroundColor || "#FCFCFC"};
    z-index: 2;
`;

const LineV = styled.div`
    position: absolute;
    border-left: 3px solid black;
    height: 50px;
    margin-left: -4px;
    margin-top: -20px;
`;

const LineH = styled.div`
    position: absolute;
    border-top: 3px solid black;
    width: 1000px;
`;

const Space1 = styled.div`
    width: 112px;
`;

const Space2 = styled.div`
    //width: 20.57px;
    /* width: 18.15px; */
    /* width: 29.9px; */
    width: 51.5px;
`;