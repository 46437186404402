import React, {useContext, useState} from "react";
import styled from "styled-components";
import {StyledDivRegular} from "../../layout-and-styling/standardized-components-library/Styling-TextInput.js";
import {OldDonutChart} from "../specific-components/Component-Donut-Chart.js";
import ComponentTopBotProcesses from "../../layout-and-styling/standardized-components-library/Component-TopBotProcesses.js";
import ComponentTagOverview from "./Component-tag-overview.js";
import {OverlayContext} from "../../layout-and-styling/context-hooks/OverlayContext.js";
import {Trans, useTranslation} from 'react-i18next';

const ProcessData = JSON.parse(localStorage.getItem("business_process_data"));

const DashboardMain = () => {
    const overlay_context = useContext(OverlayContext);
    const {t, i18n} = useTranslation();

    const [votesData, setVotesData] = useState(JSON.parse(localStorage.getItem('votes_data')));

    let topProcessesArray = votesData.slice(0,3);
    let botProcessesArray = votesData.slice(3,6);


    const getColorTop = (subprocess) => {
        let x = topProcessesArray.findIndex((el) => el.subprocess === subprocess);
        if (x === 0) return "#048B67";
        else if (x === 1) return "#06D6A0";
        else if (x === 2) return "#A9D7CB";
        else return "grey";
    };
    const getColorBot = (subprocess) => {
        let x = botProcessesArray.findIndex((el) => el.subprocess === subprocess);
        if (x === 0) return "#B3194A";
        else if (x === 1) return "#E2336B";
        else if (x === 2) return "#F7C9D9";
        else return "grey";
    };
    return (
        <Container>
            <BottomWrapper>
                <ProcessOverviewWrapper>
                    <StyledDivRegular
                        Position="relative"
                        Display="flex"
                        MarginTop="24px"
                        MarginLeft="20px"
                        FontWeight="700"
                        Width="450px"
                        Height="20px"
                        JustifyContent="left"
                    >
                        {t('Elephant.ProcessTitle')}
                    </StyledDivRegular>

                    {/*<StyledDivRegular MarginTop='60px' MarginLeft='20px' Width="360px" Height="196px">*/}
                    {/*    <DemoProcessGoodBadLocation Width="615px" Height="207px"/>*/}
                    {/*</StyledDivRegular>*/}

                    <LocationWrapper>
                        {ProcessData.map((process) => {
                            return (
                                <>
                                    <ProcessWrapper>
                                        <ProcessComponent>
                                            {overlay_context.setProcess(process.name, i18n.language)}
                                        </ProcessComponent>
                                        {process.children.map((subprocess) =>
                                            topProcessesArray.findIndex(
                                                (el) => el.subprocess === subprocess.toString()
                                            ) >= 0 &&
                                            botProcessesArray.findIndex(
                                                (el) => el.subprocess === subprocess.toString()
                                            ) >= 0 ? (
                                                <StackedSubProcessComponent>
                                                    <SubProcessComponent
                                                        Width="50%"
                                                        Background={getColorTop(subprocess.toString())}
                                                    >
                                                        {topProcessesArray.findIndex(
                                                            (el) => el.subprocess === subprocess.toString()
                                                        ) + 1}
                                                    </SubProcessComponent>
                                                    <SubProcessComponent
                                                        Width="50%"
                                                        Background={getColorBot(subprocess.toString())}
                                                    >
                                                        {botProcessesArray.findIndex(
                                                            (el) => el.subprocess === subprocess.toString()
                                                        ) + 1}
                                                    </SubProcessComponent>
                                                </StackedSubProcessComponent>
                                            ) : topProcessesArray.findIndex(
                                                (el) => el.subprocess === subprocess.toString()
                                            ) >= 0 ? (
                                                <SubProcessComponent Background={getColorTop(subprocess.toString())}>
                                                    {topProcessesArray.findIndex(
                                                        (el) => el.subprocess === subprocess.toString()
                                                    ) + 1}
                                                </SubProcessComponent>
                                            ) : botProcessesArray.findIndex(
                                                (el) => el.subprocess === subprocess.toString()
                                            ) >= 0 ? (
                                                <SubProcessComponent Background={getColorBot(subprocess.toString())}>
                                                    {botProcessesArray.findIndex(
                                                        (el) => el.subprocess === subprocess.toString()
                                                    ) + 1}
                                                </SubProcessComponent>
                                            ) : (
                                                <SubProcessComponent/>
                                            )
                                        )}
                                    </ProcessWrapper>
                                </>
                            )
                        })}
                    </LocationWrapper>
                </ProcessOverviewWrapper>

                <FeedbackTagsWrapper>
                    <StyledDivRegular
                        MarginTop="24px"
                        MarginLeft="20px"
                        FontWeight="700"
                        Width="fit-content"
                        Height="20px"
                    >
                        {t('Elephant.FeedbackTagsWrapper')}
                    </StyledDivRegular>

                    <StyledDivRegular
                        MarginTop="0px"
                        MarginLeft="20px"
                        Width="360px"
                        Height="196px"
                    >
                        <ComponentTagOverview
                            HighDonutColor="#048B67"
                            MedDonutColor="#06D6A0"
                            LowDonutColor="#A9D7CB"
                        />

                        {/* <DemoFeedbackTags Width="856px" Height="204px"/> */}
                    </StyledDivRegular>

                    <StyledDivRegular
                        MarginTop="0px"
                        MarginLeft="20px"
                        Width="360px"
                        Height="196px"
                    >
                        <ComponentTagOverview
                            HighDonutColor="#B3194A"
                            MedDonutColor="#E2336B"
                            LowDonutColor="#F7C9D9"
                        />
                    </StyledDivRegular>
                </FeedbackTagsWrapper>
            </BottomWrapper>

            <TopWrapper>
                <OptimalOverviewAndTagsWrapper>
                    <StyledDivRegular
                        MarginTop="30px"
                        MarginLeft="20px"
                        Width="543px"
                        Height="20px"
                        FontSize="20px"
                        FontWeight="700"
                        SpanColor="#048B67"
                    >
                        <Trans i18nKey="Elephant.OptimalOverviewAndTagsWrapper">
                            Business Processes <span>Top 3 Best Practices</span>
                        </Trans>
                    </StyledDivRegular>

                    <OptimalContentWrapper>
                        <OldDonutChart
                            MarginTop="105px"
                            MarginLeft="32px"
                            Width="276px"
                            Height="276px"
                            HighDonutColor="#048B67"
                            MedDonutColor="#06D6A0"
                            LowDonutColor="#A9D7CB"
                            OtherDonutColor="#C6CBCB"
                            HighValue={votesData[0].percent}
                            MedValue={votesData[1].percent}
                            LowValue={votesData[2].percent}
                            OtherValue={1 - votesData[0].percent - votesData[1].percent - votesData[2].percent}
                        />

                        <ComponentTopBotProcesses
                            OverviewType="Optimal"
                            topProcessesArray={topProcessesArray}
                            botProcessesArray={botProcessesArray}
                        />
                    </OptimalContentWrapper>
                </OptimalOverviewAndTagsWrapper>

                <ImprovementOverviewAndTagsWrapper>
                    <StyledDivRegular
                        MarginTop="30px"
                        MarginLeft="20px"
                        Width="543px"
                        Height="20px"
                        FontSize="20px"
                        FontWeight="700"
                        SpanColor="#B3194A"
                    >
                        <Trans i18nKey="Elephant.ImprovementOverviewAndTagsWrapper">
                            Business Processes <span>Top 3 Most Improvement Needed</span>
                        </Trans>
                    </StyledDivRegular>
                    <ImprovementContentWrapper>
                        <OldDonutChart
                            MarginTop="105px"
                            MarginLeft="32px"
                            Width="276px"
                            Height="276px"
                            HighDonutColor="#B3194A"
                            MedDonutColor="#E2336B"
                            LowDonutColor="#F7C9D9"
                            OtherDonutColor="#C6CBCB"
                            HighValue={votesData[3].percent}
                            MedValue={votesData[4].percent}
                            LowValue={votesData[5].percent}
                            OtherValue={1 - votesData[3].percent - votesData[4].percent - votesData[5].percent}
                        />

                        <ComponentTopBotProcesses
                            OverviewType="Improvement"
                            topProcessesArray={topProcessesArray}
                            botProcessesArray={botProcessesArray}
                        />
                    </ImprovementContentWrapper>
                </ImprovementOverviewAndTagsWrapper>
            </TopWrapper>
        </Container>
    );
};
export default DashboardMain;

const Container = styled.div`
  width: calc(100% - 104px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  @media only screen and (min-width: 1980px) {
    zoom: 1.35;
  }
`;

const TopWrapper = styled.div`
  width: calc(100% - 104px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  z-index: 3;

  @media only screen and (min-width: 1780px) {
    justify-content: left;
  }
`;

const BottomWrapper = styled.div`
  width: calc(100% - 104px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  z-index: 3;

  @media only screen and (min-width: 1780px) {
    justify-content: left;
  }
`;

const OptimalOverviewAndTagsWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: ${(props) => props.MarginTop || "40px"};
  margin-left: ${(props) => props.MarginLeft || ""};
  max-width: ${(props) => props.Width || "778px"};
  height: ${(props) => props.Height || "452px"};
  background-color: #fcfcfc;
  z-index: 5;
  flex-grow: 1;
`;

const OptimalContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ImprovementContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ImprovementOverviewAndTagsWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: ${(props) => props.MarginTop || "40px"};
  margin-left: ${(props) => props.MarginLeft || "20px"};
  margin-right: 20px;
    //width: ${(props) => props.Width || "778px"};
  max-width: ${(props) => props.Width || "778px"};
  height: ${(props) => props.Height || "452px"};
  background-color: #fcfcfc;
  z-index: 5;
  flex-grow: 1;
`;

const ProcessOverviewWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: ${(props) => props.MarginTop || "40px"};
  margin-left: ${(props) => props.MarginLeft || ""};
  max-width: ${(props) => props.Width || "661px"};
  height: ${(props) => props.Height || "315px"};
  background-color: #fcfcfc;
  z-index: 5;
  flex-grow: 1;
`;

const LocationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 16px;
`;

const ProcessWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProcessComponent = styled.div`
  width: 94px;
  height: 40px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  background-color: #282c34;
  color: #fcfcfc;
  font-family: Overpass;
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const SubProcessComponent = styled.div`
  width: ${(props) => props.Width || "94px"};
  height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: ${(props) => props.Background || "#F1F3F4"};
  color: #fcfcfc;
  font-family: Overpass;
  font-size: 12px;
  margin-bottom: 4px;
`;

const StackedSubProcessComponent = styled.div`
  width: ${(props) => props.Width || "94px"};
  display: flex;
  flex-direction: row;
`;

const FeedbackTagsWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: ${(props) => props.Width || "899px"};
  height: ${(props) => props.Height || "315px"};
  margin-top: ${(props) => props.MarginTop || "40px"};
  margin-left: ${(props) => props.MarginLeft || "20px"};
  background-color: #fcfcfc;
  overflow-x: scroll;
  z-index: 5;
  flex-grow: 1;

  ::-webkit-scrollbar {
    display: flex;
    height: 19px;
    width: 5px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #929699;
    border: 7px solid #fcfcfc;
    cursor: pointer;
  }
`;

const AnalyticsWrapper = styled.div`
  position: fixed;
  display: flex;
  margin-top: ${(props) => props.MarginTop || "68px"};
  margin-left: ${(props) => props.MarginLeft || "339px"};
  width: ${(props) => props.Width || "410px"};
  height: ${(props) => props.Height || "108px"};
  background-color: #f1f3f4;
  z-index: 5;

  button {
    position: fixed;
    margin-top: 208px;
    margin-left: 20px;
    width: ${(props) => props.Width || "210px"};
    height: ${(props) => props.Height || "48px"};
    border: ${(props) => props.Border || "none"};
    border-radius: ${(props) => props.BorderRadius || "5px"};
    background-color: ${(props) => props.BackgroundColor || "#FCFCFC"};
    color: ${(props) => props.Color || "#e2336B"};
    font-size: ${(props) => props.FontSize || "16px"};
    font-weight: ${(props) => props.FontWeight || "400"};
    font-family: ${(props) => props.FontFamily || "Overpass"};
    cursor: ${(props) => props.Cursor || "pointer"};
    transition: all 0.2s ease-in;
    align-items: start;
  }
`;
