import React, { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { Slider } from "@mui/material";
import { ReactComponent as PrevButton } from "../../../assets/initiative_prev_button.svg";
import { ReactComponent as NxtButton } from "../../../assets/initiative_next_button.svg";
import { ReactComponent as NextButtonGray } from "../../../assets/initiative_next_button_gray.svg";
import { ReactComponent as PrevButtonGray } from "../../../assets/initiative_prev_button_gray.svg";
import { StyledDivButton } from "../../../layout-and-styling/standardized-components-library/Styling-TextInput";
import { OverlayContext } from "../../../layout-and-styling/context-hooks/OverlayContext";
import { ComponentTooltipCulture } from "../../../layout-and-styling/standardized-components-library/Component-Tooltips";
import ComponentCultureSlider from "./Component-culture-slilder";

const ComponentCultureQuestions = (props) => {
  const {t, i18n} = useTranslation();
  const context = useContext(OverlayContext);
  const [hover3, setHover3] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem("current_user_data"))
  const userId = userInfo[0]["id"];

  //Make initial state undefinied to check if the user has clicked on any of the sliders
  // const [communication, setCommunication] = useState(0);
  // const [trust, setTrust] = useState(0);
  // const [deciding, setDeciding] = useState(0);
  // const [disagreeing, setDisagreeing] = useState(0);
  // const [scheduling, setScheduling] = useState(0);
  // const [leading, setLeading] = useState(0);
  // const [evaluating, setEvaluating] = useState(0);

  const getUserOwnCulture = () => {
    const userCulture = localStorage.getItem("userOwnCulture") ? JSON.parse(localStorage.getItem("userOwnCulture")) : {};
    
    return {
      source: userCulture.id ?? userId,
      target: userCulture.id ?? userId,
      communication: userCulture.communication ?? 0,
      trust: userCulture.trust ?? 0,
      deciding: userCulture.deciding ?? 0,
      disagreeing: userCulture.disagreeing ?? 0,
      scheduling: userCulture.scheduling ?? 0,
      leading: userCulture.leading ?? 0,
      evaluating: userCulture.evaluating ?? 0
  };
    
}

  
  const [userOwnCulture, setUserOwnCulture] = useState(
    getUserOwnCulture()
  )  

  useEffect(() => {
    let ArrayCultureApi = localStorage.getItem("ArrayCultureApi") ? JSON.parse(localStorage.getItem("ArrayCultureApi")) : [];

    if(ArrayCultureApi.some(user => user.target === userOwnCulture.target)){
      const userId = ArrayCultureApi.findIndex(user => user.target === userOwnCulture.target);
      ArrayCultureApi[userId] = {...userOwnCulture} 
      localStorage.setItem("ArrayCultureApi", JSON.stringify(ArrayCultureApi));
      }
      else{ //
        ArrayCultureApi.push(userOwnCulture);
        localStorage.setItem("ArrayCultureApi", JSON.stringify(ArrayCultureApi));
      }
     

  },[userOwnCulture])

  const updateCultureCommuncation = (commValue) => {
    let communicationChanged = {...userOwnCulture}
    communicationChanged.communication = commValue;
    setUserOwnCulture(communicationChanged);
    localStorage.setItem("userOwnCulture", JSON.stringify(communicationChanged))
  };

  const updateCultureTrust = (trustValue) => {
    let trustChanged = {...userOwnCulture}
    trustChanged.trust = trustValue;
    setUserOwnCulture(trustChanged);
    localStorage.setItem("userOwnCulture", JSON.stringify(trustChanged))
  };

  const updateCultureDeciding = (decidingValue) => {
    let decidingChanged = {...userOwnCulture}
    decidingChanged.deciding = decidingValue;
    setUserOwnCulture(decidingChanged);
    localStorage.setItem("userOwnCulture", JSON.stringify(decidingChanged))
  };

  const updateCultureDisagreeing = (disagreeingValue) => {
    let disagreeingChanged = {...userOwnCulture}
    disagreeingChanged.disagreeing = disagreeingValue;
    setUserOwnCulture(disagreeingChanged);
    localStorage.setItem("userOwnCulture", JSON.stringify(disagreeingChanged))
  };

  const updateCultureScheduling = (schedulingValue) => {
    let schedulingChanged = {...userOwnCulture}
    schedulingChanged.scheduling = schedulingValue;
    setUserOwnCulture(schedulingChanged);
    localStorage.setItem("userOwnCulture", JSON.stringify(schedulingChanged))
  };

  const updateCultureLeading = (leadingValue) => {
    let leadingChanged = {...userOwnCulture}
    leadingChanged.leading = leadingValue;
    setUserOwnCulture(leadingChanged);
    localStorage.setItem("userOwnCulture", JSON.stringify(leadingChanged))
  };

  const updateCultureEvaluating = (evaluatingValue) => {
    let evaluatingChanged = {...userOwnCulture}
    evaluatingChanged.evaluating = evaluatingValue;
    setUserOwnCulture(evaluatingChanged);
    localStorage.setItem("userOwnCulture", JSON.stringify(evaluatingChanged))
  };

  //Communication
  let direct = t('PageCulture.CultureAnswer1')
  let indirect = t('PageCulture.CultureAnswer2')
  let labelDirect = t('PageCulture.SliderDirect')
  let labelIndirect = t('PageCulture.SliderIndirect')

  //Trust
  let knowledge = t('PageCulture.TrustAnswer1')
  let relationship = t('PageCulture.TrustAnswer2')
  let labelKnowledge = t('PageCulture.SliderKnowledge')
  let labelRelationship = t('PageCulture.SliderRelationship')

  //Deciding
  let team = t('PageCulture.DecidingAnswer1')
  let higherUp = t('PageCulture.DecidingAnswer2')
  let labelTeam = t('PageCulture.SliderTeam')
  let labelHigherUp = t('PageCulture.SliderHigherUp')

  //Leading
  let equal = t('PageCulture.LeadingAnswer1')
  let hierarchical = t('PageCulture.LeadingAnswer2')
  let labelEqual = t('PageCulture.SliderEqual')
  let labelHierarchical = t('PageCulture.SliderHierarchical')

  //Disagreeing
  let confrontational = t('PageCulture.DisagreeingAnswer1')
  let avoidsConfront = t('PageCulture.DisagreeingAnswer2')
  let labelConfrontational = t('PageCulture.SliderConfrontational')
  let labelAvoidsConfront = t('PageCulture.SliderAvoidsConfront')

  //Scheduling
  let linear = t('PageCulture.SchedulingAnswer1')
  let flexible = t('PageCulture.SchedulingAnswer2')
  let labelLinear = t('PageCulture.SliderLinear')
  let labelFlexible = t('PageCulture.SliderFlexible')

  //Evaluating
  let directFeedback = t('PageCulture.EvaluatingAnswer1')
  let indirectFeedback = t('PageCulture.EvaluatingAnswer2')
  let labelDirectFeedback = t('PageCulture.SliderDirectFeedback')
  let labelIndirectFeedback = t('PageCulture.SliderIndirectFeedback')


  //Marks 

  const marksCommunication = [
    {
        value: 1,
        label: direct,
        labelDescription: labelDirect,
    },
    {
        value: 2,
        labelDescription: "2",
    },
    {
        value: 3,
        labelDescription: "3",
    },
    {
        value: 4,
        labelDescription: "4",
    },
    {
        value: 5,
        labelDescription: "5",

    },
    {
        value: 6,
        labelDescription: "6",
    },
    {
        value: 7,
        labelDescription: "7",

    },
    {
        value: 8,
        labelDescription: "8",
    },
    {
        value: 9,
        label: indirect,
        labelDescription: labelIndirect,
    } 
];

const marksTrust = [
  {
      value: 1,
      label: knowledge,
      labelDescription: labelKnowledge,
  },
  {
      value: 2,
      labelDescription: "2",
  },
  {
      value: 3,
      labelDescription: "3",
  },
  {
      value: 4,
      labelDescription: "4",
  },
  {
      value: 5,
      labelDescription: "5",

  },
  {
      value: 6,
      labelDescription: "6",
  },
  {
      value: 7,
      labelDescription: "7",

  },
  {
      value: 8,
      labelDescription: "8",
  },
  {
      value: 9,
      label: relationship,
      labelDescription: labelRelationship,
  } 
];

const marksDeciding = [
  {
      value: 1,
      label: team,
      labelDescription: labelTeam,
  },
  {
      value: 2,
      labelDescription: "2",
  },
  {
      value: 3,
      labelDescription: "3",
  },
  {
      value: 4,
      labelDescription: "4",
  },
  {
      value: 5,
      labelDescription: "5",

  },
  {
      value: 6,
      labelDescription: "6",
  },
  {
      value: 7,
      labelDescription: "7",

  },
  {
      value: 8,
      labelDescription: "8",
  },
  {
      value: 9,
      label: higherUp,
      labelDescription: labelHigherUp,
  } 
];


const marksLeading = [
  {
      value: 1,
      label: equal,
      labelDescription: labelEqual,
  },
  {
      value: 2,
      labelDescription: "2",
  },
  {
      value: 3,
      labelDescription: "3",
  },
  {
      value: 4,
      labelDescription: "4",
  },
  {
      value: 5,
      labelDescription: "5",

  },
  {
      value: 6,
      labelDescription: "6",
  },
  {
      value: 7,
      labelDescription: "7",

  },
  {
      value: 8,
      labelDescription: "8",
  },
  {
      value: 9,
      label: hierarchical,
      labelDescription: labelHierarchical,
  } 
];

const marksDisagreeing = [
  {
      value: 1,
      label: confrontational,
      labelDescription: labelConfrontational,
  },
  {
      value: 2,
      labelDescription: "2",
  },
  {
      value: 3,
      labelDescription: "3",
  },
  {
      value: 4,
      labelDescription: "4",
  },
  {
      value: 5,
      labelDescription: "5",

  },
  {
      value: 6,
      labelDescription: "6",
  },
  {
      value: 7,
      labelDescription: "7",

  },
  {
      value: 8,
      labelDescription: "8",
  },
  {
      value: 9,
      label: avoidsConfront,
      labelDescription: labelAvoidsConfront,
  } 
];

const marksScheduling = [
  {
      value: 1,
      label: linear,
      labelDescription: labelLinear,
  },
  {
      value: 2,
      labelDescription: "2",
  },
  {
      value: 3,
      labelDescription: "3",
  },
  {
      value: 4,
      labelDescription: "4",
  },
  {
      value: 5,
      labelDescription: "5",

  },
  {
      value: 6,
      labelDescription: "6",
  },
  {
      value: 7,
      labelDescription: "7",

  },
  {
      value: 8,
      labelDescription: "8",
  },
  {
      value: 9,
      label: flexible,
      labelDescription: labelFlexible,
  } 
];

const marksEvaluating = [
  {
      value: 1,
      label: directFeedback,
      labelDescription: labelDirectFeedback,
  },
  {
      value: 2,
      labelDescription: "2",
  },
  {
      value: 3,
      labelDescription: "3",
  },
  {
      value: 4,
      labelDescription: "4",
  },
  {
      value: 5,
      labelDescription: "5",

  },
  {
      value: 6,
      labelDescription: "6",
  },
  {
      value: 7,
      labelDescription: "7",

  },
  {
      value: 8,
      labelDescription: "8",
  },
  {
      value: 9,
      label: indirectFeedback,
      labelDescription: labelIndirectFeedback,
  } 
];

  let cultureObj = [
    {
      scale : "communication",
      question: t("PageCulture.QuestionCulture"),
      marks: marksCommunication,
      setValForScale : updateCultureCommuncation
    },
    {
      scale : "disagreeing",
      question: t("PageCulture.QuestionDisagreeing"),
      marks: marksDisagreeing,
      setValForScale: updateCultureDisagreeing
    },
    {
      scale : "deciding",
      question: t("PageCulture.QuestionDeciding"),
      marks: marksDeciding,
      setValForScale: updateCultureDeciding
    },
    {
      scale : "leading",
      question: t("PageCulture.QuestionLeading"),
      marks: marksLeading,
      setValForScale: updateCultureLeading
    },
    {
      scale : "trust",
      question: t("PageCulture.QuestionTrust"),
      marks: marksTrust,
      setValForScale: updateCultureTrust
    },
    {
      scale : "scheduling",
      question: t("PageCulture.QuestionScheduling"),
      marks: marksScheduling,
      setValForScale: updateCultureScheduling
    },
    {
      scale : "evaluating",
      question: t("PageCulture.QuestionEvaluating"),
      marks: marksEvaluating,
      setValForScale: updateCultureEvaluating
    }
  ]

  const userCompletedTrue = () => {
    if(userOwnCulture?.["communication"] &&
    userOwnCulture?.["trust"] &&
    userOwnCulture?.["scheduling"] &&
    userOwnCulture?.["deciding"] &&
    userOwnCulture?.["leading"] &&
    userOwnCulture?.["evaluating"] &&
    userOwnCulture?.["disagreeing"]){
      return true;
    }
    else {
      return false;
    }
  }

  props.setInputChecker(userCompletedTrue())

  let eachQuestion = cultureObj.map(slider => {
    return <ComponentCultureSlider 
    question = {slider.question}
    scale = {slider.scale}
    answer1={slider.answer1}
    answer2={slider.answer2}
    setValue={slider.setValForScale}
    value={userOwnCulture[slider.scale] ?? 1}
    marks={slider.marks}
    >
    </ComponentCultureSlider>
  })

  return (
    <WrapperQuestion outline={!props.inputCheckPeople & props.nextButtonClicked ? "2px solid #F72B2B" : ""}>
      <IncompleteContentText display={!props.inputCheckPeople & props.nextButtonClicked ? "flex" : "none"}>
        {t('PageCulture.SliderError')}
      </IncompleteContentText>
    <Container>
      <TopContainer>
        <InfoHover onMouseOver={() => setHover3(true)}
                    onMouseOut={() => setHover3(false)}>?
            {hover3 && (<ComponentTooltipCulture whichTooltip={"PageCulture"}/>
            )}
          </InfoHover>
     {/* <b> <Trans i18nKey="PageCulture.Title2">
                     
                </Trans></b> */}
      
     {/* <UserNameAndPicture>
                <NamePicture>
                    <PersonPic>
                        {userInfo[0].first_name.charAt(0) +
                            userInfo[0].last_name.charAt(0)}
                    </PersonPic>
                    {context.setFirstName(userInfo[0].first_name)}
                </NamePicture>
            </UserNameAndPicture>  */}
      </TopContainer>
      
      <QuestionsContainer>
          {eachQuestion}
      </QuestionsContainer>
   
    </Container>
    </WrapperQuestion>
  );
};

export default ComponentCultureQuestions;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 1000px;
  max-width: 1600px;
  font-family: "Overpass", sans-serif;
  border-radius: 10px;
  background-color: #fcfcfc;
  padding:  25px 15px 12px 22px;
  padding-left: 25px;

`;

const WrapperQuestion = styled.div`
  outline: ${props => props.outline || ''};
  outline-offset: -2px;
  max-height: 1000px;
  max-width: 1600px;
  font-family: "Montserrat", sans-serif;
  border-radius: 10px;
  background-color: #fcfcfc;
  padding: 15px 30px 40px 35px;
  margin-top: -1.2em;
`

const IncompleteContentText = styled.div`
  position: absolute;
  display: ${(props) => props.display || ""};
  justify-content: center;
  margin-left: 632px;
  border-radius: ${(props) => props.BorderRadius || "5px"};
  color: #F72B2B;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;

const TopContainer = styled.div`
width: 100%;
margin-bottom: 1.5em;
font-size: 18px;
margin-bottom: -0.5em;
`

const UserNameAndPicture = styled.div`
  margin-top: 20px;
`;

const NamePicture = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  width: fit-content;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const PersonPic = styled.div`
  margin-left: 20px;
  margin-right: 10px;
  align-items: center;
  width: 42px;
  height: 42px;
  font-size: 20px;
  border-radius: 50%;
  background: #d0e1f1;
  color: #271b36;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const QuestionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 0.9em;
  column-gap: 5em;
  text-align: center;
  margin-right: 1.5em;
`

const InfoHover = styled.div`
  float: right;
  font-size: 18px;
  font-family: 'Overpass', sans-serif;
  font-weight: 500;
  position: relative;
  color: #FCFCFC;
  margin-top: 16px;
  margin-right: -18px;
  border: 2px solid #E2336B;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  background-color: #E2336B;
  cursor: pointer;
  margin-top: -1.1em;
`;