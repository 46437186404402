import React, {useContext, useState} from "react";
import styled from "styled-components";

import CloseButton from "../../assets/dashboard/CloseButtonOverlay.png";
import {ReactComponent as CommentsIcon} from "../../assets/dashboard/CommentsIcon.svg";
import {ReactComponent as CommentsIconClicked} from "../../assets/dashboard/CommentsIconClicked.svg";
import { DashboardContext } from "../DashboardContext";
import { OverlayContext } from "../../layout-and-styling/context-hooks/OverlayContext";
import {useTranslation} from 'react-i18next';

import {ReactComponent as ImprovBackround} from "../../assets/dashboard/ImprovementsCommentsBackground.svg";
import {ReactComponent as OptimalBackround} from "../../assets/dashboard/OptimalCommentsBackground.svg";


const DashboardCommentsOverlay = (props) => {
    const dashboard_context_value = useContext(DashboardContext);
    let data = dashboard_context_value.tagSelected;
    const overlay_context = useContext(OverlayContext);
    const [translationMode, setTranslationMode] = useState(false)
    const {t, i18n} = useTranslation();
    let badFeedbackEmpty = false;
    let goodFeedbackEmpty = false;

    return (
      <>
        <BackgroundWrapper Display={props.Display}>
          {data.length && data[0].type === "improvement" ? <ImprovBackround/> : <OptimalBackround/>}
        </BackgroundWrapper>
        <Container Display={props.Display}>
            <Heading>

                {data.length && data[0].type === "optimal" &&
                    <ProcessNumber
                        Background={data[0].index - 1 === 0 ? '#048B67' : data[0].index - 1 === 1 ? '#06D6A0' : '#A9D7CB'}>
                        {data.length ? data[0].index : ""}
                    </ProcessNumber>
                }

                {data.length && data[0].type === "improvement" &&
                    <ProcessNumber
                        Background={data[0].index - 1 === 0 ? '#B3194A' : data[0].index - 1 === 1 ? '#E2236B' : '#F7C9D9'}>
                        {data.length ? data[0].index : ""}
                    </ProcessNumber>
                }

                <ProcessName>
                    {data.length ? overlay_context.setProcess(data[0].subprocess, i18n.language) : ""}
                </ProcessName>

                <TagName>
                    {data.length ? data[0].tag : ""}
                </TagName>

            </Heading>
            <CloseButtonWrapper
                onClick={() => {
                    props.setDisplayModal(false);
                    dashboard_context_value.setOpenAnalysis(false);
                    dashboard_context_value.setDataAnalysis(null);
                }}
            >
                <img width="20px" src={CloseButton}/>
            </CloseButtonWrapper>


            <Content>
                <LeftContainer>
                    <HeadingLeft>
                      
                      { data.length && data[0].type === "improvement" ? 
                      <Title
                      // Border={data.length && data[0].type === "optimal" ? "1px solid #048B67" : "1px solid #B3194A"}
                      Border={"1px solid #271B36"}
                      // Color={data.length && data[0].type === "optimal" ? "#048B67" : "#B3194A"}
                      Color={"#271B36"}>
                      {t('Elephant.CommentsFeedbackImprovement')}</Title>
                      :
                      <Title
                      // Border={data.length && data[0].type === "optimal" ? "1px solid #048B67" : "1px solid #B3194A"}
                      Border={"1px solid #271B36"}
                      // Color={data.length && data[0].type === "optimal" ? "#048B67" : "#B3194A"}
                      Color={"#271B36"}>
                      {t('Elephant.CommentsFeedbackOptimal')}</Title>
                      }

                      <Comments>
                        {data.length && data[0].type === "improvement" ? data[0].feedbackBad.length : ''}
                        {data.length && data[0].type === "optimal" ? data[0].feedbackGood.length : ''}
                        <IconWrapper>
                            <CommentsIcon/>
                        </IconWrapper>

                        <IconWrapperClicked>
                            <CommentsIconClicked/>
                        </IconWrapperClicked>
                      </Comments>
                    </HeadingLeft>

                    { data.length && data[0].type === "improvement" &&
                    <LeftFeedback>
                        {data.length && data[0].feedbackBad.map((feedback, index) => {
                            return (
                                <FeedbackComponent>
                                    <span>{t('Elephant.Feedback')} {index + 1} : </span>
                                    <br/>
                                    {/* {translationMode ? feedback.tr_justification : feedback.justification} */}
                                    {feedback}
                                </FeedbackComponent>
                            )
                        })}
                    </LeftFeedback>
                    }

                    {data.length && data[0].type === "optimal" &&
                    <LeftFeedback>
                    {data.length && data[0].feedbackGood.map((feedback, index) => {
                        return (
                            <FeedbackComponent>
                                <span>{t('Elephant.Proposals')} {index + 1} : </span>
                                <br/>
                                {/* {translationMode ? feedback.tr_justification : feedback.justification} */}
                                {feedback}
                            </FeedbackComponent>
                        )
                    })}
                    </LeftFeedback>
                    }

                </LeftContainer>

                

                <RightContainer>
                    <HeadingRight>
                      { data.length && data[0].type === "improvement" ? 
                      <Title
                      // Border={data.length && data[0].type === "optimal" ? "1px solid #048B67" : "1px solid #B3194A"}
                      Border={"1px solid #271B36"}
                      // Color={data.length && data[0].type === "optimal" ? "#048B67" : "#B3194A"}
                      Color={"#271B36"}>
                      {t('Elephant.CommentsFeedbackOptimal')}</Title>
                      :
                      <Title
                      // Border={data.length && data[0].type === "optimal" ? "1px solid #048B67" : "1px solid #B3194A"}
                      Border={"1px solid #271B36"}
                      // Color={data.length && data[0].type === "optimal" ? "#048B67" : "#B3194A"}
                      Color={"#271B36"}>
                      {t('Elephant.CommentsFeedbackImprovement')}</Title>
                      }
                        <Comments>
                            {data.length && data[0].type === "improvement" ? data[0].feedbackGood.length : ''}
                            {data.length && data[0].type === "optimal" ? data[0].feedbackBad.length : ''}
                            <IconWrapper>
                                <CommentsIcon/>
                            </IconWrapper>

                            <IconWrapperClicked>
                                <CommentsIconClicked/>
                            </IconWrapperClicked>
                        </Comments>
                    </HeadingRight>

                    { data.length && data[0].type === "improvement" ?
                    <RightFeedback>
                        {data.length && data[0].feedbackGood.map((feedback, index) => {
                            
                                return (
                                    <FeedbackComponent>
                                        <span>{t('Elephant.Proposals')} {index + 1} : </span>
                                        <br/>
                                        {/* {translationMode ? feedback.tr_proposal : feedback.proposal} */}
                                        {feedback}
                                    </FeedbackComponent>
                                )

                        })}
                    </RightFeedback>
                    :
                    <RightFeedback>
                        {data.length && data[0].feedbackBad.map((feedback, index) => {
                            
                                return (
                                    <FeedbackComponent>
                                        <span>{t('Elephant.Feedback')} {index + 1} : </span>
                                        <br/>
                                        {/* {translationMode ? feedback.tr_proposal : feedback.proposal} */}
                                        {feedback}
                                    </FeedbackComponent>
                                )

                        })}
                    </RightFeedback>
                    }

                </RightContainer>
                
            </Content>
        </Container>
        </>
    )
}

export default DashboardCommentsOverlay;


const Container = styled.div`
  position: absolute;
  display: ${props => props.Display || "block"};
  width: 1276px;
  height: 850px;
  right: 64px;
  top: 0px;
  z-index: 6;
  background: transparent;
  animation: showNav 250ms ease-in-out both;

  @keyframes showNav {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  display: ${props => props.Display || "block"};
  width: 1276px;
  height: 850px;
  right: 64px;
  top: 0px;
  z-index: 5;
  background: #fcfcfc;
  animation: showNav 250ms ease-in-out both;

  @keyframes showNav {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Heading = styled.div`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #0a070e;
  margin-top: 45px;
  margin-left: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  z-index: 8;
`;

const CloseButtonWrapper = styled.div`
  width: 24px;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 20px;
  margin-right: 40px;
  cursor: pointer;
  z-index: 8;
`;

const ProcessNumber = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  padding: 3px;
  line-height: 20px;
  color: #FCFCFC;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  background: ${props => props.Background || 'none'};
  z-index: 8;
`;

const ProcessName = styled.div`
  font-size: 32px;
  z-index: 8;
`;

const TagName = styled.div`
  background: #FFFFFF;
  border: 1px solid #0A070E;
  border-radius: 20px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  align-items: flex-start;
  padding: 4px 34px;
  gap: 8px;
  z-index: 8;
`;

const Content = styled.div`
  flex-direction: row;
  display: flex;
  gap: 20px;
  margin-top: 24px;
  z-index: 8;
`;

const LeftContainer = styled.div`
  flex-direction: column;
  display: flex;
  width: 588px;
  background: #FCFCFC;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 684px;
  margin-left: 40px;
  z-index: 8;
`;

const RightContainer = styled.div`
  flex-direction: column;
  display: flex;
  width: 588px;
  background: #FCFCFC;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 684px;
  z-index: 8;
`;

const LeftFeedback = styled.div`
  flex-direction: column;
  display: flex;
  background: #E3E5E4;
  border-radius: 5px;
  width: 548px;
  height: 564px;
  margin-top: 8px;
  margin-left: 20px;
  overflow-y: scroll;
  z-index: 8;
  gap: 16px;
  padding: 16px 0 16px 0;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #929699;
  }

`;

const RightFeedback = styled.div`
  flex-direction: column;
  display: flex;
  background: #E3E5E4;
  border-radius: 5px;
  width: 548px;
  height: 564px;
  margin-top: 8px;
  margin-left: 20px;
  overflow-y: scroll;
  z-index: 8;
  gap: 16px;
  padding: 16px 0 16px 0;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #929699;
  }
`;

const HeadingLeft = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 8;
`;

const HeadingRight = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 8;
`;

const Title = styled.div`
  background: #FCFCFC;
  border: ${props => props.Border || '1px solid #26547C'};
  color: ${props => props.Color || '#26547C'};
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  gap: 8px;
  margin-left: 20px;
  margin-top: 16px;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  z-index: 8;
`;

const Comments = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  color: #271B36;
  flex-direction: row;
  cursor: pointer;
  margin-right: 20px;
  margin-top: 16px;
  align-items: center;
  z-index: 8;
`;

const IconWrapper = styled.div`
  display: flex;
  margin-left: 2px;
  z-index: 8;
`;

const IconWrapperClicked = styled.div`
  display: none;
  margin-left: 2px;
  z-index: 8;
`;

const FeedbackComponent = styled.div`
  background: #FCFCFC;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  padding: 12px 20px;
  margin-left: 20px;
  margin-right: 20px;
  z-index: 8;

  span {
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    color: #3E4245;
  }
`;

