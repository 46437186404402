import {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import {StyledDivRegular} from "../../../layout-and-styling/standardized-components-library/Styling-TextInput";
import {
    ReactComponent as Uncheckmark
} from "../../../assets/SurveyCommunication/Icon_Survey_CheckMark_ClickCounter_Unchecked.svg";
import {
    ReactComponent as Checkmark
} from "../../../assets/SurveyCommunication/Icon_Survey_CheckMark_ClickCounter_Green.svg";
import {SubProcessBlock,} from "../../../layout-and-styling/standardized-components-library/Styling-ProcessBlocks";
import {OverlayContext} from "../../../layout-and-styling/context-hooks/OverlayContext";
import i18next from "i18next";
import {Trans, useTranslation} from "react-i18next";

// difference
import axios from "axios"; 
import { ComponentTooltipProcessGoodFeedback } from "../../../layout-and-styling/standardized-components-library/Component-Tooltips";

const ComponentPrePageProcessGoodFeedback = (props) => {
    /** Survey page for giving feedback on subprocess steps that are the best practices */

    const {t, i18n} = useTranslation();
    const CurrentUserData = JSON.parse(localStorage.getItem("current_user_data"));

    const context = useContext(OverlayContext);

    let [array_length_limiter, setArrayLengthLimiter] = useState(localStorage.getItem("optimalFeedbackSelectedProcesses") ? JSON.parse(localStorage.getItem("optimalFeedbackSelectedProcesses")) : []);
    let [countSubprocess, setCountSubprocess] = useState(array_length_limiter.length);
    const ProcessData = JSON.parse(localStorage.getItem("business_process_data"));
    const SubprocessData = JSON.parse(localStorage.getItem("business_subprocess_data"));

    const SubprocessList = [];
    for (let i = 0; i < ProcessData.length; i++) {
        for (let j = 0; j < ProcessData[i]["children"].length; j++) {
            SubprocessList.push({subprocess_name: ProcessData[i]["children"][j]});
        }
    }

    const [ArrayFeedback, updateArrayFeedback] = useState(SubprocessList);

    const SubprocessCounter = [];
    for (let i = 0; i < ProcessData.length; i++) {
        if (i === 0) {
            SubprocessCounter.push(0);
        } else {
            SubprocessCounter.push(
                ProcessData[i - 1]["children"].length + SubprocessCounter[i - 1]
            );
        }
    }

    function activateSelected() {
        for (let i = 0; i < ArrayFeedback.length; i++) {

            if (array_length_limiter.includes(ArrayFeedback[i].id - 1)) {
                ArrayFeedback[i].selected = true;
            }
        }
    }

    useEffect(() => {
        activateSelected()
    }, [])

    for (let i = 0; i < SubprocessList.length; i++) {
        /** Populate InitArray to the current api/feedbacks/ format */
        SubprocessList[i].id = 1 + i;
        SubprocessList[i].selected = false;
        SubprocessList[i].activated = false;
        SubprocessList[i].expanded = true;
        SubprocessList[i].FeedbackData = [];

        /** Append feedback data including, subprocess id which is now separated in API Viewsets */
        for (let j = 0; j < SubprocessData.length; j++) {
            if (SubprocessData[j]["name"] === SubprocessList[i]["subprocess_name"]) {
                SubprocessList[i]["FeedbackData"].push({
                    feedback_type: "HL",
                    given_by: CurrentUserData[0]["user"],
                    related_to: SubprocessData[j]["id"],
                    justification: null,
                    proposal: null,
                });
            }
        }
    }

    useEffect(() => {
        setCountSubprocess(array_length_limiter.length);

        if (array_length_limiter.length >= 3) {
            props.set_input_check(true);
        }


        localStorage.setItem("optimalFeedbackSelectedProcesses", JSON.stringify(array_length_limiter));
    }, [array_length_limiter])

    const changeSelected = (current_subprocess_id) => {
        var arrayCopy = array_length_limiter
        /** if statement, to check whether the clicked item was already selected. If so just remove it from array_length_limiter */
        if (array_length_limiter.includes(current_subprocess_id)) {
            props.set_input_check(false);
            ArrayFeedback[current_subprocess_id]["selected"] = false;
            /** Remove current selected entr, via indexOf and splice() */
            const index = array_length_limiter.indexOf(current_subprocess_id);
            arrayCopy.splice(index, 1);
            setArrayLengthLimiter(arrayCopy)
            setCountSubprocess(array_length_limiter.length);
        } else {
            /** If not yet selected, and if 3 already selected. Then remove the oldest entry and .push() the newest selection */
            if (array_length_limiter.length < 3) {
                // /** Push latest entry */
                // const newArray = [...array_length_limiter, current_subprocess_id]
                // setArrayLengthLimiter(newArray);
                // ArrayFeedback[current_subprocess_id]["selected"] = true;
                // ArrayFeedback[array_length_limiter[0]]["selected"] = false;

                // /** Remove first entry, via shift()*/
                // // array_length_limiter.shift();
                // newArray.shift()
                // setArrayLengthLimiter(newArray);
                // setCountSubprocess(array_length_limiter.length);
                /** If not yet selected, and if less than 3 already selected. Then .push() the newest selection*/
                ArrayFeedback[current_subprocess_id]["selected"] = true;
                const newArray = [...array_length_limiter, current_subprocess_id]
                setArrayLengthLimiter(newArray);
            } 
        }
        /** counter for displaying how many subprocesses were selected */
        localStorage.setItem("optimalFeedbackSelectedProcesses", JSON.stringify(array_length_limiter));
        /** Trigger a re-render via a stupid setState */
        reRender();
    };

    /** Check if already authenticated, and bring to dashboard if possible.*/
    useEffect(() => {
        const axiosInstance = axios.create({withCredentials: true});
        // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
        axiosInstance
            .get(process.env.REACT_APP_APIURL + "/api/auth/validatesession/")
            .catch((error) => {
                window.location.replace(process.env.REACT_APP_APPURL + "/login");
            });
    }, []);

    /** States to check if page is properly filled in, before going to next page */
    const [render, setRender] = useState(false);
    const reRender = () => {
        setRender(!render);
    };

    const [hover1, setHover1] = useState(false);

    return (
        <Container outline={(!props.input_check) & props.nextButtonClicked ? "2px solid #F72B2B" : ""}>
            <IncompleteContentText display={!props.input_check & props.nextButtonClicked ? "flex" : "none"}>
              {t('PrePageGoodFeedback.ErrorText')}
            </IncompleteContentText>
            <FeedbackHeaderWrapper>
                <BodyHeadingWrapper>
                    <Trans i18nKey="PageGoodFeedback.BodyHeadingWrapper">
                        Which 3 work activities have the <span>best practices</span>?
                    </Trans>
                </BodyHeadingWrapper>

                <CounterOutput
                    Color={array_length_limiter.length > 2 ? "#06D6A0" : "#929699"}>
                    {countSubprocess}/3
                    <StyledDivRegular MarginTop="0px" MarginLeft="64px" Width="24px" Height="24px">
                        {array_length_limiter.length > 2 ? <Checkmark/> : <Uncheckmark/>}
                    </StyledDivRegular>
                </CounterOutput>

                <InfoHover
                    onMouseOver={() => setHover1(true)}
                    onMouseOut={() => setHover1(false)}
                >
                    ?
                    {hover1 && (
                        <ComponentTooltipProcessGoodFeedback
                            whichTooltip={"Subprocess"}
                        />
                    )}
                </InfoHover>
            </FeedbackHeaderWrapper>

            <ComponentWrapper>

                <ProcessComponentWrapper>
                    {ProcessData.map((current_process_object, current_process_id, all_process_objects) => {
                        return (
                            <ProcessComponent>
                                {current_process_id === 0 &&
                                    <ArrowProcess
                                        RightBorderTop={"40px solid transparent"}
                                        RightBorderBottom={"40px solid transparent"}
                                        RightBorderLeft={"40px solid #271B36"}
                                        RightBorderRight={"none"}>
                                        <ArrowTitle>
                                            {context.setProcess(current_process_object.name, i18n.language)}
                                        </ArrowTitle>
                                    </ArrowProcess>
                                }
                                {current_process_id === ProcessData.length - 1 &&
                                    <ArrowProcess3
                                        LeftBorderTop={"40px solid transparent"}
                                        LeftBorderBottom={"40px solid transparent"}
                                        LeftBorderLeft={"40px solid #FCFCFC"}
                                        LeftBorderRight={"none"}>
                                        <ArrowTitle>
                                            {context.setProcess(current_process_object.name, i18n.language)}
                                        </ArrowTitle>
                                    </ArrowProcess3>
                                }

                                {current_process_id !== 0 && current_process_id !== ProcessData.length - 1 &&
                                    <ArrowProcess2
                                        LeftBorderTop={"40px solid transparent"}
                                        LeftBorderBottom={"40px solid transparent"}
                                        LeftBorderLeft={"40px solid #FCFCFC"}
                                        LeftBorderRight={"none"}
                                        RightBorderTop={"40px solid transparent"}
                                        RightBorderBottom={"40px solid transparent"}
                                        RightBorderLeft={"40px solid #271B36"}
                                        RightBorderRight={"none"}
                                    >
                                        <ArrowTitle>
                                            {context.setProcess(current_process_object.name, i18n.language)}
                                        </ArrowTitle>
                                    </ArrowProcess2>
                                }


                                {current_process_object.children.map((subprocess, subprocess_index) => {
                                        const subprocess_indexer = SubprocessCounter[current_process_id] + subprocess_index;
                                        return (
                                            <SubProcessColumnWrapper>
                                                <SubProcessBlock onClick={() => changeSelected(subprocess_indexer)}
                                                                 Cursor={array_length_limiter.length === 3 && !ArrayFeedback[subprocess_indexer].selected ? "default" : array_length_limiter.length === 3 && ArrayFeedback[subprocess_indexer].selected ? "pointer" : "pointer"}
                                                                 Width="160px" Height="80px"
                                                                 FontWeight={ArrayFeedback[subprocess_indexer].selected === true ? "700" : "400"}
                                                                 Background={ArrayFeedback[subprocess_indexer].selected === true ? "#06D6A0" : "#E3E5E4"}
                                                                 Color={ArrayFeedback[subprocess_indexer].selected === true ? "#FCFCFC" : "#0A070E"}
                                                                 Opacity={array_length_limiter.length === 3 && !ArrayFeedback[subprocess_indexer].selected ? "0.3" : "1"}
                                                                 BackgroundHover={array_length_limiter.length === 3 && !ArrayFeedback[subprocess_indexer].selected ? "#E3E5E4" :  ArrayFeedback[subprocess_indexer].selected ? "#00C592" : "#C6CBCB"}

                                                >
                                                    <p>{context.setBusinessProcess(subprocess, i18next.language)}</p>

                                                    {/*{subprocess}*/}
                                                </SubProcessBlock>
                                            </SubProcessColumnWrapper>
                                        );
                                    }
                                )}
                            </ProcessComponent>
                        )
                    })}
                </ProcessComponentWrapper>
            </ComponentWrapper>
        </Container>
    );
};
export default ComponentPrePageProcessGoodFeedback;

const Container = styled.div`
  background: #fcfcfc;
  width: 1200px;
  height: 700px;
  border-radius: 10px;
  padding: 12px 12px 12px 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  @media (min-height: 950px) {
    margin-top: 80px;
  }
  outline: ${props => props.outline || ''};
  outline-offset: -2px;
`;

const IncompleteContentText = styled.div`
  position: absolute;
  display: ${(props) => props.display || ""};
  border-radius: ${(props) => props.BorderRadius || "5px"};
  color: #F72B2B;
  margin-top: -4px;
  margin-left: 464px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;

const FeedbackHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  width: 100%;
`;

const BodyHeadingWrapper = styled.div`
  display: flex;
  position: relative;
  font-family: "Montserrat";
  font-style: normal;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  width: 100%;
  margin-top: 12px;

  span {
    color: #06D6A0;
    margin-left: 5px;
  }
`;

const InfoHover = styled.div`
  position: absolute;
  font-size: 18px;
  font-family: "Overpass";
  font-weight: 300;
  //position: absolute;
  color: #FCFCFC;
  background-color: #E2336B;
  border: 2px solid #e2336b;
  right: 0;
  margin: 8px 24px 8px 8px;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  margin-left: 12px;
  align-items: center;
  flex: 10;
  height: 100%;
  width: 100%;
`;

const CounterOutput = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  margin: 20px 92px 0 0;
  float: right;
  width: 72px;
  height: 24px;
  font-family: "Overpass";
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.Color || "#929699"};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


const SubProcessColumnWrapper = styled.div`
  p {
    width: 125px;
  }
`;

const ProcessComponentWrapper = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: row;
`;

const ProcessComponent = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const ArrowProcess = styled.div`
  width: 165px;
  height: 80px;
  background: ${props => props.Background || "#271B36"};
  border-radius: 2px;
  color: #FCFCFC;
  font-family: 'Overpass';
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  &:after {
    content: " ";
    z-index: 1;
    position: absolute;
    margin-left: 205px;
    border-right: ${props => props.RightBorderRight || "28px solid #271B36"};;
    border-top: ${props => props.RightBorderTop || "28px solid transparent"};
    border-bottom: ${props => props.RightBorderBottom || "28px solid transparent;"};
    border-left: ${props => props.RightBorderLeft || "28px solid #271B36"};
  }
`;

const ArrowProcess2 = styled.div`
  width: 165px;
  height: 80px;

  background: ${
          props => props.Background || "#271B36"
  };
  border-radius: 2px;
  color: #FCFCFC;
  font-family: 'Overpass';
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  &:before {
    content: " ";
    position: absolute;
    margin-right: 125px;
    border-top: ${props => props.LeftBorderTop || "28px solid #271B36"};
    border-right: ${props => props.LeftBorderRight || "28px solid #271B36"};
    border-bottom: ${props => props.LeftBorderBottom || "28px solid #271B36"};
    border-left: ${props => props.LeftBorderLeft || "28px solid transparent"};
  }

  &:after {
    content: " ";
    z-index: 1;
    position: absolute;
    margin-left: 205px;
    border-right: ${props => props.RightBorderRight || "28px solid #271B36"};;
    border-top: ${props => props.RightBorderTop || "28px solid transparent"};
    border-bottom: ${props => props.RightBorderBottom || "28px solid transparent;"};
    border-left: ${props => props.RightBorderLeft || "28px solid #271B36"};
  }
`;

const ArrowProcess3 = styled.div`
  width: 165px;
  height: 80px;
  background: ${
          props => props.Background || "#271B36"
  };
  border-radius: 2px;
  color: #FCFCFC;
  font-family: 'Overpass';
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  &:before {
    content: " ";
    position: absolute;
    margin-right: 125px;
    border-top: ${props => props.LeftBorderTop || "28px solid #271B36"};
    border-right: ${props => props.LeftBorderRight || "28px solid #271B36"};
    border-bottom: ${props => props.LeftBorderBottom || "28px solid #271B36"};
    border-left: ${props => props.LeftBorderLeft || "28px solid transparent"};
  }
`;

const ArrowTitle = styled.div`
  margin-left: 40px;
  text-align: center;
  width: 88px;
`;