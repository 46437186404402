import {useContext, useEffect, useRef, useState, useLayoutEffect} from "react";
import SocialNetwork from './SocialNetwork'; 
import styled,{css} from "styled-components";
import { DashboardContext } from "../../DashboardContext";
import { OverlayContext } from "../../../layout-and-styling/context-hooks/OverlayContext";
import RandomNames from "../../../assets/random_names/names.js";
import { comparisonVis } from "../../dashboard-pages/Comparison";
import { vis } from "../Dashboard-social-network";

/** Variable to hold the component, initialized at null and assigned later when the component is created */

var container_position = "absolute";

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

export default function SocialNetworkRenderComponent(props) {
    const {height, width} = useWindowDimensions();
    const language = props.language;
    /** React js hook usestate for tracking data changes */
    if(typeof props.Comparison === 'undefined'|| !props.Comparison){
        container_position = "fixed"
    }
    else{
        container_position = "absolute"
    }
    /** The useRef Hook creates a variable that "holds on" to a value across rendering passes.
     * In this case it will hold our component's SVG DOM element.
     * It's initialized null and React will assign it later (see the return statement) */
    const Ref = useRef();
    const graphRef = useRef();

    // Function to center containig box of the social network in the center
    function centerVis(containingBoxX, containingBoxY, medianX, medianY) {
        Ref.current.scrollLeft = ( containingBoxX - Ref.current.offsetWidth ) / 2 + (( containingBoxX - Ref.current.offsetWidth ) / containingBoxX) * medianX;
        Ref.current.scrollTop = ( containingBoxY - Ref.current.offsetHeight ) / 2 + (( containingBoxY - Ref.current.offsetHeight ) / containingBoxY) * medianY;
    }

    /** It adds content to the DOM outside of React’s virtual DOM mechanism */
    useEffect(() => {
        let demo_mode = JSON.parse(localStorage.getItem('demo_mode'));
        let data = props.graphData
        let selected_survey = data.survey
        let zoom = props.Zoom
        let comparison = props.Comparison
        let iteration = props.Iteration
        
        if(comparison) {
            if (data) {
                const d3Props = {
                    data,
                    width,
                    height,
                    zoom,
                    context,
                    demo_mode,
                    RandomNames,
                    language,
                    selected_survey,
                };
                if(iteration === "before") comparisonVis[0] = new SocialNetwork(graphRef.current, d3Props)
                else if(iteration === "after") comparisonVis[1] = new SocialNetwork(graphRef.current, d3Props)
                props.setZoom(width / comparisonVis[comparisonVis.length-1].containingBoxX)
                props.setGraphWidth(comparisonVis[comparisonVis.length-1].containingBoxX)
                props.setGraphHeight(comparisonVis[comparisonVis.length-1].containingBoxY)
                centerVis(comparisonVis[comparisonVis.length-1].containingBoxX, comparisonVis[comparisonVis.length-1].containingBoxY, comparisonVis[comparisonVis.length-1].medianX, comparisonVis[comparisonVis.length-1].medianY)
            }
        } else {
            if (data) {
                const d3Props = {
                    data,
                    width,
                    height,
                    zoom,
                    context,
                    demo_mode,
                    RandomNames,
                    language,
                    selected_survey,
                };
                vis[0] = new SocialNetwork(graphRef.current, d3Props)
                props.setZoom(width / vis[0].containingBoxX)
                props.setGraphWidth(vis[0].containingBoxX)
                props.setGraphHeight(vis[0].containingBoxY)
                centerVis(vis[0].containingBoxX, vis[0].containingBoxY, vis[0].medianX, vis[0].medianY)
                console.log(vis[0].containingBoxX, width / vis[0].containingBoxX)
            }
        }

        
    }, [props.graphData]);
    /** Adding a listener that runs when graph data is sotred in the local storage */
    let context = useContext(OverlayContext);
    useEffect(() => {
        if (!Ref.current) return;
        let isDown = false;
        let startX;
        let startY;
        let scrollLeft;
        let scrollTop;

        Ref.current.addEventListener("mousedown", (e) => {
            isDown = true;
            startX = e.pageX - Ref.current.offsetLeft;
            startY = e.pageY - Ref.current.offsetTop;
            scrollLeft = Ref.current.scrollLeft;
            scrollTop = Ref.current.scrollTop;
        });
        Ref.current.addEventListener("mouseleave", () => {
            isDown = false;
        });
        Ref.current.addEventListener("mouseup", () => {
            isDown = false;
        });
        Ref.current.addEventListener("mousemove", (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - Ref.current.offsetLeft;
            const y = e.pageY - Ref.current.offsetTop;
            const walk = x - startX; // scroll-fast
            const walk2 = y - startY; // scroll-fast
            Ref.current.scrollLeft = scrollLeft - walk;
            Ref.current.scrollTop = scrollTop - walk2;
        });
    }, [Ref]);

    const onScroll = (e) => {
        const delta = e.deltaY * -0.001;
        const newZoom = props.Zoom + delta;
        if(newZoom > 0 && newZoom < 2) props.setZoom(newZoom)
    };


    return (
        <Container Width={"100%"} Height={"100%"} ref={Ref} onWheel={onScroll} id="container" Left={props.Left}>
            <Graph Width={props.graphWidth+"px"} Height={props.graphHeight+"px"} ref={graphRef} id="Graph" className="flex-child graph" Transform={`scale(${props.Zoom})`}/>
        </Container>
        
    )
}

const Container = styled.div`
  position: ${props => !props.Comparison ? container_position : 'fixed'};
  overflow: hidden;
  width: ${props => props.Width || "100%"};
  height: ${props => props.Height || "100%"};
  top: 0;
  left:  ${props => props.Left || 0};
  cursor: grab;
`;

const Graph = styled.div`
  position: relative;
  overflow: hidden;
  width: ${props => props.Width || "100%"};
  height: ${props => props.Height || "100%"};
  top: 0;
  left: 0;
  cursor: grab;
  transform: ${props => props.Transform || "scale(1.0)"};

`;
