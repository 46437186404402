import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import styled from "styled-components";

import {ReactComponent as Logo} from '../../assets/userintro/Logo_frame.svg';
import {StyledDivBold, StyledDivRegular} from '../../layout-and-styling/standardized-components-library/Styling-TextInput';
import ComponentFooter from '../../layout-and-styling/standardized-components-library/Component-Footer';
import {ReactComponent as TopWiggle} from '../../assets/userintro/FrameTopWiggle.svg';
import {ReactComponent as BottomWiggle} from '../../assets/userintro/FrameBottomWiggle.svg';
import {useWindowSize} from '../../layout-and-styling/standardized-components-library/Component-Responsive';
import ComponentLanguageSwitcher from "../../layout-and-styling/standardized-components-library/Component-language-switcher";
import {OverlayContext} from "../../layout-and-styling/context-hooks/OverlayContext";
import i18next from "i18next";
import {useTranslation, Trans} from 'react-i18next';
import {AnimateKeyframes}  from 'react-simple-animate';
import {ReactComponent as IconCover} from '../../assets/SurveyIntroduction/IconCover.svg';
import {ReactComponent as IconFeedback} from '../../assets/SurveyIntroduction/IconFeedback.svg';
import {ReactComponent as IconProgress} from '../../assets/SurveyIntroduction/IconProgress.svg';

const CurrentUserData = JSON.parse(localStorage.getItem('current_user_data'));

const PageWelcome = () => {
    const context = useContext(OverlayContext);
    const { t } = useTranslation();

    /** Check if already authenticated, and bring to dashboard if possible.*/
    useEffect(() => {
        const axiosInstance = axios.create({withCredentials: true})

        // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
            .catch((error) => {
                console.log('error, you are not logged in yet!')
                window.location.replace(process.env.REACT_APP_APPURL + '/login');
            })
    }, []);

    /** Responsive window sizing and useLayoutEffect trigger */
    useWindowSize();

    const [render, setRender] = useState(false);
    const reRender = () => {
        setRender(!render)
    };

    useEffect(() => {
        if(!localStorage.getItem("i18nextLng")) {
            localStorage.setItem("i18nextLng", "en-US")
        }
    }, [])

    return (
        <Container>
            <Wrapper>

                <LeftContainer>

                    <TopWiggleWrapper>
                      <TopWiggle/>
                    </TopWiggleWrapper>

                    <LogoWrapper>
                      <Logo/>   
                    </LogoWrapper>

                    <LeftTextWrapper>
                      <Trans i18nKey="PageWelcome.TextSidebar">
                        A few steps away from <span>boosting your organization</span>.
                      </Trans>
                    </LeftTextWrapper>

                    <ImageWrapper>
                      <IconCover style={{width: '100%', height: '100%'}}/>
                    </ImageWrapper>

                    <BottomWiggleWrapper>
                      <BottomWiggle/>
                    </BottomWiggleWrapper>

                </LeftContainer>

                <RightContainer>

                    <LanguageSwitcherWrapper>

                      <AnimateKeyframes IntroFrameBackground play={true} duration={0.75} delay={1} iterationCount={6} direction="alternate-reverse" keyframes={[
                          { 0: 'opacity: 0.1' }, // 10%
                          { 100: 'opacity: 1' }, // 100%
                        ]}                
                      >
                        <ComponentLanguageSwitcher reRender={reRender} Position='static' MarginTop='0' MarginLeft='0' MarginRight='0'/>
                      </AnimateKeyframes>

                    </LanguageSwitcherWrapper>
                    
                    <RightTextWrapper>

                      <StyledDivBold Position='static' Width='auto' Height='auto' FontSize='42px' LineHeight='52px'>
                        {t('PageWelcome.TextHeader1')}  {context.setFirstName(CurrentUserData[0]['first_name'], i18next.language)},
                      </StyledDivBold>

                      <StyledDivRegular Position='static' MarginTop='40px' Width='auto' Height='auto' FontSize='18px' LineHeight='28px'>
                        <Trans i18nKey="PageWelcome.TextBody">
                          Ready to boost teamwork in your organization? Meet <b>Elephants in the Room</b>, your partner in finding out what’s really happening at your workplace. This is your chance to let everyone know about the biggest challenges you face and how well your team actually collaborates. And the best part? You can do it completely anonymously!
                        </Trans>
                      </StyledDivRegular>

                      <StyledDivBold Position='static' MarginTop='40px' Width='auto' Height='auto' FontSize='20px' LineHeight='20px'>
                        {t('PageWelcome.TextHeader2')}
                      </StyledDivBold>

                      <FocusWrapper>
            
                        <PointWrapper>

                          <IconFeedback/>

                          <PointTextWrapper>

                            <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='18px' LineHeight='28px' FontWeight='700'>
                              {t('PageWelcome.TextPoint1')}
                            </StyledDivRegular>

                            <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#929699' FontSize='18px' LineHeight='28px'>
                              {t('PageWelcome.TextDescription1')}
                            </StyledDivRegular>

                          </PointTextWrapper>

                        </PointWrapper>

                        <PointWrapper>

                          <IconProgress/>

                          <PointTextWrapper>

                            <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='18px' LineHeight='28px' FontWeight='700'>
                              {t('PageWelcome.TextPoint2')}
                            </StyledDivRegular>

                            <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#929699' FontSize='18px' LineHeight='28px'>
                              {t('PageWelcome.TextDescription2')}
                            </StyledDivRegular>

                          </PointTextWrapper>

                        </PointWrapper>

                      </FocusWrapper>

                    </RightTextWrapper>

                    <ComponentFooter identifier='Welcome' page='PageWelcome' nextdirectory='/user-benefits' ButtonText='Next' MarginTop='0' MarginLeft='0' Width='160px' Height='56px'/>

                </RightContainer>
                
            </Wrapper>
        </Container>
    )
};

export default PageWelcome;

const Container = styled.div`
  background: #f6f7fa;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: calc(1 / 1.75);
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const LeftContainer = styled.div`
  position: absolute;
  width: 26%;
  min-width: 400px;
  max-width: 500px;
  height: 100%;
  display: flex;
  background: #271B36;
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.05);

  @media (max-width: 1420px) {
    display: none;
  }
`;

const TopWiggleWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -1px;
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 40px;
  left: 60px;
`;

const LeftTextWrapper = styled.div`
  position: absolute;
  top: 18%;
  max-width: 100%;
  padding: 0 60px 0 64px;
  color: #fcfcfc;
  font-family: Montserrat;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;

  span {
    color: #e2336B;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  bottom: 9%;
  max-width: 100%;
  padding: 0 60px 0 102px;
`;

const BottomWiggleWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -3px;
`;

const RightContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;

  @media (min-width: 1921px) {
    justify-content: center;
  }
`;

const LanguageSwitcherWrapper = styled.div`
  position: absolute;
  top: 39px;
  display: flex;
  justify-content: flex-end;
  width: 1300px;
  padding: 0 60px;

  @media (max-width: 2420px) {
    margin-left: max(400px, 26%);
  }

  @media (max-width: 1920px) {
    right: 0;
  }
`;

const RightTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1030px;
  padding: 0 220px 0 170px;

  @media (max-width: 2420px) {
    margin-left: max(400px, 26%);
  }

  @media (max-width: 1420px) {
    padding: 0 60px;
    margin: auto;
  }
`;

const FocusWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  column-gap: 160px;
  row-gap: 20px;
`;

const PointWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

const PointTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
`;

// Archive
// const util = require('util') // pre-condition for console.log to see all content of array without cutting off 
// console.log('ArrayTotals =',util.inspect(ArrayTotals, { maxArrayLength: null }))
/* @media(max-width:1200px){
    display: none;
} */


// const util = require('util') // pre-condition for console.log to see all content of array without cutting off
// console.log('window.innerHeight =',util.inspect(window.innerHeight, { maxArrayLength: null }))
// console.log('window.innerWidth =',util.inspect(window.innerWidth, { maxArrayLength: null }))
// console.log('window.screen.availHeight =',util.inspect(window.screen.availHeight, { maxArrayLength: null }))
// console.log('window.screen.availWidth =',util.inspect(window.screen.availWidth, { maxArrayLength: null }))
// console.log('window.screen.height =',util.inspect(window.screen.height, { maxArrayLength: null }))
// console.log('window.screen.width =',util.inspect(window.screen.width, { maxArrayLength: null }))
// console.log('HeightFloatToString(892/940)  =',util.inspect(   HeightFloatToString(892/940), { maxArrayLength: null }))

// function FloatToString(num) {
//     if (Number.isInteger(num)) { return num + ".0%" }
//     else {return num.toFixed(2).toString() + "%" ; }
// }