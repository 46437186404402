import { useTranslation } from 'react-i18next';
import { useEffect, useState } from "react";
import styled from 'styled-components';
import load from "../../../assets/dashboard/load.gif";
import ProblemContainer from './elements/ProblemContainer';
import ActionsNeededList from './elements/ActionsNeededList';

import { useContext } from 'react';
import { OverlayContext } from '../../../layout-and-styling/context-hooks/OverlayContext';
import { ComponentPageTransformation } from '../../../layout-and-styling/standardized-components-library/Component-Tooltips';


const ProblemsPerYear = ({ surveyDates, totalProblems }) => {

    const context = useContext(OverlayContext);
    const { t, i18n } = useTranslation();
    const [selectedProblemKey, setSelectedProblemKey] = useState("")
    const [selectedProblem, setSelectedProblem] = useState({})
    const [hover1, setHover1] = useState(false);


    useEffect(() => {
        setSelectedProblemKey(Object.keys(totalProblems)[0])
    }, [totalProblems])

    useEffect(() => {
        setSelectedProblem(totalProblems[selectedProblemKey]);
    }, [selectedProblemKey])

    const selectProblem = (key) => {
        setSelectedProblemKey(key);
    }

    return (
        <ProblemsPerYearWrapper>
            {totalProblems != {} && Object.keys(totalProblems).length && selectedProblem ?
                <ProblemsPerYearData>
                    <ProblemListContainter>
                        <ProblemsPerYearTitle>
                            {t('Transformation.TopProblems')}
                        </ProblemsPerYearTitle>

                        <ProblemsPerYearSubtitle>
                            {t('Transformation.TheProblems')} {t('Transformation.from')} {surveyDates[0]} {t('Transformation.To')} {surveyDates[1]}
                        </ProblemsPerYearSubtitle>
                        {Object.entries(totalProblems).map(([key, problem]) => {
                            if (problem["rootCause"])
                                return <ProblemContainer key={key} name={key} select={selectProblem} problem={problem} isSelected={selectedProblemKey === key} />
                        })}
                    </ProblemListContainter>

                    <ActionsNeededData>

                        <ActionsNeededHeader>
                            <ActionsNeededKey style={{ backgroundColor: selectedProblem.color }}>{selectedProblem.key}</ActionsNeededKey>
                            <ActionsNeededTitle color={selectedProblem.color}>
                                {context && context.setBusinessProcess && selectedProblem && selectedProblemKey &&
                                    context.setBusinessProcess(selectedProblemKey, i18n.language)}
                            </ActionsNeededTitle>
                        </ActionsNeededHeader>

                        <ActionsNeededList actionsNeeded={selectedProblem.actionsNeeded} color={selectedProblem.color} />

                    </ActionsNeededData>


                </ProblemsPerYearData> :
                <LoadLogo>
                    {" "}
                    <img src={load} alt="loading..." />
                </LoadLogo>}

            {/* The hover information */}
            <InfoHover onMouseOver={() => setHover1(true)}
                onMouseOut={() => setHover1(false)}>?
                {hover1 && (<ComponentPageTransformation whichTooltip={"TopProblemsActions"} />
                )}
            </InfoHover>

        </ProblemsPerYearWrapper>
    )
}

export default ProblemsPerYear

const ProblemsPerYearWrapper = styled.div`
    position: relative;
    display: flex;
    margin-left: 16px;
    background: #FCFCFC;
    height: 390px;
    min-width: fit-content;
    width: 1110px;
    flex-direction: column;
    border-radius: 5px;
`;

const ProblemsPerYearData = styled.div`
    display: flex;
    margin-left: 30px;
    margin-top: 23px;
`;

const ActionsNeededData = styled.div`
`;

const ActionsNeededHeader = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`;

const ActionsNeededKey = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50%;
    font-size: 15px;
    font-weight: 600;
    padding: 2px;
    height: 20px;
    width: 20px;
`;

const ActionsNeededTitle = styled.div`
    color: ${props => props.color};
    margin-left: 10px;
    font-family: "Overpass";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
`;

const ProblemsPerYearTitle = styled.div`
    color: #271B36;
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 8px;
`;

const ProblemsPerYearSubtitle = styled.div`
    color: #929699;
    font-family: "Overpass";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 16px;
`;

const ProblemListContainter = styled.div`
    width: 50%;
    height: 240px;
`;

const InfoHover = styled.div`
    font-size: 18px;
    position: absolute;
    font-family: "Overpass";
    font-weight: 300;
    color: #FCFCFC;
    background-color: #e2336b;
    border: 2px solid #e2336b;
    top: 16px;
    border-radius: 500px;
    width: 20px;
    text-align: center;
    height: 20px;
    cursor: pointer;
    z-index: 20;
    right: 16px;
    float: right;
`;

const LoadLogo = styled.div`
  img {
    width: 50px;
  }
  display: flex;
  position: absolute;
  top: 48%;
  left: 50%;
`;