import React from "react";
import styled from "styled-components";
import { Cell, Pie, PieChart } from "recharts";
import { StyledDivRegular } from "../../layout-and-styling/standardized-components-library/Styling-TextInput";
import { useTranslation} from 'react-i18next';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.55;
  const x = cx + radius * 1.4 * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * 1.4 * Math.sin(-midAngle * RADIAN);

  return (
      <text x={x} y={y} fill="#fcfcfc" fontSize="14px" textAnchor="middle" dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
  );
};

const renderCustomizedLabelOverview = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.55;
  const x = cx + radius * 1.28 * Math.cos(-0.994 * midAngle * RADIAN);
  const y = cy + radius * 1.4 * Math.sin(-0.994 * midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="#FCFCFC"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="insideStart"
    >
      {/*{`${(percent * 100).toFixed(0)}%`}*/}
    </text>
  );
};

export const OverviewDonutChartsKnowledge = (props) => {
  let totalPeople =
    props.data.enough_knowledge + props.data.not_enough_knowledge;
  const data = [
    {
      name: "Knowledge",
      value: parseInt(
        ((props.data.enough_knowledge * 100) / totalPeople).toFixed(0)
      ),
    },
    {
      name: "Other",
      value: parseInt(
        ((props.data.not_enough_knowledge * 100) / totalPeople).toFixed(0)
      ),
    },
  ];

  /** Find props Margin positions, and adjust for the inner circle Donut chart */
  const OuterRadius = props.radius.OuterRadius;
  const InnerRadius = props.radius.InnerRadius;
  const InnerWidthPx = (InnerRadius * 2).toString() + "px";

  const InnerCircleTop = OuterRadius - InnerRadius + 5;
  const InnerCircleLeft = OuterRadius - InnerRadius + 5;

  return (
    <>
      {/* <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width={props.Width} Height={props.Height}> */}
      <StyledDivRegular
        MarginTop={props.MarginTop}
        MarginLeft={"20px"}
        Width={props.Width}
        Height={props.Height}
      >
        <PieChart width={2 * OuterRadius + 20} height={2 * OuterRadius + 20}>
          <Pie
            data={data}
            cx={OuterRadius}
            cy={OuterRadius}
            labelLine={false}
            label={renderCustomizedLabelOverview}
            animationBegin={100}
            animationDuration={1000}
            outerRadius={props.radius.CircleSize}
            fill=""
            dataKey="value"
            startAngle={-270}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  entry.value >= 50 && entry.name === "Knowledge"
                    ? "#06D6A0"
                    : entry.value <= 50 && entry.name === "Knowledge"
                    ? "#E2336B"
                    : "#D9D9D9"
                }
              />
            ))}
          </Pie>
        </PieChart>
      </StyledDivRegular>

      <StyledDivRegular
        BorderRadius="50%"
        Background="#FCFCFC"
        MarginTop={InnerCircleTop.toString() + "px"}
        MarginLeft={(InnerCircleLeft + 20).toString() + "px"}
        Width={InnerWidthPx}
        Height={InnerWidthPx}
      />

      <StyledDivRegular
        TextAlign="center"
        Background="transparent"
        FontSize={"20px"}
        FontWeight={"600"}
        MarginTop={(InnerCircleTop + InnerRadius - 10).toString() + "px"}
        MarginLeft={(InnerCircleLeft + InnerRadius - 30).toString() + "px"}
        Width="100px"
        Height="82px"
        SpanColor={data[0].value >= 50 ? "#06D6A0" : "#E2336B"}
      >
        <span>{data[0].value}%</span>
      </StyledDivRegular>
    </>
  );
};

export const OverviewDonutChartsSatisfaction = (props) => {
  let totalPeople =
    props.data.satisfied + props.data.dissatisfied + props.data.neutral;

  const data = [
    {
      name: "Satisfaction",
      value: parseInt(((props.data.satisfied * 100) / totalPeople).toFixed(0)),
    },
    {
      name: "Other",
      value: parseInt(
        (
          ((props.data.dissatisfied + props.data.neutral) * 100) /
          totalPeople
        ).toFixed(0)
      ),
    },
  ];

  /** Find props Margin positions, and adjust for the inner circle Donut chart */
  const OuterRadius = props.radius.OuterRadius;
  const InnerRadius = props.radius.InnerRadius;
  const InnerWidthPx = (InnerRadius * 2).toString() + "px";

  const InnerCircleTop = OuterRadius - InnerRadius + 5;
  const InnerCircleLeft = OuterRadius - InnerRadius + 5;

  return (
    <>
      {/* <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width={props.Width} Height={props.Height}> */}
      <StyledDivRegular
        MarginTop={props.MarginTop}
        MarginLeft={"20px"}
        Width={props.Width}
        Height={props.Height}
      >
        <PieChart width={2 * OuterRadius + 20} height={2 * OuterRadius + 20}>
          <Pie
            data={data}
            cx={OuterRadius}
            cy={OuterRadius}
            labelLine={false}
            label={renderCustomizedLabelOverview}
            animationBegin={100}
            animationDuration={1000}
            outerRadius={props.radius.CircleSize}
            fill=""
            dataKey="value"
            startAngle={-270}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  entry.value >= 50 && entry.name === "Satisfaction"
                    ? "#06D6A0"
                    : entry.value <= 50 && entry.name === "Satisfaction"
                    ? "#E2336B"
                    : "#D9D9D9"
                }
              />
            ))}
          </Pie>
        </PieChart>
      </StyledDivRegular>

      <StyledDivRegular
        BorderRadius="50%"
        Background="#FCFCFC"
        MarginTop={InnerCircleTop.toString() + "px"}
        MarginLeft={(InnerCircleLeft + 20).toString() + "px"}
        Width={InnerWidthPx}
        Height={InnerWidthPx}
      />

      <StyledDivRegular
        TextAlign="center"
        Background="transparent"
        FontSize={"20px"}
        FontWeight={"600"}
        MarginTop={(InnerCircleTop + InnerRadius - 10).toString() + "px"}
        MarginLeft={(InnerCircleLeft + InnerRadius - 30).toString() + "px"}
        Width="100px"
        Height="82px"
        SpanColor={data[0].value >= 50 ? "#06D6A0" : "#E2336B"}
      >
        <span>{data[0].value}%</span>
      </StyledDivRegular>
    </>
  );
};

export const OverviewDonutChartsCollaboration = (props) => {
    const data = [
    {
      name: "Collaboration",
      value: props.data
    },
    {
      name: "No Collaboration",
      value: 100 - props.data
    }
  ];

  /** Find props Margin positions, and adjust for the inner circle Donut chart */
  const OuterRadius = props.radius.OuterRadius;
  const InnerRadius = props.radius.InnerRadius;
  const InnerWidthPx = (InnerRadius * 2).toString() + "px";

  const InnerCircleTop = OuterRadius - InnerRadius + 5;
  const InnerCircleLeft = OuterRadius - InnerRadius + 5;

  return (
    <>
      {/* <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width={props.Width} Height={props.Height}> */}
      <StyledDivRegular
        MarginTop={props.MarginTop}
        MarginLeft={"20px"}
        Width={props.Width}
        Height={props.Height}
      >
        <PieChart width={2 * OuterRadius + 20} height={2 * OuterRadius + 20}>
          <Pie
            data={data}
            cx={OuterRadius}
            cy={OuterRadius}
            labelLine={false}
            label={renderCustomizedLabelOverview}
            animationBegin={100}
            animationDuration={1000}
            outerRadius={props.radius.CircleSize}
            fill=""
            dataKey="value"
            startAngle={-270}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  entry.value >= 50 && entry.name === "Collaboration"
                    ? "#06D6A0"
                    : entry.value <= 50 && entry.name === "Collaboration"
                    ? "#E2336B"
                    : "#D9D9D9"
                }
              />
            ))}
          </Pie>
        </PieChart>
      </StyledDivRegular>

      <StyledDivRegular
        BorderRadius="50%"
        Background="#FCFCFC"
        MarginTop={InnerCircleTop.toString() + "px"}
        MarginLeft={(InnerCircleLeft + 20).toString() + "px"}
        Width={InnerWidthPx}
        Height={InnerWidthPx}
      />

      <StyledDivRegular
        TextAlign="center"
        Background="transparent"
        FontSize={"20px"}
        FontWeight={"600"}
        MarginTop={(InnerCircleTop + InnerRadius - 10).toString() + "px"}
        MarginLeft={(InnerCircleLeft + InnerRadius - 30).toString() + "px"}
        Width="100px"
        Height="82px"
        SpanColor={data[0].value >= 50 ? "#06D6A0" : "#E2336B"}
      >
        <span>{data[0].value}%</span>
      </StyledDivRegular>
    </>
  );
};

export const DonutChart = (props) => {
  /** Values for Donut Chart */
  // how percentages for the chart seem to be calculated:
  //   - from the following data the percentages get drawn from knowing the entire total votes by adding all 3 top processes and the OtherValue,
  //     which contains the number of votes that were giving to processes other than the top 3
  //   - position of elements in data is important, as the percentages get written from top counter clockwise depending on the next itm in the data array
  const data = [
    { name: "Top1", value: props.HighValue },
    { name: "Other", value: props.OtherValue },
    { name: "Top3", value: props.LowValue },
    { name: "Top2", value: props.MedValue },
  ];

  /** Colors for Donut Chart */
  const COLORS = [
    props.HighDonutColor,
    props.OtherDonutColor,
    props.LowDonutColor,
    props.MedDonutColor,
  ];

  /** Find props Margin positions, and adjust for the inner circle Donut chart */
  const OuterRadius = 80;
  const InnerRadius = 43;
  const InnerWidthPx = (InnerRadius * 2).toString() + "px";

  const InnerCircleTop =
    parseInt(props.MarginTop) + OuterRadius - InnerRadius + 5;
  const InnerCircleLeft =
    parseInt(props.MarginLeft) + OuterRadius - InnerRadius + 5;

  return (
    <Container>
      {/* <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width={props.Width} Height={props.Height}> */}
      <StyledDivRegular
        Position="relative"
        MarginTop={props.MarginTop}
        MarginLeft={props.MarginLeft}
        Width={props.Width}
        Height={props.Height}
      >
        <PieChart width={2 * OuterRadius + 20} height={2 * OuterRadius + 20}>
          <Pie
            data={data}
            cx={OuterRadius}
            cy={OuterRadius}
            labelLine={false}
            label={renderCustomizedLabel}
            animationBegin={100}
            animationDuration={1000}
            outerRadius={OuterRadius}
            fill=""
            dataKey="value"
            startAngle={-270}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </StyledDivRegular>

      <StyledDivRegular
        Position="absolute"
        Top="0"
        Left="0"
        BorderRadius="50%"
        Background="#FCFCFC"
        MarginTop={"64px"}
        MarginLeft={"44px"}
        Width={InnerWidthPx}
        Height={InnerWidthPx}
      />
    </Container>
  );
};

export const OldDonutChart = (props) => {
  const {t, i18n} = useTranslation();

  /** Values for Donut Chart */
  const data = [
    { name: "Top1", value: props.HighValue },
    { name: "Other", value: props.OtherValue },
    { name: "Top3", value: props.LowValue },
    { name: "Top2", value: props.MedValue },
  ];

  /** Colors for Donut Chart */
  const COLORS = [
    props.HighDonutColor,
    props.OtherDonutColor,
    props.LowDonutColor,
    props.MedDonutColor,
  ];

  /** Find props Margin positions, and adjust for the inner circle Donut chart */
  const OuterRadius = 138;
  const InnerRadius = 88;
  const InnerWidthPx = (InnerRadius * 2).toString() + "px";

  const InnerCircleTop =
    parseInt(props.MarginTop) + OuterRadius - InnerRadius + 5;
  const InnerCircleLeft =
    parseInt(props.MarginLeft) + OuterRadius - InnerRadius + 5;

  return (
    <>
      {/* <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width={props.Width} Height={props.Height}> */}
      <StyledDivRegular
        MarginTop={props.MarginTop}
        MarginLeft={props.MarginLeft}
        Width={props.Width}
        Height={props.Height}
      >
        <PieChart width={2 * OuterRadius + 20} height={2 * OuterRadius + 20}>
          <Pie
            data={data}
            cx={OuterRadius}
            cy={OuterRadius}
            labelLine={false}
            label={renderCustomizedLabel}
            animationBegin={100}
            animationDuration={1000}
            outerRadius={OuterRadius}
            fill=""
            dataKey="value"
            startAngle={-270}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </StyledDivRegular>

      <StyledDivRegular
        BorderRadius="50%"
        Background="#FCFCFC"
        MarginTop={InnerCircleTop.toString() + "px"}
        MarginLeft={InnerCircleLeft.toString() + "px"}
        Width={InnerWidthPx}
        Height={InnerWidthPx}
      />

      <StyledDivRegular
        TextAlign="center"
        Background="transparent"
        MarginTop={(InnerCircleTop + InnerRadius - 21).toString() + "px"}
        MarginLeft={(InnerCircleLeft + InnerRadius - 50).toString() + "px"}
        Width="100px"
        Height="82px"
        SpanColor={props.MedDonutColor}
      > 
      </StyledDivRegular>
    </>
  );
};

export const SatisfactionChart = (props) => {
  if (props.data) {
    /** Values for Donut Chart */
    const satisfactionData = [
      { name: "Dissatisfied", value: props.data[3].dissatisfied },
      { name: "Neutral", value: props.data[3].neutral },
      { name: "Satisfaction", value: props.data[3].satisfied },
    ];

    const SatisfactionColors = [
      props.DissatisfiedColor,
      props.NeutralColor,
      props.SatisfiedColor,
    ];

    const renderCustomizedLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      index,
    }) => {
      const { width, height, value } = props;

      const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <g>
          <circle cx={x} cy={y} r={21} fill="#F1F3F4" />
          <text x={x + 3} y={y + 4} fill="#271B36" textAnchor={"middle"}>
            {`${(percent * 100).toFixed(0)}%`}
          </text>
          <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#271B36">
            Satisfaction
          </text>
        </g>
      );
    };

    return (
      <>
        <StyledDivRegular
          Position="relative"
          Display="flex"
          MarginTop="130px"
          Cursor="pointer"
        >
          <PieChart width={230} height={300} mar>
            <Pie
              dataKey="value"
              isAnimationActive={true}
              data={satisfactionData}
              cx="40%"
              cy="60%"
              innerRadius={70}
              outerRadius={87}
              label={renderCustomizedLabel}
            >
              {SatisfactionColors.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={SatisfactionColors[index % SatisfactionColors.length]}
                />
              ))}
            </Pie>
          </PieChart>
          <PieChartLabelWrapper Cursor="pointer">
            <LabelWrapper>
              <StyledDivRegular
                Width="20px"
                Height="20px"
                Background="#B3194A"
                BorderRadius="50%"
                Position="relative"
                MarginRight="8px"
              />
              <text>Dissatisfied</text>
            </LabelWrapper>
            <LabelWrapper>
              <StyledDivRegular
                Width="20px"
                Height="20px"
                Background="#C6CBCB"
                BorderRadius="50%"
                Position="relative"
                MarginRight="8px"
              />

              <text>Neutral</text>
            </LabelWrapper>
            <LabelWrapper>
              <StyledDivRegular
                Width="20px"
                Height="20px"
                Background="#26547C"
                BorderRadius="50%"
                Position="relative"
                MarginRight="8px"
              />

              <text>Satisfied</text>
            </LabelWrapper>
          </PieChartLabelWrapper>
        </StyledDivRegular>
      </>
    );
  }
};

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`;

const PieChartLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 110px;
  margin-top: 35px;
  font-family: "Overpass",sans-serif;
  font-size: 14px;
  color: #5F6366;
  margin-right: 45px;
`;

const Container = styled.div`
  display: ${props => props.Display || ""};
  position: relative;
  align-items: center;
  justify-content: center;
  z-index: 0;

`;

//Archive
