import React, { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { Slider } from "@mui/material";
import {ReactComponent as PrevButton} from "../../../assets/initiative_prev_button.svg";
import {ReactComponent as NextButton} from "../../../assets/initiative_next_button.svg";
import {ReactComponent as NextButtonNL} from "../../../assets/initiative_next_buttonNL.svg";
import {ReactComponent as NextButtonCN} from "../../../assets/initiative_next_buttonCN.svg";
import {ReactComponent as NextButtonGray} from "../../../assets/initiative_next_button_gray.svg";
import {ReactComponent as NextButtonGrayNL} from "../../../assets/initiative_next_button_grayNL.svg";
import {ReactComponent as NextButtonGrayCN} from "../../../assets/initiative_next_button_grayCN.svg";
import {ReactComponent as PrevButtonGray} from "../../../assets/initiative_prev_button_gray.svg";
import { StyledDivButton } from "../../../layout-and-styling/standardized-components-library/Styling-TextInput";
import { OverlayContext } from "../../../layout-and-styling/context-hooks/OverlayContext";
import { ComponentTooltipCulture } from "../../../layout-and-styling/standardized-components-library/Component-Tooltips";
import ComponentCultureSlider from "./Component-culture-slilder";
import i18next from "i18next";

export const DEFAULT_SLIDER_VALUE = 1;

const ComponentCultureQuestionsPeer = (props) => {
  const { t, i18n } = useTranslation();
  const context = useContext(OverlayContext);
  const [hover3, setHover3] = useState(false);

  // let ArrayCultureApi = JSON.parse(localStorage.getItem("ArrayCultureApi"))
  const userInfo = JSON.parse(localStorage.getItem("current_user_data"));
  const userId = userInfo[0]["id"];
  const filteredUsers = JSON.parse( localStorage.getItem("filteredPeopleCulturePerTeam") );
  const cultureSelectedPeople = JSON.parse( localStorage.getItem("cultureSelectedPeople") );

  // cultureSelectedPeople.sort(function (a, b) {
  //   return a.id - b.id;
  // });

  let all_finalInput_checker = () => {
    if (props.ArrayInputCheck.length === cultureSelectedPeople.length) return true;
    return false;
}

  props.setInputChecker(all_finalInput_checker())

  const getColleagueByUserId = (id) => {
    return props.ArrayCulturePeers.find((e) => e.target === id);
  };

  const getColleagueByUserIdPic = (id) => {
    return cultureSelectedPeople.find((e) => e.target === id);
  };

  //Problem getting index, maybe causes slider to not update as well
  const selectedColleagueArray = getColleagueByUserId(props.selectedColleague);
  let index = props.ArrayCulturePeers.indexOf(selectedColleagueArray);

  const selectedColleagePic = getColleagueByUserIdPic(props.selectedColleague);
  let index1 = cultureSelectedPeople.indexOf(selectedColleagePic);

  useEffect(() => {
    const newArr = [...props.ArrayInputCheck];

    props.ArrayCulturePeers.forEach((user) => {
      if (user.completed) {
        
        if (!newArr.some((userId) => userId === user.target)) {
          newArr.push(user.target)
          props.updateArrayInputCheck(newArr);
          localStorage.setItem("CheckedEmployeesCulturePeer", JSON.stringify(newArr));
        }
      }
    });
  }, [props.ArrayCulturePeers]);

  useEffect(() => {
    cultureObj.forEach((slider) => {
      slider.value = DEFAULT_SLIDER_VALUE;
    });
  }, [props.selectedColleague]);


  const updateCultureCommunication = (commValue) => {
    let newArr = [...props.ArrayCulturePeers];
    const selectedColleagueIndex = newArr.findIndex(user => user.target === props.selectedColleague)

    newArr[selectedColleagueIndex].communication = commValue;
    props.updateArrayCulturePeers(newArr);
    localStorage.setItem("ArrayCultureApi", JSON.stringify(newArr));
    props.checkColleagueCompleted(props.selectedColleague);
  };

  const updateCultureTrust = (trustValue) => {
    let newArr = [...props.ArrayCulturePeers];

    const selectedColleagueIndex = newArr.findIndex(user => user.target === props.selectedColleague)

    newArr[selectedColleagueIndex].trust = trustValue;
    props.updateArrayCulturePeers(newArr);
    localStorage.setItem("ArrayCultureApi", JSON.stringify(newArr));
    props.checkColleagueCompleted(props.selectedColleague);
  };

  const updateCultureDeciding = (decidingValue) => {
    let newArr = [...props.ArrayCulturePeers];

    const selectedColleagueIndex = newArr.findIndex(user => user.target === props.selectedColleague)

    newArr[selectedColleagueIndex].deciding = decidingValue;
    props.updateArrayCulturePeers(newArr);
    localStorage.setItem("ArrayCultureApi", JSON.stringify(newArr));
    props.checkColleagueCompleted(props.selectedColleague);
  };

  const updateCultureDisagreeing = (disagreeingValue) => {
    let newArr = [...props.ArrayCulturePeers];

    const selectedColleagueIndex = newArr.findIndex(user => user.target === props.selectedColleague)

    newArr[selectedColleagueIndex].disagreeing = disagreeingValue;
    props.updateArrayCulturePeers(newArr);
    localStorage.setItem("ArrayCultureApi", JSON.stringify(newArr));
    props.checkColleagueCompleted(props.selectedColleague);
  };

  const updateCultureScheduling = (schedulingValue) => {
    let newArr = [...props.ArrayCulturePeers];

    const selectedColleagueIndex = newArr.findIndex(user => user.target === props.selectedColleague)

    newArr[selectedColleagueIndex].scheduling = schedulingValue;
    
    props.updateArrayCulturePeers(newArr);
    localStorage.setItem("ArrayCultureApi", JSON.stringify(newArr));
    props.checkColleagueCompleted(props.selectedColleague);
  };

  const updateCultureLeading = (leadingValue) => {
    let newArr = [...props.ArrayCulturePeers];

    const selectedColleagueIndex = newArr.findIndex(user => user.target === props.selectedColleague)

    newArr[selectedColleagueIndex].leading = leadingValue;
    props.updateArrayCulturePeers(newArr);
    localStorage.setItem("ArrayCultureApi", JSON.stringify(newArr));
    props.checkColleagueCompleted(props.selectedColleague);
  };

  const updateCultureEvaluating = (evaluatingValue) => {
    let newArr = [...props.ArrayCulturePeers];

    const selectedColleagueIndex = newArr.findIndex(user => user.target === props.selectedColleague)

    newArr[selectedColleagueIndex].evaluating = evaluatingValue;
    props.updateArrayCulturePeers(newArr);
    localStorage.setItem("ArrayCultureApi", JSON.stringify(newArr));
    props.checkColleagueCompleted(props.selectedColleague);
  };


  //Communication
  let direct = t('PageCulture.CultureAnswer1')
  let indirect = t('PageCulture.CultureAnswer2')
  let labelDirect = t('PageCulture.SliderDirect')
  let labelIndirect = t('PageCulture.SliderIndirect')

  //Trust
  let knowledge = t('PageCulture.TrustAnswer1')
  let relationship = t('PageCulture.TrustAnswer2')
  let labelKnowledge = t('PageCulture.SliderKnowledge')
  let labelRelationship = t('PageCulture.SliderRelationship')

  //Deciding
  let team = t('PageCulture.DecidingAnswer1')
  let higherUp = t('PageCulture.DecidingAnswer2')
  let labelTeam = t('PageCulture.SliderTeam')
  let labelHigherUp = t('PageCulture.SliderHigherUp')

  //Leading
  let equal = t('PageCulture.LeadingAnswer1')
  let hierarchical = t('PageCulture.LeadingAnswer2')
  let labelEqual = t('PageCulture.SliderEqual')
  let labelHierarchical = t('PageCulture.SliderHierarchical')

  //Disagreeing
  let confrontational = t('PageCulture.DisagreeingAnswer1')
  let avoidsConfront = t('PageCulture.DisagreeingAnswer2')
  let labelConfrontational = t('PageCulture.SliderConfrontational')
  let labelAvoidsConfront = t('PageCulture.SliderAvoidsConfront')

  //Scheduling
  let linear = t('PageCulture.SchedulingAnswer1')
  let flexible = t('PageCulture.SchedulingAnswer2')
  let labelLinear = t('PageCulture.SliderLinear')
  let labelFlexible = t('PageCulture.SliderFlexible')

  //Evaluating
  let directFeedback = t('PageCulture.EvaluatingAnswer1')
  let indirectFeedback = t('PageCulture.EvaluatingAnswer2')
  let labelDirectFeedback = t('PageCulture.SliderDirectFeedback')
  let labelIndirectFeedback = t('PageCulture.SliderIndirectFeedback')


  //Marks 

  const marksCommunication = [
    {
        value: 1,
        label: direct,
        labelDescription: labelDirect,
    },
    {
        value: 2,
        labelDescription: "2",
    },
    {
        value: 3,
        labelDescription: "3",
    },
    {
        value: 4,
        labelDescription: "4",
    },
    {
        value: 5,
        labelDescription: "5",

    },
    {
        value: 6,
        labelDescription: "6",
    },
    {
        value: 7,
        labelDescription: "7",

    },
    {
        value: 8,
        labelDescription: "8",
    },
    {
        value: 9,
        label: indirect,
        labelDescription: labelIndirect,
    } 
];

const marksTrust = [
  {
      value: 1,
      label: knowledge,
      labelDescription: labelKnowledge,
  },
  {
      value: 2,
      labelDescription: "2",
  },
  {
      value: 3,
      labelDescription: "3",
  },
  {
      value: 4,
      labelDescription: "4",
  },
  {
      value: 5,
      labelDescription: "5",

  },
  {
      value: 6,
      labelDescription: "6",
  },
  {
      value: 7,
      labelDescription: "7",

  },
  {
      value: 8,
      labelDescription: "8",
  },
  {
      value: 9,
      label: relationship,
      labelDescription: labelRelationship,
  } 
];

const marksDeciding = [
  {
      value: 1,
      label: team,
      labelDescription: labelTeam,
  },
  {
      value: 2,
      labelDescription: "2",
  },
  {
      value: 3,
      labelDescription: "3",
  },
  {
      value: 4,
      labelDescription: "4",
  },
  {
      value: 5,
      labelDescription: "5",

  },
  {
      value: 6,
      labelDescription: "6",
  },
  {
      value: 7,
      labelDescription: "7",

  },
  {
      value: 8,
      labelDescription: "8",
  },
  {
      value: 9,
      label: higherUp,
      labelDescription: labelHigherUp,
  } 
];


const marksLeading = [
  {
      value: 1,
      label: equal,
      labelDescription: labelEqual,
  },
  {
      value: 2,
      labelDescription: "2",
  },
  {
      value: 3,
      labelDescription: "3",
  },
  {
      value: 4,
      labelDescription: "4",
  },
  {
      value: 5,
      labelDescription: "5",

  },
  {
      value: 6,
      labelDescription: "6",
  },
  {
      value: 7,
      labelDescription: "7",

  },
  {
      value: 8,
      labelDescription: "8",
  },
  {
      value: 9,
      label: hierarchical,
      labelDescription: labelHierarchical,
  } 
];

const marksDisagreeing = [
  {
      value: 1,
      label: confrontational,
      labelDescription: labelConfrontational,
  },
  {
      value: 2,
      labelDescription: "2",
  },
  {
      value: 3,
      labelDescription: "3",
  },
  {
      value: 4,
      labelDescription: "4",
  },
  {
      value: 5,
      labelDescription: "5",

  },
  {
      value: 6,
      labelDescription: "6",
  },
  {
      value: 7,
      labelDescription: "7",

  },
  {
      value: 8,
      labelDescription: "8",
  },
  {
      value: 9,
      label: avoidsConfront,
      labelDescription: labelAvoidsConfront,
  } 
];

const marksScheduling = [
  {
      value: 1,
      label: linear,
      labelDescription: labelLinear,
  },
  {
      value: 2,
      labelDescription: "2",
  },
  {
      value: 3,
      labelDescription: "3",
  },
  {
      value: 4,
      labelDescription: "4",
  },
  {
      value: 5,
      labelDescription: "5",

  },
  {
      value: 6,
      labelDescription: "6",
  },
  {
      value: 7,
      labelDescription: "7",

  },
  {
      value: 8,
      labelDescription: "8",
  },
  {
      value: 9,
      label: flexible,
      labelDescription: labelFlexible,
  } 
];

const marksEvaluating = [
  {
      value: 1,
      label: directFeedback,
      labelDescription: labelDirectFeedback,
  },
  {
      value: 2,
      labelDescription: "2",
  },
  {
      value: 3,
      labelDescription: "3",
  },
  {
      value: 4,
      labelDescription: "4",
  },
  {
      value: 5,
      labelDescription: "5",

  },
  {
      value: 6,
      labelDescription: "6",
  },
  {
      value: 7,
      labelDescription: "7",

  },
  {
      value: 8,
      labelDescription: "8",
  },
  {
      value: 9,
      label: indirectFeedback,
      labelDescription: labelIndirectFeedback,
  } 
];


  let cultureObj = [
    {
      scale: "communication",
      question: t("PageCulturePeer.QuestionCulture"),
      marks: marksCommunication,
      setValForScale: updateCultureCommunication,
    },
    {
      scale: "disagreeing",
      question: t("PageCulturePeer.QuestionDisagreeing"),
      marks: marksDisagreeing,
      setValForScale: updateCultureDisagreeing,
    },
    {
      scale: "deciding",
      question: t("PageCulturePeer.QuestionDeciding"),
      marks: marksDeciding,
      setValForScale: updateCultureDeciding,
    },
    {
      scale: "trust",
      question: t("PageCulturePeer.QuestionTrust"),
      marks: marksTrust,
      setValForScale: updateCultureTrust,
    },
    {
      scale: "evaluating",
      question: t("PageCulturePeer.QuestionEvaluating"),
      marks: marksEvaluating,
      setValForScale: updateCultureEvaluating,
    },
    {
      scale: "scheduling",
      question: t("PageCulturePeer.QuestionScheduling"),
      marks: marksScheduling,
      setValForScale: updateCultureScheduling,
    },
    {
      scale: "leading",
      question: t("PageCulturePeer.QuestionLeading"),
      marks: marksLeading,
      setValForScale: updateCultureLeading,
    }
  ];

  function checkNextColleague() {
    for (let i = 0; i < cultureSelectedPeople.length; i++) {
      if (
        cultureSelectedPeople[i].id === props.selectedColleague &&
        i + 1 < cultureSelectedPeople.length
      ) {
        return true;
      }
    }
  }

  function checkPreviousColleague() {
    for (let i = 0; i < cultureSelectedPeople.length; i++) {
      if (cultureSelectedPeople[i].id === props.selectedColleague && i - 1 >= 0) {
        return true;
      }
    }
  }

  const previousColleague = () => {
    for (let i = 0; i < cultureSelectedPeople.length; i++) {
      if (cultureSelectedPeople[i].id === props.selectedColleague && i - 1 >= 0) {
        props.checkColleagueCompleted(props.selectedColleague);
        props.setSelectedColleague(cultureSelectedPeople[i - 1].id);
        localStorage.setItem(
          "props.selectedColleague",
          cultureSelectedPeople[i - 1].id
        );
        break;
      }
    }

    /**
     * This is for the screen to automatically scroll to the user that is selected. The try/catch is to mitigate an error that does not affect the functionality
     * caused by a desync between the data and the ref.
     */
    try {
      props.employeeRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const nextColleague = () => {
    for (let i = 0; i < cultureSelectedPeople.length; i++) {
      if (
        cultureSelectedPeople[i].id === props.selectedColleague &&
        i + 1 < cultureSelectedPeople.length
      ) {
        props.checkColleagueCompleted(props.selectedColleague);
        props.setSelectedColleague(cultureSelectedPeople[i + 1].id);
        localStorage.setItem(
          "SelectedColleague",
          cultureSelectedPeople[i + 1].id
        );
        break;
      }
    }

    try {
      props.employeeRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    } catch (error) {
      console.log(error);
    }
  };

  let eachQuestion = cultureObj.map((slider) => {
    const sliderValue = props.ArrayCulturePeers.find(
      (user) => user.target === props.selectedColleague
    )[slider.scale];
    return (
      <ComponentCultureSlider
        key={slider.scale}
        question={slider.question}
        scale={slider.scale}
        answer1={slider.answer1}
        answer2={slider.answer2}
        setValue={slider.setValForScale}
        index={index}
        value={sliderValue ?? 1}
        marks={slider.marks}
      ></ComponentCultureSlider>
    );
  });

  const selectedColleagueObj = cultureSelectedPeople.find(user => user.id === props.selectedColleague);

  return (

    <Container>
      <TopContainer>
        <InfoHover
          onMouseOver={() => setHover3(true)}
          onMouseOut={() => setHover3(false)}
        >
          ?{hover3 && <ComponentTooltipCulture whichTooltip={"PageCulture"} />}
        </InfoHover>
        <UserNameAndPicture>
          <NamePicture>
            <PersonPic>
              {selectedColleagueObj.first_name.charAt(0) +
                selectedColleagueObj.last_name.charAt(0)}
            </PersonPic>
            {context.setFirstName(selectedColleagueObj.first_name , i18next.language)}
          </NamePicture>
        </UserNameAndPicture>
      </TopContainer>
      <QuestionsContainer>{eachQuestion}</QuestionsContainer>
      <NextPreviousButtons>
        <button>{!checkPreviousColleague() ? <PrevButtonGray/> :
            <PrevButton onClick={() => previousColleague()}/>}</button>
        {i18n.language == 'en-US' &&
        <button>{!checkNextColleague() ? <NextButtonGray/> :
            <NextButton onClick={() => nextColleague()}/>}</button>
                    }
        {i18n.language == 'nl-NL' &&
        <button>{!checkNextColleague() ? <NextButtonGrayNL/> :
            <NextButtonNL onClick={() => nextColleague()}/>}</button>
        }
        {i18n.language == 'ch-CH' &&
        <button>{!checkNextColleague() ? <NextButtonGrayCN/> :
            <NextButtonCN onClick={() => nextColleague()}/>}</button>
        }
    </NextPreviousButtons>
    </Container>
  );
};

export default ComponentCultureQuestionsPeer;

const TopContainer = styled.div`
  width: 100%;
`;

const UserNameAndPicture = styled.div`
  margin-top: 10px;
  margin-bottom: 5px;
`;

const NamePicture = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  width: fit-content;
  font-family: "Overpass";
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const PersonPic = styled.div`
  margin-left: 20px;
  margin-right: 10px;
  align-items: center;
  width: 42px;
  height: 42px;
  font-size: 20px;
  border-radius: 50%;
  background: #d0e1f1;
  color: #271b36;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 1000px;
  max-width: 1600px;
  font-family: "Overpass", sans-serif;
  border-radius: 10px;
  background-color: #fcfcfc;
  padding: 15px 35px 0px 45px;
  margin-top: -1.2em;
`;

const QuestionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 0.2em;
  column-gap: 5em;
  margin-left: -1.1em;
  text-align: center;
`;

const InfoHover = styled.div`
  float: right;
  font-size: 18px;
  font-family: "Overpass", sans-serif;
  font-weight: 500;
  position: relative;
  color: #fcfcfc;
  margin-right: -7px;
  margin-top: 2px;
  border: 2px solid #e2336b;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  background-color: #e2336b;
  cursor: pointer;
`;

const NextPreviousButtons = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 26px;
  margin-top: 24px;

  button {
    background: none;
    border: none;
    cursor: pointer;
  }
`;
