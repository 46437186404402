import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { useWindowSize } from "../../layout-and-styling/standardized-components-library/Component-Responsive";

import ComponentSignificantColleagues from "../survey-close-colleagues/page-specific-components/Component-significant-colleagues";
import ComponentHeaderBlock from "../../layout-and-styling/standardized-components-library/Component-header-block";
import ComponentFooter from "../../layout-and-styling/standardized-components-library/Component-Footer";
import { StyledDivRegular } from "../../layout-and-styling/standardized-components-library/Styling-TextInput";
import ComponentCultureQuestionsPeer from "./page-specific-components/Component-culture-questions-peer";
import i18next from "i18next";

const PageCulturePeer = () => {
  const { t } = useTranslation();
  const [localStorageLanguage, setSelectedLanguage] = useState(
    JSON.parse(localStorage.getItem("Language"))
      ? JSON.parse(localStorage.getItem("Language"))
      : 0
  );

  /** Get Interaction and Employee datasets from API stored data from localStorage */
  const cultureSelectedPeople = JSON.parse(
    localStorage.getItem("cultureSelectedPeople")
  );
  const CurrentUserData = JSON.parse(localStorage.getItem("current_user_data"));
  /** From InteractionData.json, store the selected colleagues' names into an array to be used for each input window
   * create impact and pleasantness fields */
  /** initiate Array to be pushed via API Post request */

  useEffect(() => {
    const axiosInstance = axios.create({ withCredentials: true });
    // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
    axiosInstance
      .get(process.env.REACT_APP_APIURL + "/api/auth/validatesession/")
      .catch((error) => {
        window.location.replace(process.env.REACT_APP_APPURL + "/login");
      });
  }, []);

  const [ArrayQuality, updateArrayQuality] = useState(
    JSON.parse(localStorage.getItem("ArrayQuality"))
  );
  const [ArrayInputCheck, updateArrayInputCheck] = useState(
    JSON.parse(localStorage.getItem("CheckedEmployeesCulturePeer"))
      ? JSON.parse(localStorage.getItem("CheckedEmployeesCulturePeer"))
      : []
  );

  const [InputChecker, setInputChecker] = useState(false);
  const employeeRef = useRef([]);
  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  const [selectedColleague, setSelectedColleague] = useState(
    cultureSelectedPeople[0].id
  );
  const incompleteText = "Fill in all your colleagues to continue";
  const [render, setRender] = useState(false);
  const reRender = () => {
    setRender(!render);
  };
  const userInfo = JSON.parse(localStorage.getItem("current_user_data"));
  const userId = userInfo[0]["id"];
  const filteredUsers = JSON.parse(
    localStorage.getItem("filteredPeopleCulturePerTeam")
  );
  const [ArrayCultureApi, setArrayCultureApi] = useState(getArrayCultureApi());

  function getArrayCultureApi() {
    const userOwnCulture = localStorage.getItem("userOwnCulture") ? JSON.parse(localStorage.getItem("userOwnCulture")) : {};
    const InitArrayCultureApi = localStorage.getItem("ArrayCultureApi")
      ? JSON.parse(localStorage.getItem("ArrayCultureApi"))
      : [];

    for (let i = 0; i < filteredUsers.length; i++) {
      for (let j = 0; j < filteredUsers[i]["team_members"].length; j++) {
        if (
          !InitArrayCultureApi.some(
            (user) => user.target === filteredUsers[i]["team_members"][j]["id"]
          )
        ) {
          InitArrayCultureApi.push({
            source: userId,
            target: filteredUsers[i]["team_members"][j]["id"],
            communication: 0,
            trust: 0,
            deciding: 0,
            disagreeing: 0,
            scheduling: 0,
            leading: 0,
            evaluating: 0,
            completed: false, 
          });
        }
      }
    }
    if(userOwnCulture != {} && !InitArrayCultureApi.some(user => user.target === userId))
    {
      InitArrayCultureApi.push(userOwnCulture); 
    }
    return InitArrayCultureApi;
  }

  const checkColleagueCompleted = (target) => {
    const newArr = [...ArrayCultureApi];

    const colleagueIndex = newArr.findIndex((user) => user.target === target);

    if (
      newArr[colleagueIndex]?.["communication"] &&
      newArr[colleagueIndex]?.["trust"] &&
      newArr[colleagueIndex]?.["scheduling"] &&
      newArr[colleagueIndex]?.["deciding"] &&
      newArr[colleagueIndex]?.["leading"] &&
      newArr[colleagueIndex]?.["evaluating"] &&
      newArr[colleagueIndex]?.["disagreeing"]
    ) {
      newArr[colleagueIndex]["completed"] = true;
      setArrayCultureApi(newArr);
      localStorage.setItem("ArrayCultureApi", JSON.stringify(newArr));
    }
  };

  return (
    // TODO:
    //Check mark on the people who already completed the sliders.
    //Tooltip
    //Fix shrinking colleague component
    //Save each score for each employee
    <Container>
      <HeaderAndOverlayWrapper>
        <ComponentHeaderBlock page="Survey" reRender={reRender} />
      </HeaderAndOverlayWrapper>

      <WrapperTop>
        <Trans i18nKey="PageCulturePeer.Title">
          What <span style={{ color: "#B3194A" }}> What </span>
          type of work environment do you prefer?
        </Trans>
      </WrapperTop>

      <WrapperBottom>
        <ComponentSignificantColleagues
          inputCheck={ArrayInputCheck}
          checkColleagueCompleted={checkColleagueCompleted}
          page={"PageCulturePeer"}
          selectedColleague={selectedColleague}
          setSelectedColleague={setSelectedColleague}
          ArrayInputCheck={ArrayInputCheck}
          nextButtonClicked={nextButtonClicked}
          reRender={reRender}
          employeeRef={employeeRef}
          incompleteText={incompleteText}
          inputCheckPeople={InputChecker}
        ></ComponentSignificantColleagues>

        <ComponentCultureQuestionsPeer
          checkColleagueCompleted={checkColleagueCompleted}
          ArrayInputCheck={ArrayInputCheck}
          updateArrayInputCheck={updateArrayInputCheck}
          ArrayCulturePeers={ArrayCultureApi}
          updateArrayCulturePeers={setArrayCultureApi}
          selectedColleague={selectedColleague}
          ArrayQuality={ArrayQuality}
          setSelectedColleague={setSelectedColleague}
          employeeRef={employeeRef}
          setInputChecker={setInputChecker}
        ></ComponentCultureQuestionsPeer>
      </WrapperBottom>

      {/* Make it check in the footer if the page is culture and send data after that(Save data in localstorage) */}
      <ComponentFooter
        page="PageCulturePeer"
        backDirectory="/survey-culture"
        FooterMarginTop={(useWindowSize()[1] - 104).toString() + "px"}
        FooterMarginLeft="0px"
        FooterHeight="104px"
        FooterWidth="100%"
        MarginTop="25px"
        BackButtonMarginLeft="65%"
        NextButtonMarginLeft="calc(65% + 66px)"
        nextButtonClicked={nextButtonClicked}
        setNextButtonClicked={setNextButtonClicked}
        inputCheckPeople={InputChecker}
      ></ComponentFooter>
    </Container>
  );
};

export default PageCulturePeer;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f1f3f4;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: calc(1 / 1.75);
  }
`;

const HeaderAndOverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
`;

const WrapperBottom = styled.div`
  position: relative;
  width: 85%;
  background: #f1f3f4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 1em;
`;

const WrapperTop = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 100px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 1.5em;
`;
