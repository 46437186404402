import React, {useState} from 'react';
import styled from '@emotion/styled';

export default function ComponentOptionSelector(props){

    const [output, setOutput] = useState("");

    const handleSubmit = (e) =>{
        e.preventDefault();
        props.handleSelectedOption(output);
    }
    if(props.formOptions.length > 0){
        return(
            <Container>
                <form onSubmit={handleSubmit} style={{marginLeft: '8px'}}>
                    {props.formOptions.map((item,index)=>{
                        return <input id={"option_"+ item} type="submit" value={item} onClick={() => setOutput(item)} />
                    })}
                </form>
            </Container>
        )
    }
    return false;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  max-width: 500px;
  height: fit-content;
  padding-top: 0px;
  padding-left: 92px;
  margin-left: 4px;

  input{
    width: fit-content; height: 40px; margin-left: 4px; border: solid; border-radius: 32px;
    font-family: overpass; font-size: 12px; cursor: pointer; margin-bottom: 8px; color: #000000; border-color: #E3E5E4;
    border-width: 1px; line-height: 18px; padding-left: 16px; padding-right: 16px; background-color: #E3E5E4;
    font-weight: 575; transition: 0.3s;
    :hover{
        background-color: #26547C;
        color: #FCFCFC;
    }
  }
`;