import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";

import {useTranslation, Trans} from 'react-i18next';
import ComponentLanguageSwitcher from "../../../layout-and-styling/standardized-components-library/Component-language-switcher";
import {StyledDivBold, StyledDivRegular} from '../../../layout-and-styling/standardized-components-library/Styling-TextInput';
import {OverlayContext} from "../../../layout-and-styling/context-hooks/OverlayContext";
import {ReactComponent as IconTarget} from '../../../assets/intake/IconTarget.svg';
import {ReactComponent as IconOverlap} from '../../../assets/intake/IconOverlap.svg';
import {ReactComponent as IconChecklist} from '../../../assets/intake/IconChecklist.svg';
import {ReactComponent as IconPresentation} from '../../../assets/intake/IconPresentation.svg';
import {ReactComponent as IconLightbulb} from '../../../assets/intake/IconLightBulb.svg';
import IntroductionVideoComponent from "./IntroductionVideoComponent";
import VideoIntake from '../../../assets/intake/VideoIntake.mp4';
import GifComparingVersions from '../../../assets/intake/GifComparingVersions.gif';
import GifAddMainProcess from '../../../assets/intake/GifAddMainProcess.gif';
import GifAddSubProcess from '../../../assets/intake/GifAddSubProcess.gif';
import GifRenameMainProcess from '../../../assets/intake/GifRenameMainProcess.gif';
import GifRenameSubProcess from '../../../assets/intake/GifRenameSubProcess.gif';
import {ReactComponent as IconEnterKey} from '../../../assets/intake/IconEnterKey.svg';
import {ReactComponent as IconEscKey} from '../../../assets/intake/IconEscKey.svg';
import GifMoveMainProcess from '../../../assets/intake/GifMoveMainProcess.gif';
import GifMoveSubProcess from '../../../assets/intake/GifMoveSubProcess.gif';
import {ReactComponent as IconArrowLeftKey} from '../../../assets/intake/IconArrowLeftKey.svg';
import {ReactComponent as IconArrowRightKey} from '../../../assets/intake/IconArrowRightKey.svg';
import {ReactComponent as IconArrowUpKey} from '../../../assets/intake/IconArrowUpKey.svg';
import {ReactComponent as IconArrowDownKey} from '../../../assets/intake/IconArrowDownKey.svg';
import GifDeleteMainProcess from '../../../assets/intake/GifDeleteMainProcess.gif';
import GifDeleteSubProcess from '../../../assets/intake/GifDeleteSubProcess.gif';
import {ReactComponent as IconBackspaceKey} from '../../../assets/intake/IconBackspaceKey.svg';
import {ReactComponent as IconDeleteKey} from '../../../assets/intake/IconDeleteKey.svg';
// import ImageBlankProcess from '../../../assets/intake/ImageBlankProcess.png';
// import ImageStandardProcess from '../../../assets/intake/ImageStandardProcess.png';
import {ReactComponent as IconChevronLeft} from '../../../assets/intake/IconChevronLeft.svg';

const currentUserData = JSON.parse(localStorage.getItem('current_user_data'));

const IntroductionComponent = (props) => {
    const context = useContext(OverlayContext);
    const { t } = useTranslation();

    const [indexes, setIndexes] = useState([]);

    useEffect(() => {
        if(props.identifier === 'IntakeBuilderLeader' || props.identifier === 'IntakeComparison') {
            setIndexes(Array.from({ length: 6 }, (_, index) => index));
        } else {
            setIndexes(Array.from({ length: 5 }, (_, index) => index));
        }
    }, [props.identifier]);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [componentClicked, setComponentClicked] = useState(false);

    const showNextPage = () => {
        if (props.identifier === 'IntakeBuilderLeader' && currentIndex === 1 && !componentClicked) {
            // do nothing
        } else {
            if (currentIndex < indexes.length - 1) {
                setCurrentIndex(currentIndex + 1);
            }
        }
    }

    const showPreviousPage = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    }
    
    // const [selectedTemplate, setSelectedTemplate] = useState('');

    // const selectTemplate = (templateName) => {
    //     if (selectedTemplate === templateName){
    //         setSelectedTemplate('');
    //     } else {
    //         setSelectedTemplate(templateName);
    //     }
    // }

    const getStarted = () => {
        // if (props.identifier === 'IntakeBuilderLeader') {
        //     if (selectedTemplate.trim().length !== 0) {
        //         if (selectedTemplate === 'StandardProcess') {
        //             props.setSelectedTemplate(selectedTemplate);
        //         }
    
        //         props.hideIntroduction();
        //     }
        // } else {
            props.hideIntroduction();
        // }
    }

    return (
        <IntroductionContainer isShowingIntroduction={props.isShowingIntroduction}>
            <IntroductionButtonContainer Top='40px'>
                <ComponentLanguageSwitcher reRender={props.reRender} Position='static' Height='auto' Padding='4px 12px' MarginTop='0' MarginLeft='0' MarginRight='0' BorderRadius='8px' Background='#E3E5E4'/>
            </IntroductionButtonContainer>

            {((props.identifier === 'IntakeBuilderLeader' || props.identifier === 'IntakeComparison') ? currentIndex === 1 : false) ? (
                (props.identifier === 'IntakeBuilderLeader') ? (
                    <IntroductionWrapper>
                        <HeaderWrapper>
                            <StyledDivBold Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                                {t('PageIntakeIntroduction.TutorialTextHeader')}
                            </StyledDivBold>

                            <StyledDivBold Position='static' Width='auto' Height='auto' Color='#C6CBCB' FontSize='20px' LineHeight='20px'>
                                <Trans i18nKey="PageIntakeIntroduction.TutorialTextSubHeader">
                                    CLICK ON <span style={{color: "#F7C9D9"}}>THE VIDEO BELOW</span> TO WATCH <span style={{color: "#F7C9D9"}}>A TUTORIAL</span>
                                </Trans>
                            </StyledDivBold>
                        </HeaderWrapper>

                        <ExampleWrapper>
                            <IntroductionVideoComponent identifier='CreatingAProcessVideo' video={VideoIntake} setComponentClicked={setComponentClicked}/>
                        </ExampleWrapper>
                    </IntroductionWrapper>
                ) : (
                    <IntroductionWrapper>
                        <HeaderWrapper>
                            <StyledDivBold Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                                {t('PageIntakeIntroduction.InstructionsTextHeader')}
                            </StyledDivBold>

                            <StyledDivBold Position='static' Width='auto' Height='auto' Color='#C6CBCB' FontSize='20px' LineHeight='20px'>
                                {t('PageIntakeIntroduction.Instructions1TextSubHeader')}
                            </StyledDivBold>
                        </HeaderWrapper>

                        <ExampleWrapper>
                            <ExamplePointWrapper>
                                <img src={GifComparingVersions} style={{width: '82.7%', height: 'auto', borderRadius: '8px'}} alt=''/>

                                <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                                    {t('PageIntakeIntroduction.Instructions1TextPoint')}
                                </StyledDivRegular>
                            </ExamplePointWrapper>
                        </ExampleWrapper>
                    </IntroductionWrapper>
                )
            ) : ((props.identifier === 'IntakeBuilderLeader' || props.identifier === 'IntakeComparison') ? currentIndex === 2 : currentIndex === 1) ? (
                <IntroductionWrapper>
                    <HeaderWrapper>
                        <StyledDivBold Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                            {t('PageIntakeIntroduction.InstructionsTextHeader')}
                        </StyledDivBold>

                        <StyledDivBold Position='static' Width='auto' Height='auto' Color='#C6CBCB' FontSize='20px' LineHeight='20px'>
                            {t('PageIntakeIntroduction.Instructions2TextSubHeader')}
                        </StyledDivBold>
                    </HeaderWrapper>

                    <ExampleWrapper>
                        <ExamplePointWrapper>
                            <ExamplePointGifWrapper>
                                <img src={GifAddMainProcess} style={{width: '100%', height: 'auto'}} alt=''/>
                            </ExamplePointGifWrapper>

                            <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                                {t('PageIntakeIntroduction.Instructions2TextPoint1')}
                            </StyledDivRegular>
                        </ExamplePointWrapper>

                        <ExamplePointWrapper>
                            <ExamplePointGifWrapper>
                                <img src={GifAddSubProcess} style={{width: '100%', height: 'auto'}} alt=''/>
                            </ExamplePointGifWrapper>

                            <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                                {t('PageIntakeIntroduction.Instructions2TextPoint2')}
                            </StyledDivRegular>
                        </ExamplePointWrapper>
                    </ExampleWrapper>
                </IntroductionWrapper>
            ) : ((props.identifier === 'IntakeBuilderLeader' || props.identifier === 'IntakeComparison') ? currentIndex === 3 : currentIndex === 2) ? (
                <IntroductionWrapper>
                    <HeaderWrapper>
                        <StyledDivBold Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                            {t('PageIntakeIntroduction.InstructionsTextHeader')}
                        </StyledDivBold>

                        <StyledDivBold Position='static' Width='auto' Height='auto' Color='#C6CBCB' FontSize='20px' LineHeight='20px'>
                            {t('PageIntakeIntroduction.Instructions3TextSubHeader')}
                        </StyledDivBold>
                    </HeaderWrapper>

                    <ExampleWrapper>
                        <ExamplePointWrapper>
                            <ExamplePointGifWrapper>
                                <img src={GifRenameMainProcess} style={{width: '100%', height: 'auto'}} alt=''/>
                            </ExamplePointGifWrapper>

                            <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                                {t('PageIntakeIntroduction.Instructions3TextPoint1')}
                            </StyledDivRegular>
                        </ExamplePointWrapper>

                        <ExamplePointWrapper>
                            <ExamplePointGifWrapper>
                                <img src={GifRenameSubProcess} style={{width: '100%', height: 'auto'}} alt=''/>
                            </ExamplePointGifWrapper>

                            <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                                {t('PageIntakeIntroduction.Instructions3TextPoint2')}
                            </StyledDivRegular>
                        </ExamplePointWrapper>
                    </ExampleWrapper>

                    <TipWrapper>
                        <IconLightbulb/>

                        <TipPointTextWrapper>
                            <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='16px' LineHeight='20px' FontWeight='700' LetterSpacing='0.3px'>
                                {t('PageIntakeIntroduction.InstructionsTipTextHeader')}
                            </StyledDivRegular>

                            <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='16px' LineHeight='40px'>
                                <Trans i18nKey="PageIntakeIntroduction.Instructions3TipTextBody1">
                                    Alternatively, you can start renaming a Process via <b>double click</b>. Once satisfied, press the 
                                </Trans>
                                <IconEnterKey style={{margin: '0 0 -15px 0'}}/>
                                {t('PageIntakeIntroduction.Instructions3TipTextBody2')}
                                <IconEscKey style={{margin: '0 0 -7.5px 0'}}/>
                                {t('PageIntakeIntroduction.Instructions3TipTextBody3')}
                            </StyledDivRegular>
                        </TipPointTextWrapper>
                    </TipWrapper>
                </IntroductionWrapper>
            ) : ((props.identifier === 'IntakeBuilderLeader' || props.identifier === 'IntakeComparison') ? currentIndex === 4 : currentIndex === 3) ? (
                <IntroductionWrapper>
                    <HeaderWrapper>
                        <StyledDivBold Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                            {t('PageIntakeIntroduction.InstructionsTextHeader')}
                        </StyledDivBold>

                        <StyledDivBold Position='static' Width='auto' Height='auto' Color='#C6CBCB' FontSize='20px' LineHeight='20px'>
                            {t('PageIntakeIntroduction.Instructions4TextSubHeader')}
                        </StyledDivBold>
                    </HeaderWrapper>

                    <ExampleWrapper>
                        <ExamplePointWrapper>
                            <ExamplePointGifWrapper>
                                <img src={GifMoveMainProcess} style={{width: '100%', height: 'auto'}} alt=''/>
                            </ExamplePointGifWrapper>

                            <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                                {t('PageIntakeIntroduction.Instructions4TextPoint1')}
                            </StyledDivRegular>
                        </ExamplePointWrapper>

                        <ExamplePointWrapper>
                            <ExamplePointGifWrapper>
                                <img src={GifMoveSubProcess} style={{width: '100%', height: 'auto'}} alt=''/>
                            </ExamplePointGifWrapper>

                            <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                                {t('PageIntakeIntroduction.Instructions4TextPoint2')}
                            </StyledDivRegular>
                        </ExamplePointWrapper>
                    </ExampleWrapper>

                    <TipWrapper>
                        <IconLightbulb/>

                        <TipPointTextWrapper>
                            <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='16px' LineHeight='20px' FontWeight='700' LetterSpacing='0.3px'>
                                {t('PageIntakeIntroduction.InstructionsTipTextHeader')}
                            </StyledDivRegular>

                            <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='16px' LineHeight='40px'>
                                {t('PageIntakeIntroduction.Instructions4TipTextBody1')}
                                <IconArrowLeftKey style={{margin: '0 0 -15px 0'}}/>
                                {t('PageIntakeIntroduction.Instructions4TipTextBody2')}
                                <IconArrowRightKey style={{margin: '0 0 -15px 0'}}/>
                                {t('PageIntakeIntroduction.Instructions4TipTextBody3')}
                                <IconArrowUpKey style={{margin: '0 0 -15px 0'}}/>
                                {t('PageIntakeIntroduction.Instructions4TipTextBody4')}
                                <IconArrowDownKey style={{margin: '0 0 -15px 0'}}/>
                                {t('PageIntakeIntroduction.Instructions4TipTextBody5')}
                            </StyledDivRegular>
                        </TipPointTextWrapper>
                    </TipWrapper>
                </IntroductionWrapper>
            ) : ((props.identifier === 'IntakeBuilderLeader' || props.identifier === 'IntakeComparison') ? currentIndex === 5 : currentIndex === 4) ? (
                <IntroductionWrapper>
                    <HeaderWrapper>
                        <StyledDivBold Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                            {t('PageIntakeIntroduction.InstructionsTextHeader')}
                        </StyledDivBold>

                        <StyledDivBold Position='static' Width='auto' Height='auto' Color='#C6CBCB' FontSize='20px' LineHeight='20px'>
                            {t('PageIntakeIntroduction.Instructions5TextSubHeader')}
                        </StyledDivBold>
                    </HeaderWrapper>

                    <ExampleWrapper>
                        <ExamplePointWrapper>
                            <ExamplePointGifWrapper>
                                <img src={GifDeleteMainProcess} style={{width: '100%', height: 'auto'}} alt=''/>
                            </ExamplePointGifWrapper>

                            <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                                {t('PageIntakeIntroduction.Instructions5TextPoint1')}
                            </StyledDivRegular>
                        </ExamplePointWrapper>

                        <ExamplePointWrapper>
                            <ExamplePointGifWrapper>
                                <img src={GifDeleteSubProcess} style={{width: '100%', height: 'auto'}} alt=''/>
                            </ExamplePointGifWrapper>

                            <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                                {t('PageIntakeIntroduction.Instructions5TextPoint2')}
                            </StyledDivRegular>
                        </ExamplePointWrapper>
                    </ExampleWrapper>

                    <TipWrapper>
                        <IconLightbulb/>

                        <TipPointTextWrapper>
                            <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='16px' LineHeight='20px' FontWeight='700' LetterSpacing='0.3px'>
                                {t('PageIntakeIntroduction.InstructionsTipTextHeader')}
                            </StyledDivRegular>

                            <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='16px' LineHeight='40px'>
                                {t('PageIntakeIntroduction.Instructions5TipTextBody1')}
                                <IconBackspaceKey style={{margin: '0 0 -15px 0'}}/>
                                {t('PageIntakeIntroduction.Instructions5TipTextBody2')}
                                <IconDeleteKey style={{margin: '0 0 -15px 0'}}/>
                                {t('PageIntakeIntroduction.Instructions5TipTextBody3')}
                            </StyledDivRegular>
                        </TipPointTextWrapper>
                    </TipWrapper>
                </IntroductionWrapper>
            // ) : (props.identifier === 'IntakeBuilderLeader' ? currentIndex === 5 : false) ? (
            //     <IntroductionWrapper>
            //         <HeaderWrapper>
            //             <StyledDivBold Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
            //                 {t('PageIntakeIntroduction.TemplatesTextHeader')}
            //             </StyledDivBold>

            //             <StyledDivBold Position='static' Width='auto' Height='auto' Color='#C6CBCB' FontSize='20px' LineHeight='20px'>
            //                 {t('PageIntakeIntroduction.TemplatesTextSubHeader')}
            //             </StyledDivBold>
            //         </HeaderWrapper>

            //         <SelectWrapper>
            //             <SelectPointWrapper onClick={() => selectTemplate('BlankProcess')} isSelected={selectedTemplate === 'BlankProcess'}>
            //                 <SelectPointImgWrapper>
            //                     <img src={ImageBlankProcess} style={{width: 'auto', height: '100%'}} alt=''/>
            //                 </SelectPointImgWrapper>

            //                 <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
            //                     {t('PageIntakeIntroduction.TemplatesTextPoint1')}
            //                 </StyledDivRegular>
            //             </SelectPointWrapper>

            //             <SelectPointWrapper onClick={() => selectTemplate('StandardProcess')} isSelected={selectedTemplate === 'StandardProcess'}>
            //                 <SelectPointImgWrapper>
            //                     <img src={ImageStandardProcess} style={{width: 'auto', height: '100%'}} alt=''/>
            //                 </SelectPointImgWrapper>

            //                 <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
            //                     {t('PageIntakeIntroduction.TemplatesTextPoint2')}
            //                 </StyledDivRegular>
            //             </SelectPointWrapper>
            //         </SelectWrapper>
            //     </IntroductionWrapper>
            ) : (
                <IntroductionWrapper>
                    <StyledDivBold Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                        {t('PageIntakeIntroduction.WelcomeTextHeader1', {userName: context.setFirstName(currentUserData[0]['first_name'])})}
                    </StyledDivBold>

                    <StyledDivRegular Position='static' Width='auto' Height='auto'Color='#FCFCFC' FontSize='18px' LineHeight='28px' >
                            <>
                                {(props.identifier === 'IntakeBuilderLeader') ? (
                                    <Trans i18nKey="PageIntakeIntroduction.WelcomeBuilderLeaderTextBody">
                                        You are about to lay the foundation for your company's business process structure. Begin by outlining the main processes and the sub-processes that are essential to your business. Once satisfied, click <b>Next</b> to select your team representatives and share your version with them. They will contribute their insights, tailoring the structure to better suit their departments. Later, you will collectively review and combine different versions into a final structure.
                                    </Trans>
                                ) : (props.identifier === 'IntakeComparison') ? (
                                    <Trans i18nKey="PageIntakeIntroduction.WelcomeComparisonTextBody">
                                        You and your team representatives have successfully outlined your versions of the business process structure. Now, it's time to collectively compare each version, carefully identifying the similarities and discussing the reasons behind specific choices. Your goal is to agree on the final business process structure that accurately reflects the insights of the entire team. Once satisfied, click <b>Next</b> to specify the duration for your main processes and share your structure with Elephants in the Room.
                                    </Trans>
                                ) : (
                                    <Trans i18nKey="PageIntakeIntroduction.WelcomeBuilderRepresentativeTextBody">
                                        One of your colleagues has just outlined the initial version of your company’s business process structure. Your role now is to contribute your insights, tailoring the structure to better suit your department. Once satisfied, click <b>Publish</b> to share your team’s version with other team representatives. When the representatives do the same, you will collectively review and combine different versions into a final structure.
                                    </Trans>
                                )}
                            </>
                    </StyledDivRegular>

                    <StyledDivBold Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='20px' LineHeight='20px'>
                        {t('PageIntakeIntroduction.WelcomeTextHeader2')}
                    </StyledDivBold>

                    {(props.identifier === 'IntakeComparison') ? (
                        <FocusWrapper>
                            <PointWrapper>
                                <IconChecklist/>

                                <PointTextWrapper>
                                    <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' FontWeight='700' LetterSpacing='0.3px'>
                                        {t('PageIntakeIntroduction.WelcomeTextPoint3')}
                                    </StyledDivRegular>

                                    <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#C6CBCB' FontSize='18px' LineHeight='28px'>
                                        {t('PageIntakeIntroduction.WelcomeTextDescription3')}
                                    </StyledDivRegular>
                                </PointTextWrapper>
                            </PointWrapper>

                            <PointWrapper>
                                <IconPresentation/>

                                <PointTextWrapper>
                                    <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' FontWeight='700' LetterSpacing='0.3px'>
                                        {t('PageIntakeIntroduction.WelcomeTextPoint4')}
                                    </StyledDivRegular>

                                    <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#C6CBCB' FontSize='18px' LineHeight='28px'>
                                        {t('PageIntakeIntroduction.WelcomeTextDescription4')}
                                    </StyledDivRegular>
                                </PointTextWrapper>
                            </PointWrapper>
                        </FocusWrapper>
                    ) : (
                        <FocusWrapper>
                            <PointWrapper>
                                <IconTarget/>

                                <PointTextWrapper>
                                    <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' FontWeight='700' LetterSpacing='0.3px'>
                                        {t('PageIntakeIntroduction.WelcomeTextPoint1')}
                                    </StyledDivRegular>

                                    <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#C6CBCB' FontSize='18px' LineHeight='28px'>
                                        {t('PageIntakeIntroduction.WelcomeTextDescription1')}
                                    </StyledDivRegular>
                                </PointTextWrapper>
                            </PointWrapper>

                            <PointWrapper>
                                <IconOverlap/>

                                <PointTextWrapper>
                                    <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#FCFCFC' FontSize='18px' LineHeight='28px' FontWeight='700' LetterSpacing='0.3px'>
                                        {t('PageIntakeIntroduction.WelcomeTextPoint2')}
                                    </StyledDivRegular>

                                    <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#C6CBCB' FontSize='18px' LineHeight='28px'>
                                        {t('PageIntakeIntroduction.WelcomeTextDescription2')}
                                    </StyledDivRegular>
                                </PointTextWrapper>
                            </PointWrapper>
                        </FocusWrapper>
                    )}
                </IntroductionWrapper>
            )}

            <IntroductionButtonContainer Bottom='40px'>
                {(currentIndex > 0) && (
                    <IntroductionButtonWrapper onClick={showPreviousPage} Width={'auto'} Padding={'16px'} Color={'#E3E5E4'}>
                        <IconChevronLeft/>
                    </IntroductionButtonWrapper>
                )}

                {(currentIndex === indexes.length - 1) ? (
                    <IntroductionButtonWrapper onClick={getStarted} /* isDisabled={props.identifier === 'IntakeBuilderLeader' && selectedTemplate.trim().length === 0} */>
                        <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#FCFCFC' FontWeight='700' LetterSpacing='0.3px'>
                            {t('PageIntakeIntroduction.TextButtonGetStarted')}
                        </StyledDivRegular>
                    </IntroductionButtonWrapper>
                ) : (
                    <IntroductionButtonWrapper onClick={showNextPage} isDisabled={props.identifier === 'IntakeBuilderLeader' && currentIndex === 1 && !componentClicked}>
                        <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#FCFCFC' FontWeight='700' LetterSpacing='0.3px'>
                            {t('PageIntakeIntroduction.TextButtonNext')}
                        </StyledDivRegular>
                    </IntroductionButtonWrapper>
                )}
            </IntroductionButtonContainer>
        </IntroductionContainer>
    );
}

export default IntroductionComponent;

const IntroductionContainer = styled.div`
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(39, 27, 54, 0.75);
    backdrop-filter: blur(12.5px);
    opacity: ${props => props.isShowingIntroduction ? '1' : '0'};
    visibility: ${props => props.isShowingIntroduction ? 'visible' : 'hidden'};
    z-index: ${props => props.isShowingIntroduction ? '49' : '-1'};
    transition: opacity 0.8s, visibility 0.8s, z-index 0.8s;
`;

const IntroductionWrapper = styled.div`
    display: flex;
    width: calc(100% - 80px);
    max-width: 1290px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 40px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ExampleWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const ExamplePointWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`;

const ExamplePointGifWrapper = styled.div`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
`;

const TipWrapper = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  border: 2px solid #FCFCFC;
  background: rgba(227, 229, 228, 0.25);
`;

const TipPointTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

// const SelectWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   gap: 20px;
// `;

// const SelectPointWrapper = styled.div`
//   display: flex;
//   padding: 20px;
//   flex-direction: column;
//   align-items: center;
//   gap: 20px;
//   border-radius: 8px;
//   background: rgba(227, 229, 228, 0.25);
//   filter: ${props => props.isSelected ? 'brightness(85%)' : ''};
//   scale: ${props => props.isSelected ? '0.95' : '1'};
//   transition: filter 0.15s, scale 0.3s;
//   user-select: none;
//   cursor: pointer;

//   &:hover {
//     filter: ${props => props.isSelected ? '' : 'brightness(85%)'};;
//   }
// `;

// const SelectPointImgWrapper = styled.div`
//   display: flex;
//   height: 276.88px;
//   padding: 40px;
//   border-radius: 8px;
//   background: #F6F7FA;
//   overflow: hidden;
// `;

const FocusWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 160px;
  row-gap: 20px;
`;

const PointWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
`;

const PointTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
`;

const IntroductionButtonContainer = styled.div`
    position: absolute;
    top: ${props => props.Top || ''};
    bottom: ${props => props.Bottom || ''};
    display: flex;
    width: calc(100% - 80px);
    max-width: 1290px;
    justify-content: flex-end;
    gap: 8px;

    @media (max-width: 1370px) {
        right: 40px;
    }
`;

const IntroductionButtonWrapper = styled.div`
    display: flex;
    width: ${props => props.Width || '118px'};
    padding: ${props => props.Padding || '16px 24px'};
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: ${props => props.Color || '#E2336B'};
    opacity: ${props => props.isDisabled ? '0.5' : ''};
    transition: opacity 0.3s, filter 0.15s;
    cursor: ${props => props.isDisabled ? '' : 'pointer'};
    user-select: none;

    &:hover {
        filter: ${props => props.isDisabled ? '' : 'brightness(90%)'};
    }
`;