import React, { useEffect} from 'react';
import axios from 'axios';
import styled from "styled-components";
import {ReactComponent as Frame} from '../assets/userintro/Logo_frame_902px450px.svg';
import {ReactComponent as FrameTopWiggle} from '../assets/userintro/FrameTopWiggle.svg';
import {ReactComponent as Logo }from '../assets/userintro/Logo_frame.svg';
import { StyledDivBold, StyledDivRegular } from '../layout-and-styling/standardized-components-library/Styling-TextInput';
import ComponentFooter from '../layout-and-styling/standardized-components-library/Component-Footer';
import {HeightFloatToString} from '../layout-and-styling/standardized-components-library/Component-Responsive';
import {Trans, useTranslation} from "react-i18next";
import i18n from "../i18n";

const CurrentUserData = JSON.parse(localStorage.getItem('current_user_data'));


const Logout = ( componentprops ) => {
    const {t} = useTranslation();
/** Page to thank the user for filling in the survey and what kind of results to expect */

    /** Check if already authenticated, and bring to dashboard if possible.*/
    useEffect(() => {
        const axiosInstance = axios.create({withCredentials: true})
        // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
        .catch((error) => {
        window.location.replace(process.env.REACT_APP_APPURL+'/login');
        })
    }, []);

    return (
        <Container> 
            <LeftContainer>

                <FrameWrapper>

                    <StyledDivRegular MarginTop='0px' MarginLeft='367px' Width='200px' FontSize='18px'
                                    LineHeight='28px'>
                        <FrameTopWiggle/>
                    </StyledDivRegular>

                    <BottomWiggle MarginLeft='24px'/>

                    <LogoWrapper MarginLeft='65px'>
                        <Logo/>
                    </LogoWrapper>

                    <LeftTextWrapper MarginTop={HeightFloatToString(322 / 852)} MarginLeft='65px'>
                        <Trans i18nKey="PageSurveyCompleted.SidebarText">
                            Together we take your organization to <span>the next level</span>.
                        </Trans>
                    </LeftTextWrapper>

                    <IntroFrameBackground Height="100%"/>

                </FrameWrapper>

            </LeftContainer>
    
            <RightContainer>
                <StyledDivBold MarginTop='286px' MarginLeft='170px' Width='1080px' FontSize='42px' LineHeight='52px'>
                    <Trans i18nKey="Logout.Thanks">
                    Thank you for your time
                    </Trans> {CurrentUserData[0]['first_name']}!
                </StyledDivBold> 
                
                <StyledDivRegular MarginTop='378px' MarginLeft='170px' Width='950px' FontSize='24px' LineHeight='36px'>
                    <Trans i18nKey="Logout.Sure">
                    Are you sure you want to logout?
                    </Trans>
                </StyledDivRegular>


                <ComponentFooter page = 'Logout' 
                    nextdirectory = '/dashboard'
                    ButtonText='Logout'
                    MarginTop='480px' MarginLeft='170px' Width='200px' Height='56px' BorderRadius='8px' />
                        
            </RightContainer>
                
       </Container> 
 
     );
 };
 export default Logout;

const Container = styled.div`
  background: #FCFCFC;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: calc(1 / 1.75);
  }

`;

const LeftContainer = styled.div`
    width: 500px;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    background: #FCFCFC;
`;

const FrameWrapper = styled.div`
  position: fixed;
  z-index: 1;
  height: 94.666667%;
  margin: 0 25px;
`;

const BottomWiggle = styled.div`
  position: ${props => props.Position || 'fixed'};
  bottom: 2.66667%;
  margin-left: ${props => props.MarginLeft || "0%"};
  width: 100px;
  height: 100px;
  background: #e2336B;
  border-radius: 0 100px 0 0;
  -moz-border-radius: 0 100px 0 0;
  -webkit-border-radius: 0 100px 0 20px;
  flex: 1;
  vertical-align: text-bottom;

`;

const IntroFrameBackground = styled.div`
  margin-left: 24px;
  width: ${props => props.Width || "450px"};
  height: ${props => props.Height || "852px"};
  background: #271B36;
  border-radius: 20px;
`;

const LogoWrapper = styled.div`
    position: absolute;
    margin-top: 40px;
    margin-left: 40px;
    z-index: 2;
`;

const LeftTextWrapper = styled.div`
    position: absolute;
    margin-top: ${props => props.MarginTop || "0%"};
    margin-left: ${props => props.MarginLeft || "40px"};
    width: 360px;
    z-index: 2;
    font-family: 'Montserrat';
    font-size: 42px;
    line-height: 52px;
    font-weight: 700;
    color: #FCFCFC;

    span {
        color: #e2336B
    }
`;

const RightContainer = styled.div`
    width: 100%;
    background-color: #FCFCFC;
    height: 100%;
    display: flex;
    flex-direction: column;
`;