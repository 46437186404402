import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import TransformationsSolved from './specific-components/TransformationsSolved';
import TransformationsEmerged from './specific-components/TransformationsEmerged';
import MetricsChangesBar from './specific-components/MetricsChangesBar';
import ElephantsStatistics from './specific-components/ElephantsStatistics';
import ProblemsPerYear from './specific-components/ProblemsPerYear';


const PageTransformations = () => {

  const { t, i18n } = useTranslation();

  const colors = ["#0180B8", "#61A5C2", "#81C5E3", "#FFA62B"]
  let colorIndex = 0;

  const [totalProblems, setTotalProblems] = useState({})
  const [surveyDates, setSurveyDates] = useState([])
  const [solvedProblems, setSolvedProblems] = useState([])
  const [unsolvedProblems, setUnsolvedProblems] = useState([])
  const [newProblems, setNewProblems] = useState([])
  const [problemColors, setProblemColors] = useState({})
  const [metricsData, setMetricsData] = useState([])
  const [graphData, setGraphData] = useState([])

  function formatDate(inputDateString) {
    const [month, year] = inputDateString.split('/');
    const date = new Date(`${month}/01/${year}`);

    const options = { month: 'long', year: 'numeric' };
    const formattedString = date.toLocaleString('en-US', options);

    return formattedString;
  }

  /* Adding a listener that runs when graph data is sorted in the local storage */
  useEffect(() => {
    window.addEventListener('transformation_data_event', () => {
      const dates = [];
      const transformationData = JSON.parse(localStorage.getItem("transformation_data"))

      transformationData["company_data"]["Dates"].forEach(date => {
        dates.push(formatDate(date))
      })
      setSurveyDates(dates)

      setMetricsData([])
      Object.entries(transformationData["company_data"]).forEach(([key, value]) => {
        if (key != "Dates") {
          const metric = {
            name: key,
            ...value
          }
          const metricsCopy = metricsData;
          metricsCopy.push(metric)
          setMetricsData(metricsCopy)
        }
      })

      const oldProblems = transformationData["old_elephants_data"]
      const newProblems = transformationData["new_elephants_data"]
      const allProblems = { ...oldProblems, ...newProblems }
      Object.values(allProblems).forEach(problem => {
        const currentColor = colors[colorIndex];
        problem["color"] = currentColor
        colorIndex = (colorIndex + 1) % colors.length;
      })
      setTotalProblems(allProblems)
    })
  }, [])



  useEffect(() => {
    setSolvedProblems(Object.keys(totalProblems).filter(problem => totalProblems[problem].isResolved).map(problem => { return { name: problem, ...totalProblems[problem] } }))
    setUnsolvedProblems(Object.keys(totalProblems).filter(problem => !totalProblems[problem].isResolved).map(problem => { return { name: problem, ...totalProblems[problem] } }))
    setNewProblems(Object.keys(totalProblems).filter(problem => totalProblems[problem].isNew))

    const dataColors = {};

    // Preparing graph data
    const data = Object.keys(totalProblems).map(problem => {
      const problemData = { data: [] }
      surveyDates.forEach(date => {
        const problemPercentage = totalProblems[problem].votePercentage.find(vote => formatDate(vote.survey) === date)?.percentage
        if (problemPercentage !== undefined) {
          problemData["key"] = totalProblems[problem].key
          problemData["name"] = problem
          problemData["data"].push({ category: date, value: problemPercentage })
        }
        dataColors[totalProblems[problem].key] = totalProblems[problem].color;
      })
      return problemData
    })
    setProblemColors(dataColors)
    setGraphData(data)
  }, [totalProblems])

  return (
    <Container>

      <FirstRowContainer>
        <ProblemsOverviewContainer>
          <TransformationsSolved totalProblems={3} solvedProblems={solvedProblems.length} surveyDates={surveyDates} />
          <TransformationsEmerged newProblems={newProblems.length} surveyDates={surveyDates} />
        </ProblemsOverviewContainer>
        <ElephantsStatistics solvedProblems={solvedProblems} unsolvedProblems={unsolvedProblems} colors={problemColors} data={graphData} />
      </FirstRowContainer>

      <SecondRowContainer>
        <MetricsChangesBar surveyDates={surveyDates} metricsData={metricsData} />
        <ProblemsPerYear totalProblems={totalProblems} surveyDates={surveyDates} />
      </SecondRowContainer>

    </Container>
  )
}

export default PageTransformations;

const Container = styled.div`
  font-family: 'Overpass', sans-serif;
  width: calc(100%);
  display: block;
  z-index: 1;
  margin-top: 24px;
  margin-left: 42px;
  margin-right: 42px;
`;

const FirstRowContainer = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 16px;
`;

const SecondRowContainer = styled.div`
  width: 100%;
  display: flex;
`;

const ProblemsOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;