import React, {useContext, useState, useEffect, useRef} from "react";
import styled from "styled-components";
import * as d3 from "d3";
import { OverlayContext } from "../../layout-and-styling/context-hooks/OverlayContext";
import {StyledDivButton, StyledDivRegular} from "../../layout-and-styling/standardized-components-library/Styling-TextInput";
import ZoomInButton from "../../assets/dashboard/ZoomInButton.png";
import ZoomOutButton from "../../assets/dashboard/ZoomOutButton.png";
import DashboardSocialNetworkComponent from "./components/Dashboard-social-network-component";
import load from "../../assets/dashboard/load.gif";
import {DashboardContext} from "../DashboardContext";
import {useTranslation} from 'react-i18next';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import {ReactComponent as IconExpand} from "../../assets/dashboard/IconExpand.svg";
export var vis = [];


var polygon_btn_click = false
var currentZone = "";
var currentLeaders = "";
var polygon_btn_click = false
    
const DashboardSocialNetwork = () => {
    const context = useContext(OverlayContext);
    const {t, i18n} = useTranslation();
    
    const [hasSelectionAreas, setHasSelectionAreas] = useState(false);
    const [centerPointOfAllSelectionAreas, setCenterPointOfAllSelectionAreas] = useState(null);

    /** The useRef Hook creates a variable that "holds on" to a value across rendering passes.
     * In this case it will hold our component's SVG DOM element.
     * It's initialized null and React will assign it later (see the return statement) */

    const [survey, setSurvey] = useState(JSON.parse(localStorage.getItem("selected_survey")));
    
    /** States to control graph buttons styling */
    const [zoom, setZoom] = useState(
        survey == 2 ? 0.62
        : survey == 3 ? 0.55
        : survey == 5 ? 0.40
        : survey == 8 ? 0.52
        : survey == 9 ? 0.56
        : 1);
    const [graphWidth, setGraphWidth] = useState(
        survey == 2 ? 2465
        : survey == 3 ? 2782
        : survey == 5 ? 3770
        : survey == 8 ? 2916
        : survey == 9 ? 3286 
        : 0);
    const [graphHeight, setGraphHeight] = useState(
        survey == 2 ? 2465
        : survey == 3 ? 2782
        : survey == 5 ? 3770
        : survey == 8 ? 2916
        : survey == 9 ? 3286 
        : 0);

    const [zone, setZone] = useState("");
    const [leaders, setLeaders] = useState("");
    const [hoverButtonZone, setHoverButtonZone] = useState("");
    const [hoverLeader, setHoverLeader] = useState("");
    
    const [legendExpanded, expandLegend] = React.useState(false);
    const [expandedElephant, setExpandedElephant] = useState(-1);
    const [clickedElephant, setClickedElephant] = useState(-1);
    const [hoverToolbox, setHoverToolbox] = useState(false);
    const [toolboxClicked, setToolboxClicked] = useState(false);
    
    const [graphData, setGraphData] = useState(JSON.parse(localStorage.getItem("graph_data")));
   
    const dashboard_context_value = useContext(DashboardContext);

    /** Adding a listener that runs when graph data is sotred in the local storage */
    useEffect(() => {      
        window.addEventListener('graph_data_event', () => {
            setGraphData(JSON.parse(localStorage.getItem("graph_data")));
            dashboard_context_value.setOpenGraph(true);
        })
    }, [])

    //rerendering helps team legend rerender?...where to trigger this new render, not finished
    const [render, setRender] = useState(true);
    const reRender = () => {
      setRender(!render);
    };


    function createCircles(button, ids) {
        if(polygon_btn_click && vis[0].HasSelectionAreas()){
            vis[0].RemoveSelectionAreas(ids)
            setCenterPointOfAllSelectionAreas(null)

        }
        polygon_btn_click = !polygon_btn_click

        vis[0].GeneratePolygonsForSelection(ids);
        let centerPointCoordinates = vis[0].GetCenterPointForSelectionArea()
        if(!hasSelectionAreas){
            setHasSelectionAreas(true);
        }
        else{
            setHasSelectionAreas(false);
        }
        setCenterPointOfAllSelectionAreas(centerPointCoordinates)

    }

    function zoneButtons(button) {
        if (button !== zone) {
            setZone(button);
            vis[0].zones(button);
            d3.select('.ColorScale').style('display', 'flex');
            currentZone = button
        } else {
            setZone("");
            vis[0].zones("");
            d3.select('.ColorScale').style('display', 'none');
            currentZone = ""
        }
        
    }

    function leaderButtons(button) {

        if (button !== leaders) {
            setLeaders(button);
            vis[0].highlightNodes(button);
            currentLeaders = button
        } else {
            setLeaders("");
            vis[0].highlightNodes("");
            currentLeaders = ""
        }
        
    }

    function hoverZoneButtons(button) {
        if (button !== zone) {
            setHoverButtonZone(button);
        } else {
            setHoverButtonZone("");
        }
    }

    function hoverLeadersButtons(button) {
        if (button !== leaders) {
            setHoverLeader(button);
        } else {
            setHoverLeader("");
        }
    }

    function handleClickedElephant(elephant, ids) {
        if (elephant !== clickedElephant) {
            setClickedElephant(elephant);
            vis[0].involved_people(ids);
        } else {
            setClickedElephant(-1);
            vis[0].involved_people([]);
        }
    }

    function handleZoomIn() {
        // setZoom(zoom + 0.125);
        // vis[0].update_node_size(zoom, currentZone);
        // if(currentZone!=""){
        //     vis[0].zones(currentZone)
        // }
    }

    function handleZoomOut() {
        // if (zoom > 0.2) {
        //     setZoom(zoom - 0.125)
        //     vis[0].update_node_size(zoom, currentZone);
        //     if(currentZone!=""){
        //         vis[0].zones(currentZone)
        //     }
        // }
    }

    const handleAccordions = (panel) => (event, isExpanded) => {
        if(panel === 'panel1') expandLegend(isExpanded ? panel : false);
    };

    return (
        <Container>
            
            {!graphData ? (
                <LoadLogo>
                    {" "}
                    <img src={load} alt="loading..."/>
                </LoadLogo>
            ) : (
                <>
                    <ElephantsWrapper>
                        {graphData.elephants.map((item, index) => {
                            return(
                                <CriticalProblemContainer Width={clickedElephant === index || expandedElephant == index ? "fit-content" : "24px"} PaddingLeft={clickedElephant === index || expandedElephant == index ? "24px" : "0px"} Border={clickedElephant === index ? "1px solid #E2336B" : ""}
                                                            onMouseOver={() => setExpandedElephant(index)} onMouseLeave={() => setExpandedElephant(-1)} onClick={() => handleClickedElephant(index, item.employees)}>
                                    <CriticalProblemNumber>
                                        {index + 1}
                                    </CriticalProblemNumber>

                                    <CriticalProblemName Display={clickedElephant === index || expandedElephant === index ? "flex" : "none"}>
                                        {context.setProcess(item.elephant.toString(), i18n.language)}
                                    </CriticalProblemName>

                                    <CriticalProblemButton Display={clickedElephant === index || expandedElephant === index ? "flex" : "none"} Background={clickedElephant === index ? "#E2336B" : "#F1F3F4"} Color={clickedElephant === index ? "#FCFCFC" : "#271B36"}>
                                        {t('DashboardFollowUp.Analysis')}
                                    </CriticalProblemButton>
                                </CriticalProblemContainer>
                            )
                        })}                    
                    </ElephantsWrapper>
                    <ToolboxWrapper>
                        <ToolboxButton Border={hoverToolbox === 'Impact' ? '2px solid #E2336B' : '0px solid #271B36'} 
                                onClick={() => setToolboxClicked(!toolboxClicked)} onMouseOver={() => setHoverToolbox(true)} onMouseLeave={() => setHoverToolbox(false)}>
                            {t('DashboardSocialNetwork.ToolBox')}
                        </ToolboxButton>

                        <ToolboxDetails Display={toolboxClicked ? "flex" : "none"}  Width={toolboxClicked ? "fit-content" : "100px"} Height={toolboxClicked ? "fit-content" : "100px"}>
                            <ScoresAttributesWrapper>
                                <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontWeight="700">
                                    {t('DashboardSocialNetwork.CompanyAverages')}
                                </StyledDivRegular>
                                <ScoreWrapper>
                                    <CollaborationWrapper>
                                        <LeftIconText>
                                            {t('DashboardSocialNetwork.Collaboration')}
                                        </LeftIconText>
                                        <CollaborationScoreWrapper/>
                                    </CollaborationWrapper>

                                    <SeparatingLine1/>

                                    <BenchmarkWrapper>
                                        <LeftIconText>
                                        {t('DashboardSocialNetwork.CollaborationBenchmark')}
                                        </LeftIconText>
                                        <BenchmarkScoreWrapper/>
                                    </BenchmarkWrapper>

                                </ScoreWrapper>
                            </ScoresAttributesWrapper>

                            <ZonesWrapper>
                                <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontWeight="700">
                                    {t('DashboardSocialNetwork.Metrics')}
                                </StyledDivRegular>
                                <ZonesButtons>
                                    <StyledDivButton MarginTop='0px' MarginLeft='0px' Width='180px' Height='48px' BoxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)' 
                                                    Padding='8px 4px 8px 4px' FontSize='16px' Display='flex' JustifyContent='center' Position="relative" BackgroundColor={zone === 'Impact' ? '#E2336B' : '#FCFCFC'}
                                                    Border={hoverButtonZone === 'Impact' ? '2px solid #E2336B' : '0px solid #271B36'} Color={zone === 'Impact' ? '#FCFCFC' : '#271B36'}>

                                        <button onClick={() => zoneButtons("Impact")} onMouseOver={() => hoverZoneButtons("Impact")} onMouseLeave={() => hoverZoneButtons("")}>
                                            {t('DashboardSocialNetwork.Impact')}
                                        </button>
                                    </StyledDivButton>
                                    <StyledDivButton MarginTop='0px' MarginLeft='0px' Width='180px' Height='48px' BoxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)' Padding='8px 4px 8px 4px'
                                                    FontSize='16px' Display='flex' JustifyContent='center'  Position="relative" BackgroundColor={zone === 'Pleasantness' ? '#E2336B' : '#FCFCFC'} 
                                                    Border={hoverButtonZone === 'Pleasantness' ? '2px solid #E2336B' : '0px solid #271B36'} Color={zone === 'Pleasantness' ? '#FCFCFC' : '#271B36'}>

                                        <button onClick={() => zoneButtons("Pleasantness")} onMouseOver={() => hoverZoneButtons("Pleasantness")} onMouseLeave={() => hoverZoneButtons("")}>
                                            {t('DashboardSocialNetwork.Pleasantness')}
                                        </button>
                                    </StyledDivButton>
                                    <StyledDivButton MarginTop='0px' MarginLeft='0px' Width='180px' Height='48px' BoxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)' Padding='8px 4px 8px 4px'
                                                    FontSize='16px' Display='flex' JustifyContent='center'  Position="relative" BackgroundColor={zone === 'DrivingForce' ? '#E2336B' : '#FCFCFC'} 
                                                    Border={hoverButtonZone === 'DrivingForce' ? '2px solid #E2336B' : '0px solid #271B36'} Color={zone === 'DrivingForce' ? '#FCFCFC' : '#271B36'}>

                                        <button onClick={() => zoneButtons("DrivingForce")} onMouseOver={() => hoverZoneButtons("DrivingForce")} onMouseLeave={() => hoverZoneButtons("")}>
                                            {t('DashboardSocialNetwork.DrivingForce')}
                                        </button>
                                    </StyledDivButton>
                                </ZonesButtons>
                            </ZonesWrapper>
                            <LeadersWrapper>
                                <StyledDivRegular Position="relative" MarginLeft="0px" Width="fit-content" MarginBottom="0px" FontWeight="700">
                                    {t('DashboardSocialNetwork.SeeLeaders')}
                                </StyledDivRegular>
                                <LeadersButtons>
                                    <StyledDivButton MarginTop='0px' MarginLeft='0px' Width='180px' Height='48px' BoxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)' 
                                                    Padding='8px 4px 8px 4px' FontSize='16px' Display='flex' JustifyContent='center' Position="relative" BackgroundColor={leaders === 'FormalLeaders' ? '#E2336B' : '#FCFCFC'}
                                                    Border={hoverLeader === 'FormalLeaders' ? '2px solid #E2336B' : '0px solid #271B36'} Color={leaders === 'FormalLeaders' ? '#FCFCFC' : '#271B36'}>

                                        <button onClick={() => leaderButtons("FormalLeaders")} onMouseOver={() => hoverLeadersButtons("FormalLeaders")} onMouseLeave={() => hoverLeadersButtons("")}>
                                            {t('DashboardSocialNetwork.FormalLeaders')}
                                        </button>
                                    </StyledDivButton>
                                    <StyledDivButton MarginTop='0px' MarginLeft='0px' Width='180px' Height='48px' BoxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)' Padding='8px 4px 8px 4px'
                                                    FontSize='16px' Display='flex' JustifyContent='center'  Position="relative" BackgroundColor={leaders === 'ActualLeaders' ? '#E2336B' : '#FCFCFC'} 
                                                    Border={hoverLeader === 'ActualLeaders' ? '2px solid #E2336B' : '0px solid #271B36'} Color={leaders === 'ActualLeaders' ? '#FCFCFC' : '#271B36'}>

                                        <button onClick={() => leaderButtons("ActualLeaders")} onMouseOver={() => hoverLeadersButtons("ActualLeaders")} onMouseLeave={() => hoverLeadersButtons("")}>
                                            {t('DashboardSocialNetwork.ActualLeaders')}
                                        </button>
                                    </StyledDivButton>
                                    <StyledDivButton MarginTop='0px' MarginLeft='0px' Width='180px' Height='48px' BoxShadow='0px 2px 4px rgba(0, 0, 0, 0.25)' Padding='8px 4px 8px 4px'
                                                    FontSize='16px' Display='flex' JustifyContent='center'  Position="relative" BackgroundColor={leaders === 'PotentialLeaders' ? '#E2336B' : '#FCFCFC'} 
                                                    Border={hoverLeader === 'PotentialLeaders' ? '2px solid #E2336B' : '0px solid #271B36'} Color={leaders === 'PotentialLeaders' ? '#FCFCFC' : '#271B36'}>

                                        <button onClick={() => leaderButtons("PotentialLeaders")} onMouseOver={() => hoverLeadersButtons("PotentialLeaders")} onMouseLeave={() => hoverLeadersButtons("")}>
                                            {t('DashboardSocialNetwork.PotentialLeaders')}
                                        </button>
                                    </StyledDivButton>
                                </LeadersButtons>
                            </LeadersWrapper>
                            
                        </ToolboxDetails>
                    </ToolboxWrapper>
                </>
                
            )}

            {graphData ? (
                <>
                    <DashboardSocialNetworkComponent id="DashboardSocialNetworkComponent" graphData={graphData} Zoom={zoom} setZoom={setZoom} graphWidth={graphWidth} setGraphWidth={setGraphWidth}
                        graphHeight={graphHeight} setGraphHeight={setGraphHeight} has_selection_areas={hasSelectionAreas} 
                        center_point_of_selection_areas ={centerPointOfAllSelectionAreas} language={i18n.language} Comparison={false} />


                    <LegendZoomWrapper>
                        <ScaleColorWrapper>
                            {t('DashboardSocialNetwork.Low')}
                            <Color MarginLeft="12px" Color="#B3194A"/>
                            <Color Color="#F72B2B"/>
                            <Color Color="#FFBC1F"/>
                            <Color Color="#89F066"/>
                            <Color MarginRight="12px" Color="#559B3E"/>
                            {t('DashboardSocialNetwork.High')}
                        </ScaleColorWrapper>

                        <ZoomInZoomOut>
                            <img src={ZoomInButton} onClick={handleZoomIn}/>
                            <img src={ZoomOutButton} onClick={handleZoomOut}/>
                        </ZoomInZoomOut>

                    </LegendZoomWrapper>

                    <TeamsLegendWrapper Height="fit-content" Width="fit-content">

                        <Accordion expanded={legendExpanded === 'panel1'} onChange={handleAccordions('panel1')}
                                sx={{ width: "fit-content", borderTopRightRadius: 5, borderTopLeftRadius: 5, justifyContent: 'center' }}>
                            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header"
                                sx={{ color: '#292929', fontSize: 16, fontFamily: 'Overpass', fontStyle: 'normal', fontWeight: 700,
                                    '& .MuiAccordionSummary-content': {
                                        justifyContent: 'center',
                                    },
                                    '& .MuiAccordionSummary-root.Mui-expanded': {
                                        height: '32px',
                                    }
                                }}>
                                {t('DashboardSocialNetwork.TeamsLegend')}
                            </AccordionSummary>
                            <AccordionDetails sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >
                                <TeamFiltersWrapper MarginTop="0px" MarginLeft="0px" Width="fit-content" Height="fit-content" Language= {i18n.language} reRender={reRender}/>
                            </AccordionDetails>
                        </Accordion>
                    </TeamsLegendWrapper>
                    
                </>
            ) : ""}

        </Container>
    );
};

export default DashboardSocialNetwork;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: start;
  z-index: 1;
  flex-flow: nowrap;
  position: absolute;
  font-family: 'Overpass', sans-serif;
  @media only screen and (min-width: 1980px) {
    zoom: 1;
  }
`;

const LoadLogo = styled.div`
  img {
    width: 50px;
  }

  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
`;

const ElephantsWrapper = styled.div`
  position: absolute;
  left: 32px;
  top: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 2;
  width: fit-content;
  height: fit-content;
  justify-content: center;
`;

const CriticalProblemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${(props) => props.Width || "24px"};
  border-radius: 5px;
  padding: 18px;
  gap: 40px;
  background: #FCFCFC;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
  border: ${ props => props.Border || "" };
`;

const CriticalProblemNumber = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #E2336B;
  color: #FCFCFC;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  justify-content: center;
`;

const CriticalProblemName = styled.div`
  display: ${ props => props.Display || "none" };
  width: fit-content;
  color: #271B36;
  font-size: 14px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

const CriticalProblemButton = styled.div`
  display: ${ props => props.Display || "none" };
  padding: 4px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${ props => props.Color || "" };
  border-radius: 5px;
  background: ${ props => props.Background || "" };
`;

const ToolboxButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Overpass";
  font-weight: 700;
  width: fit-content;
  height: fit-content;
  min-width: 64px;
  padding: 32px 12px 32px 12px;
  background: #FCFCFC;
  color: #271B36;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  z-index: 1;
  cursor: pointer;
`;

const ToolboxWrapper = styled.div`
  position: absolute;
  right: 92px;
  top: 20px;
  font-family: "Overpass";
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background: #FCFCFC;
  gap: 24px;
  z-index: 2;
`;

const ToolboxDetails = styled.div`
  position: absolute;
  font-family: "Overpass";
  width: fit-content;
  height: fit-content;
  top: 100px;
  right: 0px;
  display: ${ props => props.Display || "none" };
  flex-direction: column;
  background: #FCFCFC;
  padding: 16px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  gap: 24px;
  z-index: 1;
`;

const ScoresAttributesWrapper = styled.div`
  display: flex;
  position: relative;
  font-family: "Overpass";
  flex-direction: column;
  align-items: flex-start;
`;


const ScoreWrapper = styled.div.attrs({
    className: "Score",
})`
  display: flex;
  flex-direction: row;
  background-color: #FCFCFC;
  width: 100%;
  height: fit-content;
  color: #271B36;
  text-align: center;
  justify-content: space-evenly;
  margin-left: 0;
  font-family: 'Overpass';
  padding: 8px 0px 8px 0px;
  border-radius: 10px;
  z-index: 3;
`;

const LeftIconText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
`;

const CollaborationWrapper = styled.div.attrs({
    className: "CollaborationWrapper",
})`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;


const BenchmarkWrapper = styled.div.attrs({
    className: "CollaborationWrapper",
})`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;


const CollaborationScoreWrapper = styled.div.attrs({
    className: "Collaboration",
})`
  display: flex;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Overpass';
  background-color: #271B36;
  width: 40px;
  height: 40px;
  color: #FCFCFC;
  padding: 18px 8px 0 8px;
  text-align: center;
  justify-content: center;
  border-style: solid;
  border-width: 3px;
  border-radius: 50%;
`;

const BenchmarkScoreWrapper = styled.div.attrs({
    className: "Benchmark",
})`
  display: flex;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Overpass';
  background-color: #271B36;
  width: 40px;
  height: 40px;
  color: #FCFCFC;
  padding: 18px 8px 0 8px;
  text-align: center;
  justify-content: center;
  border-style: solid;
  border-width: 3px;
  border-radius: 50%;
`;

const SeparatingLine1 = styled.div.attrs({
    className: "Line1",
})`
  border-left: 1px solid #3E4245;
  height: 64px;
`;

const ZonesWrapper = styled.div.attrs({
    className: "Zones",
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  height: fit-content;
  width: 100%;
  color: #271B36;
  z-index: 2;
  gap: 12px;
`;

const ZonesButtons = styled.div.attrs({
    className: "Zones",
})`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: relative;
  height: fit-content;
  width: 100%;
  color: #271B36;
  z-index: 2;
  gap: 24px;
`;

const LeadersWrapper = styled.div.attrs({
    className: "Leaders",
})`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  height: fit-content;
  width: 100%;
  color: #271B36;
  z-index: 2;
  gap: 12px;
`;

const LeadersButtons = styled.div.attrs({
    className: "Leaders",
})`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: relative;
  height: fit-content;
  width: 100%;
  color: #271B36;
  z-index: 2;
  gap: 24px;
`;

const LegendZoomWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  bottom: 0;
  right: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  align-items: flex-end;
  gap: 16px;
`;

const ScaleColorWrapper = styled.div.attrs({
    className: "ColorScale",
})`
  display: none;
  background: #FCFCFC;
  flex-direction: row;
  font-family: "Overpass";
  color: #271B36;
  width: 224px;
  height: 24px;
  align-items: center;
  justify-content: center;
  z-index: 3;
  padding: 8px 4px;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;

const Color = styled.div`
  display: flex;
  flex-direction: row;
  width: 24px;
  height: 24px;
  margin-left: ${props => props.MarginLeft || "0px"};
  margin-right: ${props => props.MarginRight || "0px"};
  align-items: center;
  justify-content: center;
  background-color: ${props => props.Color};
  opacity: 0.6;
  z-index: 4;
`;

const ZoomInZoomOut = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 16px;

  img {
    cursor: pointer;
  }
`;
          
const TeamsLegendWrapper = styled.div`
  display: flex;
  z-index: 1;
  width: ${(props) => props.Width || "1199px"};
  height: ${(props) => props.Height || "126px"};
  position: fixed;
  justify-content: center;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const TeamFiltersWrapper = styled.div.attrs({className: "TeamFilter",})`
  position: relative;
  margin-top: ${(props) => props.MarginTop || "20px"};
  margin-left: ${(props) => props.MarginLeft || "40px"};
  width: ${(props) => props.Width || "536px"};
  height: ${(props) => props.Height || "173px"};
  max-width: 1024px;
  background-color: #FCFCFC;
  z-index: 4;
  display: flex;
  justify-content: center;
  language: ${(props) => props.Language || "20px"};
`;