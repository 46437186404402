import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as OutcomeIcon } from "../../../../assets/outcomes_icon.svg";


const OutcomeContainer = ({ outcome, color }) => {

    const { t, i18n } = useTranslation();


    const { old: oldValue, new: newValue, goal } = outcome

    const trendIsUp = newValue > oldValue;
    const outcomeValue = () => {
        if (newValue > goal) return "Above goal";
        else if (newValue === goal) return "Goal reached";
        else return "Below goal";
    }

    return (
        <OutcomeWrapper style={{ borderColor: color }}>

            <OutcomeTitle>

                <OutcomeIconWrapper >
                    <OutcomeIcon style={{ fill: color }} />
                </OutcomeIconWrapper>

                {t('Transformation.Outcome')}

            </OutcomeTitle>

            <SurveyDateFirstTitle> {t('Months.Oct')} 2022</SurveyDateFirstTitle>
            <GoalTitle>{t('Transformation.Goal')}</GoalTitle>
            <SurveyDateSecondTitle> {t('Months.Jun')} 2023</SurveyDateSecondTitle>

            <div></div>

            <OutcomeName>{outcomeValue()}</OutcomeName>

            <OldValue style={{ color: "#000000" }}>
                {(oldValue * 100).toFixed(0)}%
            </OldValue>

            <GoalValue style={{ color: "#048B67" }}>
                {(goal * 100).toFixed(0)}%
            </GoalValue>

            <NewValue style={{ color: "#000000" }}>
                {(newValue * 100).toFixed(0)}%
            </NewValue>

        </OutcomeWrapper>
    )
}

export default OutcomeContainer;

const OutcomeWrapper = styled.div`
    border-radius: 5px;
    border: 1px dashed;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 8px;
    place-items: center;
    padding: 13px;
`;

const OutcomeTitle = styled.div`
    justify-self: start;
    font-weight: 700;
    display: flex;
`;

const SurveyDateFirstTitle = styled.div`
    font-weight: 700; 
`;

const GoalTitle = styled.div`
    font-weight: 700; 
`;

const SurveyDateSecondTitle = styled.div`
    font-weight: 700;    
`;

const OutcomeName = styled.div`
    color: #271B36;
    justify-self: start;
`;

const OldValue = styled.div`
    font-family: "Overpass";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
`;

const GoalValue = styled.div`
    font-family: "Overpass";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
`;

const NewValue = styled.div`
    font-family: "Overpass";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
`;

const OutcomeIconWrapper = styled.div`
    margin-right: 8px;
`;