import React, {useContext} from "react";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Label,
    LabelList,
    Legend,
    ReferenceLine,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {OverlayContext} from "../../layout-and-styling/context-hooks/OverlayContext";
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

const CustomIcon = (props) => {
    const {x, y, payload} = props

    if (payload.value === 'No Knowledge') {
        return (
            <svg x={x - 50} y={y - 20} width="40" height="40" viewBox="0 0 36 36" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2.25 6.27593C4.24125 5.45709 7.0965 4.57408 9.873 4.29966C12.8655 4.00311 15.4035 4.43908 16.875 5.96389V27.5324C14.7712 26.3595 12.105 26.198 9.64575 26.4414C6.99075 26.707 4.31325 27.4616 2.25 28.2362V6.27593ZM19.125 5.96389C20.5965 4.43908 23.1345 4.00311 26.127 4.29966C28.9035 4.57408 31.7588 5.45709 33.75 6.27593V28.2362C31.6845 27.4616 29.0093 26.7047 26.3542 26.4436C23.8927 26.198 21.2288 26.3573 19.125 27.5324V5.96389ZM18 3.96327C15.7837 2.08881 12.5708 1.80996 9.64575 2.09766C6.23925 2.43626 2.80125 3.58484 0.65925 4.5431C0.46271 4.63102 0.296044 4.77271 0.179147 4.95126C0.0622501 5.12981 5.97857e-05 5.33768 0 5.55004L0 29.8938C5.21145e-05 30.0789 0.0473336 30.2611 0.137513 30.4236C0.227693 30.5861 0.357889 30.7238 0.516174 30.824C0.67446 30.9242 0.855775 30.9837 1.04351 30.9971C1.23125 31.0105 1.41941 30.9774 1.59075 30.9007C3.57525 30.0155 6.7725 28.951 9.87075 28.6434C13.041 28.3291 15.6982 28.8359 17.1225 30.5843C17.2279 30.7135 17.3615 30.8178 17.5134 30.8895C17.6652 30.9611 17.8315 30.9983 18 30.9983C18.1685 30.9983 18.3348 30.9611 18.4866 30.8895C18.6385 30.8178 18.7721 30.7135 18.8775 30.5843C20.3018 28.8359 22.959 28.3291 26.127 28.6434C29.2275 28.951 32.427 30.0155 34.4093 30.9007C34.5806 30.9774 34.7687 31.0105 34.9565 30.9971C35.1442 30.9837 35.3255 30.9242 35.4838 30.824C35.6421 30.7238 35.7723 30.5861 35.8625 30.4236C35.9527 30.2611 35.9999 30.0789 36 29.8938V5.55004C35.9999 5.33768 35.9377 5.12981 35.8209 4.95126C35.704 4.77271 35.5373 4.63102 35.3407 4.5431C33.1987 3.58484 29.7608 2.43626 26.3542 2.09766C23.4293 1.80775 20.2162 2.08881 18 3.96327Z"
                    fill="#C6CBCB"/>
            </svg>
        )
    }

    if (payload.value === 'Started Learning') {
        return (
            <svg x={x - 50} y={y - 20} width="40" height="40" viewBox="0 0 36 36" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2.25 6.27593C4.24125 5.45709 7.0965 4.57408 9.873 4.29966C12.8655 4.00311 15.4035 4.43908 16.875 5.96389V27.5324C14.7712 26.3595 12.105 26.198 9.64575 26.4414C6.99075 26.707 4.31325 27.4616 2.25 28.2362V6.27593ZM19.125 5.96389C20.5965 4.43908 23.1345 4.00311 26.127 4.29966C28.9035 4.57408 31.7588 5.45709 33.75 6.27593V28.2362C31.6845 27.4616 29.0093 26.7047 26.3542 26.4436C23.8927 26.198 21.2288 26.3573 19.125 27.5324V5.96389ZM18 3.96327C15.7837 2.08881 12.5708 1.80996 9.64575 2.09766C6.23925 2.43626 2.80125 3.58484 0.65925 4.5431C0.46271 4.63102 0.296044 4.77271 0.179147 4.95126C0.0622501 5.12981 5.97857e-05 5.33768 0 5.55004L0 29.8938C5.21145e-05 30.0789 0.0473336 30.2611 0.137513 30.4236C0.227693 30.5861 0.357889 30.7238 0.516174 30.824C0.67446 30.9242 0.855775 30.9837 1.04351 30.9971C1.23125 31.0105 1.41941 30.9774 1.59075 30.9007C3.57525 30.0155 6.7725 28.951 9.87075 28.6434C13.041 28.3291 15.6982 28.8359 17.1225 30.5843C17.2279 30.7135 17.3615 30.8178 17.5134 30.8895C17.6652 30.9611 17.8315 30.9983 18 30.9983C18.1685 30.9983 18.3348 30.9611 18.4866 30.8895C18.6385 30.8178 18.7721 30.7135 18.8775 30.5843C20.3018 28.8359 22.959 28.3291 26.127 28.6434C29.2275 28.951 32.427 30.0155 34.4093 30.9007C34.5806 30.9774 34.7687 31.0105 34.9565 30.9971C35.1442 30.9837 35.3255 30.9242 35.4838 30.824C35.6421 30.7238 35.7723 30.5861 35.8625 30.4236C35.9527 30.2611 35.9999 30.0789 36 29.8938V5.55004C35.9999 5.33768 35.9377 5.12981 35.8209 4.95126C35.704 4.77271 35.5373 4.63102 35.3407 4.5431C33.1987 3.58484 29.7608 2.43626 26.3542 2.09766C23.4293 1.80775 20.2162 2.08881 18 3.96327Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 7.7613C14.5187 6.22797 11.9711 5.78956 8.96715 6.08777C6.18006 6.36373 4.99884 6.93789 3 7.7613L3.00416 9C5.07753 8.2211 6.07807 7.77378 8.74319 7.51118C11.2141 7.26416 13.8882 7.42439 16 8.6061L15.9958 7.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 10.7613C14.5187 9.22797 11.9711 8.78956 8.96715 9.08777C6.18006 9.36373 4.99884 9.93789 3 10.7613L3.00416 12C5.07753 11.2211 6.07807 10.7738 8.74319 10.5112C11.2141 10.2642 13.8882 10.4244 16 11.6061L15.9958 10.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 13.7613C14.5187 12.228 11.9711 11.7896 8.96715 12.0878C6.18006 12.3637 4.99884 12.9379 3 13.7613L3.00416 15C5.07753 14.2211 6.07807 13.7738 8.74319 13.5112C11.2141 13.2642 13.8882 13.4244 16 14.6061L15.9958 13.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 16.7613C14.5187 15.228 11.9711 14.7896 8.96715 15.0878C6.18006 15.3637 4.99884 15.9379 3 16.7613L3.00416 18C5.07753 17.2211 6.07807 16.7738 8.74319 16.5112C11.2141 16.2642 13.8882 16.4244 16 17.6061L15.9958 16.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 19.7613C14.5187 18.228 11.9711 17.7896 8.96715 18.0878C6.18006 18.3637 4.99884 18.9379 3 19.7613L3.00416 21C5.07753 20.2211 6.07807 19.7738 8.74319 19.5112C11.2141 19.2642 13.8882 19.4244 16 20.6061L15.9958 19.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 22.7613C14.5187 21.228 11.9711 20.7896 8.96715 21.0878C6.18006 21.3637 4.99884 21.9379 3 22.7613L3.00416 24C5.07753 23.2211 6.07807 22.7738 8.74319 22.5112C11.2141 22.2642 13.8882 22.4244 16 23.6061L15.9958 22.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 25.7613C14.5187 24.228 11.9711 23.7896 8.96715 24.0878C6.18006 24.3637 4.99884 24.9379 3 25.7613L3.00416 27C5.07753 26.2211 6.07807 25.7738 8.74319 25.5112C11.2141 25.2642 13.8882 25.4244 16 26.6061L15.9958 25.7613Z"
                    fill="#C6CBCB"/>
            </svg>
        )
    }

    if (payload.value === 'Work Independently') {
        return (
            <svg x={x - 50} y={y - 20} width="40" height="40" viewBox="0 0 36 36" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2.25 6.27593C4.24125 5.45709 7.0965 4.57408 9.873 4.29966C12.8655 4.00311 15.4035 4.43908 16.875 5.96389V27.5324C14.7712 26.3595 12.105 26.198 9.64575 26.4414C6.99075 26.707 4.31325 27.4616 2.25 28.2362V6.27593ZM19.125 5.96389C20.5965 4.43908 23.1345 4.00311 26.127 4.29966C28.9035 4.57408 31.7588 5.45709 33.75 6.27593V28.2362C31.6845 27.4616 29.0093 26.7047 26.3542 26.4436C23.8927 26.198 21.2288 26.3573 19.125 27.5324V5.96389ZM18 3.96327C15.7837 2.08881 12.5708 1.80996 9.64575 2.09766C6.23925 2.43626 2.80125 3.58484 0.65925 4.5431C0.46271 4.63102 0.296044 4.77271 0.179147 4.95126C0.0622501 5.12981 5.97857e-05 5.33768 0 5.55004L0 29.8938C5.21145e-05 30.0789 0.0473336 30.2611 0.137513 30.4236C0.227693 30.5861 0.357889 30.7238 0.516174 30.824C0.67446 30.9242 0.855775 30.9837 1.04351 30.9971C1.23125 31.0105 1.41941 30.9774 1.59075 30.9007C3.57525 30.0155 6.7725 28.951 9.87075 28.6434C13.041 28.3291 15.6982 28.8359 17.1225 30.5843C17.2279 30.7135 17.3615 30.8178 17.5134 30.8895C17.6652 30.9611 17.8315 30.9983 18 30.9983C18.1685 30.9983 18.3348 30.9611 18.4866 30.8895C18.6385 30.8178 18.7721 30.7135 18.8775 30.5843C20.3018 28.8359 22.959 28.3291 26.127 28.6434C29.2275 28.951 32.427 30.0155 34.4093 30.9007C34.5806 30.9774 34.7687 31.0105 34.9565 30.9971C35.1442 30.9837 35.3255 30.9242 35.4838 30.824C35.6421 30.7238 35.7723 30.5861 35.8625 30.4236C35.9527 30.2611 35.9999 30.0789 36 29.8938V5.55004C35.9999 5.33768 35.9377 5.12981 35.8209 4.95126C35.704 4.77271 35.5373 4.63102 35.3407 4.5431C33.1987 3.58484 29.7608 2.43626 26.3542 2.09766C23.4293 1.80775 20.2162 2.08881 18 3.96327Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 7.7613C14.5187 6.22797 11.9711 5.78956 8.96715 6.08777C6.18006 6.36373 4.99884 6.93789 3 7.7613L3.00416 9C5.07753 8.2211 6.07807 7.77378 8.74319 7.51118C11.2141 7.26416 13.8882 7.42439 16 8.6061L15.9958 7.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 10.7613C14.5187 9.22797 11.9711 8.78956 8.96715 9.08777C6.18006 9.36373 4.99884 9.93789 3 10.7613L3.00416 12C5.07753 11.2211 6.07807 10.7738 8.74319 10.5112C11.2141 10.2642 13.8882 10.4244 16 11.6061L15.9958 10.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 13.7613C14.5187 12.228 11.9711 11.7896 8.96715 12.0878C6.18006 12.3637 4.99884 12.9379 3 13.7613L3.00416 15C5.07753 14.2211 6.07807 13.7738 8.74319 13.5112C11.2141 13.2642 13.8882 13.4244 16 14.6061L15.9958 13.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 16.7613C14.5187 15.228 11.9711 14.7896 8.96715 15.0878C6.18006 15.3637 4.99884 15.9379 3 16.7613L3.00416 18C5.07753 17.2211 6.07807 16.7738 8.74319 16.5112C11.2141 16.2642 13.8882 16.4244 16 17.6061L15.9958 16.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 19.7613C14.5187 18.228 11.9711 17.7896 8.96715 18.0878C6.18006 18.3637 4.99884 18.9379 3 19.7613L3.00416 21C5.07753 20.2211 6.07807 19.7738 8.74319 19.5112C11.2141 19.2642 13.8882 19.4244 16 20.6061L15.9958 19.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 22.7613C14.5187 21.228 11.9711 20.7896 8.96715 21.0878C6.18006 21.3637 4.99884 21.9379 3 22.7613L3.00416 24C5.07753 23.2211 6.07807 22.7738 8.74319 22.5112C11.2141 22.2642 13.8882 22.4244 16 23.6061L15.9958 22.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 25.7613C14.5187 24.228 11.9711 23.7896 8.96715 24.0878C6.18006 24.3637 4.99884 24.9379 3 25.7613L3.00416 27C5.07753 26.2211 6.07807 25.7738 8.74319 25.5112C11.2141 25.2642 13.8882 25.4244 16 26.6061L15.9958 25.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M20.0042 7.7613C21.4813 6.22797 24.0289 5.78956 27.0329 6.08777C29.8199 6.36373 31.0012 6.93789 33 7.7613L32.9958 9C30.9225 8.2211 29.9219 7.77378 27.2568 7.51118C24.7859 7.26416 22.1118 7.42439 20 8.6061L20.0042 7.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M20.0042 10.7613C21.4813 9.22797 24.0289 8.78956 27.0329 9.08777C29.8199 9.36373 31.0012 9.93789 33 10.7613L32.9958 12C30.9225 11.2211 29.9219 10.7738 27.2568 10.5112C24.7859 10.2642 22.1118 10.4244 20 11.6061L20.0042 10.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M20.0042 13.7613C21.4813 12.228 24.0289 11.7896 27.0329 12.0878C29.8199 12.3637 31.0012 12.9379 33 13.7613L32.9958 15C30.9225 14.2211 29.9219 13.7738 27.2568 13.5112C24.7859 13.2642 22.1118 13.4244 20 14.6061L20.0042 13.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M20.0042 16.7613C21.4813 15.228 24.0289 14.7896 27.0329 15.0878C29.8199 15.3637 31.0012 15.9379 33 16.7613L32.9958 18C30.9225 17.2211 29.9219 16.7738 27.2568 16.5112C24.7859 16.2642 22.1118 16.4244 20 17.6061L20.0042 16.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M20.0042 19.7613C21.4813 18.228 24.0289 17.7896 27.0329 18.0878C29.8199 18.3637 31.0012 18.9379 33 19.7613L32.9958 21C30.9225 20.2211 29.9219 19.7738 27.2568 19.5112C24.7859 19.2642 22.1118 19.4244 20 20.6061L20.0042 19.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M20.0042 22.7613C21.4813 21.228 24.0289 20.7896 27.0329 21.0878C29.8199 21.3637 31.0012 21.9379 33 22.7613L32.9958 24C30.9225 23.2211 29.9219 22.7738 27.2568 22.5112C24.7859 22.2642 22.1118 22.4244 20 23.6061L20.0042 22.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M20.0042 25.7613C21.4813 24.228 24.0289 23.7896 27.0329 24.0878C29.8199 24.3637 31.0012 24.9379 33 25.7613L32.9958 27C30.9225 26.2211 29.9219 25.7738 27.2568 25.5112C24.7859 25.2642 22.1118 25.4244 20 26.6061L20.0042 25.7613Z"
                    fill="#C6CBCB"/>
            </svg>
        )
    }

    if (payload.value === 'Can Teach Others') {
        return (
            <svg x={x - 50} y={y - 20} width="40" height="40" viewBox="0 0 36 36" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2.25 6.27593C4.24125 5.45709 7.0965 4.57408 9.873 4.29966C12.8655 4.00311 15.4035 4.43908 16.875 5.96389V27.5324C14.7712 26.3595 12.105 26.198 9.64575 26.4414C6.99075 26.707 4.31325 27.4616 2.25 28.2362V6.27593ZM19.125 5.96389C20.5965 4.43908 23.1345 4.00311 26.127 4.29966C28.9035 4.57408 31.7588 5.45709 33.75 6.27593V28.2362C31.6845 27.4616 29.0093 26.7047 26.3542 26.4436C23.8927 26.198 21.2288 26.3573 19.125 27.5324V5.96389ZM18 3.96327C15.7837 2.08881 12.5708 1.80996 9.64575 2.09766C6.23925 2.43626 2.80125 3.58484 0.65925 4.5431C0.46271 4.63102 0.296044 4.77271 0.179147 4.95126C0.0622501 5.12981 5.97857e-05 5.33768 0 5.55004L0 29.8938C5.21145e-05 30.0789 0.0473336 30.2611 0.137513 30.4236C0.227693 30.5861 0.357889 30.7238 0.516174 30.824C0.67446 30.9242 0.855775 30.9837 1.04351 30.9971C1.23125 31.0105 1.41941 30.9774 1.59075 30.9007C3.57525 30.0155 6.7725 28.951 9.87075 28.6434C13.041 28.3291 15.6982 28.8359 17.1225 30.5843C17.2279 30.7135 17.3615 30.8178 17.5134 30.8895C17.6652 30.9611 17.8315 30.9983 18 30.9983C18.1685 30.9983 18.3348 30.9611 18.4866 30.8895C18.6385 30.8178 18.7721 30.7135 18.8775 30.5843C20.3018 28.8359 22.959 28.3291 26.127 28.6434C29.2275 28.951 32.427 30.0155 34.4093 30.9007C34.5806 30.9774 34.7687 31.0105 34.9565 30.9971C35.1442 30.9837 35.3255 30.9242 35.4838 30.824C35.6421 30.7238 35.7723 30.5861 35.8625 30.4236C35.9527 30.2611 35.9999 30.0789 36 29.8938V5.55004C35.9999 5.33768 35.9377 5.12981 35.8209 4.95126C35.704 4.77271 35.5373 4.63102 35.3407 4.5431C33.1987 3.58484 29.7608 2.43626 26.3542 2.09766C23.4293 1.80775 20.2162 2.08881 18 3.96327Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 7.7613C14.5187 6.22797 11.9711 5.78956 8.96715 6.08777C6.18006 6.36373 4.99884 6.93789 3 7.7613L3.00416 9C5.07753 8.2211 6.07807 7.77378 8.74319 7.51118C11.2141 7.26416 13.8882 7.42439 16 8.6061L15.9958 7.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 10.7613C14.5187 9.22797 11.9711 8.78956 8.96715 9.08777C6.18006 9.36373 4.99884 9.93789 3 10.7613L3.00416 12C5.07753 11.2211 6.07807 10.7738 8.74319 10.5112C11.2141 10.2642 13.8882 10.4244 16 11.6061L15.9958 10.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 13.7613C14.5187 12.228 11.9711 11.7896 8.96715 12.0878C6.18006 12.3637 4.99884 12.9379 3 13.7613L3.00416 15C5.07753 14.2211 6.07807 13.7738 8.74319 13.5112C11.2141 13.2642 13.8882 13.4244 16 14.6061L15.9958 13.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 16.7613C14.5187 15.228 11.9711 14.7896 8.96715 15.0878C6.18006 15.3637 4.99884 15.9379 3 16.7613L3.00416 18C5.07753 17.2211 6.07807 16.7738 8.74319 16.5112C11.2141 16.2642 13.8882 16.4244 16 17.6061L15.9958 16.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 19.7613C14.5187 18.228 11.9711 17.7896 8.96715 18.0878C6.18006 18.3637 4.99884 18.9379 3 19.7613L3.00416 21C5.07753 20.2211 6.07807 19.7738 8.74319 19.5112C11.2141 19.2642 13.8882 19.4244 16 20.6061L15.9958 19.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 22.7613C14.5187 21.228 11.9711 20.7896 8.96715 21.0878C6.18006 21.3637 4.99884 21.9379 3 22.7613L3.00416 24C5.07753 23.2211 6.07807 22.7738 8.74319 22.5112C11.2141 22.2642 13.8882 22.4244 16 23.6061L15.9958 22.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M15.9958 25.7613C14.5187 24.228 11.9711 23.7896 8.96715 24.0878C6.18006 24.3637 4.99884 24.9379 3 25.7613L3.00416 27C5.07753 26.2211 6.07807 25.7738 8.74319 25.5112C11.2141 25.2642 13.8882 25.4244 16 26.6061L15.9958 25.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M20.0042 7.7613C21.4813 6.22797 24.0289 5.78956 27.0329 6.08777C29.8199 6.36373 31.0012 6.93789 33 7.7613L32.9958 9C30.9225 8.2211 29.9219 7.77378 27.2568 7.51118C24.7859 7.26416 22.1118 7.42439 20 8.6061L20.0042 7.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M20.0042 10.7613C21.4813 9.22797 24.0289 8.78956 27.0329 9.08777C29.8199 9.36373 31.0012 9.93789 33 10.7613L32.9958 12C30.9225 11.2211 29.9219 10.7738 27.2568 10.5112C24.7859 10.2642 22.1118 10.4244 20 11.6061L20.0042 10.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M20.0042 13.7613C21.4813 12.228 24.0289 11.7896 27.0329 12.0878C29.8199 12.3637 31.0012 12.9379 33 13.7613L32.9958 15C30.9225 14.2211 29.9219 13.7738 27.2568 13.5112C24.7859 13.2642 22.1118 13.4244 20 14.6061L20.0042 13.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M20.0042 16.7613C21.4813 15.228 24.0289 14.7896 27.0329 15.0878C29.8199 15.3637 31.0012 15.9379 33 16.7613L32.9958 18C30.9225 17.2211 29.9219 16.7738 27.2568 16.5112C24.7859 16.2642 22.1118 16.4244 20 17.6061L20.0042 16.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M20.0042 19.7613C21.4813 18.228 24.0289 17.7896 27.0329 18.0878C29.8199 18.3637 31.0012 18.9379 33 19.7613L32.9958 21C30.9225 20.2211 29.9219 19.7738 27.2568 19.5112C24.7859 19.2642 22.1118 19.4244 20 20.6061L20.0042 19.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M20.0042 22.7613C21.4813 21.228 24.0289 20.7896 27.0329 21.0878C29.8199 21.3637 31.0012 21.9379 33 22.7613L32.9958 24C30.9225 23.2211 29.9219 22.7738 27.2568 22.5112C24.7859 22.2642 22.1118 22.4244 20 23.6061L20.0042 22.7613Z"
                    fill="#C6CBCB"/>
                <path
                    d="M20.0042 25.7613C21.4813 24.228 24.0289 23.7896 27.0329 24.0878C29.8199 24.3637 31.0012 24.9379 33 25.7613L32.9958 27C30.9225 26.2211 29.9219 25.7738 27.2568 25.5112C24.7859 25.2642 22.1118 25.4244 20 26.6061L20.0042 25.7613Z"
                    fill="#C6CBCB"/>
            </svg>
        )
    }
}

export default function BarCharts(props) {
    const context = useContext(OverlayContext);
    const {t, i18n} = useTranslation();

    if (props.data) {
        if (props.ComponentShown === "knowledgeOverview") {
            let totalPeople = props.data[0].no_knowledge + props.data[0].started_learning + props.data[0].work_independent + props.data[0].teach_others;
            /** Values for Bar Chart */
            const data = [
                {
                    name: "No Knowledge",
                    value: parseInt((props.data[0].no_knowledge * 100 / totalPeople).toFixed(0)),
                    color: "#B3194A",
                },
                {
                    name: "Started Learning",
                    value: parseInt((props.data[0].started_learning * 100 / totalPeople).toFixed(0)),
                    color: "#5F6366",

                },
                {
                    name: "Work Independently",
                    value: parseInt((props.data[0].work_independent * 100 / totalPeople).toFixed(0)),
                    color: "#26547C",

                },
                {
                    name: "Can Teach Others",
                    value: parseInt((props.data[0].teach_others * 100 / totalPeople).toFixed(0)),
                    color: "#52B5B5",
                }
            ];
            return (
                <>
                    <BarChart layout="vertical" data={data} width={350} height={175}
                              margin={{
                                  top: 0,
                                  right: 0,
                                  left: 20,
                                  bottom: 0,
                              }}
                    >

                        <XAxis type="number" hide/>
                        <YAxis type="category" dataKey="name"
                               axisLine={false} tickLine={false}
                               tick={<CustomIcon/>}/>

                        <Bar dataKey="value" fill="#B3194A" radius={5} barSize={46} minPointSize={160}>
                            {data.map((entry, index) => (
                                <>
                                    <LabelList position={"insideLeft"} fill='#FCFCFC' dataKey="name" style={{
                                        fontSize: '14px',
                                    }}/>
                                    <LabelList formatter={value => `${value}%`} position={"insideRight"}
                                               fill='#FCFCFC' dataKey="value" style={{
                                        fontSize: '16px',
                                    }}/>
                                    <Cell fill={entry.color} key={`cell-${index}`}/>
                                </>
                            ))}
                        </Bar>
                    </BarChart>
                </>
            )
        }

        if (props.ComponentShown === "knowledgeLeft") {
            let dataKnowledge = props.data[1];

            let first = dataKnowledge[0]
            let second = dataKnowledge[1]
            let third = dataKnowledge[2]
            let fourth = dataKnowledge[3]

            /** Values for Bar Chart */
            const data = [
                {
                    name: context.setFirstName(first.first_name, 0) + context.setFirstName(first.last_name, 0),
                    Knowledge: first.efficacy * 25,
                    Workload: first.time_portion * 100,
                    Receiving: (1 - first.sharing_portion) * 100
                },
                {
                    name: context.setFirstName(second.first_name, 0) + context.setFirstName(second.last_name, 0),
                    Knowledge: second.efficacy * 25,
                    Workload: second.time_portion * 100,
                    Receiving: (1 - second.sharing_portion) * 100

                },
                {
                    name: context.setFirstName(third.first_name, 0) + context.setFirstName(third.last_name, 0),
                    Knowledge: third.efficacy * 25,
                    Workload: third.time_portion * 100,
                    Receiving: (1 - third.sharing_portion) * 100

                },
                {
                    name: context.setFirstName(fourth.first_name, 0) + context.setFirstName(fourth.last_name, 0),
                    Knowledge: fourth.efficacy * 25,
                    Workload: fourth.time_portion * 100,
                    Receiving: (1 - fourth.sharing_portion) * 100
                }
            ];
            return (
                <>
                    <BarChart width={760} height={280} data={data}
                              margin={{
                                  top: 20,
                                  right: 0,
                                  left: 20,
                                  bottom: 0,
                              }}
                    >

                        <CartesianGrid strokeDasharray="4 1" vertical={false}/>
                        <XAxis dataKey="name"/>
                        <YAxis tickFormatter={tick => `${tick}%`} domain={[0, 100]}/>
                        <Tooltip/>
                        <Legend formatter={(value, entry, index) => <span style={{color: '#5F6366'}}>{value}</span>}
                                align="right" verticalAlign={"middle"} height={80} iconType={"circle"}
                                layout={"vertical"}
                                wrapperStyle={{position: 'fixed', left: "700px", marginTop: '60px'}}/>

                        <Bar dataKey="Knowledge" fill="#B3194A" radius={[5, 5, 0, 0]}/>
                        <Bar dataKey="Workload" fill="#FDC4C4" radius={[5, 5, 0, 0]}/>
                        <Bar dataKey="Receiving" fill="#D0E1F1" radius={[5, 5, 0, 0]}/>

                    </BarChart>

                </>
            )
        }

        if (props.ComponentShown === "knowledgeRight") {
            let dataKnowledge = props.data[1];

            let first = dataKnowledge[dataKnowledge.length - 1]
            let second = dataKnowledge[dataKnowledge.length - 2]
            let third = dataKnowledge[dataKnowledge.length - 3]
            let fourth = dataKnowledge[dataKnowledge.length - 4]
            /** Values for Bar Chart */
            const data = [
                {
                    name: context.setFirstName(first.first_name, 0) + context.setFirstName(first.last_name, 0),
                    Knowledge: first.efficacy * 25,
                    Workload: first.time_portion * 100,
                    Sharing: first.sharing_portion * 100
                },
                {
                    name: context.setFirstName(second.first_name, 0) + context.setFirstName(second.last_name, 0),
                    Knowledge: second.efficacy * 25,
                    Workload: second.time_portion * 100,
                    Sharing: second.sharing_portion * 100

                },
                {
                    name: context.setFirstName(third.first_name, 0) + context.setFirstName(third.last_name, 0),
                    Knowledge: third.efficacy * 25,
                    Workload: third.time_portion * 100,
                    Sharing: third.sharing_portion * 100

                },
                {
                    name: context.setFirstName(fourth.first_name, 0) + context.setFirstName(fourth.last_name, 0),
                    Knowledge: fourth.efficacy * 25,
                    Workload: fourth.time_portion * 100,
                    Sharing: fourth.sharing_portion * 100
                }
            ];

            return (
                <>
                    <BarChart width={760} height={280} data={data}
                              margin={{
                                  top: 20,
                                  right: 0,
                                  left: 10,
                                  bottom: 0,
                              }}>

                        <CartesianGrid strokeDasharray="4 1" vertical={false}/>
                        <XAxis dataKey="name"/>
                        <YAxis tickFormatter={tick => `${tick}%`} domain={[0, 100]}/>
                        <Tooltip/>
                        <Legend formatter={(value, entry, index) => <span style={{color: '#5F6366'}}>{value}</span>}
                                align="right" verticalAlign={"middle"} height={80} iconType={"circle"}
                                layout={"vertical"}
                                wrapperStyle={{position: 'fixed', marginTop: '60px'}}/>

                        <Bar dataKey="Knowledge" fill="#52B5B5" radius={[5, 5, 0, 0]}/>
                        <Bar dataKey="Workload" fill="#FDC4C4" radius={[5, 5, 0, 0]}/>
                        <Bar dataKey="Sharing" fill="#DFD6EA" radius={[5, 5, 0, 0]}/>

                    </BarChart>
                </>
            )
        }

        if (props.ComponentShown === "satisfactionLeft") {
            const satisfactionData = [
                {
                    name: "Salary",
                    satisfaction: props.data[4].satisfied.average_coaching_score,
                    // influence: props.data[4][0]["average_weight_coaching"] * 100,
                },
                {
                    name: "Work itself",
                    satisfaction: props.data[4].satisfied.average_work_score,
                    // influence: props.data[4][0]["average_weight_work"] * 100,
                },
                {
                    name: "Recognition",
                    satisfaction: props.data[4].satisfied.average_recognition_score,
                    // influence: props.data[4][0]["average_weight_recognition"] * 100,
                },
                {
                    name: "Coaching",
                    satisfaction: props.data[4].satisfied.average_coaching_score,
                    // influence: props.data[4][0]["average_weight_coaching"] * 100,
                },
                {
                    name: "Company",
                    satisfaction: props.data[4].satisfied.average_company_benefits_score,
                    // influence: props.data[4][0]["average_weight_company_benefits"] * 100,
                },
                {
                    name: "Promotion",
                    satisfaction: props.data[4].satisfied.average_promotion_score,

                    // influence: props.data[4][0]["average_weight_promotion"] * 100,
                }
            ];

            const sortedData = satisfactionData.sort((a, b) => b.satisfaction - a.satisfaction)

            return (
                <>
                    <BarChart width={750} height={250} data={sortedData}
                              margin={{
                                  top: 20,
                                  right: 50,
                                  left: 30,
                                  bottom: 0,
                              }}>

                        <CartesianGrid strokeDasharray="4 1" vertical={false}/>
                        <YAxis ticks={[-2, 0, 2]}
                               tickFormatter={value => value < -1 ? `Dissatisfied` : value < 1 ? `Neutral` : value < 2 ? `Satisfied` : `Satisfied`}
                               type="number"
                               domain={[-2, 2]}
                        />
                        <XAxis
                            dataKey="name"
                            width="10"
                            // angle="-45"
                        />

                        <Legend formatter={(value, entry, index) =>
                            <span style={{color: '#5F6366'}}>{value}</span>}
                                height={80}
                                width={215}
                                align="right"
                                verticalAlign={"middle"}
                                iconType={"circle"}
                                layout={"vertical"}
                                wrapperStyle={{position: 'relative', marginLeft: '550px', marginTop: '-360px'}}/>
                        <Tooltip/>
                        {/* <Bar dataKey="influence" name="Influence of factor" fill="#26547C" radius={[5, 5, 0, 0]} /> */}
                        {/* <Bar dataKey="satisfaction" name="Satisfaction of factor" fill="#FFD166" radius={[5, 5, 0, 0]} /> */}
                        <Bar dataKey="satisfaction"
                             name="Satisfaction of factor"
                            // fill={satisfactionData.satisfaction < 0 ? "#B3194A" : "#06D6A0"}
                             fill="#FFD166"
                             radius={[5, 5, 0, 0]}/>

                        <ReferenceLine y={50} stroke="#000000" strokeDasharray="3 3"/>

                        {/* {(satisfactionData.satisfaction < 50) ? "#B3194A" : "#06D6A0"} */}
                        {/* <Bar dataKey="satisfaction" fill="#06D6A0" radius={[5, 5, 0, 0]}/>
                            <Bar dataKey="satisfaction" fill="#B3194A" radius={[5, 5, 0, 0]}/> */}
                    </BarChart>
                </>
            )
        }

        if (props.ComponentShown === "satisfactionRight") {
            const satisfactionData = [
                {
                    name: "Salary",
                    satisfaction: props.data[4].dissatisfied.average_coaching_score,
                    // influence: props.data[4][0]["average_weight_coaching"] * 100,
                },
                {
                    name: "Work itself",
                    satisfaction: props.data[4].dissatisfied.average_work_score,
                    // influence: props.data[4][0]["average_weight_work"] * 100,
                },
                {
                    name: "Recognition",
                    satisfaction: props.data[4].dissatisfied.average_recognition_score,
                    // influence: props.data[4][0]["average_weight_recognition"] * 100,
                },
                {
                    name: "Coaching",
                    satisfaction: props.data[4].dissatisfied.average_coaching_score,
                    // influence: props.data[4][0]["average_weight_coaching"] * 100,
                },
                {
                    name: "Company",
                    satisfaction: props.data[4].dissatisfied.average_company_benefits_score,
                    // influence: props.data[4][0]["average_weight_company_benefits"] * 100,
                },
                {
                    name: "Promotion",
                    satisfaction: props.data[4].dissatisfied.average_promotion_score,

                    // influence: props.data[4][0]["average_weight_promotion"] * 100,
                }
            ];

            const sortedData = satisfactionData.sort((a, b) => b.satisfaction - a.satisfaction)

            return (
                <>
                    <BarChart width={750} height={250} data={sortedData}
                              margin={{
                                  top: 20,
                                  right: 50,
                                  left: 30,
                                  bottom: 0,
                              }}>

                        <CartesianGrid strokeDasharray="4 1" vertical={false}/>
                        <YAxis ticks={[-2, 0, 2]}
                               tickFormatter={value => value < -1 ? `Dissatisfied` : value < 1 ? `Neutral` : value < 2 ? `Satisfied` : `Satisfied`}
                               type="number"
                               domain={[-2, 2]}
                        />
                        <XAxis
                            dataKey="name"
                            width="10"
                            // angle="-45"
                        />

                        <Legend formatter={(value, entry, index) =>
                            <span style={{color: '#5F6366'}}>{value}</span>}
                                height={80}
                                width={215}
                                align="right"
                                verticalAlign={"middle"}
                                iconType={"circle"}
                                layout={"vertical"}
                                wrapperStyle={{position: 'relative', marginLeft: '550px', marginTop: '-360px'}}/>
                        <Tooltip/>
                        {/* <Bar dataKey="influence" name="Influence of factor" fill="#26547C" radius={[5, 5, 0, 0]} /> */}
                        {/* <Bar dataKey="satisfaction" name="Satisfaction of factor" fill="#FFD166" radius={[5, 5, 0, 0]} /> */}
                        <Bar dataKey="satisfaction"
                             name="Satisfaction of factor"
                            // fill={satisfactionData.satisfaction < 0 ? "#B3194A" : "#06D6A0"}
                             fill="#FFD166"
                             radius={[5, 5, 0, 0]}/>

                        <ReferenceLine y={50} stroke="#000000" strokeDasharray="3 3"/>

                        {/* {(satisfactionData.satisfaction < 50) ? "#B3194A" : "#06D6A0"} */}
                        {/* <Bar dataKey="satisfaction" fill="#06D6A0" radius={[5, 5, 0, 0]}/>
                            <Bar dataKey="satisfaction" fill="#B3194A" radius={[5, 5, 0, 0]}/> */}
                    </BarChart>
                </>
            )
        }

        if (props.ComponentShown === "collaborationOverview") {
            /** Values for Bar Chart */
            let collaborationData = props.data[2];
            let sharing_portion = 0;
            let sharing_quality = 0;

            collaborationData.map(employee => {
                sharing_portion += employee.sharing_portion;
                sharing_quality += employee.sharing_quality
            })
            sharing_quality /= collaborationData.length;
            sharing_portion /= collaborationData.length;
            const data = [
                {
                    name: "Quality of Collaboration",
                    quality: parseInt((sharing_quality * 100 / 5).toFixed(0)),
                    sharing: (sharing_portion * 100).toFixed(0),
                    receiving: ((1 - sharing_portion) * 100).toFixed(0),
                },
            ];

            return (

                <CollaborationWrapper>
                    <BarChartWrapper>
                        <BarChart width={90} height={155} data={data}>
                            <Bar dataKey="quality" fill="#B3194A" radius={5}>
                                {data.map((entry, index) => (
                                    <>
                                        <LabelList dataKey="quality" formatter={value => `${value}%`}
                                                   position={"inside"} fill="#FCFCFC" style={{
                                            fontSize: '20px',
                                        }}/>
                                        <Cell fill="#52B5B5" key={`cell-${index}`}/>
                                    </>

                                ))}
                            </Bar>
                        </BarChart>
                        <BarChartLabel>Quality of collaboration</BarChartLabel>
                    </BarChartWrapper>
                    <BarChartWrapper>
                        <BarChart width={90} height={155} data={data}>
                            <Bar dataKey="receiving" stackId="a" fill="#52B5B5" radius={[0, 0, 5, 5]} unit="%">
                                <LabelList dataKey="receiving" formatter={value => `${value}%`}
                                           position="inside"
                                           fill="#FCFCFC" style={{
                                    fontSize: '20px',
                                }}/>
                            </Bar>
                            <Bar dataKey="sharing" stackId="a" fill="#06D6A0" radius={[5, 5, 0, 0]} unit="%">
                                <LabelList dataKey="sharing" formatter={value => `${value}%`} position="inside"
                                           fill="#FCFCFC" style={{
                                    fontSize: '20px',
                                }}/>
                            </Bar>
                        </BarChart>
                        <BarChartLabel>Sharing & Receiving</BarChartLabel>
                    </BarChartWrapper>
                </CollaborationWrapper>
            )
        }

        if (props.ComponentShown === "collaborationLeft") {

            let collaborationData = props.data[2].reduce((agg, curr) => {
                let found = agg.find((x) => x.team === curr.team);
                if (found) {
                    found.employee.push(curr);
                } else {
                    agg.push({
                        team: curr.team,
                        employee: [curr]
                    });
                }
                return agg;
            }, []);

            collaborationData.map(team => {
                let team_impact = 0;
                let team_pleasantness = 0;
                let team_sharing_portion = 0;
                let team_sharing_quality = 0;

                team.employee.map(employee => {
                    team_impact += employee.impact;
                    team_pleasantness += employee.pleasantness;
                    team_sharing_portion += employee.sharing_portion;
                    team_sharing_quality += employee.sharing_quality;
                })
                team.impact = team_impact / team.employee.length;
                team.pleasantness = team_pleasantness / team.employee.length;
                team.sharing_portion = team_sharing_portion / team.employee.length;
                team.sharing_quality = team_sharing_quality / team.employee.length;
            })

            collaborationData.sort(function (a, b) {
                return a.impact - b.impact || a.pleasantness - b.pleasantness || a.sharing_quality - b.sharing_quality;
            });
            //
            // let betweenTeams = []
            // collaborationData.map(team => {
            //     for (let i = 0; i < team.employee.length; i++)
            //         for (let j = i; j < team.employee.length; j++) {
            //             betweenTeams.append({teams_names: context.setTeams(team[i].team.split('-')[1]) + " + " + context.setTeams(team[j].team.split('-')[1]),
            //             impact:team[i].impact})
            //         }
            // })

            /** Values for Bar Chart */
            const data = [
                {
                    name: "Sales & PCB",
                    Quality: "50",
                    Pleasantness: "24",
                    Impact: "30"
                },
                {
                    name: "Logistics & ODOO & ICT",
                    Quality: "55",
                    Pleasantness: "24",
                    Impact: "30"

                },
                {
                    name: "QC & Sales",
                    Quality: "76",
                    Pleasantness: "67",
                    Impact: "67"

                },
                {
                    name: "VAG & R&D",
                    Quality: "30",
                    Pleasantness: "24",
                    Impact: "30"
                }
            ];
            return (
                <BetweenTeamsWrapper>
                    <BarChart width={760} height={280} data={data} margin={{
                        top: 0,
                        right: 0,
                        left: 55,
                        bottom: 0,
                    }}>
                        <CartesianGrid strokeDasharray="4 1" vertical={false}/>
                        <XAxis dataKey="name" style={{
                            fontSize: '14px'
                        }}/>
                        <YAxis ticks={[40, 60, 80]}
                               tickFormatter={value => value <= 40 ? `Needs Improvements` : value <= 60 && value > 40 ? `Good` : `Optimal`}
                               type="number"
                               domain={[0, 100]}/>

                        <Tooltip/>

                        <Legend formatter={(value, entry, index) => <span style={{color: '#5F6366'}}>{value}</span>}
                                align="right" verticalAlign={"middle"} height={80} iconType={"circle"}
                                layout={"vertical"}
                                wrapperStyle={{position: 'fixed', left: "700px", marginTop: '40px'}}/>
                        {/*<ReferenceLine y={25} stroke="#000"/>*/}

                        <Bar dataKey="Quality" fill="#C3FDEE" radius={[5, 5, 0, 0]}/>
                        <Bar dataKey="Pleasantness" fill="#F7C9D9" radius={[5, 5, 0, 0]}/>
                        <Bar dataKey="Impact" fill="#FFEDC2" radius={[5, 5, 0, 0]}/>
                    </BarChart>
                </BetweenTeamsWrapper>
            )
        }

        if (props.ComponentShown === "collaborationRight") {
            let collaborationData = props.data[2].reduce((agg, curr) => {
                let found = agg.find((x) => x.team === curr.team);
                if (found) {
                    found.employee.push(curr);
                } else {
                    agg.push({
                        team: curr.team,
                        employee: [curr]
                    });
                }
                return agg;
            }, []);

            collaborationData.map(team => {
                let team_impact = 0;
                let team_pleasantness = 0;
                let team_sharing_portion = 0;
                let team_sharing_quality = 0;

                team.employee.map(employee => {
                    team_impact += employee.impact;
                    team_pleasantness += employee.pleasantness;
                    team_sharing_portion += employee.sharing_portion;
                    team_sharing_quality += employee.sharing_quality;
                })
                team.impact = team_impact / team.employee.length;
                team.pleasantness = team_pleasantness / team.employee.length;
                team.sharing_portion = team_sharing_portion / team.employee.length;
                team.sharing_quality = team_sharing_quality / team.employee.length;
            })

            collaborationData.sort(function (a, b) {
                return a.impact - b.impact || a.pleasantness - b.pleasantness || a.sharing_quality - b.sharing_quality;
            });

            /** Values for Bar Chart */
            const data = [
                {
                    name: context.setTeams(collaborationData[0].team.split('-')[1]),
                    Quality: parseInt((collaborationData[0].sharing_quality * 100 / 5).toFixed(0)),
                    Pleasantness: parseInt((collaborationData[0].pleasantness * 100 / 5).toFixed(0)),
                    Sharing: (collaborationData[0].sharing_portion * 100).toFixed(0),
                    Receiving: ((1 - collaborationData[0].sharing_portion) * 100).toFixed(0),
                    Impact: parseInt((collaborationData[0].impact * 100 / 5).toFixed(0)),
                },
                {
                    name: context.setTeams(collaborationData[1].team.split('-')[1]),
                    Quality: parseInt((collaborationData[1].sharing_quality * 100 / 5).toFixed(0)),
                    Pleasantness: parseInt((collaborationData[1].pleasantness * 100 / 5).toFixed(0)),
                    Sharing: (collaborationData[1].sharing_portion * 100).toFixed(0),
                    Receiving: ((1 - collaborationData[1].sharing_portion) * 100).toFixed(0),
                    Impact: parseInt((collaborationData[1].impact * 100 / 5).toFixed(0)),

                },
                {
                    name: context.setTeams(collaborationData[2].team.split('-')[1]),
                    Quality: parseInt((collaborationData[2].sharing_quality * 100 / 5).toFixed(0)),
                    Pleasantness: parseInt((collaborationData[2].pleasantness * 100 / 5).toFixed(0)),
                    Sharing: (collaborationData[2].sharing_portion * 100).toFixed(0),
                    Receiving: ((1 - collaborationData[2].sharing_portion) * 100).toFixed(0),
                    Impact: parseInt((collaborationData[2].impact * 100 / 5).toFixed(0)),

                },
                {
                    name: context.setTeams(collaborationData[3].team.split('-')[1]),
                    Quality: parseInt((collaborationData[3].sharing_quality * 100 / 5).toFixed(0)),
                    Pleasantness: parseInt((collaborationData[3].pleasantness * 100 / 5).toFixed(0)),
                    Sharing: (collaborationData[3].sharing_portion * 100).toFixed(0),
                    Receiving: ((1 - collaborationData[3].sharing_portion) * 100).toFixed(0),
                    Impact: parseInt((collaborationData[3].impact * 100 / 5).toFixed(0)),
                }
            ];
            return (
                <BetweenTeamsWrapper>
                    <BarChart width={760} height={280} data={data} margin={{
                        top: 0,
                        right: 0,
                        left: 55,
                        bottom: 0,
                    }}>
                        <CartesianGrid strokeDasharray="4 1" vertical={false}/>
                        <XAxis dataKey="name" style={{
                            fontSize: '14px'
                        }}/>
                        <YAxis ticks={[40, 60, 80]}
                               tickFormatter={value => value <= 40 ? `Needs Improvements` : value <= 60 && value > 40 ? `Good` : `Optimal`}
                               type="number"
                               domain={[0, 100]}/>

                        <Tooltip/>

                        <Legend formatter={(value, entry, index) => <span style={{color: '#5F6366'}}>{value}</span>}
                                align="right" verticalAlign={"middle"} height={80} iconType={"circle"}
                                layout={"vertical"}
                                wrapperStyle={{position: 'fixed', marginTop: '20px'}}/>
                        {/*<ReferenceLine y={25} stroke="#000"/>*/}

                        <Bar dataKey="Quality" fill="#C3FDEE" radius={[5, 5, 0, 0]}/>
                        <Bar dataKey="Pleasantness" fill="#F7C9D9" radius={[5, 5, 0, 0]}/>
                        <Bar dataKey="Sharing" fill="#DFD6EA" radius={[5, 5, 0, 0]}/>
                        <Bar dataKey="Receiving" fill="#D0E1F1" radius={[5, 5, 0, 0]}/>
                        <Bar dataKey="Impact" fill="#FFEDC2" radius={[5, 5, 0, 0]}/>

                    </BarChart>
                </BetweenTeamsWrapper>
            )
        }

        if (props.ComponentShown === "followUp") {

            /** Values for Bar Chart */
            const data = [
                {
                    name: context.setTeams(props.data[0].team, i18n.language),
                    Knowledge: parseInt((props.data[0].knowledge * 100 / props.maxValue).toFixed(0)),
                    Collaboration: parseInt((props.data[0].collaboration * 100 / props.maxValue).toFixed(0)),
                    Driving: parseInt((props.data[0].driving_force * 100 / props.maxValue).toFixed(0)),
                },
                {
                    name: context.setTeams(props.data[1].team, i18n.language),
                    Knowledge: parseInt((props.data[1].knowledge * 100 / props.maxValue).toFixed(0)),
                    Collaboration: parseInt((props.data[1].collaboration * 100 / props.maxValue).toFixed(0)),
                    Driving: parseInt((props.data[1].driving_force * 100 / props.maxValue).toFixed(0)),

                },
                {
                    name: context.setTeams(props.data[2].team, i18n.language),
                    Knowledge: parseInt((props.data[2].knowledge * 100 / props.maxValue).toFixed(0)),
                    Collaboration: parseInt((props.data[2].collaboration * 100 / props.maxValue).toFixed(0)),
                    Driving: parseInt((props.data[2].driving_force * 100 / props.maxValue).toFixed(0)),
                },
            ];
            return (
                <BarWrapper>
                    <BarChart width={800} height={360} data={data}>
                        <CartesianGrid strokeDasharray="5 5"/>
                        {/* <ReferenceLine y={68}
                                       label={<Label value="Collaboration benchmark" position="right"/>}
                                       stroke="#4183BD" strokeDasharray="5 5"/>
                        <ReferenceLine y={77}
                                       label={<Label value="Knowledge benchmark" position="right"/>}
                                       stroke="#D0E1F1" strokeDasharray="5 5"/>
                        <ReferenceLine y={59}
                                       label={<Label value="Driving force benchmark" position="right"/>}
                                       stroke="#26547C" strokeDasharray="5 5"/> */}
                        <XAxis dataKey="name" axisLine={false}/>
                        <YAxis ticks={[0, 50, 100]}
                               domain={[0, 100]}
                               tickFormatter={tick => `${tick}%`}
                               axisLine={false}
                        />
                        <Tooltip/>
                        <Legend verticalAlign="top" height={36} iconType="circle"
                                formatter={(value, entry, index) => <span
                                    style={{
                                        color: '#271B36', textDecoration: 'underline', fontSize: 14,
                                        fontFamily: 'Overpass'
                                    }}>
                                    {value === "Collaboration" ? t('DashboardFollowUp.Collaboration') :
                                        value === "Driving" ? t('DashboardFollowUp.DrivingForce') : t('DashboardFollowUp.Knowledge')}</span>}
                        />
                        <Bar barSize={35} dataKey="Collaboration" fill="#4183BD" radius={[20, 20, 0, 0]}/>
                        <Bar barSize={35} dataKey="Knowledge" fill="#D0E1F1" radius={[20, 20, 0, 0]}/>
                        <Bar barSize={35} dataKey="Driving" fill="#26547C" radius={[20, 20, 0, 0]}/>
                    </BarChart>
                </BarWrapper>
            )
        }
    }


}

const CollaborationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const BetweenTeamsWrapper = styled.div`
`;

const BarWrapper = styled.div`
  margin-top: 16px;
`;

const BarChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BarChartLabel = styled.div`
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  width: 84px;
  color: #5F6366;
`;

