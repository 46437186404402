import React, {useEffect, useState, useContext, useRef} from "react";
import styled from 'styled-components';

import {useTranslation} from 'react-i18next';
import {ReactComponent as EITRLogoPink} from "../../assets/header/Icon_eitr2_logo.svg";
import {Checkbox} from '@mui/material';
import {ReactComponent as HeaderIconSettings} from "../../assets/header/Icon_settings.svg";
import {ReactComponent as HeaderIconSettingsActive} from "../../assets/header/Icon_settings_active.svg";
import ComponentDockOverlay from './Component-dock-overlay'; //TODO using Context Hook to load in component
import ComponentLanguageSwitcher from "./Component-language-switcher";
import {ReactComponent as SideBarMenuOpened} from "../../assets/dashboard/SideBarMenuOpened.svg";
import {ReactComponent as SideBarMenuClosed} from "../../assets/dashboard/SideBarMenuClosed.svg";
import {ReactComponent as Logout} from "../../assets/dashboard/logout.svg";
import {StyledDivRegular} from "./Styling-TextInput";
import { OverlayContext } from "../context-hooks/OverlayContext";
import {ReactComponent as LogoDark} from '../../assets/intake/LogoDark.svg';
import {ReactComponent as IconChevronDown} from '../../assets/intake/IconChevronDown.svg';
import {ReactComponent as IconCircleQuestion} from '../../assets/intake/IconCircleQuestion.svg';
import {ReactComponent as IconCompare} from '../../assets/intake/IconCompare.svg';
import {ReactComponent as IconPlus} from '../../assets/intake/IconPlus.svg';
import {ReactComponent as IconPencil} from '../../assets/intake/IconPencil.svg';
import {ReactComponent as IconMove} from '../../assets/intake/IconMove.svg';
import {ReactComponent as IconTrash} from '../../assets/intake/IconTrash.svg';
import {ReactComponent as IconArrowForward} from '../../assets/intake/IconArrowForward.svg';
import {ReactComponent as IconPaperPlane} from '../../assets/intake/IconPaperPlane.svg';
import {ReactComponent as IconSignOut} from '../../assets/intake/IconSignOut.svg';
import GifComparingVersions from '../../assets/intake/GifComparingVersions.gif';
import GifAddMainProcess from '../../assets/intake/GifAddMainProcess.gif';
import GifAddSubProcess from '../../assets/intake/GifAddSubProcess.gif';
import GifRenameMainProcess from '../../assets/intake/GifRenameMainProcess.gif';
import GifRenameSubProcess from '../../assets/intake/GifRenameSubProcess.gif';
import GifMoveMainProcess from '../../assets/intake/GifMoveMainProcess.gif';
import GifMoveSubProcess from '../../assets/intake/GifMoveSubProcess.gif';
import GifDeleteMainProcess from '../../assets/intake/GifDeleteMainProcess.gif';
import GifDeleteSubProcess from '../../assets/intake/GifDeleteSubProcess.gif';

function ComponentHeaderBlock(componentprops) {
    const { t } = useTranslation();

    /** Component to load in different header styling, combined with an introduction overlay */

    /** Overlay dock state, with big picture introduction */
    const [dockstatus, dockVisible] = useState(true);
    const localStorageLanguage = JSON.parse(localStorage.getItem("Language")) ? JSON.parse(localStorage.getItem('Language')) : 'en-US';
    const [currentLanguage, setCurrentLanguage] = useState('en-US');
    const [isOpen, setIsOpen] = useState(false);
    const currentUser = JSON.parse(localStorage.getItem("current_user_data"))[0];
    const context = useContext(OverlayContext);
    

    useEffect(() => {
        if (componentprops.hasOwnProperty('set_selected_language')) {
            setCurrentLanguage(componentprops.set_selected_language(localStorageLanguage));
        }
    }, [currentLanguage]);

    const [isHowToUseOpen, setIsHowToUseOpen] = useState(false);
    const [isShowingHowToUseExample, setIsShowingHowToUseExample] = useState(false);
    const [selectedHowToUseExample, setSelectedHowToUseExample] = useState({ howToUseExampleIndex: null})
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const howToUseRef = useRef(null);
    const profileRef = useRef(null);

    useEffect(() => {
        const closeDropdowns = (e) => {
            if (howToUseRef.current && !howToUseRef.current.contains(e.target) && profileRef.current && !profileRef.current.contains(e.target)) {
                setIsHowToUseOpen(false);
                setIsProfileOpen(false);
            } else if (howToUseRef.current && !howToUseRef.current.contains(e.target)) {
                setIsHowToUseOpen(false);
            } else if (profileRef.current && !profileRef.current.contains(e.target)) {
                setIsProfileOpen(false);
            }
        };

        document.addEventListener('click', closeDropdowns);
        
        return () => {
          document.removeEventListener('click', closeDropdowns);
        };
        
    }, []);

    const handleMouseEnter = (howToUseExampleIndex) => {
        setSelectedHowToUseExample({ howToUseExampleIndex: howToUseExampleIndex });
        setIsShowingHowToUseExample(true);
    }

    const handleMouseLeave = () => {
        setIsShowingHowToUseExample(false);
        setSelectedHowToUseExample({ howToUseExampleIndex: null });
    }

    if (componentprops.page === "IntakeBuilder") {
        if (componentprops.identifier === "IntakeBuilderLeader" || componentprops.identifier === "IntakeBuilderRepresentative" || componentprops.identifier === "IntakeComparison") {
            return (
                <HeaderContainer Width='calc(100% - 80px)' Height='auto' Padding='12px 40px' JustifyContent='space-between' AlignItems='center'>
                    <HeaderLogoWrapper onClick={() => window.location.replace(process.env.REACT_APP_APPURL + '/home')} Position='static' Display='flex' MarginTop='0' MarginLeft='0'>
                        <LogoDark/>
                    </HeaderLogoWrapper>

                    <HeaderSectionWrapper>
                        <ComponentLanguageSwitcher reRender={componentprops.reRender} Position='static' Height='auto' Padding='4px 12px' MarginTop='0' MarginLeft='0' MarginRight='0' BorderRadius='8px' Background='#E3E5E4'/>

                        <DropdownContainer ref={howToUseRef}>
                            <DropdownWrapper>     
                                {isShowingHowToUseExample && (
                                    (selectedHowToUseExample.howToUseExampleIndex === 1) ? (
                                        <DropdownExampleWrapper>
                                            <ExamplePointWrapper>
                                                <ExamplePointGifWrapper>
                                                    <img src={GifAddMainProcess} style={{width: 'auto', height: '100%'}} alt=''/>
                                                </ExamplePointGifWrapper>

                                                <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#5F6366' FontSize='16px' LineHeight='20px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                                                    {t('PageIntakeIntroduction.Instructions2TextPoint1')}
                                                </StyledDivRegular>
                                            </ExamplePointWrapper>

                                            <ExamplePointWrapper>
                                                <ExamplePointGifWrapper>
                                                    <img src={GifAddSubProcess} style={{width: 'auto', height: '100%'}} alt=''/>
                                                </ExamplePointGifWrapper>

                                                <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#5F6366' FontSize='16px' LineHeight='20px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                                                    {t('PageIntakeIntroduction.Instructions2TextPoint2')}
                                                </StyledDivRegular>
                                            </ExamplePointWrapper>
                                        </DropdownExampleWrapper>
                                    ) : (selectedHowToUseExample.howToUseExampleIndex === 2) ? (
                                        <DropdownExampleWrapper>
                                            <ExamplePointWrapper>
                                                <ExamplePointGifWrapper>
                                                    <img src={GifRenameMainProcess} style={{width: 'auto', height: '100%'}} alt=''/>
                                                </ExamplePointGifWrapper>

                                                <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#5F6366' FontSize='16px' LineHeight='20px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                                                    {t('PageIntakeIntroduction.Instructions3TextPoint1')}
                                                </StyledDivRegular>
                                            </ExamplePointWrapper>

                                            <ExamplePointWrapper>
                                                <ExamplePointGifWrapper>
                                                    <img src={GifRenameSubProcess} style={{width: 'auto', height: '100%'}} alt=''/>
                                                </ExamplePointGifWrapper>

                                                <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#5F6366' FontSize='16px' LineHeight='20px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                                                    {t('PageIntakeIntroduction.Instructions3TextPoint2')}
                                                </StyledDivRegular>
                                            </ExamplePointWrapper>
                                        </DropdownExampleWrapper>
                                    ) : (selectedHowToUseExample.howToUseExampleIndex === 3) ? (
                                        <DropdownExampleWrapper>
                                            <ExamplePointWrapper>
                                                <ExamplePointGifWrapper>
                                                    <img src={GifMoveMainProcess} style={{width: 'auto', height: '100%'}} alt=''/>
                                                </ExamplePointGifWrapper>

                                                <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#5F6366' FontSize='16px' LineHeight='20px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                                                    {t('PageIntakeIntroduction.Instructions4TextPoint1')}
                                                </StyledDivRegular>
                                            </ExamplePointWrapper>

                                            <ExamplePointWrapper>
                                                <ExamplePointGifWrapper>
                                                    <img src={GifMoveSubProcess} style={{width: 'auto', height: '100%'}} alt=''/>
                                                </ExamplePointGifWrapper>

                                                <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#5F6366' FontSize='16px' LineHeight='20px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                                                    {t('PageIntakeIntroduction.Instructions4TextPoint2')}
                                                </StyledDivRegular>
                                            </ExamplePointWrapper>
                                        </DropdownExampleWrapper>
                                    ) : (selectedHowToUseExample.howToUseExampleIndex === 4) ? (
                                        <DropdownExampleWrapper>
                                            <ExamplePointWrapper>
                                                <ExamplePointGifWrapper>
                                                    <img src={GifDeleteMainProcess} style={{width: 'auto', height: '100%'}} alt=''/>
                                                </ExamplePointGifWrapper>

                                                <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#5F6366' FontSize='16px' LineHeight='20px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                                                    {t('PageIntakeIntroduction.Instructions5TextPoint1')}
                                                </StyledDivRegular>
                                            </ExamplePointWrapper>

                                            <ExamplePointWrapper>
                                                <ExamplePointGifWrapper>
                                                    <img src={GifDeleteSubProcess} style={{width: 'auto', height: '100%'}} alt=''/>
                                                </ExamplePointGifWrapper>

                                                <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#5F6366' FontSize='16px' LineHeight='20px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                                                    {t('PageIntakeIntroduction.Instructions5TextPoint2')}
                                                </StyledDivRegular>
                                            </ExamplePointWrapper>
                                        </DropdownExampleWrapper>
                                    ) : (
                                        <DropdownExampleWrapper>
                                            <ExamplePointWrapper>
                                                <ExamplePointGifWrapper>
                                                    <img src={GifComparingVersions} style={{width: 'auto', height: '100%'}} alt=''/>
                                                </ExamplePointGifWrapper>

                                                <StyledDivRegular Position='static' Width='auto' Height='auto' Color='#5F6366' FontSize='16px' LineHeight='20px' TextAlign='center' FontWeight='700' LetterSpacing='0.3px'>
                                                    {t('PageIntakeIntroduction.Instructions1TextPoint')}
                                                </StyledDivRegular>
                                            </ExamplePointWrapper>
                                        </DropdownExampleWrapper>
                                    )
                                )}

                                {isHowToUseOpen && (
                                    <DropdownListWrapper>
                                        {(componentprops.identifier === 'IntakeComparison') && (
                                            <DropdownButtonWrapper onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={handleMouseLeave}>
                                                <IconCompare/>

                                                <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#5F6366'>
                                                    {t('PageIntakeBuilder.InstructionsTextPoint1')}
                                                </StyledDivRegular>
                                            </DropdownButtonWrapper>
                                        )}

                                        <DropdownButtonWrapper onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={handleMouseLeave}>
                                            <IconPlus/>

                                            <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#5F6366'>
                                                {t('PageIntakeBuilder.InstructionsTextPoint2')}
                                            </StyledDivRegular>
                                        </DropdownButtonWrapper>

                                        <DropdownButtonWrapper onMouseEnter={() => handleMouseEnter(2)} onMouseLeave={handleMouseLeave}>
                                            <IconPencil/>

                                            <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#5F6366'>
                                                {t('PageIntakeBuilder.InstructionsTextPoint3')}
                                            </StyledDivRegular>
                                        </DropdownButtonWrapper>

                                        <DropdownButtonWrapper onMouseEnter={() => handleMouseEnter(3)} onMouseLeave={handleMouseLeave}>
                                            <IconMove/>

                                            <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#5F6366'>
                                                {t('PageIntakeBuilder.InstructionsTextPoint4')}
                                            </StyledDivRegular>
                                        </DropdownButtonWrapper>

                                        <DropdownButtonWrapper onMouseEnter={() => handleMouseEnter(4)} onMouseLeave={handleMouseLeave}>
                                            <IconTrash/>

                                            <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#5F6366'>
                                                {t('PageIntakeBuilder.InstructionsTextPoint5')}
                                            </StyledDivRegular>
                                        </DropdownButtonWrapper>
                                    </DropdownListWrapper>
                                )}                       
                            </DropdownWrapper>
                            
                            <HeaderButtonWrapper onClick={() => {setIsHowToUseOpen(!isHowToUseOpen)}} Filter={isHowToUseOpen && 'brightness(90%)'}>
                                <IconCircleQuestion/>

                                <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#5F6366' FontWeight='700' LetterSpacing='0.3px'>
                                    {t('PageIntakeBuilder.InstructionsTextHeader')}
                                </StyledDivRegular>

                                <IconChevronDown style={{transform: isHowToUseOpen ? 'rotate(180deg)' : ''}}/>
                            </HeaderButtonWrapper>
                        </DropdownContainer>

                        <DropdownContainer ref={profileRef}>
                            {isProfileOpen && (
                                <DropdownWrapper>
                                    <DropdownListWrapper>
                                        <DropdownButtonWrapper onClick={() => window.location.replace(process.env.REACT_APP_APPURL + '/logout')}>
                                            <IconSignOut/>

                                            <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#5F6366'>
                                                {t('PageIntakeBuilder.ProfileTextPoint')}
                                            </StyledDivRegular>
                                        </DropdownButtonWrapper>
                                    </DropdownListWrapper>
                                </DropdownWrapper>
                            )}

                            <HeaderButtonWrapper onClick={() => setIsProfileOpen(!isProfileOpen)} Width='auto' Padding='4px 12px 4px 4px' Filter={isProfileOpen && 'brightness(90%)'}>
                                <StyledDivRegular Position="static" Display="flex" Width="36px" Height="36px" JustifyContent="center" AlignItems="center" BorderRadius="100px" Background="#F7C9D9" Color="#5F6366">
                                    {context.setFirstName(currentUser.first_name, 0).charAt(0) + context.setFirstName(currentUser.last_name, 0).split(" ").filter((str) => str !== '').pop().charAt(0)}
                                </StyledDivRegular>

                                <IconChevronDown style={{transform: isProfileOpen ? 'rotate(180deg)' : ''}}/>
                            </HeaderButtonWrapper>
                        </DropdownContainer>

                        <HeaderButtonWrapper onClick={componentprops.publishMainProcesses} MarginLeft='24px' Background='#E2336B'>
                            {(componentprops.identifier === 'IntakeBuilderLeader' || componentprops.identifier === 'IntakeComparison') ? 
                                <IconArrowForward/>
                            : 
                                <IconPaperPlane/>
                            }

                            <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#FCFCFC' FontWeight='700' LetterSpacing='0.3px'>
                                {(componentprops.identifier === 'IntakeBuilderLeader' || componentprops.identifier === 'IntakeComparison') ? (
                                    <>
                                        {t('PageIntakeBuilder.NextTextHeader')}
                                    </>
                                ) : (
                                    <>
                                        {t('PageIntakeBuilder.PublishTextHeader')}
                                    </>
                                )}
                            </StyledDivRegular>
                        </HeaderButtonWrapper>
                    </HeaderSectionWrapper>
                </HeaderContainer>
            );
        }
    }

    if (componentprops.page === 'IntakeRepresentative' || componentprops.page === 'ProcessTime') {
        return (
            <HeaderContainer Width='calc(100% - 80px)' Height='auto' Padding='12px 40px' JustifyContent='space-between' AlignItems='center'>
                <HeaderLogoWrapper onClick={() => window.location.replace(process.env.REACT_APP_APPURL + '/home')} Position='static' Display='flex' MarginTop='0' MarginLeft='0'>
                    <LogoDark/>
                </HeaderLogoWrapper>

                <HeaderSectionWrapper>
                    <ComponentLanguageSwitcher reRender={componentprops.reRender} Position='static' Height='auto' Padding='4px 12px' MarginTop='0' MarginLeft='0' MarginRight='0' BorderRadius='8px' Background='#E3E5E4'/>

                    <DropdownContainer ref={profileRef}>
                        {isProfileOpen && (
                            <DropdownWrapper>
                                <DropdownListWrapper>
                                    <DropdownButtonWrapper onClick={() => window.location.replace(process.env.REACT_APP_APPURL + '/logout')}>
                                        <IconSignOut/>

                                        <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#5F6366'>
                                            {t('PageIntakeBuilder.ProfileTextPoint')}
                                        </StyledDivRegular>
                                    </DropdownButtonWrapper>
                                </DropdownListWrapper>
                            </DropdownWrapper>
                        )}

                        <HeaderButtonWrapper onClick={() => setIsProfileOpen(!isProfileOpen)} Width='auto' Padding='4px 12px 4px 4px' Filter={isProfileOpen && 'brightness(90%)'}>
                            <StyledDivRegular Position="static" Display="flex" Width="36px" Height="36px" JustifyContent="center" AlignItems="center" BorderRadius="100px" Background="#F7C9D9" Color="#5F6366">
                                {context.setFirstName(currentUser.first_name, 0).charAt(0) + context.setFirstName(currentUser.last_name, 0).split(" ").filter((str) => str !== '').pop().charAt(0)}
                            </StyledDivRegular>

                            <IconChevronDown style={{transform: isProfileOpen ? 'rotate(180deg)' : ''}}/>
                        </HeaderButtonWrapper>
                    </DropdownContainer>

                    <HeaderButtonWrapper onClick={componentprops.handleClick} isDisabled={componentprops.isDisabled} MarginLeft='24px' Background='#E2336B'>
                        <IconPaperPlane/>

                        <StyledDivRegular Position='static' Width='auto' Height='auto' FontSize='16px' LineHeight='20px' Color='#FCFCFC' FontWeight='700' LetterSpacing='0.3px'>
                            {t('PageIntakeBuilder.PublishTextHeader')}
                        </StyledDivRegular>
                    </HeaderButtonWrapper>
                </HeaderSectionWrapper>
            </HeaderContainer>
        );
    }

    if (componentprops.page === "Survey") {

        return (
            <HeaderContainer>

                {/* <SurveyHeaderIconHelp MarginLeft={MarginLeftHelpIcon}>
                    <button onClick={() => handleDock()}>
                        <HeaderIconHelp2/>
                    </button>
                </SurveyHeaderIconHelp> */}

                <HeaderLogoWrapper
                    onClick={() => window.location.replace(process.env.REACT_APP_APPURL + '/home')}>
                    <EITRLogoPink/>
                </HeaderLogoWrapper>

                <CheckboxWrapper MarginTop='20px' MarginLeft='90%' Width="fit-content">

                    <ComponentLanguageSwitcher reRender={componentprops.reRender} MarginLeft="0%" MarginRight="24px"
                                               set_language_switcher={setCurrentLanguage}/>

                    <Checkbox style={{fontSize: 35}}
                              icon={<HeaderIconSettings/>}
                              checkedIcon={<HeaderIconSettingsActive/>}
                              onClick={() => setIsOpen(!isOpen)}/>

                    {isOpen && 
                    (<DropdownContent>
                        <button onClick={() => window.location.replace(process.env.REACT_APP_APPURL + '/logout')}>

                            <Logout/>
                            <p>
                                {t('Logout.LogoutButton')}
                            </p>

                        </button>
                    </DropdownContent>)}

                </CheckboxWrapper>
                
                <CheckboxWrapper MarginTop='6px' MarginLeft='93.100%' Height='50px' Width='50px'>
                    <PersonPic>
                        <StyledDivRegular Position="absolute" BorderRadius="50%" Width="36px" Height="36px" Background="#D0E1F1"
                            Color="#271B36" Display="flex" AlignItems="center" JustifyContent="center"
                        >
                            {context.setFirstName(currentUser.first_name, 0).charAt(0)
                                + context.setFirstName(currentUser.last_name, 0).split(" ").filter((str) => str !== '').pop().charAt(0)}
                        </StyledDivRegular>
                    </PersonPic>
                </CheckboxWrapper>

                <SurveyDockWrapper>
                    <ComponentDockOverlay dock={dockstatus} setDock={dockVisible} whichpage={componentprops.overlay}
                    />
                </SurveyDockWrapper>

            </HeaderContainer>
        );
    }

    if (componentprops.page === "Dashboard") {

        return (
            <HeaderContainer BorderBottom='1px solid #F1F3F4' BoxShadow='0px 4px 20px #F1F3F4'>
                <CloseOpenButton MarginTop='10px'>
                    <button onClick={() => componentprops.setSideBarOpen(!componentprops.sidebarOpen)}>
                        {componentprops.sidebarOpen ? <SideBarMenuOpened/> : <SideBarMenuClosed/>}
                    </button>
                </CloseOpenButton>

                {/*<CheckboxWrapper MarginTop='20px' MarginLeft='86.81000%'>*/}
                {/*    <Checkbox style={{fontSize: 35}}*/}
                {/*              icon={<HeaderIconNotification/>}*/}
                {/*              checkedIcon={<HeaderIconNotificationActive/>}/>*/}
                {/*</CheckboxWrapper>*/}

                {/*<CheckboxWrapper MarginTop='20px' MarginLeft='89.1667%'>*/}
                {/*    <Checkbox style={{fontSize: 35}}*/}
                {/*              icon={<HeaderIconHelp/>}*/}
                {/*              checkedIcon={<HeaderIconHelpActive/>}/>*/}
                {/*</CheckboxWrapper>*/}


                <CheckboxWrapper MarginTop='20px' MarginLeft='91.4583%'>

                    <ComponentLanguageSwitcher reRender={componentprops.reRender} MarginLeft={"80%"}
                                               set_language_switcher={setCurrentLanguage}/>

                    <Checkbox style={{fontSize: 35}}
                              icon={<HeaderIconSettings/>}
                              checkedIcon={<HeaderIconSettingsActive/>}
                              onClick={() => setIsOpen(!isOpen)}/>

                    {isOpen && (<DropdownContent>
                        <button onClick={() => window.location.replace(process.env.REACT_APP_APPURL + '/logout')}>

                            <Logout/>

                            <p>
                                {t('Logout.LogoutButton')}
                            </p>

                        </button>
                    </DropdownContent>)}

                </CheckboxWrapper>

                <CheckboxWrapper MarginTop='6px' MarginLeft='93.100%' Height='50px' Width='50px'>
                    <PersonPic>
                        <StyledDivRegular Position="absolute" BorderRadius="50%" Width="36px" Height="36px" Background="#D0E1F1"
                            Color="#271B36" Display="flex" AlignItems="center" JustifyContent="center" >
                            {context.setFirstName(currentUser.first_name, 0).charAt(0)
                                + context.setFirstName(currentUser.last_name, 0).split(" ").filter((str) => str !== '').pop().charAt(0)}
                        </StyledDivRegular>
                    </PersonPic>
                </CheckboxWrapper>

            </HeaderContainer>
        )
    } else {
        return (
            <HeaderContainer>
                <HeaderLogoWrapper>
                    <EITRLogoPink/>
                </HeaderLogoWrapper>
            </HeaderContainer>
        )
    }
};
export default ComponentHeaderBlock

const HeaderContainer = styled.div`
  position: fixed;
  display: flex;
  width: ${props => props.Width || "100%"};
  height: ${props => props.Height || "64px"};
  padding: ${props => props.Padding || ''};
  justify-content: ${props => props.JustifyContent || ''};
  align-items: ${props => props.AlignItems || ''};
  border-bottom: ${props => props.BorderBottom || "1px solid #E3E5E4"};
  background-color: #FCFCFC;
  box-shadow: ${props => props.BoxShadow || ''};
  user-select: none;
  z-index: 3;
`;

export const HeaderLogoWrapper = styled.div`
  position: ${props => props.Position || 'fixed'};
  display: ${props => props.Display || ''};
  margin-top: ${props => props.MarginTop || '8px'};
  margin-left: ${props => props.MarginLeft || '3.6458%'};
  cursor: pointer;
`;

const HeaderSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const DropdownContainer = styled.div`
  position: relative;
  display: flex;
`;

const HeaderButtonWrapper = styled.div`
  display: flex;
  width: ${props => props.Width || '151px'};
  padding: ${props => props.Padding || '12px'};
  margin-left: ${props => props.MarginLeft || ''};
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  opacity: ${props => props.isDisabled ? '0.5' : props.Opacity || '1'};
  background: ${props => props.Background || '#E3E5E4'};
  filter: ${props => props.Filter || ''};
  transition: filter 0.15s;
  cursor: ${props => props.isDisabled ? '' : 'pointer'};
  user-select: none;

  &:hover {
    filter: ${props => props.isDisabled ? '' : 'brightness(90%)'};
  }
`;

const DropdownWrapper = styled.div`
  position: absolute;
  top: 52px;
  right: 0;
  display: flex;
  flex-direction: reverse-row;
  gap: 8px;
`;

const DropdownListWrapper = styled.div`
  display: flex;
  width: max-content;
  height: min-content;
  padding: 4px;
  flex-direction: column;
  border-radius: 8px;
  background: #FCFCFC;
  box-shadow: 0px 4px 25px 0px rgba(39, 27, 54, 0.10);
`;

const DropdownButtonWrapper = styled.div`
  display: flex;
  width: (100% - 24px);
  padding: 12px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px;
  background: #FCFCFC;
  transition: filter 0.15s;
  cursor: pointer;
  user-select: none;

  &:hover {
    filter: brightness(90%);
  }
`;

const DropdownExampleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 20px;
  border-radius: 8px;
  background: #FCFCFC;
  box-shadow: 0px 4px 25px 0px rgba(39, 27, 54, 0.10);
`;

const ExamplePointWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const ExamplePointGifWrapper = styled.div`
  display: flex;
  height: calc(50vh - 102px);
  max-height: 354.5px;
  border-radius: 8px;
  overflow: hidden;
`;

const CheckboxWrapper = styled.div`
  position: fixed;
  cursor: pointer;
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "0%"};
  width: ${props => props.Width || "24px"};
  height: ${props => props.Height || "24px"};
  color: ${props => props.inputColor || 'transparent'};
  background-color: #fcfcfc;
  border-color: ${props => props.BorderColor || 'transparent'}; //1px solid #E3E5E4 
  /* box-shadow: ${props => props.BoxShadow || '0px 4px 20px #F1F3F4'}; */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
`;

const DropdownContent = styled.div`
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  top: 45px;

  button {
    padding-left: 10px;
    height: 48px;
    border: none;
    background: #f9f9f9;
    color: ${props => props.Color || '#5F6366'};
    border-radius: 10px;
    cursor: pointer;
    font-family: ${props => props.FontFamily || 'Overpass'};
    font-size: ${props => props.FontSize || '16px'};
    line-height: ${props => props.LineHeight || '20px'};
    font-weight: ${props => props.FontWeight || '400'};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: -webkit-fill-available;
    column-gap: 15px;
  }
`;

export const LanguageSwitcher = styled.div`
  margin-left: 100%;
  position: relative;
  height: 50px;
`;

export const SurveyHeaderText = styled.div`
  position: fixed;
  margin-top: ${props => props.MarginTop || "28px"};
  margin-left: ${props => props.MarginLeft || "890px"};
  font-family: 'Overpass', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #271B36;
  line-height: 36px;
  z-index: 2;
`;

export const SurveyHeaderIconHelp = styled.div`
  position: fixed;
  margin-top: ${props => props.MarginTop || "18px "};
    // margin-left: ${props => props.MarginLeft || "1672px"};
  margin-left: 87.4%;
  transform: scale(1.5);
  z-index: 9;

  button {
    border: none;
    background-color: transparent;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    cursor: ${props => props.Cursor || "pointer"};
    display: flex;
    align-content: center;
    justify-content: center;
  }
`;

export const SurveyHeaderTextHelp = styled.div`
  position: absolute;
  margin-top: ${props => props.MarginTop || "22px"};
    // margin-left: ${props => props.MarginLeft || "1706px"};
  margin-left: 89%;
  display: flex;
  align-content: center;
  justify-content: center;
  font-family: 'Overpass', sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  z-index: 9;

  button {
    border: none;
    background-color: transparent;
    color: #e2336B;
    height: 24px;
    width: 86px;
    cursor: ${props => props.Cursor || "pointer"};
    text-decoration: underline;
    display: flex;
    align-content: center;
    justify-content: center;
  }

`;

const SurveyDockWrapper = styled.div`
  background-color: #FCFCFC;
  font-family: 'Overpass', sans-serif;
  font-size: 16px; /* 42px --> 38px smaller size 1920x900px */
  font-weight: 400;
  line-height: 24px; /* 68px --> 61px smaller size 1920x900px */
  color: #C6CBCB;
  /* background-color: #e3316b; */
  position: fixed;
  z-index: 12;
`;

const CloseOpenButton = styled.div`
  margin-top: ${props => props.MarginTop || "0%"};
  margin-left: ${props => props.MarginLeft || "30px"};
  z-index: 3;
  width: ${props => props.Width || "44px"};
  position: fixed;

  button {
    background: #FCFCFC;
    padding-left: 10px;
    height: 48px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
`;

const PersonPic = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
`;


//Archive
// 
// /** Function to show introduction triggered by help button OnClick */
// const handleDock = () => {
//     dockVisible(!dockstatus);
// };

// if (componentprops.page === "LeaderIntake") {
//     return (
//         <HeaderContainer Height='64px' Width='1920px' Border='1px solid #E3E5E4'>

//             <SurveyHeaderLogoWrapper>
//                 <EITRLeaderIntake/>
//             </SurveyHeaderLogoWrapper>

//         </HeaderContainer>
//     );
// }

// if (componentprops.page === "LeaderIntakeGoals") {
//     return (
//         <HeaderContainer>

//             <HeaderLogoWrapper>
//                 <EITRLeaderIntake/>
//             </HeaderLogoWrapper>

//             <HeaderLineWrapper></HeaderLineWrapper>

//             <HeaderText1> <span>01.</span> <br/> Goal Setting </HeaderText1>
//             <HeaderText2 InputColor='#C6CBCB' SpanInputColor='#C6CBCB'> <span>02.</span> <br/> Key Performance
//                 Indicator </HeaderText2>
//             <HeaderText3 InputColor='#C6CBCB' SpanInputColor='#C6CBCB'> <span>03.</span> <br/> Problem
//                 Identification </HeaderText3>
//             <HeaderText4 InputColor='#C6CBCB' SpanInputColor='#C6CBCB'> <span>04.</span> <br/> Milestones
//             </HeaderText4>

//         </HeaderContainer>
//     );
// }

// if (componentprops.page === "LeaderIntakeKPI") {
//     return (
//         <HeaderContainer>

//             <HeaderLogoWrapper>
//                 <EITRLeaderIntake/>
//             </HeaderLogoWrapper>

//             <HeaderLineWrapper></HeaderLineWrapper>

//             <HeaderText1 InputColor='#C6CBCB' SpanInputColor='#C6CBCB'> <span>01.</span> <br/> Goal Setting
//             </HeaderText1>
//             <HeaderText2> <span>02.</span> <br/> Key Performance Indicator </HeaderText2>
//             <HeaderText3 InputColor='#C6CBCB' SpanInputColor='#C6CBCB'> <span>03.</span> <br/> Problem
//                 Identification </HeaderText3>
//             <HeaderText4 InputColor='#C6CBCB' SpanInputColor='#C6CBCB'> <span>04.</span> <br/> Milestones
//             </HeaderText4>

//         </HeaderContainer>
//     );
// }

// if (componentprops.page === "LeaderIntakeProblemIdentification") {
//     return (
//         <HeaderContainer>

//             <HeaderLogoWrapper>
//                 <EITRLeaderIntake/>
//             </HeaderLogoWrapper>

//             <HeaderLineWrapper></HeaderLineWrapper>

//             <HeaderText1 InputColor='#C6CBCB' SpanInputColor='#C6CBCB'> <span>01.</span> <br/> Goal Setting
//             </HeaderText1>
//             <HeaderText2 InputColor='#C6CBCB' SpanInputColor='#C6CBCB'> <span>02.</span> <br/> Key Performance
//                 Indicator </HeaderText2>
//             <HeaderText3> <span>03.</span> <br/> Problem Identification </HeaderText3>
//             <HeaderText4 InputColor='#C6CBCB' SpanInputColor='#C6CBCB'> <span>04.</span> <br/> Milestones
//             </HeaderText4>

//         </HeaderContainer>
//     );
// }

// if (componentprops.page === "LeaderIntakeMilestones") {
//     return (
//         <HeaderContainer>

//             <HeaderLogoWrapper>
//                 <EITRLeaderIntake/>
//             </HeaderLogoWrapper>

//             <HeaderLineWrapper></HeaderLineWrapper>

//             <HeaderText1 InputColor='#C6CBCB' SpanInputColor='#C6CBCB'> <span>01.</span> <br/> Goal Setting
//             </HeaderText1>
//             <HeaderText2 InputColor='#C6CBCB' SpanInputColor='#C6CBCB'> <span>02.</span> <br/> Key Performance
//                 Indicator </HeaderText2>
//             <HeaderText3 InputColor='#C6CBCB' SpanInputColor='#C6CBCB'> <span>03.</span> <br/> Problem
//                 Identification </HeaderText3>
//             <HeaderText4> <span>04.</span> <br/> Milestones </HeaderText4>

//         </HeaderContainer>
//     );
// }

// if (componentprops.page === "PageLeaderInput") {
//     return (
//         <HeaderContainer Height='64px' Width='1920px' Border='1px solid #E3E5E4'>

//             <SurveyHeaderIconHelp>
//                 <IconButton
//                     icon={<HeaderIconHelpActive/>}
//                     checkedIcon={<HeaderIconHelpActive/>}
//                     onChange={handleDock}
//                 />
//             </SurveyHeaderIconHelp>

//             <SurveyHeaderLogoWrapper>
//                 <EITRLogoPink/>
//             </SurveyHeaderLogoWrapper>

//             <SurveyHeaderTextHelp onClick={handleDock}>
//                 {i18next.t('Component-header-block-need-help')}
//             </SurveyHeaderTextHelp>

//             <SurveyDockWrapper>
//                 <ComponentDockOverlay dock={dockstatus} setDock={dockVisible} whichpage={componentprops.overlay} />
//             </SurveyDockWrapper>

//         </HeaderContainer>
//     );
// }

// if (componentprops.page === "PageLeaderTeamCompProcessCreator") {
//     return (
//         <HeaderContainer Height='64px' Width='1920px' Border='1px solid #E3E5E4'>

//             <SurveyHeaderLogoWrapper>
//                 <EITRLogoPink/>
//             </SurveyHeaderLogoWrapper>

//         </HeaderContainer>
//     );
// }

// // LeaderIntakeGoals
// const HeaderText1 = styled.div`
//   position: fixed;
//   margin-top: 1.111%;
//   margin-left: 27.4479%;
//   z-index: 2;
//   color: ${props => props.InputColor || "#271B36"};
//   font-family: 'Montserrat', sans-serif;
//   font-size: 16px;
//   font-weight: 700;
//   line-height: 20px;

//   span {
//     color: ${props => props.SpanInputColor || "#e3316b"};
//   }
// `;

// const HeaderLineWrapper = styled.div`
//   position: fixed;
//   background-color: #FCFCFC;
//   border-top: 1px solid #E3E5E4;
//   width: 100%;
//   margin-top: ${props => props.MarginTop || "4.444%"};
//   margin-left: ${props => props.MarginLeft || "0%"};
//   z-index: 50;
// `;

// const HeaderLogoWrapper = styled.div`
//   position: fixed;
//   margin-top: ${props => props.MarginTop || "0.8889%"};
//   margin-left: ${props => props.MarginLeft || "4.1667%"};
//   z-index: 2;
//   cursor: pointer;
// `;

// const HeaderText2 = styled(HeaderText1)`
//   margin-left: 37.031%;
//   color: ${props => props.InputColor || "#271B36"};

//   span {
//     color: ${props => props.SpanInputColor || "#e3316b"};
//   }
// `;

// const HeaderText3 = styled(HeaderText1)`
//   margin-left: 52.916%;
//   color: ${props => props.InputColor || "#271B36"};

//   span {
//     color: ${props => props.SpanInputColor || "#e3316b"};
//   }
// `;

// const HeaderText4 = styled(HeaderText1)`
//   margin-left: 66.979%;
//   color: ${props => props.InputColor || "#271B36"};

//   span {
//     color: ${props => props.SpanInputColor || "#e3316b"};
//   }
// `;