import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Fade, Tab, Tabs, Tooltip} from '@mui/material';
import {OverlayContext} from "../../layout-and-styling/context-hooks/OverlayContext";
import {DashboardContext} from "../DashboardContext";
import Metrics from "../dashboard-pages/Metrics";
import DashboardSocialNetwork from '../dashboard-social-web/Dashboard-social-network';

const DashboardSubTabs = () => {
    const dashboard_context_value = useContext(DashboardContext);

    return (
        <Container>
          
            <ProcessWrapper>

                {/* 1. Metrics content */
                }
                {dashboard_context_value.subTabSelection === "SubTabMetrics" && 
                    <> 
                        <Metrics/>    
                    </>
                }

                {/* 2. Actions content */
                }
                {dashboard_context_value.subTabSelection === "SubTabSocialNetwork" && 
                     <>
                        <DashboardSocialNetwork/>
                    </>
                }
            </ProcessWrapper>
        </Container>
    )
}

export default DashboardSubTabs

const Container = styled.div`
  font-family: 'Overpass', sans-serif;
  width: calc(100%);
  display: flex;
  flex-direction: column;
  z-index: 6;
`;

const ProcessWrapper = styled.div`
  display: flex;
  background: ${
          props => props.Background || ""
  };
  min-width: fit-content;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
`;


