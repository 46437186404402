import React, { useEffect, useRef, useState} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import ComponentHeaderBlock from '../../layout-and-styling/standardized-components-library/Component-header-block';
import ComponentFooter from '../../layout-and-styling/standardized-components-library/Component-Footer';
import { useWindowSize } from '../../layout-and-styling/standardized-components-library/Component-Responsive';
import ComponentSignificantColleagues from '../survey-close-colleagues/page-specific-components/Component-significant-colleagues';
import ComponentWeek from './page-specific-components/Component-week';
import {Trans, useTranslation} from "react-i18next";


const PageSpontaneousCollaboration = () => {
    /**
     * Component that allows the user to fill in how much they communicate per week, based on the colleagues they selected
     * 1) Load Data (selected colleagues) via API GET request and localStorage (from previous page Footer Next button)
     * 2) Component Header, with corresponding Survey styling
     * 3) Day selection component, with buttons that will select which day the time should registered
     * 4) Colleagues and the user's interaction time registration, for the selected day
     * 5) Component Footer, specifically for this page with if statement will then do an update via PUT API request. Also if you return from the next page
     */
    const {t} = useTranslation();
    /** Check if already authenticated, and bring to dashboard if possible.*/
    useEffect(() => {
        const axiosInstance = axios.create({withCredentials: true})
        // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
            .catch((error) => {
                window.location.replace(process.env.REACT_APP_APPURL + '/login');
            })
    }, []);

    /** initiate ArrayHrs and ArrayTotals with the above Init arrays */
    const [ArrayInputCheck, updateArrayInputCheck] = useState(JSON.parse(localStorage.getItem('CheckedEmployeesSpontaneousCollaboration')) ? JSON.parse(localStorage.getItem('CheckedEmployeesSpontaneousCollaboration')) : []);
    const [render, setRender] = useState(false);
    const reRender = () => {
        setRender(!render)
    };

    const [InputChecker, setInputChecker] = useState(false)
    const employeeRef = useRef([]);
    const [nextButtonClicked, setNextButtonClicked] = useState(false);
    const significantColleagues = JSON.parse(localStorage.getItem("SignificantColleagues"))

    let interactionsPerDay = []
    
    for (let i = 0; i < significantColleagues.length; i++) {
      interactionsPerDay.push({
        employee: significantColleagues[i].id, 
        days: [0, 0, 0, 0, 0]
      });
    }

    /** meetingsTimes = interacted time every day from the planned meetings page, if no meetings -> [0, 0, 0, 0, 0] */
    const [meetingsTimes, setMeetingsTimes] = useState(JSON.parse(localStorage.getItem("meetingsTimes")));

    /** MeetingsDays = starts with just the five days and more will be added if days are added to the week */
    const [MeetingsDays, setMeetingsDays] = useState(JSON.parse(localStorage.getItem("MeetingsDays")));

    /** spontaneousTimes = starts the same as the meetingsTimes but will changhe with the sliders */
    const [spontaneousTimes, setSpontaneousTimes] = useState( JSON.parse(localStorage.getItem("spontaneousTimes")));

    /** SpontaneousDays = starts with just the five days and more will be added if days are added to the week */
    const [SpontaneousDays, setSpontaneousDays] = useState( JSON.parse(localStorage.getItem("SpontaneousDays")));

    const [selectedColleague, setSelectedColleague] = useState(significantColleagues[0].id)
    

    const plannedMeetings = JSON.parse(localStorage.getItem("plannedMeetings")) 
    let backDirectory = ""
    if (plannedMeetings) backDirectory = '/survey-planned-meetings'
    else backDirectory = '/survey-close-colleagues'

    return (
        <Container>
            <HeaderAndOverlayWrapper>
                <ComponentHeaderBlock page='Survey' reRender={reRender} overlay='PageSpontaneousCollaboration' directory='/survey-interaction-quality'/>
            </HeaderAndOverlayWrapper>
            <WrapperTop>
                <Trans i18nKey="PageSpontaneousCollaboration.Title">
                  How much do you <span style={{color: "#B3194A"}}> spontaneously </span> interact with your colleagues?
                </Trans>
            </WrapperTop>

            <WrapperBottom>
                <ComponentSignificantColleagues selectedColleague={selectedColleague} setSelectedColleague={setSelectedColleague} ArrayInputCheck={ArrayInputCheck} nextButtonClicked={nextButtonClicked} page={"PageSpontaneousCollaboration"} 
                                                reRender={reRender} employeeRef={employeeRef} inputCheckPeople={InputChecker} meetingsTimes={meetingsTimes} spontaneousTimes={spontaneousTimes} />
                
                <ComponentWeek SpontaneousDays={SpontaneousDays} setSpontaneousDays={setSpontaneousDays} spontaneousTimes={spontaneousTimes} setSpontaneousTimes={setSpontaneousTimes} reRender={reRender} MeetingsDays={MeetingsDays} setMeetingsDays={setMeetingsDays}
                                ArrayInputCheck={ArrayInputCheck} updateArrayInputCheck={updateArrayInputCheck} setInputChecker={setInputChecker} selectedColleague={selectedColleague} meetingsTimes={meetingsTimes} setMeetingsTimes={setMeetingsTimes}/>
            </WrapperBottom>

            <ComponentFooter page='PageSpontaneousCollaboration' backDirectory={backDirectory} inputCheckPeople={InputChecker} FooterMarginTop={(useWindowSize()[1] - 104).toString() + "px"} 
                            FooterMarginLeft='0px'FooterHeight='104px' FooterWidth='100%' MarginTop='25px'MarginLeft='77%' BackButtonMarginLeft='65%' NextButtonMarginLeft='calc(65% + 66px)'
                            nextButtonClicked={nextButtonClicked} setNextButtonClicked={setNextButtonClicked} />
        </Container>
    );
};
export default PageSpontaneousCollaboration

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 65px);
  background: #F1F3F4;
  display: flex;
  flex-direction: column;
  align-items: center;  
`;

const HeaderAndOverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
`;

const WrapperTop = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 100px;
  letter-spacing: 0em;
  text-align: center;
  height: fit-content;
  width: fit-content;
  margin-top: 64px;
`;

const WrapperBottom = styled.div`
  margin-top: 0;
  position: relative;
  width: 1600px;
  height: 564px;
  top: 12px;
  background: #F1F3F4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
`;
