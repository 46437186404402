import styled from '@emotion/styled';
import React, {useEffect, useRef, useState} from 'react'
import ComponentFancyCheckboxBad from './Component-Fancy-Checkbox-Bad';
import {useTranslation} from 'react-i18next';
import { ComponentTooltipProcessBadFeedback } from '../../../layout-and-styling/standardized-components-library/Component-Tooltips';
import {ReactComponent as NextButton} from "../../../assets/next_arrow_feedback.svg";
import {ReactComponent as PrevButton} from "../../../assets/prev_arrow_feedback.svg";
import {ReactComponent as NextButtonGray} from "../../../assets/next_arrow_feedback_gray.svg";
import {ReactComponent as PrevButtonGray} from "../../../assets/prev_arrow_feedback_gray.svg";
import {ReactComponent as Uncheckmark1} from "../../../assets/SurveyCommunication/Icon_Survey_CheckMark_ClickCounter_Unchecked1.svg";
import {ReactComponent as Uncheckmark2} from "../../../assets/SurveyCommunication/Icon_Survey_CheckMark_ClickCounter_Unchecked2.svg";
import {ReactComponent as Uncheckmark3} from "../../../assets/SurveyCommunication/Icon_Survey_CheckMark_ClickCounter_Unchecked3.svg";
import {ReactComponent as Checkmark} from "../../../assets/SurveyCommunication/Icon_Survey_CheckMark_ClickCounter_PinkFeedback.svg";
import i18n from "../../../i18n";

/* Specific component bad feedback, contains all three main inputs: tags, teams, and a textbox for proposals. */
export default function ComponentFeedbackTextBad(props) {
  
    const {t} = useTranslation();
    const isFirstRender = useRef(true);
    const [hover3, setHover3] = useState(false);
    const [hover4, setHover4] = useState(false);
    const [hover5, setHover5] = useState(false);

    const [selectedAllTeams, setSelectedAllTeams] = useState(false);
    useEffect(() => {
      /** Used to select/deselect all teams. First render is ignored (page load) because it is not an interaction from the user. */
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (selectedAllTeams) {
          props.setSelectedTeams(props.companyTeams);
        } else {
          props.setSelectedTeams([]);
        }
    }, [selectedAllTeams])

    return (
        <WrapperRightBottom>
        
            <BottomContainer>
              {/* Proposal container, here the user can type up to 2100 characters */}
                <FeedbackContainer>
                  <InfoHover3
                  onMouseOver={() => setHover3(true)}
                  onMouseOut={() => setHover3(false)}
                  >
                  ?
                  {hover3 && (
                    <ComponentTooltipProcessBadFeedback
                        whichTooltip={"Text1"}
                    />
                  )}
                  </InfoHover3>
                    <BoxTitleFeedback>{t('PageBadFeedback.BoxTitle3')}</BoxTitleFeedback>
                    <FeedbackInput
                        onChange={(event) => event.target.value.length > 2100 ? props.setWrittenJustification(event.target.value.substring(0, 2100)) : props.setWrittenJustification(event.target.value)}
                        placeholder={t('PageBadFeedback.FeedbackPlaceholder')} value={props.writtenJustification} id="feedbacktxt"/>

                </FeedbackContainer>
                <FeedbackContainer>
                  <InfoHover4
                  onMouseOver={() => setHover4(true)}
                  onMouseOut={() => setHover4(false)}
                  >
                  ?
                  {hover4 && (
                    <ComponentTooltipProcessBadFeedback
                        whichTooltip={"Text2"}
                    />
                  )}
                  </InfoHover4>
                    <BoxTitleFeedback>{t('PageBadFeedback.BoxTitle4')}</BoxTitleFeedback>
                    <FeedbackInput
                        onChange={(event) => event.target.value.length > 2100 ? props.setWrittenCause(event.target.value.substring(0, 2100)) : props.setWrittenCause(event.target.value)}
                        placeholder={t('PageBadFeedback.FeedbackPlaceholder')} value={props.writtenCause} id="feedbacktxt"/>

                </FeedbackContainer>
                <FeedbackContainer>
                <InfoHover5
                  onMouseOver={() => setHover5(true)}
                  onMouseOut={() => setHover5(false)}
                >
                  ?
                  {hover5 && (
                    <ComponentTooltipProcessBadFeedback
                      whichTooltip={"Text3"}
                    />
                  )}
                </InfoHover5>
                    <BoxTitleFeedback>{t('PageBadFeedback.BoxTitle5')}</BoxTitleFeedback>
                    <FeedbackInput
                        onChange={(event) => event.target.value.length > 2100 ? props.setWrittenProposal(event.target.value.substring(0, 2100)) : props.setWrittenProposal(event.target.value)}
                        placeholder={t('PageBadFeedback.FeedbackPlaceholder')} value={props.writtenProposal} id="feedbacktxt"/>

                </FeedbackContainer>
            </BottomContainer>

            <ButtonContainer>
                <button>{props.currentProcessPointer === 0 ? <StyledPrevButtonGray/> :
                    <StyledPrevButton name="prev" onClick={() => props.setCurrentProcessPointer(props.currentProcessPointer - 1)}/>}</button>


                <button>{props.currentProcessPointer === 2 ? <StyledNextButtonGray/> :
                    <StyledNextButton name="cont" onClick={() => props.setCurrentProcessPointer(props.currentProcessPointer + 1)}/>}</button>
            </ButtonContainer>


            {/* Component to facilitate navigation for users */}
            <NavCheckmarks>

                <NavPage onClick={() => props.setCurrentProcessPointer(0)}> {props.checkProcessComplete(0) ? <SelectedCheck Outline={props.currentProcessPointer === 0 ? "solid 3px #271B36" : ""}><Checkmark/></SelectedCheck> : <SelectedCheck Outline={props.currentProcessPointer === 0 ? "solid 3px #271B36" : ""}><Uncheckmark1/></SelectedCheck>} </NavPage>
                <NavPage onClick={() => props.setCurrentProcessPointer(1)}> {props.checkProcessComplete(1) ? <SelectedCheck Outline={props.currentProcessPointer === 1 ? "solid 3px #271B36" : ""}><Checkmark/></SelectedCheck> : <SelectedCheck Outline={props.currentProcessPointer === 1 ? "solid 3px #271B36" : ""}><Uncheckmark2/></SelectedCheck>} </NavPage>
                <NavPage onClick={() => props.setCurrentProcessPointer(2)}> {props.checkProcessComplete(2) ? <SelectedCheck Outline={props.currentProcessPointer === 2 ? "solid 3px #271B36" : ""}><Checkmark/></SelectedCheck> : <SelectedCheck Outline={props.currentProcessPointer === 2 ? "solid 3px #271B36" : ""}><Uncheckmark3/></SelectedCheck>} </NavPage>

            </NavCheckmarks>

        </WrapperRightBottom>
    )
}

const InfoHover3 = styled.div`
  position: absolute;
  font-size: 18px;
  font-family: "Overpass";
  font-weight: 300;
  color: #FCFCFC;
  background-color: #e2336b;
  border: 2px solid #e2336b;
  top: 8px;
  right: 8px;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
  z-index: 20;
`;


const InfoHover4 = styled.div`
  position: absolute;
  font-size: 18px;
  font-family: "Overpass";
  font-weight: 300;
  color: #FCFCFC;
  background-color: #e2336b;
  border: 2px solid #e2336b;
  top: 8px;
  right: 8px;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
  z-index: 20;
`;

const InfoHover5 = styled.div`
  position: absolute;
  font-size: 18px;
  font-family: "Overpass";
  font-weight: 300;
  color: #FCFCFC;
  background-color: #e2336b;
  border: 2px solid #e2336b;
  top: 8px;
  right: 8px;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
  z-index: 20;
`;


const WrapperRightBottom = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fcfcfc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  flex: 1350px;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;


const BoxTitleFeedback = styled.div`
  position: absolute;
  top: -15px;
  left: 15px;
  height: 30px;
  width: fit-content;
  margin: 4px 4px 0 4px;
  padding: 0 4px 0 4px;
  background-color: #fcfcfc;
  font-family: 'Overpass';
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
`;


const BottomContainer = styled.div`
  position: relative;
  display: flex;
  flex: 3;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-left: 24px;
`;

const FeedbackContainer = styled.div`
  position: relative;
  border-radius: 8px;
  border: 1px solid #929699;
  height: 85%;
  width: 100%;
  margin: 64px 24px 40px 0px;
  background-color: #fcfcfc;

  input {
    width: 100%;
    height: calc(100% - 25px);

    margin-top: 20px;
  }
`;

const FeedbackInput = styled.textarea`
  position: relative;
  border: none;
  border-radius: 8px;
  width: calc(100% - 64px);
  padding: 8px;
  height: calc(100% - 40px);
  margin-top: 20px;
  background-color: #fcfcfc;
  resize: none;
  margin-left: 20px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  outline: 0;

  ::placeholder {
    color: #929699;
    font-weight: 400;
  }
`;

const StyledNextButton = styled(NextButton)`
  margin: 12px;
  cursor: pointer;
`;

const StyledPrevButton = styled(PrevButton)`
  margin: 12px;
  cursor: pointer;
`;

const StyledNextButtonGray = styled(NextButtonGray)`
  margin: 12px;
  cursor: pointer;
`;

const StyledPrevButtonGray = styled(PrevButtonGray)`
  margin: 12px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  width: calc(100% - 60px);
  justify-content: space-between;
  align-items: center;

  p {
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0;
    text-align: center;
    color: #06D6A0;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }
`;

const NavCheckmarks = styled.div`
position: absolute;
  display: flex;
  flex-direction: row;
  bottom: 42px;
`;

const NavPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  cursor: pointer;
`;

const SelectedCheck = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  outline: ${props => props.Outline || ""};
`;