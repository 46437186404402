import styled from 'styled-components';
import React, { useContext, useState } from "react"
import { useHistory } from 'react-router-dom';
import { Dock } from "react-dock"
import { OverlayContext } from '../context-hooks/OverlayContext';
import { Trans, useTranslation } from 'react-i18next';
import { StyledDivBold, StyledDivRegular } from "./Styling-TextInput";
import { MarginAndImageWidth, useWindowSize } from './Component-Responsive';
import { ReactComponent as OverlayTeamComposition } from "../../assets/leaderintro/OverlayImage_TeamComposition.svg";
import { ReactComponent as OverlayValueChain } from "../../assets/leaderintro/OverlayImage_BusinessProcess.svg";

import { ReactComponent as IntroSatisfactionLeft } from "../../assets/SurveyIntroduction/Intro-Satisfaction-Left.svg"
import { ReactComponent as IntroSatisfactionMiddle } from "../../assets/SurveyIntroduction/Intro-Satisfaction-Middle.svg"
import { ReactComponent as IntroSatisfactionRight } from "../../assets/SurveyIntroduction/Intro-Satisfaction-Right.svg"

import { ReactComponent as UsersDemo } from "../../assets/SurveyIntroduction/users-demo.svg";
import { ReactComponent as SliderDemo } from "../../assets/SurveyIntroduction/slider-demo.svg";

import {
    ComponentTooltipCulture,
    ComponentTooltipIntroductionIndividualImpact,
    ComponentTooltipIntroductionIndividualSatisfaction,
    ComponentTooltipIntroductionProcessBadFeedback,
    ComponentTooltipIntroductionProcessGoodFeedback,
    ComponentTooltipKnowledge,
    ComponentTooltipProcessBadFeedback,
    ComponentTooltipIntroSpontaneousCollaborationPage
} from "./Component-Tooltips";
import ProcessTimePortionGif from '../../assets/SurveyIntroduction/DemoGifs/ProcessTimePortion.gif';
import KnowledgeBusinessProcessGif from '../../assets/SurveyIntroduction/DemoGifs/KnowledgeBusinessProcess.gif';
import ImpactCompanyGif from '../../assets/SurveyIntroduction/DemoGifs/ImpactCompany.gif';
import ChooseColleagueGif from '../../assets/SurveyIntroduction/DemoGifs/ChooseColleague.gif';
import SliderKnowledgeGif from '../../assets/SurveyIntroduction/DemoGifs/DrivingKnowledgeSlider.gif';
import ImpactGif from '../../assets/SurveyIntroduction/DemoGifs/ImpactGif.gif';
import PleasantnessGif from '../../assets/SurveyIntroduction/DemoGifs/PleasantnessGif.gif';
import SelectColleagues from '../../assets/SurveyIntroduction/DemoGifs/SelectColleagues.gif';
import CustomersOrSupplierGif from '../../assets/SurveyIntroduction/DemoGifs/CustomersOrSuppliers.gif';
import TimeCustomersGif from '../../assets/SurveyIntroduction/DemoGifs/TimeCustomers.gif';
import BestPracticesGif from '../../assets/SurveyIntroduction/DemoGifs/BestPractices.gif';
import ImprovementsNeedGif from '../../assets/SurveyIntroduction/DemoGifs/ImprovementsNeed.gif';
import TagsBestPracticesGif from '../../assets/SurveyIntroduction/DemoGifs/TagsBestPractices.gif';
import WrittenGoodFeedbackGif from '../../assets/SurveyIntroduction/DemoGifs/WrittenGoodFeedback.gif';
import WrittenBadFeedbackGif from '../../assets/SurveyIntroduction/DemoGifs/WrittenBadFeedback.gif';
import TagsImprovementNeededGif from '../../assets/SurveyIntroduction/DemoGifs/TagsImprovementNeeded.gif';
import AddMeetingGif from '../../assets/SurveyIntroduction/DemoGifs/AddMeeting.gif';
import TimeFrameGif from '../../assets/SurveyIntroduction/DemoGifs/TimeFrameGif.gif';

import SpotaneousInteractionOldGif from '../../assets/SurveyIntroduction/DemoGifs/SpontaniousTime-old.gif';
import SpotaneousInteractionOldGif2 from '../../assets/SurveyIntroduction/DemoGifs/SpontaniousTime-old2.gif';
import SliderCulture from '../../assets/SurveyIntroduction/DemoGifs/SliderCulture.gif';
import SliderCultureExplanation from '../../assets/SurveyIntroduction/DemoGifs/cultueSliderExplanationGif.gif'
import ChatbotButtonGif from '../../assets/SurveyIntroduction/DemoGifs/ChatbotButton.gif';
import ChatbotMessageGif from '../../assets/SurveyIntroduction/DemoGifs/ChatbotMessage.gif';
import Participants from '../../assets/SurveyIntroduction/DemoGifs/Participants.svg';
import DayDuration from '../../assets/SurveyIntroduction/DemoGifs/DayDuration.svg';
import PlannedMeetingsResources from '../../assets/SurveyIntroduction/DemoGifs/PlannedMeetingsResources.svg';
import SpontaneousInteractionsResources from '../../assets/SurveyIntroduction/DemoGifs/SpontaneousInteractionsResources.svg';

function ComponentDockOverlay(props) {
    /**
     * ComponentDockOver is the functional component and it's styling for creating overlays such as the Survey Introduction pages.
     * Dock is imported from react-dock and where you can look up it's arguments described there
     */

    /** Needed for back and next button browser url history */
    let history = useHistory();
    const { t, i18n } = useTranslation();

    /** Load in OverlayContext to toggle tooltip opacity */
    const context = useContext(OverlayContext);

    /** Responsive window sizing and useLayoutEffect trigger */
    useWindowSize();
    const [hover1, setHover1] = useState(false);
    const [hover2, setHover2] = useState(false);
    const [hover3, setHover3] = useState(false);
    const [hover4, setHover4] = useState(false);
    const [hoverPreGeneratedAnswers, setHoverPreGeneratedAnswers] = useState(false);
    const [hoverChatBot, setHoverChatBot] = useState(false);
    const [hoverBusinessFlowBadFeedback, setHoverBusinessFlowBadFeedback] = useState(false);
    const [hoverInteraction, setHoverInteraction] = useState(false);
    const [hoverTypicalWeek, setHoverTypicalWeek] = useState(false);
    const [hoverCustomerSupplier, setHoverCustomerSupplier] = useState(false);

    if (props.whichpage === "TeamCompProcessCreator") {
        return (

            <Dock position='top' isVisible={props.dock} dimMode='opaque' size={0.8073} duration={10}>

                <DockBodyWrapper>

                    <SurveyIntroHeadingBlock MarginTop="0%" MarginLeft="0%" BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                        <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='100px' Width='90%' Color='#FCFCFC' FontSize='18px' LineHeight='28px'>
                            Introduction
                        </StyledDivRegular>

                        <StyledDivBold MarginTop='76px' MarginLeft='100px' Width='90%' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                            A few things before we can start...
                        </StyledDivBold>

                        <StyledDivRegular MarginTop='144px' MarginLeft='100px' Width='1350px' Color='#FCFCFC' FontSize='18px' LineHeight='28px'>
                            First, the overview of all teams and team members needs to be uploaded or added manually.
                            Second, we have created an easy tool so that we can understand the business process of your
                            company.
                        </StyledDivRegular>

                    </SurveyIntroHeadingBlock>

                    <IntroImageWrapper MarginTop="280px" MarginLeft="82px">
                        <OverlayTeamComposition />
                    </IntroImageWrapper>

                    <StyledDivBold MarginTop='628px' MarginLeft='231px' Width='505px' Color='#271B36' FontSize='32px' LineHeight='40px'>
                        Team Composition
                    </StyledDivBold>

                    <StyledDivRegular MarginTop='684px' MarginLeft='156px' Width='505px' Color='#271B36' FontSize='16px' LineHeight='20px'>
                        Name, email, location, team and employment start date by either importing an Excel file or
                        adding manually
                    </StyledDivRegular>

                    <OverlayLine />

                    <IntroImageWrapper MarginTop="280px" MarginLeft="858px">
                        <OverlayValueChain />
                    </IntroImageWrapper>

                    <StyledDivBold MarginTop='628px' MarginLeft='1018px' Width='505px' Color='#271B36' FontSize='32px' LineHeight='40px'>
                        Business Process
                    </StyledDivBold>

                    <StyledDivRegular MarginTop='684px' MarginLeft='913px' Width='520px' Color='#271B36' FontSize='16px' LineHeight='20px'>
                        Activities needed to create your product/service, for example inbound logistics, operations,
                        outbound logisitics, sales & marketing and services
                    </StyledDivRegular>

                    <HideDockButtonWrapper MarginTop="794px" MarginLeft="639px" ButtonWidth='271px' ButtonHeight='56px'>
                        <button
                            onClick={() => {
                                history.push("/leader-team-composition");
                            }}
                        > Take me to my question
                        </button>
                    </HideDockButtonWrapper>

                </DockBodyWrapper>

            </Dock>
        );
    }

    if (props.whichpage === "PageLeaderInput") {
        return (
            <Dock position='top' isVisible={props.dock} dimMode='opaque' size={0.8073} duration={10}>

                <DockBodyWrapper>

                    <SurveyIntroHeadingBlock MarginTop="0%" MarginLeft="0%" BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>


                        <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='100px' Width='90%' Color='#FCFCFC' FontSize='18px' LineHeight='28px'>
                            Introduction
                        </StyledDivRegular>

                        <StyledDivBold MarginTop='76px' MarginLeft='100px' Width='90%' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                            A few things before we can start...
                        </StyledDivBold>

                        <StyledDivRegular MarginTop='144px' MarginLeft='100px' Width='1350px' Color='#FCFCFC' FontSize='18px' LineHeight='28px'>
                            First, the overview of all teams and team members needs to be uploaded or added manually.
                            Second, we have created an easy tool so that we can understand the business process of your
                            company.
                        </StyledDivRegular>
                    </SurveyIntroHeadingBlock>

                    <IntroImageWrapper MarginTop="280px" MarginLeft="82px">
                        <OverlayTeamComposition />
                    </IntroImageWrapper>

                    <StyledDivBold MarginTop='628px' MarginLeft='231px' Width='505px' Color='#271B36' FontSize='32px' LineHeight='40px'>
                        Team Composition
                    </StyledDivBold>

                    <StyledDivRegular MarginTop='684px' MarginLeft='156px' Width='505px' Color='#271B36' FontSize='16px' LineHeight='20px'>
                        Name, email, location, team and employment start date by either importing an Excel file or
                        adding manually
                    </StyledDivRegular>

                    <OverlayLine />

                    <IntroImageWrapper MarginTop="280px" MarginLeft="858px">
                        <OverlayValueChain />
                    </IntroImageWrapper>

                    <StyledDivBold MarginTop='628px' MarginLeft='1018px' Width='505px' Color='#271B36' FontSize='32px' LineHeight='40px'>
                        Business Process
                    </StyledDivBold>

                    <StyledDivRegular MarginTop='684px' MarginLeft='913px' Width='520px' Color='#271B36' FontSize='16px' LineHeight='20px'>
                        Activities needed to create your product/service, for example inbound logistics, operations,
                        outbound logisitics, sales & marketing and services
                    </StyledDivRegular>

                    <HideDockButtonWrapper MarginTop="794px" MarginLeft="639px" ButtonWidth='271px' ButtonHeight='56px'>
                        <button
                            onClick={() => {
                                history.push("/leader-team-composition");
                            }}
                        > Take me to my question
                        </button>
                    </HideDockButtonWrapper>

                </DockBodyWrapper>

            </Dock>
        );
    }

    if (props.whichpage === "PageCloseColleagueFilter") {

        return (
            <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>

                <DockBodyWrapper Zoom1="calc(1 / 1.25)" Zoom2="calc(1 / 1.5)" JustifyContent="center">

                    <SurveyIntroHeadingBg />

                    <SurveyIntroHeadingBlock MarginTop="0%" MarginRight="710px" Position="absolute" BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                        <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='0px' Width='200px'
                            Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                            {t('Component-Dock-Overlay-IntroductionText')}

                        </StyledDivRegular>

                        <StyledDivBold MarginTop='86px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='36px' LineHeight='52px'>
                            <Trans i18nKey="IntroPageCloseColleagueFilter.Title">
                                Who <span style={{ color: "#FCFCFC" }}> Who </span>
                                do you interact with in a normal with in a normal week?
                            </Trans>
                        </StyledDivBold>

                        <StyledDivRegular Position='fixed' MarginTop='154px' MarginLeft='0px' Width='100%'
                            Color='#FCFCFC' FontSize='26px' FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                            {t('IntroPageCloseColleagueFilter.Subtitle')}
                        </StyledDivRegular>

                    </SurveyIntroHeadingBlock>

                    <StepsWrapper>
                        <StepOneDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                <Trans i18nKey="IntroPageCloseColleagueFilter.ExplanationBox1">
                                Go through all the employees in your company and select the ones you usually
                                <HoverTooltip onMouseOver={() => setHoverInteraction(true)}
                                    onMouseOut={() => setHoverInteraction(false)}>
                                        {hoverInteraction && (<ComponentTooltipIntroductionIndividualSatisfaction
                                        whichTooltip={"Interaction"} /> 
                                    )} 
                                </HoverTooltip>
                                    with in a
                                </Trans>
                                <Trans i18nKey="IntroPageCloseColleagueFilter.ExplanationBox1TypicalWeek">
                                typical week
                                <HoverTooltip onMouseOver={() => setHoverTypicalWeek(true)}
                                    onMouseOut={() => setHoverTypicalWeek(false)}>
                                        {hoverTypicalWeek && (<ComponentTooltipIntroductionIndividualSatisfaction
                                        whichTooltip={"TypicalWeek"} /> 
                                    )} 
                                </HoverTooltip>
                                </Trans>
                                <Trans i18nKey="IntroPageCloseColleagueFilter.ExplanationBox1After">
                                for chinese translation only
                                <HoverTooltip onMouseOver={() => setHoverInteraction(true)}
                                    onMouseOut={() => setHoverInteraction(false)}>
                                        {hoverInteraction && (<ComponentTooltipIntroductionIndividualSatisfaction
                                        whichTooltip={"Interaction"} /> 
                                    )} 
                                </HoverTooltip>
                                    with in a
                                </Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                <img height={300} src={SelectColleagues} />
                            </IntroImageWrapper>

                        </StepOneDiv>


                        <StepTwoDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                            <StyledDivRegular Position="" TextAlign='center' MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px'  Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                <Trans i18nKey="IntroPageCloseColleagueFilter.ExplanationBox2">
                                    Optional: If you have any interaction with the 
                                <HoverTooltip onMouseOver={() => setHoverCustomerSupplier(true)}
                                    onMouseOut={() => setHoverCustomerSupplier(false)}> 
                                        {hoverCustomerSupplier && (<ComponentTooltipIntroductionIndividualSatisfaction
                                        whichTooltip={"CustomerSupplier"} /> 
                                    )} 
                                </HoverTooltip>
                                , select them too on the right
                                </Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                <img height={300} src={CustomersOrSupplierGif} />
                            </IntroImageWrapper>

                        </StepTwoDiv>

                        <StepThreeDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>3</p></IntroOverlayNumber>

                            <StyledDivRegular Position="" MarginTop='-20px' MarginLeft="0" TextAlign='center' Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                <Trans i18nKey="IntroPageCloseColleagueFilter.ExplanationBox3"></Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                <img height={300} src={TimeCustomersGif} />
                            </IntroImageWrapper>
                        </StepThreeDiv>

                    </StepsWrapper>

                    <HideDockButtonWrapper Display="flex" MarginTop='800px' ButtonWidth='271px' ButtonHeight='56px' MarginLeft="0px" >
                        <button onClick={() => {
                            props.setDock(false);
                        }}>
                            {t('Component-Dock-Overlay-Button')}
                        </button>
                    </HideDockButtonWrapper>

                </DockBodyWrapper>

            </Dock>
        );
    }

    if (props.whichpage === "PagePlannedMeetingsQuestion") {
        return (
            <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>

                <DockBodyWrapper JustifyContent="center">

                    <SurveyIntroHeadingBg />

                    <SurveyIntroHeadingBlock MarginTop="0%" MarginRight="710px" Position="absolute" BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                        <StyledDivRegular Position='fixed' MarginTop='56px' MarginLeft='0px' Width='200px'
                            Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                            {t('Component-Dock-Overlay-IntroductionText')}

                        </StyledDivRegular>

                        <StyledDivBold MarginTop='108px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='36px' LineHeight='52px'>
                            <Trans i18nKey="IntroPagePlannedMeetingsQuestion.Title">
                                Share with us how often you usually collaborate with your colleages during a week
                            </Trans>

                        </StyledDivBold>

                    </SurveyIntroHeadingBlock>

                    <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='280px' MarginLeft="0" Width='fit-content' Color='#271B36' FontSize='28px' LineHeight='28px' ZIndex="3" FontWeight="700">
                        <Trans i18nKey="IntroPagePlannedMeetingsQuestion.Subtitle"></Trans>
                    </StyledDivRegular>

                    <StepsWrapper>
                            <StepOneDiv MarginTop='350px' MarginLeft="20px">

                                <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' Color='#271B36' FontSize='18px' LineHeight='28px' ZIndex="3" FontWeight="700">
                                    <Trans i18nKey="IntroPagePlannedMeetingsQuestion.Type1">
                                        Planned meetings
                                    </Trans>
                                </StyledDivRegular>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='32px' MarginLeft="0" Width='600px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPagePlannedMeetingsQuestion.Type1Explanation"></Trans>
                                </StyledDivRegular>

                                <StyledDivRegular Position="absolute" MarginTop='116px' MarginLeft="0" Width='600px' Display="flex" FlexDirection="row">
                                    <IntroImageWrapper Position="absolute" MarginTop="0px" MarginLeft="-264px">
                                        <img height={24} src={DayDuration} />
                                    </IntroImageWrapper>
                                    <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='0px' MarginLeft="-112px" Width='fit-content' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                        <Trans i18nKey="IntroPagePlannedMeetingsQuestion.DayDuration"></Trans>
                                    </StyledDivRegular>
                                    <IntroImageWrapper Position="absolute" MarginTop="0px" MarginLeft="78px">
                                        <img height={24} src={Participants} />
                                    </IntroImageWrapper>
                                    <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='0px' MarginLeft="196px" Width='fit-content' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                        <Trans i18nKey="IntroPagePlannedMeetingsQuestion.Participants"></Trans>
                                    </StyledDivRegular>
                                </StyledDivRegular>


                                <IntroImageWrapper Position="absolute" MarginTop="172px" MarginLeft="10px">
                                    <img height={100} src={PlannedMeetingsResources} />
                                </IntroImageWrapper>

                            </StepOneDiv>

                            <StepTwoDiv MarginTop='350px' MarginLeft="20px">

                                <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' Color='#271B36' FontSize='18px' LineHeight='28px' ZIndex="3" FontWeight="700">
                                    <Trans i18nKey="IntroPagePlannedMeetingsQuestion.Type2">
                                        Spontaneous interactions
                                    </Trans>
                                </StyledDivRegular>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='32px' MarginLeft="0" Width='600px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPagePlannedMeetingsQuestion.Type2Explanation"></Trans>
                                </StyledDivRegular>

                                <StyledDivRegular Position="absolute" MarginTop='146px' MarginLeft="0" Width='600px' Display="flex" FlexDirection="row">
                                    <IntroImageWrapper Position="absolute" MarginTop="0px" MarginLeft="-264px">
                                        <img height={24} src={DayDuration} />
                                    </IntroImageWrapper>
                                    <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='0px' MarginLeft="-112px" Width='fit-content' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                        <Trans i18nKey="IntroPagePlannedMeetingsQuestion.DayDuration"></Trans>
                                    </StyledDivRegular>
                                    <IntroImageWrapper Position="absolute" MarginTop="0px" MarginLeft="78px">
                                        <img height={24} src={Participants} />
                                    </IntroImageWrapper>
                                    <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='0px' MarginLeft="196px" Width='fit-content' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                        <Trans i18nKey="IntroPagePlannedMeetingsQuestion.Participants"></Trans>
                                    </StyledDivRegular>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="192px" MarginLeft="10px">
                                    <img height={100} src={SpontaneousInteractionsResources} />
                                </IntroImageWrapper>

                            </StepTwoDiv>

                        </StepsWrapper>

                        <HideDockButtonWrapper Display="flex" MarginTop='780px' ButtonWidth='271px' ButtonHeight='56px' MarginLeft="0px" >
                            <button onClick={() => {
                                props.setDock(false);
                            }}>
                                {t('Component-Dock-Overlay-Button')}
                            </button>
                        </HideDockButtonWrapper>

                </DockBodyWrapper>

            </Dock>
        );
    }

    if (props.whichpage === "PagePlannedMeetings") {
        return (
            <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>

                <DockBodyWrapper JustifyContent="center">

                    <SurveyIntroHeadingBg />

                    <SurveyIntroHeadingBlock MarginTop="0%" MarginRight="710px" Position="absolute" BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                        <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='0px' Width='200px'
                            Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                            {t('Component-Dock-Overlay-IntroductionText')}

                        </StyledDivRegular>

                        <StyledDivBold MarginTop='86px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                            <Trans i18nKey="IntroPagePlannedMeetings.Title"></Trans>
                        </StyledDivBold>

                        <StyledDivRegular Position='fixed' MarginTop='154px' MarginLeft='0px' Width='100%'
                            Color='#FCFCFC' FontSize='26px' FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                            {t('IntroPagePlannedMeetings.Subtitle')}

                        </StyledDivRegular>

                    </SurveyIntroHeadingBlock>

                    <StepsWrapper>
                            <StepOneDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPagePlannedMeetings.ExplanationBox1"></Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                    <img height={300} src={AddMeetingGif} />
                                </IntroImageWrapper>

                            </StepOneDiv>

                            <StepTwoDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='550px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPagePlannedMeetings.ExplanationBox2"></Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                    <img height={300} src={TimeFrameGif} />
                                </IntroImageWrapper>

                            </StepTwoDiv>

                            <StepThreeDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>3</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center"  MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPagePlannedMeetings.ExplanationBox3"></Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                    <img height={300} src={SelectColleagues} />
                                </IntroImageWrapper>

                            </StepThreeDiv>

                        </StepsWrapper>
                    
                        <HideDockButtonWrapper Display="flex" MarginTop='800px' ButtonWidth='271px' ButtonHeight='56px' MarginLeft="0px" >
                            <button onClick={() => {
                                props.setDock(false);
                            }}>
                                {t('Component-Dock-Overlay-Button')}
                            </button>
                        </HideDockButtonWrapper>

                </DockBodyWrapper>

            </Dock>
        );
    }

    if (props.whichpage === "PageSpontaneousCollaboration") {
        return (
            <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>
                <DockBodyWrapper Zoom1="calc(1 / 1.25)" Zoom2="calc(1 / 1.5)" JustifyContent="center">

                    <SurveyIntroHeadingBg />

                    <SurveyIntroHeadingBlock MarginTop="0%" MarginRight="710px" Position="absolute" BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                        <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='0px' Width='200px'
                            Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                            {t('Component-Dock-Overlay-IntroductionText')}

                        </StyledDivRegular>

                        <StyledDivBold MarginTop='86px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                            <Trans i18nKey="IntroPageSpontaneousCollaboration.Title"></Trans>
                        </StyledDivBold>

                        <StyledDivRegular Position='fixed' MarginTop='154px' MarginLeft='0px' Width='100%'
                            Color='#FCFCFC' FontSize='26px' FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                            {t('IntroPageSpontaneousCollaboration.Subtitle')}

                        </StyledDivRegular>

                    </SurveyIntroHeadingBlock>


                    <StepsWrapper>
                        <StepOneDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                <Trans i18nKey="IntroPageSpontaneousCollaboration.ExplanationBox1"></Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="90px" MarginLeft="10px">
                                <img height={300} src={SelectColleagues} />
                            </IntroImageWrapper>

                        </StepOneDiv>

                        <StepTwoDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='520px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                <Trans i18nKey="IntroPageSpontaneousCollaboration.ExplanationBox2"></Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="90px" MarginLeft="10px">
                                <img height={300} src={SpotaneousInteractionOldGif2} />
                            </IntroImageWrapper>

                        </StepTwoDiv>

                        <StepThreeDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>3</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                            <Trans i18nKey="IntroPageSpontaneousCollaboration.ExplanationBox3">
                                Drag the sliders to set the values. You can also add <HoverTooltip onMouseOver={() => setHover1(true)}
                                        onMouseOut={() => setHover1(false)}>
                                        {hover1 && (<ComponentTooltipIntroSpontaneousCollaborationPage
                                            whichTooltip={"ExtraDays"} />
                                        )}extra days
                                    </HoverTooltip> if you want
                                </Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="90px" MarginLeft="10px">
                                {/* <img height={300} src={AddExtraDay} /> */}
                                <img height={300} src={SpotaneousInteractionOldGif} />
                            </IntroImageWrapper>

                        </StepThreeDiv>

                    </StepsWrapper>

                    <HideDockButtonWrapper Display="flex" MarginTop='800px' ButtonWidth='271px' ButtonHeight='56px' MarginLeft="0px" >
                        <button onClick={() => {
                            props.setDock(false);
                        }}>
                            {t('Component-Dock-Overlay-Button')}
                        </button>
                    </HideDockButtonWrapper>

                </DockBodyWrapper>

            </Dock>
        );
    }

    if (props.whichpage === "PageCollaborationMeasurement") {
        return (
            <>
                <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>
                    <DockBodyWrapper Zoom1="calc(1 / 1.25)" Zoom2="calc(1 / 1.5)" JustifyContent="center" Display="flex">

                        <SurveyIntroHeadingBg />

                        <SurveyIntroHeadingBlock MarginTop="0%" MarginRight="710px" Position="absolute"
                            BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                            <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='0px' Width='200px'
                                Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                                {t('Component-Dock-Overlay-IntroductionText')}

                            </StyledDivRegular>

                            <StyledDivBold MarginTop='86px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                                {t('IntroPageCollaborationMeasurement.Title')}
                            </StyledDivBold>

                            <StyledDivRegular Position='fixed' MarginTop='154px' MarginLeft='0px' Width='100%'
                                Color='#FCFCFC' FontSize='26px' FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                                {t('IntroPageCollaborationMeasurement.Subtitle')}

                            </StyledDivRegular>


                        </SurveyIntroHeadingBlock>

                        <StepsWrapper>
                            <StepOneDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPageCollaborationMeasurement.ExplanationBox1"></Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="90px" MarginLeft="10px">
                                    <img height={300} src={ChooseColleagueGif} />
                                </IntroImageWrapper>

                            </StepOneDiv>

                            <StepTwoDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='550px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPageCollaborationMeasurement.ExplanationBox2"></Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="90px" MarginLeft="10px">
                                    <img height={300} src={ImpactGif} />
                                </IntroImageWrapper>

                            </StepTwoDiv>


                        </StepsWrapper>

                        <HideDockButtonWrapper Position="absolute" Display="flex" MarginTop='800px' ButtonWidth='271px' ButtonHeight='56px' MarginLeft="0px" >
                            <button onClick={() => {
                                props.setDock(false);
                            }}>
                                {t('Component-Dock-Overlay-Button')}
                            </button>
                        </HideDockButtonWrapper>

                    </DockBodyWrapper>
                </Dock>
            </>
        );
    }

    if (props.whichpage === "PageProactiveness") {
        return (
            <>
                <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>

                    <DockBodyWrapper Zoom1="calc(1 / 1.25)" JustifyContent="center" Display="flex">

                        <SurveyIntroHeadingBg />

                        <SurveyIntroHeadingBlock  MarginTop="0%" MarginRight="710px" Position="absolute"
                            BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                            <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='0px' Width='200px' Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                                {t('Component-Dock-Overlay-IntroductionText')}

                            </StyledDivRegular>

                            <StyledDivBold MarginTop='86px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                                {t('IntroPageProactiveness.Title')}
                            </StyledDivBold>

                            <StyledDivRegular Position='fixed' MarginTop='154px' MarginLeft='0px' Width='100%'
                                Color='#FCFCFC' FontSize='26px' FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                                {t('IntroPageProactiveness.Subtitle')}

                            </StyledDivRegular>
                        </SurveyIntroHeadingBlock>


                        <StepsWrapper>

                            <StepOneDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>1</p></IntroOverlayNumber>


                                <StyledDivRegular Position="absolute" MarginTop='0' MarginLeft="0" MaxWidth='500px' Width="fit-content" Color='#271B36' FontSize='16px' TextAlign="center" LineHeight='28x'>
                                    {t('IntroPageProactiveness.ExplanationBox1')}
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="100px" MarginLeft="10px">
                                    <img height={300} src={ChooseColleagueGif} />
                                </IntroImageWrapper>



                            </StepOneDiv>

                            <StepTwoDiv MarginTop='280px' MarginLeft="20px">
                                <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" MarginTop='0' MarginLeft="0" MaxWidth='620px' Width="fit-content" Color='#271B36' FontSize='16px' TextAlign="center" LineHeight='28x'>
                                    {t('IntroPageProactiveness.ExplanationBox2')}
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="100px" MarginLeft="10px">
                                    <img height={300} src={SliderKnowledgeGif} />
                                </IntroImageWrapper>

                            </StepTwoDiv>

                        </StepsWrapper>

                        <HideDockButtonWrapper MarginTop="800px" MarginLeft="0px" ButtonWidth='271px' ButtonHeight='56px'>
                            <button onClick={() => {
                                props.setDock(false);
                                context.toggleOverlay();
                                context.toggleDecisionMakingProcessesComponent(3);
                            }}>
                                {t('Component-Dock-Overlay-Button')}
                            </button>
                        </HideDockButtonWrapper>

                    </DockBodyWrapper>

                </Dock>

            </>
        );
    }

    if (props.whichpage === "PageKnowledge") {
        return (
            <Dock style={{ display: "none" }} position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>

                <DockBodyWrapper JustifyContent="center">

                    <SurveyIntroHeadingBg />

                    <SurveyIntroHeadingBlock  MarginTop="0%" MarginRight="710px" Position="absolute" BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                        <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='0px' Width='200px'
                            Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                            {t('Component-Dock-Overlay-IntroductionText')}

                        </StyledDivRegular>

                        <StyledDivBold MarginTop='86px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                            {t('IntroPageKnowledge.Title')}
                        </StyledDivBold>

                        <StyledDivRegular Position='fixed' MarginTop='154px' MarginLeft='0px' Width='100%'
                            Color='#FCFCFC' FontSize='26px' FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                            {t('IntroPageKnowledge.Subtitle')}

                        </StyledDivRegular>
                    </SurveyIntroHeadingBlock>

                    <StepsWrapper>
                        <StepOneDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='600px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                            <Trans i18nKey="IntroPageKnowledge.ExplanationBox1">
                                    Show in
                                    How would you rate your <HoverTooltip onMouseOver={() => setHover1(true)} onMouseOut={() => setHover1(false)}>
                                        {hover1 && (<ComponentTooltipKnowledge
                                            whichTooltip={"Step1"} />
                                        )}
                                    </HoverTooltip>
                                </Trans>
                                <br></br>
                            <Trans i18nKey="IntroPageKnowledge.ExplanationBox2">
                                </Trans>

                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                <img height={300} src={ProcessTimePortionGif} />
                            </IntroImageWrapper>

                        </StepOneDiv>

                        <StepTwoDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='600px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                           
                            <Trans i18nKey="IntroPageKnowledge.ExplanationBox3">
                                </Trans>
                                <br></br>
                                <Trans i18nKey="IntroPageKnowledge.ExplanationBox4">
                                    Show in
                                    How would you rate your <HoverTooltip onMouseOver={() => setHover2(true)} onMouseOut={() => setHover2(false)}>
                                        {hover2 && (<ComponentTooltipKnowledge
                                            whichTooltip={"Step2"} />
                                        )}
                                    </HoverTooltip>
                                </Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="70px">
                                <img height={300} src={ImpactCompanyGif} />
                            </IntroImageWrapper>

                        </StepTwoDiv>

                        <StepThreeDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>3</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='600px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                            <Trans i18nKey="IntroPageKnowledge.ExplanationBox5">
                                    Show in
                                    How would you rate your <HoverTooltip onMouseOver={() => setHover3(true)} onMouseOut={() => setHover3(false)}>
                                        {hover3 && (<ComponentTooltipKnowledge
                                            whichTooltip={"Step3"} />
                                        )}
                                    </HoverTooltip>
                                </Trans>
                                <br></br>
                            <Trans i18nKey="IntroPageKnowledge.ExplanationBox6">
                                </Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="70px">
                                <img height={300} src={KnowledgeBusinessProcessGif} />
                            </IntroImageWrapper>

                        </StepThreeDiv>
                    </StepsWrapper>

                    <HideDockButtonWrapper Display="flex" MarginTop='800px' MarginLeft="0px" ButtonWidth='271px' ButtonHeight='56px'>
                        <button onClick={() => {
                            props.setDock(false);
                        }}>
                            {t('Component-Dock-Overlay-Button')}
                        </button>

                    </HideDockButtonWrapper>

                </DockBodyWrapper>

            </Dock>
        )
    }

    if (props.whichpage === "PageIndividualSatisfaction") {
        return (
            <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>
                <DockBodyWrapper Zoom1="calc(1 / 1.25)" Zoom2="calc(1 / 1.5)">

                    <SurveyIntroHeadingBg />

                    <SurveyIntroHeadingBlock MarginTop="0%" MarginLeft={MarginAndImageWidth(1550)}
                        BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                        <StyledDivRegular Position='fixed' MarginTop='56px' MarginLeft='100px' Width='200px'
                            Color='#E2336B' FontSize='52px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                            {t('Component-Dock-Overlay-IntroductionText')}

                        </StyledDivRegular>

                        <StyledDivBold MarginTop='108px' MarginLeft='100px' Width='100%' Color='#FCFCFC'
                            FontSize='42px' LineHeight='52px'>
                            {t('IntroPageIndividualSatisfaction.SurveyIntroHeadingBlock1')}
                        </StyledDivBold>

                        {/* <StyledDivRegular MarginTop='144px' MarginLeft='100px' Width='90%' Color='#FCFCFC'
                                          FontSize='18px' LineHeight='28px'>
                            {t('IntroPageIndividualSatisfaction.SurveyIntroHeadingBlock2')}
                        </StyledDivRegular> */}

                    </SurveyIntroHeadingBlock>

                    <StepsWrapper>
                        <StepOneDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                            <StyledDivRegular MarginTop='0' MarginLeft="0" MaxWidth='530px' Width="fit-content" Color='#271B36' FontSize='18px' LineHeight='28px' ZIndex="3">
                                <Trans i18nKey="IntroPageIndividualSatisfaction.ExplanationBox1">
                                    Which <HoverTooltip onMouseOver={() => setHover1(true)}
                                        onMouseOut={() => setHover1(false)}>
                                        {hover1 && (<ComponentTooltipIntroductionIndividualSatisfaction
                                            whichTooltip={"Factors"} />
                                        )}factors
                                    </HoverTooltip>
                                    bring you satisfaction
                                </Trans>

                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="152px" MarginLeft="140px" Width="400px">
                                <IntroSatisfactionLeft />
                            </IntroImageWrapper>

                            <StyledDivRegular Position="absolute" FontWeight="bold" MarginTop='100px' Width='100px' ZIndex="2" Color='#929699'
                                TextAlign="center" FontSize='14px' LineHeight='20px' MarginLeft="0" MarginRight="228px" >
                                {t('IntroPageIndividualSatisfaction.VeryDissatisfying')}
                            </StyledDivRegular>

                            <StyledDivRegular Position="absolute" FontWeight="bold" MarginTop='100px' Width='100px' ZIndex="2" Color='#929699' TextAlign="center"
                                FontSize='14px' LineHeight='20px' MarginLeft="0" MarginRight="56px" >
                                {t('IntroPageIndividualSatisfaction.SlightlyDissatisfying')}
                            </StyledDivRegular>

                            <StyledDivRegular Position="absolute" FontWeight="bold" MarginTop='100px' Width='100px' ZIndex="2" Color='#929699' TextAlign="center"
                                FontSize='14px' LineHeight='20px' MarginLeft="125px" >
                                {t('IntroPageIndividualSatisfaction.Neutral')}
                            </StyledDivRegular>

                            <StyledDivRegular Position="absolute" FontWeight="bold" MarginTop='100px' Width='100px'
                                ZIndex="2" Color='#929699' TextAlign="center" FontSize='14px' LineHeight='20px' MarginLeft="324px" >
                                {t('IntroPageIndividualSatisfaction.SlightlySatisfying')}
                            </StyledDivRegular>

                            <StyledDivRegular Position="absolute" FontWeight="bold" MarginTop='100px' Width='90px'
                                ZIndex="2" Color='#929699' TextAlign="center" FontSize='14px' LineHeight='20px' MarginLeft="516px" >
                                {t('IntroPageIndividualSatisfaction.VerySatisfying')}
                            </StyledDivRegular>

                            <StyledDivRegular Position="absolute" FontWeight="800" MarginTop='155px' Width='140px' TextAlign="left"
                                ZIndex="2" Color='#929699' MarginRight="350px" FontSize='18px' LineHeight='28px' MarginLeft="0" >
                                {t('IntroPageIndividualSatisfaction.Coaching')}
                            </StyledDivRegular>

                            <StyledDivRegular Position="absolute" FontWeight="800" MarginTop='215px' Width='140px'
                                ZIndex="2" Color='#929699' MarginRight="350px" FontSize='18px' LineHeight='28px' MarginLeft="0" >
                                {t('IntroPageIndividualSatisfaction.WorkItself')}
                            </StyledDivRegular>

                        </StepOneDiv>
                        <StepTwoDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" MarginTop='0' MarginLeft="0" MaxWidth='530px' Width="fit-content"
                                Color='#271B36' FontSize='18px' LineHeight='28px' ZIndex="3">
                                <Trans i18nKey="IntroPageIndividualSatisfaction.ExplanationBox2">
                                    Which <HoverTooltip onMouseOver={() => setHover2(true)} onMouseOut={() => setHover2(false)}>
                                        {hover2 && (<ComponentTooltipIntroductionIndividualSatisfaction
                                            whichTooltip={"Weight"} />
                                        )}factors
                                    </HoverTooltip>
                                    bring you satisfaction
                                </Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="143px">
                                <IntroSatisfactionMiddle />
                            </IntroImageWrapper>

                            <StyledDivRegular Position="absolute" ZIndex="15" MarginTop='150px' Width='fit-content'
                                TextAlign="center" Color='#FCFCFC' MarginRight="256px" FontSize='16px' LineHeight='28px' MarginLeft="0" >
                                {t('IntroPageIndividualSatisfaction.Coaching')}
                                <br></br>
                                57%
                            </StyledDivRegular>

                            <StyledDivRegular Position="absolute" MarginTop='150px' Width='fit-content' ZIndex="20" Color='#FCFCFC' TextAlign="center"
                                FontSize='16px' LineHeight='28px' MarginLeft="256px" >
                                {t('IntroPageIndividualSatisfaction.WorkItself')}
                                <br></br>
                                57%
                            </StyledDivRegular>

                            <StyledDivRegular Position="absolute" MarginTop='243px' Width='fit-content' ZIndex="2" Color='#271B36' TextAlign="center"
                                FontSize='16px' LineHeight='28px' MarginLeft="0" >
                                {t('PageKnowledge.DragSlider')}
                            </StyledDivRegular>
                            <HideDockButtonWrapper MarginTop='400px' MarginLeft="0px" ButtonWidth='271px' ButtonHeight='56px'>
                                <button onClick={() => {
                                    props.setDock(false);
                                    context.toggleOverlay();
                                    context.toggleLikertSatisfactionComponent(3);
                                }}>
                                    {t('Component-Dock-Overlay-Button')}
                                </button>
                            </HideDockButtonWrapper>

                        </StepTwoDiv>
                        <StepThreeDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>3</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" MarginTop='0' MarginLeft="0" MaxWidth='530px' Width="fit-content"
                                Color='#271B36' FontSize='18px' LineHeight='28px' ZIndex="3">
                                <Trans i18nKey="IntroPageIndividualSatisfaction.ExplanationBox3">
                                    Share with us how <HoverTooltip onMouseOver={() => setHover3(true)} onMouseOut={() => setHover3(false)}>
                                        {hover3 && (<ComponentTooltipIntroductionIndividualSatisfaction
                                            whichTooltip={"SatisfactionSlider"} />
                                        )}satisfied you feel
                                    </HoverTooltip>
                                    at the company
                                </Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="177px">
                                <IntroSatisfactionRight />
                            </IntroImageWrapper>

                            <StyledDivRegular Position="absolute" MarginTop='110px' Width='100px' FontWeight="bold"
                                ZIndex="2" Color='#929699' TextAlign="center" FontSize='16px' LineHeight='28px' MarginLeft="0" MarginRight="480px" >
                                {t('IntroPageIndividualSatisfaction.VeryDissatisfied')}
                            </StyledDivRegular>

                            <StyledDivRegular Position="absolute" MarginTop='110px' Width='100px' FontWeight="bold"
                                ZIndex="2" Color='#929699' TextAlign="center" FontSize='16px' LineHeight='28px' MarginLeft="0" MarginRight="250px" >
                                {t('IntroPageIndividualSatisfaction.SlightlyDissatisfied')}
                            </StyledDivRegular>

                            <StyledDivRegular Position="absolute" MarginTop='110px' Width='100px' FontWeight="bold"
                                ZIndex="2" Color='#929699' TextAlign="center" FontSize='16px' LineHeight='28px' MarginLeft="0" >
                                {t('IntroPageIndividualSatisfaction.Satisfied')}
                            </StyledDivRegular>

                            <StyledDivRegular Position="absolute" MarginTop='110px' Width='100px' FontWeight="bold"
                                ZIndex="2" Color='#929699' TextAlign="center" FontSize='16px' LineHeight='28px' MarginLeft="230px" >
                                {t('IntroPageIndividualSatisfaction.SlightlySatisfied')}
                            </StyledDivRegular>

                            <StyledDivRegular Position="absolute" MarginTop='110px' Width='80px' FontWeight="bold"
                                ZIndex="2" Color='#929699' TextAlign="center" FontSize='16px' LineHeight='28px' MarginLeft="480px" >
                                {t('IntroPageIndividualSatisfaction.VerySatisfied')}
                            </StyledDivRegular>
                        </StepThreeDiv>
                    </StepsWrapper>
                </DockBodyWrapper>
            </Dock>
        );
    }

    if (props.whichpage === "PageCulture") {
        return (
            <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>
                <DockBodyWrapper JustifyContent="center" Display="flex">

                    <SurveyIntroHeadingBg />

                    <SurveyIntroHeadingBlock  MarginTop="0%" MarginRight="710px" Position="absolute"
                        BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                        <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='0px' Width='200px'
                            Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                            {t('Component-Dock-Overlay-IntroductionText')}
                        </StyledDivRegular>

                        <StyledDivBold MarginTop='86px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                            {t('IntroPageCulture.Title')}
                        </StyledDivBold>

                        <StyledDivRegular Position='fixed' MarginTop='154px' MarginLeft='0px' Width='100%'
                            Color='#FCFCFC' FontSize='26px' FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                            {t('IntroPageCulture.Subtitle')}

                        </StyledDivRegular>
                    </SurveyIntroHeadingBlock>

                    <StepsWrapper>
                        <StepOneDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                            <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='520px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                <Trans i18nKey="IntroPageCulture.ExplanationBox1">
                                </Trans>
                                <br />
                                <Trans i18nKey="IntroPageCulture.ExplanationBox2">
                                    Show in
                                    How would you rate your <HoverTooltip onMouseOver={() => setHover3(true)} onMouseOut={() => setHover3(false)}>
                                        {hover3 && (<ComponentTooltipCulture
                                            whichTooltip={"Slider"} />
                                        )}
                                    </HoverTooltip>
                                
                                </Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="80px" MarginLeft="10px">
                                <img height={300} src={SliderCulture} width={600} />
                            </IntroImageWrapper>

                        </StepOneDiv>

                        <StepTwoDiv MarginTop='280px' MarginLeft="20px">

                            <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                            <StyledDivRegular Position="" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='550px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3" TextAlign="center">
                                <Trans i18nKey="IntroPageCulture.ExplanationBox3">
                                </Trans>
                            </StyledDivRegular>

                            <IntroImageWrapper Position="absolute" MarginTop="80px" MarginLeft="20px">
                                <img height={300} src={SliderCultureExplanation} />
                            </IntroImageWrapper>


                        </StepTwoDiv>
                    </StepsWrapper>

                    <HideDockButtonWrapper MarginTop='800px' MarginLeft="0px" ButtonWidth='271px' ButtonHeight='56px'>
                        <button onClick={() => {
                            props.setDock(false);
                        }}>
                            {t('Component-Dock-Overlay-Button')}
                        </button>

                    </HideDockButtonWrapper>

                </DockBodyWrapper>

            </Dock>
        );
    }

    if (props.whichpage === "PageBusinessProcessGoodFeedback") {
        return (
            <>
                <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>
                    <DockBodyWrapper JustifyContent="center" Display="flex">

                        <SurveyIntroHeadingBg />

                        <SurveyIntroHeadingBlock MarginTop="0%" MarginRight="710px" Position="absolute" BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                            <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='0px' Width='200px'
                                Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                                {t('Component-Dock-Overlay-IntroductionText')}

                            </StyledDivRegular>

                            <StyledDivBold MarginTop='86px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                                {t('IntroPageGoodFeedback.Title')}
                            </StyledDivBold>

                            <StyledDivRegular Position='fixed' MarginTop='154px' MarginLeft='0px' Width='100%'
                                Color='#FCFCFC' FontSize={i18n.language === "nl-NL" ? '22px' : '26px'} FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                                {t('IntroPageGoodFeedback.Subtitle')}

                            </StyledDivRegular>
                        </SurveyIntroHeadingBlock>


                        <StepsWrapper>
                            <StepOneDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                <Trans i18nKey="IntroPageGoodFeedback.ExplanationBox1">
                                    Show in
                                    How would you rate your <HoverTooltip onMouseOver={() => setHover1(true)} onMouseOut={() => setHover1(false)}>
                                        {hover1 && (<ComponentTooltipProcessBadFeedback
                                            whichTooltip={"businessFlow"} />
                                        )}
                                    </HoverTooltip>
                                </Trans>
                                <br></br>
                            <Trans i18nKey="IntroPageGoodFeedback.ExplanationBox2">
                                </Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                    <img height={300} src={BestPracticesGif} />
                                </IntroImageWrapper>

                            </StepOneDiv>

                            <StepTwoDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='580px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPageGoodFeedback.ExplanationBox3"></Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                    <img height={300} src={TagsBestPracticesGif} />
                                </IntroImageWrapper>

                            </StepTwoDiv>

                            <StepThreeDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>3</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3">
                                    <Trans i18nKey="IntroPageGoodFeedback.ExplanationBox4"></Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="70px" MarginLeft="10px">
                                    <img height={300} src={WrittenGoodFeedbackGif} />
                                </IntroImageWrapper>

                            </StepThreeDiv>

                        </StepsWrapper>

                        <HideDockButtonWrapper Display="flex" MarginTop='800px' ButtonWidth='271px' ButtonHeight='56px' MarginLeft="0px" >
                            <button onClick={() => {
                                props.setDock(false);
                            }}>
                                {t('Component-Dock-Overlay-Button')}
                            </button>
                        </HideDockButtonWrapper>

                    </DockBodyWrapper>
                </Dock>

            </>
        );
    }

    if (props.whichpage === "PageBusinessProcessBadFeedback") {
        return (
            <>
                <Dock position='top' isVisible={props.dock} dimMode='opaque' size={1} duration={100}>
                    <DockBodyWrapper JustifyContent="center">

                        <SurveyIntroHeadingBg />

                        <SurveyIntroHeadingBlock MarginTop="0%" MarginRight="710px" Position="absolute" BoxShadow='-20px 4px 60px rgba(0, 0, 0, 0.1)'>

                            <StyledDivRegular Position='fixed' MarginTop='40px' MarginLeft='0px' Width='200px'
                                Color='#E2336B' FontSize='30px' FontWeight='700' LineHeight='28px' FontFamily='Montserrat'>
                                {t('Component-Dock-Overlay-IntroductionText')}

                            </StyledDivRegular>

                            <StyledDivBold MarginTop='86px' MarginLeft='0px' Width='100%' Color='#FCFCFC' FontSize='42px' LineHeight='52px'>
                                {t('IntroPageBadFeedback.Title')}
                            </StyledDivBold>

                            <StyledDivRegular Position='fixed' MarginTop='154px' MarginLeft='0px' Width='100%'
                                Color='#FCFCFC' FontSize={i18n.language === "nl-NL" ? '22px' : '26px'} FontWeight='400' LineHeight='28px' FontFamily='Montserrat'>
                                {t('IntroPageBadFeedback.Subtitle')}
                            </StyledDivRegular>
                        </SurveyIntroHeadingBlock>

                        <StepsWrapper>
                            <StepOneDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>1</p></IntroOverlayNumber>

                                <StyledDivRegular Position="absolute" TextAlign="center" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3" >
                                <Trans i18nKey="IntroPageBadFeedback.ExplanationBox1">
                                    Show in
                                    On the first page, go through the <HoverTooltip onMouseOver={() => setHoverBusinessFlowBadFeedback(true)} onMouseOut={() => setHoverBusinessFlowBadFeedback(false)}>
                                        {hoverBusinessFlowBadFeedback && (<ComponentTooltipProcessBadFeedback
                                            whichTooltip={"businessFlow"} />
                                        )}% business flow
                                    </HoverTooltip>
                                    and select the 3 subprocesses that need the most improvement
                                    </Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="80px" MarginLeft="10px">
                                    <img height={300} src={ImprovementsNeedGif} />
                                </IntroImageWrapper>

                            </StepOneDiv>

                            <StepTwoDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>2</p></IntroOverlayNumber>

                                <StyledDivRegular Position="" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3" TextAlign="center">
                                    <Trans i18nKey="IntroPageBadFeedback.ExplanationBox2">
                                    On the second page, we have a <HoverTooltip onMouseOver={() => setHoverChatBot(true)} onMouseOut={() => setHoverChatBot(false)}>
                                        {hoverChatBot && (<ComponentTooltipProcessBadFeedback
                                            whichTooltip={"chatbot"} />
                                        )}chatbot
                                    </HoverTooltip>
                                    . Use the textbox to answer its questions. Discuss why you think your selected subprocesses need improvement
                                    </Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="80px" MarginLeft="10px">
                                    <img height={300} src={ChatbotMessageGif} />
                                </IntroImageWrapper>

                            </StepTwoDiv>

                            <StepThreeDiv MarginTop='280px' MarginLeft="20px">

                                <IntroOverlayNumber><p>3</p></IntroOverlayNumber>

                                <StyledDivRegular Position="" MarginTop='-20px' MarginLeft="0" Width='fit-content' MaxWidth='500px' Color='#271B36' FontSize='16px' LineHeight='28px' ZIndex="3" TextAlign="center">
                                    <Trans i18nKey="IntroPageBadFeedback.ExplanationBox3">
                                    Show in
                                    If you want to, click on the <HoverTooltip onMouseOver={() => setHoverPreGeneratedAnswers(true)} onMouseOut={() => setHoverPreGeneratedAnswers(false)}>
                                        {hoverPreGeneratedAnswers && (<ComponentTooltipProcessBadFeedback
                                            whichTooltip={"generatedAnswers"} />
                                        )}% pre-generated answers
                                    </HoverTooltip>
                                    to help speed up the conversation
                                    </Trans>
                                </StyledDivRegular>

                                <IntroImageWrapper Position="absolute" MarginTop="80px" MarginLeft="10px">
                                    <img height={300} src={ChatbotButtonGif} />
                                </IntroImageWrapper>

                            </StepThreeDiv>

                        </StepsWrapper>

                        <HideDockButtonWrapper Display="flex" MarginTop='800px' ButtonWidth='271px' ButtonHeight='56px' MarginLeft="0px" >
                            <button onClick={() => {
                                props.setDock(false);
                            }}>
                                {t('Component-Dock-Overlay-Button')}
                            </button>
                        </HideDockButtonWrapper>

                    </DockBodyWrapper>
                </Dock>

            </>
        );
    }

};
export default ComponentDockOverlay
const DockBodyWrapper = styled.div`
                    display: flex;
                    z-index: 10;
                    overflow-x: hidden;
                    justify-content: ${props => props.JustifyContent || "flex-start"};
                    @media (-webkit-device-pixel-ratio: 1.25) {
                        zoom: ${props => props.Zoom1 || ""};
  }
                    @media (-webkit-device-pixel-ratio: 1.5) {
                        zoom: ${props => props.Zoom2 || ""};
  }
                    `;


const SurveyIntroHeadingBg = styled.div`
                    position: fixed;
                    width: 100%;
                    height: 215px;
                    margin-top: ${props => props.MarginTop || "0%"};
                    margin-left: ${props => props.MarginLeft || "0%"};
                    box-shadow: ${props => props.BoxShadow || '0px 10px 10px -15px rgba(0,0,0,0.25)'};
                    background: #271B36;
                    z-index: 10;
                    `;

const SurveyIntroHeadingBlock = styled.div`
                    position: ${props => props.Position || "fixed"};
                    width: ${props => props.Width || "900px"};
                    height: 240px;
                    margin-top: ${props => props.MarginTop || "0%"};
                    margin-left: ${props => props.MarginLeft || "0%"};
                    margin-right: ${props => props.MarginRight || "0%"};

                    z-index: 10;
                    `;

const OverlayLine = styled.div`
                    position: fixed;
                    height: 416px;
                    width: 1px;
                    background: #271B36;
                    margin-top: ${props => props.MarginTop || "308px"};
                    margin-left: ${props => props.MarginLeft || "775px"};
                    `;

const IntroImageWrapper = styled.div`
                    position: ${props => props.Position || "fixed"};
                    display: flex;
                    align-items: center;
                    margin-top: ${props => props.MarginTop || "0%"};
                    margin-left: ${props => props.MarginLeft || "0%"};
                    justify-content: center;
                    width: ${props => props.Width || ""};
                    `;


const HideDockButtonWrapper = styled.div`
                    position: absolute;
                    margin-top: ${props => props.MarginTop || "50%"};
                    margin-left: ${props => props.MarginLeft || "36%"};

                    button {
                        height: ${props => props.ButtonHeight || "56px"};
                    width: ${props => props.ButtonWidth || "212px"};
                    border: none;
                    border-radius: 10px;
                    background-color: #e2336B;
                    color: #FCFCFC;
                    font-size: 18px;
                    font-weight: 400;
                    font-family: 'Overpass';
                    cursor: pointer;
                    transition: all 0.2s ease-in;
                    :hover {
                        background-color: #B3194A;
    }
  }
                    `;

const IntroOverlayNumber = styled.div`
                    position: fixed;
    // top: ${props => props.Top || "220px"};
                    margin-top: -90px;
                    margin-bottom: ${props => props.MarginBottom || "0%"};
                    width: ${props => props.Width || "40px"};
                    height: ${props => props.Height || "40px"};
                    background: #e2336B;
                    z-index: 3;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: ${props => props.FontFamily || 'Overpass'};
                    font-size: ${props => props.FontSize || '18px'};
                    line-height: ${props => props.LineHeight || '28px'};
                    font-weight: ${props => props.FontWeight || '400'};
                    color: #fcfcfc;

                    p {
                        height: 25px;
  }
                    `;

const StepsWrapper = styled.div`
                    display: flex;
                    flex-direction: row;
                    width: ${props => props.Width || "70%"};
                    height: 100%;
                    justify-content: center;
                    margin: 0 auto;
                    margin-top: 70px;
                    @media only screen and (max-width: 2300px) {
                        width: 100%;
  }
                    `

const StepOneDiv = styled.div`
                    display: flex; 
                    flex-direction: column;
                    align-items: center;
                    margin-top: ${props => props.MarginTop || "0"};
                    margin-left: ${props => props.MarginLeft || "0"};
                    width: ${props => props.Width || "33%"};
                    margin-right: 40px;
                    @media only screen and (min-width: 2000px) {
                        margin-right: 0px;
                    margin-left: 0px;
  }
                    `; 


const StepTwoDiv = styled.div`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: ${props => props.MarginTop || "0"};
                    margin-left: ${props => props.MarginLeft || "0"};
                    width: ${props => props.Width || "33%"};
                    margin-right: 40px;
                    @media only screen and (min-width: 2000px) {
                        margin-right: 0px;
                    margin-left: 0px;

  }
                    `;

const StepThreeDiv = styled.div`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: ${props => props.MarginTop || "0"};
                    margin-left: ${props => props.MarginLeft || "0"};
                    width: 33%;
                    margin-right: 40px;

                    @media only screen and (min-width: 2000px) {
                        margin-right: 0px;
                    margin-left: 0px;
  }
                    `;


const HoverTooltip = styled.div`
                    text-decoration: underline;
                    display: inline;
                    color: #E2336B;
                    cursor: pointer;
                    position: relative;
                    z-index: 2;
                    `;
