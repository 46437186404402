import React, { useEffect, useRef, useState} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import ComponentHeaderBlock from '../../layout-and-styling/standardized-components-library/Component-header-block';
import ComponentFooter from '../../layout-and-styling/standardized-components-library/Component-Footer';
import { useWindowSize } from '../../layout-and-styling/standardized-components-library/Component-Responsive';
import ComponentSignificantColleagues from '../survey-close-colleagues/page-specific-components/Component-significant-colleagues';
import ComponentCollaborationSlider from './page-specific-components/Component-collaboration-sliders';
import {Trans, useTranslation} from "react-i18next";
const PageCollaborationMeasurement = () => {
    /**
     * Component that allows the user to fill in how much they communicate per week, based on the colleagues they selected
     * 1) Load Data (selected colleagues) via API GET request and localStorage (from previous page Footer Next button)
     * 2) Component Header, with corresponding Survey styling
     * 3) Day selection component, with buttons that will select which day the time should registered
     * 4) Colleagues and the user's interaction time registration, for the selected day
     * 5) Component Footer, specifically for this page with if statement will then do an update via PUT API request. Also if you return from the next page
     */
    const {t} = useTranslation();
    /** Check if already authenticated, and bring to dashboard if possible.*/
    useEffect(() => {
        const axiosInstance = axios.create({withCredentials: true})
        // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
            .catch((error) => {
                window.location.replace(process.env.REACT_APP_APPURL + '/login');
            })
    }, []);
    /** Get Interaction and Employee datasets from API stored data from localStorage */
    const InteractionData = JSON.parse(localStorage.getItem('SignificantInteractions'));
    const EmployeeData = JSON.parse(localStorage.getItem('employee_data'));
    const significantColleagues = JSON.parse(localStorage.getItem("SignificantColleagues"))
    const CurrentUserData = JSON.parse(localStorage.getItem('current_user_data'));
    /** From InteractionData.json, store the selected colleagues' names into an array to be used for each input window
     * create impact and pleasantness fields */
    /** initiate Array to be pushed via API Post request */

    function getArrayQuality()
    {
        const InitArrayQuality = localStorage.getItem('ArrayQuality') ? JSON.parse(localStorage.getItem('ArrayQuality')) : []


        for (var i = 0; i < Object(InteractionData).length; i++) {
            for (var j = 0; j < Object(EmployeeData).length; j++) {
                /**  Populate API array, without input window info and only the necessary /api/interactions JSON data **/
                
                /**  initialize the colleague input windows */
                var exists = InitArrayQuality.filter(obj => {
                    return obj.full_name === EmployeeData[j]['first_name'].concat(' ', EmployeeData[j]['last_name'])
                })
                if (EmployeeData[j]['id'] === InteractionData[i]['target_survey'] && exists.length === 0) {
                    InitArrayQuality.push({
                        full_name: EmployeeData[j]['first_name'].concat(' ', EmployeeData[j]['last_name']),
                        selected: false,
                        source: CurrentUserData[0].id,
                        target: EmployeeData[j]['id'],
                        impact: 1,
                        pleasantness: 1,
                        impact_activated: false,
                        pleasantness_activated: false,
                        initiative: 1,
                        decision_making: 1,
                        finish_line: 1,
                        initiative_activated: false,
                        decision_making_activated: false,
                        finish_line_activated: false,
                        first_name: EmployeeData[j]['first_name'],
                        last_name: EmployeeData[j]['last_name']
                    })
                }
            }
        }
        return InitArrayQuality
    }

    /** initiate ArrayHrs and ArrayTotals with the above Init arrays */
    const [ArrayQuality, updateArrayQuality] = useState(getArrayQuality());
    localStorage.setItem('ArrayQuality', JSON.stringify(ArrayQuality))
    const [ArrayInputCheck, updateArrayInputCheck] = useState(JSON.parse(localStorage.getItem('CheckedEmployeesCollaborationMeasurement')) ? JSON.parse(localStorage.getItem('CheckedEmployeesCollaborationMeasurement')) : []);
    const [render, setRender] = useState(false);
    const reRender = () => {
        setRender(!render)
    };
    const [InputChecker, setInputChecker] = useState(false)
    const [SignificantColleagues, setSignificantColleaguesPerTeam] = useState();
    const employeeRef = useRef([]);
    const [nextButtonClicked, setNextButtonClicked] = useState(false);
    const [selectedColleague, setSelectedColleague] = useState(significantColleagues[0].id)

    return (
        <Container>
            <HeaderAndOverlayWrapper>
                <ComponentHeaderBlock page='Survey' reRender={reRender}
                                      overlay='PageCollaborationMeasurement' directory='/survey-interaction-quality'/>
            </HeaderAndOverlayWrapper>
            <WrapperTop>
                <Trans i18nKey="PageCollaborationMeasurement.Title">
                    How is the <span style={{color: "#B3194A"}}> collaboration </span>
                    with your colleagues?
                </Trans>
            </WrapperTop>

            <WrapperBottom>
                <ComponentSignificantColleagues ArrayQuality={ArrayQuality} updateArrayQuality={updateArrayQuality} ArrayInputCheck={ArrayInputCheck} nextButtonClicked={nextButtonClicked} page={"PageCollaborationMeasurement"}
                    selectedColleague={selectedColleague} setSelectedColleague={setSelectedColleague} reRender={reRender} employeeRef={employeeRef} inputCheckPeople={InputChecker} />

                <ComponentCollaborationSlider ArrayQuality={ArrayQuality} ArrayInputCheck={ArrayInputCheck} updateArrayInputCheck={updateArrayInputCheck}
                    updateArrayQuality={updateArrayQuality} selectedColleague={selectedColleague} setSelectedColleague={setSelectedColleague} SignificantColleagues={SignificantColleagues}
                    reRender={reRender} setInputChecker={setInputChecker} employeeRef={employeeRef} />
            </WrapperBottom>

            <ComponentFooter page='PageCollaborationMeasurement' backDirectory='/survey-spontaneous-collaboration' inputCheckPeople={InputChecker} FooterMarginTop={(useWindowSize()[1] - 104).toString() + "px"} 
                            FooterMarginLeft='0px'FooterHeight='104px' FooterWidth='100%' MarginTop='25px'MarginLeft='77%' BackButtonMarginLeft='65%' NextButtonMarginLeft='calc(65% + 66px)'
                            nextButtonClicked={nextButtonClicked} setNextButtonClicked={setNextButtonClicked} />
        </Container>
    );
};
export default PageCollaborationMeasurement

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 105px);
  background: #F1F3F4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
`;
const HeaderAndOverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3;
`;
const WrapperTop = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 100px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 10px;
  height: 10px;
`;

const WrapperBottom = styled.div`
  margin-top: 20px;
  position: relative;
  width: 80%;
  height: calc(100% - 168px);
  top: 64px;
  background: #F1F3F4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
`;
