import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import MetricsChart from './elements/MetricsChart';
import load from "../../../assets/dashboard/load.gif";
import { ComponentPageTransformation } from '../../../layout-and-styling/standardized-components-library/Component-Tooltips';
import { useState } from "react";

const MetricsChangesBar = ({ surveyDates, metricsData }) => {

    const { t, i18n } = useTranslation();
    const [hover1, setHover1] = useState(false);

    return (
        <MetricsWrapper>

            <MetricsData>

                <MetricsTitle>
                    {t('Transformation.MetricsChanges')}
                </MetricsTitle>

                <MetricsDates>
                    {t('Transformation.From')} {surveyDates[0]} {t('Transformation.To')} {surveyDates[1]}
                </MetricsDates>

                {metricsData.length === 3 ? <MetricsChart data={metricsData} /> :
                    <LoadLogo>
                        {" "}
                        <img src={load} alt="loading..." />
                    </LoadLogo>}

            </MetricsData>

            {/* The hover information */}
            <InfoHover onMouseOver={() => setHover1(true)}
                onMouseOut={() => setHover1(false)}>?
                {hover1 && (<ComponentPageTransformation whichTooltip={"ElephantsMetricsChanges"} />
                )}
            </InfoHover>


        </MetricsWrapper>
    )
}

export default MetricsChangesBar;

const MetricsWrapper = styled.div`
    position: relative;
    background: #FCFCFC;
    width: 654px;
    height: 390px;
    border-radius: 5px;
`;

const MetricsData = styled.div`
    margin-left: 30px;
    margin-top: 23px;
`;

const MetricsTitle = styled.div`
    color: #271B36;
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 8px;
`;

const MetricsDates = styled.div`
    color: #929699;
    font-family: "Overpass";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 16px;
`;

const InfoHover = styled.div`
    font-size: 18px;
    position: absolute;
    font-family: "Overpass";
    font-weight: 300;
    color: #FCFCFC;
    background-color: #e2336b;
    border: 2px solid #e2336b;
    top: 16px;
    border-radius: 500px;
    width: 20px;
    text-align: center;
    height: 20px;
    cursor: pointer;
    z-index: 20;
    right: 16px;
    float: right;
`;

const LoadLogo = styled.div`
  img {
    width: 50px;
  }
  display: flex;
  position: absolute;
  top: 48%;
  left: 45%;
`;