import React, {useState} from 'react';
import styled from '@emotion/styled';
import ComponentMessage from './Component-Message';
import ComponentOptionSelector from './Component-OptionSelector';
import { ComponentTooltipRootcauseChatbot } from '../../../layout-and-styling/standardized-components-library/Component-Tooltips';

export default function ComponentChatbox(props){

    function handleSelectedOption(option){
        props.giveBotSelectedOption(option);
    }
    const [hover1, setHover1] = useState(false);

    return(
        <Container id="chatboxContainer">
          <InfoHover MarginLeft="100px" MarginTop="-10px" onMouseOver={() => setHover1(true)}
                                       onMouseOut={() => setHover1(false)}>?
                                {hover1 && (<ComponentTooltipRootcauseChatbot/> )}
          </InfoHover>
            <div>
            {props.messages.map((item,index)=>{
                if(item[1]){
                    return <div style={{justifyContent: 'left', display: 'flex'}}> <ComponentMessage message={item[0]} owner={item[1]} genAvatar={item[2]}/> </div>
                }
                return <div style={{justifyContent: 'right', display: 'flex'}}> <ComponentMessage message={item[0]} owner={item[1]} genAvatar={item[2]}/> </div>
            })}
            {props.botThinking && <ComponentMessage message="Elly is thinking..." owner={true} genAvatar={true} />}
            {props.formOptions.length > 0 && <ComponentOptionSelector formOptions={props.formOptions} handleSelectedOption={handleSelectedOption} />}
            </div>
        </Container>
    )
}

const Container = styled.div`
  display: flex;
  border-style: solid;
  flex-direction: column;
  width: calc(100% - 88px);
  min-width: 64px;
  height: 100%;
  background-color: #FCFCFC;
  border-color: #C6CBCB;
  border-radius: 20px;
  border-width: 1px;
  font-size: 14px;
  font-weight: 400;
  margin: 0px 32px 0px 32px;
  white-space: normal;
  overflow-y: scroll;
  padding: 24px 8px 12px 16px;
  
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #929699;
    border-radius: 5px;
  }
`;

const InfoHover = styled.div`
  position: absolute;
  margin: 8px;
  top: 124px;
  right: 32px;
  margin-top: ${props => props.MarginTop || ""};
  margin-left: ${props => props.MarginLeft || "50px"};
  font-size: 18px;
  font-family: 'Overpass';
  font-weight: 700;
  color: #FCFCFC;
  background: #E2336B;
  border: 2px solid #E2336B;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
`;

