import styled from 'styled-components';
import { useTranslation } from 'react-i18next';


const CustomLegendBarChart = () => {

    const { t, i18n } = useTranslation();

    return (
        <CustomLegend>
           <LegendLabel><LegendCircle color={"#D6BAEB"}/>{t('Transformation.Before')}</LegendLabel>
           <LegendLabel><LegendCircle color={"#A773E4"}/>{t('Transformation.After')}</LegendLabel>
           <LegendLabel><LegendLine/>{t('Transformation.Benchmark')}</LegendLabel> 
        </CustomLegend>
    )
}

export default CustomLegendBarChart

const CustomLegend = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;
`;

const LegendLabel = styled.div`
    display: flex;
    align-items: center;
    color: #929699;
    font-family: "Overpass";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-inline: 10px;
`;

const LegendCircle = styled.div`
    margin-bottom: 2px;
    margin-right: 3px;
    background-color: ${props => props.color};
    border-radius: 50%;
    width: 10px;
    height: 10px;
`;

const LegendLine = styled.div`
    margin-bottom: 5px;
    margin-right: 3px;
    margin-left: -5px;
    width: 12px;
    height: 1px;
    border-bottom: 2px solid #E2336B;
`;