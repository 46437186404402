import React, { useEffect, useState, useContext} from 'react';
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import Fade from '@mui/material/Fade';
import {SubProcessBlock} from '../layout-and-styling/standardized-components-library/Styling-ProcessBlocks';


const ProcessData = JSON.parse(localStorage.getItem('businessProcessIntake'));

const MappedSubProcesses = ({ 
    current_process_object,
    current_process_id,
    containerRef
}) => {
const [hover, setHover] = useState(false)
const {t, i18n} = useTranslation();

return (
    <>
        {current_process_id === 0 &&
            <>
                <ArrowProcess
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                Background={hover ? "#E2336B" : "#271B36"}
                RightBorderTop={"40px solid transparent"}
                RightBorderBottom={"40px solid transparent"}
                RightBorderLeft={hover ? "40px solid #E2336B" : "40px solid #271B36"}
                RightBorderRight={"none"}
                ref={containerRef}>
                    <ArrowTitle id={current_process_object.name}>
                        {current_process_object.name}
                    </ArrowTitle>
                </ArrowProcess>
                
                <Fade in={hover} timeout={600} container={containerRef.current}>
                    <HoverTooltip>
                        {current_process_object.subProcesses.map((subprocess, subprocess_index) => {
                            if (subprocess_index === current_process_object.subProcesses.length - 1) {
                                return (
                                    <>
                                        <SubProcessColumnWrapper>
                                            <SubProcessBlock MarginRight='16px'>
                                                <SubProcessText>
                                                {subprocess.name}
                                                    </SubProcessText>
                                            </SubProcessBlock>
                                        </SubProcessColumnWrapper>
                                        
                                        <BreakColumn/>
                                    </>
                                )
                            } else {
                                return (
                                    <SubProcessColumnWrapper>
                                        <SubProcessBlock MarginRight='16px'>
                                            <SubProcessText>
                                                {subprocess.name} {/* {context.setBusinessProcess(subprocess, i18n.language)} */}
                                            </SubProcessText>
                                        </SubProcessBlock>
                                    </SubProcessColumnWrapper>
                                )
                            }
                        })}
                    </HoverTooltip>
                </Fade>
            </>
        }

        {current_process_id === ProcessData.length - 1 &&
            <>
                <ArrowProcess3
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                // onClick={() => changeChecked(current_process_id)}
                Background={
                    // contains ? "#B3194A" : 
                    hover ? "#E2336B" : "#271B36"}
                LeftBorderTop={"40px solid transparent"}
                LeftBorderBottom={"40px solid transparent"}
                LeftBorderLeft={"40px solid #FCFCFC"}
                LeftBorderRight={"none"}
                ref={containerRef}>
                    <ArrowTitle id={current_process_object.name}>
                        {current_process_object.name} {/* {context.setBusinessProcess(current_process_object.name, i18n.language)} */}
                    </ArrowTitle>
                </ArrowProcess3>

                <Fade in={hover} timeout={600} container={containerRef.current}>
                    <HoverTooltip>
                        {current_process_object.subProcesses.map((subprocess, subprocess_index) => {
                            if (subprocess_index === current_process_object.subProcesses.length - 1) {
                                return (
                                    <>
                                        <SubProcessColumnWrapper>
                                            <SubProcessBlock MarginRight='16px'>
                                                <SubProcessText>
                                                    {subprocess.name} {/* {context.setBusinessProcess(subprocess, i18n.language)} */}
                                                </SubProcessText>
                                            </SubProcessBlock>
                                        </SubProcessColumnWrapper>

                                        <BreakColumn/>
                                    </>
                                )
                            } else {
                                return (
                                    <SubProcessColumnWrapper>
                                        <SubProcessBlock MarginRight='16px'>
                                            <SubProcessText>
                                                {subprocess.name} {/* {context.setBusinessProcess(subprocess, i18n.language)} */}
                                            </SubProcessText>
                                        </SubProcessBlock>
                                    </SubProcessColumnWrapper>
                                )
                            }})
                        }
                    </HoverTooltip>
                </Fade>
            </>
        }

        {current_process_id !== 0 && current_process_id !== ProcessData.length - 1 &&
            <>
                <ArrowProcess2
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                Background={hover ? "#E2336B" : "#271B36"}
                LeftBorderTop={"40px solid transparent"}
                LeftBorderBottom={"40px solid transparent"}
                LeftBorderLeft={"40px solid #FCFCFC"}
                LeftBorderRight={"none"}
                RightBorderTop={"40px solid transparent"}
                RightBorderBottom={"40px solid transparent"}
                RightBorderLeft={
                    // contains ? "40px solid #B3194A" : 
                    hover ? "40px solid #E2336B" : "40px solid #271B36"}
                RightBorderRight={"none"}
                ref={containerRef}>
                    <ArrowTitle id={current_process_object.name}>
                        {current_process_object.name} {/* {context.setBusinessProcess(current_process_object.name, i18n.language)} */}
                    </ArrowTitle>
                </ArrowProcess2>

                <Fade in={hover} timeout={600} container={containerRef.current}>
                    <HoverTooltip>
                        <SubProcessWrapper>
                            {current_process_object.subProcesses.map((subprocess, subprocess_index) => {
                                if (subprocess_index === current_process_object.subProcesses.length - 1) {
                                    return (
                                        <>
                                            <SubProcessColumnWrapper>
                                                <SubProcessBlock MarginRight='16px'>
                                                    <SubProcessText>
                                                        {subprocess.name} {/* {context.setBusinessProcess(subprocess, i18n.language)} */}
                                                    </SubProcessText>
                                                </SubProcessBlock>
                                            </SubProcessColumnWrapper>

                                            <BreakColumn/>
                                        </>
                                    )
                                } else {
                                    return (
                                        <SubProcessColumnWrapper>
                                            <SubProcessBlock MarginRight='16px'>
                                                <SubProcessText>
                                                    {subprocess.name} {/* {context.setBusinessProcess(subprocess, i18n.language)} */}
                                                </SubProcessText>
                                            </SubProcessBlock>
                                        </SubProcessColumnWrapper>
                                    )
                                }
                            })}
                        </SubProcessWrapper>
                    </HoverTooltip>
                </Fade>
            </>
        }
    </>
)}

export default MappedSubProcesses;

const ArrowProcess = styled.div`
  width: 160px;
  height: 80px;
  background: ${props => props.Background || "#271B36"};
  border-radius: 8px 2px 2px 8px;
  color: #FCFCFC;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  cursor: pointer;

  &:after {
    content: " ";
    z-index: 4;
    position: absolute;
    margin-left: 200px;
    border-right: ${props => props.RightBorderRight || "28px solid #271B36"};
    border-top: ${props => props.RightBorderTop || "28px solid transparent"};
    border-bottom: ${props => props.RightBorderBottom || "28px solid transparent;"};
    border-left: ${props => props.RightBorderLeft || "28px solid #271B36"};
  }
`;

const ArrowProcess2 = styled.div`
  width: 160px;
  height: 80px;
  background: ${props => props.Background || ""};
  border-radius: 2px;
  color: #FCFCFC;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  cursor: pointer;

  &:before {
    content: " ";
    /* z-index: 11; */
    position: absolute;
    margin-right: 130px;
    border-top: ${props => props.LeftBorderTop || "28px solid #271B36"};
    border-right: ${props => props.LeftBorderRight || "28px solid #271B36"};
    border-bottom: ${props => props.LeftBorderBottom || "28px solid #271B36"};
    border-left: ${props => props.LeftBorderLeft || "28px solid transparent"};
  }

  &:after {
    content: " ";
    z-index: 15;
    position: absolute;
    margin-left: 200px;
    border-right: ${props => props.RightBorderRight || "28px solid #271B36"};;
    border-top: ${props => props.RightBorderTop || "28px solid transparent"};
    border-bottom: ${props => props.RightBorderBottom || "28px solid transparent;"};
    border-left: ${props => props.RightBorderLeft || "28px solid #271B36"};
  }
`;

const ArrowProcess3 = styled.div`
  width: 160px;
  height: 80px;
  background: ${props => props.Background || ""};
  border-radius: 2px 8px 8px 2px;
  color: #FCFCFC;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  cursor: pointer;

  &:before {
    content: " ";
    position: absolute;
    margin-right: 130px;
    border-top: ${props => props.LeftBorderTop || "28px solid #271B36"};
    border-right: ${props => props.LeftBorderRight || "28px solid #271B36"};
    border-bottom: ${props => props.LeftBorderBottom || "28px solid #271B36"};
    border-left: ${props => props.LeftBorderLeft || "28px solid transparent"};
  }
`;

const ArrowTitle = styled.div`
  margin-left: 40px;
  text-align: center;
  width: 88px;
  color: var(--primary-color-white, #FCFCFC);
  text-align: center;
  font-family: Overpass;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
`;

const HoverTooltip = styled.div`
  //display: flex;
  //align-items: center;
  position: absolute;
  padding: 0 0 10px 20px;
  top: 300px;
  z-index: 3;
  background: #FCFCFC;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const SubProcessColumnWrapper = styled.div`
`;

const SubProcessWrapper = styled.div`
`;
const SubProcessText = styled.p`
  margin: 0;
  padding: 3px;
  width: 115px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: 'Overpass';
  font-weight: ${props => props.FontWeight || "400"};
  font-size: ${props => props.FontSize || "14px"};
  line-height: 18px;
  overflow: hidden;
color: var(--primary-color-dark-purple, #271B36);
text-align: center;
/* Body 3 */
font-family: Overpass;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
`;
const BreakColumn = styled.div`
  flex-basis: ${props => props.FlexBasis || "100%"};
`;



