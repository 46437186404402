import styled from "styled-components";
import React, {useState} from "react";
import {ReactComponent as FlipButtonFront} from "../../assets/dashboard/FlipButtonFront.svg"
import {ReactComponent as AdviceIcon} from "../../assets/dashboard/AdviceIcon.svg"

import {Layer, Rectangle} from "recharts";

const MyCustomComponent = (props) => {
    const {x, y, width, height, index, payload, containerWidth} = props;
    const isOut = x + width + 6 > containerWidth;
    return (
        <Layer key={`CustomNode${index}`}>
            <Rectangle
                x={x} y={y} width={width} height={height}
                fill={payload.color} fillOpacity="1"
            />
            <text
                textAnchor={isOut ? 'end' : 'start'}
                x={isOut ? x - 6 : x + width + 6}
                y={y + height / 2}
                fontSize="14"
                stroke="#5F6366"
                strokeOpacity="0.2"
            >{payload.name}</text>
            <text
                textAnchor={isOut ? 'end' : 'start'}
                x={isOut ? x - 6 : x + width + 6}
                y={y + height / 2 + 13}
                fontSize="12"
                stroke="#5F6366"
                strokeOpacity="0.3"
            >{payload.value + ' employees'}</text>
        </Layer>
    );
}
const MyCustomLinkComponent = (props) => {
    return <path
        d={`
      M${props.sourceX},${props.sourceY}
      C${props.sourceControlX},${props.sourceY} ${props.targetControlX},${props.targetY} ${props.targetX},${props.targetY}
    `}
        stroke={props.payload.color}
        strokeWidth={props.linkWidth}
        {...props}
    />
}

const FrontCard = ({selectedPage, component, flipBack, data}) => {
    if (selectedPage === "Collaboration Quality") {

        return (
            <>
                <FrontCardTitle>Collaboration Quality</FrontCardTitle>

                <CircleWrapper Border={"10px solid #FFD166"}>
                    <Score>51%</Score>
                </CircleWrapper>

                <FlipButtonFront onClick={flipBack}/>
            </>
        )
    } else if (selectedPage === "Knowledge") {
        return (
            <>

                <FrontCardTitle>Knowledge</FrontCardTitle>

                <CircleWrapper Border={"10px solid #06D6A0"}>
                    <Score>78%</Score>
                </CircleWrapper>

                <FlipButtonFront onClick={flipBack}/>

            </>
        )


    } else if (selectedPage === "Driving Force") {
        return (
            <>

                <FrontCardTitle>Driving Force</FrontCardTitle>

                <CircleWrapper Border={"10px solid #E2336B"}>
                    <Score>38%</Score>
                </CircleWrapper>

                <FlipButtonFront onClick={flipBack}/>

            </>
        )

    }
};
const BackCard = ({selectedPage, component, flipBack, data}) => {
        if (selectedPage === "Collaboration Quality") {
            return (
                <>
                    <BackCardWrapper>

                        <BackCardTitle>Employees who need improvements</BackCardTitle>
                        <BackCardAdvice>
                            <AdviceIcon/>

                            <AdviceText>
                                Contact formal leaders to improve communication
                            </AdviceText>
                        </BackCardAdvice>

                        <ActionsContainer>

                        </ActionsContainer>
                    </BackCardWrapper>
                </>

            )

        } else if (selectedPage === "Knowledge") {
            return (
                <>
                    <BackCardWrapper>

                        <FrontCardTitle>Satisfaction factors scores for all teams</FrontCardTitle>
                        <FrontCardSubTitle>Showcasing the satisfaction score of the factors.</FrontCardSubTitle>

                    </BackCardWrapper>

                </>

            )
        } else if (selectedPage === "Driving Force") {
            return (
                <>
                    <BackCardWrapper>

                        <BackCardTitle>Between teams collaboration</BackCardTitle>
                        <BackCardSubTitle>Below are the collaborations between teams that need the most improvements
                        </BackCardSubTitle>
                    </BackCardWrapper>
                </>
            )
        }
    }
;

const FlipCard = ({selectedPage, component, data}) => {

    const [flip, setFlipped] = useState(false);

    function flipCard() {
        setFlipped(!flip);
    }

    return (
        <Card Transform={flip ? "perspective(1000px) rotateY(180deg)" : ""} TransformStyle={flip ? "preserve-3d" : ""}>
            <CardFront>
                <CardBody>
                    <FrontCard selectedPage={selectedPage} flipBack={flipCard} data={data}/>
                </CardBody>
            </CardFront>
            <CardBack onClick={flipCard}>
                <CardBody>
                    <BackCard selectedPage={selectedPage} data={data}/>
                </CardBody>
            </CardBack>
        </Card>
    );
}

export default FlipCard;

const Card = styled.div`
  position: relative;
  background-color: #fcfcfc;
  width: 529px;
  min-height: 602px;
  cursor: pointer;
  perspective: 1000px;
  transition: all 0.5s ease-in-out;

  &:first-of-type { // Front side of the card
    transform: ${props => props.Transform || ''};
    transform-style: ${props => props.TransformStyle || ''}
  }

  &:last-of-type { // Back side of the card
    transform: ${props => props.Transform || ''};
    transform-style: ${props => props.TransformStyle || ''}
  }

  &:focus,
  &:hover {
    box-shadow: 0 0 40px rgba(0, 0, 0, .15);
  }

`;

const CardFront = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; // both sides will be stacked on each other and only one will be visible
  transition: all 0.25s ease-in-out;
  font-weight: bold;
  text-align: center;
`;

const CardBack = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  //padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; // both sides will be stacked on each other and only one will be visible
  transform: rotateY(-180deg);
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 100px;
`;

const FrontCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FrontCardTitle = styled.div`
  display: flex;
  color: #271B36;
  text-align: center;
  font-size: 32px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
`;

const FrontCardSubTitle = styled.div`
  display: flex;
  font-family: Overpass;
  font-size: 14px;
  font-weight: 700;
  color: #5F6366;
  line-height: 18px;
`;

const BackCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-top: 24px;
  margin-right: 20px;
`;

const BackCardTitle = styled.div`
  display: flex;
  font-family: Overpass;
  font-size: 18px;
  font-weight: 700;
  color: #271B36;
  line-height: 20px;
`;

const BackCardSubTitle = styled.div`
  display: flex;
  font-family: Overpass;
  font-size: 14px;
  font-weight: 700;
  color: #5F6366;
  line-height: 18px;
`;

const TagComponent = styled.div`
  display: flex;
  flex-direction: row;
  height: 300px;
`;

const CircleWrapper = styled.div`
  width: 229px;
  height: 229px;
  border-radius: 229px;
  border: ${props => props.Border || ''};
  background: #271B36;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Score = styled.div`
  color: #FCFCFC;
  text-align: center;
  font-size: 56px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  line-height: 68px;
`;

const BackCardAdvice = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  border-radius: 5px;
  border: 1px solid #E2336B;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
`;

const AdviceText = styled.div`
  color: #271B36;
  text-align: center;
  font-size: 14px;
  font-family: Overpass;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

const ActionsContainer = styled.div`

`;
