import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import Delete from "../../../assets/delete.png";
import ComponentTimeDay from "./Component-time-day";
import ComponentMeeting from './Component-meeting';
import {ReactComponent as Checkmark} from "../../../assets/SurveyCommunication/Icon_Survey_CheckMark_ClickCounter_Green.svg";

import {useTranslation} from "react-i18next";

const ComponentDay = (props) => {
    const {t} = useTranslation();

    const myElementRef = useRef(null);
    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
      const handleScroll = () => {
        const el = myElementRef.current;
        setScrollTop(el.scrollTop);
      };

      const element = myElementRef.current;
      element.addEventListener("scroll", handleScroll);

      return () => {
        element.removeEventListener("scroll", handleScroll);
      };
    }, []);
    
    function changeDaySelection(value) {
      props.setSelectedDay(value);
      if (props.MeetingsDays[value].selected === false) {

        props.MeetingsDays.map((day, index) => {
            if (index === value) {
                day.selected = true
            }
        });

        props.MeetingsDays.map((day, index) => {
            if (index !== value) {
                day.selected = false;
            }
        });
      }
      props.reRender();
      props.setMeetingsDays(props.MeetingsDays)
      localStorage.setItem('MeetingsDays', JSON.stringify(props.MeetingsDays))
    }

    const changeMeetingSelection = (meeting, day) => {
      if (props.MeetingsDays[day].meetings[meeting].selected === false) {

        props.MeetingsDays[day].meetings.map((val, index) => {
              if (index === meeting) {
                  val.selected = true;
              }
          });

          props.MeetingsDays[day].meetings.map((val, index) => {
              if (index !== meeting) {
                  val.selected = false;
              }
          });
      }
      props.reRender();
      props.setSelectedMeeting(meeting);
      props.setMeetingsDays(props.MeetingsDays)
      localStorage.setItem('MeetingsDays', JSON.stringify(props.MeetingsDays))
    }

    function removeDay() {

      props.setSelectedMeeting(0)
      props.setSelectedDay(props.id-1)
      props.MeetingsDays.splice(props.id, 1);
      for (let i = 0; i < props.meetingsTimes.length; i++) props.meetingsTimes[i]["days"].splice(props.id, 1);
      props.setMeetingsDays(props.MeetingsDays)
      props.setMeetingsTimes(props.meetingsTimes)
      localStorage.setItem("MeetingsDays", JSON.stringify(props.MeetingsDays))
      localStorage.setItem("meetingsTimes", JSON.stringify(props.meetingsTimes))

      props.SpontaneousDays.splice(props.id, 1);
      for (let i = 0; i < props.spontaneousTimes.length; i++) props.spontaneousTimes[i]["days"].splice(props.id, 1);
      props.setSpontaneousDays(props.SpontaneousDays)
      props.setSpontaneousTimes(props.spontaneousTimes)
      localStorage.setItem("SpontaneousDays", JSON.stringify(props.SpontaneousDays))
      localStorage.setItem("spontaneousTimes", JSON.stringify(props.spontaneousTimes))

      props.reRender()
    }  

    return (
        <Container>

              <ComponentTimeDay changeDaySelection={changeDaySelection} day={props.day} id={props.id} selectedDay={props.selectedDay}/>

              <MeetingsWrapper ref={myElementRef} Height={props.id > 4 ? '276px' : '328px'}
                onClick={() => changeDaySelection(props.id)}>
                {props.MeetingsDays[props.id].meetings.length > 0 && 
                  props.MeetingsDays[props.id].meetings.map((meeting, meeting_id) => (
                    <ComponentMeeting day={props.id} selectedDay={props.selectedDay} meeting_id={meeting_id} scrollLeft={props.scrollLeft}
                          meeting={meeting} reRender={props.reRender} selectedMeeting={props.selectedMeeting} scrollTop={scrollTop}
                          changeMeetingSelection={changeMeetingSelection} MeetingsDays={props.MeetingsDays} setInteractionTime={props.setInteractionTime}
                          setMeetingsDays={props.setMeetingsDays} interactionTime={props.interactionTime}/>
                ))}
              </MeetingsWrapper>

              {props.id > 4 &&
                <ButtonWrapper>
                  <button onClick={removeDay}>
                    <img width='20px' src={Delete}/>
                    {/* {t("PagePlannedMeetings.RemoveDay")} */}
                  </button>
                </ButtonWrapper>
              }
              
        </Container>
    );
};

export default ComponentDay;

const Container = styled.div`
  opacity: ${props => props.Opacity || "1"};
  pointer-events: ${props => props.PointerEvents || ""};
  width: 172px;
  min-width: 172px;
  height: 100%;
  background-color: #F1F3F4;
  position: relative;
  border: none;
  font-family: "Overpass";
  cursor: pointer;
`;

const MeetingsWrapper = styled.div`
  width: 153px;
  height: ${props => props.Height || '325px'};
  display: flex;
  padding: 8px; 
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #F1F3F4;
  margin: 0 0 0 4px;
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    height: 120px;
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #929699;
    border-radius: 5px;
  }
`;

const ButtonWrapper = styled.div`
  button {
    display: flex;
    vertical-align: middle;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 167px;
    height: 32px;
    padding: 8px 12px 8px 12px;
    margin-left: 3px;
    background: #FCFCFC;
    border-radius: 32px;
    border: none;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    font-family: "Overpass";
    cursor: pointer;
    gap: 8px;
  }

  button:hover {
    background: #919191;
    color: #FCFCFC;
  }
`;