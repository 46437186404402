import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import {ReactComponent as Footer_back_button} from "../../assets/leaderintake/Footer_back_button.svg";
import {ReactComponent as SurveyFooterBackButton} from "../../assets/footer/BackButton_Survey_56px.svg";
import ProgressBar from "@ramonak/react-progress-bar";
import { handleLogout, PostSignificantInteractions, UpdateFeedbackImprovement, UpdateFeedbackOptimal,
        UpdateMeetingHours, UpdateSpontaneousHours, UpdateKnowledge, UpdateProactiveness, 
        UpdateCollaborationQuality, UserIntroToColleagueFilterPromises, UpdateCulture, UpdateCulturePeer, UpdateRootcauseChatbot, SendRepresentativeIntake} from "./Component-API";
import {StyledDivRegular} from './Styling-TextInput';
import {useTranslation} from "react-i18next";
import axios from 'axios';
import logo from "../../assets/dashboard/load.gif";
import getCookieValue from '../../utils';


function ComponentFooter(props) {
    /** Component to build footer buttons, with the corresponding API calls.
     * First section you find all the API get, post and update request functions
     * Second section you find the if statements for specific pages, to load in specific styling and API requests
     * Third section you find the styled div css styling
     */

    /** Go from one directory to another via useHistory */
    let history = useHistory();
    const {t} = useTranslation();
    const [buttonClicked, setButtonClicked] = useState(false);
    const [advance, setAdvance] = useState(false);
    const axiosInstance = axios.create({withCredentials: true});

    async function sendPageTime(page, windowReplace)
    {
        const axiosInstance = axios.create({withCredentials: true});

        await axiosInstance
            .post(
                process.env.REACT_APP_APIURL +
                "/api/pagetimestamp/",
                {"page": page},
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": getCookieValue("csrftoken"),
                    },
                }
            )
            .then (() => {
                if(windowReplace) {
                    window.location.replace(process.env.REACT_APP_APPURL + windowReplace);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const [buttonWidth, setButtonWidth] = useState(0);
    const [buttonTimeout, setButtonTimeout] = useState(props.timeout || 5000);

    useEffect(() => {
        if (props.page != "PageBenefits") {
            enableButton()
        }
    }, [props.identifier]);

    const enableButton = () => {
        const buttonEnabledBefore = localStorage.getItem(`buttonEnabled${props.identifier}`);

        if (!buttonEnabledBefore) {
            setButtonWidth('100%')
    
            setTimeout(() => {
                localStorage.setItem(`buttonEnabled${props.identifier}`, 'true');
                setButtonEnabled(true);
            }, buttonTimeout);
        } else {
            setButtonEnabled(true);
        }
    }

    useEffect(() => {
        if (props.componentClicked) {
            enableButton()
        }
    }, [props.componentClicked]);

    /** Workaround for API and rendering issues, with timeouts and forced re-renders
     * TODO: async await API handshakes
     * TODO: proper first render of mapped localStorage data, without the need of page refresh
     */
    /** If else statement, to only push ArrayCloseColleagues for now at "PageCommFreqMeasFilter */
    if (props.page === "LeaderIntakeFirstPage") {
        return (
            <Wrapper Height='104px'>
                <ButtonWrapperNext MarginTop='860px' MarginLeft='1540px' Height='48px' Width='160px'>
                    <button
                        onClick={() => {
                            history.push(props.nextdirectory);
                        }}
                    >Next
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    if (props.page === "LeaderIntake") {
        return (
            <Wrapper Height='104px'>
                <ButtonWrapperBack MarginTop='860px' MarginLeft='1464px' Height='48px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop='860px' MarginLeft='1540px' Height='48px' Width='160px'>
                    <button
                        onClick={() => {
                            history.push(props.nextdirectory);
                        }}
                    >Next
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }
    if (props.page === "ButtonOnly") {
        return (
            <>
                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft} PointerEvents={"all"}
                                   Width={props.Width} Height={props.Height} BorderRadius={props.BorderRadius}>
                    <button
                        onClick={() => {
                            history.push(props.nextdirectory);
                        }}
                    > {props.ButtonText} </button>
                </ButtonWrapperNext>
            </>
        );
    }

    if (props.page === "IntroBackNext") {
        return (

            <Wrapper>
                <ButtonWrapperBack MarginTop={props.BackMarginTop} MarginLeft={props.BackMarginLeft}
                                   Width={props.BackWidth} Height={props.BackHeight} BorderRadius={props.BackBorderRadius}>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.NextMarginTop} MarginLeft={props.NextMarginLeft}
                                   Width={props.NextWidth} Height={props.NextHeight} BorderRadius={props.NextBorderRadius}>
                    <button
                        onClick={() => {
                            history.push(props.nextdirectory);
                        }}
                    > {props.NextButtonText} </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    /** User input, then use api/employee api/mydetails */
    if (props.page === "IntroVerificationButton") {
        const checked = props.checked_flag;
        return (

            <>
                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft} Width={props.Width} Height={props.Height}
                                   BorderRadius={props.BorderRadius} BackgroundColor={checked === true ? "#e2336B" : "#F7C9D9"} Cursor={checked === true ? "pointer" : "default"}>

                    <button
                        disabled={!checked}
                        onClick={() => {
                            history.push(props.nextdirectory);
                        }}
                    >
                        {props.ButtonText}
                    </button>
                </ButtonWrapperNext>
            </>

        );
    }

    if (props.page === "PageWelcome") {
        return (
            <IntroductionContainer>

                {buttonEnabled ? (
                    <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft} PointerEvents={"all"}
                                Width={props.Width} Height={props.Height}>
                        <button
                            onClick={() => {
                                sendPageTime("user-intro", props.nextdirectory);
                                history.push(props.nextdirectory);
                            }}
                        >
                            {t('Component-NextButton')}
                        </button>
                    </ButtonWrapperNext>
                ) : (
                    <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft}
                                            Width={props.Width} Height={props.Height}>
                        <ButtonWrapperLoading>
                            <button disabled={true}>
                                {t('Component-NextButton')}
                            </button>

                            <ButtonWrapperLoadingOverlay Width={buttonWidth} Transition={`${buttonTimeout}ms`}>
                                <button disabled={true}>
                                    {t('Component-NextButton')}
                                </button>
                            </ButtonWrapperLoadingOverlay>
                        </ButtonWrapperLoading>
                    </ButtonWrapperNext>
                )}

            </IntroductionContainer>
        );
    }

    /** User input GPDR, then get data for next page */
    if (props.page === "PageBenefits") {
        return (
            <IntroductionContainer>

                <ButtonWrapperBack Position={props.Position} MarginTop={props.MarginTop} MarginLeft={props.BackMarginLeft}
                                   Width={props.BackWidth} Height={props.BackHeight}>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.prevdirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>
                
                {buttonEnabled ? (
                    <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft} PointerEvents={"all"}
                                       Width={props.Width} Height={props.Height}>
                        <button
                            onClick={() => {
                                sendPageTime("page-user-benefits")
                                history.push(props.nextdirectory);
                            }}
                        >
                            {t('Component-NextButton')}
                        </button>
                    </ButtonWrapperNext>
                ) : (
                    <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft}
                                            Width={props.Width} Height={props.Height}>
                        <ButtonWrapperLoading>
                            <button disabled={true}>
                                {t('Component-NextButton')}
                            </button>

                            <ButtonWrapperLoadingOverlay Width={buttonWidth} Transition={`${buttonTimeout}ms`}>
                                <button disabled={true}>
                                    {t('Component-NextButton')}
                                </button>
                            </ButtonWrapperLoadingOverlay>
                        </ButtonWrapperLoading>
                    </ButtonWrapperNext>
                )}
                
            </IntroductionContainer>
        );
    }

    /** User input GPDR, then get data for next page */
    if (props.page === "PageBenefitsDisabled") {
        return (
            <IntroductionContainer>

                <ButtonWrapperBack Position={props.Position} MarginTop={props.MarginTop} MarginLeft={props.BackMarginLeft}
                                   Width={props.BackWidth} Height={props.BackHeight}>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.prevdirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>
                
                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft}
                                   Width={props.Width} Height={props.Height}>
                    <ButtonWrapperLoading>
                        <button disabled={true}>
                            {t('Component-NextButton')}
                        </button>
                    </ButtonWrapperLoading>
                </ButtonWrapperNext>
                
            </IntroductionContainer>
        );
    }

    /** User input GPDR, then get data for next page */
    if (props.page === "PageInstructions") {
        return (
            <IntroductionContainer>

                <ButtonWrapperBack Position={props.Position} MarginTop={props.MarginTop} MarginLeft={props.BackMarginLeft}
                                   Width={props.BackWidth} Height={props.BackHeight}>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.prevdirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                {buttonEnabled ? (
                    !buttonClicked ? (
                        <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft} PointerEvents={"all"}
                                           Width={props.Width} Height={props.Height}>
                            <button
                                onClick={() => {
                                    setButtonClicked(true)
                                    UserIntroToColleagueFilterPromises(props.hasBeenRequested, props.setHasBeenRequested, setButtonClicked)
                                }}>
                                {t('Component-GetStartedButton')}
                            </button>
                        </ButtonWrapperNext> 
                    ) : ( 
                        <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft}
                                           Width={props.Width} Height={props.Height}>
                            <ButtonWrapperLogo>
                                <button disabled={true}/>
                                <img src={logo} alt="loading..."/>   
                            </ButtonWrapperLogo>
                        </ButtonWrapperNext>
                    )
                ) : (
                    <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft}
                                            Width={props.Width} Height={props.Height}>
                        <ButtonWrapperLoading>
                            <button disabled={true}>
                                {t('Component-GetStartedButton')}
                            </button>

                            <ButtonWrapperLoadingOverlay Width={buttonWidth} Transition={`${buttonTimeout}ms`}>
                                <button disabled={true}>
                                    {t('Component-GetStartedButton')}
                                </button>
                            </ButtonWrapperLoadingOverlay>
                        </ButtonWrapperLoading>
                    </ButtonWrapperNext>
                )}

            </IntroductionContainer>
        );
    }

    if (props.page === "PageCloseColleagueFilter") {
        /** Built in check whether anything is filled in */
        // const input_checked_process = props.input_check_process;
        const input_checked_people = props.inputCheckPeople;
        const nextButtonClicked = props.nextButtonClicked;
        const customer_check_hours = props.customerAndSupplier.customer.hours;
        const supplier_check_hours = props.customerAndSupplier.supplier.hours;
        const customer_check_significant = props.customerAndSupplier.customer.interaction;
        const supplier_check_significant = props.customerAndSupplier.supplier.interaction;
        

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 1 of 10 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 1 / 10} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft}
                                PointerEvents={advance ? "none" : "all"} Height='56px' Width='160px' BackgroundColor={advance === true ? "#F7C9D9" : "#e2336B"}  Cursor={advance === true ? "default" : "pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            if(input_checked_people && (customer_check_hours > 0 || customer_check_significant === false) && (supplier_check_hours > 0 || supplier_check_significant === false)){
                                setAdvance(true)
                                PostSignificantInteractions(setButtonClicked)
                            }
                        }}
                    >
                        {advance && (
                            <LoadLogo MarginTop={"-12px"}>
                                <img src={logo} alt="loading..."/>
                            </LoadLogo>
                        )}
                        {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    if (props.page === "PagePlannedMeetingsQuestion") {

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button id="backbtn">
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

            </Wrapper>
        );
    }

    if (props.page === "PagePlannedMeetings") {

        function checkMeetings() {
            let meetingsNoPeople = []
            let meetingsNoTime = []
            for (let i = 0; i < props.MeetingsDays.length; i++) {
                for (let j = 0; j < props.MeetingsDays[i].meetings.length; j++) {
                    if (props.MeetingsDays[i].meetings[j]["counter_people"] === 0) meetingsNoPeople.push(props.MeetingsDays[i].meetings[j]["name"])
                    if (props.MeetingsDays[i].meetings[j]["duration"] === 0) meetingsNoTime.push(props.MeetingsDays[i].meetings[j]["name"]) 
                }
            }
            return [meetingsNoPeople, meetingsNoTime]
        }

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 2 of 10 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 2 / 10} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={advance ? "none" : "all"} Height='56px' Width='160px'
                                BackgroundColor={advance === true ? "#F7C9D9" : "#e2336B"} Cursor={advance === true ? "default" : "pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            let checker = checkMeetings()
                            props.setCheckMeetingMembers(checker[0].length == 0)
                            props.setCheckMeetingTime(checker[1].length == 0)
                            if(checker[0].length == 0 && checker[1].length == 0) {
                                setAdvance(true)
                                UpdateMeetingHours(setButtonClicked)
                            } 
                        }}
                    >
                        {advance && (
                            <LoadLogo MarginTop={"-12px"}>
                                <img src={logo} alt="loading..."/>
                            </LoadLogo>
                        )}
                       {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    if (props.page === "PageSpontaneousCollaboration") {
        /** Built in check whether anything is filled in */
        const inputCheckPeople = props.inputCheckPeople;

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 3 of 10 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 3 / 10} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button id="backbtn">
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={advance ? "none" : "all"} Height='56px' Width='160px'
                                BackgroundColor={advance === true ? "#F7C9D9" : "#e2336B"} Cursor={advance === true ? "default" : "pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            if(inputCheckPeople){
                                setAdvance(true)
                                UpdateSpontaneousHours(setButtonClicked)
                            }
                        }}
                    >
                        {advance && (
                            <LoadLogo MarginTop={"-12px"}>
                                <img src={logo} alt="loading..."/>
                            </LoadLogo>
                        )}
                       {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    if (props.page === "PageCollaborationMeasurement") {
        /** Built in check whether anything is filled in */
        const inputCheckPeople = props.inputCheckPeople;

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 4 of 10 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 4 / 10} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={"all"} Height='56px' Width='160px'
                                   BackgroundColor={"#e2336B"} Cursor={"pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            if(inputCheckPeople){
                                setAdvance(true)
                                UpdateCollaborationQuality(setButtonClicked)
                            }
                        }}
                    >
                        {advance && (
                        <LoadLogo MarginTop={"-13px"}>
                            <img src={logo} alt="loading..."/>
                        </LoadLogo>
                        )}
                        {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    if (props.page === "PageProactiveness") {
        /** Built in check whether anything is filled in */
        const inputCheckPeople = props.inputCheckPeople;
        const data = props.data;

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 5 of 10 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 5 / 10} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={"all"} Height='56px' Width='160px'
                                   BackgroundColor={"#e2336B"} Cursor={"pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            if(inputCheckPeople){
                                setAdvance(true)
                                UpdateProactiveness(setButtonClicked)
                            }
                        }}
                    >
                        {advance && (
                        <LoadLogo MarginTop={"-13px"}>
                            <img src={logo} alt="loading..."/>
                        </LoadLogo>
                        )}
                        {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>                        
            </Wrapper>
        );
    }

    if (props.page === "PageKnowledge") {
        /** Built in check whether anything is filled in */
        const input_check_individual_impact = props.input_check_individual_impact;
        const slider_checked = props.sliderCheck;
        const knowledge_processes_checked = props.input_check_knowledge_processes;

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 6 of 10 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 6 / 10} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={"all"} Height='56px' Width='160px'
                                   BackgroundColor={"#e2336B"} Cursor={"pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            if(knowledge_processes_checked && input_check_individual_impact && slider_checked){
                                setAdvance(true)
                                UpdateKnowledge(setButtonClicked)
                            }
                        }}
                    >
                        {advance && (
                        <LoadLogo MarginTop={"-13px"}>
                            <img src={logo} alt="loading..."/>
                        </LoadLogo>
                        )}
                        {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    if (props.page === "PageCulture") {
        
        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 7 of 10 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 7 / 10} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={advance ? "none" : "all"} Height='56px' Width='160px'
                                BackgroundColor={advance === true ? "#F7C9D9" : "#e2336B"} Cursor={advance === true ? "default" : "pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                           const userOwnCulture = localStorage.getItem("userOwnCulture") ? JSON.parse(localStorage.getItem("userOwnCulture")) : {};
                           const ArrayCultureApi = localStorage.getItem("ArrayCultureApi") ? JSON.parse(localStorage.getItem("ArrayCultureApi")) : [];
                            setAdvance(true)
                            if(!ArrayCultureApi[0]["communication"] || !ArrayCultureApi[0]["trust"] || !ArrayCultureApi[0]["scheduling"] || !ArrayCultureApi[0]["deciding"] || !ArrayCultureApi[0]["leading"] || !ArrayCultureApi[0]["evaluating"] || !ArrayCultureApi[0]["disagreeing"] && JSON.parse(localStorage.getItem("cultureSelectedPeople")) == null){
                                setAdvance(false)
                            }
                            else if(JSON.parse(localStorage.getItem("cultureSelectedPeople")) == null){
                                UpdateCulture(setButtonClicked)
                            }
                            else if(
                            userOwnCulture?.["communication"] &&
                            userOwnCulture?.["trust"] &&
                            userOwnCulture?.["scheduling"] &&
                            userOwnCulture?.["deciding"] &&
                            userOwnCulture?.["leading"] &&
                            userOwnCulture?.["evaluating"] &&
                            userOwnCulture?.["disagreeing"]){
                                UpdateCulture(setButtonClicked)
                            }
                            else {
                                setAdvance(false) 
                            }
                        }}
                    >
                        {advance && (
                            <LoadLogo MarginTop={"-12px"}>
                                <img src={logo} alt="loading..."/>
                            </LoadLogo>
                        )}
                       {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    if (props.page === "PageCulturePeer") {

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 7 of 10 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 7 / 10} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={advance ? "none" : "all"} Height='56px' Width='160px'
                                BackgroundColor={advance === true ? "#F7C9D9" : "#e2336B"} Cursor={advance === true ? "default" : "pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            const ArrayCultureApi = localStorage.getItem("ArrayCultureApi") ? JSON.parse(localStorage.getItem("ArrayCultureApi")) : [];
                            const newArr = ArrayCultureApi.slice(1)
                            
                            let result = newArr.filter(user => user.completed)
                            let compare = (result.length == newArr.length)
                            let checkAllTrue = false
                            if (localStorage.getItem("CheckedEmployeesCulturePeer") != null){
                                checkAllTrue = (JSON.parse(localStorage.getItem("cultureSelectedPeople")).length == JSON.parse(localStorage.getItem("CheckedEmployeesCulturePeer")).length)
                            }
                            if(checkAllTrue && compare){
                                UpdateCulturePeer(setButtonClicked)
                                setAdvance(true)
                            }
                            else{
                                setAdvance(false)
                            }
                        }}
                    >
                        {advance && (
                            <LoadLogo MarginTop={"-12px"}>
                                <img src={logo} alt="loading..."/>
                            </LoadLogo>
                        )}
                       {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    if (props.page === "PrePageProcessGoodFeedback") {
        /** Built in check whether anything is filled in */
        const feedback_checked = props.input_check;

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft} Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 8 of 10 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 8 / 10} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={"all"} Height='56px' Width='160px'
                                   BackgroundColor={"#e2336B"} Cursor={"pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            if(feedback_checked){
                                setAdvance(true)
                                sendPageTime("page-good-feedback-process-selector", "/survey-feedback-optimal")
                            }
                        }}
                    >
                        {advance && (
                        <LoadLogo MarginTop={"-13px"}>
                            <img src={logo} alt="loading..."/>
                        </LoadLogo>
                        )}
                        {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    if (props.page === "PageProcessGoodFeedback") {
        /** Built in check whether anything is filled in */
        const feedback_checked = props.input_check;

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft} Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 8 of 10 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 8 / 10} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={"all"} Height='56px' Width='160px'
                                   BackgroundColor={"#e2336B"} Cursor={"pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            if(feedback_checked){
                                setAdvance(true)
                                UpdateFeedbackOptimal(setButtonClicked)
                            }
                        }}
                    >
                        {advance && (
                        <LoadLogo MarginTop={"-13px"}>
                            <img src={logo} alt="loading..."/>
                        </LoadLogo>
                        )}
                        {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    if (props.page === "PrePageProcessImprovementFeedback") {
        /** Built in check whether anything is filled in */
        const feedback_checked = props.input_check;

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft} Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 9 of 10 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 9 / 10} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={"all"} Height='56px' Width='160px'
                                   BackgroundColor={"#e2336B"} Cursor={"pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            if(feedback_checked){
                                setAdvance(true)
                                sendPageTime("page-bad-feedback-process-selector", "/survey-rootcause-chatbot")
                            }
                        }}
                    >
                        {advance && (
                        <LoadLogo MarginTop={"-13px"}>
                            <img src={logo} alt="loading..."/>
                        </LoadLogo>
                        )}
                        {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    if (props.page === "PageProcessBadFeedback") {
        /** Built in check whether anything is filled in */
        const feedback_checked = props.input_check;

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 10 of 10 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 10 / 10} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={"all"} Height='56px' Width='160px'
                                   BackgroundColor={"#e2336B"} Cursor={"pointer"}>
                    <button
                        onClick={() => {
                            props.setNextButtonClicked(true)
                            if(feedback_checked){
                                setAdvance(true)
                                UpdateFeedbackImprovement(setButtonClicked)
                            }
                        }}
                    >
                        {advance && (
                        <LoadLogo MarginTop={"-13px"}>
                            <img src={logo} alt="loading..."/>
                        </LoadLogo>
                        )}
                        {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    if (props.page === "PageRootcauseChatbot") {
        /** Built in check whether anything is filled in */
        const conversationState = props.conversationState;

        return (
            <Wrapper MarginTop={props.FooterMarginTop} MarginLeft={props.FooterMarginLeft}
                     Width={props.FooterWidth} Height={props.FooterHeight}>

                <StyledDivRegular MarginTop='28px' MarginLeft={(100 * 220 / 1920).toString() + "%"}>
                    <StyledDivRegular Width='100px'> 10 of 10 </StyledDivRegular>
                    <StyledDivRegular MarginTop='30px'>
                        <ProgressBar completed={100 * 10 / 10} customLabel=" " height='16px' width='280px' bgColor="#e2336b" baseBgColor="#E3E5E4"/>
                    </StyledDivRegular>
                </StyledDivRegular>

                <ButtonWrapperBack MarginTop={props.MarginTop} MarginLeft={props.BackButtonMarginLeft} Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                {!buttonClicked ? 
                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} PointerEvents={conversationState ? "all" : "none"} Height='56px' Width='160px'
                                   BackgroundColor={conversationState ? "#e2336B" : "#F7C9D9"} Cursor={conversationState === true ? "pointer" : "default"}>
                    <button
                        disabled={!conversationState}
                        onClick={() => {
                            setButtonClicked(true)
                            UpdateRootcauseChatbot(setButtonClicked)
                        }}
                    >
                        {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
                :
                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.NextButtonMarginLeft} Height='56px' Width='160px'
                                    BackgroundColor={conversationState ? "#e2336B" : "#F7C9D9"} Cursor={conversationState ? "pointer" : "default"}>
                        <LoadLogo>
                            <button disabled={true}>
                                {t('Component-NextButton')}
                            </button>
                            <img src={logo} alt="loading..."/>
                        </LoadLogo>
                        
                </ButtonWrapperNext>}                       
            </Wrapper>
        );
    }

    if (props.page === "Survey") {
        return (
            <Wrapper Height='104px'>
                <ButtonWrapperBack MarginTop='853px' MarginLeft='1464px' Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop='853px' MarginLeft='1540px' Height='56px' Width='160px'>
                    <button
                        onClick={() => {
                            history.push(props.nextdirectory);
                        }}
                    >
                        {t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    }

    if (props.page === "SurveyCompleted") {
        return (
            <>
                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft} PointerEvents={"all"} Width={props.Width} Height={props.Height} BorderRadius={props.BorderRadius}>

                    <button
                        onClick={() => {
                            axiosInstance
                                .post(
                                    process.env.REACT_APP_APIURL +
                                    "/api/pagetimestamp/",
                                    {"page": "survey-complete"},
                                    {
                                        headers: {
                                            "Content-Type": "application/json",
                                            "X-CSRFToken": getCookieValue("csrftoken"),
                                        },
                                    }
                                )
                                .then (() => {
                                    handleLogout()
                                })
                        }}
                    >
                        {/*Logout*/}
                        {t('PageSurveyCompleted.ButtonText')}
                    </button>
                </ButtonWrapperNext>
            </>
        );
    }

    if (props.page === "Logout") {
        return (
            <>
                <ButtonWrapperNext MarginTop={props.MarginTop} MarginLeft={props.MarginLeft} PointerEvents={"all"}
                                   Width={props.Width} Height={props.Height} BorderRadius={props.BorderRadius}>

                    <button
                        onClick={() => {
                            handleLogout()
                        }} >
                        {t('Logout.LogoutButton')}
                    </button>
                </ButtonWrapperNext>
            </>
        );
    }

    if (props.page === "Dashboard") {
        return (
            <Wrapper>
                <ButtonWrapperBack MarginTop="44%" MarginLeft="77.3958%">
                    <button>
                        <Footer_back_button onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop="44%" MarginLeft="80.9375%">
                    <button
                        onClick={() => {
                            history.push(props.nextdirectory);
                        }}
                    >{t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );
    } else {
        return (
            <Wrapper Height='104px'>
                <ButtonWrapperBack MarginTop='853px' MarginLeft='1464px' Height='56px' Width='56px'>
                    <button>
                        <SurveyFooterBackButton onClick={() => {
                            history.push(props.backDirectory)
                        }}/>
                    </button>
                </ButtonWrapperBack>

                <ButtonWrapperNext MarginTop='853px' MarginLeft='1540px' Height='56px' Width='160px'>
                    <button
                        onClick={() => {
                            history.push(props.nextdirectory);
                        }}
                    >{t('Component-NextButton')}
                    </button>
                </ButtonWrapperNext>
            </Wrapper>
        );

    }
    ;
};
export default ComponentFooter

const Wrapper = styled.div`
  position: fixed;
//   margin-top: ${props => props.MarginTop || ""}; 
  margin-left: ${props => props.MarginLeft || ""};
  height: ${props => props.Height || "104px"};
  width: ${props => props.Width || "100%"};
  background-color: ${props => props.BackgroundColor || "#FCFCFC"};
  border-top: 1px solid #E3E5E4;
  z-index: 1;
  display: ${props => props.Display || ""};
  bottom: 0; //fixes the footer to the bottom of the page

  img {
    -drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;

const LoadLogo = styled.div`
  display: flex;
  justify-content: flex-start;
  position: absolute;

  img {
    width: 50px;
    margin-left: ${props => props.MarginLeft || "55px"};
    margin-top: ${props => props.MarginTop || "3px"};
  }

  button {
    opacity: 0.2;
    position: absolute;
    cursor: unset;
  }
`;

const IntakeContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  width: calc(100% - 80px);
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  border-top: 1px solid #E3E5E4;
  background: #FCFCFC;

  img {
    -drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
`;

const IntakeWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1290px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
`;

const IntroductionContainer = styled.div`
  position: absolute;
  bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  max-width: 1030px;
  padding: 0 220px 0 170px;

  img {
    -drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  @media (max-width: 2420px) {
    margin-left: max(400px, 26%);
  }

  @media (max-width: 1920px) {
    right: 0;
  }

  @media (max-width: 1420px) {
    left: 50%;
    transform: translateX(-50%);
    padding: 0 60px;
    margin: auto;
  }

  @media (max-width: 1150px) {
    right: 0;
    left: auto;
    transform: translateX(0);
  }
`;

const ButtonWrapperBack = styled.div`
  position: ${props => props.Position || "fixed"};
  margin-bottom: ${props => props.MarginBottom || ""};
  margin-top: ${props => props.MarginTop || "39.4%"};
  margin-left: ${props => props.MarginLeft || "76.5%"};

  @media only screen and (max-width: 1700px) {
    margin-left: ${props => props.MarginLeft || "75.5%"};
  }

  z-index: 4;
  width: ${props => props.Width || "39.4%"};
  height: ${props => props.Height || "39.4%"};

  button {
    border: none;
    padding: 0;
    background: none;
    cursor: ${props => props.Cursor || "pointer"};
  }
`;

const ButtonWrapperNext = styled.div`
  justify-content: center;
  margin-top: ${props => props.MarginTop || "22px"};
  margin-left: ${props => props.MarginLeft || "80.1%"};
  z-index: 4;

  button {
    width: ${props => props.Width || "136px"};
    height: ${props => props.Height || "48px"};
    border: ${props => props.Border || "none"};
    border-radius: ${props => props.BorderRadius || "8px"};
    background-color: ${props => props.BackgroundColor || "#e2336B"};
    color: ${props => props.Color || "#FCFCFC"};
    font-size: ${props => props.FontSize || "18px"};
    font-weight: ${props => props.FontWeight || "400"};
    font-family: ${props => props.FontFamily || "Overpass"};
    cursor: ${props => props.Cursor || "pointer"};
    transition: all 0.2s ease-in;
    align-items: flex-start;

    pointer-events: ${props => props.PointerEvents || "none"};

    :hover {
        background-color: #B3194A;
    }
  }
`;

const ButtonWrapperLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    position: absolute;
    width: 40px;
  }

  button {
    cursor: unset;
    background: #E3E5E4;
  }
`;

const ButtonWrapperLoading = styled.div`
  position: relative;
  display: flex;

  button {
    cursor: unset;
    background-color: #E3E5E4;
    color: #929699;
  }
`;

const ButtonWrapperLoadingOverlay = styled.div`
  position: absolute;
  width: ${props => props.Width || '0'};
  transition: width ${props => props.Transition || '0'} linear;
  overflow: hidden;

  button {
    cursor: unset;
    background-color: #C6CBCB;
    color: #FCFCFC;
  }
`;
