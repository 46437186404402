import React, {useContext, useState, useEffect} from 'react';
import styled from 'styled-components';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { OverlayContext } from '../../../layout-and-styling/context-hooks/OverlayContext';
import {
    ReactComponent as Uncheckmark
} from "../../../assets/SurveyCommunication/Icon_Survey_CheckMark_ClickCounter_Unchecked.svg";
import {
    ReactComponent as Checkmark
} from "../../../assets/SurveyCommunication/Icon_Survey_CheckMark_ClickCounter_Green.svg";
import i18next from "i18next";
import { ComponentTooltipKnowledge } from '../../../layout-and-styling/standardized-components-library/Component-Tooltips';
import {useTranslation} from "react-i18next";


const ComponentKnowledgeProcesses = (props) => {
    /** Component to select how much knowledge an employee has of the business processes */
    const context = useContext(OverlayContext);
    const [render, setRender] = useState(false);
    const [hover, setHover] = useState(false);
    const {t, i18n} = useTranslation();

    const reRender = () => {
        setRender(!render)
    };

    let knowledgeCounter = JSON.parse(localStorage.getItem("KnowledgeCounter")) ? JSON.parse(localStorage.getItem('KnowledgeCounter')) : new Array()
    

    /** input check for parent page, so people don't skip to the next page without giving any input for this component */
    if (knowledgeCounter.length === props.SelfRatedKnowledge.length)
        props.set_input_check_knowledge_processes(true)
    else
        props.set_input_check_knowledge_processes(false)

    const changeKnowledge = (process, value) => {
        props.SelfRatedKnowledge[process.id].self_rated_knowledge = value;
        
        if (!knowledgeCounter.includes(process.id)) {
            knowledgeCounter.push(process.id);
        }
        props.setSelfRatedKnowledge(props.SelfRatedKnowledge)
        localStorage.setItem("KnowledgeCounter", JSON.stringify(knowledgeCounter))
        localStorage.setItem("SelfRatedKnowledge", JSON.stringify(props.SelfRatedKnowledge))
        reRender();
    }

    useEffect(() => {
        localStorage.setItem("KnowledgeCounter", JSON.stringify(knowledgeCounter));
        localStorage.setItem("SelfRatedKnowledge", JSON.stringify(props.SelfRatedKnowledge))
      }, [knowledgeCounter, props.SelfRatedKnowledge])

    /** TODO create a more elegant await authentication, now a simple if statement to prevent .map(null) errors */
    if (props.SelfRatedKnowledge !== null) {
        return (
            <Container outline={!props.inputCheckIndividualKnowledgeProcesses && props.nextButtonClicked ? "2px solid #F72B2B" : ""}>
                <IncompleteContentText display={!props.inputCheckIndividualKnowledgeProcesses && props.nextButtonClicked ?  "flex" : "none"}>
                    {t('PageKnowledge.ProcessesKnowledgeError')}
                </IncompleteContentText>
                <InfoHover onMouseOver={() => setHover(true)}
                           onMouseOut={() => setHover(false)}>?
                    {hover && (<ComponentTooltipKnowledge whichTooltip={"BusinessProcessKnowledge"}/>
                    )}
                </InfoHover>

                <ProgressCheckWrapper>
                    {knowledgeCounter.length}/{props.SelfRatedKnowledge.length}

                    <CheckWrapper>
                        {knowledgeCounter.length > props.SelfRatedKnowledge.length - 1 ? <Checkmark/> : <Uncheckmark/>}
                    </CheckWrapper>

                </ProgressCheckWrapper>
                <KnowledgeHeaderText>
                    {t('PageKnowledge.KnowledgeHeaderText')}
                </KnowledgeHeaderText>
                <LikertWrapper>
                    <TextWrapper>
                        <p>{t('PageKnowledge.NoKnowledge')}</p>
                        <p>{t('PageKnowledge.StartedLearning')}</p>
                        <p>{t('PageKnowledge.CanAlmostWorkIndependently')}</p>
                        <p>{t('PageKnowledge.CanWorkIndependently')}</p>
                        <p>{t('PageKnowledge.CanTeachOthers')}</p>
                    </TextWrapper>

                    <ProcessWrapper>
                        {props.SelfRatedKnowledge.map((process) => (
                            <RadioWrapper>
                                <FormControl>
                                    <RadioGroup row
                                                value={process.self_rated_knowledge}
                                                onChange={(_, value) => changeKnowledge(process, value)}
                                    >

                                        <p>{context.setBusinessProcess(process.process_name, i18next.language)}</p>
                                        <RadioButtonsWrapper>
                                            <FormControlLabel sx={{
                                                '&.MuiFormControlLabel-root': {
                                                    mr: "60px",
                                                    "@media only screen and (max-width: 1800px) and (-webkit-device-pixel-ratio: 1)": {
                                                        mr: "46px"
                                                    },
                                                    "@media only screen and (max-width: 1690px) and (-webkit-device-pixel-ratio: 1)": {
                                                        mr: "54px"
                                                    },
                                                    "@media only screen and (max-width: 1590px) and (-webkit-device-pixel-ratio: 1)": {
                                                        mr: "47px"
                                                    }
                                                },
                                            }} id="noknowledge" value="0" control={<Radio sx={{
                                                '&.Mui-checked': {
                                                    color: "#26547C",
                                                },
                                            }}/>}/>
                                            <FormControlLabel sx={{
                                                '&.MuiFormControlLabel-root': {
                                                    mr: "60px",
                                                    "@media only screen and (max-width: 1800px) and (-webkit-device-pixel-ratio: 1)": {
                                                        mr: "46px"
                                                    },
                                                    "@media only screen and (max-width: 1690px) and (-webkit-device-pixel-ratio: 1)": {
                                                        mr: "54px"
                                                    },
                                                    "@media only screen and (max-width: 1590px) and (-webkit-device-pixel-ratio: 1)": {
                                                        mr: "47px"
                                                    }
                                                },
                                            }} id="startedlearning" value="1" control={<Radio sx={{
                                                '&.Mui-checked': {
                                                    color: "#26547C",
                                                },
                                            }}/>}/>
                                            <FormControlLabel sx={{
                                                '&.MuiFormControlLabel-root': {
                                                    mr: "60px",
                                                    "@media only screen and (max-width: 1800px) and (-webkit-device-pixel-ratio: 1)": {
                                                        mr: "46px"
                                                    },
                                                    "@media only screen and (max-width: 1690px) and (-webkit-device-pixel-ratio: 1)": {
                                                        mr: "54px"
                                                    },
                                                    "@media only screen and (max-width: 1590px) and (-webkit-device-pixel-ratio: 1)": {
                                                        mr: "47px"
                                                    }
                                                },
                                            }} id="canalmost" value="2" control={<Radio sx={{
                                                '&.Mui-checked': {
                                                    color: "#26547C",
                                                },
                                            }}/>}/>
                                            <FormControlLabel sx={{
                                                '&.MuiFormControlLabel-root': {
                                                    mr: "60px",
                                                    "@media only screen and (max-width: 1800px) and (-webkit-device-pixel-ratio: 1)": {
                                                        mr: "46px"
                                                    },
                                                    "@media only screen and (max-width: 1690px) and (-webkit-device-pixel-ratio: 1)": {
                                                        mr: "54px"
                                                    },
                                                    "@media only screen and (max-width: 1590px) and (-webkit-device-pixel-ratio: 1)": {
                                                        mr: "47px"
                                                    }
                                                },
                                            }} id="canwork" value="3" control={<Radio sx={{
                                                '&.Mui-checked': {
                                                    color: "#26547C",
                                                },
                                            }}/>}/>
                                            <FormControlLabel sx={{
                                                '&.MuiFormControlLabel-root': {
                                                    mr: "60px",
                                                    "@media only screen and (max-width: 1800px) and (-webkit-device-pixel-ratio: 1)": {
                                                        mr: "46px"
                                                    },
                                                    "@media only screen and (max-width: 1690px) and (-webkit-device-pixel-ratio: 1)": {
                                                        mr: "54px"
                                                    },
                                                    "@media only screen and (max-width: 1590px) and (-webkit-device-pixel-ratio: 1)": {
                                                        mr: "47px"
                                                    }
                                                },
                                            }} id="canteach" value="4" control={<Radio sx={{
                                                '&.Mui-checked': {
                                                    color: "#26547C",
                                                },
                                            }}/>}/>
                                        </RadioButtonsWrapper>
                                    </RadioGroup>
                                </FormControl>
                            </RadioWrapper>
                        ))}
                    </ProcessWrapper>
                </LikertWrapper>

            </Container>
        )
    }

}
export default ComponentKnowledgeProcesses


/** Styling */
const Container = styled.div`
  background: #FCFCFC;
  top: ${props => props.Top || "184px"};
  left: ${props => props.Left || "70px"};
  width: 716px;
  @media only screen and (max-width: 1800px) and (-webkit-device-pixel-ratio: 1) {
    width: 615px;
  }
  @media only screen and (max-width: 1690px) and (-webkit-device-pixel-ratio: 1) {
    width: 510px;
  }
  @media only screen and (max-width: 1590px) and (-webkit-device-pixel-ratio: 1) {
    width: 470px;
  }
  height: 572px;
  border-radius: ${props => props.BorderRadius || '5px'};

  p {
    font-family: 'Overpass';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #929699;
  }
  outline: ${props => props.outline || ''};
  outline-offset: -2px;
`;

const IncompleteContentText = styled.div`
  position: absolute;
  display: ${(props) => props.display || ""};
  margin-left: 216px;
  margin-top: 4px;
  border-radius: ${(props) => props.BorderRadius || "5px"};
  color: #F72B2B;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;

const KnowledgeHeaderText = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  height: 20px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
`;

const LikertWrapper = styled.div`
  display: flex;
  margin-left: 20px;
  margin-top: 100px;
  height: fit-content;
  flex-direction: column;
`;

const ProgressCheckWrapper = styled.div`
  float: right;
  display: flex;
  color: #06D6A0;
  margin-top: 30px;
  margin-right: 20px;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Overpass';
`;

const CheckWrapper = styled.div`
  display: flex;
  margin-left: 5px;
  margin-top: -3px;
`;

const TextWrapper = styled.div`
  display: flex;
  flow-direction: column;
  margin-left: 195px;
  height: fit-content;
  text-align: center;
  width: 475px;
  @media only screen and (max-width: 1800px) and (-webkit-device-pixel-ratio: 1) {
    margin-left: 176px
  }
  @media only screen and (max-width: 1690px) and (-webkit-device-pixel-ratio: 1) {
    margin-left: 97px;
  }
  @media only screen and (max-width: 1590px) and (-webkit-device-pixel-ratio: 1) {
    margin-left: 90px;
  }
  // Likert scale options
  p {
    margin-right: 2px;
    font-size: 14px;
    width: 100px;
    @media only screen and (max-width: 1800px) and (-webkit-device-pixel-ratio: 1) {
      margin-right: 3px;
      width: 74.5px;
    }
    @media only screen and (max-width: 1690px) and (-webkit-device-pixel-ratio: 1) {
      width: 88px;
      margin-right: -5px;
      font-size: 12px;
    }
    @media only screen and (max-width: 1590px) and (-webkit-device-pixel-ratio: 1) {
      margin-right: -11px;
      font-size: 11px;
    }
  }
`;

const ProcessWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  // Names business processes
  p {
    white-space: nowrap;
    font-size: 14px;
    @media only screen and (max-width: 1690px) and (-webkit-device-pixel-ratio: 1) {
      font-size: 14px;
      white-space: break-spaces;

    }
    @media only screen and (max-width: 1590px) and (-webkit-device-pixel-ratio: 1) {
      font-size: 14px;
      white-space: break-spaces;

    }
    width: 100px;
  }
`;

const RadioButtonsWrapper = styled.div`
  margin-left: 140px;
  @media only screen and (max-width: 1800px) and (-webkit-device-pixel-ratio: 1) {
    margin-left: 104px;
  }
  @media only screen and (max-width: 1690px) and (-webkit-device-pixel-ratio: 1) {
    margin-left: 25px;
  }
  @media only screen and (max-width: 1590px) and (-webkit-device-pixel-ratio: 1) {
    margin-left: 21px;
  }
`;

const InfoHover = styled.div`
  float: right;
  font-size: 18px;
  font-family: 'Overpass', sans-serif;
  font-weight: 300;
  position: relative;
  color: #FCFCFC;
  background-color: #E2336B;
  margin-right: 16px;
  margin-top: 16px;
  border: 2px solid #E2336B;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
`;
