import React, {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {useTranslation} from 'react-i18next';

export default function ComponentSendmessage(props){

    const {t} = useTranslation();

    const [msg, setMsg] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if(msg !== ''){
            props.addMessage(msg, false, true);
        }
        let textBox = document.getElementById('chatbotmessagebox');
        textBox.value = "";
        setMsg("");
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          handleSubmit(e);
        }
      };

    return(
        <Container>
        <form onSubmit={handleSubmit} style={{width: '100%', display: 'flex'}}>
            <textarea maxLength={200} id="chatbotmessagebox" placeholder={t("PageRootcauseChatbot.MessageBoxPlaceholder")} onChange={(e) => setMsg(e.target.value)} onKeyDown={handleKeyPress}
            />
            <input id="sendbutton" type="submit" value={t("PageRootcauseChatbot.SendButtonText")}
            style={{width: '80px', height: '80px', border: 'none', backgroundColor: '#F1F3F4', color: '#E2336B', fontFamily: 'overpass',
            fontSize: '18px', lineHeight: '28px', cursor: 'pointer', borderRadius: '4px', marginTop: '16px', opacity: props.botThinking || (props.currentStage === "FINISHED") ? '50%' : '100%'}}
            />
        </form>
        </Container>
    )
}

const Container = styled.div`
display: flex;
flex-direction: row;
width: calc(100% - 80px);
margin: 0px 40px 0px 40px;
height: 80px;

textarea {
 height: 70px; border: none; background-color: #F1F3F4; font-size: 16px; font-family: overpass;
    color: #000000; line-height: 20px; border-radius: 4px; margin-left: 0px; margin-top: 16px; margin-bottom: 16px;
    margin-right: 16px; resize: none; flex-grow: 3; padding-top: 8px; padding-left: 8px;
}
`;