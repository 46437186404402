import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import { StyledDivRegular } from '../../../layout-and-styling/standardized-components-library/Styling-TextInput';
import Delete from "../../../assets/delete.png";
import ComponentFrequencySlider from './Component-frequency-slider';
import {useTranslation} from "react-i18next";
import i18next from "i18next";

const ComponentDay = (props) => {

  function toHHMMSS(totalMinutes) {
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    if (!hours) {
        return `00:${padTo2Digits(Math.floor(minutes))}`;

    } else {
        return `${padTo2Digits(hours)}:${padTo2Digits(Math.floor(minutes))}`;
    }
  }

  function padTo2Digits(num) {
      return num.toString().padStart(2, '0');
  }


  function sliderChange(value) {
    if(!props.average){
      let employee = props.spontaneousTimes.filter((emp) => emp["employee"] === props.selectedColleague)[0]
      employee["days"][props.id] = value
      let average = 0
      for (let i = 0; i < employee["days"].length; i++) {
        average += employee["days"][i];
        
      }
      employee["average"] = average/employee["days"].length
      props.spontaneousTimes.filter((emp) => emp["employee"] === props.selectedColleague)[0] = employee
      props.setSpontaneousTimes(props.spontaneousTimes)
      localStorage.setItem('spontaneousTimes', JSON.stringify(props.spontaneousTimes))
      props.reRender()
    }
  }

  
  function removeDay() {
    if(!props.average){
      props.MeetingsDays.splice(props.id, 1);
      for (let i = 0; i < props.meetingsTimes.length; i++) props.meetingsTimes[i]["days"].splice(props.id, 1);
      props.setMeetingsDays(props.MeetingsDays)
      props.setMeetingsTimes(props.meetingsTimes)
      localStorage.setItem("MeetingsDays", JSON.stringify(props.MeetingsDays))
      localStorage.setItem("meetingsTimes", JSON.stringify(props.meetingsTimes))

      props.SpontaneousDays.splice(props.id, 1);
      for (let i = 0; i < props.spontaneousTimes.length; i++) props.spontaneousTimes[i]["days"].splice(props.id, 1);
      props.setSpontaneousDays(props.SpontaneousDays)
      props.setSpontaneousTimes(props.spontaneousTimes)
      localStorage.setItem("SpontaneousDays", JSON.stringify(props.SpontaneousDays))
      localStorage.setItem("spontaneousTimes", JSON.stringify(props.spontaneousTimes))

      props.reRender()
    }
  } 

  return (
    <Container Padding={props.id > 4 ? "12px 0px 12px 24px" : "12px 24px 12px 24px"}>

      <SliderContainer>
        <StyledDivRegular Width="124px" Position="relative" MarginRight="24px">
          {props.id > 4 
              ? (i18next.language === "en-US" ? props.day.name : i18next.language === "nl-NL" ? props.day.name_nl : props.day.name_ch) + " " + (props.id - 4)
              : (i18next.language === "en-US" ? props.day.name : i18next.language === "nl-NL" ? props.day.name_nl : props.day.name_ch) 
          }
        </StyledDivRegular>

        <ComponentFrequencySlider meetingTime={props.meetingsTimes.filter((emp) => emp["employee"] === props.selectedColleague)[0]["days"][props.id] > 240 ? 239 : props.meetingsTimes.filter((emp) => emp["employee"] === props.selectedColleague)[0]["days"][props.id]} average={props.average} element={props.element}
                                  spontaneousTime={props.spontaneousTimes.filter((emp) => emp["employee"] === props.selectedColleague)[0]["days"][props.id]} sliderChange={sliderChange} />

        <StyledDivRegular Width="64px" Position="relative" MarginLeft="24px">
          { toHHMMSS(props.meetingsTimes.filter((emp) => emp["employee"] === props.selectedColleague)[0]["days"][props.id] + props.spontaneousTimes.filter((emp) => emp["employee"] === props.selectedColleague)[0]["days"][props.id]) }
        </StyledDivRegular>
        <StyledDivRegular Width="fit-content" Position="relative" MarginLeft="0px">
          {"h"}
        </StyledDivRegular>
      </SliderContainer>
          
      {props.id > 4 &&
        <ButtonWrapper Background={!props.average ? "#919191" : ""} Cursor={!props.average ? "pointer" : ""}>
          <button onClick={removeDay}>
            <img width='20px' src={Delete}/>
          </button>
        </ButtonWrapper>
      }

    </Container>

  );
};

export default ComponentDay;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  opacity: ${props => props.Opacity || "1"};
  margin-left: 32px;
  margin-right: 32px;
  height: 42px;
  min-height: 42px;
  padding: ${props => props.Padding || ""};
  background-color: #FCFCFC;
  position: relative;
  border: ${props => props.Border || ''};
  border-radius: ${props => props.BorderRadius || '10px'};
  font-family: "Overpass";
  /* box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25); */
`;

const SliderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  button {
    display: flex;
    vertical-align: middle;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 100%;
    padding: 24px 12px 24px 12px;
    margin-left: 12px;
    background: #C6CBCB;
    border-radius: 12px;
    border: solid 0.5px #271B36;
    font-size: 16px;
    font-family: "Overpass";
    cursor: ${props => props.Cursor || ''};
    gap: 8px;
    z-index: 5;
  }

  button:hover {
    background: ${props => props.Background || ''};
    color: #FCFCFC;
  }
`;