import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { DashboardContext } from './DashboardContext';
import ComponentDashboardSidebar from "./Component-dashboard-sidebar";

const PageDashboard = () => {
    const dashboard_context_value = useContext(DashboardContext);
    const [sidebarOpen, setSideBarOpen] = useState(false);

    /** Check if already authenticated, and bring to dashboard if possible.*/
    React.useEffect(() => {
        const axiosInstance = axios.create({withCredentials: true})
        // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
            .then(
                console.log(process.env.REACT_APP_APIURL)
            )
            .catch((error) => {
                window.location.replace(process.env.REACT_APP_APPURL + '/login');
            })
    }, []);
    

    return (
        <Container>

            <SidebarWrapper>
                <button onMouseOver={() => setSideBarOpen(true)} onMouseLeave={() => setSideBarOpen(false)}>
                  <ComponentDashboardSidebar sidebarOpen={sidebarOpen} setSideBarOpen={setSideBarOpen}/>
                </button>
            </SidebarWrapper>

            <TabContainer MarginTop="0">

                {dashboard_context_value.initialTab}

            </TabContainer>

        </Container>
    );
};
export default PageDashboard

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: start;
  z-index: 1;
  flex-flow: nowrap; 

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: calc(1 / 1.75);
  }
`;

const SidebarWrapper = styled.div`
  position: fixed;
  margin-top: ${props => props.MarginTop || "0px"};
  margin-left: ${props => props.MarginLeft || "0px"};
  z-index: 2;
  color: #5F6366;
`;

const TabContainer = styled.div`
  position: fixed;
  margin-top: ${props => props.MarginTop || "64px"};
  margin-left: ${props => props.MarginLeft || "74px"};
  width: ${props => props.Width || "100%"};
  min-height: 100%;
  height: ${props => props.Height || "877px"};
  background: #F1F3F4;
  z-index: 1;
`;
