import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { StyledDivRegular } from '../../layout-and-styling/standardized-components-library/Styling-TextInput';
import { useTranslation} from 'react-i18next';

const CurrentUserData = JSON.parse(localStorage.getItem('current_user_data'));

const ComponentTagOverview = (props) => {

    const selectedCompany = JSON.parse(localStorage.getItem("selected_company"));
    const {t, i18n} = useTranslation();

    //Venice hardcoded, replace when back-end sending dynamically getting data
    if(CurrentUserData[0].company === 4 || selectedCompany.id === 4){
        return(
            <>
                <TagBoxWrapper BorderTop="3px solid #048B67">

                    <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width="410px" Height="108px">

                        <StyledDivRegular MarginTop="24px" MarginLeft="20px" Position='fixed' Width='fit-content' Height='fit-content' FontSize='14px'>
                            {t('Elephant.TagBoxWrapperLeft')}
                        </StyledDivRegular>

                        <StyledDivRegular MarginTop="60px" MarginLeft="20px" Width='350px' Height='24px' Display='flex' JustifyContent='flex-start' FlexWrap='wrap' Gap='10px 10px' TextAlign='left'>

                            <StyledDivRegular MarginTop="0px" MarginLeft = "0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Competence level
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#048B67' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                23%
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color ='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Product Quality
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#048B67' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                9%
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Customer relations & aftercare
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#048B67' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                7%
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Supply Chain Management
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#048B67' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                6%
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Distribution & Logistics
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#048B67' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                6%
                            </StyledDivRegular>


                        </StyledDivRegular>

                    </StyledDivRegular>

                </TagBoxWrapper>

                <TagBoxWrapper BorderTop='3px solid #B3194A' MarginLeft='440px'>

                    <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width="410px" Height="108px">

                        <StyledDivRegular MarginTop="24px" MarginLeft="20px" Position='fixed' Width='fit-content' Height='fit-content'
                            FontSize='14px' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                            {t('Elephant.TagBoxWrapperRight')}
                        </StyledDivRegular>

                        <StyledDivRegular MarginTop="60px" MarginLeft="20px" Width='380px' Height='24px' Display='flex' JustifyContent='flex-start' FlexWrap='wrap' Gap='10px 10px' TextAlign='left'>

                            <StyledDivRegular MarginTop="0px" MarginLeft = "0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Prioritization & Planning
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#B3194A' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                22%
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color ='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Product Quality
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#B3194A' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                17%
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Supply Chain Management
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#B3194A' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                10%
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color ='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Specification
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#B3194A' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                8%
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Marketing
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#B3194A' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                6%
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Sales
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#B3194A' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                6%
                            </StyledDivRegular>

                        </StyledDivRegular>

                    </StyledDivRegular>

                </TagBoxWrapper>

            </>
        )
    }

    //Avocado hardcoded, replace when back-end sending dynamically getting data
    if(CurrentUserData[0].company === 6 || selectedCompany.id === 6){

        return(
            <>
                <TagBoxWrapper BorderTop="3px solid #048B67">

                    <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width="410px" Height="108px">

                        <StyledDivRegular MarginTop="24px" MarginLeft="20px" Position='fixed' Width='fit-content' Height='fit-content' FontSize='14px'>
                            {t('Elephant.TagBoxWrapperLeft')}
                        </StyledDivRegular>

                        <StyledDivRegular MarginTop="60px" MarginLeft="20px" Width='370px' Height='24px' Display='flex' JustifyContent='flex-start' FlexWrap='wrap' Gap='10px 10px' TextAlign='left'>

                            <StyledDivRegular MarginTop="0px" MarginLeft = "0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Sales & Marketing
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#048B67' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                18%
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color ='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Supply Chain Management
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#048B67' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                18%
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Customer feedback & support
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#048B67' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                16%
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Specification & Design
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#048B67' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                14%
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Finance
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#048B67' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                11%
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Product Quality
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#048B67' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                10%
                            </StyledDivRegular>

                        </StyledDivRegular>

                    </StyledDivRegular>

                </TagBoxWrapper>

                <TagBoxWrapper BorderTop='3px solid #B3194A' MarginLeft='440px'>

                    <StyledDivRegular MarginTop='0px' MarginLeft='0px' Width="410px" Height="108px">

                        <StyledDivRegular MarginTop="24px" MarginLeft="20px" Position='fixed' Width='fit-content' Height='fit-content'
                            FontSize='14px' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                {t('Elephant.TagBoxWrapperRight')}
                        </StyledDivRegular>

                        <StyledDivRegular MarginTop="60px" MarginLeft="20px" Width='380px' Height='24px' Display='flex' JustifyContent='flex-start' FlexWrap='wrap' Gap='10px 10px' TextAlign='left'>

                            <StyledDivRegular MarginTop="0px" MarginLeft = "0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Specification & Design
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#B3194A' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                25%
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color ='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Sales & Marketing
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#B3194A' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                14%
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Supply Chain Management
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#B3194A' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                14%
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color ='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Customer feedback & support
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#B3194A' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                13%
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Product Quality
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#B3194A' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                9%
                            </StyledDivRegular>

                            <StyledDivRegular MarginTop="0px" MarginLeft="0px" Position='relative' Background='#D0E1F1' Width='fit-content' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#132A3E' BorderRadius='10px' Display='flex' Padding='3px 10px'>
                                Documentation
                            </StyledDivRegular>
                            <StyledDivRegular MarginTop="0px" MarginLeft="-30px" Position='relative' Background='#B3194A' Width='40px' Height='fit-content'
                            FontSize='12px' FontWeight='700' Color='#FCFCFC' BorderRadius='10px' Display='flex' JustifyContent='end' Padding='3px 10px' ZIndex='-1'>
                                7%
                            </StyledDivRegular>

                        </StyledDivRegular>

                    </StyledDivRegular>

                </TagBoxWrapper>

            </>
        )
    }

}
export default ComponentTagOverview

const TagBoxWrapper = styled.div`
    position: fixed;
    margin-top: ${props => props.MarginTop || "66px"};
    margin-left: ${props => props.MarginLeft || "0px"};
    width: ${props => props.Width || "417px"};
    height: ${props => props.Height || "204px"};
    background-color: #FCFCFC;
    border-top: ${props => props.BorderTop || "3px solid #048B67"};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    z-index:5;
    button{
        position:fixed;
        margin-top: 208px;
        margin-left: 20px;
        width: ${props => props.Width || "210px"};
        height: ${props => props.Height || "48px"};
        border: ${props => props.Border || "none"};
        border-radius: ${props => props.BorderRadius || "5px"};
        background-color: ${props => props.BackgroundColor || "#FCFCFC"};
        color: ${props => props.Color || "#e2336B"};
        font-size: ${props => props.FontSize || "16px"};
        font-weight: ${props => props.FontWeight || "400"};
        font-family: ${props => props.FontFamily || "Overpass"};
        cursor: ${props => props.Cursor || "pointer"};
        transition: all 0.2s ease-in;
        align-items: left;
    }
`;

