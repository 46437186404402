import { useTranslation } from 'react-i18next';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';
import load from "../../../assets/dashboard/load.gif";
import { ReactComponent as ElephantZoneIcon } from '../../../assets/elephant_zone_icon.svg';
import { ReactComponent as ElephantSolvedZoneIcon } from '../../../assets/solved_zone_icon.svg'
import ElephantContainer from './elements/ElephantContainer';
import { ComponentPageTransformation } from '../../../layout-and-styling/standardized-components-library/Component-Tooltips';
import { useState } from "react";



const ElephantsStatistics = ({ solvedProblems, unsolvedProblems, data, colors }) => {

    const { t, i18n } = useTranslation();
    const [hover1, setHover1] = useState(false);


    const CustomizedDot = (props) => {
        const { cx, cy, stroke, dotkey } = props;

        const circleRadius = 10;
        const textX = cx;
        const textY = cy + 5;
        return (
            <>
                <circle cx={cx} cy={cy} r={circleRadius} fill={stroke} />

                <text x={textX} y={textY} textAnchor="middle" fill="#FCFCFC">
                    {dotkey}
                </text>
            </>
        );
    };

    return (

        <StatisticsWrapper>
            {solvedProblems.length && unsolvedProblems.length && data ?
                <>
                    <StatisticsData>
                        <StatisticsTitle>
                            {t('Transformation.ElephantStatistics')}
                        </StatisticsTitle>
                        <StatisticsSubtitle>
                            {t('Transformation.ElephantStatisticsSubtitle')}
                        </StatisticsSubtitle>
                    </StatisticsData>

                    <InfoHover onMouseOver={() => setHover1(true)}
                        onMouseOut={() => setHover1(false)}>?
                        {hover1 && (<ComponentPageTransformation whichTooltip={"ElephantsStatistics"} />
                        )}
                    </InfoHover>

                    <ElephantZoneContainer>

                        <ElephantZoneTitle>
                            <ElephantZoneIconWrapper >
                                <ElephantZoneIcon />
                            </ElephantZoneIconWrapper>
                            {t('Transformation.ElephantZone')}
                        </ElephantZoneTitle>

                    </ElephantZoneContainer>

                    <SolvedZoneContainer>
                        <SolvedZoneTitle>
                            <ElephantZoneIconWrapper >
                                <ElephantSolvedZoneIcon />
                            </ElephantZoneIconWrapper>
                            {t('Transformation.LessVotedZone')}
                        </SolvedZoneTitle>
                    </SolvedZoneContainer>

                    <ResponsiveContainer width="78%" height="76%">
                        <LineChart
                            width={500}
                            height={300}
                            data={data}
                            margin={{
                                top: 5,
                                right: 40,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="5 5" />
                            <XAxis dy={10} dataKey="category" type="category" allowDuplicatedCategory={false} />
                            <YAxis dx={-10} dataKey="value" type='number' domain={[0, 16]} tickFormatter={(value) => `${value}%`} />
                            <Tooltip />
                            {data.map(d => {
                                return <Line key={d.key} dataKey="value" data={d.data} name={d.key} stroke={colors[d.key]} strokeWidth={3} dot={<CustomizedDot dotkey={d.key} />} activeDot={false}></Line>
                            })}
                        </LineChart>
                    </ResponsiveContainer>

                    <LineChartLegend>
                        <LegendLabel>{t('Transformation.Unsolved')}</LegendLabel>
                        {unsolvedProblems.map(problem => <ElephantContainer key={problem.key} problem={problem} />)}
                        <LegendLabel>{t('Transformation.LessVoted')}</LegendLabel>
                        {solvedProblems.map(problem => <ElephantContainer key={problem.key} problem={problem} />)}
                    </LineChartLegend>
                </>
                :                     <LoadLogo>
                {" "}
                <img src={load} alt="loading..." />
            </LoadLogo>}
        </StatisticsWrapper>
    )
}

export default ElephantsStatistics

const StatisticsWrapper = styled.div`
    position: relative;
    display: flex;
    margin-left: 0px;
    background: #FCFCFC;
    height: 447px;
    min-width: fit-content;
    width: 1385px;
    flex-direction: column;
    border-radius: 5px;
`;

const StatisticsData = styled.div`
    margin-left: 30px;
    margin-top: 23px;
`;

const StatisticsTitle = styled.div`
    color: #271B36;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 8px;
`;

const StatisticsSubtitle = styled.div`
    color: #929699;
    font-family: 'Overpass';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 16px;
`;

const ElephantZoneContainer = styled.div`
    background-color: #FAEAEF;
    position: absolute;
    margin-top: 92px;
    margin-left: 79px;
    // Based on the width of the responsive container - the difference of width between the chart and the container
    width: calc(78% - 120px);
    height: 199px;
`;

const ElephantZoneTitle = styled.div`
    color: #E2336B;
    opacity: 62%;
    margin-top: 10px;
    margin-left: 15px;
    font-family: 'Overpass';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    display: flex;
`;

const ElephantZoneIconWrapper = styled.div`
    margin-right: 8px;
`;

const SolvedZoneContainer = styled.div`
    background-color: #EBF0EA;
    position: absolute;
    bottom: 0px;
    margin-bottom: 56px;
    margin-left: 79px;
    // Based on the width of the responsive container - the difference of width between the chart and the container
    width: calc(78% - 120px);
    height: 100px;
`;

const SolvedZoneTitle = styled.div`
    color: #A9D7CB;
    margin-top: 10px;
    margin-left: 15px;
    font-family: 'Overpass';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    display: flex;
`;

const LineChartLegend = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    height: 260px;
    width: 225px;
    margin-block: auto;
    right: 50px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;

    ::-webkit-scrollbar {
        width: 5px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #929699;
    }
`;

const LegendLabel = styled.div`
    color: #929699;
    font-family: Overpass;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    margin-top: 5px;
`;

const InfoHover = styled.div`
    font-size: 18px;
    position: absolute;
    font-family: "Overpass";
    font-weight: 300;
    color: #FCFCFC;
    background-color: #e2336b;
    border: 2px solid #e2336b;
    top: 16px;
    border-radius: 500px;
    width: 20px;
    text-align: center;
    height: 20px;
    cursor: pointer;
    z-index: 20;
    right: 16px;
    float: right;
`;

const LoadLogo = styled.div`
  img {
    width: 50px;
  }
  display: flex;
  position: absolute;
  top: 48%;
  left: 50%;
`;