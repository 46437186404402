import React, { createContext, useState, useEffect } from 'react'


import Elephant from './dashboard-pages/Elephant';
import DashboardSubTabs from './specific-components/Dashboard-subtabs';
import Actions from './dashboard-pages/Actions';
import DashboardMain from './archive/Dashboard-landing-overview-old';
import Comparison from './dashboard-pages/Comparison';
import PageTransformations from './trends/Page-Transformations';

export const DashboardContext = createContext();

export const DashboardContextProvider = (props) => {
  /** States to control Dashboard.js sidebar with different tabs */
  const [tabSelection, setTabSelection] = useState("TabElephantIdentification");
  const [subTabSelection, setSubTabSelection] = useState("SubTabMetrics");

  const [subProcessSelected, setSubProcessSelected] = useState("initial");
  const [initialTab, setInitialTab] = useState(<Elephant/>);
  const [openAnalysis, setOpenAnalysis] = useState(false);
  const [openGraph, setOpenGraph] = useState(false);
  const [tagSelected, setTagSelected] = useState([]);

  const [displayModal, setDisplayModal] = useState(false);
  const [dataAnalysis, setDataAnalysis] = useState();
  const [dataOverview, setDataOverview] = useState();
  const [tagsOverview, setTagsOverview] = useState();

  useEffect(()=> {
    if(tabSelection === "TabElephantIdentification"){setInitialTab(<Elephant/>); setOpenGraph(false)}
    else if(tabSelection === "TabRootCauseDiagnostics"){setInitialTab(<DashboardSubTabs/>); setOpenGraph(false)}
    else if(tabSelection === "TabActions"){setInitialTab(<Actions/>); setOpenGraph(false)}
    else if(tabSelection === "TabTransformation"){setInitialTab(<PageTransformations/>); setOpenGraph(false)}
    else if(tabSelection === "TabHighlights"){setInitialTab(<Comparison/>); setOpenGraph(false)}
    else{ setInitialTab(<DashboardMain/>)}
    }, [tabSelection]
  );


  return (
    <DashboardContext.Provider value={
      { /** Dashboard Menu */
        tabSelection: tabSelection,
        setTabSelection: setTabSelection,
        subTabSelection: subTabSelection,
        setSubTabSelection: setSubTabSelection,
        initialTab: initialTab,
        setInitialTab: setInitialTab,

        subProcessSelected: subProcessSelected,
        setSubProcessSelected: setSubProcessSelected,
        openAnalysis: openAnalysis,
        setOpenAnalysis: setOpenAnalysis,
        openGraph:openGraph,
        setOpenGraph:setOpenGraph,
        displayModal: displayModal,
        setDisplayModal: setDisplayModal,
        dataAnalysis: dataAnalysis,
        setDataAnalysis: setDataAnalysis,
        setDataOverview: setDataOverview,
        dataOverview: dataOverview,
        setTagSelected: setTagSelected,
        tagSelected: tagSelected,
        tagsOverview: tagsOverview,
        setTagsOverview: setTagsOverview
      }
    }>
    {props.children}
    </DashboardContext.Provider>
  )
};
