import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { Slider } from "@mui/material";
import { DEFAULT_SLIDER_VALUE } from "./Component-culture-questions-peer";

const ComponentCultureSlider = (props) => {

  const valueLabelFormat = (value) => {
    const marks = props.marks;
    const index = marks.findIndex((mark) => mark.value === value);
    return marks[index].labelDescription;
};

  return (
    <SliderContainer>
      <Question>{props.question}</Question>
      <StyledSlider
        id="cultureslider"
        key={props.scale}
        onChange={(event, value) => props.setValue(value)}
        aria-label={props.scale}
        value={props.value}
        valueLabelFormat={valueLabelFormat}
        valueLabelDisplay="auto"
        step={1}
        marks={props.marks}
        min={1}
        max={9}
        sx={{
          p: "7px 0",
          width: "calc(100% - 20px)",
          height: 10,
          "@media screen and (max-width: 1920px)": {
            "& .MuiSlider-markLabel": { fontSize: "12px" },
            MarginTop: 20,
            height: 9
          },
          color: "#C6CBCB",
          "& .MuiSlider-thumb": {
            color: props.value ? "#B3194A" : "#5F6366",
            width: "15px",
            height: "15px",
            "&:focus, &:hover, &.Mui-active": {
              color: "#D13C70",
              zIndex: 1500,
              boxShadow:
                "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
              "@media (hover: none)": { boxShadow: "none" },
            },
          },
          "& .MuiSlider-rail": {
            opacity: 1,
            width: 1,
            backgroundColor: "#F1F3F4",
          },
          "& .MuiSlider-root": {
            opacity: 1,
            width: 1,
            backgroundColor: "#F1F3F4",
            p: "5px",
          },
          "& .MuiSlider-track": {
            width: "100%",
            backgroundColor: "#B3194A",
          },
        }}
      />
      <WordsContainer>
        <LeftWord> {props.answer1} </LeftWord>{" "}
        <RightWord> {props.answer2} </RightWord>
      </WordsContainer>
    </SliderContainer>
  );
};

export default ComponentCultureSlider;

const SliderContainer = styled.div`
  background-color: #fcfcfc;
  width: 42%;
  padding: 5px 0 5px 0;
  margin-top: 20px;
`;

const StyledSlider = styled(Slider)`
  position: relative;
  font-family: "Overpass";
  font-size: 16px;
  color: #271b36;

  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: 1.25;
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: 1.5;
  }

  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: 1.75;
  }

  &:focus {
    outline: none !important;
    border: 1px solid #e2336b;
  }
`;

const Question = styled.div`
  font-size: 16.5px;
  font-family: "Overpass", sans-serif;
  color: #271b36;
`;

const LeftWord = styled.div`
  margin-left: -20px;
`;

const RightWord = styled.div``;

const WordsContainer = styled.div`
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  color: #929699;
`;
