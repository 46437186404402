import React, {useState} from "react";
import SocialNetworkRenderComponent from './SocialNetwork-render-component';
import MatrixRenderComponent from '../../archive/matrix/Matrix-render-component';
import './D3Components.css';

const DashboardSocialNetworkComponent = (props) => {

    const [component, setComponent] = useState("graph");

    if (component === "graph") {
        return (
            <SocialNetworkRenderComponent graphData={props.graphData} Zoom={props.Zoom} setZoom={props.setZoom} graphWidth={props.graphWidth} setGraphWidth={props.setGraphWidth} graphHeight={props.graphHeight} setGraphHeight={props.setGraphHeight}
            has_selection_areas={props.has_selection_areas} center_point_of_selection_areas={props.center_point_of_selection_areas} language={props.language} Left={props.Left} Comparison={props.Comparison} Iteration={props.Iteration}/>
        );
    } else if (component === "matrix") {
        return (
            <>
                <MatrixRenderComponent/>
                {/*<button class="showMatrixBtn" onClick={() => setComponent("graph")}>Show graph</button>*/}
            </>
        );
    }
}
export default DashboardSocialNetworkComponent

