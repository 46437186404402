import styled from 'styled-components';
import {useEffect, useState} from 'react';
import axios from 'axios';

import ComponentHeaderBlock from '../../layout-and-styling/standardized-components-library/Component-header-block';
import ComponentFooter from '../../layout-and-styling/standardized-components-library/Component-Footer';
import ComponentTeamPersonFilter from './page-specific-components/Component-team-person-filter';
import { useWindowSize } from '../../layout-and-styling/standardized-components-library/Component-Responsive';
import {Trans, useTranslation} from 'react-i18next';
import ComponentClientSuppliers from './page-specific-components/ComponentClientSuppliers';


const PageCloseColleagueFilter = () => {
    const {t} = useTranslation();

    /** Check if already authenticated, and bring to dashboard if possible.*/
    useEffect(() => {
        const axiosInstance = axios.create({withCredentials: true})
        // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
            .catch((error) => {
                console.log('error, you are not logged in yet!')
                window.location.replace(process.env.REACT_APP_APPURL + '/login');
            })
    }, []);

    /** Responsive window sizing and useLayoutEffect trigger */
    useWindowSize();

    const [render, setRender] = useState(false);
    const reRender = () => {
        setRender(!render)
    };
    
    const [InputCheckProcess, setInputCheckProcess] = useState(false);
    const [InputCheckPeople, setInputCheckPeople] = useState(false);
    const [nextButtonClicked, setNextButtonClicked] = useState(false);
    const [localStorageLanguage, setSelectedLanguage] = useState(JSON.parse(localStorage.getItem("Language")) ? JSON.parse(localStorage.getItem('Language')) : 0)
    const [customerAndSupplier, setCustomerAndSupplier] = useState({
        "customer": {
            hours: 0,
            interaction: false
        },
        "supplier": {
            hours: 0,
            interaction: false
        }
    });

    return (
      <Container>
          <HeaderAndOverlayWrapper>
              <ComponentHeaderBlock page='Survey' set_selected_language={setSelectedLanguage} reRender={reRender}
                                    overlay='PageCloseColleagueFilter' directory='/survey-close-colleagues'/>
          </HeaderAndOverlayWrapper>

          
          <WrapperTop>
              <Trans i18nKey="PageCloseColleagueFilter.Title">
                  Who <span
                  style={{color: "#B3194A"}}> Who </span>
                  do you usually interact with during a week?
              </Trans>
          </WrapperTop>
          
          <IncompleteContentText display={ !InputCheckPeople & nextButtonClicked ? "flex" : "none"}>
            {t('PageCloseColleagueFilter.ErrorColleagues')}
          </IncompleteContentText>

          <WrapperBottom>
              <ComponentTeamPersonFilter whichFilter="PageCloseColleagueFilter" inputMarginTop="16px"  inputMarginLeft="30px" inputCheckPeople={InputCheckPeople} 
                        nextButtonClicked={nextButtonClicked} set_inputCheckPeople={setInputCheckPeople} set_selected_language={setSelectedLanguage} reRender={reRender}/>

              <ComponentClientSuppliers setCustomerAndSupplier={setCustomerAndSupplier} customerAndSupplier={customerAndSupplier} reRender={reRender} inputCheckPeople={InputCheckPeople} 
                        nextButtonClicked={nextButtonClicked} set_inputCheckPeople={setInputCheckPeople} inputCheckProcess={InputCheckProcess} setInputCheckProcess={setInputCheckProcess}/>
          </WrapperBottom>

          <ComponentFooter page='PageCloseColleagueFilter' backDirectory='/user-instructions' FooterMarginTop={(useWindowSize()[1] - 104).toString() + "px"} FooterMarginLeft='0px'
                            FooterHeight="104px" FooterWidth='100%' MarginTop='25px' BackButtonMarginLeft='65%' NextButtonMarginLeft='calc(65% + 66px)' input_check_process={InputCheckProcess}
                            inputCheckPeople={InputCheckPeople} customerAndSupplier={customerAndSupplier} nextButtonClicked={nextButtonClicked} setNextButtonClicked={setNextButtonClicked}/>
      </Container>
    );
};
export default PageCloseColleagueFilter


const Container = styled.div`
  position: absolute;
  width: 100%;
  /* height: 100%; */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #F1F3F4;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderAndOverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
`;

const WrapperTop = styled.div`
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 100px;
  letter-spacing: 0;
  text-align: center;
  margin-top: 64px;
  height: fit-content;
`;

const IncompleteContentText = styled.div`
  position: absolute;
  display: ${(props) => props.display || ""};
  margin-top: "0px";
  color: #F72B2B;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
`;

const WrapperBottom = styled.div`
  position: relative;
  width: 85%;
  height: 70%;
  background: #F1F3F4;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
`;