import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const CustomTooltipBarChart = (data) => {

    const { t, i18n } = useTranslation();

    const { active, payload, label } = data;
    if (active && payload && payload.length) {
        const { values, benchmark } = payload[0].payload
        return (
            <TooltipBarChartContainer>
                <div style={{ fontWeight: 400 }}>{label}</div>
                <div style={{ color: "#D6BAEB" }}>{t('Transformation.Before')}: {values[0]}%</div>
                <div style={{ color: "#A773E4" }}>{t('Transformation.After')}: {values[1]}%</div>
                <div style={{ color: "#E2336B" }}>{t('Transformation.Benchmark')}: {benchmark}%</div>
            </TooltipBarChartContainer >
        );
    }
    return null;
};
export default CustomTooltipBarChart;

const TooltipBarChartContainer = styled.div`
    font-family: "Overpass";
    font-size: 16px;
    font-style: normal;
    line-height: 18px;
    position: absolute !important;
    background-color: white;
    z-index: 20 !important;
    padding: 15px;
    width: 135px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    left: -50px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
`;