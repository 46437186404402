import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
import styled from "styled-components";
import {Trans, useTranslation} from 'react-i18next';
import { useWindowSize } from '../../layout-and-styling/standardized-components-library/Component-Responsive';

import ComponentSignificantColleagues from '../survey-close-colleagues/page-specific-components/Component-significant-colleagues';
import ComponentHeaderBlock from '../../layout-and-styling/standardized-components-library/Component-header-block';
import ComponentFooter from '../../layout-and-styling/standardized-components-library/Component-Footer';
import { StyledDivRegular } from '../../layout-and-styling/standardized-components-library/Styling-TextInput';
import ComponentCultureQuestions from './page-specific-components/Component-culture-questions';
import i18next from "i18next";

const PageCulture = () => {
    

    const {t} = useTranslation();
    const [localStorageLanguage, setSelectedLanguage] = useState(JSON.parse(localStorage.getItem("Language")) ? JSON.parse(localStorage.getItem('Language')) : 0)

    /** Get Interaction and Employee datasets from API stored data from localStorage */
    const significantColleagues = JSON.parse(localStorage.getItem("SignificantColleagues"))
    const CurrentUserData = JSON.parse(localStorage.getItem('current_user_data'));
    /** From InteractionData.json, store the selected colleagues' names into an array to be used for each input window
     * create impact and pleasantness fields */
    /** initiate Array to be pushed via API Post request */

    useEffect(() => {
        const axiosInstance = axios.create({withCredentials: true})
        // Perform GET request to get a CSRFToken from the API and store it in sessionStorage
        axiosInstance.get(process.env.REACT_APP_APIURL + '/api/auth/validatesession/')
            .catch((error) => {
                window.location.replace(process.env.REACT_APP_APPURL + '/login');
            })
    }, []);

    const [InputChecker, setInputChecker] = useState(false)
    const [SignificantColleagues, setSignificantColleaguesPerTeam] = useState();
    const employeeRef = useRef([]);
    const [nextButtonClicked, setNextButtonClicked  ] = useState(false);
    const [selectedColleague, setSelectedColleague] = useState(significantColleagues[0].id)
    const incompleteText = "Fill in all your colleagues to continue"
    const [render, setRender] = useState(false);
    const reRender = () => {
        setRender(!render)
    };



    return(

         // TODO:   
         //Check mark on the people who already completed the sliders.
         //Tooltip
         //Fix shrinking colleague component
         //Save each score for each employee
         
        <Container>
           
           <HeaderAndOverlayWrapper>
                <ComponentHeaderBlock page='Survey' reRender={reRender}
                                      overlay='PageCulture' directory='/survey-culture'/>
            </HeaderAndOverlayWrapper>
        
        <WrapperTop>
                <Trans i18nKey="PageCulture.Title">
                     What <span style={{color: "#B3194A"}}> What </span>
                     type of work environment do you prefer? 
                </Trans>
            </WrapperTop>
            
        <WrapperBottom>
                                                                                        
          <ComponentCultureQuestions setInputChecker={setInputChecker} inputCheckPeople={InputChecker} nextButtonClicked={nextButtonClicked} setNextButtonClicked={setNextButtonClicked} ></ComponentCultureQuestions>
        
        </WrapperBottom>
        
       
        {/* Make it check in the footer if the page is culture and send data after that(Save data in localstorage) */}
        <ComponentFooter page="PageCulture" backDirectory="/survey-knowledge" FooterMarginTop={(useWindowSize()[1] - 104).toString() + "px"}
                          FooterMarginLeft="0px" FooterHeight="104px" FooterWidth="100%" MarginTop="25px" BackButtonMarginLeft="65%" NextButtonMarginLeft="calc(65% + 66px)" nextButtonClicked={nextButtonClicked} setNextButtonClicked={setNextButtonClicked}></ComponentFooter>
        </Container>
    )
}

export default PageCulture;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #F1F3F4;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  @media (-webkit-device-pixel-ratio: 1.25) {
    zoom: calc(1 / 1.25);
  }
  @media (-webkit-device-pixel-ratio: 1.5) {
    zoom: calc(1 / 1.5);
  }
  @media (-webkit-device-pixel-ratio: 1.75) {
    zoom: calc(1 / 1.75);
  }
`;

const HeaderAndOverlayWrapper = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
`;

const WrapperBottom = styled.div`
margin-top: 0.8em;
  position: relative;
  width: 80%;
  background: #F1F3F4;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const WrapperTop = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 100px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 1.8em;  
`;


