import React, {useContext, useState, useEffect} from "react";
import i18next from "i18next";
import styled from "styled-components";
import { OverlayContext } from "../../../layout-and-styling/context-hooks/OverlayContext";
import {Slider} from "@mui/material";
import {ReactComponent as PrevButton} from "../../../assets/initiative_prev_button.svg";
import {ReactComponent as NextButton} from "../../../assets/initiative_next_button.svg";
import {ReactComponent as NextButtonNL} from "../../../assets/initiative_next_buttonNL.svg";
import {ReactComponent as NextButtonCN} from "../../../assets/initiative_next_buttonCN.svg";
import {ReactComponent as NextButtonGray} from "../../../assets/initiative_next_button_gray.svg";
import {ReactComponent as NextButtonGrayNL} from "../../../assets/initiative_next_button_grayNL.svg";
import {ReactComponent as NextButtonGrayCN} from "../../../assets/initiative_next_button_grayCN.svg";
import {ReactComponent as PrevButtonGray} from "../../../assets/initiative_prev_button_gray.svg";
import ComponentStyledSlider from "../../../layout-and-styling/standardized-components-library/ComponentStyledSlider";
import {useTranslation} from "react-i18next";
import { ComponentTooltipProactiveness } from "../../../layout-and-styling/standardized-components-library/Component-Tooltips";

/** 
 * Component specifically made to hold three sliders to grade Driving force. On change data is stored in ArrayQualityAPI and ArrayQuality, 
 * and pushed to localStorage. This page also takes care of checking whether the page is complete or not by running the function
 * all_finalInput_checker() every rerender.
*/
export default function ComponentInitiativeSlider({
                                                    ArrayQuality, ArrayInputCheck, updateArrayQuality,
                                                    updateArrayInputCheck, selectedColleague, setSelectedColleague,
                                                    SignificantColleagues, set_input_checker, reRender, employeeRef
                                                }) {
    const {t, i18n} = useTranslation();
    const context = useContext(OverlayContext);
    const [hover1, setHover1] = useState(false);

    SignificantColleagues = JSON.parse(localStorage.getItem("SignificantColleagues")) ? JSON.parse(localStorage.getItem('SignificantColleagues')) : [];
    let SignificantColleaguesPerTeam = JSON.parse(localStorage.getItem("SignificantColleaguesPerTeam")) ? JSON.parse(localStorage.getItem('SignificantColleaguesPerTeam')) : [];

    /** create an array that contains the people selected independent
     * of teams for easier checking*/
    let significantColleaguesListWithoutTeams = [];

    SignificantColleaguesPerTeam.map((team) => {
        team.team_members.map((employee) => {
                significantColleaguesListWithoutTeams.push(employee)
            }
        )
    });


    const getColleagueByUserId = (id) => {
        return ArrayQuality.find(e => e.target === id)
    };


    const selectedColleagueArray = getColleagueByUserId(selectedColleague);
    const index = ArrayQuality.indexOf(selectedColleagueArray);


    for (let i = 0; i < ArrayQuality.length; i++) {
        if (ArrayQuality[i]['initiative_activated'] === true &&
            ArrayQuality[i]['decision_making_activated'] === true &&
            ArrayQuality[i]['finish_line_activated'] === true &&
            !ArrayInputCheck.includes(ArrayQuality[i].target)) {
            ArrayInputCheck.push(ArrayQuality[i].target);
        }
    }

    if (ArrayInputCheck.length) {
        updateArrayInputCheck(ArrayInputCheck)
        localStorage.setItem('CheckedEmployeesDrivingForce', JSON.stringify(ArrayInputCheck))
    }

    let all_finalInput_checker = () => {
        if (ArrayQuality.length === ArrayInputCheck.length) return true;
        return false;
    }

    /** set parent prop, check state to true or false depending on check result */
    set_input_checker(all_finalInput_checker())

    const updateArray = (value, factor, click) => {
        for (let i = 0; i < ArrayQuality.length; i++) {

            if(!click) ArrayQuality[index][factor] = value; //.target.value

            /**  Set the activated flag of this "first" Colleague to true*/
            if(factor == 'initiative'){
                updateArrayQuality(prevArray => prevArray.map((val, i) => i === index ? {
                    ...val,
                    initiative_activated: true
                } : val))
            } else if(factor == 'decision_making'){
                updateArrayQuality(prevArray => prevArray.map((val, i) => i === index ? {
                    ...val,
                    decision_making_activated: true
                } : val))
            } else if(factor == 'finish_line'){
                updateArrayQuality(prevArray => prevArray.map((val, i) => i === index ? {
                    ...val,
                    finish_line_activated: true
                } : val))
            }
            /** Update variable in local storage */
            localStorage.setItem('ArrayQuality', JSON.stringify(ArrayQuality))
        }
    };

    
    const previousColleague = () => {
        for (let i = 0; i < significantColleaguesListWithoutTeams.length; i++) {
            if (significantColleaguesListWithoutTeams[i].id === selectedColleague && i - 1 >= 0) {
                setSelectedColleague(significantColleaguesListWithoutTeams[i - 1].id);
                localStorage.setItem("SelectedColleague", significantColleaguesListWithoutTeams[i - 1].id)
                break;
            }
        }

        /** 
         * This is for the screen to automatically scroll to the user that is selected. The try/catch is to mitigate an error that does not affect the functionality
         * caused by a desync between the data and the ref.
         */
        try {
            employeeRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest',
            });
        } catch (error) {
            console.log(error)
        }
    }

    const nextColleague = () => {
        for (let i = 0; i < significantColleaguesListWithoutTeams.length; i++) {
            if (significantColleaguesListWithoutTeams[i].id === selectedColleague && i + 1 < significantColleaguesListWithoutTeams.length) {
                setSelectedColleague(significantColleaguesListWithoutTeams[i + 1].id);
                localStorage.setItem("SelectedColleague", significantColleaguesListWithoutTeams[i + 1].id)
                break;
            }
        }

        try {
            employeeRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        } catch (error) {
            console.log(error)
        }        
    }

    const marksInitiatve = [
        {
            value: 1,
            label: t('PageProactiveness.Rarely'),
            labelDescription: t('PageProactiveness.Slider1LabelDisplay1'),
        },
        {
            value: 2,
            label: '',
            labelDescription: t('PageProactiveness.Slider1LabelDisplay2'),
        },
        {
            value: 3,
            label: t('PageProactiveness.Sometimes'),
            labelDescription: t('PageProactiveness.Slider1LabelDisplay3'),
        },
        {
            value: 4,
            label: '',
            labelDescription: t('PageProactiveness.Slider1LabelDisplay4'),
        },
        {
            value: 5,
            label: t('PageProactiveness.Always'),
            labelDescription: t('PageProactiveness.Slider1LabelDisplay5'),
        }
    ];

    const marksDM = [
        {
            value: 1,
            label: t('PageProactiveness.Rarely'),
            labelDescription: t('PageProactiveness.Slider2LabelDisplay1'),
        },
        {
            value: 2,
            label: '',
            labelDescription: t('PageProactiveness.Slider2LabelDisplay2'),
        },
        {
            value: 3,
            label: t('PageProactiveness.Sometimes'),
            labelDescription: t('PageProactiveness.Slider2LabelDisplay3'),
        },
        {
            value: 4,
            label: '',
            labelDescription: t('PageProactiveness.Slider2LabelDisplay4'),
        },
        {
            value: 5,
            label: t('PageProactiveness.Always'),
            labelDescription: t('PageProactiveness.Slider2LabelDisplay5'),
        }
    ];

    const marksResults = [
        {
            value: 1,
            label: t('PageProactiveness.Rarely'),
            labelDescription: t('PageProactiveness.Slider3LabelDisplay1'),
        },
        {
            value: 2,
            label: '',
            labelDescription: t('PageProactiveness.Slider3LabelDisplay2'),
        },
        {
            value: 3,
            label: t('PageProactiveness.Sometimes'),
            labelDescription: t('PageProactiveness.Slider3LabelDisplay3'),
        },
        {
            value: 4,
            label: '',
            labelDescription: t('PageProactiveness.Slider3LabelDisplay4'),
        },
        {
            value: 5,
            label: t('PageProactiveness.Always'),
            labelDescription: t('PageProactiveness.Slider3LabelDisplay5'),
        }
    ];

    function checkNextColleague() {
        for (let i = 0; i < significantColleaguesListWithoutTeams.length; i++) {
            if (significantColleaguesListWithoutTeams[i].id === selectedColleague && i + 1 < significantColleaguesListWithoutTeams.length) {
                return true;
            }
        }
    }

    function checkPreviousColleague() {
        for (let i = 0; i < significantColleaguesListWithoutTeams.length; i++) {
            if (significantColleaguesListWithoutTeams[i].id === selectedColleague && i - 1 >= 0) {
                return true;
            }
        }
    }

    return (
        <Container id="collaborationsliders">
            <TopHeader>
                <NamePicture>
                    <PersonPic>
                        {ArrayQuality[index].first_name.charAt(0) +
                            ArrayQuality[index].last_name.charAt(0)}
                    </PersonPic>
                    {context.setFirstName(ArrayQuality[index].first_name, i18next.language)}
                </NamePicture>
                <InfoHover  onMouseOver={() => setHover1(true)}
                        onMouseOut={() => setHover1(false)}>?
                        {hover1 && (<ComponentTooltipProactiveness whichTooltip={"Sliders"}/>
                    )}
                </InfoHover>
            </TopHeader>
            
            <SliderContainer>
                <ComponentStyledSlider updateArray={updateArray} index={index} ArrayQuality={ArrayQuality} factor={'initiative'}
                                    thumbColorBool={ArrayQuality[index].initiative_activated} min={1} max={5} marks={marksInitiatve}
                                    title={t('PageProactiveness.Slider1')}/>
                <ComponentStyledSlider updateArray={updateArray} MarginLeft="80px" index={index} ArrayQuality={ArrayQuality} factor={'decision_making'}
                                    thumbColorBool={ArrayQuality[index].decision_making_activated} min={1} max={5} marks={marksDM}
                                    title={t('PageProactiveness.Slider2')}/>
                <ComponentStyledSlider updateArray={updateArray} index={index} ArrayQuality={ArrayQuality} factor={'finish_line'}
                                    thumbColorBool={ArrayQuality[index].finish_line_activated} min={1} max={5} marks={marksResults}
                                    title={t('PageProactiveness.Slider3')}/>
            </SliderContainer>

            <NextPreviousButtons>
                <button>{!checkPreviousColleague() ? <PrevButtonGray/> :
                    <PrevButton onClick={() => previousColleague()}/>}</button>
                {i18n.language == 'en-US' &&
                <button>{!checkNextColleague() ? <NextButtonGray/> :
                    <NextButton onClick={() => nextColleague()}/>}</button>
                            }
                {i18n.language == 'nl-NL' &&
                <button>{!checkNextColleague() ? <NextButtonGrayNL/> :
                    <NextButtonNL onClick={() => nextColleague()}/>}</button>
                }
                {i18n.language == 'ch-CH' &&
                <button>{!checkNextColleague() ? <NextButtonGrayCN/> :
                    <NextButtonCN onClick={() => nextColleague()}/>}</button>
                }
            </NextPreviousButtons>
        </Container>
    );
}

const Container = styled.div`
  position: relative;
  height: calc(100% - 64px);
  width: 100%;
  max-width: 850px;
  max-height: 600px;
  background-color: #FCFCFC;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 12px 0 12px;
  border-radius: 10px;
  font-family: 'Overpass';
`;

const TopHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: calc(100% - 32px);
  padding: 16px 16px 0 16px;
  justify-content: space-between;
`;

const SliderContainer = styled.div`
    position: relative;
    height: calc(100% - 65px);
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
`

const NamePicture = styled.div`
  display: flex;
  padding: 4px;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  width: fit-content;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const PersonPic = styled.div`
  margin-left: 8px;
  margin-right: 12px;
  align-items: center;
  width: 42px;
  height: 42px;
  font-size: 20px;
  border-radius: 50%;
  background: #d0e1f1;
  color: #271b36;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NextPreviousButtons = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 26px;
  margin-top: 24px;

  button {
    background: none;
    border: none;
    cursor: pointer;
  }
`;

const InfoHover = styled.div`
  float: right;
  font-size: 18px;
  font-family: 'Overpass', sans-serif;
  font-weight: 300;
  position: relative;
  color: #FCFCFC;
  background-color: #E2336B;
  border: 2px solid #E2336B;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
`;