import React, {useContext, useState, useEffect} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {OverlayContext} from "../../layout-and-styling/context-hooks/OverlayContext";
import FlipCard from '../specific-components/Component-flash-cards';
import { StyledDivRegular } from '../../layout-and-styling/standardized-components-library/Styling-TextInput';
import load from "../../assets/dashboard/load.gif";
import collaboration_icon from "../../assets/dashboard/Actions/collaboration_icon.svg";
import knowledge_icon from "../../assets/dashboard/Actions/knowledge_icon.svg";
import driving_force_icon2 from "../../assets/dashboard/Actions/driving_force_icon.svg";
import arrow from "../../assets/dashboard/Actions/arrow.svg";
import dottedline from "../../assets/dashboard/Actions/dotted_line.svg";
import driving_force_icon from "../../assets/dashboard/Actions/driving_force_action.svg";
import {ReactComponent as CommentsIcon} from "../../assets/dashboard/CommentsIcon.svg";
import {ReactComponent as CommentsIconClicked} from "../../assets/dashboard/CommentsIconClicked.svg";
import { init } from 'i18next';
import { act } from '@testing-library/react';


const Actions = () => {
  const context = useContext(OverlayContext);
  const {t, i18n} = useTranslation();

  const [selectedElephant, setSelectedElephant] = useState(0);
  const [actionsData, setActionsData] = useState(JSON.parse(localStorage.getItem("actions_data")));

  /** Adding a listener that runs when graph data is sotred in the local storage */
  useEffect(() => {      
      window.addEventListener('actions_data_event', () => {
          setActionsData(JSON.parse(localStorage.getItem("actions_data")));
      })
  }, [])

    return (
      <Container>
        
            <ComponentsWrapper Background="#FCFCFC">

              <ProcessTitle>
                  {t('DashboardFollowUp.ElephantsTitle')}
              </ProcessTitle> 

              
              <CriticalProblemWrapper FlexDirection="row">
                {!(actionsData && actionsData.elephants_data.length > 0) ? (
                  <LoadLogo Top="0%" Left="0%">
                      {" "}
                      <img src={load} alt="loading..."/>
                  </LoadLogo>
                ) : (
                  <>
                    {actionsData.elephants_data.map((item, index) => {
                      return (
                          <CriticalProblemContainer onClick={() => setSelectedElephant(index)} Border={selectedElephant === index ? "1px solid #E2336B" : ""} >
                              <CriticalProblemNumber>
                                  {index + 1}
                              </CriticalProblemNumber>

                              <CriticalProblemName>
                                  {context.setProcess(item.elephant.toString(), i18n.language)}
                              </CriticalProblemName>

                              <CriticalProblemButton Background={selectedElephant === index ? "#E2336B" : "#F1F3F4"} Color={selectedElephant === index ? "#FCFCFC" : "#271B36"}>
                                {t('DashboardFollowUp.Analysis')}
                              </CriticalProblemButton>
                          </CriticalProblemContainer>
                      )
                    })}
                  </>
                )}
              </CriticalProblemWrapper>
              

              </ComponentsWrapper>
                  
                <MetricsContainer>

                  <MetricWrapper>
                    <LeftContainerMetric>

                      {!(actionsData && actionsData.elephants_data.length > 0) ? (
                        <LoadLogo Top="0%" Left="0%">
                            {" "}
                            <img src={load} alt="loading..."/>
                        </LoadLogo>
                      ) : (
                        <>
                          <MetricState MarginRight={"16px"}>
                            <MetricName>
                            {t('DashboardFollowUp.DrivingForce')}
                            </MetricName>

                              {actionsData.elephants_data[selectedElephant].driving_force.current_metric < actionsData.elephants_data[selectedElephant].driving_force.benchmark ? (
                                <>{t('DashboardFollowUp.BelowTheBenchmark')}</>
                              ) : (
                                <>{t('DashboardFollowUp.NoActionsNeeded')}</>
                              )}
                              
                          </MetricState>

                          {/* Check if the current metric is higher than the benchmark */}
                        
                          <DisplayMetricContainer>

                            <CircleTitle>
                              {t('DashboardFollowUp.Current')}
                            </CircleTitle>

                            <Percentage Border={"5px solid #FFD166"}>
                              <p>{(actionsData.elephants_data[selectedElephant].driving_force.current_metric * 100).toFixed(0)}%</p>
                            </Percentage>

                          </DisplayMetricContainer><img src={arrow} alt="loading..." />

                          <FirstGoalContainer>

                              <CircleTitle>
                                {t('DashboardFollowUp.FirstGoal')}
                              </CircleTitle>

                              <Percentage Border={"5px solid #FFD166"}>
                                <p>{Math.ceil((actionsData.elephants_data[selectedElephant].driving_force.first_goal * 100))}%</p>
                              </Percentage>

                          </FirstGoalContainer><img src={dottedline} alt="loading..." />
                            
                          <BenchmarkContainer>

                            <CircleTitle>
                              {t('DashboardFollowUp.EndGoal')}
                            </CircleTitle>

                            <Percentage Border={"5px solid #06D6A0" } Opacity={"30%"}>
                              <p>{(actionsData.elephants_data[selectedElephant].driving_force.benchmark * 100).toFixed(0)}%</p>
                            </Percentage>

                          </BenchmarkContainer>
                        </>
                      )}
                    </LeftContainerMetric>

                    
                    <LeftContainer>
                      {!(actionsData && actionsData.elephants_data.length > 0) ? (
                        <LoadLogo>
                            {" "}
                            <img src={load} alt="loading..."/>
                        </LoadLogo>
                      ) : (
                        <>
                          <TitleContainer>
                            <img src={driving_force_icon2} alt="loading..."/>
                            {t('DashboardFollowUp.IncreaseDrivingForce')}
                          </TitleContainer>
                          {actionsData.elephants_data[selectedElephant].driving_force.actions.length > 0 ? (
                            <>
                            {actionsData.elephants_data[selectedElephant].driving_force.actions.map((item, index) => {
                              return(
                                <>
                                  <Action>
                                    {item.teams.length === 2 ?
                                      <ActionDescription>
                                        {t('DashboardFollowUp.BetweenTeams')} {context.setTeams(item.teams[0], i18n.language)} {t('DashboardFollowUp.And')} {context.setTeams(item.teams[1], i18n.language)}
                                      </ActionDescription>
                                    :
                                      <ActionDescription>
                                        {t('DashboardFollowUp.WithinTeam')} {context.setTeams(item.teams[0], i18n.language)}
                                      </ActionDescription>
                                    }
                                    
                                    <ActionBenefit>
                                      <AddedBenefit>{t('DashboardFollowUp.AddedBenefit')}</AddedBenefit><Green>{(item.increment*100).toFixed(1)}%</Green>
                                    </ActionBenefit>
                                  </Action>
                                  <Underliner>
                                    <hr></hr>
                                  </Underliner>
                                </>
                              )
                            })} 
                            </>
                          ) : (
                            <>
                              <LeftContainer JustifyContent="center" AlignItems="center" Gap="16px;">
                                <Heading>
                                  {t('DashboardFollowUp.DrivingForce')}
                                </Heading>
                              
                                <NoActionsNeeded>
                                  {t('DashboardFollowUp.NoActionsNeeded')}
                                </NoActionsNeeded>
                              </LeftContainer>
                            </>
                          )}  
                        </>
                      )}
                      
                    </LeftContainer>

                  </MetricWrapper>
                    
                  <MetricWrapper>

                    <MiddleContainerMetric>
                      {!(actionsData && actionsData.elephants_data.length > 0) ? (
                        <LoadLogo Top="0%" Left="0%">
                            {" "}
                            <img src={load} alt="loading..."/>
                        </LoadLogo>
                      ) : (
                        <>
                        <MetricState>

                        <MetricName>
                        {t('DashboardFollowUp.Knowledge')}
                        </MetricName>
                        {actionsData.elephants_data[selectedElephant].knowledge.current_metric < actionsData.elephants_data[selectedElephant].knowledge.benchmark ? (
                              <>{t('DashboardFollowUp.BelowTheBenchmark')}</>
                            ) : (
                              <>{t('DashboardFollowUp.NoActionsNeeded')}</>
                            )}

                        </MetricState>

                          <DisplayMetricContainer>

                            <CircleTitle>
                              {t('DashboardFollowUp.Current')}
                            </CircleTitle>

                            <Percentage Border={"5px solid #FFD166"}>
                              <p>{(actionsData.elephants_data[selectedElephant].knowledge.current_metric * 100).toFixed(0)}%</p>
                            </Percentage>

                          </DisplayMetricContainer>

                          <img src={arrow} alt="loading..." />

                          <FirstGoalContainer>

                          <CircleTitle>
                          {t('DashboardFollowUp.FirstGoal')}
                          </CircleTitle>

                          <Percentage Border={"5px solid #FFD166"}>
                            <p>{Math.ceil((actionsData.elephants_data[selectedElephant].knowledge.first_goal*100))}%</p>
                          </Percentage>

                          </FirstGoalContainer>

                          <img src={dottedline} alt="loading..." />

                          <BenchmarkContainer>

                            <CircleTitle>
                              {t('DashboardFollowUp.EndGoal')}
                            </CircleTitle>

                            <Percentage Border={"5px solid #06D6A0"} Opacity={"30%"}>
                              <p>{(actionsData.elephants_data[selectedElephant].knowledge.benchmark * 100).toFixed(0)}%</p>
                            </Percentage>

                          </BenchmarkContainer>
                        </>
                      )}
                    </MiddleContainerMetric>
                    
                    <MiddleContainer>
                      {!(actionsData && actionsData.elephants_data.length > 0) ? (
                        <LoadLogo>
                            {" "}
                            <img src={load} alt="loading..."/>
                        </LoadLogo>
                      ) : (
                        <>
                          <TitleContainer>
                            <img src={knowledge_icon} alt="loading..."/>
                            {t('DashboardFollowUp.IncreaseKnowledge')}
                          </TitleContainer>
                          {actionsData.elephants_data[selectedElephant].knowledge.actions.length > 0 ? (
                            <>
                            {actionsData.elephants_data[selectedElephant].knowledge.actions.map((item, index) => {
                              return(
                                <>
                                  <Action>
                                    {item.teams.length === 2 ?
                                      <ActionDescription>
                                        {t('DashboardFollowUp.BetweenTeams')} {context.setTeams(item.teams[0], i18n.language)} {t('DashboardFollowUp.And')} {context.setTeams(item.teams[1], i18n.language)}
                                      </ActionDescription>
                                    :
                                      <ActionDescription>
                                        {t('DashboardFollowUp.WithinTeam')} {context.setTeams(item.teams[0], i18n.language)}
                                      </ActionDescription>
                                    }
                                    
                                    <ActionBenefit>
                                      <AddedBenefit>{t('DashboardFollowUp.AddedBenefit')}</AddedBenefit><Green>{(item.increment*100).toFixed(1)}%</Green>
                                    </ActionBenefit>
                                  </Action>
                                  <Underliner>
                                    <hr></hr>
                                  </Underliner>
                                </>
                              )
                            })} 
                            </>
                          ) : (
                            <>
                              <MiddleContainer JustifyContent="center" AlignItems="center" Gap="16px;">
                                <Heading>
                                  {t('DashboardFollowUp.knowledge')}
                                </Heading>
                              
                                <NoActionsNeeded>
                                  {t('DashboardFollowUp.NoActionsNeeded')}
                                </NoActionsNeeded>
                              </MiddleContainer>
                            </>
                          )}  
                        </>
                      )}
                      
                    </MiddleContainer>
              
              </MetricWrapper>

              <MetricWrapper>
              
                <RightContainerMetric>
                  {!(actionsData && actionsData.elephants_data.length > 0) ? (
                    <LoadLogo Top="0%" Left="0%">
                        {" "}
                        <img src={load} alt="loading..."/>
                    </LoadLogo>
                  ) : ( 
                  <>
                    <MetricState>

                      <MetricName>
                      {t('DashboardFollowUp.Collaboration')}
                      </MetricName>
                      {actionsData.elephants_data[selectedElephant].collaboration.current_metric < actionsData.elephants_data[selectedElephant].collaboration.benchmark ? (
                            <>{t('DashboardFollowUp.BelowTheBenchmark')}</>
                          ) : (
                            <>{t('DashboardFollowUp.NoActionsNeeded')}</>
                          )}

                    </MetricState>

                    {/* Check if the current metric is higher than the benchmark */}
                      <DisplayMetricContainer>

                        <CircleTitle>
                          {t('DashboardFollowUp.Current')}
                        </CircleTitle>

                        <Percentage Border={"5px solid #FFD166"}>
                          <p>{Math.round((actionsData.elephants_data[selectedElephant].collaboration.current_metric * 100))}%</p>
                        </Percentage>

                      </DisplayMetricContainer>

                      <img src={arrow} alt="loading..."/>

                      <FirstGoalContainer>
                      
                      <CircleTitle>
                      {t('DashboardFollowUp.FirstGoal')}
                      </CircleTitle>
                      
                      <Percentage Border={"5px solid #FFD166"}>
                        <p>{Math.ceil((actionsData.elephants_data[selectedElephant].collaboration.first_goal*100))}%</p>
                      </Percentage>
                      
                      </FirstGoalContainer>

                      <img src={dottedline} alt="loading..."/>
                      
                      <BenchmarkContainer>

                        <CircleTitle>
                          {t('DashboardFollowUp.EndGoal')}
                        </CircleTitle>

                        <Percentage Border={"5px solid #06D6A0"} Opacity={"30%"}>
                          <p>{(actionsData.elephants_data[selectedElephant].collaboration.benchmark * 100).toFixed(0)}%</p>
                        </Percentage>

                      </BenchmarkContainer>
                  </>
                  )}
              
                </RightContainerMetric>
                
                
                <RightContainer>
                      {!(actionsData && actionsData.elephants_data.length > 0) ? (
                        <LoadLogo>
                            {" "}
                            <img src={load} alt="loading..."/>
                        </LoadLogo>
                      ) : (
                        <>
                          <TitleContainer>
                            <img src={driving_force_icon2} alt="loading..."/>
                            {t('DashboardFollowUp.IncreaseCollaboration')}
                          </TitleContainer>
                          {actionsData.elephants_data[selectedElephant].collaboration.actions.length > 0 ? (
                            <>
                            {actionsData.elephants_data[selectedElephant].collaboration.actions.map((item, index) => {
                              return(
                                <>
                                  <Action>
                                    {item.teams.length === 2 ?
                                      <ActionDescription>
                                        {t('DashboardFollowUp.BetweenTeams')} {context.setTeams(item.teams[0], i18n.language)} {t('DashboardFollowUp.And')} {context.setTeams(item.teams[1], i18n.language)}
                                      </ActionDescription>
                                    :
                                      <ActionDescription>
                                        {t('DashboardFollowUp.WithinTeam')} {context.setTeams(item.teams[0], i18n.language)}
                                      </ActionDescription>
                                    }
                                    
                                    <ActionBenefit>
                                      <AddedBenefit>{t('DashboardFollowUp.AddedBenefit')}</AddedBenefit><Green>{(item.increment*100).toFixed(1)}%</Green>
                                    </ActionBenefit>
                                  </Action>
                                  <Underliner>
                                    <hr></hr>
                                  </Underliner>
                                </>
                              )
                            })} 
                            </>
                          ) : (
                            <>
                              <RightContainer JustifyContent="center" AlignItems="center" Gap="16px;">
                                <Heading>
                                  {t('DashboardFollowUp.Collaboration')}
                                </Heading>
                              
                                <NoActionsNeeded>
                                  {t('DashboardFollowUp.NoActionsNeeded')}
                                </NoActionsNeeded>
                              </RightContainer>
                            </>
                          )}  
                        </>
                      )}
                      
                    </RightContainer>

              </MetricWrapper>

            </MetricsContainer>

              <BottomWrapper>
              
                {!(actionsData && actionsData.elephants_data.length > 0) ? (
                      <LoadLogo Top="0%">
                          {" "}
                          <img src={load} alt="loading..."/>
                      </LoadLogo>
                ) : (
                  <>
                    <CompletingActionsExplanation>
                    {t('DashboardFollowUp.CompletingActionsExplanation')}
                    </CompletingActionsExplanation>
                      
                    {actionsData.elephants_data[selectedElephant].tags.map((item, index) => {
                      return(
                        <ResolvedTags>
                        {item}
                        </ResolvedTags> 
                      )
                      
                    })} 
                  </>
              
                )}
              </BottomWrapper>
            
      </Container>
    )
}

export default Actions

const Container = styled.div`
  font-family: 'Overpass', sans-serif;
  width: 1734px;;
  display: flex;
  flex-direction: column;
  z-index: 6;
  margin-top: 24px;
  margin-left: 42px;
  margin-right: 42px;
`;

const ComponentsWrapper = styled.div`
  display: flex;
  background: ${ props => props.Background || "" };
  padding-left: 0px;
  padding-right: 0px;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
`;

const ProcessTitle = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #271B36;
  margin-top: 20px;
  margin-left: 24px;
  align-self: center;
`;


const CriticalProblemWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.FlexDirection || ""};
  justify-content: center;
  gap: 8px;
  margin-left: 20px;
  margin-top: 16px;
  margin-bottom: 24px;
  gap: 24px;
`;

const CriticalProblemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${ props => props.Width || "" };
  border-radius: 5px;
  padding: 18px;
  gap: 40px;
  background: #FCFCFC;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
  border: ${ props => props.Border || "" };
`;

const CriticalProblemNumber = styled.div`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #E2336B;
  color: #FCFCFC;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  justify-content: center;
  display: flex;
`;

const CriticalProblemName = styled.div`
  display: flex;
  color: #271B36;
  font-size: 14px;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

const CriticalProblemButton = styled.div`
  display: flex;
  padding: 4px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${ props => props.Color || "" };
  border-radius: 5px;
  background: ${ props => props.Background || "" };
`;

const MetricsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const MetricWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const LeftContainerMetric = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: #FCFCFC;
  width: 490px;
  height: 118px;
  padding: 12px 36px 0px 36px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 12px;
  align-items: top;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-top: 30px;
  margin-left: 24px;
`;

const DisplayMetricContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

const FirstGoalContainer = styled.div`
 margin-bottom: 16px;
`;

const BenchmarkContainer = styled.div`
 margin-bottom: 16px;
`;

const Percentage = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: center;
   width: 52px;
   height: 52px;
   background: #271B36;
   border: ${ props => props.Border || "" };
   border-radius: 50px;
   color: #FCFCFC;
   opacity: ${ props => props.Opacity || "100%" };
`;

const MetricName = styled.div`
   font-family: 'Montserrat';
   text-decoration: underline;
   margin-bottom: 12px;
   font-weight: bold;
`;

const CircleTitle = styled.div`
 
`;

const MetricState = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: ${ props => props.MarginRight || "" };
`;

const MiddleContainerMetric = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: #FCFCFC;
  width: 490px;
  height: 118px;
  padding: 12px 36px 0px 36px;
`;

const RightContainerMetric = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: #FCFCFC;
  width: 490px;
  height: 118px;
  padding: 12px 36px 0px 36px;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 560px;
  height: 418px;
  justify-content: ${ props => props.JustifyContent || "" };
  align-items: ${ props => props.AlignItems || "" };
  border-radius: 5px;
  background: #FCFCFC;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  gap: ${ props => props.Gap || "" };
  overflow: auto;
  overflow-x: hidden;
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  margin-left: 24px;
  margin-right: 24px;
`;

const ActionDescription = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px;
`;

const ActionBenefit = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #F1F3F4;
  border-radius: 4px;
  padding: 7px;
`;

const AddedBenefit = styled.div`
  margin-right: 6px;
`;

const Green = styled.div`
  color: #06D6A0;
`;

const Underliner = styled.div`
  margin-left: 24px;
  margin-right: 24px;
`;

const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 560px;
  height: 418px;
  justify-content: ${ props => props.JustifyContent || "" };
  align-items: ${ props => props.AlignItems || "" };
  border-radius: 5px;
  background: #FCFCFC;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  gap: ${ props => props.Gap || "" };
  overflow: auto;
  overflow-x: hidden;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 560px;
  height: 418px;
  justify-content: ${ props => props.JustifyContent || "" };
  align-items: ${ props => props.AlignItems || "" };
  border-radius: 5px;
  background: #FCFCFC;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  gap: ${ props => props.Gap || "" };
  overflow: auto;
  overflow-x: hidden;
`;

const Heading = styled.div`
font-family: 'Montserrat';
font-size: 32px;
font-weight: bold;
`;


const NoActionsNeeded = styled.div`
font-family: 'Overpass';
font-size: 16px;
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  border-radius: 4px;
  background: #FCFCFC;
  width: calc(100%);
  height: 120px;
  margin-top: 24px;
`;

const CompletingActionsExplanation = styled.div`
  font-family: 'Montserrat';
  font-weight: bold;
  padding-top: 31px;
  padding-bottom: 31px;
  padding-left: 24px;
`;

const ResolvedTags = styled.div`
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   font-family: 'Overpass';
   font-size: 14px;
   width: auto;
   height: 34px;
   background: #FCFCFC;
   border-radius: 50px;
   color: #0A070E;
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
   padding: 8px 16px;
`;

const LoadLogo = styled.div`
  img {
    width: 50px;
  }
  display: flex;
  position: relative;
  top: ${ props => props.Top || "50%" };
  left: ${ props => props.Left || "50%" };
`;