import React, {useState} from 'react';
import styled from 'styled-components';
import ComponentTeamPersonFilter from './Component-team-person-filter';
import { ComponentTooltipSpontaneousCollaborationPage } from '../../../layout-and-styling/standardized-components-library/Component-Tooltips';
import { ComponentTooltipCollaborationMeasurement } from '../../../layout-and-styling/standardized-components-library/Component-Tooltips';
import { ComponentTooltipProactiveness } from '../../../layout-and-styling/standardized-components-library/Component-Tooltips';
import { ComponentTooltipCulture } from '../../../layout-and-styling/standardized-components-library/Component-Tooltips';
import {useTranslation} from "react-i18next";

/** Reusable component used to create a list of close colleagues separated by teams. */
const ComponentSignificantColleagues = (props) => {
    const [hover, setHover] = useState(false);
    const {t} = useTranslation();

    return (
        <WrapperEmployees MarginTop={props.page == "PageCulturePeer" ? "-1.18em" : ""} outline={(!props.inputCheckPeople) & props.nextButtonClicked ? "2px solid #F72B2B" : ""} Heigth={props.page == "PageSpontaneousCollaboration" ? "calc(100% - 24px)" : props.page == "PageCulturePeer" ? "" : "calc(100% - 64px)"} 
        MaxHeight={props.page === "PageCulturePeer" ? "800px" : ""}>
          <IncompleteContentText display={!props.inputCheckPeople & props.nextButtonClicked ? "flex" : "none"}>
              {t('PageSpontaneousCollaboration.ErrorText')}
          </IncompleteContentText>
            <TopHeader>

                <Title>
                  {props.page === "PageSpontaneousCollaboration" ? (
                      t('PageSpontaneousCollaboration.ColleaguesSelectorText')
                  ) : props.page ==="PageCollaborationMeasurement" ? (
                      t('PageCollaborationMeasurement.ColleaguesSelectorText')
                  ) : props.page ==="PageProactiveness" ? (
                      t('PageProactiveness.ColleaguesSelectorText')
                  ) : (
                      t('PageCulture.ColleaguesSelectorText')
                  )
                } 
                
                </Title>

                <InfoHover onMouseOver={() => setHover(true)}
                           onMouseOut={() => setHover(false)}>?

                    {hover && props.page === "PageSpontaneousCollaboration" && (
                      <ComponentTooltipSpontaneousCollaborationPage whichTooltip={"SelectedPeople"}/>
                    )}
                    {hover && props.page === "PageCollaborationMeasurement" && (
                      <ComponentTooltipCollaborationMeasurement whichTooltip={"SelectedPeople"}/>
                    )}
                    {hover && props.page === "PageProactiveness" && (
                      <ComponentTooltipProactiveness whichTooltip={"SelectedPeople"}/>
                    )}
                    {hover && props.page === "PageCulturePeer" && (
                      <ComponentTooltipCulture whichTooltip={"PageCulturePeerPeopleFilter"}/>
                    )}
                </InfoHover>

            </TopHeader>

            <ComponentTeamPersonFilter ArrayInputCheck={props.ArrayInputCheck} meetingsTimes={props.meetingsTimes} spontaneousTimes={props.spontaneousTimes} selectedColleague={props.selectedColleague} checkColleagueCompleted={props.checkColleagueCompleted} 
                                      setSelectedColleague={props.setSelectedColleague} reRender={props.reRender} employeeRef={props.employeeRef} whichFilter={props.page} inputMarginTop="16px" />

        </WrapperEmployees>
    );
}

export default ComponentSignificantColleagues;

const WrapperEmployees = styled.div`
  background-color: #FCFCFC;
  opacity: ${props => props.Opacity || "1"};
  pointer-events: ${props => props.PointerEvents || ""};
  height: ${props => props.Heigth || ""};
  width: 543px;
  max-width: 530px;
  max-height: ${props => props.MaxHeight || "600px"};
  flex-shrink: 0;
  margin: 0 12px 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  outline: ${props => props.outline || ''};
  outline-offset: -2px;
  margin-top: ${props => props.MarginTop || ""};
`;

const IncompleteContentText = styled.div`
  position: absolute;
  display: ${(props) => props.display || ""};
  border-radius: ${(props) => props.BorderRadius || "5px"};
  color: #F72B2B;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 4px;
`;

const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
`;

const Title = styled.div`
  width: fit-content;
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  margin-left: 24px;
  margin-top: 24px;
  color: #271B36;
`;

const InfoHover = styled.div`
  float: right;
  font-size: 18px;
  font-family: 'Overpass', sans-serif;
  font-weight: 500;
  position: relative;
  color: #FCFCFC;
  background-color: #E2336B;
  margin-top: 16px;
  margin-right: 16px;
  border: 2px solid #E2336B;
  border-radius: 500px;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
  z-index: 2;
`;
